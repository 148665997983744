import * as actions from "./actions";
import * as appActions from "../app/actions";
import * as projectActions from "../projects/actions";
import { Record, Map, OrderedMap } from "immutable";
import { platformActions } from "../platformActions";
import { registerUserScopeLogs } from "../configureMiddleware";

const InitialState = Record({
  isSideMenuOpen: false,
  localImages: Map(),
  currProject: null,
  lastSeenProjects: Map(),
  headerTitle: null,
  urlParams: Map(),
  uiParams: Map(),
  inDraftMode: false,
  cleanCacheRevokes: 0,
});
const initialState = new InitialState();

function setSuperProperties(properties) {
  let props = registerUserScopeLogs(properties);
  platformActions.mixpanel.registerSuperProperties(props);
}

export default function uiReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    case actions.ENTER_DRAFT_MODE: {
      const { draftDetails } = action.payload;
      return state.set("inDraftMode", draftDetails || true);
    }

    case actions.LEAVE_DRAFT_MODE: {
      return state.set("inDraftMode", false);
    }

    case actions.ON_SIDE_MENU_CHANGE: {
      const { isOpen } = action.payload;
      return state.set("isSideMenuOpen", isOpen);
    }

    case actions.TOGGLE_SIDE_MENU:
      return state.update(
        "isSideMenuOpen",
        (isSideMenuOpen) => !isSideMenuOpen
      );

    case projectActions.SET_PROJECT_IMAGE: {
      const { projectId, uri } = action.payload;
      return state.setIn(["localImages", projectId], uri);
    }

    case projectActions.ENTER_PROJECT: {
      const { projectId, lastVisitedProjectId } = action.payload;
        setSuperProperties({onSite: lastVisitedProjectId === projectId});
      state = state.setIn(['urlParams', 'selectedProjectId'], projectId);
      return state.set('currProject', projectId);
    }

    case projectActions.LEAVE_PROJECT: {
      const { projectId } = action.payload;
      setSuperProperties({ onSite: false });
      if (state.get("currProject") !== projectId) return state;

      return state.set("currProject", null);
    }

    case actions.SET_URL_PARAMS: {
      const { params } = action.payload;

      (params || {}).loopEach(
        (k, v) => (state = state.setIn(["urlParams", k], v))
      );
      return state;
    }

    case actions.SET_UI_PARAM: {
      const { params } = action.payload;
      (params || {}).loopEach(
        (k, v) => (state = state.setIn(["uiParams", k], v))
      );
      return state;
    }

    case appActions.CLEAN_DYNAMIC_CACHE_DATA + "_SUCCESS": {
      return state.set("cleanCacheRevokes", state.get("cleanCacheRevokes") + 1);
    }

    case actions.CLEAR_ALL_URL_PARAMS: {
      state.set("urlParams", new Map());
    }

    case actions.SET_HEADER_TITLE: {
      const { title } = action.payload;
      return state.set("headerTitle", title);
    }
  }

  return state;
}
