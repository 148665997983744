import { fetchByTS, debugParams, replaceMaxUpdateTSIfNeeded } from '../lib/utils/utils';
import { schemasInfo, uploadObjectsDispatcher } from '../lib/offline-mode/config';
import { platformActions } from "../platformActions";
import _ from 'lodash';

export const CREATE_EMPLOYEE 		  		= 'CREATE_EMPLOYEE';
export const GET_EMPLOYEES   		  		= 'GET_EMPLOYEES';
export const END_EMPLOYEES_LISTENER   = 'END_EMPLOYEES_LISTENER';
export const UPSERT_EMPLOYEE    			= 'UPSERT_EMPLOYEE';
export const DELETE_EMPLOYEE          = 'DELETE_EMPLOYEE';
export const CLEAN_CACHED_EMPLOYEES   = 'CLEAN_CACHED_EMPLOYEES';
export const EMPLOYEES_DONE_LOADING 	= 'EMPLOYEES_DONE_LOADING';
export const GET_NEW_ID    						= 'GET_NEW_ID';
export const SAVE_EMPLOYEES    				= 'SAVE_EMPLOYEES';

export function getEmployees(viewer, projectId, cleanAll) {
	return ({ realmInstance, lokiInstance, platformActions }) => {

		if (cleanAll)
			setEmployeesValues({}, projectId, realmInstance, lokiInstance, platformActions, true);

		const saveFunc = (_data) => {
			if (debugParams.disableFetchByTSSave) return;
			return setEmployeesValues(_data, projectId, realmInstance, lokiInstance, platformActions);
		};
		let fetchParams = {
			scope: 'projects',
			projectId,
			viewer,
			resource: {
				name: 'employees',
				doneLoading: EMPLOYEES_DONE_LOADING,
				firebasePath: 'employees/projects',
			},
			saveFunc,
			getLastUpdateTS: () => getLastUpdateTS(realmInstance, lokiInstance, projectId)
		};
		fetchByTS(fetchParams);

		return {
			type: GET_EMPLOYEES,
			payload: { projectId }
		};
	};
}


function getLastUpdateTS(realmInstance, lokiInstance, scopeId) {

  var lastUpdateTS = 0;
	if (platformActions.app.getPlatform() == "web") {
		let lastUpdateTSObj = {};
    let lastUpdateTSObjArr = lokiInstance.getCollection('employees').chain().find({projectId: scopeId, isLocal: { '$ne' : true }}).simplesort("updatedTS", true).limit(1).data();
    if (lastUpdateTSObjArr.length) lastUpdateTSObj = lastUpdateTSObjArr[0];
    lastUpdateTS = lastUpdateTSObj.updatedTS;
	}
	else {
		lastUpdateTS = realmInstance.employees.objects('employee1').filtered('projectId = "' + scopeId + '"').max('updatedTS');
	}

  return lastUpdateTS || 0;
}

function setEmployeesValues(employees, projectId, realmInstance, lokiInstance, platformActions, cleanAll) {
  if (platformActions.app.getPlatform() == "web")
  	saveToLoki(employees, projectId, lokiInstance, cleanAll);
	else
		saveToRealm(employees, projectId, realmInstance, cleanAll);
}

export async function removeEmployeesFromLoki(lokiInstance) {
	//await lokiInstance.getCollection('lastUpdateTS').cementoFullDelete({ type: 'employees' });
	await lokiInstance.getCollection('employees').cementoFullDelete();
}

function saveToRealm(employees, projectId, realmInstance, cleanAll) {
	if (!cleanAll && Object.keys(employees || {}).length == 0) return;
	
	employees = Object.values(employees || {}).sort((employeeA, employeeB) => (employeeA.updatedTS || 0) > (employeeB.updatedTS || 0) ? -1 : 1);
	let currBatchMaxLastUpdateTS = _.get(employees, [0 , 'updatedTS'], 0);

	let realm = realmInstance.employees;
	realm.write(() => {
		if (cleanAll) {
			let allEmployees = realm.objects('employee1').filtered(`projectId = "${projectId}"`);
			realm.delete(allEmployees);
		}
		
		(employees).forEach(curr => {
			if (curr && curr.id) {
				if (!curr.isDeleted) {
					realm.create('employee1', {...curr.realmToObject(), projectId, projectId_objId:projectId + "_" + curr.id}, true)
				}
				else {
					let allCurrentEmployee = realm.objects('employee1').filtered(`projectId = "${projectId}" AND id = "${curr.id}"`);
					realm.delete(allCurrentEmployee);
				}
			}
			else
				console.warn('employees missing ID'); // TODO: Send to bugsnag
		});
		replaceMaxUpdateTSIfNeeded(currBatchMaxLastUpdateTS, realm, 'employee1', `projectId = "${projectId}"`);
	});
}

function saveToLoki(employees = {}, projectId, lokiInstance, cleanAll) {
	if (!cleanAll && Object.keys(employees || {}).length == 0) return;
	
	employees = Object.values(employees).sort((employeeA, employeeB) => employeeA.updatedTS > employeeB.updatedTS ? -1 : 1);
		
	let allEmployees = [];
	let deletedIds = [];
	employees.forEach(curr => {
		curr.isDeleted ? 
			deletedIds.push(curr.id) : 
			allEmployees.push({...curr.realmToObject(), projectId })
	});
	if (cleanAll) { 
		lokiInstance.getCollection('employees').cementoDelete({ projectId });
	}
	if (deletedIds.length > 0) lokiInstance.getCollection('employees').cementoDelete({ projectId, id: { '$in' : deletedIds }});
	lokiInstance.getCollection('employees').cementoUpsert(allEmployees);
}

export function removeEmployeesFromRealm(realmInstance) {
	let realm = realmInstance.employees;
	let allEmployees = realm.objects('employee1');
	realm.write(() => {
		realm.delete(allEmployees);
	});
}

export function endEmployeesListener(projectId) {	
  return ({ firebaseDatabase }) => {
	  firebaseDatabase().ref('employees/projects/' + projectId).off('value');
	  firebaseDatabase().ref('employees/projects/' + projectId).off('child_added');
	  firebaseDatabase().ref('employees/projects/' + projectId).off('child_changed');

    return {
      type: END_EMPLOYEES_LISTENER,
      payload: { projectId }
    };
  };
}

export function getEmployeeNewId() {	
  return ({ firebaseDatabase }) => {
		var push = firebaseDatabase().ref('employees/objects/').push(); // Note! - althow we are working in projectal scope - we are taking the ID from shared path! - BECOUSE THERES A LISTENER FUNCTION THAT COPY THEM TO THE GLOBAL PATH

    return {
      type: GET_NEW_ID,
      payload: { key:push.key }
    };
  };
}

export function upsertEmployee(inEmployee, projectId) {
	return ({ firebaseDatabase, removeEmpty, dispatch }) => {

		if (!inEmployee) return;

		let employeeToSave = { ...inEmployee.realmToObject(), projectId };
		if (!employeeToSave.id) {
			const id = firebaseDatabase().ref('employees/objects/').push().key; // Note! - althow we are working in projectal scope - we are taking the ID from shared path! - BECOUSE THERES A LISTENER FUNCTION THAT COPY THEM TO THE GLOBAL PATH
			employeeToSave.id = id;
		}

		employeeToSave = removeEmpty(employeeToSave, 'upsertEmployee');

		dispatch(saveEmployees(projectId, [employeeToSave]));

		return {
			type: UPSERT_EMPLOYEE,
			payload: { employee: employeeToSave }
		};
	};
}

export function deleteEmployee(employeeId, projectId) {
	return ({ dispatch }) => {

		const employeeToSave = { id: employeeId, isDeleted: true };
		
		dispatch(saveEmployees(projectId, [employeeToSave]));

		return {
			type: DELETE_EMPLOYEE,
			payload: { employee: employeeToSave }
		};
	};
}

const saveEmployees = (projectId, newEmployees, originalEmployees = null) => {
	if (projectId && Object.values(newEmployees || {}).length) {
		newEmployees = Object.values(newEmployees).filter(Boolean).map(employee => ({ ...employee, projectId, projectId_objId: projectId + "_" + employee.id}));
		
		uploadObjectsDispatcher({
			projectId,
			objectsToSave: newEmployees,
			originalObjects: originalEmployees,
			schemaInfo: schemasInfo.employees,
		});
	}

	return {
		type: SAVE_EMPLOYEES,
		payload: { newEmployees, originalEmployees }
	}
}