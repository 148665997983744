import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as companiesTypes from '../../../common/companies/companiesTypes';
import { CompanyContext } from '../../../common/companies/contexts';
import analyticsMessages from '../../../common/analytics/analyticsMessages';
import safetyMessages from '../../../common/safety/safetyMessages';
import systemMessages from '../../../common/app/systemMessages';
import reportsMessages from '../../../common/reports/reportsMessages';
import { platformActions } from '../../../common/platformActions';
import { getConfigurations } from '../../../common/configurations/actions';

class CompanyContextPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.getCompanyEmployees = this.getCompanyEmployees.bind(this);
    this.getCompanyPropertiesTypesAndSections = this.getCompanyPropertiesTypesAndSections.bind(this);
    this.getCompanyPropertiesMappings = this.getCompanyPropertiesMappings.bind(this);
    this.getPropInstancesPerProject = this.getPropInstancesPerProject.bind(this);
    this.getFirebaseData = this.getFirebaseData.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.refreshConfigurations = this.refreshConfigurations.bind(this);
    this.state = {
      selectedCompanyId: null,
      refreshConfigurations: this.refreshConfigurations
    };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({ firstMount: true }, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  async setComponentData(props, nextProps) {
    let newStateChanges = {};

    const companyIdChanged = props.isValDiff(nextProps, ['urlParams', 'selectedCompanyId']);

    // Set selectedCompanyId
    if (props.isValDiff(nextProps, ['urlParams', 'scope']) || companyIdChanged) {
      newStateChanges.selectedCompanyId = nextProps.getNested(['urlParams', 'selectedCompanyId']);

      if (nextProps.viewer && (!newStateChanges.selectedCompanyId || newStateChanges.selectedCompanyId === '_'))
        newStateChanges.selectedCompanyId = nextProps.viewer.companyId;
    }


    const nextCompanyId = newStateChanges.selectedCompanyId || this.state.selectedCompanyId || null;

    // Set company and companyProjects
    if (nextCompanyId && props.isValDiff(nextProps, ['companies', nextCompanyId]) || companyIdChanged) {
      newStateChanges.company = nextProps.getNested(['companies', nextCompanyId]);
      if (newStateChanges.company) {
        newStateChanges.companyProjects = {};
        Object.entries(newStateChanges.company.projects || {}).forEach(([currProjectId, currProjectInfo]) => {
          if (!currProjectInfo || currProjectInfo && currProjectInfo.isDeleted) return;

          newStateChanges.companyProjects[currProjectId] = (nextProps.projects || {}).getNested([currProjectId], {});
        });
      }
    }

    const nextCompanyProjects = newStateChanges.companyProjects || this.state.companyProjects || null;

    // Set companySubContractors
    if (nextCompanyProjects && companyIdChanged) {
      newStateChanges.companySubContractors = {};
      Object.entries(nextProps.companies.toJS ? nextProps.companies.toJS() : nextProps.companies || {}).forEach(([currCompanyId, currCompanyInfo]) => {
        const { projects } = currCompanyInfo;

        Object.entries(projects || {}).forEach(([currProjectId, currProjectInfo]) => {
          if (!currProjectInfo || currProjectInfo.isDeleted || !currProjectInfo.types) return;

          if (Boolean(currProjectInfo.getNested(['types', companiesTypes.COMPANY_TYPES_SUB])))
            newStateChanges.companySubContractors[currCompanyId] = currCompanyInfo;
        });
      });
    }

    // Set companyEmployees
    if ((companyIdChanged || !this.state.companyEmployees) && nextCompanyId)
      newStateChanges.companyEmployees = await this.getCompanyEmployees(nextCompanyId);

    const nextCompanyEmployees = newStateChanges.companyEmployees || this.state.companyEmployees || null;

    // Set companyPropertiesTypes && companyPropertiesSections
    if ((companyIdChanged || !this.state.companyPropTypes) && nextCompanyId) {
      const { companySubjectProperties, companySubjectSections } = await this.getCompanyPropertiesTypesAndSections(nextCompanyId);
      newStateChanges.companyPropertiesSections = companySubjectSections;
      newStateChanges.companyPropertiesTypes = companySubjectProperties;
    }

    // Set companyPropertiesMappings
    if ((companyIdChanged || !this.state.companyPropertiesMappings) && nextCompanyId)
      newStateChanges.companyPropertiesMappings = await this.getCompanyPropertiesMappings(nextCompanyId);

    // Set propInstancesPerProject && companyPropertiesInstances
    if ((companyIdChanged || !this.state.propInstancesPerProject) && nextCompanyProjects) {
      newStateChanges.propInstancesPerProject = await this.getPropInstancesPerProject(nextCompanyProjects);
      newStateChanges.companyPropertiesInstances = this.getCompanyPropInstances(newStateChanges.propInstancesPerProject);
    }



    if (Object.keys(newStateChanges || {}).length)
      this.setState(newStateChanges);
    
      const { companiesConfigurations, rtl, urlParams, viewer, loading, company } = this.props;
      this.setState({companiesConfigurations, rtl, urlParams, viewer, loading, company});
  }

  async getCompanyEmployees(companyId) {
    return await this.getFirebaseData(`employees/companies/${companyId}`, {});
  }

  async getCompanyPropertiesTypesAndSections(companyId) {
    const companySubjects = await this.getFirebaseData(`properties/types/companies/${companyId}`, {});

    let companySubjectSections = {};
    let companySubjectProperties = {};
    Object.entries(companySubjects).forEach(([subjectName, propsAndSections]) => {
      companySubjectSections[subjectName] = propsAndSections.sections || {};
      companySubjectProperties[subjectName] = propsAndSections.properties || {};
    });

    return {
      companySubjectSections,
      companySubjectProperties,
    };
  }

  async getCompanyPropertiesMappings(companyId) {
    return await this.getFirebaseData(`properties/mapping/companies/${companyId}`, {});
  }


  async getPropInstancesPerProject(companyProjects) {
    let instancesPerProject = {};

    await Promise.all(Object.keys(companyProjects).map(async currProjectId => {
      const projectInstances = await this.getFirebaseData(`properties/instances/projects/${currProjectId}`, null);
      if (!projectInstances) return;

      instancesPerProject[currProjectId] = projectInstances;
    }));

    return instancesPerProject;
  }

  refreshConfigurations() {
    const { getConfigurations } = this.props;
    let companyId = this.props.getNested(['urlParams', 'selectedCompanyId']);
    if (companyId)
      getConfigurations('companies',companyId);
  }

  getCompanyPropInstances(propInstancesPerProject) {
    let companyPropInstances = {};
    Object.values(propInstancesPerProject).forEach(projectSubjects => {
      Object.entries(projectSubjects).forEach(([subjectName, subjectProps]) => {
        companyPropInstances[subjectName] = { ...(companyPropInstances[subjectName] || {}), ...subjectProps };
      });
    });

    return companyPropInstances;
  }

  async getFirebaseData(ref, defaultVal) {
    const firebase = platformActions.firebase.getFirebase();
    return (await firebase.database().ref(ref).once('value')).val() || defaultVal;
  }

  render() {
    const { children, urlParams, rtl, loading, viewer, companiesConfigurations } = this.props;
    const { company, menus, selectedCompanyId, companyProjects, companySubContractors, companyEmployees, companyPropertiesTypes, companyPropertiesMappings, propInstancesPerProject, companyPropertiesSections, companyPropertiesInstances } = this.state;

    return (
      <CompanyContext.Provider value={this.state}>
        {children}
      </CompanyContext.Provider>
    );
  }
}

CompanyContextPage = connect(state => ({
  companiesConfigurations: state.configurations.companiesMap,
  urlParams: state.ui.urlParams,
  viewer: state.users.viewer,
  loading: state.app.loading,
  rtl: state.app.rtl,
  companies: state.companies.map,
  projects: state.projects.map,
}), {
  getConfigurations
})(CompanyContextPage);

export default CompanyContextPage;
