import React from "react";
import moment from 'moment-timezone';
import theme from '../app/theme';
import systemMessages from '../app/systemMessages'
import { getAppState, getDispatch } from '../configureMiddleware';
import _ from 'lodash';
import issuesMessages from "../issues/issuesMessages";
import { platformActions } from "../platformActions";
import ExtraError from "../lib/errors/extraError";
import mime from 'react-native-mime-types';
import { startToast, startAlert } from "./actions";
import { startProjectFirebaseListener } from "../lib/utils/utils";
import { DEFAULT_SYSTEM_LANGUAGE } from '../../common/app/constants';
import { v4 as uuidv4 } from 'uuid';


export function getDateString(value, intl, customShortDateFormat) {
  const shortDateFormat = customShortDateFormat || systemMessages.shortDateFormat;
  let dateString = moment(value).calendar(null, {
    sameDay: intl.formatMessage(systemMessages.today),
    nextDay: '[' + intl.formatMessage(systemMessages.tomorrow) + ']',
    nextWeek: intl.formatMessage(shortDateFormat),
    lastDay: '[' + intl.formatMessage(systemMessages.yesterday) + ']',
    lastWeek: intl.formatMessage(shortDateFormat),
    sameElse: intl.formatMessage(shortDateFormat)
  });

  return dateString;
} 

export function funcSingleton(obj, id, func) {
  if (!obj.funcList)
    obj.funcList = {};
  if (!obj.funcList[id])
    obj.funcList[id] = func;

  return obj.funcList[id];
}

export function isOperationActive(operationId) {
  return !Boolean(getAppState().getNested(['app', 'canceledOperations', operationId], false));
}

export function convertTextLinks(string, placeHolder, transformerFunc) {
  if (typeof(string) != "string")
    return string;
    
  let remainingString = string;
  let stringParts = [];
  let transformer = transformerFunc ? transformerFunc : (url) => (<a key={url} style={{ display: "inline-block", textDecoration: "underline", color: theme.brandPrimary }} target='_blank' href={url}> {placeHolder}</a>);
  let httpIndex = string.indexOf("http");
  let nextSpaceIndex;

  while (httpIndex != -1) {
    let text = remainingString.slice(0, httpIndex);
    stringParts.push(text);
    remainingString = remainingString.slice(httpIndex);


    nextSpaceIndex = remainingString.indexOf(" ");
    let url = nextSpaceIndex != -1 ? remainingString.slice(0, nextSpaceIndex) : remainingString;

    if (placeHolder != "")
      stringParts.push(transformer(url, placeHolder));

    remainingString = remainingString.slice(nextSpaceIndex);
    httpIndex = remainingString.indexOf("http");
  }

  if (nextSpaceIndex != -1)
    stringParts.push(remainingString);

  return stringParts;
}

export function convertCementoML(string, h1TransformerFunc = _.identity, h2TransformerFunc = _.identity, separatorElement, linksMode, linkPlaceHolder, linkTransformerFunc) {
  const HEADER_REGEX = new RegExp('<<h\\d>>');
  const DIGIT_REGEX = new RegExp('\\d');

  const HEADER = '<<h1>>';
  const BREAK = '<<br>>';
  const SEPARATOR = '<<-->>';

  let stringParts = [];
  let remainingString = String(string);


  const pushWithLinkHandling = (str = '') => stringParts.push(...Boolean(linksMode) ? convertTextLinks(str, linkPlaceHolder, linkTransformerFunc) : [str]);

  const breakLines = (str = '') => {
    str = str || '';
    let brIndex = str.indexOf(BREAK);
    while (brIndex != -1) {
      str = str.replace(BREAK, '\n');
      brIndex = str.indexOf(BREAK);
    }
    return str;
  };

  const nonHeaderTextPush = text => {
    let str = breakLines(text);
    let nextSeparatorIndex = str.indexOf(SEPARATOR);
    while (nextSeparatorIndex != -1) {
      pushWithLinkHandling(str.slice(0, nextSeparatorIndex));
      stringParts.push(separatorElement);
      str = str.slice(nextSeparatorIndex + SEPARATOR.length);
      nextSeparatorIndex = str.indexOf(SEPARATOR);
    }
    pushWithLinkHandling(str);
  };

  let nextHeaderTagIndex = remainingString.search(HEADER_REGEX);
  while (nextHeaderTagIndex != -1) {
    let text = remainingString.slice(0, nextHeaderTagIndex);
    nonHeaderTextPush(text);
    let tag = _.get(remainingString.match(HEADER_REGEX), '0', '');
    let hDigit = _.get(tag.match(DIGIT_REGEX), '0', '');
    remainingString = remainingString.slice(nextHeaderTagIndex + HEADER.length);
    nextHeaderTagIndex = remainingString.search(HEADER_REGEX);
    if (nextHeaderTagIndex != -1) {
      text = remainingString.slice(0, nextHeaderTagIndex);
      text = `${text}`;
      text = (hDigit == 1) ? h1TransformerFunc(text) : h2TransformerFunc(text);
      stringParts.push(text);
      remainingString = remainingString.slice(nextHeaderTagIndex + HEADER.length);
      nextHeaderTagIndex = remainingString.search(HEADER_REGEX);
    }
  }

  nonHeaderTextPush(remainingString);
  
  return stringParts;
};

export function splitInBatches(obj, batchSize) {
  if (!obj || !batchSize)
    return;

  let batches = [];
  let currBatch = {};
  let i = 1;
  for (let key in obj) {
    if (i === batchSize + 1) {
      batches.push(currBatch);
      currBatch = {};
      i = 1;
    }
    currBatch[key] = obj[key];
    i++;
  }
  
  if (Object.keys(currBatch).length)
    batches.push(currBatch);

  return batches;
}

export function validateEmail(emailAddress) {
  const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  return expression.test(String(emailAddress).toLowerCase())
}

const DEFAULT_NUMBER_OF_OPTIONS = 2;
/**
 * @typedef {({
 *  title: string | { [lang: string]: string } | import("./actions").IntlMessage
 *  id: string,
 * }} Option
 * 
 * @typedef OptionsToTextParams
 * @property {Option[]} options
 * @property {number} [numOfOptionsToShow] - default 2
 * @property {{ [optionId: string]: string }} data
 */

/**
 * @param {OptionsToTextParams} param0 
 * @returns 
 */
export const optionsToText = ({ options, numOfOptionsToShow = DEFAULT_NUMBER_OF_OPTIONS, intl, data }) => {
  let optionsArr = options && options.toJS ? options.toJS() : options;
  if (optionsArr && typeof optionsArr === 'object' && !Array.isArray(optionsArr))
    optionsArr = Object.values(optionsArr);
  
  if (!Array.isArray(optionsArr))
    return '';

  const selectedOptionsSet = optionsArr.reduce((acc, option) => {
    let textString = '';
    
    if (data && data[option.id]) {
      if (intl && _.get(option, ['title', 'defaultMessage']))
        textString = intl.formatMessage(option.title);
      else if (option && option.getCementoTitle)
        textString = option.getCementoTitle();
      else if (typeof option.title === 'string')
        textString = option.title;
    }

    if (textString)
      acc.add(textString);
    
    return acc;
  }, new Set);

  const selectedOptionsArr = Array.from(selectedOptionsSet);
  const restNumOfSelectedOptions = selectedOptionsArr.length > numOfOptionsToShow ? selectedOptionsArr.length - numOfOptionsToShow : null;
  
  return selectedOptionsArr.sort((a, b) => a.localeCompare(b))
                .slice(0, numOfOptionsToShow)
                .join(', ')
                + (restNumOfSelectedOptions ? `, +${restNumOfSelectedOptions}` : '');
}

export const getFloorTitle = ({ intl, floorId, buildingFloors, floor = null }) => {
  let floorTitle = '';
  if (!(floorId && buildingFloors) && !floor) return floorTitle;
  
  if (!floor)
    floor = buildingFloors.getNested2([floorId]);

  floor = floor && floor.toJS ? floor.toJS() : floor;

  if (floor) {
    floorTitle = _.get(floor, ['description'], _.get(floor, ['title'])) || '';
    if (!floorTitle) {
      const floorNumber = _.get(floor, ['num']);
      if (!_.isNil(floorNumber) && intl)
        floorTitle = intl.formatMessage(issuesMessages.floorNumber, { floorNumber: String(floorNumber) });
    } 
  }

  return floorTitle;  
}

export const getMergedViewsConfigurations = ({ isMobile = false, configurations }) => {
  const viewsConfig = configurations.views || {};
  const baseObj = viewsConfig.base || {};
  const overwriteObj = (isMobile ? viewsConfig.mobile : viewsConfig.web) || {};

  return _.mergeWith(baseObj, overwriteObj);
}

export const getPageViewUniversalIds = ({ pageViewConfiguration }) => {
  return Object.values(pageViewConfiguration || {}).reduce((acc, viewObjOptions) => {
    Object.values(viewObjOptions).forEach(viewObjOption => !acc.includes(viewObjOption.universalId) && acc.push(viewObjOption.universalId));
    
    return acc;
  }, []);
}

export const getDifference = (object, base) => _.transform(object, (acc, value, key) => { 
  const baseValue = base?.[key];
  if (_.isNil(baseValue) && _.isNil(value)) 
    return;

  if (!_.isEqual(value, baseValue)) 
    acc[key] = (_.isObject(value) && _.isObject(baseValue)) ? getDifference(value, baseValue) : value; 
});

export const flattenObject = (object, _prefix = '', _result = {}) => {
  _prefix = _prefix ? _prefix : '';
  _result = _result ? _result : {};

  if (_.isString(object) || _.isNumber(object) || _.isBoolean(object) || _.isNull(object)) {
    _result[_prefix] = object;
    return _result;
  }

  if (_.isArray(object) || _.isPlainObject(object)) {
    for (const key in object) {
      let pref = !_.isEmpty(_prefix) ? `${_prefix}/${key}` : key;
      flattenObject(object[key], pref, _result);
    }
  }

  return _result;
}


export const encodeBase64 = async (uri, contentType) => {
  let base64String = null;

  try {
    const shortPath = uri.replace('file:/', '');
    contentType = contentType || mime.lookup(shortPath);
    const isFileExist = await platformActions.fs.exists(shortPath);
    if (!isFileExist)
      throw new ExtraError('File no longer exists in file system', { uri, contentType });

    base64String = await platformActions.fs.getBase64String(shortPath); // TODO: check on web whats happens because there is not .fs for web
    base64String = `data:${contentType || 'image/jpeg'};base64,${base64String}`;
  }
  catch (error) {
    console.warn('An error occuered converting file to base64', { error, uri, type: contentType });
    onError({
      errorMessage: 'Failed conversion of file to base64',
      error,
      methodMetaData: {
        args: { uri, contentType },
        name: 'encodeBase64',
      },
      errorMetaData: {
        ...(error.metadata || {})
      }
    });
  }

  return base64String;
}

export const getBase64StringInfo = base64String => {
	let base64Info = {};

	const find = ';base64,';
	if (typeof base64String === 'string' && base64String.indexOf(find) !== -1) {
		const splitB64 = base64String.replace('data:', '').split(find);
		if (splitB64.length === 2) {
			base64Info.type = splitB64[0];
			base64Info.extension = mime.extension(base64Info.type) || null;
			base64Info.uri = splitB64[1];
		} else if (splitB64.length === 1) base64Info.uri = splitB64[0];

		base64Info.dataString = base64String;
	}

	return Object.keys(base64Info).length ? base64Info : null;
};

/**
 * 
 * @param {string} projectId 
 * @param {string} formTemplateId 
 * @param {{ buildingId?: string, floorId?: string, unitId?: string }} location 
 * @param {function} onUpdate - callback called with the updated forms
 * @returns {Promise<function>} function to call to remove the listener
 */

export const locationFormsListener = async (projectId, formTemplateId, location, onUpdate) => {
  const firebase = platformActions.firebase.getFirebase();
  const ref = await firebase.database().ref(`forms/${projectId}/full/general`).orderByChild('formTemplateId').equalTo(formTemplateId);

  await ref.on('value', snapshot => {
    const projectForms = snapshot.val();
    const isLocMatch = (locA, locB) => (locA && locB && locA === locB) || (!locA && !locB);
    let forms = {};
    _.values(projectForms).forEach(currForm => {
      if (!isLocMatch(currForm.location.unitId, location.unitId)     || 
          !isLocMatch(currForm.location.floorId, location.floorId)   || 
          !isLocMatch(currForm.location.buildingId, location.buildingId))
        return;

      forms[currForm.id] = currForm;
    });

    if (onUpdate)
      onUpdate(forms);
  });

  return () => ref.off('value');
}

/**
 * 
 * @param {string} projectId 
 * @param {string} formId 
 * @param {function} onUpdate - callback called with the updated form
 * @returns {function} function to call to remove the listener
 */

 export const openFormListener = (projectId, formId, formType, onUpdate) => {
  return startProjectFirebaseListener(projectId, `forms/${projectId}/full/${formType || 'general'}/${formId}`, 'value', onUpdate);
}

/**
 * @param {string} projectId 
 * @param {string} formTemplateId 
 * @param {{ buildingId?: string, floorId?: string, unitId?: string }} location 
 * @returns {Promise<{ [formId: string]: import("../pdf/actions").Form }>}
 */
export const getLocationForms = async (projectId, formTemplateId, location) => {
  const firebase = platformActions.firebase.getFirebase();
  /** @type {{ [formId: string]: import("../pdf/actions").Form }} */
  const projectForms = (await firebase.database().ref(`forms/${projectId}/full/general`).orderByChild('formTemplateId').equalTo(formTemplateId).once('value')).val();
  const isLocMatch = (locA, locB) => (locA && locB && locA === locB) || (!locA && !locB);
  let forms = {};
  Object.values(projectForms).forEach(currForm => {
    if (!isLocMatch(currForm.location.unitId, location.unitId)     || 
        !isLocMatch(currForm.location.floorId, location.floorId)   || 
        !isLocMatch(currForm.location.buildingId, location.buildingId))
      return;

    forms[currForm.id] = currForm;
  });

  return forms;
}

/**
 * @typedef {{ 
  * 	errorMessage: string, 
  *   errorCode?: number,
  * 	error?: any,
  * 	errorMetaData?: Object.<string, any>, 
  * 	methodMetaData?: { name: string, args: { [key: string]: any } }, 
  * 	alertParams?: import('../app/actions').StartToastParams ,
  *   nativeAlertParamsWithActions?: { title: string, message: string, actions: { text: string, onPress?: () => void, style?: 'default' | 'cancel' | 'destructive' }[] },
  * }} OnErrorParams
  * @param {OnErrorParams} paramsObj
*/
export const onError = ({ errorMessage, errorCode, error, errorMetaData, methodMetaData, alertParams, nativeAlertParamsWithActions }) => {
  const [parsedErrorMeta, parsedMethodMeta, parsedError] = [errorMetaData, methodMetaData, error].map(metaData => {
    let parsed = null;
    if (metaData) {
      parsed = {};
      try {
        _.entries(metaData).forEach(([key, val]) => {
          try {
            _.set(parsed, [key], JSON.stringify(val));
          }
          catch(e) {
            _.set(parsed, [key], val);
          }
        });
      }
      catch (e) {
        parsed = metaData;
      }
    }

    return parsed;
  });


  platformActions.sentry.notify(errorMessage, {
    errorMessage,
    errorCode,
    error: parsedError,
    context: {
      isConnectedToInternet: Boolean(getAppState && getAppState() && getAppState().getNested(['app', 'isConnected'], false)),
    },
    methodMetaData: parsedMethodMeta,
    errorMetaData: parsedErrorMeta,
  });

  if (alertParams || nativeAlertParamsWithActions) {
    const dispatch = getDispatch();
    if (platformActions.app.getPlatform() === 'web') {
      if (alertParams) dispatch(startToast(alertParams));
    }
    else {
      if (nativeAlertParamsWithActions) {
        // import('react-native').then(n => n.Alert.alert(nativeAlertParamsWithActions.title, nativeAlertParamsWithActions.message, nativeAlertParamsWithActions.actions));
      }
      else if (alertParams)
        dispatch(startAlert(alertParams.title, alertParams.message, alertParams.values));
    }
  }

  console.error(`An error occured -`, { errorMessage, errorCode, error, errorMetaData, methodMetaData, alertParams });
}

export const toJSDeep = (variable, ObjectClass = Object) => {
  const isObject = variable && typeof variable === 'object';
  const isArray = isObject && Array.isArray(variable);

  if (isObject) {
    variable = !isArray && typeof variable.toJS === 'function' ? variable.toJS() : variable;
    variable = Object.entries(variable).reduce((acc, [subVarKey, subVarVal]) => { acc[subVarKey] = toJSDeep(subVarVal, ObjectClass); return acc; }, isArray ? [] : new ObjectClass);
  }

  return variable;
}

export const safeFormatMessage = (intl, message) => {
  let messageString = '';

  if (typeof message === 'string')
    messageString = message;
  else if (_.get(message, 'defaultMessage') && message.id)
    messageString = intl 
      ? intl.formatMessage(message) 
      : message.defaultMessage;

  return messageString;
}

/**
 * Returns true if the given value is considered "empty" meaning it is either NaN or null or undefined or an empty string or an empty array or an empty object
 * @param {any} value 
 * @returns 
 */
export const isEmptyValue = (value) => (
  _.isNil(value)                                                                 || 
  _.isNaN(value)                                                                 ||
  ((typeof value === 'object' || typeof value === 'string') && _.isEmpty(value)) 
);


export const getAppLang = () => {
  let lang = DEFAULT_SYSTEM_LANGUAGE;

  const appState = getAppState && getAppState();
  if (appState) {
    const selectedProjectId = appState.getNested(['ui', 'currProject']);
    const project = appState.getNested(['projects', 'map', selectedProjectId], {});
    if (project.lang)
      lang = project.lang;
  }

  return lang;
}

const PHONE_NUMBER_MIN_LENGTH = 6;
const PHONE_NUMBER_MAX_LENGTH = 16;
/**
 * @param {string} phoneNumber 
 * @returns 
 */
export const isValidPhoneNumber = (phoneNumber) => {
  phoneNumber = phoneNumber ? String(phoneNumber) : '';
  const formattedPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');
  return Boolean(formattedPhoneNumber && formattedPhoneNumber.length > PHONE_NUMBER_MIN_LENGTH && formattedPhoneNumber.length <= PHONE_NUMBER_MAX_LENGTH);
}

/**
 * 
 * @param {string} str 
 * @returns 
 */
export const isBooleanString = str => str === 'true' || str === 'false';
/**
 * 
 * @param {'true' | 'false'} str 
 * @returns 
 */
export const parseBooleanString = str => str === 'true' ? true : str === 'false' ? false : undefined;
export const isUndefinedString = str => str === 'undefined';
export const isNullString = str => str === 'null';

/**
 * 
 * @param {moment.MomentInput} timestamp - anything that goes into moment
 * @param {string} [timezone] - timezone to use (i.e.: 'Asia/Jerusalem')
 * @returns 
 */
export const UTCToLocalTS = (timestamp, timezone) => {
  timezone = timezone || moment.tz.guess();
  const localMoment = moment(timestamp).tz(timezone);

  return localMoment.subtract(localMoment.utcOffset(), 'minutes').valueOf();
}

/**
 * 
 * @param {moment.MomentInput} timestamp - anything that goes into moment
 * @param {string} [timezone] - timezone to use (i.e.: 'Asia/Jerusalem')
 * @returns 
 */
export const localTSToUTC = (timestamp, timezone) => {
  timezone = timezone || moment.tz.guess();

  const localMoment = moment(timestamp).tz(timezone);
  const utcMoment = moment(localMoment).utc().add(localMoment.utcOffset(), 'minutes');

  return utcMoment.valueOf();
}

/**
 * 
 * @param {moment.MomentInput} timestamp - anything that goes into moment
 * @param {string} timezone - timezone to use (i.e.: 'Asia/Jerusalem')
 * @param {'startOf' | 'endOf'} [roundingMode] - if endOf will round to the end boundary of the roundingMode (i.e.: roundingUnit = 'day' && 'endOf' -> 23:59:59 | 'startOf' -> 00:00:00)
 * @param {import('moment').unitOfTime.StartOf} [roundingUnit]
 * @return
 */
export const roundTS = (timestamp, timezone, roundingMode, roundingUnit) => {
  timezone = timezone || moment.tz.guess();
  
  const mom = moment(timestamp).tz(timezone);
  
  let ts;
  if (_.isFunction(mom[roundingMode]) && roundingUnit)
    ts = mom[roundingMode](roundingUnit).valueOf();
  else
    ts = mom.valueOf();

  return ts;
}

const processString = str => (str || '').toLowerCase().split(' ').map(s => s.trim()).filter(Boolean).join(' ').split('-').map(s => s.trim()).filter(Boolean).join(' ');
export const isStringMatch = (string, searchString) => {
  string = processString(string);
  searchString = processString(searchString);

  return string.indexOf(searchString) !== -1;
}

export const storeImagePermanently = async (uri, inFileName, fileExtension) => {
  if (platformActions.app.isWeb()) return uri;

  const baseFolder = platformActions.app.isIOS() ? platformActions.fs.getDocumentDirectoryPath() : platformActions.fs.getPicturesDirectoryPath();
  if (uri.includes(baseFolder)) return uri;

  const fileName = inFileName || uuidv4();

  // TODO: Permissions

  let fileBasePath = `//${baseFolder}/cemento/uploadImageCache`;
  await platformActions.fs.createPath(fileBasePath);
  const filePath = `${fileBasePath}/${fileName}.${fileExtension}`;
  const shortUri = uri?.replace("file:/", "");
  if (shortUri) {
    if (await platformActions.fs.exists(shortUri)) {
      await platformActions.fs.moveFile(shortUri, filePath);
    }
    else {
      onError({
        errorMessage: 'File no longer exists to be stored permanently',
        methodMetaData: {
          name: 'storeImagePermanentely',
          args: [uri, fileName, fileExtension],
        },
      });
      return null;
    }
  }

  return `file:/${filePath}`;
}

/**
 * @template T
 * @param {T} error 
 * @returns {T extends (Error | ExtraError | string) ? string : null}
 */
export const getOriginalErrorMessage = (error) => {
  if (error instanceof ExtraError) {
    return getOriginalErrorMessage(error.innerError);
  } else if (error instanceof Error) {
    return error.message;
  } else if (typeof error === 'string') {
    return error;
  } else {
    return null;
  }
}