import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import _fp from 'lodash/fp';
import { useSelector } from 'react-redux';
import { decodeFiltersFromSearch } from '../../../web/app/funcs';
import { lokiInstance, realmInstance } from '../../configureMiddleware';
import { getFilteredResults } from '../../permissions/funcs';
import { ProjectContext } from '../../projects/contexts';
import { unknownCompanyId } from '../../companies/companiesTypes';
import { v4 as uuidv4 } from 'uuid';
import { platformActions } from '../../platformActions';
import { POSTS_FILTER_URL_KEY } from '../../../web/app/constants';
import useMemoizedValue from '../../hooks/useMemoizedValue';

const addChecklistsAndStagesToPosts = (posts, checklistsMap, checklistInstancesMap, currentStagesMap) => {
	if (!checklistsMap || !posts || !checklistInstancesMap || !currentStagesMap) {
		return [];
	}
	const plainChecklistInstances = {};

	checklistInstancesMap.forEach(checklistItem => {
		checklistItem.forEach(itemLocation => {
			itemLocation.forEach(checklistItemInstance => {
				plainChecklistInstances[checklistItemInstance.id] = {
					...checklistItemInstance,
				};
			});
		});
	});
	return posts.map(post => {
		if (!post.checklistItemInstance?.id || !plainChecklistInstances[post.checklistItemInstance?.id]) {
			return post;
		}
		const { checklistId, id: checklistItemInstanceId } = plainChecklistInstances[post.checklistItemInstance?.id];

		const {
			stageId,
			description: checklistTitle,
			ordinalNo: checklistOrdinalNo
		} = checklistsMap.getIn([checklistId], {});
		const stage = currentStagesMap.getIn([stageId], {});

		return {
			...post,
			stageOrdinalNo: stage.ordinalNo,
			stageId,
			stageTitle: stage.getCementoTitle(),
			checklistOrdinalNo,
			checklistId,
			checklistTitle,
			checklistItemInstanceId,
		}
	});
}

const setCompanyIdAndGroupsToOwnerAndAssignToOnPosts = (posts, members) => {
	return Object.values(posts || {}).map(post => {
		['owner', 'assignTo'].forEach(memberPropKey => {
			const memberId = _.get(post, [memberPropKey, 'id']);
			if (!memberId) return;

			const memberCompanyId = _.get(members, [memberId, 'companyId'], unknownCompanyId);
			const memberGroups = _.get(members, [memberId, 'groups']);
			post = _fp.set([memberPropKey, 'companyId'], memberCompanyId, post);
			post = _fp.set([memberPropKey, 'groups'], memberGroups, post);
		});

		return post;
	});
};

const addInstancesValuesToPosts = (posts, postsInstances) => {
	if (!posts || !postsInstances) return posts;

	let postsMap = {};
	posts.forEach(post => {
		postsMap[post.id] = Object.assign({}, post);
	});

	postsInstances.forEach(instance => {
		const postId = instance.parentId;
		const propId = instance.propId;
		if (!postId || !propId) return;

		const currentProp = postsMap[postId];
		if (currentProp) _.set(postsMap, [postId, 'instances', propId], instance);
	});

	return _.values(postsMap);
};

// function to create a query. filterVal = value to query, data = [{source : [array of data], key : which field to search }]
// if the field you want to search is in different array,  pass another object in the data array. Example:  [{ source: allMembers, key: ["companyId"] }, { source: allCompanies, key: ["name"] }] .
// here we are looking for members that their companyName begins with filterVal
const generateLokiQuery = (filterVal, data, path) => ({
	$or: Object.values(
		data[0].source
			.filter(item =>
				data.length < 2
					? item.getNested(data[0].key, '').toLowerCase().includes(filterVal)
					: data[1].source.getNested([item.getNested(data[0].key)])
					? data[1].source
							.getNested([item.getNested(data[0].key)], {})
							.getNested(data[1].key, '')
							.toLowerCase()
							.includes(filterVal)
					: false,
			)
			.map(item => ({ [path]: item.get('id') }))
			.toJS(),
	),
});

const orStringBuilder = orsArr => `(${orsArr.join(' OR ')})`;
const andStringBuilder = andsArr => `${andsArr.join(' AND ')}`;
const realmQueryStringBuilder = queryArr =>
	andStringBuilder(queryArr.map(a => (Array.isArray(a) ? orStringBuilder(a) : a)));

/**
 * @typedef {'records' | 'tasks'} PostsType
 *
 * @typedef {'safety'} ContentType
 *
 * @typedef Filters
 * @property {ContentType} [contentType]
 * @property {PostsType} [postsType]
 * @property {string} [locationSearch] - Router location.search
 * @property {string[]} [inIds] - specific post ids
 * @param {Filters} postsFilters
 * @returns
 */

const usePosts = postsFilters => {
	// props
	const {
		allCompanies, allMembers, trades,
		stages, checklists, checklistInstances,
	} = useSelector(state => ({
		allCompanies: state.companies.map,
		allMembers: state.members.map,
		trades: state.trades.map,
		stages: state.stages.map,
		checklists: state.checklists.map,
		checklistInstances: state.checklistItemsInstances.map,
	}));
	const { configurations, selectedProjectId, projectMembers } = useContext(ProjectContext);
	const currentStages = useMemo(
		() => stages.getIn([selectedProjectId], {}),
		[stages, selectedProjectId]
	);
	const currentChecklists = useMemo(
		() => checklists?.getIn([selectedProjectId]),
		[checklists, selectedProjectId]
	);
	const currentChecklistInstances = useMemo(
		() => checklistInstances?.getIn([selectedProjectId]),
		[checklistInstances, selectedProjectId]
	);

	postsFilters = _.isNil(postsFilters) ? {} : postsFilters;

	// state
	const [currViewPostsMap, setCurrViewPostsMap] = useState({});
	const [currViewPosts, setCurrViewPosts] = useState([]);
	const [filteredPosts, setFilteredPosts] = useState([]);
	
	// refs
	const lokiPostsRef = useRef(null);
	const realmPostsRef = useRef(null);
	const lokiPostsInstancesRef = useRef(null);
	
	const isNative = platformActions.app.isNative();
	const _postsFilters = useMemoizedValue(postsFilters);

	const getRealmPostsQuery = useCallback(() => {
		const { contentType, postsType, inIds } = _postsFilters;
		let queryArr = [ `projectId == "${selectedProjectId}"` ];

		if (contentType === 'safety') queryArr.push('trade.id == "1038"');
		else if (configurations.getNested(['features', 'safety', 'isActive'])) queryArr.push(`trade.id != "1038"`);

		switch (postsType) {
			case 'records': {
				queryArr.push(['issueState == 0', 'issueState == null']);
				queryArr.push(['isIssue == false', 'isIssue == null']);
				break;
			}

			case 'tasks': {
				queryArr.push(['isIssue == true']);
				break;
			}
		}

		if (inIds?.length) {
			queryArr.push(inIds.map(id => `id == "${id}"`));
		}

		return { postsQuery: realmQueryStringBuilder(queryArr) };
	}, [_postsFilters.contentType, _postsFilters.postsType, _postsFilters.inIds, configurations, selectedProjectId]);

	const getLokiPostsQuery = useCallback(() => {
		const { contentType, postsType, filterValue, inIds } = _postsFilters;

		let postsQuery = { projectId: selectedProjectId, $and: [] };
		if (filterValue) {
			const regExp = {
				$regex: new RegExp(filterValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i'),
			};
			const companysQuery = generateLokiQuery(
				filterValue,
				[
					{ source: allMembers, key: ['companyId'] },
					{ source: allCompanies, key: ['name'] },
				],
				'assignTo.id',
			);
			const tradesQuery = generateLokiQuery(filterValue, [{ source: trades, key: ['getTitle'] }], 'trade.id');
			const membersQuery = generateLokiQuery(
				filterValue,
				[{ source: allMembers, key: ['displayName'] }],
				'assignTo.id',
			);

			postsQuery['$and'].push({
				['$or']: [
					{ title: regExp },
					{ [`extraData.images.${filterValue}`]: { $gt: 0 } },
					companysQuery,
					tradesQuery,
					membersQuery,
				],
			});
		}

		if (contentType === 'safety') postsQuery['$and'].push({ 'trade.id': '1038' });
		else if (configurations.getNested(['features', 'safety', 'isActive']))
			postsQuery['$and'].push({ 'trade.id': { $ne: '1038' } });

		switch (postsType) {
			case 'records': {
				postsQuery['$and'].push({
					$or: [{ issueState: 0 }, { issueState: { $exists: false } }],
				});
				postsQuery['$and'].push({
					$or: [{ isIssue: false }, { isIssue: { $exists: false } }],
				});
				break;
			}

			case 'tasks': {
				postsQuery['$and'].push({
					$or: [{ isIssue: true }, { isIssue: { $exists: false } }],
				});
				postsQuery['$and'].push({ issueState: { $exists: true } });
				break;
			}

			default:
				break;
		}

		if (inIds?.length) {
			postsQuery['$and'].push({ 
				$or: inIds.map(id => ({ id })),
			});
		}

		const postsInstancesQuery = {
			projectId: selectedProjectId,
			subjectName: 'postsInfo',
		};

		return { postsQuery, postsInstancesQuery };
	}, [
		selectedProjectId, configurations,
		_postsFilters.contentType, _postsFilters.postsType,
		_postsFilters.filterValue, _postsFilters.inIds, allCompanies,
		allMembers, trades,
	]);

	const postsQuery = useMemo(
		() => (isNative ? getRealmPostsQuery : getLokiPostsQuery)(),
		[isNative ? getRealmPostsQuery : getLokiPostsQuery]
	);

	const getRealmPostsMap = useCallback((posts, changes = null, shouldRevoke = true) => {
		let arr = [];
		posts.forEach((post, index) => {
			const isPostDeleted = post.isDeleted || changes?.deletions.includes(index);
			if (isPostDeleted)
				return;

			const isPostModified = changes?.modifications.includes(index);
			const isPostAdded = changes?.insertions.includes(index);
			const shouldRevokePost = shouldRevoke || !currViewPostsMap[post.id] || isPostModified || isPostAdded;
			arr.push(
				shouldRevokePost
					? JSON.parse(JSON.stringify(post.realmToObject())) // TODO: CEM-6163 - For the love of god, remove this as soon as possible (change usePost to give the realm cursor instead of map / array) 
					: currViewPostsMap[post.id]
			);
		});

		arr = setCompanyIdAndGroupsToOwnerAndAssignToOnPosts(
			arr,
			projectMembers,
		);
		arr.sort((a, b) => (b.editedAt || b.createdAt || 0) - (a.editedAt || a.createdAt || 0));

		return { map: _.keyBy(arr, 'id'), arr };
	}, [projectMembers, currViewPostsMap]);
	const getRealmPostsMapRef = useRef(getRealmPostsMap);
	useEffect(() => { getRealmPostsMapRef.current = getRealmPostsMap; }, [getRealmPostsMap]);

	const realmPostsListener = useCallback((values, changes) => {
		const didChange = Boolean(changes.insertions.length || changes.deletions.length || changes.modifications.length);

		if (didChange) {
			const { map, arr } = getRealmPostsMapRef.current(values, changes); // using a ref because the function is passed to a listener and otherwise would run with old params
			setCurrViewPostsMap(map);
			setCurrViewPosts(arr);
		}
	}, []);
	
	const updateRealmPosts = useCallback(async () => {
		realmPostsRef.current = await realmInstance.posts.objects('post24').filtered(postsQuery.postsQuery);
		const { arr, map } = getRealmPostsMap(realmPostsRef.current);
		setCurrViewPosts(arr);
		setCurrViewPostsMap(map);

		await realmPostsRef.current.addListener(realmPostsListener);
	}, [postsQuery, getRealmPostsMap]);

	useEffect(() => {
		if (isNative) {
			updateRealmPosts();
			return () => realmPostsRef.current.removeListener(realmPostsListener);
		}
	}, [postsQuery]);

	const getLokiPostsMap = useCallback(() => {
		let nextViewPosts = [];
		if (selectedProjectId && lokiPostsRef.current) {
			const { postsQuery: query, postsInstancesQuery } = postsQuery;
			
			const relevantPostsInstances = lokiPostsInstancesRef.current.cementoFind(postsInstancesQuery);
			nextViewPosts = lokiPostsRef.current.cementoFind(query);
			nextViewPosts = addInstancesValuesToPosts(nextViewPosts, relevantPostsInstances);
			nextViewPosts = setCompanyIdAndGroupsToOwnerAndAssignToOnPosts(nextViewPosts, projectMembers); // TODO: do this directly from loki
			nextViewPosts = addChecklistsAndStagesToPosts(
				nextViewPosts, currentChecklists, currentChecklistInstances, currentStages,
			);
			nextViewPosts.sort((a, b) => (b.editedAt || b.createdAt || 0) - (a.editedAt || a.createdAt || 0));
		}

		return {
			arr: nextViewPosts,
			map: _.keyBy(nextViewPosts, 'id'),
		};
	}, [
		postsQuery,
		selectedProjectId,
		projectMembers,
	]);
	const getLokiPostsMapRef = useRef(getLokiPostsMap);
	useEffect(() => { getLokiPostsMapRef.current = getLokiPostsMap; }, [getLokiPostsMap]);

	const lokiPostsListener = useCallback(collectionName => {
		if (collectionName === 'posts' || collectionName === 'propertyInstances') {
			const { arr, map } = getLokiPostsMapRef.current(); // using a ref because the function is passed to a listener and otherwise would run with old params
			setCurrViewPosts(arr);
			setCurrViewPostsMap(map);
		}
	}, []);

	// didMount
	useEffect(() => {
		let cleanUp;

		if (!isNative) {
			const lokiPosts = lokiInstance.getCollection('posts');
			lokiPostsRef.current = lokiPosts;

			const lokiPostsPropertiesInstances = lokiInstance.getCollection('propertyInstances');
			lokiPostsInstancesRef.current = lokiPostsPropertiesInstances;

			const { arr, map } = getLokiPostsMap();
			setCurrViewPosts(arr);
			setCurrViewPostsMap(map);

			const postsListenerId = uuidv4();
			const instancesListenerId = uuidv4();
			lokiPosts.cementoOn(postsListenerId, lokiPostsListener);
			lokiPostsPropertiesInstances.cementoOn(instancesListenerId, lokiPostsListener);

			cleanUp = () => {
				lokiPosts.cementoOff(postsListenerId);
				lokiPostsPropertiesInstances.cementoOff(instancesListenerId);
			};
		}

		// willUnmount
		return cleanUp;
	}, [postsQuery]);

	// Special query coming from url (only on web for now)
	const cementoQuery = useMemo(() => {
		const { locationSearch } = _postsFilters;
		let query = null;
		if (!isNative) {
			query = decodeFiltersFromSearch(locationSearch, POSTS_FILTER_URL_KEY).cementoQuery; 
		}

		return query;
	}, [_postsFilters.locationSearch]);

	useEffect(() => {
		let nextFilteredPosts = currViewPosts;
		if (cementoQuery) {
			nextFilteredPosts = Object.values(
				getFilteredResults({ cementoQuery, targetList: currViewPosts }).permitted,
			);
		}

		setFilteredPosts(nextFilteredPosts);
	}, [cementoQuery, currViewPosts]);

	return useMemoizedValue({
		filteredPosts, // Is only used on web, it is the filtered posts from the url query (is the same as currViewPosts on native)
		currViewPosts,
		currViewPostsMap,
	});
};

export default usePosts;

/**
 * @callback ChilrenRenderProps
 * @param {{ filteredProps: any[], currViewPosts: any[], currViewPostsMap: { [postId: string]: any } }}
 */
/**
 *
 * @param {{ filters: Filters, children: ChilrenRenderProps }} props
 * @returns {ChilrenRenderProps}
 */
export const PostsHOC = props => {
	const postsProps = usePosts(props.filters);

	return props.children(postsProps);
};
