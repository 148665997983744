import React from 'react';
import Text from './Text';
import _ from 'lodash';

/**
 * 
 * @param {{
 *  label?: string | import('../../../common/app/actions').IntlMessage,
 *  dotStyle?: import('react-native').StyleProp<import('react-native').ViewStyle>,
 *  labelStyle?: import('react-native').StyleProp<import('react-native').TextStyle>,
 *  color?: string,
 * }} props
 * @returns 
 */
const DotStatusText = (props) => {
  const { label, dotStyle, labelStyle, color, containerStyle } = props;

  if (_.isNil(label) || _.isNil(color))
    return null; 

  return (
    <div style={Object.assign({ display: 'flex', alignItems: 'center' }, containerStyle)}>
      <div style={Object.assign({ borderRadius: '50%', backgroundColor: color}, dotStyle)} />
      {Boolean(label) && <Text style={labelStyle}>{label}</Text>}
    </div>
  )
  
}

export default DotStatusText;