export const GET_NOTIFICATIONS   			 	 = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_STARTED   = 'GET_NOTIFICATIONS_STARTED';
export const END_NOTIFICATIONS_LISTENER  = 'END_NOTIFICATIONS_LISTENER';
export const NAVIGATE_BY_NOTIFICATION    = 'NAVIGATE_BY_NOTIFICATION';

export function getProjectNotifications(uid, projectId) {	
	  return ({ dispatch, firebaseDatabase }) => {
	  	const getPromise = async () => {
		  firebaseDatabase().ref('notifications/' + uid + '/' + projectId).orderByChild('createdAt').on('value', function(snapshot) {
			  dispatch({ type: GET_NOTIFICATIONS, payload: {notifications: snapshot.val(), viewerId: uid }, projectId: projectId});
			});		  	
		}

    return {
      type: GET_NOTIFICATIONS_STARTED,
      payload: getPromise()
    };
  };
}

export function endProjectNotificationsListener(uid, projectId) {	
  return ({ firebaseDatabase }) => {
	  firebaseDatabase().ref('notifications/' + uid + '/' + projectId).off('value');
    return {
      type: END_NOTIFICATIONS_LISTENER,
      payload: { projectId }
    };
  };
}