import * as actions from './actions';
import { firebaseActions } from '../lib/redux-firebase';
import EventCounter from './eventCounter';
import { REPLACE_USER } from '../auth/actions';

import { OrderedMap, Map } from 'immutable';
import { Record } from '../transit';

const InitialState = Record({
  userCounter: EventCounter({counter: 0}),
}, 'eventsCounters');

const initialState = new InitialState;

export default function eventsCountersReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  // switch (action.type) {
  //   case actions.GET_EVENTS_COUNTERS: {
  //     if (!action.payload || !action.payload.eventsCounters)
  //       return state;

  //     const { eventsCounters } = action.payload;      
  //     if (eventsCounters.counter)
  //       state = state.set('userCounter', new EventCounter(eventsCounters));
  //     return state;
  //   }
    
  //   case REPLACE_USER + "_SUCCESS": {
  //     return initialState;
  //   }
  // }
 
  return state;
}


