import { Record } from 'immutable';

const Comment = Record({
  createdAt: null,
  createdTS: null,
  id: '',
  owner: null,
  content: null,
  images: null,
  type: null,
  parentType: null,
  data: null,
  isLocal: null,
});

export default Comment;
