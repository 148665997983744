import React from "react";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { Router, Route, Switch, withRouter, Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import {
  saveURLParams,
  saveUIParams,
  clearAllUrlParams,
} from "../../../common/ui/actions";
import { envParams } from "../../../common/configureMiddleware";
import {
  startLoading,
  hideLoading,
  setLang,
} from "../../../common/app/actions";
import CompanySafetyTable from "../CompanySafety/CompanySafetyTable";
import { HeaderLinks } from "../../components";
import { CompanyContext } from "../../../common/companies/contexts";
import { navigateToProject } from "../../../common/projects/funcs";
import PropertyAnalytics from "../Properties/PropertyAnalytics";
import { ProjectContext } from "../../../common/projects/contexts";
import _ from "lodash";
import CompanyQATable from "../CompanyQuality/CompanyQualityTable";
import { platformActions } from "../../../common/platformActions";
import systemMessages from "../../../common/app/systemMessages";
import { v4 as uuidv4 } from "uuid";

import theme from "../../assets/css/theme";
import propertiesMessages from "../../../common/propertiesTypes/propertiesMessages";
import Text from "../../components/CementoComponents/Text";
import CompanyViewManager from "./CompanyViewManager";
import companiesMessages from "../../../common/companies/companiesMessages";
import CompanyHomePage from "./CompanyHomePage";

const DEFAULT_COMPANY_PAGE = 'home';

class CompanyContainerPage extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.handleUnmount = this.handleUnmount.bind(this);
    this.handleUnmountBeforeBrowserExit =
      this.handleUnmountBeforeBrowserExit.bind(this);
    this.setHeaderParams = this.setHeaderParams.bind(this);
    this.updateLanguageIfDiff = this.updateLanguageIfDiff.bind(this);
    this.handleNavigateToProject = this.handleNavigateToProject.bind(this);
    this.fetchCompanyTablesData = this.fetchCompanyTablesData.bind(this);
    this.state = {};
  }

  handleUnmount(beforeBrowserExit) {
    window.removeEventListener(
      "beforeunload",
      this.handleUnmountBeforeBrowserExit
    );
  }

  handleUnmountBeforeBrowserExit() {
    this.handleUnmount(true);
  }

  componentWillUnmount() {
    this.handleUnmount();
  }

  componentDidMount() {
    const { hideLoading } = this.props;
    hideLoading();
  }

  UNSAFE_componentWillMount() {
    window.addEventListener(
      "beforeunload",
      this.handleUnmountBeforeBrowserExit
    );
    let { urlParams, saveURLParams, match } = this.props;
    let containerUrl = match.getNested(["params", "url"]);
    let selectedCompanyId = match.getNested(["params", "selectedCompanyId"]);
    let contentType = match.getNested(["params", "contentType"]);
    urlParams = Object.assign(
      {},
      { containerUrl, selectedCompanyId, scope: "company", contentType }
    );
    saveURLParams(urlParams);
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { urlParams, saveURLParams, history, viewer, match } = this.props;
    let containerUrl = history.location.pathname;
    let selectedCompanyId = match.getNested(["params", "selectedCompanyId"]);
    let contentType = match.getNested(["params", "contentType"]);
    let scope = urlParams.get("scope");

    if (this.props.isValDiff(nextProps, ["urlParams", "scope"]))
      if (nextProps.getNested(["urlParams", "scope"]) == "project") {
        let selectedProjectId = nextProps.getNested(
          ["urlParams", "selectedProjectId"],
          "_"
        );
        nextProps.history.push(
          `/main/projectContainerPage/${selectedProjectId}/_`
        );
      }

    if (viewer && (!selectedCompanyId || selectedCompanyId == "_")) {
      let userSelectedCompanyId = viewer.companyId;
      saveURLParams({
        scope,
        containerUrl,
        selectedCompanyId: userSelectedCompanyId,
        contentType,
      });
      history.push(containerUrl.replace("_", userSelectedCompanyId));
    }

    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    this.updateLanguageIfDiff(nextProps);

    const { selectedCompanyId } = this.state;
    let newStateChanges = {};

    if (props.viewer != nextProps.viewer)
      newStateChanges.isAdmin = Boolean(
        nextProps.getNested(["viewer", "adminMode"], 0) > 0
      );

    if (
      !selectedCompanyId ||
      nextProps.isValDiff(props, ["match", "params", "selectedCompanyId"])
    ) {
      newStateChanges.selectedCompanyId = nextProps.getNested([
        "match",
        "params",
        "selectedCompanyId",
      ]);
    }

    if (props.isValDiff(nextProps, ["match", "params", "contentType"]))
      nextProps.saveURLParams({
        contentType: nextProps.getNested(["match", "params", "contentType"]),
      });

    if (props.isValDiff(nextProps, ["match", "params", "section"]))
      nextProps.saveURLParams({
        section: nextProps.getNested(["match", "params", "section"]),
      });

    if (
      this.props.configurations != nextProps.configurations ||
      this.props.getNested(["match", "params", "contentType"]) !=
        nextProps.getNested(["match", "params", "contentType"])
    ) {
      let contentType = nextProps.getNested(["match", "params", "contentType"]);
      newStateChanges.contentType = contentType;
      newStateChanges.isFeatureActive = nextProps.getNested(
        [
          "configurations",
          "features",
          "companyView",
          ...(contentType == "settings"
            ? ["settings", "members"]
            : [contentType]),
          "isActive",
        ],
        false
      );
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  updateLanguageIfDiff(nextProps) {
    const { setLang } = nextProps;
    if (!this.waitForIntlProviderRerender && (nextProps.project || {}).lang) {
      const currLang = nextProps.lang;
      const newLang = nextProps.getNested(["project", "lang"], "en");
      if (currLang != newLang) {
        this.waitForIntlProviderRerender = true;
        setLang(newLang);
      }
    }
  }

  setHeaderParams(headerParams) {
    const { match, uiParams, menus, saveUIParams } = this.props;
    let contentType = match.params.contentType;
    let isOnlySideNav = Boolean(
      (!headerParams || !headerParams.headerComponent) &&
        menus &&
        menus[contentType]
    );
    if (uiParams.getNested(["onlySideNav"]) != isOnlySideNav)
      saveUIParams({ onlySideNav: isOnlySideNav });
    this.setState({ headerParams });
  }

  handleNavigateToProject(projectId, innerPath) {
    const {
      saveURLParams,
      clearAllUrlParams,
      history,
      startLoading,
      enterProject,
    } = this.props;

    if (!projectId) return null;

    navigateToProject(this.props, projectId, innerPath, false, true);
  }

  async fetchCompanyTablesData(subject, newSelectedCompanyId, startTS, endTS) {
    const { selectedCompanyId } = this.state;
    const { startLoading, hideLoading, viewer, userId, isPdfMode } = this.props;
    const { apiServer } = envParams;

    let companyId = newSelectedCompanyId || selectedCompanyId;
    let viewerId = isPdfMode ? userId : viewer.id;

    if (
      !(subject == "safety" || subject == "quality") ||
      !companyId ||
      !viewerId
    )
      return null;

    let operationId = uuidv4();
    startLoading({
      title: systemMessages.loadingMessage,
      overlay: true,
      operationId,
    });
    let tablesData;
    let url = `${apiServer}/v1/gateway/${subject}?companyId=${companyId}&viewerId=${viewerId}`;
    if (!_.isNil(startTS)) url += `&startTS=${startTS}`;
    if (!_.isNil(endTS)) url += `&endTS=${endTS}`;
    let res;
    try {
      let resp = await platformActions.net.fetch(url);
      let jsonResp = await resp.json();
      if (!jsonResp) res = null;
      res = jsonResp;
    } catch (error) {
      console.error(error);
      res = null;
    } finally {
      hideLoading(operationId);
      tablesData = _.isObject(res) ? res : {};
      tablesData = _.entries(tablesData).map(([key, value]) => ({
        id: key,
        ...value,
      }));
    }
    return tablesData;
  }

  render() {
    const {
      menus,
      viewer,
      intl,
      tradesMap,
      formUniversalIdsMap,
      rtl,
      isPdfMode,
    } = this.props;
    const {
      headerParams,
      selectedCompanyId,
      isFeatureActive,
      contentType,
      isAdmin,
    } = this.state;

    if (!isFeatureActive && !isAdmin && contentType && contentType != "_")
      return (
        <>
          <div
            style={{
              justifyContent: "start",
              alignItems: "center",
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <div style={{ flexDirection: "column", margin: theme.margin * 3 }}>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: theme.fontSizeH3,
                  color: theme.brandPrimary,
                }}
              >
                {_.get(companiesMessages, [
                  "notPermittedFeature",
                  "companyView",
                  `${contentType}Title`,
                ])}
              </Text>
              <br />
              <Text style={{ fontSize: theme.fontSizeH4, lineHeight: 1.4 }}>
                {_.get(companiesMessages, [
                  "notPermittedFeature",
                  "companyView",
                  `${contentType}Content`,
                ])}
              </Text>
            </div>
          </div>
        </>
      );

    return (
      <div
        style={{
          display: "flex",
          flexDirection:
            headerParams && headerParams.headerComponent ? "column" : "row",
          flex: 1,
        }}
      >
        <HeaderLinks
          key="secondaryHeader"
          defaultSelection={null}
          headerParams={headerParams}
          routingMode={false}
          menus={menus}
          menuMode={true}
          intl={intl}
          isSecondary={true}
        />

        <Switch>
          {/* REDIRECTS // FIXME: fix the commented redirect */}
          <Route
            exact
            path="/main/companyContainerPage"
            render={() => (
              <Redirect
                to={`/main/companyContainerPage/${selectedCompanyId}/home`}
              />
            )}
          />
          <Route
            exact
            path="/main/companyContainerPage/:selectedCompanyId/settings"
            render={() => (
              <Redirect
                to={`/main/companyContainerPage/${selectedCompanyId}/settings/analytics/members`}
              />
            )}
          />
          {/* <Route exact path="/main/companyContainerPage/:selectedCompanyId/settings/analytics/:contentType" render={() => <Redirect to={`/main/companyContainerPage/${selectedCompanyId}/settings/analytics/:contentType`} />} /> */}
          {/* <Route exact path="/main/companyContainerPage/:selectedCompanyId/companies" render={() => <Redirect to={`/main/companyContainerPage/${selectedCompanyId}/companies/analytics`} />} /> */}
          <Route
            exact
            path="/main/companyContainerPage/:selectedCompanyId/safety"
            render={() => (
              <Redirect
                to={`/main/companyContainerPage/${selectedCompanyId}/safety/analytics`}
              />
            )}
          />
          <Route
            exact
            path="/main/companyContainerPage/:selectedCompanyId"
            render={() => (
              <Redirect
                to={`/main/companyContainerPage/${selectedCompanyId}/safety/analytics`}
              />
            )}
          />
          <Route
            path="/main/companyContainerPage/:selectedCompanyId/home"
            render={() => <CompanyHomePage selectedCompanyId={selectedCompanyId} />}
          />
          <Route
            path="/main/companyContainerPage/:selectedCompanyId/settings/analytics/:reportId"
            render={(props) => (
              <PropertyAnalytics
                onProjectClick={this.handleNavigateToProject}
                setHeaderParams={this.setHeaderParams}
                contentType={"settings"}
                history={props.history}
                location={props.location}
                match={props.match}
              />
            )}
          />
          {/* <Route path="/main/companyContainerPage/:selectedCompanyId/settings/analytics/companies" render={props => (<PropertyAnalytics onProjectClick={this.handleNavigateToProject} setHeaderParams={this.setHeaderParams} contentType={"settings"} history={props.history} location={props.location} match={props.match} />)} /> */}
          <Route
            path="/main/companyContainerPage/:selectedCompanyId/safety/analytics/:reportId"
            render={(props) => (
              <PropertyAnalytics
                onProjectClick={this.handleNavigateToProject}
                setHeaderParams={this.setHeaderParams}
                contentType={"safety"}
                history={props.history}
                location={props.location}
                match={props.match}
              />
            )}
          />
          <Route
            path="/main/companyContainerPage/:selectedCompanyId/safety/analytics"
            render={(props) => (
              <CompanySafetyTable
                onProjectClick={this.handleNavigateToProject}
                history={props.history}
                match={props.match}
                selectedCompanyId={selectedCompanyId}
                rtl={rtl}
                formUniversalIdsMap={formUniversalIdsMap}
                isPdfMode={isPdfMode}
                setHeaderParams={this.setHeaderParams}
                viewer={viewer}
                fetchCompanyTablesData={this.fetchCompanyTablesData}
              />
            )}
          />

          <Route
            path="/main/companyContainerPage/:selectedCompanyId/qa"
            render={(props) => (
              <CompanyQATable
                onProjectClick={this.handleNavigateToProject}
                subject={"qa"}
                history={props.history}
                match={props.match}
                selectedCompanyId={selectedCompanyId}
                rtl={rtl}
                tradesMap={tradesMap}
                isPdfMode={isPdfMode}
                setHeaderParams={this.setHeaderParams}
                viewer={viewer}
                fetchCompanyTablesData={this.fetchCompanyTablesData}
              />
            )}
          />
          <Route
            path="/main/companyContainerPage/:selectedCompanyId/companyViewManager"
            render={(props) => (
              <CompanyViewManager
                subject={"companyViewManager"}
                history={props.history}
                match={props.match}
                selectedCompanyId={selectedCompanyId}
                rtl={rtl}
                viewer={viewer}
                setHeaderParams={this.setHeaderParams}
              />
            )}
          />
          <Route
            path="/main/companyContainerPage/:selectedCompanyId"
            render={({ match }) => (
              <Redirect
                to={`/main/companyContainerPage/${_.get(
                  match,
                  ["params", "selectedCompanyId"],
                  "_"
                )}/${DEFAULT_COMPANY_PAGE}`}
              />
            )}
          />
          <Redirect to={`/main/companyContainerPage/_/${DEFAULT_COMPANY_PAGE}`} />
        </Switch>
      </div>
    );
  }
}

CompanyContainerPage = injectIntl(CompanyContainerPage);
CompanyContainerPage = withRouter(CompanyContainerPage);
const enhance = compose(
  connectContext(CompanyContext.Consumer),
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      projectsMap: state.projects.map,
      lang: state.app.lang,
      rtl: state.app.rtl,
      urlParams: state.ui.urlParams,
      uiParams: state.ui.uiParams,
      formUniversalIdsMap: state.quasiStatics.formUniversalIdsMap,
      tradesMap: state.trades.map,
      viewer: state.users.viewer,
    }),
    {
      startLoading,
      hideLoading,
      saveURLParams,
      clearAllUrlParams,
      saveUIParams,
      setLang,
    }
  )
);
export default enhance(CompanyContainerPage);
