import configureStorage from "./configureStorage";
import { platformActions, init } from "./platformActions";
import { setPlatformStandardInput } from "./app/platformComponents/PlatformStandardInput";
import createLoggerMiddleware from "redux-logger";
import { v4 as uuidv4 } from "uuid";
import validate from "./validate";
import ExtraError from "./lib/errors/extraError";
import * as rlmSchema from "./lib/realm/schema.js";
import { lokiCollections } from "./lib/loki/collections";
import moment from "moment";
import _ from "lodash";
import { setPlatformContainer } from "./app/platformComponents/PlatformContainer";
import { setPlatformText } from "./app/platformComponents/PlatformText";
import { setPlatformTradeBadge } from "./app/platformComponents/PlatformTradeBadge";
import { setPlatformTheme } from "./platformTheme";
import { getAppLang, getOriginalErrorMessage } from "./app/funcs";
import { removeEmpty} from './lib/utils/utils'

let sentry = null;
let firebase = null;
let logRocket = null;
let firebaseDeps = null;
let isMixpanelInit = false;
let realmInstance = null;
/** @type {import('lokijs')} @instance */
let lokiInstance = null;
export let envParams = null;

const logsHost = "listener.logz.io";
const logsToken = "dnYOrcQZciFpcYVtbdhOxAHaOgSPIBvN";
const isLocalHost = false;

//? try{action({ ...deps, dispatch, getState })}catch(err){console.log('injectMiddleware ERROR' + err)}
// Like redux-thunk but with dependency injection.
const injectMiddleware =
  (deps) =>
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    try {
      getAppState = getState;
      getDispatch = () => dispatch;
      apiListenersDeps = {
        dispatch,
        fetchCompressedDataFromServer,
        errorReport,
        firebaseDatabase: firebaseDeps.firebaseDatabase,
      };

      if (typeof action != "function")
        writeLog("info", action ? action.type : null, "ActionsTrack", {
          type: action ? action.type : null,
        });
      return next(
        typeof action === "function"
          ? action({ ...deps, errorReport, dispatch, getState })
          : action
      );
    } catch (err) {
      var type = null;
      if (action && action.type) type = action.type;
      if (
        process.env.NODE_ENV == "production" &&
        !((envParams || {}).deploy_env == "beta")
      )
        errorReport(type, err, action);
      else {
        errorReport(type, err, action);
        console.warn(err);
        if (action.type) console.warn("type:" + action.type);
        if (action.payload)
          console.warn(
            "payload:" + JSON.stringify(action.payload).slice(0, 200)
          );
        if (
          err.metadata &&
          err.metadata.errorMessage &&
          err.metadata.errorMessage != err.toString()
        )
          console.warn(err.metadata.errorMessage);
      }
    }
  };

const errorReport = (type, error, action) => {
  if (
    process.env.NODE_ENV != "production" &&
    !((envParams || {}).deploy_env == "beta")
  )
    console.warn("Error on action - ", type, action, error);
  if (sentry) {
    //platformActions.sentry.notify(error, {function: type});

    const originalErrorMessage = getOriginalErrorMessage(error);
    const shouldReport = originalErrorMessage && originalErrorMessage.indexOf('Network request failed') === -1;
    if (shouldReport) {
      if (error instanceof ExtraError) {
        // TODO: Replace this with typeof prototype, this is a workaround
        if (error.innerError instanceof ExtraError)
          platformActions.sentry.notify(error.innerError.innerError, {
            function: type,
            ...(error.innerError.metadata || {}),
            "errorParent:": error,
          });
        else if (error.innerError instanceof Error)
          platformActions.sentry.notify(error.innerError, {
            function: type,
            ...(error.metadata || {}),
          });
        else
          platformActions.sentry.notify(
            new Error("error type is not of error - 10001")
          );
      } else if (error instanceof Error) {
        platformActions.sentry.notify(error);
      }
      else {
        platformActions.sentry.notify(
          new Error("error type is not of error - 10002")
        );
      }
    }

    type &&
      sentry.addBreadcrumb({
        message: (type + "_ERROR").slice(0, 30),
        data: error,
      });
  }

  writeLog("error", type + "_ERROR", "ActionsTrack", {
    error,
    type: action ? action.type : null,
  });
};

const fetchCompressedDataFromServer = async (
  projectId,
  lastUpdateTS,
  apiName,
  subjectName,
  params
) => {
  if (!projectId) return {};

  let response;
  let url;
  try {
    url = `${envParams.apiServer}/v1/${apiName}?projectId=${projectId}&lean=true`;
    if (lastUpdateTS) url += `&lastUpdateTS=${lastUpdateTS}`;
    if (subjectName) url += `&subjectName=${subjectName}`;
    if (params)
      _.forIn(
        params,
        (val, key) =>
          (url += `&${key}=${_.isArray(val) ? JSON.stringify(val) : val}`)
      );

    if (process.env.NODE_ENV !== "production")
      console.log("fetchCompressedDataFromServer -> url", url);

    let resp = await platformActions.net.fetch(url);

    response = await resp.json();
  } catch (err) {
    console.warn(
      "error on apiName:",
      apiName,
      "projectId:",
      projectId,
      "lastUpdateTS:",
      lastUpdateTS
    );
    console.warn(err);
    return null;
  }

  return response;
};

var platform = null;
var deviceAppVersion = null;
var userLogsData = null;
var userSuperProps = null;
var logsBulk = [];
var bulkIndex = 0;

const initUserLogsData = (userData) => {
  userLogsData = userData;
};
const registerUserScopeLogs = (superProps) => {
  let props = superProps;
  if (userSuperProps) props = Object.assign({}, userSuperProps, superProps);
  userSuperProps = props;
  return userSuperProps;
};
const writeLog = async (level, message, messageType, extraInfo, forceSend) => {
  if (process.env.NODE_ENV !== "production" && level)
    console.log(level, message, messageType, extraInfo);
  return null; // TODO: We've stopped working with Logz.io. We should add new logging instead of make that one working again
  if (!userLogsData) return null;

  var obj = null;
  try {
    if (level && message) {
      if (level != "error" && level != "info" && level != "debug")
        level = "error";

      obj = Object.assign({}, userSuperProps || {}, extraInfo || {});
      obj.user = userLogsData;
      obj.env =
        process.env.NODE_ENV == "production"
          ? "Prod"
          : process.env.NODE_ENV == "development"
          ? "Dev"
          : "Staging";
      obj.level = level;
      obj.message = message;
      obj.messageType = messageType;
      obj.source = "clients";
      obj.deviceAppVersion = deviceAppVersion;
      obj.platform = platform;
      if (extraInfo && extraInfo.error)
        obj.errorStackTrace = extraInfo.error.stack;
      //obj.stack = new Error().stack.replace("Error", "Stack:");
    }

    if ((forceSend || logsBulk.length == 100) && logsBulk.length > 0) {
      let bulkString = "";
      logsBulk.forEach((curr) => (bulkString += JSON.stringify(curr) + "\n"));
      await platformActions.net.fetch(
        "http://" + logsHost + ":8070/?token=" + logsToken,
        {
          method: "POST",
          body: bulkString,
          headers: {
            "Content-Type": "multipart/form-data",
            "Content-Transfer-Encoding": "BASE64",
          },
        }
      );

      logsBulk = [];
      bulkIndex++;
    }

    if (obj) {
      obj.bulkIndex = bulkIndex;
      obj.bulkMessageIndex = logsBulk.length;
      logsBulk.push(obj);
    }
  } catch (err) {
    console.warn(err);
  }
};

// Like redux-promise-middleware but without the noise.
const promiseMiddleware =
  (deps) =>
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const { type, payload: promise } = action;
    const isPromise = promise && typeof promise.then === "function";
    if (!isPromise)
      return next(action);
    
    const createAction = (suffix, payload) => ({
      type: `${type}_${suffix}`,
      meta: { action },
      payload,
    });
    next(createAction("START"));

    if (type) {
      let typeStart = type + "_START";
      if (sentry) sentry.addBreadcrumb({ message: typeStart.slice(0, 30) });
      //writeLog('info', typeStart, 'ActionsTrack', { type: typeStart });
    }

    const onFulfilled = (value) => {
      dispatch(createAction("SUCCESS", value));
      if (type) {
        let typeSuccess = type + "_SUCCESS";
        if (sentry)
          sentry.addBreadcrumb({
						message: typeSuccess.slice(0, 30),
						data: { value },
					});
        //writeLog('info', typeSuccess, 'ActionsTrack', { type: typeSuccess });
      }

      return value;
    };
    const onRejected = (error) => {
      errorReport(type, error, action);
      dispatch(createAction("ERROR", error));
    };
    return promise.then(onFulfilled, onRejected);
  };

const sendNotification = (path, params) => {
  // TODO: Remove this and add the id at the server side once we remove this method to be as a functino from firebase
  params.id = uuidv4();
  if (!envParams) return;
  try {
    if (
      getAppState &&
      getAppState() &&
      getAppState().getNested(["app", "isConnected"], false)
    )
      platformActions.net
        .fetch(envParams.apiServer + "/v1/services/notifications/" + path, {
          ignoreError: true,
          method: "POST",
          body: JSON.stringify(params),
        })
        .catch((error) => {
          platformActions.sentry.notify(error, {
            function: "sendNotification",
            params,
          });
        });
    else
      console.warn(
        "Device is offline, cannot fetch ",
        envParams.apiServer,
        "/v1/services/notifications/",
        path
      );
  } catch (err) {
    console.log("Notification error:" + err);
  }
};



const checklistItemsInstanceSchema = {
  schema: [
    rlmSchema.lastUpdateTSSchema,
    rlmSchema.checklistItemsInstanceSchema,
    rlmSchema.extraDataFieldSchema,
  ],
  schemaVersion: 12,
};
const postsSchema = {
  schema: [
    rlmSchema.postsSchema,
    rlmSchema.locationIdSchema,
    rlmSchema.locationSchema,
    rlmSchema.taggedCompanySchema,
    rlmSchema.commentDataSchema,
    rlmSchema.commentSchema,
    rlmSchema.tradeSchema,
    rlmSchema.slimChecklistItemInstanceSchema,
    rlmSchema.imagesSchema,
    rlmSchema.slimUserSchema,
    rlmSchema.memberSchema,
    rlmSchema.lastUpdateTSSchema,
    rlmSchema.subCategorySchema,
    rlmSchema.requiredActionSchema,
    rlmSchema.fineSchema,
    rlmSchema.attachmentsSchema,
    rlmSchema.postRefSchema,
  ],
  schemaVersion: rlmSchema.postsSchema.schemaVersion,
};

export async function loadRealmInstance() {
  if (!realmInstance || !realmInstance.posts) return;
  var Realm = platformActions.localDB.getInstance();
  var postRealmAction = new Realm({
    path: "posts.realm",
    schemaVersion: postsSchema.schemaVersion,
    schema: postsSchema.schema,
  });
  var retry_postRealmAction = new Realm({
    path: "retry_posts.realm",
    schemaVersion: postsSchema.schemaVersion,
    schema: postsSchema.schema,
  });
  var chkRealmAction = new Realm({
    path: "checklistItemsInstances.realm",
    schemaVersion: checklistItemsInstanceSchema.schemaVersion,
    schema: checklistItemsInstanceSchema.schema,
  });
  var retry_chkRealmAction = new Realm({
    path: "retry_checklistItemsInstances.realm",
    schemaVersion: checklistItemsInstanceSchema.schemaVersion,
    schema: checklistItemsInstanceSchema.schema,
  });
  var piRealmAction = new Realm({
    path: "propertyInstances.realm",
    schemaVersion: rlmSchema.propertyInstanceSchema.schemaVersion,
    schema: [rlmSchema.lastUpdateTSSchema, rlmSchema.propertyInstanceSchema],
  });
  var empRealmAction = new Realm({
    path: "employees.realm",
    schemaVersion: rlmSchema.employeeSchema.schemaVersion,
    schema: [rlmSchema.lastUpdateTSSchema, rlmSchema.employeeSchema],
  });
  var equRealmAction = new Realm({
    path: "equipment.realm",
    schemaVersion: rlmSchema.equipmentSchema.schemaVersion,
    schema: [rlmSchema.lastUpdateTSSchema, rlmSchema.equipmentSchema],
  });
  var retryObjectsRealmAction = new Realm({
    path: "retryObjects.realm",
    schemaVersion: rlmSchema.retryObjectsSchema.schemaVersion,
    schema: [rlmSchema.retryObjectsSchema],
  });

  var realmInstancasArray = await Promise.all([
    postRealmAction,
    chkRealmAction,
    piRealmAction,
    empRealmAction,
    equRealmAction,
    retry_postRealmAction,
    retry_chkRealmAction,
    retryObjectsRealmAction,
  ]);
  realmInstance.posts = realmInstancasArray[0];
  realmInstance.checklistItemsInstances = realmInstancasArray[1];
  realmInstance.propertyInstances = realmInstancasArray[2];
  realmInstance.employees = realmInstancasArray[3];
  realmInstance.equipment = realmInstancasArray[4];
  realmInstance.retry_posts = realmInstancasArray[5];
  realmInstance.retry_checklistItemsInstances = realmInstancasArray[6];
  realmInstance.retryObjects = realmInstancasArray[7];
}

let utilsConfigObject = {};
export const getUtilsConfigObject = () => {
  return utilsConfigObject;
};

const enhancePlatformActions = (platformActions) => {
  let enhancedPlatformActions = Object.assign({}, platformActions, {
    app: Object.assign({}, platformActions.app),
  });

  enhancedPlatformActions.app.isWeb = (() => {
    let isSet = false;
    let flag;

    return () => {
      if (!isSet) {
        flag = platformActions.app.getPlatform() === "web";
        isSet = true;
      }

      return flag;
    };
  })();

  enhancedPlatformActions.app.isNative = (() => {
    let isSet = false;
    let isNative;

    return () => {
      if (!isSet) {
        isNative = platformActions.app.getPlatform() !== "web";
        isSet = true;
      }

      return isNative;
    };
  })();

  enhancedPlatformActions.app.isAndroid = (() => {
    let isSet = false;
    let flag;

    return () => {
      if (!isSet) {
        flag = platformActions.app.getPlatform() === "android";
        isSet = true;
      }

      return flag;
    };
  })();

  enhancedPlatformActions.app.isIOS = (() => {
    let isSet = false;
    let flag;

    return () => {
      if (!isSet) {
        flag = platformActions.app.getPlatform() === "ios";
        isSet = true;
      }

      return flag;
    };
  })();

  enhancedPlatformActions.app.getLang = getAppLang;

  return enhancedPlatformActions;
};

export default function configureMiddleware(
  initialState,
  platformDeps,
  platformMiddleware,
  inPlatformActions,
  standardInputComponent,
  containerComponent,
  textComponent,
  tradeComponent,
  theme
) {
  init(enhancePlatformActions(inPlatformActions), initialState.config);
  setPlatformStandardInput(standardInputComponent);
  setPlatformContainer(containerComponent);
  setPlatformText(textComponent);
  setPlatformTradeBadge(tradeComponent);
  setPlatformTheme(theme);

  //setInterval(function() { writeLog(null, null, null, null, true) }, 5000);
  deviceAppVersion = platformActions.app.getVersion();
  platform = platformActions.app.getPlatform();

  if (!logRocket && platform == "web") {
    logRocket = platformActions.LogRocket.getLogRocket();
    if (process.env.NODE_ENV == "production") logRocket.init("6jlpwp/cemento");
  }

  if (!sentry) {
    sentry = platformActions.sentry.getSentry();

    platformActions.mixpanel.init(initialState.config.mixpanel, () => {
      isMixpanelInit = true;
    });
    userSuperProps = { platform: platform };
  }

  if (!firebase) {
    firebase = platformActions.firebase.getFirebase();
    if (platform == "web")
      firebase.initializeApp(initialState.config.firebase);
  }

  if (!firebaseDeps) {
    // Lazy init.
    firebaseDeps = {
      firebase: firebase.database().ref(),
      firebaseAuth: firebase.auth,
      firebaseAnalytics: firebase.analytics ? firebase.analytics() : null,
      firebaseDatabase: firebase.database,
      firebaseFirestore: firebase.firestore,
      firebaseStorage: firebase.storage,
      firebaseMessaging: firebase.messaging,
    };
  }

  if (platform == "web" && !lokiInstance) {
    lokiInstance = platformActions.localDB.getInstance();
    window.lokiInstance = lokiInstance;
    lokiCollections.forEach((c) => lokiInstance.addCollection(c));
  }

  if (platform != "web" && !realmInstance) {
    realmInstance = {};

    platformActions.localDB
      .getInstance()
      .open({
        schema: postsSchema.schema,
        schemaVersion: postsSchema.schemaVersion,
        migration: (oldRealm, newRealm) => {
          // only apply this change if upgrading to schemaVersion 1
        },
      })
      .then((realm) => {
        realmInstance.posts = realm;
      });

    platformActions.localDB
      .getInstance()
      .open({
        path: "retry_posts.realm",
        schema: postsSchema.schema,
        schemaVersion: postsSchema.schemaVersion,
        migration: (oldRealm, newRealm) => {
          // only apply this change if upgrading to schemaVersion 1
        },
      })
      .then((realm) => {
        realmInstance.retry_posts = realm;
      });

    platformActions.localDB
      .getInstance()
      .open({
        path: "checklistItemsInstances.realm",
        schemaVersion: checklistItemsInstanceSchema.schemaVersion,
        schema: checklistItemsInstanceSchema.schema,
        migration: (oldRealm, newRealm) => {
          // let allChecklistInstances = newRealm.objects('checklistItemsInstance1');
          // let allLastUpdateTS = newRealm.objects('lastUpdateTS').filtered('type = "checklistItemsInstance"');
          // newRealm.delete(allLastUpdateTS);
          // newRealm.delete(allChecklistInstances);
        },
      })
      .then((realm) => {
        realmInstance.checklistItemsInstances = realm;
      });

    platformActions.localDB
      .getInstance()
      .open({
        path: "retry_checklistItemsInstances.realm",
        schemaVersion: checklistItemsInstanceSchema.schemaVersion,
        schema: checklistItemsInstanceSchema.schema,
        migration: (oldRealm, newRealm) => {
          // let allChecklistInstances = newRealm.objects('checklistItemsInstance1');
          // let allLastUpdateTS = newRealm.objects('lastUpdateTS').filtered('type = "checklistItemsInstance"');
          // newRealm.delete(allLastUpdateTS);
          // newRealm.delete(allChecklistInstances);
        },
      })
      .then((realm) => {
        realmInstance.retry_checklistItemsInstances = realm;
      });

    platformActions.localDB
      .getInstance()
      .open({
        path: "employees.realm",
        schemaVersion: rlmSchema.employeeSchema.schemaVersion,
        schema: [rlmSchema.lastUpdateTSSchema, rlmSchema.employeeSchema],
        migration: (oldRealm, newRealm) => {
          console.log("START employees migration");
          let migrationCounter = 0;
            if (oldRealm.schemaVersion < 9) {
              const oldObjects = oldRealm.objects(rlmSchema.employeeSchema.name);
              const newObjects = newRealm.objects(rlmSchema.employeeSchema.name);
              // loop through all objects and set the name property in the new schema
              console.log("oldObjects.length before migration", oldObjects.length);
              for (let i = 0; i < oldObjects.length; i++) {
                if (!oldObjects[i].projectId_objId) {
                  console.log('oldObjects[i].projectId_objId not found', oldObjects[i].projectId, oldObjects[i].id);
                }
                if (oldObjects[i].projectId && oldObjects[i].id) {
                  migrationCounter++;
                  newObjects[i].projectId_objId = oldObjects[i].projectId + "_" + oldObjects[i].id;
                } else
                  console.log('Wrong data', oldObjects[i].projectId ,oldObjects[i].id);
              }
          }
          
          console.log("end employees migration ", migrationCounter);
        },
      })
      .then((realm) => (realmInstance.employees = realm));

    platformActions.localDB
      .getInstance()
      .open({
        path: "equipment.realm",
        schemaVersion: rlmSchema.equipmentSchema.schemaVersion,
        schema: [rlmSchema.lastUpdateTSSchema, rlmSchema.equipmentSchema],
        migration: (oldRealm, newRealm) => {
          console.log("START equipment migration");
          let migrationCounter = 0;
            if (oldRealm.schemaVersion < 8) {
              const oldObjects = oldRealm.objects(rlmSchema.equipmentSchema.name);
              const newObjects = newRealm.objects(rlmSchema.equipmentSchema.name);
              // loop through all objects and set the name property in the new schema
              console.log("oldObjects.length before migration", oldObjects.length);
              for (let i = 0; i < oldObjects.length; i++) {
                if (!oldObjects[i].projectId_objId) {
                  console.log('oldObjects[i].projectId_objId not found', oldObjects[i].projectId, oldObjects[i].id);
                }
                if (oldObjects[i].projectId && oldObjects[i].id) {
                  migrationCounter++;
                  newObjects[i].projectId_objId = oldObjects[i].projectId + "_" + oldObjects[i].id;
                } else
                  console.log('Wrong data', oldObjects[i].projectId ,oldObjects[i].id);
              }
          }
          
          console.log("end equipment migration ", migrationCounter);
        },
      })
      .then((realm) => (realmInstance.equipment = realm));

    platformActions.localDB
      .getInstance()
      .open({
        path: "propertyInstances.realm",
        schemaVersion: rlmSchema.propertyInstanceSchema.schemaVersion,
        schema: [
          rlmSchema.lastUpdateTSSchema,
          rlmSchema.propertyInstanceSchema,
        ],
        migration: (oldRealm, newRealm) => {
          //   if (oldRealm.schemaVersion < 3) {
          //     const oldObjects = oldRealm.objects('propertyInstance1');
          //     const newObjects = newRealm.objects('propertyInstance1');
          //     // loop through all objects and set the name property in the new schema
          //     for (let i = 0; i < oldObjects.length; i++) {
          //       newObjects[i].subjectName = oldObjects.instanceDataType;
          //       newObjects[i].parentId = oldObjects.locationId;
          //       newObjects[i].propId = oldObjects.typeId;
          //     }
          // }

          console.log("in propertyInstances migration");
        },
      })
      .then((realm) => (realmInstance.propertyInstances = realm));

    platformActions.localDB
      .getInstance()
      .open({
        path: "retryObjects.realm",
        schemaVersion: rlmSchema.retryObjectsSchema.schemaVersion,
        schema: [rlmSchema.retryObjectsSchema],
        migration: (oldRealm, newRealm) => {
          console.log("in retryObjects migration");
        },
      })
      .then((realm) => (realmInstance.retryObjects = realm));
  }

  if (!envParams)
    envParams = {
      flavour: initialState.config.flavour,
      servicesServer: initialState.config.servicesServer,
      apiServer: !isLocalHost
        ? initialState.config.apiServer
        : "http://127.0.0.1:8080",
      pdfServer: initialState.config.pdfServer,
      notificationServer: initialState.config.notificationServer,
      auth0: initialState.config.auth0,
      isProduction: initialState.config.isProduction,
      deploy_env: initialState.config.deploy_env,
    };

  const { STORAGE_SAVE, storageEngine, storageMiddleware } = configureStorage(
    initialState,
    platformDeps.createStorageEngine
  );

  const middleware = [
    injectMiddleware({
      ...platformDeps,
      ...firebaseDeps,
      ...apiListenersDeps,
      getUid: () => uuidv4(),
      now: () => Date.now(),
      removeEmpty,
      initUserLogsData,
      platformActions,
      writeLog,
      sendNotification,
      apiServer: envParams.apiServer,
      notificationServer: envParams.notificationServer,
      auth0: envParams.auth0,
      storageEngine,
      validate,
      realmInstance,
      lokiInstance,
      sentry,
      logRocket,
    }),
    promiseMiddleware(platformDeps),
    ...platformMiddleware,
  ];

  if (storageMiddleware) {
    middleware.push(storageMiddleware);
  }
  const enableLogger =
    (process.env.NODE_ENV !== "production" && process.env.IS_BROWSER) ||
    initialState.device.isReactNative;

  // Logger must be the last middleware in chain.
  if (false && enableLogger) {
    const ignoredActions = [STORAGE_SAVE];
    const logger = createLoggerMiddleware({
      collapsed: true,
      predicate: (getState, action) =>
        ignoredActions.indexOf(action.type) === -1,
      // Convert immutable to JSON.
      stateTransformer: (state) => JSON.parse(JSON.stringify(state)),
    });
    middleware.push(logger);
  }

  utilsConfigObject = {
    ...firebaseDeps,
    platformActions,
    apiServer: envParams.apiServer,
  };

  // if (logRocket)
  //   middleware.push(logRocket.reduxMiddleware());

  return middleware;
}

var getAppState;
var getDispatch;
let apiListenersDeps;
export {
  realmInstance,
  getAppState,
  getDispatch,
  writeLog,
  registerUserScopeLogs,
  isMixpanelInit,
  lokiInstance,
  apiListenersDeps,
  firebaseDeps,
};
