import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: "Records",
    id: 'posts.posts.title'
  },
  recordInfoTitle: {
    defaultMessage: 'Record Info',
    id: 'posts.info.title'
  }, 
  empty: {
    defaultMessage: 'There are no items to display.',
    id: 'posts.posts.empty'
  },
  selectLocation: {
    "defaultMessage": "No location selected",
    "id": "posts.posts.selectLocation"
  },
  createNew: {
    defaultMessage: 'Press the orange button to add an item!',
    id: 'posts.posts.createNew'
  },
  commentButton: {
    defaultMessage: 'Comment',
    id: 'posts.button.comment'
  },
  customerButton: {
    defaultMessage: 'Customer',
    id: 'posts.button.customer'
  },
  loadingPost: {
    defaultMessage: 'Loading',
    id: 'posts.post.loading'
  },
  loading: {
    defaultMessage: 'Loading',
    id: 'posts.posts.loading'
  },
  newPost: {
    defaultMessage: 'New Record',
    id: 'posts.buttons.newPost'
  },
  newIssue: {
    defaultMessage: 'New task',
    id: 'posts.buttons.newIssue'
  },
  postSaved: {
    defaultMessage: 'Record successfully saved',
    id: 'posts.buttons.postSaved'
  },
  issueSaved: {
    defaultMessage: 'Task successfully saved',
    id: 'posts.buttons.issueSaved'
  },
  noComments: {
    defaultMessage: 'No comments',
    id: 'posts.comments.noComments'
  },
  commentsCounter: {
    defaultMessage: 'Comments',
    id: 'posts.comments.commentsCounter'
  },
  sort: {
    defaultMessage: 'Sort',
    id: 'posts.buttons.sort'
  },
  sortBy: {
    defaultMessage: 'Sort',
    id: 'posts.buttons.sortBy'
  },
  filter: {
    defaultMessage: 'Filter',
    id: 'posts.buttons.filter'
  },
  filterBy: {
    defaultMessage: 'Filter',
    id: 'posts.buttons.filterBy'
  },
  optionsTitle: {
    defaultMessage: 'More options',
    id: 'posts.options.title'
  },
  optionsContent: {
    defaultMessage: 'Please select the wanted option',
    id: 'posts.options.content'
  },
  export: {
    defaultMessage: 'Send as a file',
    id: 'posts.options.export'
  },
  allProject: {
    defaultMessage: 'All project',
    id: 'posts.tagged.all'
  },
  to: {
    defaultMessage: 'To:',
    id: 'posts.tagged.to'
  },
  playIssue: {
    defaultMessage: 'Read task',
    id: 'posts.buttons.playIssue'
  },
  playPost: {
    defaultMessage: 'Read post',
    id: 'posts.buttons.playPost'
  },
  documentation: {
    defaultMessage: 'Record',
    id: 'posts.titles.documentation'
  },
  downloadingPosts: {
    defaultMessage: 'Downloading updates, please wait',
    id: 'posts.titles.downloadingPosts'
  },
  searchPlaceholder: {
    defaultMessage: 'Search',
    id: 'posts.placeholders.search'
  },
  select: {
    tasks: {
      defaultMessage: 'Select tasks',
      id: 'posts.select.tasks',
    },
    records: {
      defaultMessage: 'Select records',
      id: 'posts.select.records',
    },
  },
});
