import _ from 'lodash';
import {getFullLocationDetailsByIdNoProps} from '../locations/func';
import {Map} from 'immutable';
import {platformActions} from '../platformActions';
import {lokiInstance, realmInstance} from '../configureMiddleware';
import {schemasInfo} from '../lib/offline-mode/config';


export const convertDrawingsV2ToV1 = (drawings, projectId) => {
  const drawingObjects = [];
  Object
  .values(drawings || {})
  .forEach(drawing => {
    const category = _.first(_.keys(drawing.category));
    if (drawing.specificLocations) {
      Object.keys(drawing.specificLocations).forEach(locationId => {
        const locationData = getFullLocationDetailsByIdNoProps(locationId);
        locationData.id = locationId;
        drawingObjects.push({
          ...drawing,
          category,
          locationData,
        });
      });
    }
    const locationIds = [];

    if (drawing.locationType?.['-units'] && drawing['-unitsTags']) {
      locationIds.push(...getLocationsByPropIds(projectId, drawing['-unitsTags'], '-unitsTags'));
    }
    if (drawing.locationType?.['-floors'] && drawing['-floorsTags']) {
      locationIds.push(...getLocationsByPropIds(projectId, drawing['-floorsTags'], '-floorsTags'));
    }
    if (drawing.locationType?.['-buildings'] && drawing['-buildingsTags']) {
      locationIds.push(...getLocationsByPropIds(projectId, drawing['-buildingsTags'], '-buildingsTags'));
    }
    locationIds.forEach(locationId => {
      const locationData = getFullLocationDetailsByIdNoProps(locationId);
      locationData.id = locationId;
      drawingObjects.push({
        ...drawing,
        category,
        locationData,
      });
    });
  });

  return drawingObjects.reduce((map, val) => {
    if (!val.locationData.locationIds?.buildingId) {
      return map;
    }
    return map.setIn([val.locationData.locationIds?.buildingId, val.locationData.id, val.id], val)
  }, new Map());
}

// returns array of location ids
export const getLocationsByPropIds = (selectedProjectId, groupIds, propId = 'groups') => {
  const instancesCollectionName = schemasInfo.propertyInstances.schemaType;
  const subjectName = 'locationsInfo';
  // mobile
  if (platformActions.app.getPlatform() !== "web") {
    const filter = `projectId = "${selectedProjectId}" AND subjectName = "${subjectName}" AND propId = "${propId}"`;

    return realmInstance[instancesCollectionName]
      .objects(schemasInfo.propertyInstances.schemaName)
      .filtered(filter)
      .filter(item => {
        if (!item.data) {
          return;
        }
        return Object
        .keys(groupIds)
        .find(group => JSON.parse(item.data)?.[group])
      })
      .map(item => item.parentId);
  }
  // desktop
  const instancesQuery = {
    projectId : selectedProjectId,
    subjectName,
    propId,
  };
  return lokiInstance
    .getCollection(instancesCollectionName)
    .cementoFind(instancesQuery)
    .filter(item =>
      Object.keys(groupIds).find(group => item.data?.[group])
    )
    .map(item => item.parentId);
}