import * as actions from './actions';
import { firebaseActions } from '../lib/redux-firebase';
import { REPLACE_USER } from '../auth/actions';

import Comment from './comment';

import { OrderedMap } from 'immutable';
import { Record } from '../transit';

const InitialState = Record({
  map: OrderedMap(),
  localComments: OrderedMap(),
}, 'comments');

const initialState = new InitialState;

export default function commentReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    case actions.GET_COMMENTS + '_SUCCESS': {
      if (!action.payload)
        return state;
      let { comments, projectId, parentId } = action.payload;

      let commentsMap = state.getNested(['map', projectId, parentId], new OrderedMap());
      let retArray = Object.values(comments || {});
      retArray = retArray.sort(function(a, b){return a.createdAt && b.createdAt ? a.createdAt-b.createdAt : 0});

      for (var i = 0; i < retArray.length; i++) {
        var comment = new Comment(retArray[i]);
        commentsMap = commentsMap.set(comment.id, comment)
      }
      
      return state.setIn(['map', projectId, parentId], commentsMap);
    }

    case actions.DELETE_COMMENT + '_SUCCESS': {
      if (!action.payload)
        return state;
      
      return state.deleteIn(['map', action.payload.projectId,  action.payload.parentId, action.payload.commentId]);
    }
    

    case actions.END_COMMENTS_LISTENER:
    case actions.END_COMMENTS_LISTENER + '_SUCCESS': {
      if (!action.payload)
        return state;
      return state.deleteIn(['map', action.projectId, action.parentId]);
    }

    case actions.CREATE_NEW_COMMENT + '_SUCCESS': {
      if (!action.payload)
        return state;

      const { projectId, parentId, comment } = action.payload;
      state = state.setIn(['map', projectId, parentId, comment.id], new Comment(comment));
      state = state.setIn(['localComments', projectId, parentId, comment.id], comment);
      // comments = posts.sort(function(a, b){
      //   return (b.updatedTS || b.createdAt || 0)-(a.updatedTS || a.createdAt || 0)
      // });

      return state;
    }

    case actions.UPLOAD_NEW_POST_COMMENT + "_SUCCESS":
    case actions.UPLOAD_NEW_CHECKLIST_STATUS_COMMENT + "_SUCCESS": {
      if (!action.payload)
        return state;
      const { projectId, parentId, comment } = action.payload;
      if (projectId && parentId && comment)
        return state.deleteIn(['localComments', projectId, parentId, comment.id]); 
      else
        return state;
    }

    case REPLACE_USER + "_SUCCESS": {
      return initialState;
    }
  }

  return state;
}


