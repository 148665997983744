import ExtraError from '../lib/errors/extraError'
import { hideLoading, startLoading, startAlert, cancelOperation } from '../app/actions';
import reportsMessages from '../../common/reports/reportsMessages.js';
import { getAppState, envParams } from '../configureMiddleware';
import _ from 'lodash';

import pdfMessages from '../app/pdfMessages';
import { track } from '../lib/reporting/actions';
import { isOperationActive, onError, openFormListener } from '../app/funcs';
import systemMessages from '../app/systemMessages';
import { firebaseGet } from '../lib/utils/utils';

export const DOWNLOAD_PDF  = 'DOWNLOAD_PDF';
export const SHARE_FILE  = 'SHARE_FILE';
export const EXPORT_FORM_AS_PDF  = 'EXPORT_FORM_AS_PDF';
export const CONVERT_PDF_TO_IMAGE = 'CONVERT_PDF_TO_IMAGE';

function hashCode(s) {
  return s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);              
}

/**
 * @typedef Form
 * @property {string} formTemplateId
 * @property {string} id
 * @property {string} type
 * @property {string} uri
 * @property {number} updatedTS
 * @property {{ displayName: string, id: string }} generator
 * @property {{ buildingId?: string, floorId?: string, unitId?: string }} location
 * @property {{ [signatureId: string]: { id: string, lang: string, url: string } }} [signatures]
 */

export function getPDFFromServer(uri_attachment, filename_attachment, returnBase64, pdfName) {	
  return ({ platformActions, dispatch }) => {
  	const getPromise = async () => {
			try {
        //dispatch(startLoading({title:pdfMessages.generating}));
        // TODO: Check for cached items
        var pdfLocation = null;
        var fileExist = null;
        var hashUrl = hashCode(uri_attachment);

        pdfLocation = platformActions.fs.getCacheDirectoryPath() + '/' + (pdfName || hashUrl) + '.pdf';
        fileExist = await platformActions.fs.exists(pdfLocation);

        if (!fileExist) {
					if (!(getAppState && getAppState() && getAppState().getNested(['app', 'isConnected'], false))) {
						onError({
							errorMessage: 'Failed to getPDFFromServer due to no signal',
							methodMetaData: {
								name: 'getPDFFromServer',
								args: [uri_attachment, filename_attachment, returnBase64],
							},
							alertParams: {
								title: reportsMessages.exportErrors.title,
								message: reportsMessages.exportErrors.content
							},
						});
						dispatch(hideLoading());
						throw new ExtraError('getPDFFromServer no reception', {uri_attachment})     		
					}

        	var res = await platformActions.net.fetch(uri_attachment, { headers: {}}, null, true);
        	let base64Str = res.data;
          if (returnBase64)
            return base64Str;
            
        	await platformActions.fs.writeFile(pdfLocation, base64Str, 'base64');
        }
        return pdfLocation;

      } catch (error) {
        dispatch({ type: DOWNLOAD_PDF, payload: { success: false }});
        console.warn(error)
        throw new ExtraError('getPDFFromServer error', {uri_attachment, filename_attachment, returnBase64}, error)     
		  }
		}
		return {
      type: DOWNLOAD_PDF,
      payload: getPromise()
    };
	}
}

export const ERROR_CODE_TIMEOUT = 101;
export const ERROR_CODE_FAILED_PARSING = 102;
/**
 * @typedef ExportFormPDFParams
 * @property {{ id: string }} project
 * @property {string} formId
 * @property {string} formType
 * @property {boolean} [showWebView] - Default: false
 * @property {boolean} [displayLoading] - Default: true
 * @property {string} [operationId] - Default: undefined
 * @property {boolean} [autoHandleError] - Default: true
 * @property {function} [onLateResponse] - Default: null
 * @property {boolean} [isListenerMode] - Default: false
 * 
 * @param {ExportFormPDFParams}
 * @returns {Promise<{ uri: string } | import('../app/funcs').OnErrorParams>}
 */
export function exportFormPDF({ project, formId, formType, showWebView = false, displayLoading = true, operationId, autoHandleError = true, onLateResponse = null, isListenerMode = false, reportDate }) {
	return ({ dispatch, getState, platformActions, firebase }) => {
		const getPromise = async () => {
			/** @type {import('../app/funcs').OnErrorParams} */
			let onErrorParams = { 
				methodMetaData: {
					name: 'exportFormPDF',
					args: { project, formId, formType, showWebView, displayLoading, operationId },
				},
				alertParams: {
					title: reportsMessages.exportErrors.title,
					message: reportsMessages.exportErrors.contentWithCode,
					actions: [{ message: systemMessages.ok, color: 'success' }],
				},
			}
			operationId = operationId + '_exportFormPDF_func_loading_id_' + formId;
			let ret = null;
			try {
				if (!(getAppState && getAppState() && getAppState().getNested(['app', 'isConnected'], false))) {
					_.set(onErrorParams, ['errorMessage'], 'export pdf action failed because user is disconnected');
					_.set(onErrorParams, ['errorMetaData'], { isConnected: false });
					_.set(onErrorParams, ['alertParams', 'message'], reportsMessages.exportErrors.content);

					throw new ExtraError('export pdf action failed because user is disconnected');
				}

				if (displayLoading)
					dispatch(startLoading({ title: pdfMessages.generating, overlay: true, hideOnBackgroundPress: false, cancelOnBackgroundPress: true, operationId, isWithTimeout: false }));

				let resp = null;
				try {
					resp = await (new Promise(async (resolve, reject) => {
						let rejectTimeout = null;
						let timeoutExceeded = false;
						if (displayLoading)
							rejectTimeout = setTimeout(() => {timeoutExceeded = true; reject('Action canceled by timeout: Could not contact server in a reasonable amount of time');}, 1000 * 45);
						let ret = null;
						if (isListenerMode) {
							const formReadyToGenerateTSPath = `forms/${project.id}/full/${formType}/${formId}/readyToGenerateTS`;
							if (!(await firebaseGet(formReadyToGenerateTSPath)))
								await firebase.update({ [formReadyToGenerateTSPath]: Date.now() });

							ret = await (new Promise(innerResolve => {
								let removeFormListener;
								removeFormListener = openFormListener(project.id, formId, formType, form => {
									if (!form || !form.uri || form.readyToGenerateTS)
										return;

									removeFormListener();
									innerResolve({ getJson: () => form });
								});
							}));
						}
						else {
							let body = {
								formId,
								formType,
								projectId: project.id,
								debug: showWebView,
								environment: process.env.REACT_APP_ENVIRONMENT,
							};
							if (reportDate) body.reportDate = reportDate;

							ret = await (platformActions.net.fetch(envParams.pdfServer + '/pdf', {
								'method': 'POST',
								'body': JSON.stringify(body)
							}, null, true));
						}
						if (onLateResponse && timeoutExceeded)
							onLateResponse(ret);

						if (rejectTimeout)
							clearTimeout(rejectTimeout);
						resolve(ret);
					}));
				} 
				catch (error) {
					_.set(onErrorParams, ['errorMessage'], 'Failed contacting pdf server');
					_.set(onErrorParams, ['errorCode'], 101);
					_.set(onErrorParams, ['errorMetaData'], { serverResp: resp });
					_.set(onErrorParams, ['alertParams', 'values', 'errorCode'], ERROR_CODE_TIMEOUT);

					throw error;
				}

				dispatch({ type: EXPORT_FORM_AS_PDF, payload: { success: true, project, formType, formId } });
				try {
					ret = await (resp.getJson());
				}
				catch (error) {
					_.set(onErrorParams, ['errorMessage'], 'Failed parsing pdf server response');
					_.set(onErrorParams, ['errorCode'], 102);
					_.set(onErrorParams, ['errorMetaData'], { serverResp: resp });
					_.set(onErrorParams, ['alertParams', 'values', 'errorCode'], ERROR_CODE_FAILED_PARSING);

					throw error;
				}

				if (operationId && !isOperationActive(operationId)) {
					ret = null;
					console.log("pdfForm creation/download/saveToDevice canceled!");
				}
				else {
					if (!ret.uri)
						ret.uri = ret.URL || ret.debugUrl;
					dispatch(track('exportFormPDF', {
						uri: ret.uri,
						body: JSON.stringify({ formId, formType, projectId: project.id })
					}));
				}
			} catch (error) {
				dispatch({ type: EXPORT_FORM_AS_PDF, payload: { success: false, project, formType, formId } });
				const errorParams = { ...onErrorParams, error };
				if (autoHandleError)
					onError(errorParams);

				ret = errorParams;
				throw new ExtraError('pdfForm creation/download/saveToDevice error', null, error);
			}
			finally {
				dispatch(hideLoading(operationId));
				return ret;
			}
		};
		return {
			type: EXPORT_FORM_AS_PDF,
			payload: getPromise()
		};
	};
}

export function convertPdfToImage(uri, density, expires) {
	return ({ apiServer, platformActions, removeEmpty }) => {
		const getPromise = async () => {
			try {
				let body = { uri, expires };
				
				if (density)
					body.density = density;
					
				let resp = await (platformActions.net.fetch(apiServer + '/v1/services/convert/pdf/url', {
					'method': 'POST',
					'body': JSON.stringify(body)
				}, null, true));
				
				let ret = await (resp.getJson());
				return ret;
			}
			catch (err) {
				console.log("convertPdfToImage -> err", err);
			}
		};
		
		return {
			type: CONVERT_PDF_TO_IMAGE,
			payload: getPromise()
		};
	};
};