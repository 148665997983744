import React from "react";
import { injectIntl } from "react-intl";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose, hoistStatics } from "recompose";
import { connect } from "react-redux";
import { connectContext } from "react-connect-context";
import { FiltersSortsContext } from "../../../common/projects/contexts";
import { withRouter } from "react-router-dom";
import { CloseRounded } from "@material-ui/icons";
import { saveUIParams } from "../../../common/ui/actions";
import postsMenuMessages from "../../../common/posts/postsMenuMessages";
import theme from "../../assets/css/theme";
import Text from "./Text";
import collapse from "../../assets/img/icons/collapse.png";

class FilterStatusLine extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.state = {};
    this.filtersMapMessages = {
      "owner/id": postsMenuMessages.filterStatusLine["owner/id"],
      "assignTo/id": postsMenuMessages.filterStatusLine["assignTo/id"],
      "assignTo/id members_companyId":
        postsMenuMessages.filterStatusLine["assignTo/id members_companyId"],
      "trade/id": postsMenuMessages.filterStatusLine["trade/id"],
      issueState: postsMenuMessages.filterStatusLine["issueState"],
      and: postsMenuMessages.filterStatusLine["and"],
    };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};
    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  clearFilters() {
    const { saveUIParams } = this.props;
    saveUIParams({ uiFilterLine: null });
    this.props.history.push(this.props.location.pathname);
  }

  render() {
    const {
      filters,
      uiParams,
      urlParams,
      intl,
      expandStatus,
      onCollapseExpandClick,
      style,
      rtl,
      headerTitle,
    } = this.props;
    let obj = uiParams.getNested(["uiFilterLine"]);
    let count = uiParams.getNested(["dataCount"]);
    let type =
      urlParams.getNested(["page"]) == "records"
        ? intl.formatMessage(postsMenuMessages.dataTypeRecords)
        : intl.formatMessage(postsMenuMessages.dataTypeTasks);
    let filterLine = [
      <Text
        style={{
          fontWeight: theme.bold,
          [rtl ? "paddingLeft" : "paddingRight"]: 2,
        }}
        values={{ count, type }}
      >
        {postsMenuMessages.statusDataCount}
      </Text>,
    ];

    if (obj) {
      obj.loopEach((k, v) => {
        filterLine.push(
          <Text
            style={{
              [rtl ? "paddingLeft" : "paddingRight"]: 2,
              paddingLeft: 2,
            }}
            values={{ value: v.value }}
          >
            {this.filtersMapMessages[k]}
          </Text>
        );
        filterLine.push(
          <Text
            style={{
              [rtl ? "paddingLeft" : "paddingRight"]: 2,
              paddingLeft: 2,
            }}
          >
            {this.filtersMapMessages.and}
          </Text>
        );
      });
      filterLine.splice(-1, 1);
    }

    return (
      <div style={style}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            fontSize: 16,
            fontWeight: theme.strongBold,
          }}
          onClick={onCollapseExpandClick ? onCollapseExpandClick : undefined}
        >
          <img
            src={collapse}
            style={{
              transform: Boolean(!expandStatus)
                ? "rotate(90deg)"
                : rtl
                ? "rotate(180deg)"
                : "",
              height: 10,
              transition: "0.2s",
              [rtl ? "marginLeft" : "marginRight"]: 10,
            }}
          />
          <Text style={{ marginLeft: theme.verticalMargin }}>
            &nbsp;{headerTitle}&nbsp;
          </Text>
          (
          {filterLine.map((fl, i) => (
            <Text key={i}>{fl}</Text>
          ))}
          {Boolean(filters) && (
            <div
              style={{
                display: "flex",
                margin: 5,
                cursor: "pointer",
                borderRadius: "50%",
                border: "1px solid rgba(210, 210, 210, 0.46)",
              }}
            >
              <CloseRounded
                style={{ fontSize: 16 }}
                onClick={this.clearFilters}
              />
            </div>
          )}
          )
        </div>
      </div>
    );
  }
}

let styles = {};

FilterStatusLine = withStyles(styles)(FilterStatusLine);
FilterStatusLine = withRouter(FilterStatusLine);
const enhance = compose(
  connectContext(FiltersSortsContext.Consumer),
  connect(
    (state) => ({
      rtl: state.app.rtl,
      uiParams: state.ui.uiParams,
      urlParams: state.ui.urlParams,
      headerTitle: state.ui.headerTitle,
    }),
    { saveUIParams }
  )
);

FilterStatusLine = injectIntl(FilterStatusLine);
export default enhance(FilterStatusLine);
