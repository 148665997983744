import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

import ExtraError from '../lib/errors/extraError'
import { startLoading } from '../app/actions'; 
import usersMessages from '../users/usersMessages';
import { onError } from '../app/funcs';

export const SEARCH_UNLOCAL_USERS_BY_UIDS = 'SEARCH_UNLOCAL_USERS_BY_UIDS';
export const GET_USER_BY_UID = 'GET_USER_BY_UID';

export const SEARCH_USERS_BY_PHONE_STARTED = 'SEARCH_USERS_BY_PHONE_STARTED';
export const SEARCH_USERS_BY_PHONE = 'SEARCH_USERS_BY_PHONE';
export const UPDATE_MY_METADATA = 'UPDATE_MY_METADATA';
export const COPY_GLOBAL_VIEWER_TO_SCOPE = 'COPY_GLOBAL_VIEWER_TO_SCOPE';


export const SEARCH_PROJECT_USERS = 'SEARCH_PROJECT_USERS';

export const MATCH_PHONES = 'MATCH_PHONES';
export const MATCH_PHONES_SUCCESS = 'MATCH_PHONES_SUCCESS';

export const GET_MEMBER_ACTIVITY_INFO = 'GET_MEMBER_ACTIVITY_INFO';
export const GET_USER_PAGE = 'GET_USER_PAGE';
export const END_USER_PAGE_LISTENER = 'END_USER_PAGE_LISTENER';

export const REVIEW_MEMBER = 'REVIEW_MEMBER';
export const LIKE_MEMBER = 'LIKE_MEMBER';

export const SET_CONTACTS_LATEST_USER_UPDATE_AT = 'SET_CONTACTS_LATEST_USER_UPDATE_AT';

export const UPSERT_USER_PAGE_IMAGE = 'UPSERT_USER_PAGE_IMAGE';
export const ON_OTHER_USER_PROFILE_UPDATE = 'ON_OTHER_USER_PROFILE_UPDATE';
export const INVITE_UNINVITED_USER = 'INVITE_UNINVITED_USER';
export const CLEAN_CACHED_MEMBERS = 'CLEAN_CACHED_MEMBERS';

export function updateOtherUserProfile(uid, userDetails, userScopeDetails, scopeKey, patchOnly, callback) {
  return ({ validate, platformActions, apiServer }) => {
    const getPromise = async () => {
      await validate({uid})
      .prop('uid')
      .required()
      .promise;
      

      var userValues = {
          "uid": uid,
          "displayName": userDetails.displayName,
          "idNumber": userDetails.idNumber,
          "email": userDetails.email,
          "about": userDetails.about,
          "trades": userScopeDetails.trades ? Object.keys(userScopeDetails.trades) : null,
          "title": userScopeDetails.title ? userScopeDetails.title : null,
          "companyId": userScopeDetails.companyId,
          "scopeKey" : scopeKey
      };

      if (userDetails.avatar) 
        userValues.avatar = userDetails.avatar;
      
      if (userScopeDetails.projectsObject && scopeKey == 'global')
        userValues.projects = userScopeDetails.projectsObject;

      var resp = await platformActions.net.fetch(apiServer + '/v1/users/' + encodeURIComponent(uid), {
        method: patchOnly ? 'PATCH' : 'PUT', 
        body: JSON.stringify(userValues)
      });
      
      // var didSuccess = (resp.respInfo && resp.respInfo.status == 200);
      var didSuccess = resp && resp.status == 200;
      if (didSuccess) {
        var ret = await (resp.getJson());
        var DBMember = {};
        DBMember = ret.user_metadata;
        if (callback){
          callback(DBMember)
        }
          
        return { DBMember, uid };
      }
    };
    return {
      type: ON_OTHER_USER_PROFILE_UPDATE,
      payload: getPromise(),
    };
  };
}

export function isNumberValid(phoneNumber, countryLetters) {
  var phoneNumberString = null;
  try {
    var phoneUtil = PhoneNumberUtil.getInstance();
    var parsedPhoneNumber = phoneUtil.parse(phoneNumber, countryLetters);
    var isValid = phoneUtil.isValidNumber(parsedPhoneNumber)
    if (isValid)
      phoneNumberString = phoneUtil.format(parsedPhoneNumber, PhoneNumberFormat.E164)
  } catch (err) {
    console.log(err);
  }

  return phoneNumberString;
}
export function createFromLocalContact(localContact, userDetails, scopeKey, callback, upsert) {
  return ({ dispatch, getState, platformActions, apiServer }) => {
    const getPromise = async () => {
      
      // CEM-3971 - The bug happen here, couldn't repraduce so added logs to sentry (18.5.21)
      const myPhoneNumber = getState().auth.number;
      var viewerCountryLetters = 'US';        
      if (myPhoneNumber) {
        if (myPhoneNumber.startsWith('+972'))
          viewerCountryLetters = 'IL';
        else if (myPhoneNumber.startsWith('+507'))
          viewerCountryLetters = 'PA';
        else if (myPhoneNumber.startsWith('+49'))
          viewerCountryLetters = 'DE';
      }
      else {
        platformActions.sentry.notify('createFromLocalContact - Phone number did not loaded in time', {myPhoneNumber, numberTryingToAdd: userDetails.phoneNumber});
        console.warn('Phone number did not loaded in time - auto set on IL location code: 10002')
      }

      var phoneNumberString = isNumberValid(userDetails.phoneNumber.replace(/[ ,-]/g, ''), viewerCountryLetters);
      
      if (phoneNumberString) {
        dispatch(startLoading({title:usersMessages.createUserPleaseWait, overlay:true}));
        // clean the trades if needed
        userDetails = userDetails || {};
        var userParams = {
            "scopeKey" : scopeKey,
            "phoneNumber": phoneNumberString,
            "displayName": userDetails.displayName,
            "avatar": userDetails.avatar,
            "trades": userDetails.trades,
            "title": userDetails.title,
            "about": userDetails.about,
            "companyId": userDetails.companyId,
            "defaultAssignee": userDetails.defaultAssignee,
            "inviterUid": getState().users.viewer.get('id')
        };

        if (scopeKey == 'global' && userDetails.projectsObject)
          userParams['projects'] = userDetails.projectsObject

        const apiUrl = apiServer + '/v1/users';
        try {
          let resp = await platformActions.net.fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify({...userParams, forceUpsert: upsert})})
            
          var ret = await (resp.getJson());
          var user_metadata = ret.user_metadata;
          var DBMember = {};
          DBMember.id = ret.user_id;
          DBMember.activated = ret.phone_verified;
          DBMember.phoneNumber = phoneNumberString;  
          if (user_metadata) {
            user_metadata.loopEach((k, value) => { DBMember[k] = value });
            if (scopeKey != 'global')
              user_metadata.getNested(['projects', scopeKey], {}).loopEach((k, value) => { DBMember[k] = value });
          }

          if (callback)
            await callback(DBMember);
          dispatch({ type: SEARCH_USERS_BY_PHONE, payload: { members: [ {DBMember, localContact } ], scopeKey }});
          return DBMember;
        } catch (error) {
          onError({
            errorMessage: 'Failed to fetch user meta data',
            error,
            methodMetaData: {
              args: { localContact, userDetails, scopeKey, upsert },
              name: 'createFromLocalContact',
            },
            errorMetaData: {
              apiUrl,
              body: { ...userParams, forceUpsert: upsert }
            },
          });
        };
      }
      else {
        platformActions.sentry.notify('createFromLocalContact - Phone number is not valid', {myPhoneNumber, numberTryingToAdd: userDetails.phoneNumber});
        if (callback)
          await callback(null);
        return null
      }
    };
    return {
      type: MATCH_PHONES,
      payload: getPromise()
    };
  };
}

export function getUserByUid(uid) {
  return ({ dispatch }) => {
    var uids = {};
    uids[uid] = uid;
    dispatch(getUsersByUids(uids));

    return {
      type: GET_USER_BY_UID,
      payload: { uid },
    }
  }
}

export function getUsersByUids(uids) {  
    return ({ dispatch, platformActions, getState, apiServer }) => {
      const getPromise = async () => {
      if (uids && Object.keys(uids).length > 0) {
        try {
          let finalUidsList = {};
          Object.keys(uids).forEach(uid => {
            if (!getState().getNested(['members','inProcessMap', uid]) && !getState().getNested(['members','map', uid]))
              finalUidsList[uid] = true;
          })
          if (!Object.keys(finalUidsList).length)
            return {};

          let resp = await platformActions.net.fetch(`${apiServer}/v1/users?includeGroups=true&uidsList=${encodeURIComponent(JSON.stringify(Object.keys(finalUidsList)))}`);
          let response = await (resp.getJson());
          let DBMembers = getDBMembers(response);
          return ({ DBMembers, uids:finalUidsList });
        } catch (error) {
          console.log('getUsersByUids error')
          console.warn(error)
          throw new ExtraError('getUsersByUids error', {uids}, error)
        }
      }
    } 

    return {
      type: SEARCH_UNLOCAL_USERS_BY_UIDS,
      payload: getPromise(),
      uids
    };      
  };
}

export function getUsersByProject(projectId) {  
    return ({ dispatch, platformActions, apiServer, getState }) => {
      const getPromise = async () => {
        // var projectMembers = getState().getNested(['projects','detailsMap', projectId, 'members']);
        // var deletedMembers = getState().getNested(['projects','detailsMap', projectId, 'deletedMembers']);
        
        // projectMembers = Object.assign({}, projectMembers, deletedMembers);
        // if (Object.values(projectMembers).length == 0)
        //   return;

        try {
          let resp = await platformActions.net.fetch(`${apiServer}/v1/users?includeGroups=true&projectId=${projectId}`);
          
          var response = await (resp.getJson());
          var DBMembers = getDBMembers(response);
          dispatch({ type: SEARCH_PROJECT_USERS, payload: { DBMembers, projectId }});
        } catch (error) {
          throw new ExtraError('getUsersByUids', {projectId}, error)
        };
      
    }

    return {
      type: SEARCH_PROJECT_USERS,
      payload: getPromise()
    };      
  };
}


export function getMemberActivityInfo(userId, callback) {  
  return ({ firebaseDatabase }) => {
    const getPromise = async () => {
      var snapshot = await firebaseDatabase().ref('users-projects/' + userId).once('value');
      var projects = Object.keys(snapshot.val() || {});
      var ret = { projects };
      if (callback)
        callback(ret)
      return ret;
    }

    return {
      type: GET_MEMBER_ACTIVITY_INFO,
      payload: getPromise()
    };
  };
}


export function startUserPageListener(user) {  
  return ({ firebaseDatabase, dispatch }) => {
    const getPromise = async () => {
      await firebaseDatabase().ref('userPages/' + user.id).on('value', function(snapshot) {
        dispatch({ type: GET_USER_PAGE + '_SUCCESS', payload: { userPage: snapshot.val(), id: user.id }});
      });        
    }

    return {
      type: GET_USER_PAGE,
      payload: getPromise()
    };
  };
}

export function endUserPageListener(user) {  
  return ({ firebaseDatabase }) => {
    firebaseDatabase().ref('userPages/' + user.id).off('value');
    return {
      type: END_USER_PAGE_LISTENER
    };
  };
}

export function likeMember(projectId, memberUid, rank) {  
  return ({ dispatch, getState, firebase }) => {
    const getPromise = async () => {
      
      var myUid = getState().users.viewer.get('id');

      var updates = {};
      updates['userPages/' + memberUid + '/likes/' + projectId + '/' + myUid] = {rank};
      var upload = await firebase.update(updates)

      return { memberUid, myUid, projectId, rank }
    };
    return {
      type: LIKE_MEMBER,
      payload: getPromise()
    };
  };
}

export function reviewMember(projectId, memberUid, review) {  
  return ({ dispatch, getState, firebase }) => {
    const getPromise = async () => {
      
      var myUid = getState().users.viewer.get('id');

      var updates = {};
      updates['userPages/' + memberUid + '/reviews/' + projectId + '/' + myUid] = {'content' : review, owner: {id: myUid}};
      var upload = await firebase.update(updates)

      return { memberUid, myUid, projectId, review }
    };
    return {
      type: REVIEW_MEMBER,
      payload: getPromise()
    };
  };
}

export function upsertUserPageImage(projectId, inImage, isDelete) {  
  return ({ dispatch, getState, firebase }) => {
    const getPromise = async () => {
      
      var uid = getState().users.viewer.get('id');
      var imageId = inImage.id || inImage.uri.substr(-36);
      var image = !isDelete ? Object.assign({}, inImage) : null;

      var updates = {};
      updates['userPages/' + uid + '/images/' + projectId + '/picked/' + imageId] = image;
      var upload = await firebase.update(updates)

      return { uid, projectId, image, imageId }
    };
    return {
      type: UPSERT_USER_PAGE_IMAGE,
      payload: getPromise()
    };
  };
}

export function findNetworkFromContacts(contacts, viewerFullNumber, fetchWithViewer, forceFetch, callback) {
  return ({ dispatch, getState, platformActions, apiServer }) => {
    const getPromise = async () => {
      if (!contacts)
        return {
          type: MATCH_PHONES,
          payload: { }
        };

      const phoneNumber = viewerFullNumber ? viewerFullNumber : getState().auth.number;
      const viewer = getState().users.viewer;
      var phoneUtil = PhoneNumberUtil.getInstance();

      var phonesList = {};
      var localContacts = {};
      var viewerCountryLetters = 'US';        
      if (!phoneNumber) {
        console.warn('Phone number did not loaded in time - auto set on IL location code: 10003')
      }
      else {
        if (phoneNumber.startsWith('+972'))
          viewerCountryLetters = 'IL';
        else if (phoneNumber.startsWith('+507'))
          viewerCountryLetters = 'PA';
        else if (phoneNumber.startsWith('+49'))
          viewerCountryLetters = 'DE';
      }
      
      contacts.loopEach((key, contact) => {
        (contact.phoneNumbers || []).loopEach((k, currPhoneNumber) => {
          try {
            var phoneNumber = phoneUtil.parse(currPhoneNumber, viewerCountryLetters);
            var phoneNumberString = phoneUtil.format(phoneNumber, PhoneNumberFormat.E164)
            phonesList[phoneNumberString] = phoneNumberString; 
            localContacts[phoneNumberString] = contact;
          }
          catch (error) {
            onError({
              errorMessage: 'Failed to parse contacts',
              error,
              methodMetaData: {
                name: 'findNetworkFromContacts',
                args: { contacts, viewerFullNumber, fetchWithViewer },
              },
            })
          }
        });
      })

      // Send the request in batches
      const max_batch_size = 100;
      var phonesListArray = Object.keys(phonesList);
      if (fetchWithViewer && viewer && viewer.phoneNumber)
        phonesListArray.push(viewer.phoneNumber);

      for (var i=0; i < phonesListArray.length; i += max_batch_size) {
        var currList = phonesListArray.slice(i, i + max_batch_size);

        // Call the phone number on a query
        let resp = await platformActions.net.fetch(`${apiServer}/v1/users?includeGroups=true&phonesList=${encodeURIComponent(JSON.stringify(Object.values(currList)))}`);
        var matchNumbersResponse = await (resp.getJson());
        let myUser = null;
        let DBMembersArr = [];

        Object.values(matchNumbersResponse).map(member => {
          var DBMember = member.user_metadata;
          var updated_at = member.updated_at
          // Save every matching phone nubmer found
          if (DBMember && viewer && DBMember.id == viewer.id) {
            myUser = { DBMember, updated_at };
            DBMembersArr.push(myUser);
          }
          else if (DBMember && localContacts[member.name]) {
            var localContact = localContacts[member.name].toJS ? localContacts[member.name].toJS() : localContacts[member.name];
            DBMember.activated = member.phone_verified;

            DBMember.phoneNumber = member.user_metadata.phoneNumber && member.user_metadata.phoneNumber != "" ?
              member.user_metadata.phoneNumber :
              member.phone_number;
            
            DBMembersArr.push({ DBMember, localContact, updated_at });
          }
        });
        if (callback)
          callback(DBMembersArr);
        if (myUser)
          dispatch({ type: UPDATE_MY_METADATA + "_SUCCESS", payload: { values: myUser.DBMember, updated_at: myUser.updated_at, myId: viewer.id, scopeKey: 'global' }});
        if (DBMembersArr.length > 0)
          dispatch({ type: SEARCH_USERS_BY_PHONE, payload: { members: DBMembersArr, scopeKey: 'global', scope: 'contacts' }});
      }
    }
    return {
      type: MATCH_PHONES,
      payload: getPromise()
    };
  };
}

export function getAllUsers(pageLimit, strSearchQuery) {  
  return ({ dispatch, platformActions, apiServer }) => {
    const getPromise = async () => {
      try {
        let resp = await platformActions.net.fetch(`${apiServer}/v1/users?includeGroups=true&pageLimit=${pageLimit}&strSearchQuery=${encodeURIComponent(strSearchQuery)}`);
        var response = await (resp.getJson());
        var DBMembers = getDBMembers(response);

        return ({ DBMembers });
      } catch (error) {
        console.log('getAllUsers error')
        console.warn(error)
        throw new ExtraError('getAllUsers error', {}, error)
      }
    } 

    return {
      type: SEARCH_UNLOCAL_USERS_BY_UIDS,
      payload: getPromise()
    };      
  };
}

function getDBMembers(usersMap) {
  let DBMembers = {};
  Object.values(usersMap || {}).forEach(user => { 
    if (user.user_metadata) {
      DBMembers[user.user_metadata.id] = user.user_metadata;
      DBMembers[user.user_metadata.id].activated = user.phone_verified;
      DBMembers[user.user_metadata.id].phoneNumber = user.user_metadata.phoneNumber && user.user_metadata.phoneNumber != "" ? 
      user.user_metadata.phoneNumber : user.phone_number;
    }
  });
  return DBMembers;
}
