import * as actions from './actions';
import { Map } from 'immutable';
import { Record } from '../transit';

const InitialState = Record({
  map: Map(),
}, 'images');

const initialState = new InitialState;

export default function imageReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;
  // switch (action.type) {
  //   case actions.PREFETCH_IMAGE: {
  //   	const { uris } = action.payload;
  //   	(uris || []).forEach(uri =>  state = state.setIn(['map', uri], true));
  //     return state;
  //   }
  // }

  return state;
}


