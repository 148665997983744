import React from 'react';
import { connect } from 'react-redux';
import { compose, hoistStatics } from 'recompose';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../../../common/projects/contexts';
import { getMemberActivityInfo } from '../../../common/members/actions';
import { replaceUser } from '../../../common/auth/actions';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Avatar from '../../components/CementoComponents/Avatar';
import CompanyName from '../../components/CementoComponents/CompanyName';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import theme from '../../assets/css/theme';
import Button from '../../components/CustomButtons/Button.jsx';
import buttonStyle from '../../assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx';
import { startToast } from '../../../common/app/actions';
import systemMessages from '../../../common/app/systemMessages';
import { syncUsersLocalStorage } from '../../../common/lastUpdates/actions';

class MemberCard extends React.Component {
	constructor(props) {
		super(props);
		this.setComponentData = this.setComponentData.bind(this);
		this.setMemberProjects = this.setMemberProjects.bind(this);
		this.onRevokeFeatureSelect = this.onRevokeFeatureSelect.bind(this);
		this.onSyncFeatureSelect = this.onSyncFeatureSelect.bind(this);
		this.connectAs = this.connectAs.bind(this);
		this.revokeCache = this.revokeCache.bind(this);
		this.syncCache = this.syncLocalStorage.bind(this);
		this.getLogUrl = this.getLogUrl.bind(this);
		this.state = {
			memberProjectsTitles: [],
			memberProjectsIds: [],
		};
	}

	UNSAFE_componentWillMount() {
		this.setComponentData({}, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setComponentData(this.props, nextProps);
	}

	setMemberProjects(memberActivityInfo, nextProps) {
		const { projects } = nextProps;
		let memberProjectsTitles = [];
		let memberProjectsIds = memberActivityInfo.projects || [];
		memberProjectsIds.forEach(projId => {
			let currProj = projects.getNested([projId], {});
			memberProjectsTitles.push(currProj.title || currProj.address);
		});
		memberProjectsTitles.sort();
		this.setState({ memberProjectsTitles, memberProjectsIds });
	}

	setComponentData(props, nextProps) {
		let newStateChanges = {};

		if (props.getNested(['member', 'id']) != nextProps.getNested(['member', 'id'])) {
			newStateChanges.selectedRevokeFeature = null;
			newStateChanges.selectedSyncFeature = null;
			newStateChanges.memberProjectsTitles = [];
			newStateChanges.memberProjectsIds = [];
		}

		if (
			props.getNested(['member', 'id']) != nextProps.getNested(['member', 'id']) ||
			props.projects != nextProps.projects
		)
			nextProps.getMemberActivityInfo(nextProps.getNested(['member', 'id']), memberActivityInfo =>
				this.setMemberProjects(memberActivityInfo, nextProps),
			);

		if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
	}

	onSelectedCompanyChange(sourceName, selected) {
		this.setState({ [sourceName]: selected.slice() });
	}

	onRevokeFeatureSelect(e) {
		let selectedRevokeFeature = e;
		this.setState({ selectedRevokeFeature });
	}

	onSyncFeatureSelect(e) {
		let selectedSyncFeature = e;
		this.setState({ selectedSyncFeature });
	}

	revokeCache() {
		const { revokeCache, member, selectedProjectId } = this.props;
		const { selectedRevokeFeature, memberProjectsIds } = this.state;
		let projectIdsToRevoke = selectedProjectId ? [selectedProjectId] : memberProjectsIds;
		if (revokeCache && member && selectedRevokeFeature)
			revokeCache(selectedRevokeFeature.value, [member.id], projectIdsToRevoke);
	}

	syncLocalStorage(withUpload) {
		const { syncUsersLocalStorage, member, selectedProjectId } = this.props;
		const { selectedSyncFeature, memberProjectsIds } = this.state;
		let projectIdsToRevoke = selectedProjectId ? [selectedProjectId] : memberProjectsIds;
		if (syncUsersLocalStorage && member && selectedSyncFeature)
			syncUsersLocalStorage(selectedSyncFeature.value, [member.id], projectIdsToRevoke, withUpload);
	}

	getLogUrl() {
		const { startToast, selectedProjectId, member } = this.props;
		const { selectedSyncFeature } = this.state;
		if (member && selectedSyncFeature) {
			const link = `https://planme-1383.firebaseio.com/_internal/logs/instancesSync/${member.id}/${selectedProjectId}/${selectedSyncFeature.label}`;
			window.open(link, '_blank');
		} else
			startToast({
				overlay: true,
				mandatory: true,
				message: 'Make sure you selected member and feature',
				actions: [{ message: systemMessages.ok }],
			});
	}

	async connectAs() {
		const { member, replaceUser } = this.props;
		if ((member || {}).phoneNumber) {
			try {
				var ret = await replaceUser(member.phoneNumber);
				if (!ret || !ret.fakeUser) console.error(ret && ret.error ? ret.error : 'An unknown Error has occurred');
			} catch (err) {
				console.error(err, 'An unknown Error has occurred');
			}
		}
	}

	render() {
		const { style, member, trades, titles, viewer, selectedProjectId } = this.props;
		const { memberProjectsTitles, selectedRevokeFeature, selectedSyncFeature } = this.state;
		let memberObj = member || {};
		const adminMode = (viewer || {}).adminMode;
		const revokeOptions = [
			'buildings',
			'checklists-checklists',
			'checklists-items',
			'checklists-itemsInstances',
			'properties-types',
			'properties-mapping',
			'properties-instances-locationInfo',
			'properties-instances-employeesInfo',
			'properties-instances-equipmentInfo',
			'drawings/buildings',
			'drawings/floors',
			'drawings/units',
			'floors',
			'posts',
			'projects',
			'units',
			'employees',
			'equipment',
		].map(feature => {
			return { value: feature, label: feature };
		});

		const syncOptions = ['checklistInstances', 'posts', 'propertiesInstances'].map(feature => {
			return { value: feature, label: feature };
		});

		return (
			<Card style={style}>
				<div style={{ width: 150, marginTop: '-75px', alignSelf: 'center' }}>
					<Avatar src={memberObj.avatar} />
				</div>
				<CardBody
					style={{
						padding: theme.paddingSize,
						maxHeight: 550,
						overflowY: 'scroll',
					}}
				>
					<GridContainer spacing={16}>
						<GridItem xs={4}>Id</GridItem>
						<GridItem xs={8}>{memberObj.id}</GridItem>
						<GridItem xs={4}>Display name</GridItem>
						<GridItem xs={8}>{memberObj.displayName}</GridItem>
						<GridItem xs={4}>Company</GridItem>
						<GridItem xs={8}>
							<CompanyName companyId={memberObj.companyId} />
						</GridItem>
						<GridItem xs={4}>Phone number</GridItem>
						<GridItem xs={8}>{memberObj.phoneNumber}</GridItem>
						<GridItem xs={4}>Title</GridItem>
						<GridItem xs={8}>{titles.getNested([memberObj.title, 'getTitle', ''])}</GridItem>
						<GridItem xs={4}>Trades</GridItem>
						<GridItem xs={8}>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								{Object.keys(memberObj.trades || {}).map(tradeId => (
									<div key={tradeId}>{trades.getNested([tradeId, 'getTitle'], '')}</div>
								))}
							</div>
						</GridItem>
						<GridItem xs={4}>Projects</GridItem>
						<GridItem xs={8}>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								{Boolean(adminMode != 1) ? (
									<div style={{ color: theme.brandDanger }}>You must switch to AllProjects mode</div>
								) : (
									memberProjectsTitles.map(p => <div key={p}>{p}</div>)
								)}
							</div>
						</GridItem>
						<GridItem xs={6}>
							<Select
								value={selectedRevokeFeature}
								onChange={this.onRevokeFeatureSelect}
								styles={theme.basicSelectStyles}
								options={revokeOptions}
							/>
						</GridItem>
						<GridItem xs={6}>
							<Select
								value={selectedSyncFeature}
								onChange={this.onSyncFeatureSelect}
								styles={theme.basicSelectStyles}
								options={syncOptions}
							/>
						</GridItem>
						<GridItem xs={6}>
							<Button fullWidth onClick={this.revokeCache} disabled={Boolean(adminMode != 1)}>
								{Boolean(selectedProjectId) ? "Revoke project's cache" : 'Revoke all projects cache'}
							</Button>
						</GridItem>
						<GridItem xs={6}>
							<Button fullWidth onClick={() => this.syncLocalStorage(true)} disabled={Boolean(adminMode != 1)}>
								{'Sync - info + upload'}
							</Button>
							<Button fullWidth onClick={() => this.syncLocalStorage()} disabled={Boolean(adminMode != 1)}>
								{'Sync - info only'}
							</Button>
							<Button fullWidth onClick={this.getLogUrl} disabled={Boolean(adminMode != 1)}>
								{'Go to log'}
							</Button>
						</GridItem>
						<GridItem xs={12}>
							<Button fullWidth onClick={this.connectAs} disabled={Boolean(!memberObj.phoneNumber)} color='warning'>
								{'Login as member'}
							</Button>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	}
}

const styles = {
	textCenter: {
		textAlign: 'center',
		alignItems: 'center',
		alignContent: 'center',
		justify: 'center',
	},
};

MemberCard = withStyles(theme.combineStyles(buttonStyle, styles))(MemberCard);
MemberCard = withRouter(MemberCard);
const enhance = compose(
	connectContext(ProjectContext.Consumer),
	connect(
		state => ({
			globalGroups: state.groups.globalGroups,
			projectGroups: state.groups.projectGroups,
		}),
		{ getMemberActivityInfo, replaceUser, startToast, syncUsersLocalStorage },
	),
);
export default enhance(MemberCard);
