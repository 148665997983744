import * as actions from './actions';

import Permission from './permission.js';
import { Map } from 'immutable';
import { Record } from '../transit';

const InitialState = Record({
  map: Map(),
  lastUpdateTS: 0
}, 'permissions');

export default function permissionsReducer(state = new InitialState, action) {
  switch (action.type) {
    case actions.GET_PERMISSIONS + "_SUCCESS": {
      if (!action.payload)
        return state;

      const { lastUpdateTS, permissions } = action.payload;    
      var newMap = new Map();
      (permissions || {}).loopEach((key, permission) => { if (permission) newMap = newMap.set(key, new Permission({permissionKey: key, actions: permission})); })
      state = state.set('lastUpdateTS', lastUpdateTS ? lastUpdateTS : 0);
      return state.set('map', newMap);
    }
  }
  
  return state;
}
