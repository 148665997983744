import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

// @material-ui/icons
import ExpandMore from "@material-ui/icons/ExpandMore";
import collapse from '../../assets/img/icons/collapse.png';

import accordionStyle from "../../assets/jss/material-dashboard-pro-react/components/accordionStyle.jsx";
import theme from '../../assets/css/theme'

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.active
    };
  }
  handleChange = panel => (event, expanded) => {
    this.setState({
      active: expanded ? panel : -1
    });
  };
  render() {
    const { classes, collapses, rtl, style={} } = this.props;
    return (
      <div style={style} className={classes.root}>
        {collapses.map((prop, key) => {
          return (
            <ExpansionPanel
              expanded={this.state.active === key}
              onChange={this.handleChange(key)}
              key={key}
              classes={{
                root: classes.expansionPanel,
                expanded: Boolean((style||{}).expanded) ? "" : classes.expansionPanelExpanded
              }}
              style={(style||{}).container}
            >
              <ExpansionPanelSummary
                classes={{
                  root: classes.expansionPanelSummary,
                  expanded: classes.expansionPanelSummaryExpaned,
                  content: classes.expansionPanelSummaryContent,
                  expandIcon: classes.expansionPanelSummaryExpandIcon
                }}
              >

                <h4  className={classes.title} style={{color: theme.brandPrimary, fontWeight: 400, fontSize: 20, marginLeft: 25, marginRight: 25 }}>{prop.title}</h4>

                <img 
                  src={collapse} 
                  style={{
                    transform: (Boolean(this.state.active === key) ? 'rotate(90deg)' : (rtl ? 'rotate(180deg)' : '')),
                    padding: 0, height: 10,
                    transition: '0.2s', position: 'absolute', [rtl ? 'right' : 'left']: theme.paddingSize, top: 15 }}/>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                {prop.content}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </div>
    );
  }
}

Accordion.defaultProps = {
  active: -1
};

Accordion.propTypes = {
  classes: PropTypes.object.isRequired,
  // index of the default active collapse
  active: PropTypes.number,
  collapses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node
    })
  ).isRequired
};

export default withStyles(accordionStyle)(Accordion);