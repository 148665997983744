import React from "react";
import { hoistStatics, compose } from "recompose";
import { connect } from "react-redux";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import MultiCheckSelect from "./MultiCheckSelect";
import theme from "../../assets/css/theme";
import InputField from "./InputField";
import systemMessages from "../../../common/app/systemMessages";
import _ from "lodash";

// expectedProps: extraData obj from item ({ subjectName: { [id]: id } }) --> selected ids
// onChange: send full object with changes

class LinkExtraData extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.handleSelectSubject = this.handleSelectSubject.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
    this.state = {
      availableSubjectNames: [],
      currSubjectName: null,
      subjectItems: {},
    };
  }

  componentDidMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};

    if (props.isValDiff(["propertiesTypes"], nextProps)) {
      const { propertiesTypes } = nextProps;
      // TODO: remove filter once types are supported
      newStateChanges.availableSubjectNames = Object.keys(propertiesTypes)
        .filter((name) =>
          ["checklistItemsInfo", "locationsInfo"].includes(name)
        )
        .map((name) => ({ id: name, title: name }));
    }

    if (
      props.isValDiff(["extraDataObj"], nextProps) ||
      props.isValDiff(["propertiesTypes"], nextProps)
    ) {
      const { propertiesSections, propertiesTypes, extraDataObj } = nextProps;

      let subjectItems = Object.assign({}, propertiesTypes);
      subjectItems.loopEach((subjectName, properties) => {
        subjectItems[subjectName] = Object.assign(
          {},
          subjectItems[subjectName]
        );
        properties.loopEach((propId, prop) => {
          if (prop.getNested(["isExtra"], false)) {
            delete subjectItems[subjectName][propId];
            return;
          }

          prop = Object.assign({}, prop.toJS ? prop.toJS() : prop);
          let newTitle = "";
          if (prop.getNested(["sectionId"], false)) {
            let section = propertiesSections.getNested(
              [subjectName, prop.sectionId],
              {}
            );
            let sectionTitle = section.getCementoTitle
              ? section.getCementoTitle()
              : section.getNested(["title"], "");
            if (sectionTitle) newTitle += sectionTitle + " - ";
          }

          newTitle += prop.getCementoTitle();

          prop.title = newTitle;

          if (extraDataObj.getNested([propId], false)) prop.checked = true;

          subjectItems = subjectItems.setNested([subjectName, propId], prop);
        });
      });

      newStateChanges.subjectItems = subjectItems;
    }

    if (Object.keys(newStateChanges).length) this.setState(newStateChanges);
  }

  onCheckChange(allItems) {
    const { currSubjectName } = this.state;
    const { onChange, extraDataObj } = this.props;

    if (!onChange) return;

    let newExtraDataObj = Object.assign({}, extraDataObj);
    let lastOrdinalNo = Object.values(newExtraDataObj).reduce(
      (currMax, curr) =>
        Boolean((curr.ordinalNo || 0) > currMax)
          ? Number(curr.ordinalNo)
          : currMax,
      0
    );
    allItems.loopEach((propId, propInfo) => {
      const isChecked = propInfo.getNested(["checked"], false);
      if (isChecked && !newExtraDataObj[propId])
        newExtraDataObj[propId] = {
          id: propId,
          subjectName: currSubjectName,
          ordinalNo: ++lastOrdinalNo,
        };

      if (!isChecked && newExtraDataObj[propId]) delete newExtraDataObj[propId];
    });

    onChange(newExtraDataObj);
  }

  handleSelectSubject(newSubjectObj) {
    let newSubjectName = Object.values(newSubjectObj)[0];
    this.setState({ currSubjectName: newSubjectName });
  }

  render() {
    const { currSubjectName, subjectItems, availableSubjectNames } = this.state;
    const { extraDataObj, lang, propertiesTypes } = this.props;

    return (
      <>
        <div style={{ height: "600px", padding: theme.paddingSize }}>
          <InputField
            name={systemMessages.selectSubject}
            type={"SelectionList"}
            value={{ [currSubjectName]: currSubjectName }}
            values={availableSubjectNames}
            onChange={this.handleSelectSubject}
          />
          <MultiCheckSelect
            items={Object.values(
              subjectItems.getNested([currSubjectName], {})
            ).reduce(
              (acc, p) =>
                _.set(
                  acc,
                  [p.id],
                  Object.assign({}, p, { checked: Boolean(extraDataObj[p.id]) })
                ),
              {}
            )}
            onChange={this.onCheckChange}
            height={500}
            width={300}
          />
        </div>
      </>
    );
  }
}

const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      lang: state.app.lang,
      rtl: state.app.rtl,
    }),
    {}
  )
);

export default enhance(LinkExtraData);
