export const SET_PROJECT_GROUPS_ROULES_BY_COMPANYIDS = 'SET_PROJECT_GROUPS_ROULES_BY_COMPANYIDS';
export const GET_GROUPS = 'GET_GROUPS';
export const START_GROUPS_LISTENER = 'START_GROUPS_LISTENER';
export const END_GROUPS_LISTENER = 'END_GROUPS_LISTENER';

export function startGroupsListener() { 
  return ({ firebaseDatabase, dispatch }) => {
    const getPromise = async () => {
      await firebaseDatabase().ref('groups').on('value', function(snapshot) {
        dispatch({
          type: GET_GROUPS,
          payload: { groups: snapshot.val()}
        });
      });
    }

    return {
      type: START_GROUPS_LISTENER,
      payload: getPromise()
    };
  };
}

export function endGroupsListener() {
  return ({ firebaseDatabase }) => {
		firebaseDatabase().ref('groups/').off('value');

    return {
      type: END_GROUPS_LISTENER,
      payload: {}
    };
  };
}


export function setProjectGroupRulesByCompanyIds(projectId, groupsByCompanyIds) { 
  return ({ firebase, getState }) => {
    const getPromise = async () => {
      if (!projectId || !groupsByCompanyIds || Object.keys(groupsByCompanyIds).length == 0)
        return null
        
      let projectGroups = getState().getNested(['groups', 'projectGroups', projectId], {});
      let newGroups = Object.assign({}, projectGroups.groups);

      (groupsByCompanyIds || {}).loopEach((companyId, groupsArr) => {
        newGroups = clearCompanyIdFromAllGroups(newGroups, companyId);
        if (groupsArr != null && groupsArr.length) {
          groupsArr.forEach(g => {
            if (!newGroups[g.value] || !newGroups[g.value].rules)
              newGroups[g.value] = { id: g.value, title: '', rules: []};
            newGroups[g.value].rules.push({"companyId": companyId});
          })
        }
      })

      let updates = {};
      updates['groups/projects/' + projectId] = newGroups;
      firebase.update(updates)
      return { projectId }
    }

    return {
      type: SET_PROJECT_GROUPS_ROULES_BY_COMPANYIDS,
      payload: getPromise()
    };
  };
}

function clearCompanyIdFromAllGroups(groups, companyId) {
  let newGroups = Object.assign({}, groups);
  newGroups.loopEach((id, g) => { 
    if (!g.rules)
      return {}; 

    for (let i = g.rules.length - 1; i >= 0; i--) {
      if (g.rules[i].companyId == companyId)
        g.rules.splice(i, 1);
    }

    if (g.rules.length == 0)
      delete newGroups[id];
  })

  return newGroups;
}