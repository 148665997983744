import { Record } from '../transit';

const PropertyInstance = Record({
  id: null,
  propType: null,
  propId: null, // typeId ===> propId
  data: null,
  updatedTS: null,
  createdTS: null,
  isDeleted: null,
  isLocal: null,
  parentId: null, // locationId ===> parentId
  action: null,
}, 'propertyInstance');

export default PropertyInstance;
