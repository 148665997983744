import { platformActions } from '../platformActions';
import { envParams } from '../configureMiddleware';
import projectsMessages from './projectsMessages';


export async function fetchProjectData(projectId) {
  if (!projectId)
    return;

  let url = `${envParams.apiServer}/v1/gateway/projects/${projectId}`;
  let res = await platformActions.net.fetch(url);
  res = await res.getJson();

  return res;
}

export function navigateToProject(props, projectId, toLocation = 'issues/dashboard/', overwriteURL = false, newTab = false) {
  const { saveURLParams, clearAllUrlParams, history, startLoading, selectedProjectId, enterProject } = props;

  if (!projectId)
    return null;

  let redirectUrl = overwriteURL ? overwriteURL : `/main/projectContainerPage/${projectId}/${toLocation}`;
  if (newTab && window) {
    window.open(redirectUrl, '_blank');
    return;
  }

  clearAllUrlParams();
  let urlParams = { selectedProjectId: projectId, scope: "project" };
  if (selectedProjectId !== projectId) {
    // startLoading({ title: projectsMessages.loadingProjectData, overlay: true });
    if (enterProject) enterProject(projectId, selectedProjectId);
  }

  history.push(redirectUrl);
  urlParams.containerUrl = redirectUrl;

  saveURLParams(urlParams);
}