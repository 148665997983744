import projectManagerMessages from '../../../../common/app/projectManagerMessages';
import { Field } from './projectManagerConfig';


const {
  buildingName,
  buildingGroundFloor,
  buildingTopFloor,
  floorName,
  unitName,
} = projectManagerMessages.inputLabels;

///////////////////////////////////////////////////////
/////////////////////// Fields ////////////////////////
///////////////////////////////////////////////////////

const buildingNameField        = new Field(buildingName, 'String', ['locationData', 'title'], null, { fullWidth: true })
const buildingAdressField      = new Field('Address', 'String', ['locationData', 'address'], null, { fullWidth: true })
const buildingMinFloorField    = new Field(buildingGroundFloor, 'SelectionList', ['locationData', 'minFloor'], Array.from(new Array(501)).map((_, index) => ({ id: (-index), title: (-index).toString() })), { fullWidth: true, autoSort: false })
const buildingMaxFloorField    = new Field(buildingTopFloor, 'SelectionList', ['locationData', 'maxFloor'], Array.from(new Array(501)).map((_, index) => ({ id: index, title: index.toString() })), { fullWidth: true, autoSort: false })

const floorNameField    = new Field(floorName, 'String', ['locationData', 'description'], null, { fullWidth: true })
// const floorTypeField    = new Field('Floor type', 'String', ['locationData', 'type'], null, { fullWidth: true })

const unitNameField    = new Field(unitName, 'String', ['locationData', 'title'], null, { fullWidth: true })

// const floorNameField = new Field(numberOfBuildings, 'SelectionList', ['projectStructure', 'numOfBuildings'], Array.from(new Array(100)).map((_, index) => ({ id: ++index, title: index.toString() })), { autoSort: false, fullWidth: true, style: { flexBasis: '100%' } });

const importExportField = new Field(null, 'Excel', ['importedFile'], null, { mode: { importOnly: true } });

export const buildingFields = [
  buildingNameField,
  buildingAdressField,
  buildingMinFloorField,
  buildingMaxFloorField,
];

export const floorFields = [
  floorNameField,
  // floorTypeField,
];

export const unitFields = [
  unitNameField,
];
