import React from 'react';
import { connectContext } from 'react-connect-context';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose, hoistStatics } from 'recompose';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { getLocationTitle } from './funcs';
import { ProjectContext } from '../../../common/projects/contexts';
import { track } from '../../../common/lib/reporting/actions';
import ChecklistPage from '../Checklists/ChecklistPage';
import PostsPage from '../Posts/PostsPage';
import ObjectPropertiesPage from '../Properties/ObjectPropertiesPage';
import DrawingsPage from './DrawingsPage';
import { saveURLParams, setHeaderTitle } from '../../../common/ui/actions';
import SidebarBuilding from '../../layouts/SidebarBuilding';
import theme from '../../assets/css/theme';
import ChecklistManager from '../ProjectManager/ChecklistManager';
import { ALL_BUILDINGS_ID } from '../../../common/app/constants';

class LocationContainerPage extends React.Component {
	constructor(props) {
		super(props);
		this.getLocationTitle = this.getLocationTitle.bind(this);
		this.saveParamsToState = this.saveParamsToState.bind(this);
		this.state = {};
	}

	UNSAFE_componentWillMount() {
		this.saveParamsToState({}, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.saveParamsToState(this.props, nextProps);
	}

	componentWillUnmount() {
		const { saveURLParams } = this.props;
		saveURLParams({ buildingId: null, floorId: null, unitId: null });
	}

	getLocationTitle(nextProps) {
		let singleBuildingProject = nextProps.getNested(['buildings'], {}).size < 2;
		let nextBuildingId = nextProps.getNested(['match', 'params', 'buildingId']);
		let nextFloorId = nextProps.getNested(['match', 'params', 'floorId']);
		let nextUnitId = nextProps.getNested(['match', 'params', 'unitId']);
		let locationsTitle = getLocationTitle(
			nextProps.getNested(['buildings']),
			nextProps.getNested(['floors']),
			nextProps.getNested(['units']),
			nextBuildingId,
			nextFloorId,
			nextUnitId,
			null,
			nextProps.intl,
			singleBuildingProject,
		);
		let titlesArr = [];
		[locationsTitle.buildingTitle, locationsTitle.floorTitle, locationsTitle.unitTitle].forEach(t => {
			if (t) titlesArr.push(t);
		});

		return titlesArr;
	}

	saveParamsToState(props, nextProps) {
		const { saveURLParams, track, setHeaderTitle, buildings } = nextProps;
		let stateChanges = {};
		let nextBuildingId = nextProps.getNested(['match', 'params', 'buildingId']);
		let nextFloorId = nextProps.getNested(['match', 'params', 'floorId']);
		let nextUnitId = nextProps.getNested(['match', 'params', 'unitId']);
		let nextContentType = nextProps.getNested(['match', 'params', 'contentType']);
		let nextSelectedProjectId = nextProps.getNested(['match', 'params', 'selectedProjectId']);

		let urlBuildingIdChanged = props.getNested(['match', 'params', 'buildingId']) != nextBuildingId;
		let urlFloorIdChanged = props.getNested(['match', 'params', 'floorId']) != nextFloorId;
		let urlUnitIdChanged = props.getNested(['match', 'params', 'unitId']) != nextUnitId;
		let urlContentTypeChanged = props.getNested(['match', 'params', 'contentType']) != nextContentType;
		let urlSelectedProjectId = props.getNested(['match', 'params', 'selectedProjectId']) != nextSelectedProjectId;

		let buildingsChanged =
			props.getNested(['buildings', nextBuildingId]) != nextProps.getNested(['buildings', nextBuildingId]);
		let floorsChanged =
			props.getNested(['floors', nextBuildingId, nextFloorId]) !=
			nextProps.getNested(['floors', nextBuildingId, nextFloorId]);
		let unitsChanged =
			props.getNested(['units', nextBuildingId, nextUnitId]) !=
			nextProps.getNested(['units', nextBuildingId, nextUnitId]);

		if (props.buildings != nextProps.buildings) {
			let buildingIdsArray = buildings.keySeq ? buildings.keySeq().toArray() : Object.keys(buildings);
			stateChanges.isSingleBuilding = buildingIdsArray.length == 1;
			stateChanges.firstBuildingId = buildingIdsArray[0];
		}

		if (urlContentTypeChanged) stateChanges.headerParams = null;

		if (
			urlBuildingIdChanged ||
			urlFloorIdChanged ||
			urlUnitIdChanged ||
			buildingsChanged ||
			floorsChanged ||
			unitsChanged ||
			urlContentTypeChanged
		) {
			let urlParamLocationId =
				nextUnitId == '_'
					? nextFloorId == '_'
						? nextBuildingId == '_'
							? null
							: nextBuildingId
						: nextFloorId
					: nextUnitId;
			let nextContainerUrl =
				'/main/projectContainerPage/' +
				nextSelectedProjectId +
				'/' +
				nextProps.getNested(['match', 'params', 'contentType']) +
				'/locationContainerPage' +
				'/' +
				nextProps.getNested(['match', 'params', 'buildingId']) +
				'/' +
				nextProps.getNested(['match', 'params', 'floorId']) +
				'/' +
				nextProps.getNested(['match', 'params', 'unitId']);
			let urlParams = { urlParamLocationId, containerUrl: nextContainerUrl };

			if (urlBuildingIdChanged) urlParams.buildingId = nextBuildingId;
			if (urlFloorIdChanged) urlParams.floorId = nextFloorId;
			if (urlUnitIdChanged) urlParams.unitId = nextUnitId;
			if (urlContentTypeChanged) urlParams.contentType = nextContentType;

			saveURLParams(urlParams);
			let nextTitle = this.getLocationTitle(nextProps).join(nextProps.rtl ? ' / ' : ' \\ ');
			setHeaderTitle(nextTitle);

			let nextType =
				nextUnitId == '_' ? (nextFloorId == '_' ? (nextBuildingId == '_' ? null : 'building') : 'floor') : 'unit';

			if (urlParamLocationId && buildings.size > 0 && buildings.get(nextSelectedProjectId))
				stateChanges.allProjectViewMode =
					urlParamLocationId === 'all' ||
					(buildings.size === 1 && buildings.get(nextSelectedProjectId).keySeq().last() === urlParamLocationId);

			if (urlBuildingIdChanged || urlFloorIdChanged || urlUnitIdChanged)
				track('enterUnitPage', {
					unitTitle: nextTitle,
					unitId: nextUnitId || nextFloorId || nextBuildingId,
					type: nextType,
					contentType: nextContentType,
				});
		}

		if (Object.keys(stateChanges).length > 0) this.setState(stateChanges);
	}

	render() {
		const {
			urlParams,
			match,
			filteredPosts,
			currViewPosts,
			location,
			intl,
			rtl,
			setHeaderParams,
			uiParams,
			filterVal,
			setFilterVal,
			clearFilterVal,
		} = this.props;
		const { isSingleBuilding, firstBuildingId, allProjectViewMode } = this.state;

		let URL = match.url.endsWith('/') ? match.url : match.url + '/';
		let query = this.props.getNested(['location', 'search'], '');
		let buildingId = urlParams.getNested(['buildingId'], '_');
		let floorId = urlParams.getNested(['floorId'], '_');
		let unitId = urlParams.getNested(['unitId'], '_');
		let contentType = urlParams.getNested(['contentType'], '_');
		let locationId = unitId == '_' ? (floorId == '_' ? buildingId : floorId) : unitId;
		let locationType = unitId == '_' ? (floorId == '_' ? 'building' : 'floor') : 'unit';
		let defaultBuildingId = isSingleBuilding ? firstBuildingId : ALL_BUILDINGS_ID;
		let selectedProjectId = match.getNested(['params', 'selectedProjectId']);
		let urlLocationParams = (buildingId == '_' ? defaultBuildingId : buildingId) + '/' + floorId + '/' + unitId;
		let onlySideNav = uiParams.get('onlySideNav');
		let sideBarWidth = theme.sidebarWidth;
		let itemsMode = null;
		if (location && location.search) {
			let urlQueryParams = new URLSearchParams(location.search);
			let urlFilter;
			if (urlQueryParams) urlFilter = urlQueryParams.get('itemType');

			itemsMode = urlFilter || 'all';
		}
		//if (match.params.buildingId == 'all' && isSingleBuilding)
		//  this.props.history.push(match.url.replace('all/_/_', urlLocationParams));
		let buildingSidebarStyle = onlySideNav
			? {
					marginTop: theme.headerHeightSecondary,
					height: 'calc(100vh - ' + (theme.headerHeight + theme.headerHeightSecondary) + 'px)',
			  }
			: {};
		return (
			<div
				style={{
					display: 'flex',
					flex: 1,
					backgroundColor: theme.backgroundColorBright,
					width: '100%',
					flexDirection: 'column',
				}}
			>
				<div
					style={{
						height: 'calc(100vh - ' + (theme.headerHeight + (onlySideNav ? 0 : theme.headerHeightSecondary)) + 'px)',
						flexDirection: 'row',
						display: 'flex',
					}}
				>
					<SidebarBuilding
						rtl={rtl}
						intl={intl}
						style={buildingSidebarStyle}
						location={location}
						selectedProjectId={selectedProjectId}
						contentType={contentType}
						showBadges={
							contentType == 'issues' ||
							contentType == 'records' ||
							contentType == 'safety' ||
							contentType == 'drawings'
						}
						filteredPosts={filteredPosts}
					/>
					<div
						style={{
							height: '100%',
							width: 'calc(100vw - ' + sideBarWidth + 'px)',
							flex: 1,
						}}
					>
						<Switch>
							<Route
								path='/main/projectContainerPage/:selectedProjectId/qa/locationContainerPage/:buildingId/:floorId/:unitId'
								render={props => (
									<ChecklistPage history={props.history} match={props.match} setHeaderParams={setHeaderParams} />
								)}
							/>
							<Route
								path='/main/projectContainerPage/:selectedProjectId/projectManager/checklistManagement'
								render={props => (
									<ChecklistManager history={props.history} match={props.match} setHeaderParams={setHeaderParams} />
								)}
							/>
							<Route
								path='/main/projectContainerPage/:selectedProjectId/info/locationContainerPage/:buildingId/:floorId/:unitId'
								render={props => (
									<ObjectPropertiesPage
										history={props.history}
										match={props.match}
										objectId={locationId}
										subjectName={'locationsInfo'}
										contentType={contentType}
										setHeaderParams={setHeaderParams}
										allProjectViewMode={allProjectViewMode}
									/>
								)}
							/>
							<Route
								path='/main/projectContainerPage/:selectedProjectId/safety/locationContainerPage/:buildingId/:floorId/:unitId'
								render={props => (
									<PostsPage
										history={props.history}
										match={props.match}
										locationsAggregationView={true}
										itemsMode={itemsMode}
										data={filteredPosts}
										allPosts={currViewPosts}
										contentType={contentType}
										setHeaderParams={setHeaderParams}
										filterVal={filterVal}
										setFilterVal={setFilterVal}
										clearFilterVal={clearFilterVal}
										allProjectViewMode={allProjectViewMode}
									/>
								)}
								defaultGroupBy={['issueState']}
							/>
							<Route
								path='/main/projectContainerPage/:selectedProjectId/issues/locationContainerPage/:buildingId/:floorId/:unitId'
								render={props => (
									<PostsPage
										history={props.history}
										match={props.match}
										locationsAggregationView={true}
										itemsMode={itemsMode}
										data={filteredPosts}
										allPosts={currViewPosts}
										contentType={contentType}
										setHeaderParams={setHeaderParams}
										filterVal={filterVal}
										setFilterVal={setFilterVal}
										clearFilterVal={clearFilterVal}
										allProjectViewMode={allProjectViewMode}
									/>
								)}
							/>
							<Route
								path='/main/projectContainerPage/:selectedProjectId/records/locationContainerPage/:buildingId/:floorId/:unitId'
								render={props => (
									<PostsPage
										history={props.history}
										match={props.match}
										locationsAggregationView={true}
										itemsMode={itemsMode}
										data={filteredPosts}
										allPosts={currViewPosts}
										contentType={contentType}
										setHeaderParams={setHeaderParams}
										filterVal={filterVal}
										setFilterVal={setFilterVal}
										clearFilterVal={clearFilterVal}
										allProjectViewMode={allProjectViewMode}
									/>
								)}
							/>
							<Route
								path='/main/projectContainerPage/:selectedProjectId/drawings/locationContainerPage/:buildingId/:floorId/:unitId'
								render={props => (
									<DrawingsPage
										history={props.history}
										match={props.match}
										locationId={locationId}
										locationType={locationType}
										contentType={contentType}
										setHeaderParams={setHeaderParams}
										intl={intl}
										locationTitle={this.getLocationTitle(this.props)}
										allProjectViewMode={allProjectViewMode}
									/>
								)}
							/>
							<Route
								path='/main/projectContainerPage/:selectedProjectId/:contentType/locationContainerPage'
								render={() => <Redirect to={URL + urlLocationParams + query} />}
							/>
						</Switch>
					</div>
				</div>
			</div>
		);
	}
}

LocationContainerPage = withRouter(LocationContainerPage);
LocationContainerPage = injectIntl(LocationContainerPage);
const enhance = compose(
	connectContext(ProjectContext.Consumer),
	connect(
		state => ({
			headerTitle: state.ui.headerTitle,
			urlParams: state.ui.urlParams,
			rtl: state.app.rtl,
			uiParams: state.ui.uiParams,
		}),
		{ saveURLParams, setHeaderTitle, track },
	),
);
export default enhance(LocationContainerPage);
