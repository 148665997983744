import React from "react";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import { setAdminMode } from "../../../common/users/actions";
import { replaceUser } from "../../../common/auth/actions";
import { saveUIParams } from "../../../common/ui/actions";
import theme from "../../assets/css/theme";
import ProjectsSelector from "../../views/Projects/ProjectsSelector.js";
import { HeaderLinks, HeaderSideLinks } from "../index";
import headerStyle from "../../assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";

class Header extends React.Component {
  ALWAYS_MINIMIZE = true;

  constructor(props) {
    super(props);
    this.state = { minimize: this.ALWAYS_MINIMIZE };
  }

  render() {
    const {
      classes,
      rtl,
      intl,
      urlParams,
      routes,
      onRouteSelected,
      isCementoTeamViewer,
      location,
      viewer,
      replaceUser,
      setAdminMode,
      projectPermitted,
    } = this.props;

    return (
      <div style={{ zIndex: theme.zIndexes.header, top: 0 }}>
        <AppBar
          className={classes.appBar}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: theme.headerColorDark,
            height: theme.headerHeight,
          }}
        >
          <div style={{ alignSelf: "flex-start" }}>
            <ProjectsSelector
              style={{
                height: theme.headerHeight,
                display: "flex",
                alignItems: "center",
                width: theme.sidebarWidth,
              }}
            />
          </div>
          {Boolean(projectPermitted) && (
            <div style={{ height: "100%", overflow: "hidden" }}>
              <HeaderLinks
                key="mainHeader"
                onRouteSelected={onRouteSelected}
                location={location}
                rtl={rtl}
                intl={intl}
                urlParams={urlParams}
                routes={routes}
                isCementoTeamViewer={
                  isCementoTeamViewer && viewer.adminMode == 1
                }
              />
            </div>
          )}
          <div>
            <HeaderSideLinks
              setAdminMode={setAdminMode}
              replaceUser={replaceUser}
              rtl={rtl}
              viewer={viewer}
              isCementoTeamViewer={isCementoTeamViewer}
            />
          </div>
        </AppBar>
      </div>
    );
  }
}

const styles = {
  primaryHeader: {
    transition: "all .33s cubic-bezier(0.4, 0, 0.2, 1) 0s",
    height: theme.headerHeight,
    zIndex: theme.zIndexes.headerTitles,
    borderBottom: theme.borderLineNeutralLight + "50",
  },
  secondaryTitle: {
    transition: "all .33s cubic-bezier(0.4, 0, 0.2, 1) 0s",
    zIndex: theme.zIndexes.headerTitles,
    padding: 20,
    width: "100%",
    lineHeight: "1.5em",
    color: "white",
    fontSize: "40px",
    textAlign: "center",
    alignSelf: "center",
    fontWeight: "bold",
    margin: 0,
  },
  secondaryTitleContainer: {
    minHeight: theme.headerHeight,
    zIndex: theme.zIndexes.headerTitles,
    width: "100%",
  },
};

Header = withStyles(theme.combineStyles(headerStyle, styles))(Header);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(null, { setAdminMode, replaceUser, saveUIParams })
);
export default enhance(Header);
