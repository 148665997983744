import { Record } from '../transit';

const ChecklistItem = Record({
  id: null,
  checklistIds: null,
  onlyLocations: null,
  UIType: null,
  contentType: null,
  extraInfo: null,
  permissions: null,
  description: null,
  extraDescription: null,
  ordinalNo: null,
  universalId: null,
  trade: null,
  prefix: null,
  period: null,
  weight: 1,
  requirements : null,  // { img, desc, drawing, file }
  isDeleted: null,
  extraData: null,
  isMandatory: null,
}, 'checklistItem', false);

export default ChecklistItem;
