import { defineMessages } from 'react-intl';

export default defineMessages({
  empty: {
    defaultMessage: 'No contacts',
    id: 'contacts.contacts.empty'
  },
  smsInvitation: {
    defaultMessage: 'Invite',
    id: 'contacts.contacts.smsInvitation'
  },
  smsInvitationSent: {
    defaultMessage: 'Invitation Sent',
    id: 'contacts.contacts.smsInvitationSent'
  },
  smsInvitationContent: {
    defaultMessage: "Hi, I want to invite you to join me on Cemento app to the new project i'm working on. Download here - https://goo.gl/p5iD2W",
    id: 'contacts.sms.content'
  },
  selectMobileNumber: {
    defaultMessage: 'Select user mobile number',
    id: 'contacts.contacts.selectMobileNumber'
  },
  contactDetails: {
    defaultMessage: 'Contact detiles',
    id: 'contacts.contacts.contactDetails'
  },
  fillDisplayName: {
    defaultMessage: 'Please fill Display Name',
    id: 'contacts.fields.fillDisplayName'
  },
  writeMobileNumber: {
    defaultMessage: 'Write user mobile number',
    id: 'contacts.contacts.writeMobileNumber'
  },
  mobileNumber: {
    defaultMessage: 'Mobile Number',
    id: 'contacts.contacts.mobileNumber'
  },
  numberNotValid: {
    defaultMessage: 'The contact number is not valid, please choose other',
    id: 'contacts.contacts.numberNotValid'
  },
  writeDisplayName: {
    defaultMessage: 'Write user display name',
    id: 'contacts.contacts.writeDisplayName'
  },
  displayName: {
    defaultMessage: 'Display Name',
    id: 'contacts.contacts.displayName'
  },
  phoneContacts: {
    defaultMessage: 'Phone Contacts',
    id: 'contacts.contacts.phoneContacts'
  },
  cementoMembers: {
    defaultMessage: 'Cemento Members',
    id: 'contacts.contacts.cementoMembers'
  },  
  endOfList: {
    defaultMessage: 'No more contacts',
    id: 'contacts.contacts.endOfList'
  },  
  smsAccessTitle: {
    defaultMessage: 'Send text message',
    id: 'access.sms.title'
  },
  smsAccessMessage: {
    defaultMessage: 'Cemento App needs access to send invitation text message',
    id: 'access.sms.message'
  },  
  loading: {
    defaultMessage: 'Loading Contacts',
    id: 'contacts.contacts.loading'
  }, 
  search: {
    defaultMessage: 'Search by name',
    id: 'contacts.contacts.search'
  }, 
  recentAssignees: {
    defaultMessage: 'Recent assignees',
    id: 'contacts.contacts.recentAssignees'
  }, 
});
