import * as actions from './actions';
import { firebaseActions } from '../lib/redux-firebase';
import * as usersActions from '../users/actions';
import * as postsActions from '../posts/actions';
import { REPLACE_USER } from '../auth/actions';

import Issue from '../posts/post.js';

import { OrderedMap } from 'immutable';
import { Record } from '../transit';

const InitialState = Record({
  map: OrderedMap(),
  didLoad: OrderedMap(),
  isAdmin: false,
}, 'issues');

const initialState = new InitialState;

export default function issuesReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;


  
  return state;
}


