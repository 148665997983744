import { Record } from '../transit';

const ChecklistItemInstance = Record({
  id: null,
  checklistItemId: null,
  status: null,
  updatedTS: null,
  createdTS: null,
  targetTS: null,
  isDeleted: null,
  locationId: null,
  isLocal: null,
  checklistId: null,
  extraData: null
}, 'checklistItemInstance', false);

export default ChecklistItemInstance;
