import { injectIntl as originlInjectIntl } from 'react-intl';
import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

import { getAppState } from '../configureMiddleware';

// this.getAppStateRef = false;
// export function setGlobalInjectIntl(getAppState) {
// 	if (!this.getAppStateRef)
// 			this.getAppStateRef = getAppState;
// 		console.log('setGlobalInjectIntl', Boolean(this.getAppStateRef))
// }
// export function injectIntl(comp) {
// 	console.log('start injectIntl')

// 	console.log('check getAppState exsist')
// 	if (this.getAppStateRef) {
// 		console.log('getAppState exsist')
// 		var intl = this.getAppStateRef().getNested(['projects', 'intl']);
// 	}

// 	console.log('injectIntl intl', Boolean(intl));


// 	if (!intl)
// 		comp = originlInjectIntl(comp);
// 	else {
// 		console.log('NOW IT IS INNN')
// 		comp = Object.assign(comp, { intl });
// 	}

// 	return comp;
// };

export function injectIntl(WrappedComponent) {

  class InjectIntl extends Component {
    render() {

      var currIntl = getAppState && getAppState() && getAppState().getNested(['app', 'intl']);
    	if (getAppState && getAppState() && getAppState().getNested(['projects', 'intl']))
    		currIntl = getAppState().getNested(['projects', 'intl']);

      return (
        <WrappedComponent
          {...this.props}
          intl={currIntl}
        />
      );
    }
  }

  return hoistNonReactStatics(InjectIntl, WrappedComponent);

}