import { defineMessages } from 'react-intl';

export default defineMessages({
  add: {
    defaultMessage: 'Add',
    id: 'app.buttons.add',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'app.buttons.cancel',
  },
  delete: {
    defaultMessage: 'Delete',
    id: 'app.buttons.delete',
  },
  dismiss: {
    defaultMessage: 'Dismiss',
    id: 'app.buttons.dismiss',
  },
  facebookSignIn: {
    defaultMessage: 'Login with Facebook',
    id: 'auth.social.facebookSignIn',
  },
  signIn: {
    defaultMessage: 'Sign In',
    id: 'app.buttons.signIn',
  },
  signOut: {
    defaultMessage: 'Sign Out',
    id: 'app.buttons.signOut',
  },
  signUp: {
    defaultMessage: 'Sign Up',
    id: 'app.buttons.signUp',
  },
  submit: {
    defaultMessage: 'Submit',
    id: 'app.buttons.submit',
  },
  dragAndDrop: {
    defaultMessage: 'Drag and drop a file here or',
    id: 'app.buttons.dragAndDrop',
  },
  uploadAFile: {
    defaultMessage: 'Upload a file',
    id: 'app.buttons.uploadAFile',
  },
  posts: {
    defaultMessage: 'updates',
    id: 'project.actionButton.posts'
  },
  issues: {
    defaultMessage: 'project',
    id: 'project.actionButton.issues'
  },
  notifications: {
    defaultMessage: 'notifications',
    id: 'project.actionButton.notifications'
  },
  members: {
    defaultMessage: 'members',
    id: 'project.actionButton.members'
  },
  info: {
    defaultMessage: 'Info',
    id: 'project.actionButton.info',
  },
  reports: {
    defaultMessage: 'reports',
    id: 'project.actionButton.reports',
  },
});
