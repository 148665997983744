import {getSnapshotData} from '../lib/utils/utils';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';

export function getPermissions(inLastUpdateTS) { 
  const getPromise = async () => {
    const snapShot = await getSnapshotData({ api: 'permissions', firebaseSuffix: 'v3' });
    return { ...(snapShot || {}), lastUpdateTS: inLastUpdateTS }
  } 

  return {
		type: GET_PERMISSIONS,
    payload: getPromise()
  };
}

