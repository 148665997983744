import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import withStyles from "@material-ui/core/styles/withStyles";
import sidePhoto from "../../assets/img/sidebar-1.jpg";
import ObjectPropertiesPage from "../Properties/ObjectPropertiesPage";
import Button from "../../components/CementoComponents/Button";

class FormCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onInnerObjectSelect = this.onInnerObjectSelect.bind(this);
    this.state = {};
  }

  onInnerObjectSelect(type) {
    const { onInnerObjectSelect } = this.props;
    const { showModalSide } = this.state;
    let modalSideComponent;

    if (onInnerObjectSelect)
      modalSideComponent = onInnerObjectSelect(
        "forms",
        Object.assign({}, this.props),
        "card",
        []
      );

    if (modalSideComponent)
      this.setState({ modalSideComponent, showModalSide: !showModalSide });
  }

  render() {
    let { showModalSide, modalSideComponent } = this.state;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ width: "60%" }}>
          <ObjectPropertiesPage
            {...this.props}
            mode={"card"}
            useCollapsibleSections={true}
          />
          <Button
            title={"Click me to open something on the side"}
            style={{ width: "50%" }}
            onClick={this.onInnerObjectSelect}
          />
        </div>
        <div style={{ width: "40%" }}>
          {Boolean(showModalSide) ? (
            modalSideComponent
          ) : (
            <img src={sidePhoto} style={{ width: "100%", height: "100%" }} />
          )}
        </div>
      </div>
    );
  }
}

let styles = {};

FormCard = injectIntl(FormCard);
FormCard = withStyles(styles)(FormCard);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      rtl: state.app.rtl,
    }),
    {}
  )
);

export default enhance(FormCard);
