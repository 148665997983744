import { Record } from '../transit';

const Post = Record({
  createdAt: null,
  editedAt: null,
  updatedTS: null,
  fue: null, // First Unseen Event
  counter: null,
  id: '',
  owner: null,
  title: null,
  description: null,
  images: null,
  checklistItemInstance: null,
  comments: null,
  commentsCounter: null,
  trade: null,
  tagged: null,
  taggedCompanies: null,
  type: null,
  issueOpener: null,
  isIssue: null, 
  isDeleted: null,
  issueState: null, // 300 - Open Issue, 200 - Resolved, 100 - Closed
  stateUpdatedTS: null,
  isReported: null,
  location: null,
  dueDate: null,
  assignTo: null,
  isLocal: null,
  extraData: null,
  subCategory: null,
}, 'post');

export default Post;
