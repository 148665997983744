import { io } from 'socket.io-client';
import { getUtilsConfigObject } from '../../configureMiddleware';
import { safeToJS } from '../../permissions/funcs';
import { platformActions } from '../../platformActions';
import * as ioDisconnectReasons from './disconnectReasons';
import * as ioEvents from './eventTypes';

/**
 * 
 * @param {import('socket.io-client').Socket} socket 
 * @param {string} event 
 * @param  {...any} args 
 * @returns
 */
const emitLocalEvent = (socket, event, ...args) => {
  const callbacks = socket?._callbacks?.[`$${event}`];
  callbacks?.forEach?.(callback => callback(...args));
}

const SOCKET_RETRY_CONNECT_TIMEOUT = 1000;
export const getServerSocket = (() => {
  /** @type {import('socket.io-client').Socket} */
  let socket = null;
  let lastViewer = null;
  let reconnectTimeOut = null;
  let didInitialConnect = false;

  /**
   * @param {{ id: string, phoneNumber: string }} viewer
   */
  return (viewer) => {
    if (!viewer) return null;

    viewer = safeToJS(viewer);
    const isDiffViewer = lastViewer?.id !== viewer?.id;
    if (!socket || isDiffViewer) {
      if (socket && isDiffViewer) {
        socket.disconnect();
        didInitialConnect = false;
      }

      const { apiServer } = getUtilsConfigObject();
      const [token, clientId, phoneNumber] = [viewer.id, platformActions.app.getUniqueID(), viewer.phoneNumber];

      socket = io(apiServer, {
        path: '/events',
        query: { clientId, token, phoneNumber },
        transports: ['websocket'],
      });

      lastViewer = viewer;

      socket.on(ioEvents.DISCONNECT, reason => {
        console.info('Socket:', 'Disconnected. Reason:', reason)
        if (reason === ioDisconnectReasons.SERVER_DISCONNECT) {
          socket.connect();
        }
        // else for other reasons, the socket will automatically try to reconnect
      });

      socket.on(ioEvents.CONNECT, () => {
        if (!didInitialConnect) {
          console.info('Socket:', 'Connected');
          didInitialConnect = true;
        }
        else {
          console.info('Socket:', 'Reconnected');
          emitLocalEvent(socket, ioEvents.RECONNECT);
        }
      });

      socket.on(ioEvents.CONNECT_ERROR, (err) => {
        console.info('Socket Error:', err);
        if (!reconnectTimeOut) {
          reconnectTimeOut = setTimeout(() => {
            console.info('Socket:', 'Trying to reconnect...')
            socket.connect();
            reconnectTimeOut = null;
          }, SOCKET_RETRY_CONNECT_TIMEOUT);
        }
      });
    }

    return socket;
  }
})();