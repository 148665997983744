import React from 'react';
import ReactDOM from 'react-dom';
import { fromJSON } from './common/transit';
import { initialTransitState } from './common/initialState';
import configureStore from './common/configureStore';
import webActions from './web/webActions';
import { register } from './registerServiceWorker';
import App from './web/app/App.js';
import StandardInput from './web/components/CementoComponents/StandardInput';
import './web/assets/scss/material-dashboard-pro-react.css?v=1.3.0';
// import prototype implemntation
import './common/Prototype';

import ErrorBoundary from './web/app/ErrorBoundary';

import Text from './web/components/CementoComponents/Text';
import TradeBadge from './web/components/CementoComponents/TradeBadge';
import theme from './web/assets/css/theme';


let defaultLocale = webActions.app.getDeviceLang();
var initialState = fromJSON(initialTransitState);
const store = configureStore({
	platformActions: webActions,
	platformDeps: {
		/*createStorageEngine*/
	},
	initialState: {
		...initialState,
		device: initialState.device.set('isReactNative', false),
		intl: initialState.intl
			.set('currentLocale', defaultLocale)
			.set('defaultLocale', defaultLocale)
			.set('initialNow', Date.now()),
	},
	standardInputComponent: StandardInput,
	containerComponent: 'div',
	textComponent: Text,
	tradeComponent: TradeBadge,
	theme,
});

ReactDOM.render(
	<ErrorBoundary>
		<App store={store} />
	</ErrorBoundary>,
	document.getElementById('root'),
);

register();
