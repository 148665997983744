import { firebaseStart } from '../lib/redux-firebase/actions';
import { removeProjectsStorage } from '../projects/actions';
import { updateMyUserMetadata } from '../users/actions';
import * as propMappingsActions from '../propertiesMappings/actions';
import { getTitles } from '../titles/actions';
import { getTrades } from '../trades/actions';
import { getQuasiStatics } from '../quasiStatics/actions';
import { getPermissions } from '../permissions/actions';
import * as lastUpdatesActions from '../lastUpdates/actions';
import _ from 'lodash';
import _fp from 'lodash/fp';
import { getDifference, flattenObject, encodeBase64, getBase64StringInfo, splitInBatches } from './funcs';
import { uploadImage } from '../images/actions';
import { getRetryObjects, setRetryObjects, deleteRetryObjects } from '../lib/offline-mode/utils';
import { saveLocal, getLocal, removeNestedIsLocal } from '../lib/utils/utils';
import { getGlobalStatePathes } from '../configureStorage/statePathes';
import { getAppState } from '../configureMiddleware';
import { shouldUseBase64 } from './constants';
import { storeImagePermanently } from '../app/funcs';


export const APP_OFFLINE = 'APP_OFFLINE';
export const APP_ONLINE = 'APP_ONLINE';
export const APP_START = 'APP_START';
export const APP_STORAGE_LOAD = 'APP_STORAGE_LOAD';
export const APP_STORAGE_NOT_LOAD = 'APP_STORAGE_NOT_LOAD';
export const SAVE_APP_STORAGE = 'SAVE_APP_STORAGE';
export const SET_LANG = 'SET_LANG';
export const SET_APP_INTL = 'SET_APP_INTL';
export const ON_CHECK_DB_SETTINGS = 'ON_CHECK_DB_SETTINGS';


export const SAVE_MENU         = 'SAVE_MENU';
export const GET_MENUS          = 'GET_MENUS';

export const HIDE_TOAST = 'HIDE_TOAST';
export const START_TOAST = 'START_TOAST';
export const START_ALERT = 'START_ALERT';
export const START_LOADING = 'START_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
export const HIDE_ALL_LOADING = 'HIDE_ALL_LOADING';
export const UPDATE_LAST_PROJECT_PAGE  = 'UPDATE_LAST_PROJECT_PAGE';
export const CLEAN_CACHE = 'CLEAN_CACHE';
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE'
export const GET_SETTING_LAST_UPDATE = 'GET_SETTING_LAST_UPDATE';
export const CLEAN_DYNAMIC_CACHE_DATA = 'CLEAN_DYNAMIC_CACHE_DATA';
export const UPDATE_CONNECTION_STATUS = 'UPDATE_CONNECTION_STATUS';
export const CANCEL_OPERATION = 'CANCEL_OPERATION';
export const LOADING_TIMEOUT = 'LOADING_TIMEOUT';

export const SAVE_TO_SERVER             = 'SAVE_TO_SERVER';
export const RETRY_SAVE_OBJECTS         = 'RETRY_SAVE_OBJECTS';
export const SAVE_OBJECTS               = 'SAVE_OBJECTS';
export const FIND_AND_UPLOAD_FILES      = 'FIND_AND_UPLOAD_FILES';


export const LOADING_TIMEOUT_MS = 1000 * 45;


export function checkUpdateVersion() {
  return ({ dispatch, getState, platformActions }) => {
    const getPromise = async () => {
      let lastVersion = getState().getNested(['lastUpdates','appVersion'], '0');
      let currVersion = platformActions.app.getVersion();
      //let updateFromLastVersion = null;
      let updateOnVersion = null;
  
      if (platformActions.app.getPlatform() != 'web') {
        //updateFromLastVersion = (platformActions.app.getPlatform() == "ios") ? "5.2.56" : "3.93.0.15";
        updateOnVersion = (platformActions.app.getPlatform() == "ios") ? "5.4.49" : "3.98.0.10";
      }

      if (lastVersion && lastVersion != '0' && currVersion != lastVersion) {
        if (platformActions.app.getPlatform() == 'web') {
          if (lastVersion < '0.98') {
            try {
              await dispatch(propMappingsActions.cleanPropertiesMappingsCachedData());
            }
            catch (error) {
              platformActions.sentry.notify(error, { function: 'checkUpdateVersion' });
              console.log('checkUpdateVersion error:', error);
            }
          }
        }
        else {
          if ((lastVersion < updateOnVersion)
            // || (lastVersion == updateFromLastVersion)
          ) {
            try {
              await dispatch(propMappingsActions.cleanPropertiesMappingsCachedData());
            }
            catch (error) {
              platformActions.sentry.notify(error, { function: 'checkUpdateVersion' });
              console.log('checkUpdateVersion error:', error);
            }
          }
        }
      }

      return { appVersion: currVersion };
    }

    return {
      type: lastUpdatesActions.ON_CHECK_APP_VERSION,
      payload: getPromise()
    };
	};
}


export function checkAndUpdateSettingsFromDB() {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      // Maybe this should be here only on web
      // Init trades and title

      let settingsLastUpdates = (await dispatch(checkSettingsLastUpdate())) || {};
      if (settingsLastUpdates) {
        let titlesLastUpdateTS = getState().titles.lastUpdateTS;
        let tradesLastUpdateTS = getState().trades.lastUpdateTS;
        let quasiStaticsLastUpdateTS = getState().quasiStatics.lastUpdateTS;
        let propertiesTypesLastUpdateTS = getState().propertiesTypes.lastUpdateTS;
        let permissionsLastUpdateTS = getState().permissions.lastUpdateTS;

        if (settingsLastUpdates.getNested(['snapshot', 'permissions', 'v3', 'lastUpdateTS'], 1) > permissionsLastUpdateTS || !getState().permissions.map || !getState().permissions.map.size)
          dispatch(getPermissions(settingsLastUpdates.getNested(['snapshot', 'permissions', 'v3', 'lastUpdateTS'], 0)));
        if (settingsLastUpdates.getNested(['snapshot', 'titles', 'lastUpdateTS'], 1) > titlesLastUpdateTS || !getState().titles.map || !getState().titles.map.size)
          dispatch(getTitles(settingsLastUpdates.getNested(['snapshot', 'titles', 'lastUpdateTS'], 0)));
        if (settingsLastUpdates.getNested(['snapshot', 'trades', 'lastUpdateTS'], 1) > tradesLastUpdateTS || !getState().trades.map || !getState().trades.map.size)
          dispatch(getTrades(settingsLastUpdates.getNested(['snapshot', 'trades', 'lastUpdateTS'], 0)));
        if (settingsLastUpdates.getNested(['snapshot', 'quasiStatics', 'lastUpdateTS'], 1) > quasiStaticsLastUpdateTS || !getState().quasiStatics.requiredActionsMap || !getState().quasiStatics.requiredActionsMap.size || !getState().quasiStatics.formUniversalIdsMap.size || !getState().quasiStatics.adminUsers)
          dispatch(getQuasiStatics(settingsLastUpdates.getNested(['snapshot', 'quasiStatics', 'lastUpdateTS'], 0)));
      }

      return {
        type: ON_CHECK_DB_SETTINGS,
        payload: { done: true }
      };
    };

    return {
      type: ON_CHECK_DB_SETTINGS,
      payload: getPromise()
    };
  };
}


const loadStorage = async (dispatch, lokiInstance, platformActions) => {
  let savedJson = [];
  try {
    
    if (platformActions.app.getPlatform() == "web") 
      await lokiInstance.loadProjectDataFromStorage('global');

      await Promise.all(getGlobalStatePathes().map(async ([feature, ...featurePath]) => {
        try {
          var configKey = '@' + feature + '_' + featurePath + ':' + 'global';
  
          var value = null;
          if (platformActions.app.getPlatform() == "ios" || platformActions.app.getPlatform() == "web")
            value = await platformActions.storage.getItem(configKey);
          else {
            var configName = 'cemento_' + configKey +  '.cfg';
            var fileLocation = platformActions.fs.getDocumentDirectoryPath() + '/cemento/' + configName;
            var fileExist = await platformActions.fs.exists(fileLocation);
            if (fileExist)
              value  = await platformActions.fs.readFile(fileLocation, 'utf8');
          }
  
          if (value && value != "null") {
            savedJson.push({feature, featurePath, value})
          }
        } 
        catch (err) {
          console.warn('APP_STORAGE_LOAD error');
          console.warn(err);
        }
      }));
      
    dispatch({ type: APP_STORAGE_LOAD, payload: savedJson });    
    return { savedJson } 
  } catch (error) {
    console.log("loadStorage error: " + error)
    dispatch({ type: APP_STORAGE_NOT_LOAD });    
    throw error;
  }  
}

var didStart = false;
export function start() {
  return ({ dispatch, lokiInstance, platformActions, getState }) => {
    const getPromise = async () => {
      try {
        
        if (didStart)
          return { didStart };

        await loadStorage(dispatch, lokiInstance, platformActions)
        //await dispatch(checkUpdateVersion());
        let language = platformActions.app.getLang();
        var languageDidChanged = (!getState().app.lang || language != getState().app.lang);

        dispatch(firebaseStart());
        
        var currLang = getState().app.lang || language;
        return { lang: currLang, didChanged: languageDidChanged };
      } catch (error) {
        console.log("start error: " + error)
        throw error;
      }
    }

    return {
      type: APP_START,
      payload: getPromise()
    };
  };
}

export function hideToast() {
  return {
    type: HIDE_TOAST
  };
};


export function setAppIntl(intl) {
  return {
    type: SET_APP_INTL,
    payload: { intl }
  };
}

/**
 * @typedef {{ id: string, defaultMessage: string }} IntlMessage
*/
/**
 * @typedef {{ message: string | IntlMessage, onClick?: function, color?: 'success' | 'error' | 'danger',  }} Action
 * @typedef {{ 
 *  title: string | IntlMessage, 
 *  values?: {[key: string]: string | IntlMessage}, 
 *  message?: string | IntlMessage, 
 *  actions?: Action[] 
 *  overlay?: boolean,
 *  mandatory?: boolean,
 *  overwriteTimeout?: boolean,
 *  type?: 'error' | 'success' | 'info'
 * }} StartToastParams
 * @param {StartToastParams} paramsObj 
*/
export function startToast({title, values, message, actions, overlay, mandatory, type, overwriteTimeout, close}) {
  return {
    type: START_TOAST,
    payload: { toast: { title, values, message, actions, overlay, mandatory, date: Date.now(), type, overwriteTimeout, close } }
  };
};

/**
 * 
 * @param {string | IntlMessage} title 
 * @param {string | IntlMessage} message 
 * @param {{[key: string]: string}} values 
 */
export function startAlert(title, message, values = undefined) {
  return {
    type: START_ALERT,
    payload: { alert : {message, title, date: Date.now(), messageValues: values, titleValues: values }}
  };
};

export function startLoading({
                               title,
                               values,
                               overlay,
                               hideOnBackgroundPress = true,
                               operationId,
                               cancelOnBackgroundPress = false,
                               isWithTimeout = true
}) {
  return ({ dispatch }) => {

    let payload = { startTS: Date.now(), overlay, hideOnBackgroundPress, cancelOnBackgroundPress, operationId };
    if (Boolean(title))
      payload.toast = { title, values };
      
    if (isWithTimeout)
      setTimeout(() => dispatch(handleLoadingTimeOut(operationId)), LOADING_TIMEOUT_MS);

    return {
      type: START_LOADING,
      payload: payload
    };
  };
}

export function hideLoading(operationId) {
  return {
    type: HIDE_LOADING,
    payload: { operationId }
  };
};

export function handleLoadingTimeOut(operationId) {
  return {
    type: LOADING_TIMEOUT,
    payload: { operationId }
  };
}

export function hideAllLoading(operationId) {
  return {
    type: HIDE_ALL_LOADING,
  };
};

export function saveStorage() {
  return {
    type: SAVE_APP_STORAGE
  };
};

export function updateConnectionStatus(isConnected) {
  return {
    payload: { isConnected },
    type: UPDATE_CONNECTION_STATUS
  };
};


export function setLang(lang, shouldUpdateServer) {
  return ({ platformActions, getState, dispatch }) => {
    try {
      if (getState && getState() && getState().getNested(["app","lang"]) != lang) {
        platformActions.app.setRTLbyLang(lang);
        if (shouldUpdateServer)
          dispatch(updateMyUserMetadata('lang', lang));
      }

      return {
        type: SET_LANG,
        payload: { lang }
      };
    } catch (error) {
      throw error;
    }
  };
}

export function clearCache() {
  return {
    type: CLEAN_CACHE
  };
}

export function cancelOperation(operationId) {
  return ({ dispatch }) => {
    dispatch(hideLoading(operationId));
    return {
      type: CANCEL_OPERATION,
      payload: { operationId }
    };
  };
}

export function checkSettingsLastUpdate() {  
  return ({ firebaseDatabase }) => {
    const getPromise = async () => {
      var snapshotObject = await firebaseDatabase().ref('lastUpdates/settings').once('value');
      var snapshot = snapshotObject.val()
      return { snapshot }
    }

    return {
      type: GET_SETTING_LAST_UPDATE,
      payload: getPromise()
    };
  };
}

export function downloadFile(url, uniqeName, ext, returnBase64, fetchParams) { 
  return ({ platformActions }) => {
    const getPromise = async () => {
      // TODO: Check for cached items
      var fileLocation = null;
      var fileExist = false;
      if (uniqeName) {
        fileLocation = platformActions.fs.getCacheDirectoryPath() + '/' + (uniqeName || Date.now()) + '.'  + ext;
        fileExist = await platformActions.fs.exists(fileLocation);
      }

      if (!fileExist) {
        const { params, config, legacyFetch } = fetchParams || {};
        var res = await platformActions.net.fetch(url, params, config, legacyFetch);
        let base64Str = res.data;
        if (returnBase64)
          return base64Str;
        await platformActions.fs.writeFile(fileLocation, base64Str, 'base64');
      }

        return fileLocation;
    }
    return {
      type: DOWNLOAD_FILE,
      payload: getPromise()
    };
  }
}

export function cleanDynamicCachedData(isSignOut) {  
  return ({dispatch, getState }) => {
    const getPromise = async () => {
      let promises = [];
      
      let projectStateToRemove = [
        //['checklists',              'map'],
        //['checklists',              'lastUpdated'],
        //['checklistItems',          'map'],
        //['checklistItems',          'lastUpdated']
        ['posts',                   'lastRevokeAvailable'],
        ['posts',                   'lastRevoked'],
        ['checklistItemsInstances', 'lastRevokeAvailable'],
        ['checklistItemsInstances', 'lastRevoked'],
        //['propertiesInstances',     'lastRevokeAvailable'],
        //['propertiesInstances',     'lastRevoked'],
        //['employees',               'lastRevokeAvailable'],
        //['employees',               'lastRevoked'],
        //['equipment',               'lastRevokeAvailable'],
        //['equipment',               'lastRevoked'],
      ];
      
      let projectIdsArray = [];
      getState().getNested(['projects', 'map'],{}).loopEach((k,p) => projectIdsArray.push(p.id))
      promises.push(dispatch(removeProjectsStorage(projectIdsArray, projectStateToRemove)));
      await Promise.all(promises);
      console.log('Cleand all cached data!');
    }
    return {
      type: CLEAN_DYNAMIC_CACHE_DATA,
      payload: getPromise()
    };
  };
}


export async function clearALLData(platformActions, keepAuth0) {
  let stringAuth0_data = null;

  if (platformActions.app.getPlatform() != "ios" && platformActions.app.getPlatform() != "web") {
    let fileLocation = platformActions.fs.getDocumentDirectoryPath() + '/cemento';

    await platformActions.fs.deletePath(fileLocation);

  } else {
      if (keepAuth0) stringAuth0_data = await platformActions.storage.getItem('@auth_details:' + 'auth0_data');
      await platformActions.storage.clear();
  if (keepAuth0 && stringAuth0_data)
      await platformActions.storage.setItem('@auth_details:' + 'auth0_data', stringAuth0_data);
  }
}

export function saveMenus(projectId, filterValues, filterProps, contentType, pageType, subjectType, isDelete) {
  return ({ firebaseDatabase, firebase }) => {
    const getPromise = async () => {
      
      let filtersSet = {...filterProps, contentType, pageType};
      let filterValuesCopy = Object.assign({}, filterValues);

      if (!filtersSet.id)
        filtersSet.id = firebaseDatabase().ref('settings/' + projectId + '/menu/' + contentType).push().key;

      if (filterValuesCopy.textFilter)
        delete filterValuesCopy.textFilter;
      
      filtersSet.values = filterValuesCopy;
      filtersSet.subjectType = subjectType;

      let updates = {};

      if (isDelete)
        updates['settings/' + projectId + '/menu/' + filtersSet.id] = null;
      else
        updates['settings/' + projectId + '/menu/' + filtersSet.id] = filtersSet;
      await firebase.update(updates);

      return { projectId, filtersSet };
    };

    return {
      type: SAVE_MENU,
      payload: getPromise()
    };
  };
}

export function getMenus(projectId) {
  return ({ firebaseDatabase }) => {
    const getPromise = async () => {
      var menusRet = await firebaseDatabase().ref('settings/' + projectId + '/menu').once('value');
      var menus = menusRet.val();

      return { projectId, menus };
    };

    return {
      type: GET_MENUS,
      payload: getPromise()
    };
  };
}

const seekAndCollectUriPathsArr = (object, isAlsoCheckData = false) => {
  if (!object)
    return [];

  const flatObject = flattenObject(object);
  return Object.entries(flatObject).reduce((acc, [key, val]) => {
    const splitKey = key.split('/');

    if (_.last(splitKey) === 'uri' || (isAlsoCheckData && (_.last(splitKey) === 'data' && typeof val === 'string' && val.startsWith('data:')))) { // !!UNTIL CEM-4481!! - Also check for data as temporary fix while we align files array with the rest of the system standard for storing files under "uri" property
      const uriObjectPath = splitKey.slice(0, splitKey.length - 1);
      acc.push({ uriObjectPathArr: uriObjectPath, uriObject: _.get(object, uriObjectPath), isFromDataProperty: _.last(splitKey) === 'data' });
    }

    return acc;
  }, []);
}

export const findAndUploadFiles = async ({ objects, fileServerFolderName, targetFileNameBuilder }) => {
  let success = true;
  let hadFilesToUpload = false;
  let updatedObjects = {};

  if (Object.keys(objects || {}).length && fileServerFolderName && typeof targetFileNameBuilder === 'function') {
    await Promise.all(Object.values(objects).map(async object => {
      if (!object) return;
      let updatedObject = object;

      const uriPathObjects = seekAndCollectUriPathsArr(updatedObject, true);
      await Promise.all(uriPathObjects.map(async uriPathObject => {
        const { uriObjectPathArr, uriObject, isFromDataProperty } = uriPathObject;
        const { uri: fileUri, type: fileContentType, data: fileDataUri } = uriObject;

        const fileUriString = isFromDataProperty ? fileDataUri : fileUri;
        if (!fileUriString || (fileUriString.startsWith && fileUriString.startsWith('http'))) return;

        if (!hadFilesToUpload) 
            hadFilesToUpload = true;

        const uri = shouldUseBase64()
          ? !getBase64StringInfo(fileUriString) 
            ? await encodeBase64(fileUriString, fileContentType) 
            : fileUriString
          : await storeImagePermanently(fileUriString);

        let fileUrl = null;
        let isUploadFailed = null;
        try {
          fileUrl = await uploadImage(uri, targetFileNameBuilder(updatedObject, { uriObject, uriPathInObject: uriObjectPathArr }), fileServerFolderName);
          isUploadFailed = !fileUrl || typeof fileUrl !== 'string';
        }
        catch (error) {
          console.warn(FIND_AND_UPLOAD_FILES + ' failed uploading form signature file', {error, updatedObject, fileUri: fileUriString, fileContentType, base64FileString: uri})
          isUploadFailed = true;
        }

        if (isUploadFailed && success)
          success = false;

        updatedObject = _fp.set([...uriObjectPathArr, isFromDataProperty ? 'data' : 'uri'], (isUploadFailed ? uri : fileUrl), updatedObject);
      }));

      updatedObjects[updatedObject.id] = updatedObject;
    }));
  }
  else
    updatedObjects = objects;


  return { hadFilesToUpload, updatedObjects, success };
}



let pendingUpdatesCommands = {};
export const saveToServer = ({ objectsToSave, originalObjects, dbRootPathArr, fileServerFolderName, targetFileNameBuilder, callback, skipPendingQueuCheck = true }) => {
  return ({ firebase, removeEmpty, platformActions }) => {
    const getPromise = async () => {
      if (!Object.keys(objectsToSave || {}).length || !(dbRootPathArr || []).length)
        return { success: false, objectsToSave };

      const { hadFilesToUpload, updatedObjects, success: uploadFilesSuccess } = await findAndUploadFiles({ objects: objectsToSave, fileServerFolderName, targetFileNameBuilder });

      if (hadFilesToUpload)
        objectsToSave = updatedObjects;

      const enhancedCallback = (fullSuccess, successReason) => {
        if (callback && typeof callback === 'function')
          callback({ success: fullSuccess, hadFilesToUpload, objectsToSave, successReason });
      }

      let dbUpdates = {};
      if (!hadFilesToUpload || objectsToSave) {
        Object.values(objectsToSave).forEach(objectToSave => {
          const currObjectId = Boolean(objectToSave) && objectToSave.id;
          
          if (!currObjectId) {
            platformActions.sentry.notify(`Missing objectId for save to db`, { objectsToSave, originalObjects, dbRootPathArr });
            console.warn(`Missing objectId for save to db`, { objectsToSave, originalObjects, dbRootPathArr }); // TODO: Send to bugsnag    
            return;
          }

          objectToSave = _.cloneDeep(objectToSave);
          objectToSave = removeNestedIsLocal(objectToSave, false);
          // objectToSave = removeEmpty(objectToSave);
          delete objectToSave.updatedTS;
          const dbRootPath = [...dbRootPathArr, currObjectId].join('/');
          const originalObject = Object.values(originalObjects || {}).filter(o => o.id === currObjectId)[0];
          if (false && Boolean(originalObject) && originalObject.updatedTS) { // in update mode
            const objectDifference = getDifference(objectToSave, originalObject);
            const flatObject = flattenObject(objectDifference); // creates a flat map of the object with keys -> {'some/key/path/in/object': value}
            dbUpdates = { ...dbUpdates, ..._.mapKeys(flatObject, (val, key) => `${dbRootPath}/${key}`) };
          }
          else // in create mode
            dbUpdates[dbRootPath] = objectToSave;
        });


        // This whole pendingUpdatesCommands and callback thing is a work arround until we update react-native firebase to v6 and we are able to disable the persistence setting
        // This persistence thing means that firebase.update does not call its callback until the operation is successful and it will keep the update in memory until it succeeds and keep on retrying
        if (!skipPendingQueuCheck)
          dbUpdates = _.omit(dbUpdates, Object.keys(pendingUpdatesCommands));

        const isConnected = _.isFunction(getAppState) && getAppState().getNested(['app', 'isConnected'], false);
        const dbUpdatesKeys = Object.keys(dbUpdates);
        if (!isConnected) {
          enhancedCallback(false, { message: 'No connection' });
        }
        else if (dbUpdatesKeys.length) {
          const timeout = setTimeout(() => enhancedCallback(false, { message: 'Save timed out' }), 30000);
          (dbUpdatesKeys).forEach(updateKey => pendingUpdatesCommands[updateKey] = true);
          const uploadPromise = new Promise(async resolve => {
            let error = null;

            if (dbUpdatesKeys.length > 500) {
              const updateBatchesArr = splitInBatches(dbUpdates, 100);
              await Promise.all(updateBatchesArr.map(async updateBatch => {
                if (error)
                  return;

                return await firebase.update(updateBatch, e => e && (error = e));
              }));
            }
            else
              await firebase.update(dbUpdates, e => e && (error = e));

            resolve(error);
          });

          uploadPromise.then(error => {
            clearTimeout(timeout);
            const fullSuccess = !Boolean(error);
            enhancedCallback(fullSuccess, { message: fullSuccess ? 'It just worked :)' : 'Some error occured in firebase.update', firebaseError: error });
            if (fullSuccess)
              pendingUpdatesCommands = _.omit(pendingUpdatesCommands, dbUpdatesKeys);
          });
        }
        else
          enhancedCallback(true, { message: 'No update commands to perform' });
      }
      else
        enhancedCallback(false, {message: 'Had files to upload and it failed to upload them'});

      return { 
        objectsToSave,
        uploadFilesSuccess,
        hadFilesToUpload,
        dbUpdates,
        // success: Boolean(uploadDBsuccess && uploadFilesSuccess),
      }
    }

    return {
      type: SAVE_TO_SERVER,
      payload: getPromise()
    }
  }
}

/**
 * @typedef  SaveObjectsParams
 * @property {Object<string, Object<string, any>> | Array<Object<string, any>>} objectsToSave
 * @property {Object<string, Object<string, any>> | Array<Object<string, any>>} originalObjects
 * @property {boolean} [isRetry]
 * @property {string} schemaName
 * @property {string} schemaType
 * @property {string} lastUpdateTStypeId
 * @property {boolean} allowOfflineActivity
 * @property {Array<string>} dbRootPathArr
 * @property {string} projectId
 * @property {string} [fileServerFolderName]
 * @property {function({ projectId: string }):Array<string>} [targetFileNameBuilder]
 * @property {import('../lib/offline-mode/config').PreProcessObjectForLocalSaveFunc} [preProcessObjectForLocalSaveFunc]
 * @property {Array<string>} [serverOnlyFields]
 * @property {boolean} [skipUploadToServer]
 * @property {import('../lib/offline-mode/config').ProcessRealmObjectsOutputFunc} [processRealmObjectsOutputFunc] 
 */

/**
 * Saves objects to the server and locally if supported
 * @param {SaveObjectsParams} paramsObj
 */
export const saveObjects = ({ objectsToSave, originalObjects, isRetry = false, schemaName, schemaType, lastUpdateTStypeId, allowOfflineActivity, dbRootPathArr, projectId, fileServerFolderName, targetFileNameBuilder, preProcessObjectForLocalSaveFunc, serverOnlyFields, skipUploadToServer = false, processRealmObjectsOutputFunc }) => {
  return ({ dispatch }) => {
    /** @returns {Promise<{ success: boolean }>} */
    const getPromise = () => new Promise(async (resolve, reject) => {
      objectsToSave = Object.values(objectsToSave || {});
      if (!(objectsToSave.length && projectId && (dbRootPathArr || []).length))
        return;

      const saveObjectsLocally = objectsToSaveLocally => {
        if (allowOfflineActivity)
          saveLocal({
            lastUpdateTStypeId,
            projectId,
            schemaName,
            schemaType,
            objectsToSave: Object.values(objectsToSaveLocally).map(o => ({ ...o, isLocal: true })),
            preProcessObjectForLocalSaveFunc
          });
      };

      if (!Object.keys(originalObjects || {}).length) {
        const idsToGet = objectsToSave.map(objectToSave => objectToSave.id);
        const retryObjects = getRetryObjects({ objectType: lastUpdateTStypeId, projectId }).retryObjects;
        const localObjects = getLocal({ idsToGet, projectId, schemaName, schemaType }).objects;
        originalObjects = {};
        Object.values(retryObjects).forEach(o => !originalObjects[o.id] && idsToGet.includes(o.id) && (originalObjects[o.id] = o));
        Object.values(localObjects).forEach(o => !originalObjects[o.id] && (originalObjects[o.id] = o));
        originalObjects = Object.values(originalObjects);

        if (typeof processRealmObjectsOutputFunc === 'function')
          originalObjects = Object.values(originalObjects || {}).map(processRealmObjectsOutputFunc);
      }

      if (!isRetry)
        saveObjectsLocally(objectsToSave.map(o => ({ ...o, lastUploadTS: 0 })));

      const saveToServerCallback = ({ success: fullUploadSuccess, hadFilesToUpload, objectsToSave: updatedObjectsToSave, ...rest }) => {
        const objectsToSaveByIdMap = objectsToSave.reduce((acc, objectToSave) => _.set(acc, [objectToSave.id], objectToSave), {});
        objectsToSave = Object.values(updatedObjectsToSave || {}).map(updatedObjectToSave => ({ ...objectsToSaveByIdMap[updatedObjectToSave.id], ...updatedObjectToSave })); // some properties were removed before save to server that we DO want to have locally, so here we make sure they are there before we save local
        
        if (hadFilesToUpload)
          saveObjectsLocally(objectsToSave);
    
        if (fullUploadSuccess) {
          const lastUploadTS = Date.now();
          saveObjectsLocally(objectsToSave.map(o => ({ ...o, lastUploadTS })));
          deleteRetryObjects({ retryObjects: originalObjects, projectId });
        }
        else if (allowOfflineActivity && !isRetry)
          setRetryObjects({ retryObjects: originalObjects, objectType: lastUpdateTStypeId, projectId });
        else if (!allowOfflineActivity && !isRetry) { // also check for isRetry just in case we do get to this point without offline ability (should never happen)
          // TODO: start toast saying that the user can't do that 
        }

        const isOperationSuccess = fullUploadSuccess || (allowOfflineActivity && !fullUploadSuccess);
        resolve({ success: isOperationSuccess });
      }

      if (!skipUploadToServer) {
        const pickFields = objects => serverOnlyFields ? Object.values(objects || {}).map(o => _.pick(o, serverOnlyFields)) : objects; // Filter object's properties to only return the ones that actually need to be saved to server
        dispatch(saveToServer({ objectsToSave: pickFields(objectsToSave), originalObjects: pickFields(originalObjects), dbRootPathArr, fileServerFolderName, targetFileNameBuilder, callback: saveToServerCallback, skipPendingQueuCheck: !isRetry }));        
      }
      else 
        saveToServerCallback({ success: false, hadFilesToUpload: false, objectsToSave });
    });
    
    return {
      type: isRetry ? RETRY_SAVE_OBJECTS : SAVE_OBJECTS,
      payload: getPromise()
    }
  }
}
