import PropTypes from 'prop-types';
import * as actions from './actions';
import React, { PureComponent, Component } from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import 'moment/locale/he';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/ar';
import 'moment-timezone/node_modules/moment/locale/he';
import 'moment-timezone/node_modules/moment/locale/fr';
import 'moment-timezone/node_modules/moment/locale/es';
import 'moment-timezone/node_modules/moment/locale/ar';

import { platformActions } from '../platformActions';

export default function start(WrappedComponent) {
	class Start extends Component {
		static propTypes = {
			intl: PropTypes.object.isRequired,
			start: PropTypes.func.isRequired,
		};

		constructor(props) {
			super(props);
			this.state = {};
		}

		UNSAFE_componentWillMount() {
			const { lang, start } = this.props;

			require('intl');
			require('intl/locale-data/jsonp/en');
			require('intl/locale-data/jsonp/he');
			require('intl/locale-data/jsonp/es');

			const en = require('react-intl/locale-data/en');
			const he = require('react-intl/locale-data/he');
			const es = require('react-intl/locale-data/es');

			[en, he, es].forEach(locale => addLocaleData(locale));
			const locales = require('../initialState.js').locales;
			const polyfillLocales = require('../polyfillLocales');
			polyfillLocales(global, locales);

			//// Client side changes must be dispatched on componentDidMount, aka
			//// after the first app render, to match client and server HTML. Otherwise,
			//// React attempt to reuse markup will fail.
			start();
		}

		UNSAFE_componentWillReceiveProps(nextProps) {
			if ((nextProps.lang && !this.props.lang) || nextProps.lang != this.props.lang)
				platformActions.app.setRTLbyLang(nextProps.lang);

			this.setState({
				didLangChange: Boolean(this.props.lang && nextProps.lang != this.props.lang),
			});
		}

		render() {
			const { intl, lang, store, app } = this.props;
			const { didLangChange } = this.state;
			var { currentLocale, defaultLocale, initialNow, messages } = intl.toJS();
			const { storageLoaded } = app;

			if (!currentLocale || (lang && lang != currentLocale)) {
				if (lang == 'he') currentLocale = 'he';
				else if (lang == 'es') currentLocale = 'es';
				else if (lang == 'ar') currentLocale = 'ar';
				else currentLocale = 'en';
			}

			moment.locale(currentLocale);
			momentTZ.locale(currentLocale);
			if (!lang || !storageLoaded) return null;

			return (
				<IntlProvider
					defaultLocale={defaultLocale}
					initialNow={initialNow}
					key={currentLocale} // https://github.com/yahoo/react-intl/issues/234
					locale={currentLocale}
					messages={messages[currentLocale]}
				>
					<WrappedComponent intl={intl} store={store} didLangChange={didLangChange} />
				</IntlProvider>
			);
		}
	}

	Start = connect(
		state => ({
			intl: state.intl,
			lang: state.app.lang,
			app: state.app,
		}),
		actions,
	)(Start);

	return Start;
}