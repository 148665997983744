import React from 'react';
import { ProjectStructureContext, ProjectManagerContext } from '../../../common/projects/contexts';
import { injectIntl } from 'react-intl';
import { connectContext } from 'react-connect-context';
import { connect } from 'react-redux';
import { hoistStatics } from 'recompose';
import { compose } from 'redux';
import { buildingFields, floorFields, unitFields } from './config/projectStructureCardConfig';
import _ from 'lodash';
import { updateBuildingEmailsList } from '../../../common/buildings/actions';
import { startToast } from '../../../common/app/actions';
import EmailsInputField from '../../components/CementoComponents/EmailsInputField';
import systemMessages from '../../../common/app/systemMessages';

class ProjectStructureCard extends React.Component {
	constructor(props) {
		super(props);
		this.saveEmailsList = this.saveEmailsList.bind(this);
		this.state = {
			emailsList: {},
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { configurations, buildingsOverview } = nextProps;
		if (
			buildingsOverview.buildingId !== this.props.buildingsOverview.buildingId ||
			this.props.isValDiff(nextProps, [
				'configurations',
				'specs',
				'notifications',
				'drawing',
				'emails',
				'lists',
				'locations',
				buildingsOverview.buildingId,
			])
		) {
			let emailsList = _.get(
				configurations,
				['specs', 'notifications', 'drawing', 'emails', 'lists', 'locations', buildingsOverview.buildingId],
				[],
			);
			this.setState({ emailsList });
		}
	}

	async saveEmailsList(emailsMap) {
		const { selectedProjectId, buildingsOverview, updateBuildingEmailsList, startToast, configurations } = this.props;

		if (!emailsMap) return null;

		let isActiveAlready = _.get(configurations, ['specs', 'notifications', 'drawing', 'emails', 'isActive'], false);
		let updated = await updateBuildingEmailsList(
			selectedProjectId,
			buildingsOverview.buildingId,
			emailsMap,
			isActiveAlready,
		);
		startToast({
			title: updated ? systemMessages.objectSavedSuccessfully : systemMessages.errorOnSave,
			type: updated ? 'success' : 'error',
			values: { objectName: 'רשימת התפוצה' },
		});
	}

	render() {
		const {
			calcInputField,
			buildingsOverview: { type },
		} = this.props;
		const { emailsList } = this.state;

		let inputFields = [];
		switch (type) {
			case 'building':
				inputFields = buildingFields;
				break;
			case 'floor':
				inputFields = floorFields;
				break;
			case 'unit':
				inputFields = unitFields;
				break;
			default:
				break;
		}

		return (
			<>
				{Boolean(calcInputField) && inputFields.map(calcInputField)}
				{Boolean(type === 'building') && (
					<EmailsInputField
						emailsList={emailsList}
						includeUpdateButton={true}
						onSave={this.saveEmailsList}
						editMode={true}
					/>
				)}
			</>
		);
	}
}

ProjectStructureCard = injectIntl(ProjectStructureCard);
const enhance = compose(
	connectContext(ProjectManagerContext.Consumer),
	connectContext(ProjectStructureContext.Consumer),
	connect(state => ({}), { updateBuildingEmailsList, startToast }),
);

export default enhance(ProjectStructureCard);
