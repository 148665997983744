import * as actions from './actions';
import Contact from './contact.js';
import { Map } from 'immutable';
import { Record } from '../transit';

const InitialState = Record({
  map: Map(),
  contactsByPhone: Map(),
  didLoad: false,
  didUpdated: false,  
  didSavedIntoStorage: false,
  lastClientUpdate: false
}, 'contacts');

const initialState = new InitialState;

export default function contactsReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    case actions.GET_NATIVE_CONTACTS_COMPLETED: {
      if (!action.payload)
        return state;

      var { contacts, updated_at } = action.payload;
      state = state.set('didLoad', true);
      state = state.set('didUpdated', true);
      state.set('didSavedIntoStorage', true);
      contacts.loopEach((i, contact) => {
        let newContact =  new Contact(contact);
        state = state.setIn(['map', contact.id], newContact); 
        (contact.phoneNumbers || {}).loopEach((i, phoneNo) => {
          state = state.setIn(['contactsByPhone', (phoneNo || "").replace(/[ ,-]/g, '')], newContact); 
        })
      });

      if (updated_at && (!state.lastClientUpdate || new Date(state.lastClientUpdate) < new Date(updated_at))) {
        var datePlusMinisec = new Date(new Date(updated_at).getTime() + 1).toISOString(); // + 1 ms
        state = state.set('lastClientUpdate', datePlusMinisec);
      }


      return state
    }

    case actions.SAVE_ON_STROAGE: {
      return state.set('didSavedIntoStorage', true);
    }

    case actions.CLEAN_CACHED_CONTACTS: {
      return initialState;
    }
  }

  return state;
}