import React from "react";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { withRouter } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { Sidebar } from "../components";
import Buildings from "../views/Locations/Buildings.js";
import { draftValidator, saveUIParams } from "../../common/ui/actions";
import theme from "../assets/css/theme";

class SidebarBuilding extends React.Component {
  constructor(props) {
    super(props);
    this.onLocationClick = this.onLocationClick.bind(this);
  }

  componentDidMount() {
    this.props.saveUIParams({ sidebarLoaded: new Date().getTime() });
  }

  onLocationClick(locationsIds) {
    const { selectedProjectId, location, contentType, draftValidator } =
      this.props;
    let locationPageURL = "locationContainerPage";
    let query = location.getNested(["search"], "");
    let params = selectedProjectId + "/" + contentType + "/" + locationPageURL;
    ["buildingId", "floorId", "unitId"].forEach((key) => {
      params += "/" + (locationsIds[key] ? locationsIds[key] : "_");
    });

    draftValidator(() =>
      this.props.history.push("/main/projectContainerPage/" + params + query)
    );
  }

  render() {
    const {
      intl,
      rtl,
      marginTop,
      showBadges,
      style,
      filteredPosts,
      urlParams,
    } = this.props;
    return (
      <Sidebar
        intl={intl}
        rtl={rtl}
        style={style || {}}
        mainCompontent={
          <Buildings
            urlParams={urlParams}
            contentType={urlParams.getNested(["contentType"])}
            selectedLocation={{
              buildingId:
                urlParams.getNested(["buildingId"]) == "_"
                  ? null
                  : urlParams.getNested(["buildingId"]),
              floorId:
                urlParams.getNested(["floorId"]) == "_"
                  ? null
                  : urlParams.getNested(["floorId"]),
              unitId:
                urlParams.getNested(["unitId"]) == "_"
                  ? null
                  : urlParams.getNested(["unitId"]),
            }}
            showBadges={showBadges}
            style={{
              marginTop,
              visibility: "visible",
              width: "100%",
              height: "inherit",
              backgroundColor: theme.backgroundColor,
            }}
            maxUnitsInFloor={4}
            onClick={this.onLocationClick}
            filteredPosts={filteredPosts}
          />
        }
      />
    );
  }
}

SidebarBuilding = withRouter(SidebarBuilding);
const enhance = compose(
  connect(
    (state) => ({
      urlParams: state.ui.urlParams,
    }),
    { saveUIParams, draftValidator }
  )
);
export default enhance(SidebarBuilding);
