import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { injectIntl } from 'react-intl';
import systemMessages from '../../../common/app/systemMessages';
import siteControlMessages from '../../../common/siteControl/siteControlMessages';
import theme from '../../assets/css/theme';
import Text from '../CementoComponents/Text';
const presentIndicatorSize = 10;


export const ActivityIndicator = props => {
  const { isActive } = props;
  if (_.isNil(isActive))
    return null;

  return <div style={activityIndicatorStyles[isActive ? 'activeActivityIndicator' : 'nonActiveActivityIndicator']}></div>;
};

const activityIndicatorStyles = {
  activeActivityIndicator: {
    margin: theme.padding,
    height: presentIndicatorSize,
    width: presentIndicatorSize,
    backgroundColor: theme.brandSuccess,
    borderRadius: theme.circleBorderRadius
  },
  nonActiveActivityIndicator: {
    margin: theme.padding,
    height: presentIndicatorSize,
    width: presentIndicatorSize,
    backgroundColor: theme.placeholderTextColor,
    borderRadius: theme.circleBorderRadius
  },
};

export default injectIntl(props => {
  const { lastSynced, isActive: _isActive, isRefreshing, intl } = props;
  const isActive = _isActive && lastSynced && moment().isSame(lastSynced, 'day');
  const formattedLastSync = moment(lastSynced).calendar();
  let message = lastSynced ? siteControlMessages.presenceLogs.lastUpdated : systemMessages.camerasMessages.noData;
  return (
    <div style={camerasMonitorStyles.container}>
      <ActivityIndicator isActive={Boolean(isActive)} />
      <Text values={{ value: formattedLastSync }}>{message}</Text>
    </div>
  );
});

const camerasMonitorStyles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
};