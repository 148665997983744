import * as actions from './actions';

import { OrderedMap, Map } from 'immutable';
import { Record } from '../transit';

const InitialState = Record({
  safetyGrades: Map(),
}, 'safety');

const initialState = new InitialState;

export default function safetyReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {    
    
    case actions.GET_SAFETY_GRADE: {
      if (!action.payload)
        return state;

      const { grades } = action.payload;
      var retArray = [];
      if (grades)
        retArray = Object.values(grades);
 
      for (var i = 0; i < retArray.length; i++) {
        var currGrade = retArray[i];
        if (currGrade)
          state = state.setIn(['safetyGrades', action.projectId, currGrade.id], currGrade);
      }

      return state;
    }
  }

  return state;
}
