import { defineMessages } from 'react-intl';

export default defineMessages({
  empty: {
    defaultMessage: 'No comments yet',
    id: 'comments.comments.empty'
  },
  title: {
    defaultMessage: 'Comments',
    id: 'comments.comments.title'
  },
  placeholder: {
    defaultMessage: 'Write here your comment',
    id: 'comments.comments.placeholder'
  },
  loading: {
    defaultMessage: 'Loading comments',
    id: 'comments.comments.loading'
  },
  post: {
    defaultMessage: 'Post',
    id: 'newComment.button.post'
  },
  moreComments: {
    defaultMessage: '{counter} more unread comments',
    id: 'comments.more'
  }
});
