import _ from 'lodash';
import analyticsMessages from '../analytics/analyticsMessages';
import systemMessages from '../app/systemMessages';
import reportsMessages from '../reports/reportsMessages';
import safetyMessages from '../safety/safetyMessages';
import siteControlMessages from '../siteControl/siteControlMessages';
import postMenuMessages from '../posts/postsMenuMessages';
import buttonsMessages from '../app/buttonsMessages';
import projectManagerMessages from '../app/projectManagerMessages';
import { encodeFilterToSearch } from '../../web/app/funcs';
import { POSTS_FILTER_URL_KEY } from '../../web/app/constants';
import * as issueStates from '../issues/issueStates';
import * as propertyTypes from '../propertiesTypes/propertiesTypes';

const statusReject = { value: 'rejected' };
const statusResolved = { value: 'resolved' };
const statusConfirm = { value: 'confirm' };
const statusConfirm2 = { value: 'confirm2' };
const statusPartial = { value: 'partial' };
const statusIrrelevant = { value: 'irrelevant' };
const allStatusArray = [statusReject, statusResolved, statusConfirm, statusConfirm2, statusPartial, statusIrrelevant];
const favAllStages = { label: analyticsMessages.allStages, values: { status: allStatusArray } };

const getDefaultCompanyMenus = () => {
	let menus = {};

	menus['safety'] = {
		['general']: {
			caption: safetyMessages.menu.company.main,
			options: [{ label: safetyMessages.menu.company.title, contentType: 'safety', type: 'contentRoot' }],
			num: 0,
			contentType: 'safety',
		},
	};

	menus['settings'] = {
		['analytics']: {
			caption: analyticsMessages.reports,
			options: [
				{
					label: safetyMessages.employees,
					type: 'members',
					id: 'members',
					contentType: 'settings',
					subjectType: 'members',
				},
				{
					label: safetyMessages.companies,
					type: 'companies',
					id: 'companies',
					contentType: 'settings',
					subjectType: 'companies',
				},
			],
			num: 0,
			contentType: 'settings',
		},
	};

	return menus;
};

const getDefaultProjectMenus = ({ formsInfoPropTypes }) => {
	let defaultStaticMenu = {};

	// Need to take this from the index.js that build the headers (Currently named: dashboard.js)
	{
		['qa', 'issues', 'records', 'safety'].forEach(mnKey => {
			if (!defaultStaticMenu[mnKey]) defaultStaticMenu[mnKey] = {};

			defaultStaticMenu[mnKey]['dashboardSection'] = {
				caption: analyticsMessages.dashboards.menu.main,
				num: 0,
				type: 'dashboardSection',
				options: [{ label: analyticsMessages.dashboards.menu.title, type: 'dashboardView', contentType: mnKey }],
			};
		});
	}

	['qa', 'drawings', 'records', 'details'].forEach(mnKey => {
		if (!defaultStaticMenu[mnKey]) defaultStaticMenu[mnKey] = {};

		defaultStaticMenu[mnKey]['buildingViewSection'] = {
			caption: analyticsMessages.detailView,
			num: 2,
			type: 'buildingViewSection',
			options: [
				{
					label: analyticsMessages.buildingView,
					type: 'buildingView',
					contentType: mnKey,
					...(mnKey === 'records' ? { query: 'itemType=records' } : {}),
				},
			],
		};
	});

	['issues'].forEach(mnKey => {
		if (!defaultStaticMenu[mnKey]) defaultStaticMenu[mnKey] = {};

		defaultStaticMenu[mnKey]['buildingViewSection'] = {
			caption: analyticsMessages.detailView,
			num: 2,
			type: 'buildingViewSection',
			options: [
				{
					label: postMenuMessages.tasks,
					type: 'buildingView',
					contentType: mnKey,
					subjectType: 'openedIssues',
					query: encodeFilterToSearch(
						{ issueState: [issueStates.ISSUE_STATE_OPENED, issueStates.ISSUE_STATE_RESOLVED] },
						'itemType=tasks',
						POSTS_FILTER_URL_KEY,
					),
				},
			],
		};
	});

	['info'].forEach(mnKey => {
		if (!defaultStaticMenu[mnKey]) defaultStaticMenu[mnKey] = {};

		defaultStaticMenu[mnKey]['analytics'] = {
			caption: safetyMessages.reports,
			type: 'analytics',
			num: 1,
			options: [
				{
					label: systemMessages.specs,
					type: 'locationsReport',
					id: 'locations',
					contentType: 'info',
					subjectType: 'locations',
					adminOnly: true,
				},
			],
		};
		defaultStaticMenu[mnKey]['buildingViewSection'] = {
			caption: analyticsMessages.detailView,
			num: 2,
			type: 'buildingViewSection',
			options: [{ label: analyticsMessages.buildingView, type: 'buildingView', contentType: mnKey }],
		};
	});

	let currFormsMenuItemNum = 1;
	['forms'].forEach(mnKey => {
		if (!defaultStaticMenu[mnKey]) defaultStaticMenu[mnKey] = {};

		defaultStaticMenu[mnKey]['analytics'] = {
			caption: reportsMessages.reports,
			type: 'analytics',
			num: 1,
			options: [
				{
					label: buttonsMessages.reports,
					type: 'none',
					id: 'dailyReport',
					num: currFormsMenuItemNum++,
					contentType: 'dailyReport',
					subjectType: 'forms',
					isFallback: true,
				},
			],
		};
	});

	['forms'].forEach(mnKey => {
		// Generate and add expanded array props table menu items
		_.values(formsInfoPropTypes)
			.sort((a, b) => (a?.ordinalNo || 0) - (b?.ordinalNo || 0))
			.forEach(prop => {
				if (propertyTypes.ARRAY !== prop.type || !_.get(prop, ['settings', 'generateSubTable'], false)) return;

				defaultStaticMenu[mnKey]['analytics'].options.push({
					label: prop.getCementoTitle(),
					id: `${prop.sectionId}_${prop.id}`,
					num: currFormsMenuItemNum++,
					contentType: 'forms',
					pageType: 'analytics',
					type: 'analytics',
					subjectType: 'forms',
					values: {
						columnVisibility: {
							[prop.id]: { table: true },
						},
						isSubTable: true,
					},
				});
			});
	});

	['safety'].forEach(mnKey => {
		if (!defaultStaticMenu[mnKey]) defaultStaticMenu[mnKey] = {};

		defaultStaticMenu[mnKey]['analytics'] = {
			caption: safetyMessages.reports,
			type: 'analytics',
			num: 1,
			options: [
				{
					label: safetyMessages.employees,
					type: 'employeesReport',
					id: 'employees',
					contentType: 'safety',
					subjectType: 'employees',
					adminOnly: true,
					isFallback: true,
				},
				{
					label: safetyMessages.equipment,
					type: 'equipmentReport',
					id: 'equipment',
					contentType: 'safety',
					subjectType: 'equipment',
					adminOnly: true,
					isFallback: true,
				},
			],
		};
		defaultStaticMenu[mnKey]['buildingViewSection'] = {
			caption: safetyMessages.issues.sectionTitle,
			type: 'buildingViewSection',
			num: 2,
			options: [
				{
					label: safetyMessages.forms.captions.issues,
					type: 'buildingView',
					contentType: 'safety',
					query: encodeFilterToSearch(
						{ issueState: [issueStates.ISSUE_STATE_OPENED, issueStates.ISSUE_STATE_RESOLVED] },
						'itemType=tasks',
						POSTS_FILTER_URL_KEY,
					),
				},
				{ label: safetyMessages.issues.docs, type: 'buildingView', contentType: 'safety', query: 'itemType=records' },
			],
		};
	});

	['projectManager'].forEach(mnKey => {
		if (!defaultStaticMenu[mnKey]) defaultStaticMenu[mnKey] = {};

		defaultStaticMenu[mnKey]['settings'] = {
			caption: projectManagerMessages.sideNav.settings,
			type: 'settings',
			num: 1,
			options: [
				{
					label: projectManagerMessages.sideNav.projectProperties,
					type: 'projectManagerView',
					contentType: mnKey,
					subjectType: 'projectProperties',
				},
				{
					label: projectManagerMessages.sideNav.authorizations,
					type: 'projectManagerView',
					contentType: mnKey,
					subjectType: 'authorizations',
				},
				{
					label: projectManagerMessages.sideNav.checklistManagement,
					type: 'projectManagerView',
					contentType: mnKey,
					subjectType: 'checklistManagement',
				},
				{
					label: projectManagerMessages.sideNav.projectStructure,
					type: 'projectManagerView',
					contentType: mnKey,
					subjectType: 'projectStructure',
				},
				{
					label: projectManagerMessages.sideNav.createProject,
					type: 'projectManagerView',
					contentType: mnKey,
					subjectType: 'createProject',
				},
				{ label: systemMessages.manage.formsManager, type: 'projectManagerView', subjectType: 'formsManager' },
				{
					label: systemMessages.camerasMessages.cameras,
					type: 'projectManagerView',
					contentType: mnKey,
					subjectType: 'camerasDashboard',
				},
				{
					label: projectManagerMessages.sideNav.locationsGroupsManagement,
					type: 'projectManagerView',
					contentType: mnKey,
					subjectType: 'locationsGroupsManagement',
					id: 'locationsGroupsManagement',
				},
				{ label: 'מנהל הגדרות 0.1', type: 'none', subjectType: 'projectManager2' },
			],
		};
	});

	return defaultStaticMenu;
};

const getProjectCombinedMenus = ({ menus, defaultProjectMenu }) => {
	let combinedMenu = {};

	const menusByContentType = _.groupBy(_.values(menus), 'contentType');
	if (!menusByContentType['qa']?.length)
		combinedMenu['qa'] = {
			['analytics']: { caption: analyticsMessages.reports, options: [favAllStages], num: 1, contentType: 'qa' },
		};
	if (!menusByContentType['safety']?.length)
		combinedMenu['safety'] = {
			['analytics']: { caption: analyticsMessages.reports, options: [favAllStages], num: 1, contentType: 'safety' },
		};
	if (!menusByContentType['forms']?.length)
		combinedMenu['safety'] = {
			['analytics']: { caption: analyticsMessages.reports, options: [favAllStages], num: 1, contentType: 'forms' },
		};
	if (!menusByContentType['info']?.length)
		combinedMenu['info'] = {
			['analytics']: {
				caption: analyticsMessages.reports,
				options: [favAllStages],
				num: 1,
				contentType: 'info',
				adminOnly: true,
			},
		};
	if (!menusByContentType['siteControl']?.length)
		combinedMenu['siteControl'] = {
			['analytics']: {
				num: 1,
				contentType: 'analytics',
				caption: siteControlMessages.general.hr,
				options: [{ label: siteControlMessages.menu.mainTitle, id: 'employees', subjectType: 'employees' }],
			},
		};
	Object.values(menus || {})
		.sort((b, a) => (b.num ? b.num : 0) - (a.num ? a.num : 0))
		.forEach(menu => {
			let contentType = menu.contentType;
			let pageType = menu.pageType;

			if (!combinedMenu[contentType])
				combinedMenu[contentType] = {
					[pageType]: { num: 1, caption: analyticsMessages.reports, options: [], contentType },
				};
			combinedMenu[contentType][pageType].options.push(menu);
		});

	// Need to take this from the index.js that build the headers (Currently named: dashboard.js)
	['members', 'qa', 'issues', 'drawings', 'records', 'details', 'safety', 'info', 'projectManager', 'forms'].forEach(
		mnKey => {
			if (!combinedMenu[mnKey]) combinedMenu[mnKey] = {};

			if (defaultProjectMenu && defaultProjectMenu[mnKey]) {
				Object.keys(defaultProjectMenu[mnKey]).forEach(menuSubKey => {
					if (combinedMenu[mnKey][menuSubKey]) {
						let combinedOptions = combinedMenu
							.getNested([mnKey, menuSubKey, 'options'], [])
							.concat(
								defaultProjectMenu.getNested([mnKey, menuSubKey, 'options'], []).filter(option => !option.isFallback),
							);
						combinedMenu = combinedMenu.setNested([mnKey, menuSubKey, 'options'], combinedOptions);
					} else combinedMenu = combinedMenu.setNested([mnKey, menuSubKey], defaultProjectMenu[mnKey][menuSubKey]);
				});
			}
		},
	);

	return combinedMenu;
};

export { getDefaultProjectMenus, getProjectCombinedMenus, getDefaultCompanyMenus };
