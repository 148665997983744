import {useCallback, useContext, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import { v4 as uuidv4 } from "uuid";
import {ProjectContext} from '../../common/projects/contexts';
import {envParams, firebaseDeps} from '../../common/configureMiddleware';
import {DRAWINGS_KEY} from '../../common/drawings/reducer';
import {prepareFirebaseObject} from '../../common/lib/utils/utils';
import {cleanDrawingsCachedData, updateDrawings} from '../../common/drawings/actions';
import {hideLoading, startLoading} from '../../common/app/actions';
import systemMessages from '../../common/app/systemMessages';
import {updateProjectLastUpdateTS} from '../../common/lastUpdates/actions';
import {platformActions} from '../../common/platformActions';
import usePermissions from './usePermissions';
import _ from 'lodash';
const operationId = 'drawing-save';

const useDrawings = () => {
  const { selectedProjectId } = useContext(ProjectContext);
  const { checkPermissions } = usePermissions();
  const dispatch = useDispatch();

  const startLoadingAnimation = useCallback(() => {
    dispatch(startLoading({
      title: systemMessages.savingMessage,
      overlay: true,
      hideOnBackgroundPress: false,
      operationId,
    }));
  }, []);

  const stopLoadingAnimation = useCallback(() => {
    dispatch(hideLoading(operationId));
  }, []);

  const deleteDrawing = useCallback(async (drawingId) => {
    startLoadingAnimation();
    const firebasePath = `${DRAWINGS_KEY}/projects/${selectedProjectId}/${drawingId}`;
    await firebaseDeps.firebaseDatabase().ref(firebasePath).remove();
    updateTimestamp();
    stopLoadingAnimation();
  }, [selectedProjectId]);

  const updateTimestamp = useCallback(() => {
    dispatch(updateProjectLastUpdateTS('drawings', selectedProjectId));
  }, [selectedProjectId]);

  const addDrawingPathByLocation = useCallback((drawing) => {
    const firebasePath = `${DRAWINGS_KEY}/projects/${selectedProjectId}/${drawing.id}`;
    const ref = firebaseDeps.firebaseDatabase().ref(firebasePath);

    return ref.update(prepareFirebaseObject(drawing));
  }, [selectedProjectId]);

  const upsertDrawingApiCall = useCallback((drawing) => {
    const { apiServer } = envParams;
    const body = {
      drawing,
      projectId: selectedProjectId,
    };

    return platformActions.net.fetch(`${apiServer}/v1/drawings`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }, [selectedProjectId]);

  const updateDrawing = useCallback(async (updates = {}, original) => {
    if (!Object.keys(updates).length) {
      return original;
    }
    startLoadingAnimation();
    const { specificLocations, category, uriRemote, uri, locationType } = updates;
    const {
      id,
      specificLocations: oldSpecificLocations,
      category: oldCategory,
      title,
      version,
      date,
      number,
      ext,
    } = original;
    const locationTypeString = _.get(_.keys(locationType), 0);
    const preparedData = {
      id,
      title,
      version,
      date,
      number,
      ['-unitsTags']: locationTypeString === '-units' ? original['-unitsTags'] : null,
      ['-floorsTags']: locationTypeString === '-floors' ? original['-floorsTags'] : null,
      ['-buildingsTags']: locationTypeString === '-buildings' ? original['-buildingsTags'] : null,
      ...updates,
      category: category || (oldCategory ? { [oldCategory]: oldCategory } : null),
      // null - means removed, undefined - no changes
      specificLocations: specificLocations === undefined ?  oldSpecificLocations : specificLocations,
      updatedTS: firebaseDeps.firebaseDatabase.ServerValue.TIMESTAMP,
    };
    try {
      if (uriRemote || uri) {
        // reset everything set by drawings convertor
        preparedData.uri = uriRemote || uri;
        preparedData.ext = ext;
        preparedData.uriRemote = null;
        preparedData.uriPdf = null;
        preparedData.uriThumbnail = null;
        preparedData.multiPage = null;
        preparedData.processed = null;
      }
      const res = await upsertDrawingApiCall(preparedData);
      const updatedDrawings = await res.json();
      dispatch(updateDrawings(updatedDrawings, selectedProjectId));
      return updatedDrawings[id];
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      stopLoadingAnimation();
    }
  }, [selectedProjectId]);

  const createDrawing = useCallback(async (drawing, predefinedData = {}) => {
    try {
      const {
        category, number, version,
        date, locationType,
      } = drawing;
      const ext = drawing.ext || predefinedData.ext;
      const uriRemote = drawing.uriRemote || predefinedData.uriRemote;
      const title = drawing.title || predefinedData.title;
      const specificLocations = drawing.specificLocations || predefinedData.specificLocations;

      if (!uriRemote || !title || (!specificLocations)) {
        return;
      }
      startLoadingAnimation();
      
      const id = uuidv4();
      const locationTypeString = _.first(_.keys(locationType));
      const drawingObject = {
        id,
        specificLocations: specificLocations || predefinedData.specificLocations,
        updatedTS: firebaseDeps.firebaseDatabase.ServerValue.TIMESTAMP,
        ext,
        title: title || predefinedData.title,
        locationType,
        uri: uriRemote,
        category,
        number,
        version,
        date,
        ['-unitsTags']: locationTypeString === '-units' ? drawing['-unitsTags'] : null,
        ['-floorsTags']: locationTypeString === '-floors' ? drawing['-floorsTags'] : null,
        ['-buildingsTags']: locationTypeString === '-buildings' ? drawing['-buildingsTags'] : null,
      };

      const res = await upsertDrawingApiCall(drawingObject);
      const updatedDrawings = await res.json();
      dispatch(updateDrawings(updatedDrawings, selectedProjectId));
      return updatedDrawings[id];
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      stopLoadingAnimation();
    }
  }, [selectedProjectId]);

  const subscribeToDrawings = useCallback(() => {
    dispatch(cleanDrawingsCachedData());
    const path = `lastUpdates/projects/${selectedProjectId}/drawings/lastUpdateTS`;
    const ref = firebaseDeps.firebaseDatabase().ref(path);
    ref.on('value', handleUpdate());
  }, [selectedProjectId]);

  const unsubscribeFromDrawings = useCallback(() => {
    const path = `lastUpdates/projects/${selectedProjectId}/drawings/lastUpdateTS`;
    const ref = firebaseDeps.firebaseDatabase().ref(path);
    ref.off();
  }, [selectedProjectId]);

  const handleUpdate = useCallback(() => {
    return async (snap) => {
      const res = await platformActions.net.fetch(`${envParams.apiServer}/v1/drawings?projectId=${selectedProjectId}&version=2`, {
        method: 'GET',
      });

      const data = await res.json();

      if (data) {
        dispatch(updateDrawings(data, selectedProjectId));
      }
    }
  }, [selectedProjectId]);

  const canCreate = useMemo(() => {
    return checkPermissions('drawings', 'create');
  }, [checkPermissions]);

  const canRead = useMemo(() => {
    return checkPermissions('drawings', 'read');
  }, [checkPermissions]);

  const canUpdate = useMemo(() => {
    return checkPermissions('drawings', 'update');
  }, [checkPermissions]);

  const canDelete = useMemo(() => {
    return checkPermissions('drawings', 'delete');
  }, [checkPermissions]);

  const canComment = useMemo(() => {
    return checkPermissions('drawings', 'comment');
  }, [checkPermissions]);

  return {
    createDrawing,
    updateDrawing,
    subscribeToDrawings,
    unsubscribeFromDrawings,
    canDelete,
    canCreate,
    canRead,
    canUpdate,
    canComment,
  };
};

export default useDrawings;