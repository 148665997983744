import { Record } from '../transit';
import * as companiesTypes from './companiesTypes';

var defaultType = {};
defaultType[companiesTypes.COMPANY_TYPES_SUB] = companiesTypes.COMPANY_TYPES_SUB;

const Company = Record({
  id: null,
  name: null,
  trades: null,
  types: defaultType,
  defaultAssignee: null,
  isDeleted: null,
  logo: null,
  darkLogo: null,
  updatedTS: null,
  projects: null,
}, 'company');

export default Company;
