import React, { useCallback } from 'react';
import _ from 'lodash';

// Components
import StandardInput from "./StandardInput";


const ObjectInputField = (props) => {
  const { inputInfo, title, value, disabled, onChange } = props;

  const handleValueChange = useCallback((key, val) => {
    let newVal = Object.assign({}, value);

    if (typeof key === 'string')
      newVal.key = key;

    if (typeof val === 'string')
      newVal.value = val;

    if (onChange)
      onChange(newVal);
  }, [value, onChange]);

  return (
      <div style={{display: 'flex'}}>
        <StandardInput
          inputInfo={inputInfo ? `${inputInfo} Key` : 'Object input key field'}
					title={title ? `${title} Key` : 'Object Key'}
					type={'String'}
					value={value ? value.key : ''}
          disabled={disabled}
          onChange={val => handleValueChange(val)}
				/>
        <StandardInput
          inputInfo={inputInfo ? `${inputInfo} Value` : 'Object input value field'}
					title={title ? `${title} Value` : 'Object Value'}
					type={'String'}
					value={value ? value.value : ''}
          disabled={disabled }
          onChange={val => handleValueChange(null, val)}
				/>
      </div>
  );
}

export default ObjectInputField;

