import * as actions from './actions';
import * as usersActions from '../users/actions';
import * as authActions from '../auth/actions';
import { REPLACE_USER } from '../auth/actions';
import * as appActions from '../app/actions';

import Member from './member';
import Project from '../projects/project';
import { Seq, Map } from 'immutable';
import { Record } from '../transit';

const InitialState = Record({
  map: Map(),
  inProcessMap: Map(),
  didLoad: false,
  lastClientUpdatePerProject: Map(),
  reviews: Map(),
  thumbsUpRecived: Map(),
  thumbsUpGiven: Map(),
  images: Map(),
  likes: Map(),
  reviews: Map(),
}, 'members');

const initialState = new InitialState;

export default function membersReducer(state = new InitialState, action) {

  switch (action.type) {
    case actions.SEARCH_USERS_BY_PHONE: {
      if (!action.payload || !action.payload.members)
        return state;

      const { members } = action.payload;
    
      members.loopEach((i, curr) => {
        if (curr && curr.DBMember && curr.localContact) {
          var newMember = curr.DBMember;
          var member = new Member({
            ...newMember, 
            localId: curr.localContact.id, 
            localDisplayName: curr.localContact.displayName,
            localAvatar: curr.localContact.avatar
          });
          state = state.setIn(['map', curr.DBMember.id], member);
        }
      })
      
      return state;
    }

    case actions.SEARCH_UNLOCAL_USERS_BY_UIDS + '_ERROR':
    case actions.SEARCH_UNLOCAL_USERS_BY_UIDS + '_START': {
      const uids = action.getNested(['meta', 'action','uids']);
      if (!uids)
        return state;

      Object.keys(uids).forEach(uid => {
        state = state.setIn(['inProcessMap', uid], true);
      });
      return state;
    }

    case actions.SEARCH_PROJECT_USERS: 
    case actions.SEARCH_UNLOCAL_USERS_BY_UIDS + '_SUCCESS': {
      if (!action.payload || !action.payload.DBMembers)
        return state;

      var last_updated_user_ts = 0;
      const { projectId, DBMembers } = action.payload;

      Object.values(DBMembers).map(DBMember => {
        if (!DBMember.id)
          return;

        if (DBMember.updated_at && (!last_updated_user_ts || new Date(last_updated_user_ts) < new Date(DBMember.updated_at)))
          last_updated_user_ts = DBMember.updated_at;

        var prev = state.getNested(['map', DBMember.id], {});
        var member = new Member({
          ...DBMember,
          localId: prev.localId,
          localAvatar: prev.localAvatar,
          localDisplayName: prev.localDisplayName,
        })

        state = state.setIn(['map', DBMember.id], member);
        state = state.setIn(['inProcessMap', DBMember.id], false);
      });

      if (action.type == actions.SEARCH_PROJECT_USERS && projectId && last_updated_user_ts) {
        var datePlusMinisec = new Date(new Date(last_updated_user_ts).getTime() + 1).toISOString(); // + 1 ms
        state = state.setIn(['lastClientUpdatePerProject', projectId], datePlusMinisec);
      }
        
      return state;
    }

    case actions.ON_OTHER_USER_PROFILE_UPDATE + "_SUCCESS": {   
      const { DBMember, uid } = action.payload;

      if (state.map.get(uid) == null)
        return state;

      var prev = state.getNested(['map', DBMember.id], {});
      var member = new Member({
        ...DBMember,
        localId: prev.localId,
        localAvatar: prev.localAvatar,
        localDisplayName: prev.localDisplayName,
        activated: prev.activated,
      }) 
      
      state = state.setIn(['map', member.id], member);  
      return state;
    }

    case usersActions.UPLOAD_MY_AVATAR + '_SUCCESS': {
      const { avatar, id } = action.payload;

      if (state.map[id] == null)
        return state;

      return state.setIn(['map', id, 'avatar'], avatar);
    }

    case usersActions.UPDATE_MY_BACKGROUND + '_SUCCESS': {
      const { backgroundImage, id } = action.payload;

      if (state.map[id] == null)
        return state;

      return state.setIn(['map', id, 'backgroundImage'], backgroundImage);
    }


    case usersActions.AUTH0_SAVE_USER + "_SUCCESS": 
    case usersActions.GET_MY_USER_DETAILS: {
      const { user } = action.payload;
      if (!user)
        return state;
      
      var prev = state.getNested(['map', user.id], {});
      var myMemberRecord = new Member({
        ...user, 
        localId: prev.localId,
        localAvatar: prev.localAvatar,
        localDisplayName: prev.localDisplayName,
        projects: user.projects
      });

      return state.setIn(['map', user.id], myMemberRecord);
    }

    case usersActions.UPDATE_MY_METADATA + '_SUCCESS': {
      const { key, newValue, values, myId } = action.payload;
      if (!myId)
        return state;
      
      if (values) {
        values.loopEach((key, curr) => {
          try {
            state = state.updateIn(['map', myId, key], value => curr);
          }
          catch (error) {
            console.log('error: ' + error)
          }
        })
      }

      if (key && newValue)
        state = state.updateIn(['map', myId, key], value => newValue);

      return state;
    }

    case actions.GET_USER_PAGE + '_SUCCESS': {
      if (!action.payload)
        return state;

      const { userPage, id } = action.payload;

      if (userPage && userPage.images)
        userPage.images.loopEach((key, value) => {
          var projectId = key;
          if (value && value.picked)
            value.picked.loopEach((imageKey, imageValue) => state = state.setIn(['images', id, projectId, imageKey], imageValue));
        })
      else
        state = state.setIn(['images', id], new Map());


      if (userPage && userPage.likes)
        userPage.likes.loopEach((key, value) => {
          var projectId = key;
          if (value)
            value.loopEach((likeKey, likeValue) => state = state.setIn(['likes', id, projectId, likeKey], likeValue));
        })
      else
        state = state.setIn(['likes', id], new Map());


      if (userPage && userPage.reviews)
        userPage.reviews.loopEach((key, value) => {
          var projectId = key;
          if (value)
            Object.values(value).map(review => {if (review.owner && review.owner.id) state = state.setIn(['reviews', id, projectId, review.owner.id], review)});
        })
      else
        state = state.setIn(['reviews', id], new Map());


      return state;
    }

    case actions.UPSERT_USER_PAGE_IMAGE + '_SUCCESS': {
      if (!action.payload)
        return state;

      const { uid, projectId, image, imageId, isDelete } = action.payload;

      if (image)
        state = state.setIn(['images', uid, projectId, imageId], image);
      else
        state = state.deleteIn(['images', uid, projectId, imageId]);
      return state;
    }

    case appActions.CLEAN_CACHE: {
      return state.setIn(['lastClientUpdatePerProject'], new Map());
    }
    
    case actions.CLEAN_CACHED_MEMBERS: {
      return initialState;
    }

    case REPLACE_USER + "_SUCCESS": {
      return initialState;
    }
	}
	
  return state;
}
