import React, {useCallback, useEffect, useMemo} from 'react';
import theme from '../../../assets/css/theme';
import FunctionalInput from '../../../../common/app/components/FunctionalInput';
import _ from 'lodash';
import {FILES_ARRAY} from '../../../../common/propertiesTypes/propertiesTypes';
import ZoomImage from '../../../components/CementoComponents/ZoomImage';

/**
 * @param {PropertiesPropInputProps} props
 * @returns
 */
const PropertiesPropInput = props => {
	const {
		onChange,
		prop,
		isExpanded,
		innerValueId,
		mode,
		isDisabled,
		openSideCard,
		onRef,
		onImageSelect,
		subjectName,
		value,
		latestValueId,
		extraPropertiesTypes,
	} = props;
	const propId = prop?.id;
	if (!propId || propId === 'groups') return null;

	let _value = value;
	if (_.isNil(_value)) _value = prop.getNested(['settings', 'defaultVal'], null);
	const titleStyle = isExpanded && mode === 'modal' ? { fontSize: theme.fontSizeH4 } : {};

	const handleChange = useCallback(
		(propId, data, status) => {
			if (onChange) onChange(propId, data, status);
		},
		[onChange],
	);

	const handleFileClick = useCallback((file) => {
		if (!file) {
			openSideCard?.('nothingToDisplay');
			return;
		}

		const SideCardComponent = () => (
			<div style={{ padding: `${theme.margin * 2}px`, height: '100%' }}>
				<ZoomImage
					src={file.uri}
					pdfMode={file.type === 'pdf'}
					maxHeight={'100%'}
					maxWidth={'100%'}
					toolbar
				/>
			</div>
		);
		const title = prop.getCementoTitle();
		const params = {
			headerParams: {
				title,
				noTitle: true
			},
			objectName: title,
			componentId: `innerObjectSelectComponent_${prop.id}_${file.uri}`
		};

		openSideCard?.('_blankCard', params, SideCardComponent);
	}, [prop, openSideCard]);

	const handleInnerInputPress = useCallback(
		/** @param {string} valueId */
		valueId => {
			const Component = () => (
				<div style={{ padding: `${theme.margin * 2}px ${theme.margin * 2}px` }}>
					<PropertiesPropInput {...props} isExpanded innerValueId={valueId} />
				</div>
			);

			const title = prop.getCementoTitle();
			if (openSideCard)
				openSideCard(
					'_blankCard',
					{
						headerParams: { title, noTitle: true },
						objectName: title,
						componentId: `innerObjectSelectComponent_${propId}_${valueId}`,
					},
					Component,
				);
		},
		Object.values(props),
	);

	const renderPreview = useMemo(() => {
		if (prop?.type === FILES_ARRAY) {
			return (file) => handleFileClick(file);
		}
	}, [prop?.type, handleFileClick])

	useEffect(() => {
		if (mode === 'modal' && renderPreview) {
			renderPreview(_value?.[0] || null);
		} else if ( mode === 'modal' && !_value ) {
			openSideCard?.('nothingToDisplay');
		}
	}, [mode, _value, prop?.type, isDisabled]);

	const onCardClick = useCallback((valueId) => {
		handleInnerInputPress(valueId)
	}, [handleInnerInputPress]);

	const handleImageSelect = useCallback(
		/**
		 *
		 * @param {string} src
		 * @param {boolean} pdfMode
		 */
		(src, pdfMode) => {
			if (onImageSelect) onImageSelect(prop.title, src, pdfMode);
		},
		[onImageSelect, prop.title],
	);

	return (
		<FunctionalInput
			alignCenter
			key={propId + '_' + innerValueId + '_' + isDisabled}
			value={_value}
			withResize={false}
			onChange={handleChange}
			autoSort={false}
			disabled={isDisabled}
			onRef={!innerValueId && onRef}
			inputKey={propId + '_' + innerValueId + '_' + isDisabled}
			propId={propId}
			isExpandSummary={isExpanded}
			innerValueId={innerValueId}
			onCardClick={onCardClick}
			renderPreview={renderPreview}
			noTitle={isExpanded && mode === 'card'}
			titleStyle={titleStyle}
			subjectName={subjectName}
			extraPropertiesTypes={extraPropertiesTypes}
			latestValueId={latestValueId}
			containerStyle={{ padding: '0px 0px 25px' }}
			openPDFInWebPage={false}
			mode={'card'} // Only for files array, only supports 'card'
			onImageSelect={handleImageSelect}
			isMulti={prop?.isMulti}
			businessType={prop?.businessType}
		/>
	);
};

export default PropertiesPropInput;
