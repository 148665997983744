import jwtDecode from 'jwt-decode';
import ExtraError from '../lib/errors/extraError'
import { setLang, cleanDynamicCachedData, clearALLData } from '../app/actions';
import _ from 'lodash';
import { getNewAccessTokenFromServer } from './funcs';
import { v4 as uuidv4 } from 'uuid';


//import { signOut } from '../auth/actions';

// Firebase auth actions are in src/common/lib/redux-firebase/actions.js
export const SMS_VERIFICATION_REQUEST_SENT = 'SMS_VERIFICATION_REQUEST_SENT';
export const SMS_VERIFICATION_REQUEST_RECIVED = 'SMS_VERIFICATION_REQUEST_RECIVED';
export const SMS_VERIFICATION_SENT = 'SMS_VERIFICATION_SENT';
export const SMS_VERIFICATION_VERIFIED = 'SMS_VERIFICATION_VERIFIED';
export const SMS_VERIFICATION_VERIFIED_ERROR = 'SMS_VERIFICATION_VERIFIED_ERROR';
export const LOAD_AUTH0_USER_DATA = 'LOAD_AUTH0_USER_DATA';
export const INVITER_UID_RESPONSE = 'INVITER_UID_RESPONSE';
export const INVITER_UID_REQUEST_SENT = 'INVITER_UID_REQUEST_SENT';
export const REPLACE_USER = 'REPLACE_USER';
export const RESEND_SMS = 'RESEND_SMS';
export const SIGN_OUT = 'SIGN_OUT';
export const SILENT_SIGN_OUT = 'SILENT_SIGN_OUT';
export const INVITER_CODE_SET = 'INVITER_CODE_SET';
export const START_SMS_LISTENER = 'START_SMS_LISTENER';
export const GET_AUTH0_TOKEN = 'GET_AUTH0_TOKEN';
export const TRY_REFRESH_AUTH0_TOKEN = 'TRY_REFRESH_AUTH0_TOKEN';
 
export function requestVerificationSMS(number) {
  return ({ dispatch, auth0, platformActions }) => {
    const getPromise = async () => {
      let success = true;
      try {
        let resp = await (platformActions.net.fetch(auth0.url + '/passwordless/start', {
          'method' : 'POST',
          'body' : JSON.stringify({
            "client_id": platformActions.app.getPlatform() == 'web' ? auth0.web_client_id : auth0.mobile_client_id,
            "connection": "sms",
            "client_secret":platformActions.app.getPlatform() == 'web' ? auth0.web_client_secret : auth0.mobile_client_secret,
            "phone_number": number
          })
        }));

        var response = await (resp.getJson());
        var error = response.error;
        var userId = response._id;
        // TODO: Update the verification sms status to sent with the given id
        if (userId)
          dispatch({ type: SMS_VERIFICATION_REQUEST_RECIVED, payload: { userId: 'sms|' + userId, number, success }});
        else if (error) {
          console.log(error)
          console.log("requestVerificationSMS error: " + error)
          throw new ExtraError('auth0: verification sms error', {userId, number}, error)
        }
      } catch (err) {
        success = false;
        console.log('auth0: verification sms error :' + err);
        throw new ExtraError('auth0: verification sms error', {number}, err);
      } finally {
        return { number, success };
      }
    }
    return {
      type: SMS_VERIFICATION_REQUEST_SENT,
      payload: getPromise()
    };
  };
}

export function validateVerificationCode(userId, number, verificationCode) {
  return ({ dispatch, auth0, platformActions }) => {  
    const getPromise = async () => {
      try {

        var resp = await platformActions.net.fetch(auth0.url + '/oauth/token', {
          method: 'POST',
          body: JSON.stringify({
            "grant_type": "http://auth0.com/oauth/grant-type/passwordless/otp",
            "client_id": platformActions.app.getPlatform() == 'web' ? auth0.web_client_id : auth0.mobile_client_id,
            "client_secret": platformActions.app.getPlatform() == 'web' ? auth0.web_client_secret : auth0.mobile_client_secret,
            "audience": auth0.api_server_aud,
            "username": number,
            "otp": verificationCode,
            "realm": "sms",
            "scope": "openid offline_access"
          })
        });

        var response = await (resp.getJson());
        var id_token = response.id_token;
        var access_token = response.access_token;
        var refresh_token = response.refresh_token;
        var error = response.error;

        if (id_token) {
          // TODO: Remove this after switching to 'redux-persist' Save new token on asyncStorage manual
          await (platformActions.storage.setItem('@auth_details:auth0_data', JSON.stringify({ userId, number, id_token, access_token, refreshToken:refresh_token })));
          var success = true;
          dispatch({ type: SMS_VERIFICATION_VERIFIED, payload: { userId, number, id_token, access_token, refreshToken:refresh_token }});
        }
        else if (error) {
          dispatch({ type: SMS_VERIFICATION_VERIFIED_ERROR, payload: { userId, number, verificationCode, error: error.error_description }});
          console.log("validateVerificationCode error: " + error)
          console.log(error)
        }
      }
      catch (err) {
          console.log("validateVerificationCode error: " + err)
          throw new ExtraError('auth0: validateVerificationCode', {number, userId, verificationCode}, err)
      }

      return { number, userId, success }
    }

    return {
      type: SMS_VERIFICATION_SENT,
      payload: getPromise()
    };
  };
}

const MINIMUM_MINUTES_BEFORE_RENEW_TOKEN = 30;
let tokensExpiryTS = {};
const checkTokenExpiration = (token) => {
  if (!tokensExpiryTS[token]) {
    let decodedToken = jwtDecode(token);
    let currTokensExpiryTS = Number(_.get(decodedToken, ['exp']));
    currTokensExpiryTS = currTokensExpiryTS * 1000;
    tokensExpiryTS[token] = currTokensExpiryTS;
  }
  let minutesToExpiration = (tokensExpiryTS[token] - Date.now()) / 60;
  return Boolean(minutesToExpiration < MINIMUM_MINUTES_BEFORE_RENEW_TOKEN); // If time to expiration is less then 30 minutes
};

export function isTokenExpired(token) { return checkTokenExpiration(token); };

let isServerCalledForRenewToken = {};
const _getValidAuth0Token = async (inId_token, getState, auth0, platformActions) => {
  let auth0_data = null;
  let refreshToken = getState().auth.refreshToken;
  let id_token = inId_token || getState().auth.authToken;
  let access_token = getState().auth.accessToken;
  platformActions.sentry.getSentry().addBreadcrumb({ access_token, id_token });
  const deviceId = platformActions.app.getUniqueID();
  const userPhoneNumber = getState().auth.number;
  const platform = platformActions.app.getPlatform();
  const isJWTAccessToken = _.includes(access_token, '.');

  const isIdTokenExpired = id_token && checkTokenExpiration(id_token);
  const isAccessTokenExpired = isJWTAccessToken ? (access_token && checkTokenExpiration(access_token)) : true;

  if (id_token && access_token && !isIdTokenExpired && !isAccessTokenExpired)
    return { id_token, access_token };

  try {
    let response = {};
    if (isJWTAccessToken) {
      let fetchRequest = await platformActions.net.fetch(auth0.url + '/oauth/token', {
        method: 'POST',
        body: JSON.stringify({
          "grant_type": "refresh_token",
          "client_id": platform == 'web' ? auth0.web_client_id : auth0.mobile_client_id,
          "client_secret": platform == 'web' ? auth0.web_client_secret : auth0.mobile_client_secret,
          "refresh_token": refreshToken,
        })
      });

      response = await (fetchRequest.getJson());
      if (!response)
        throw new ExtraError('auth0: no response');
    }
    else {
      const isServerCalledAlreadyForThisNumber = _.get(isServerCalledForRenewToken, userPhoneNumber);
      if (!isServerCalledAlreadyForThisNumber) {
        _.set(isServerCalledForRenewToken, userPhoneNumber, true);
        try {
          response = await getNewAccessTokenFromServer(id_token, userPhoneNumber, platform);
        } catch (error) {
          throw error;
        } finally {
          _.set(isServerCalledForRenewToken, userPhoneNumber, false);
        }
      }
    }

    if (!response || response.error) {
      throw new ExtraError('auth0: refreshToken error', { id_token, refreshToken, deviceId, error: response.error });
    }

    id_token = response.id_token;
    access_token = response.access_token;
    refreshToken = response.refresh_token || refreshToken;

    if (id_token) {
      // TODO: Remove this after switching to 'redux-persist' Save new token on asyncStorage manual 
      let stringAuth0_data = await (platformActions.storage.getItem('@auth_details:auth0_data'));
      auth0_data = (stringAuth0_data && stringAuth0_data != 'null') ? JSON.parse(stringAuth0_data) : {};
      // { id_token, refresh } -> Always from resopnse // TODO: Find out if the delegation can ever change the referesh token
      auth0_data.id_token = id_token;
      auth0_data.refreshToken = refreshToken;
      auth0_data.access_token = access_token;
      auth0_data.userId = getState().auth.userId;
      auth0_data.number = userPhoneNumber;

      await (platformActions.storage.setItem('@auth_details:auth0_data', JSON.stringify(auth0_data)));
      return { id_token, access_token, refreshToken, userId: auth0_data.userId, number: auth0_data.number, immediateGlobalStorageSave: true };
    }
  } catch (err) {
    throw new ExtraError('auth0: getValidAuth0Token error:', { id_token, refreshToken }, err);
  }
};


const promiseTimeout = 10 * 1000;
const promiseTimeoutMessage = "timeout";

let TokenRequestsPromisesHandler = {

  pendingPromises: {},
  isInProgress: false,

  addPromise: function (resolver, rejector) {
    const key = uuidv4();
    _.set(this.pendingPromises, [key], { ts: Date.now(), resolver, rejector });
    return key;
  },

  removePromise: function (key) {
    _.unset(this.pendingPromises, [key]);
  },

  resolveAll: function (result) {
    _.forIn(this.pendingPromises, (promise, key) => {
      promise.resolver(result);
      this.removePromise(key);
    });

  },

  rejectAll: function (error) {
    _.forIn(this.pendingPromises, (promise, key) => {
      promise.rejector(error);
      this.removePromise(key);
    });
  },

  getToken: async function (inId_token, getState, auth0, platformActions) {
    this.findAndClearStuckPromises();

    this.isInProgress = true;
    let authResponse;
    try {
      authResponse = await _getValidAuth0Token(inId_token, getState, auth0, platformActions);
      this.resolveAll(authResponse);

    } catch (error) {
      platformActions.sentry.notify('getValidAuth0Token failed!', { error });
      this.rejectAll(error);
    }
    finally {
      this.isInProgress = false;
      return authResponse;
    }
  },

  findAndClearStuckPromises: function () {
    let now = Date.now();
    _.forIn(this.pendingPromises, (promise, key) => {
      if (now - promise.ts >= promiseTimeout) {
        promise.rejector(promiseTimeoutMessage);
        _.unset(this.pendingPromises, [key]);
      }
    });
  }
};

export function getValidAuth0Token(inId_token) {
  return ({ getState, auth0, platformActions }) => {
    const getPromise = () => new Promise((resolver, rejector) => {
      TokenRequestsPromisesHandler.addPromise(resolver, rejector);
      if (!TokenRequestsPromisesHandler.isInProgress)
        TokenRequestsPromisesHandler.getToken(inId_token, getState, auth0, platformActions);
    });
    return {
      type: GET_AUTH0_TOKEN,
      payload: getPromise()
    };
  };
}

// TODO: Remove this after switching to 'redux-persist' Save new token on asyncStorage manual 
export function loadAuth0UserDataFromAsyncStorage() {  
  return ({ platformActions }) => {
    const getPromise = async () => {
      var auth0_data_string = null;
      var auth0_data = null;
      try {
        auth0_data_string = await (platformActions.storage.getItem('@auth_details:auth0_data'))
        //console.log('auth0_data_string', auth0_data_string)
        if (auth0_data_string && auth0_data_string != 'null')
          auth0_data = JSON.parse(auth0_data_string);
      }
      catch (err) {
        throw new ExtraError('auth0: loadAuth0UserDataFromAsyncStorage error: no data found', null, err)
      }

      // if (!auth0_data)
      //   throw new ExtraError('auth0: loadAuth0UserDataFromAsyncStorage error: no data found')
        
      return auth0_data;
    }

    return {
      type: LOAD_AUTH0_USER_DATA,
      payload: getPromise()
    };
  };
}

export function signOut(silent) {
  return ({firebaseAuth, platformActions, dispatch }) => {
    const getPromise = async () => {
      try {
        await platformActions.storage.clear();
      } catch (e) {
        throw new ExtraError('SIGN_OUT: Storage cache clear failed', null, e)
      }
      
      try {
        await clearALLData(platformActions, false);
        dispatch(cleanDynamicCachedData(true));
        await firebaseAuth().signOut();
      }catch (e) {
        throw new ExtraError('SIGN_OUT: Sign Out Failed', null, e)
      }

      let deviceLang = platformActions.app.getLang();
      dispatch(setLang(deviceLang, false));

      return { silent }
    };

    return {
      type: SIGN_OUT,
      payload: getPromise()
    };
  };
}

export function replaceUser(newUserNumber, removeOriginalUser) {  
  return ({ getState, apiServer, platformActions }) => {
    const getPromise = async () => {

      if (removeOriginalUser)
        return { originViewer: getState().users.originViewer };

      // Get relevant user
      let resp = await platformActions.net.fetch(`${apiServer}/v1/users?includeGroups=true&phonesList=${encodeURIComponent('["' + newUserNumber + '"]')}`);

      let matchNumbersResponse = await (resp.getJson());

      if (!matchNumbersResponse)
        return {error: 'No response from server'}
      if (!Object.keys(matchNumbersResponse) || Object.keys(matchNumbersResponse).length == 0)
        return {error: 'No user found by phone number'}

      let fakeUser = Object.values(matchNumbersResponse)[0];
      let originViewer = getState().users.viewer;

      return { fakeUser, originViewer };
    }

    return {
      type: REPLACE_USER,
      payload: getPromise()
    };
  };
}

export function resendSMS() {
  return {
    type: RESEND_SMS,
    payload: { }
  };
}

export function startSMSListenerAction() {
  return {
    type: START_SMS_LISTENER,
    payload: { }
  };
}

