import React, { useState } from "react";
import filterIcon from "../../../assets/img/icons/filter-icon.png";
import filterIconActive from "../../../assets/img/icons/filter-icon-brand-primary.png";
import funnelIcon from "../../../assets/img/icons/funnel-icon.png";
import theme from "../../../assets/css/theme";
import Text from "../Text";
import postsMenuMessages from "../../../../common/posts/postsMenuMessages";
import { injectIntl } from "react-intl";
import NumberBadge from "../NumberBadge";
import { compose, hoistStatics } from "recompose";
import { connect } from "react-redux";
import { LeakRemoveTwoTone } from "@material-ui/icons";

let FilterMenuButton = ({
  numberOfFilters,
  onClick,
  disabled,
  intl,
  style = {},
  rtl,
}) => {
  // const [isHover, setIsHover] = useState(false);

  const handleClick = () => {
    if (onClick && !disabled) onClick();
  };

  const isActive = false;
  const badgeSize = 17;
  return (
    <>
      <div
        onClick={handleClick}
        style={{
          ...styles.mainContainer,
          ...(disabled ? styles.mainContainerDisabled : {}),
          ...style,
        }}
        // onMouseEnter={() => setIsHover(true)}
        // onMouseLeave={() => setIsHover(false)}s
      >
        <Text
          style={{
            ...styles.iconText,
            ...(isActive ? { color: theme.brandPrimary } : {}),
            ...(disabled ? styles.disabled : {}),
          }}
        >
          {postsMenuMessages.filters}
        </Text>
        <img
          style={{ ...styles.icon }}
          src={isActive ? filterIconActive : funnelIcon}
        />
        {Boolean(numberOfFilters) && (
          <NumberBadge
            data={numberOfFilters}
            size={badgeSize}
            leftOrRightOffset={-26}
            topOffset={-10}
            isActive={true}
          />
        )}
        {/* {Boolean(numberOfFilters) && 
        <div style={{ ...filterMenuButtonStyles.filterBadge }}>
          {numberOfFilters}
        </div>
      } */}
      </div>
    </>
  );
};

const styles = {
  mainContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: `${theme.padding - 3}px ${theme.padding * 2 - 3}px ${
      theme.padding - 3
    }px ${theme.padding * 2}px`,
    border: "1.5px solid #dee0e5",
    borderRadius: 4,
    transform: "scale(0.82)",
  },
  mainContainerDisabled: {
    cursor: "not-allowed",
    opacity: 0.4,
  },
  icon: {
    height: 15,
    margin: theme.verticalMargin,
  },

  iconText: {
    fontSize: theme.fontSizeH6,
  },
  filterBadge: {},

  disabled: {
    color: "#bdbab9", // to match other image icon color
  },
};
FilterMenuButton = injectIntl(FilterMenuButton);
const enhance = compose(connect((state) => ({ rtl: state.app.rtl }), {}));

export default enhance(FilterMenuButton);
