import issuesMessages from '../../../common/issues/issuesMessages';
import { generateNewChecklistId } from '../../../common/checklists/funcs';
import { envParams } from '../../../common/configureMiddleware';
import { platformActions } from '../../../common/platformActions';


export function convertLocationTitlesToIds(locations, type, allUnits, allFloors, allBuildings, intl, onlyFromBuildingsMap, onlyFromFloorsMap, containsMode) {
  let flatRef = {};
  let wantedLocations = {};
  
  if (!locations || locations == '' || locations == 'None')
    return null;

  locations = String(locations)

  let buildingsIdToOrder = {};
  Object.values((allBuildings.toJS ? allBuildings.toJS() : allBuildings) || {})
    .sort((a,b) => a.id.localeCompare(b.id))
    .forEach((b,i) => buildingsIdToOrder[b.id] = b.ordinalNo != null ? b.ordinalNo : i);

  let sources = { buildings: allBuildings, units: allUnits, floors: allFloors }

  let onlyFromFloorNums = null;
  if (onlyFromFloorsMap) {
    onlyFromFloorNums = {}
    onlyFromFloorsMap.loopEach((k,floor) => onlyFromFloorNums[floor.num] = true);
  }

  if (type == 'buildings')       
    flatRef = sources[type];

  else if (type == 'units' || type == 'floors') {
    let source = sources[type];
    source.loopEach(bid => { 
      if (onlyFromBuildingsMap && !onlyFromBuildingsMap[bid]) 
        return;
        
      source.getNested([bid],{}).loopEach(id => { 
        let curr = source.getNested([bid, id]); 
        if (onlyFromFloorNums && curr.floor && !onlyFromFloorNums[curr.floor.num])
          return;
        curr = curr.toJS ? curr.toJS() : curr;  
        curr.buildingTitle = allBuildings.getNested([bid, 'title']) || buildingsIdToOrder[bid];  
        flatRef[id] = curr; 
      })
    });
  }

  if (locations.toLowerCase() == 'all')
    return (flatRef && flatRef.toJS) ? flatRef.toJS() : flatRef;

  let flatArr = Object.values((flatRef.toJS ? flatRef.toJS() : flatRef) || {}).sort((a,b) => 
    a.ordinalNo != null && a.ordinalNo != null
      ? (a.ordinalNo - b.ordinalNo)
      : a.num != null && a.num != null
        ? (a.num - b.num) 
        : (a.id.localeCompare(b.id)));

  locations.split(',').forEach(curr => {
    let arr = curr.split('@');
    let indication = arr.length > 1 ? arr[0] : null;
    let title = arr.length > 1 ? arr[1] : arr[0];

    title = title.trim().replace(/\"/g,"''");
    flatArr.forEach((location, index) => {
      let formatedFloorNo = (location.num != undefined) ? intl.formatMessage(issuesMessages.floorNumber, {floorNumber: location.num }) : null;
      if (!location) 
        return;
      let currLocationTitle = String(location.title).trim().replace(/\"/g,"''");
      if ((!indication || (indication && location.buildingTitle == indication)) &&
          ((currLocationTitle.indexOf(String(title)) != -1 && containsMode) ||
           String(title) == currLocationTitle || 
           String(title) == String(location.description).trim() || 
           (formatedFloorNo && (String(title) == String(location.num))) || 
           (formatedFloorNo && (String(title) == String(formatedFloorNo))) || 
          (type == 'buildings') && String(title) == String(`building ${index + 1}`)))
        wantedLocations[location.id] = location;
    })
  })

  return wantedLocations;
}

export function convertCSVtoChecklistObjectsArray(csv, projectBuildings, projectFloors, projectUnits, selectedProjectId, getNewChecklistIdAction, getNewChecklistItemIdAction, ordinalNoOffset, intl, containsMode, originalSections) {
  let selectedProjectChecklists = [];
  let childrenChecklistIds = {};
  
  originalSections.forEach(section => {
    section.childs.forEach(child => {
      if (Object.keys(child.checklistIds).length > 1)
        childrenChecklistIds[child.id] = child.checklistIds;
    })
  });

  let lines = csv;
  let index = 0;
  let itemIndex = 0;
  let prevChecklistId = null;
  let prevCompanyIds = null;
  if (lines.length > 0) lines.forEach((currLine, lineIndex) => {

    let splitCurrLine = currLine;
    if (splitCurrLine.length != 17 || lineIndex === 0) {
      console.log("ROW DISCARDED!!!");
      return null;
    }

    let buildingsTXT = splitCurrLine[5];
    let floorsTXT = splitCurrLine[6];
    let unitsTXT = splitCurrLine[7];
    let wantedBuildings = convertLocationTitlesToIds(buildingsTXT, 'buildings', projectUnits, projectFloors, projectBuildings, intl, null, null, containsMode);
    let wantedFloors = convertLocationTitlesToIds(floorsTXT, 'floors', projectUnits, projectFloors, projectBuildings, intl, wantedBuildings, null, containsMode);
    let wantedUnits = convertLocationTitlesToIds(unitsTXT, 'units', projectUnits, projectFloors, projectBuildings, intl, wantedBuildings, wantedFloors, containsMode);

    if (floorsTXT || unitsTXT) wantedBuildings = null;
    if (unitsTXT) wantedFloors = null;

    [wantedBuildings, wantedFloors, wantedUnits].forEach(wanted => { if (wanted) wanted.loopEach(k => wanted[k] = { id: wanted[k].id }); });
    let obj = {
      index,
      itemIndex,
      id: splitCurrLine[0].trim(),
      isDeleted: Boolean(splitCurrLine[1].trim()),
      stage: splitCurrLine[2].trim(),
      tradeId: splitCurrLine[3],
      description: splitCurrLine[4].trim(),
      isRoutine: Boolean(splitCurrLine[12].trim()),
      extraDescription: splitCurrLine[14].trim(), 
      requirements: splitCurrLine[8].trim(),
      weight: splitCurrLine[9],
      partial: Boolean(splitCurrLine[10].trim()),
      irrelevant: Boolean(splitCurrLine[11].trim()),
      companyIds: splitCurrLine[13].trim(),
      locations: {
        units: wantedUnits,
        floors: wantedFloors,
        buildings: wantedBuildings,
      },
      enableDistributionList: Boolean(splitCurrLine[15].trim()),
      duplicatable: Boolean(splitCurrLine[16].trim())
    };
    if (!obj.isDeleted)
      obj.isDeleted = null;
    if (obj.tradeId == -1) {
      itemIndex = 0;
      let newId = obj.id || generateNewChecklistId(selectedProjectId);//`${checklistRepresentalId}${lineIndex}` //getNewChecklistIdAction(selectedProjectId).payload.id
      // Checklist
      let newChecklist = {
        id: newId,
        isDeleted: obj.isDeleted,
        stage: obj.stage,
        type: obj.isRoutine ? 'routine' : null,
        ordinalNo: (ordinalNoOffset + index),
        description: obj.description,
        locations: obj.locations,
        enableDistributionList: obj.enableDistributionList,
        duplicatable: obj.duplicatable
      };
      prevChecklistId = newId;
      prevCompanyIds = obj.companyIds ? obj.companyIds : null;
      selectedProjectChecklists.push({
        parent: newChecklist,
        childs: []
      });
    }
    else {
      let newId = obj.id || generateNewChecklistId(selectedProjectId); //getNewChecklistItemIdAction(selectedProjectId).payload.id
      let parent = {};
      parent[prevChecklistId] = { id: prevChecklistId };
      let permissions = { actions: {}, read: { companies: {} } };
      let requirements = {};
      let allowedRequirements = { img: 'img', desc: 'desc', drawing: 'drawing', file: 'file', signature: 'signature' };
      if (obj.requirements && obj.requirements.trim()) obj.requirements.trim().split(',').forEach(k => allowedRequirements[k] ? (requirements[k] = true) : null);
      if (obj.partial) permissions.actions.partial = true;
      if (obj.irrelevant) permissions.actions.irrelevant = true;
      if (prevCompanyIds) prevCompanyIds.split(',').forEach(id => permissions.read.companies[id] = id);
      // ChecklistItem
      let newChecklistItem = {
        id: newId,
        isDeleted: obj.isDeleted,
        ordinalNo: itemIndex,
        period: obj.isRoutine ? 100 : null,
        weight: obj.weight && obj.weight != "" && !isNaN(obj.weight) && Number(obj.weight) ? Number(obj.weight) : null,
        prefix: String(itemIndex),
        description: obj.description,
        extraDescription: obj.extraDescription,
        trade: String(obj.tradeId),
        requirements: Object.keys(requirements) ? requirements : null,
        permissions: permissions,
        checklistIds: Object.assign(childrenChecklistIds.getNested([newId], {}), parent)
      };

      if (obj.locations.units || obj.locations.floors || obj.locations.buildings)
          newChecklistItem.onlyLocations = obj.locations;

      selectedProjectChecklists[selectedProjectChecklists.length - 1].childs.push(newChecklistItem);
    }

    index++;
    itemIndex++;
  });

  return selectedProjectChecklists;
}