import { getBase64StringInfo } from '../app/funcs';
import { threadSleep } from '../lib/utils/utils';
import ExtraError, { errorCodes } from '../lib/errors/extraError';
import { getAppState, firebaseDeps } from '../configureMiddleware';
import { platformActions } from '../platformActions';
import crypto from 'crypto-js';

import _ from 'lodash';
import { encodeBase64, onError } from '../app/funcs';
import { shouldUseBase64 } from '../app/constants';
import { imageWriteFinishValidator } from './funcs';
export const UPLOAD_CONTACT_AVATAR  = 'UPLOAD_CONTACT_AVATAR';

export const MAX_IMAGE_HEIGHT = 900;
export const MAX_IMAGE_WIDTH = 10000;
export const COMPRESSION = 90;

export const PREFETCH_IMAGE = 'PREFETCH_IMAGE';
export const ROTATE_IMAGE = 'ROTATE_IMAGE';
export const FILE_MISSING = 'FILE_MISSING';

export const uploadFirebaseStatus = {
	RUNNING: 'running',
	PAUSED: 'paused'
}

export function uploadContactAvatar(localImageFile, targetFileName, serverFolder, callback) {
  return ({ dispatch, platformActions }) => {
  	const getPromise = async () => {
			try {
				const uploadBase64String = async (base64) => {
					// create a path for the file
					var path = platformActions.fs.getCacheDirectoryPath() + '/tempAvatar_' + Date.now() + '.png';
					await platformActions.fs.writeFile(path, base64, 'base64')
					uploadImage({ uri : path }, targetFileName, serverFolder, callback);
				}

				if (localImageFile.uri && localImageFile.uri.startsWith('data:image/png;base64,')){
					await uploadBase64String(localImageFile.uri.replace('data:image/png;base64,', ''));
				}
				else
					platformActions.fs.getBase64String(localImageFile.uri, async (err, base64) => {
						await uploadBase64String(base64)
					})
      } catch (error) {
				console.warn("action uploadContactAvatar error: " + error)
				throw new ExtraError('uploadContactAvatar', {localImageFile, targetFileName, serverFolder}, error)
		  }
		}
		return {
      type: UPLOAD_CONTACT_AVATAR,
      payload: getPromise()
    };
  };
}

var uploadImageTasks = {};
export async function uploadImage(img, targetFileName, serverFolder, callback, imageId, statusCb) {
	if (getAppState && getAppState() && !getAppState().getNested(['app', 'isConnected'], false))
		throw new ExtraError('uploadImage - no reception');
	let ret = null;
	let downloadURL = null;
	let shortPath = null;
	const platform = platformActions.app.getPlatform();
	let fileUriString = '';
	if (img.extension && img.data && typeof img.data === 'string')
		fileUriString = img.data;
	else if (img.uri && typeof img.uri === 'string')
		fileUriString = img.uri;
	else if (img && typeof img === 'string')
		fileUriString = img;

	try { 
		if (!fileUriString.includes(';base64,')) {
			shortPath = fileUriString.replace('file:/', '');

			// Check if image file still availble
			try {
				let exist = await platformActions.fs.exists(shortPath)
				if (!exist) 
					throw('file is not exist')
			}
			catch (err) {
				console.log(err);
				throw new ExtraError('image upload file missing', { shortPath }, err, errorCodes.MISSING_FILE);
			}
		}
		// WORKAROUND - StorageUploadTimeout didnt triggerd TIMEOUT_EXCEPTION!!!
		let uploadPromise = function(imgObj, targetFileName, serverFolder, shortPath, statusCb) {
			return new Promise(async function(resolve, reject) {
				// WORKAROUND - StorageUploadTimeout didnt triggerd TIMEOUT_EXCEPTION!!!
				let uploadTask = null; 

				setTimeout(() => {
					if (uploadTask && uploadTask.task && uploadTask.task.cancel) // For future version of react-native-firebase, they would probably add that since it exsist in the native version
						uploadTask.task.cancel();
					delete uploadImageTasks[targetFileName];
					resolve(null);
				}, 1 * 60 * 1000);

				let contentType = imgObj.type || (imgObj.extension == "pdf" ? 'application/pdf' : 'image/jpeg');
				// Create the file metadata
				let metadata = { contentType };
				let fileName = imgObj.name || "";
				if (Array.isArray(fileName) && fileName.length)
					fileName = fileName[0];

				let customMetadata = { fileName };

				const base64Info = shouldUseBase64() && getBase64StringInfo(imgObj.uri);
				
				try {
					if (base64Info) { // Base64 string
						if (base64Info.type && base64Info.type !== contentType)
							contentType = base64Info.type;

						const fileExtension = `${base64Info.extension ? '.' + base64Info.extension : ''}`;
						const fullFileName = `${targetFileName}${fileExtension}`;
						if (platform === 'web') {
							uploadTask = firebaseDeps.firebaseStorage()
								.ref(serverFolder)
								.child(fullFileName)
								.putString(base64Info.uri, 'base64', { customMetadata, contentDisposition: 'inline', contentType });

							uploadTask.on('state_changed', (snapshot) => {
									// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
									const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
									statusCb?.({
										status: snapshot.state,
										progress: progress,
									});
								},
								(error) => {
									// A full list of error codes is available at
									// https://firebase.google.com/docs/storage/web/handle-errors
									let message = 'Failed getting download url';
									switch (error.code) {
										case 'storage/unauthorized':
											message = 'User doesn\'t have permission to access the object';
											break;
										case 'storage/canceled':
											message = 'User canceled the upload';
											break;
										case 'storage/unknown':
											// Unknown error occurred, inspect error.serverResponse
											break;
									}
									onError({
										errorMessage: message,
										methodMetaData: {
											args: { targetFileName, serverFolder, shortPath, imageId },
											name: 'uploadImage'
										},
										errorMetaData: {
											b64s: base64Info?.dataString?.substring?.(0, 100),
										},
									});
								}
							);
							await uploadTask;
							const url = await uploadTask.snapshot.ref.getDownloadURL();
							resolve(url);
							return;
						}
						else {
							try { 
								uploadTask = await firebaseDeps
									.firebaseStorage()
									.ref(serverFolder)
									.child(fullFileName)
									.putString(base64Info.dataString, 'data_url');
							} catch (e) { 
								console.warn('Error uploadImage b64s', e);
								reject(e);
								onError({ 
									errorMessage: 'Error uploadImage b64s', 
									methodMetaData: { 
										args: { targetFileName, serverFolder, imgPath: imgObj.uri.startsWith('file:/') && imgObj.uri, imageId }, 
										name: 'uploadImage' 
									}, 
									errorMetaData: { 
										b64s: (typeof base64Info.dataString === 'string' ? base64Info.dataString.substring(0, 100) : base64Info.dataString), 
										filePath, 
										isFileExist: await platformActions.fs.exists(filePath) 
									}, 
									error: e 
								});
							}
						}
					}
					else { // File object upload
						uploadTask = platformActions.app.isWeb()
							? await firebaseDeps
								.firebaseStorage()
								.ref(serverFolder)
								.child(targetFileName + '_' + imgObj.name)
								.put(imgObj, { customMetadata, contentDisposition: 'inline', contentType  })
							: await firebaseDeps
								.firebaseStorage()
								.ref(serverFolder)
								.child(targetFileName)
								.putFile(shortPath, metadata);
					}
				}
				catch (e) {
					console.warn("Error firebaseStorage.upload!!", e)
					reject(e);
				}

				let url = (uploadTask || {}).downloadURL;

				if (!url && uploadTask && uploadTask.metadata.fullPath)
					url = await firebaseDeps.firebaseStorage().ref(uploadTask.metadata.fullPath).getDownloadURL();

				if (!url) {
					onError({
						errorMessage: 'Failed getting download url',
						methodMetaData: {
							args: { targetFileName, serverFolder, shortPath, imageId },
							name: 'uploadImage'
						},
						errorMetaData: {
							b64s: base64Info?.dataString?.substring?.(0, 100),
						},
					});
					reject(new ExtraError('Failed getting download url', { firebaseStoragePath: uploadTask?.metadata?.fullPath }));
				}

				resolve(url);
			});
		}
		// Make sure we are not updating the same file twice
		if (fileUriString.startsWith('https://'))
			downloadURL = fileUriString;
		else {
			if (!uploadImageTasks[targetFileName])
				uploadImageTasks[targetFileName] = uploadPromise(
					{ ..._.pick(img, ['name', 'extension', 'type']), uri: fileUriString },
					targetFileName, 
					serverFolder,
					shortPath, 
					statusCb,
				);

			downloadURL = await uploadImageTasks[targetFileName];
		}

		if (callback)
			callback(downloadURL);

		delete uploadImageTasks[targetFileName];
		ret = imageId ? { imageId, uri: downloadURL } : downloadURL;
	}
	catch (error) {
		console.warn('uploadImage', error, img);
		throw new ExtraError('uploadImage', { localImageFile: img, targetFileName, serverFolder }, error, _.get(error, ['errorCode']));
	}
	finally {
		try {
			if (platform != "web" && (downloadURL && shortPath)) {
				setTimeout(() => {
					platformActions.fs.deleteFile((platform == "ios" ? "" : "file://") + shortPath);
				}, 1000 * 10); // Give time for deletion in case of 2 retries of same posts at same time, so that we won't have a missing file event
				
			}
		}
		catch (error) {
			console.warn('uploadImage - failed to remove local image', error);
			throw new ExtraError('uploadImage - failed to remove local image', { localImagePath: shortPath }, error);
		}
	}

	return ret;
}

