import * as actions from './actions';
import * as lastUpdatesActions from '../lastUpdates/actions'; 
import { REPLACE_USER } from '../auth/actions';

import Floor from './floor';

import { OrderedMap, Map } from 'immutable';
import { Record } from '../transit';
import { DEFAULT_LAST_UPDATE_TS } from '../app/constants';

const InitialState = Record({
  map: OrderedMap(),
  didLoad: OrderedMap(),
  lastUpdated: Map(),
  lastUpdateAvailable: Map(),  
}, 'floors');

const initialState = new InitialState;

export default function floorReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;
 
  switch (action.type) {

    case actions.GET_LOCAL_FLOORS + "_SUCCESS":
    case actions.GET_FLOORS + "_SUCCESS": {
      if (!action.payload?.projectId)
        return state;

      const { projectId, floors } = action.payload;
      if (floors) {
        let floorsMap = new OrderedMap();
        Object.entries(floors).forEach(([buildingId, buildingFloors = {}]) => {
          let buildingFloorsMap = new OrderedMap();
  
          Object.values(buildingFloors).forEach(floor => {
            floor = new Floor({ ...(floor.toJS ? floor.toJS() : floor) });
            buildingFloorsMap = buildingFloorsMap.set(floor.id, floor);
          });
  
          buildingFloorsMap = buildingFloorsMap.sortBy(floor => (floor.num * -1));
          floorsMap = floorsMap.set(buildingId, buildingFloorsMap);
        });
        
        state = state.setIn(['map', projectId], floorsMap);
      }
      
      state = state.setIn(['didLoad', projectId], true);
      state = state.setIn(['lastUpdated', projectId], state.lastUpdateAvailable.get(projectId, DEFAULT_LAST_UPDATE_TS))

      return state;
    }

    case lastUpdatesActions.GET_LAST_UPDATES: {
      if (!action.payload || !action.payload.projects)
        return state;

      const { projects } = action.payload;

      Object.keys(projects).forEach(projectId => {
        let TS = projects.getNested([projectId,'floors', 'lastUpdateTS'], DEFAULT_LAST_UPDATE_TS);
        let curr = state.getNested(['lastUpdateAvailable', projectId], null)
        if (curr < TS) state = state.setIn(['lastUpdateAvailable', projectId], TS);
      });

      return state;
    }

    case actions.CLEAR_LOCAL_FLOORS: {
      const { projectId } = action.payload;
      if (!projectId)
        return state;

      return state.deleteIn(['map', projectId]);
    }


    case actions.END_FLOORS_LISTENER: {
      if (!action.payload)
        return state;
      const { projectId } = action.payload;
      if (!projectId)
        return state;

      state = state.setIn(['didLoaded', projectId ], false);
      return state.deleteIn(['map', projectId ]);
    }

    case actions.UPDATE_LOCAL_FLOOR + "_SUCCESS":
    case actions.UPDATE_FLOOR + "_SUCCESS": {
      const { projectId, buildingId, floor } = action.payload;
      
      if (!projectId || !buildingId || !floor)
        return state;
      
      return state.setIn(['map', projectId, buildingId, floor.id], new Floor({...floor, isLocal: true}));
    }
    
    case actions.UPDATE_LOCAL_FLOORS + "_SUCCESS":
    case actions.UPDATE_FLOORS + "_SUCCESS": {
      if (!action.payload)
        return state;
      const { projectId, buildingId, floors } = action.payload;
      
      if (!projectId || !buildingId || !floors)
      return state;
      
      Object.values(floors).forEach((i, floor) => {
        floor = floor.toJS ? floor.toJS() : floor;
        state = state.setIn(['map', projectId, buildingId, floor.id], new Floor({...floor, isLocal: true}));
      });
      
      return state;
    }

    case REPLACE_USER + "_SUCCESS": {
      return initialState;
    }
  }

  return state;
}