import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import {
  ProjectContext,
  ProjectStructureContext,
} from "../../../common/projects/contexts";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import unitsMessages from "../../../common/units/unitsMessages";
import issuesMessages from "../../../common/issues/issuesMessages";
// core components
import { FloatingSticky } from "../../components";
import Unit from "./Unit.js";
// styles
import "./floor.css";
import FloorBlackPNG from "../../assets/img/locations/floor_black.png";
import FloorSelectedPNG from "../../assets/img/locations/floor_selected.png";
import theme from "../../assets/css/theme";
import { BuildingContext } from "../../../common/buildings/contexts";
import CrossListSortable from "../../components/CementoComponents/CrossListSortable";

let floorWidth = 22;
let floorHight = 18;

class Floor extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.onLocationClick = this.onLocationClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.handleMoveUnitsArround = this.handleMoveUnitsArround.bind(this);
    this.state = {
      floor: null,
      floorTitle: "",
      sortedUnits: [],
      isActive: false,
      ordinalSortedUnitsToMap: {},
    };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    const {
      floors,
      units,
      selectedProjectId,
      buildingId,
      floorId,
      isActive,
      selectedLocation,
      checklistsStatusMap,
    } = props;
    let newStateChanges = {};

    if (
      (floors || {}).getNested([buildingId]) !=
      nextProps.floors.getNested([nextProps.buildingId])
    ) {
      let floor = nextProps.floors.getNested(
        [nextProps.buildingId, nextProps.floorId],
        {}
      );
      let floorTitle = floor.description;
      if (floor.type == "roof" && !floorTitle)
        floorTitle = nextProps.intl.formatMessage(unitsMessages.roof);
      if (!floorTitle)
        floorTitle = nextProps.intl.formatMessage(issuesMessages.floorNumber, {
          floorNumber: floor.num,
        });
      newStateChanges.floorTitle = floorTitle;
      newStateChanges.floor = floor;
    }

    if (
      (units || {}).getNested([buildingId]) !=
      nextProps.units.getNested([nextProps.buildingId])
    ) {
      let unitsMapedByFloorNum = {};
      let selectedProjectUnits = nextProps.units.getNested(
        [nextProps.buildingId],
        {}
      );
      selectedProjectUnits = Object.values(
        selectedProjectUnits.toJS
          ? selectedProjectUnits.toJS()
          : selectedProjectUnits
      ).sort((a, b) => (a.id || "").localeCompare(b.id || ""));
      selectedProjectUnits.forEach((currUnit) => {
        if (
          currUnit &&
          currUnit.floor &&
          (currUnit.floor.num || currUnit.floor.num == 0)
        ) {
          if (!unitsMapedByFloorNum[currUnit.floor.num])
            unitsMapedByFloorNum[currUnit.floor.num] = [];

          unitsMapedByFloorNum[currUnit.floor.num].push(currUnit);
        }
      });
      Object.entries(unitsMapedByFloorNum).forEach(([floorNum, floorUnits]) => {
        unitsMapedByFloorNum[floorNum] = unitsMapedByFloorNum[floorNum].sort(
          (a, b) =>
            a.ordinalNo !== undefined && b.ordinalNo !== undefined
              ? -1 * (b.ordinalNo - a.ordinalNo)
              : 0
        );
      });
      newStateChanges.ordinalSortedUnitsToMap = unitsMapedByFloorNum;
    }

    let nextFloorData = (nextProps.checklistsStatusMap || {}).getNested([
      nextProps.floorId,
    ]);
    if (
      this.state.floorData != nextFloorData ||
      nextProps.checklistsStatusMap != checklistsStatusMap
    )
      newStateChanges.floorData = nextFloorData;

    if (
      (selectedLocation || {}).getNested(["floorId"]) !=
        nextProps.selectedLocation.getNested(["floorId"]) ||
      (selectedLocation || {}).getNested(["unitId"]) !=
        nextProps.selectedLocation.getNested(["unitId"])
    ) {
      let newIsActive = Boolean(
        nextProps.selectedLocation.getNested(["floorId"]) ==
          nextProps.floorId &&
          (nextProps.selectedLocation.getNested(["unitId"]) == "_" ||
            !nextProps.selectedLocation.getNested(["unitId"]))
      );

      if (isActive != newIsActive) newStateChanges.isActive = newIsActive;
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  onMouseEnter() {
    this.setState({ isActive: true });
  }

  onMouseLeave() {
    let { selectedLocation, floorId } = this.props;
    let isActive = Boolean(
      selectedLocation.getNested(["floorId"]) == floorId &&
        (selectedLocation.getNested(["unitId"]) == "_" ||
          !selectedLocation.getNested(["unitId"]))
    );
    this.setState({ isActive });
  }

  onLocationClick() {
    const { onClick, floorId, buildingId, unitId } = this.props;
    const { floorTitle } = this.state;
    if (onClick) onClick({ type: "floor", buildingId, floorId }, floorTitle);
  }

  handleMoveUnitsArround(newStateChunk) {
    const { setContextProjectStructure, buildingId } = this.props;
    const { floor } = this.state;

    let updatedStateChunk = newStateChunk.map((unit, index) => {
      return unit
        .setNested(["ordinalNo"], index + 1)
        .setNested(["floor", "num"], floor.num);
    });

    /* this.setState(({ ordinalSortedUnitsToMap }) => ({
      ordinalSortedUnitsToMap: ordinalSortedUnitsToMap.setNested([floor.num], updatedStateChunk)
    }), () => { */
    if (setContextProjectStructure)
      setContextProjectStructure(updatedStateChunk, buildingId, floor.num);
    // });
  }

  render() {
    const {
      buildingBadges,
      buildingId,
      checklistsStatusMap,
      floorId,
      onClick,
      classes,
      maxUnitsInFloor,
      rtl,
      showBadges,
      isEditMode,
      isSelectionMode,
      filter,
      filterLocations,
      locationsMap,
    } = this.props;
    const { floorTitle, floor, isActive, floorData, ordinalSortedUnitsToMap } =
      this.state;
    var badgeSize = 20;
    let badge = null;
    if (
      showBadges &&
      ((!checklistsStatusMap && buildingBadges && buildingBadges[floorId]) ||
        (checklistsStatusMap && floorData && floorData.issuesCounter != 0))
    )
      badge = checklistsStatusMap
        ? floorData.issuesCounter
        : buildingBadges[floorId];

    let currFloorUnits = ordinalSortedUnitsToMap[floor.num] || [];

    let recalculatedIsActive =
      isSelectionMode && !isActive
        ? locationsMap.getNested(["floors", floor.id, "selected"], false)
        : isActive;

    let filteredCurrFloorUnits = currFloorUnits;
    if (filter.type === "units") {
      filteredCurrFloorUnits = filterLocations(currFloorUnits, filter);
      if (
        Boolean(isSelectionMode || filter.string !== "") &&
        !filteredCurrFloorUnits.length
      )
        return <></>;
    } else if (filter.type === "floors" && isSelectionMode)
      filteredCurrFloorUnits = [];

    return (
      <div
        xs={12}
        key={floor.id}
        style={{
          marginLeft: 20,
          marginRight: 20,
          borderBottom: theme.borderLineNeutralLight + "75",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            paddingTop: 10,
            paddingBottom: 3,
          }}
        >
          <FloatingSticky style={{ top: "0px", zIndex: theme.zIndexes.floor }}>
            <div
              onClick={this.onLocationClick}
              className={classes.floorContainer}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
            >
              <div
                style={{
                  flex: 1,
                  fontSize: 13,
                  justifyContent: "space-between",
                  fontWeight: theme.strongBold,
                  color: recalculatedIsActive
                    ? theme.brandPrimary
                    : theme.brandNeutral,
                }}
                className={
                  classes.floorTitle +
                  (recalculatedIsActive ? " " + classes.floorTitleSelected : "")
                }
              >
                <div style={{ [rtl ? "marginLeft" : "marginRight"]: 5 }}>
                  {floorTitle}
                </div>
                {Boolean(badge) && (
                  <div
                    style={{
                      height: badgeSize,
                      width: badgeSize,
                      maxWidth: "30px",
                      maxHeight: "30px",
                      textAlign: "center",
                      backgroundColor: recalculatedIsActive
                        ? theme.brandPrimary
                        : theme.brandNeutral,
                      borderRadius: "50%",
                      zIndex: theme.zIndexes.numberBadge,
                    }}
                  >
                    <div
                      style={{
                        fontFamily: theme.defaultFont,
                        color: theme.backgroundColor,
                        fontWeight: theme.strongBold,
                        fontSize:
                          badge < 100
                            ? theme.smallFontSize + 1
                            : theme.smallFontSize,
                      }}
                    >
                      {badge}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </FloatingSticky>
          <CrossListSortable
            style={{
              display: "flex",
              flex: 1,
              flexWrap: "wrap",
              justifyContent: rtl ? "flex-end" : "flex-start",
              paddingTop: filteredCurrFloorUnits.length > 0 ? 25 : 0,
              transitionDuration: "1s",
              transitionTimingFunction: "cubic-bezier(0.215, 0.61, 0.355, 1)",
              ...(rtl ? { flexDirection: "row-reverse" } : {}),
            }}
            masterList={filteredCurrFloorUnits}
            sortableProps={{
              disabled: !isEditMode,
              dragClass: "sortable-unit-drag",
              ghostClass: "sortable-unit-ghost",
              fallbackClass: "sortable-unit-fallback",
              group: "shared",
              forceFallback: true,
              emptyInsertThreshold: 10,
            }}
            onChange={this.handleMoveUnitsArround}
          >
            {(currUnit) => {
              return (
                <div
                  key={currUnit.id}
                  style={{
                    display: "flex",
                    flex: "1 0 " + 100 / maxUnitsInFloor + "%",
                    justifyContent: "center",
                    maxWidth: 100 / maxUnitsInFloor + "%",
                  }}
                >
                  <Unit
                    showBadges={showBadges}
                    key={currUnit.id}
                    onClick={onClick ? onClick : undefined}
                    buildingId={buildingId}
                    floorId={floorId}
                    unitId={currUnit.id}
                  />
                </div>
              );
            }}
          </CrossListSortable>
        </div>
      </div>
    );
  }
}

let style = {
  floorContainer: {
    flex: 1,
    display: "flex",
    cursor: "pointer",
    backgroundColor: theme.backgroundColor,
  },
  floorTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: theme.mediumFontSize,
  },
  floorTitleSelected: {
    color: theme.brandPrimary,
    fontWeight: 500,
  },
};
Floor = withStyles(style)(Floor);
Floor = injectIntl(Floor);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connectContext(BuildingContext.Consumer),
  connectContext(ProjectStructureContext.Consumer),
  connect(
    (state) => ({
      rtl: state.app.rtl,
    }),
    {}
  )
);
export default enhance(Floor);
