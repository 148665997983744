import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import MultiSelect from "@kenshooui/react-multi-select";
import VirtualizedCheckbox from "../VirtualizedCheckbox/VirtualizedCheckbox";
import FilteredMultiSelect from "react-filtered-multiselect";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";

let UNTIL_MultiSelect_DEPLOYMENT_BUG_WILL_BE_FIXED = true;

class MultiCheckboxSelect extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};
    //////////////////////////////
    //////////////////////////////
    //////////////////////////////

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  handleSelectionChange = (changes) => {
    //this.setState({selectedItems2: selectedItems})

    if (UNTIL_MultiSelect_DEPLOYMENT_BUG_WILL_BE_FIXED) {
      let copy = this.props.selectedItems.slice() || [];
      let chengesArray = Array.isArray(changes) ? changes : [changes];
      chengesArray.forEach((currChange) => {
        if (!currChange.checked) {
          let index = 0;
          copy.forEach((v, i) => {
            if (v.id == changes.value) index = i;
          });
          copy.splice(index, 1);
        } else {
          copy.push({ id: currChange.value });
        }

        if (this.props.onChange) this.props.onChange(copy);
      });
      return true;
    }

    if (this.props.onChange) this.props.onChange(changes);
  };

  render() {
    const { items, selectedItems } = this.props;

    if (UNTIL_MultiSelect_DEPLOYMENT_BUG_WILL_BE_FIXED) {
      let selected2 = {};
      (selectedItems || []).forEach((v) => (selected2[v.id] = true));
      let items2 = [];
      (items || []).forEach((v) =>
        items2.push({ value: v.id, label: v.label, checked: selected2[v.id] })
      );

      return (
        <div style={{ height: "300px", ...(this.props.style || {}) }}>
          <VirtualizedCheckbox
            items={items2}
            hasOkButton={false}
            hasCancelButton={false}
            onChange={this.handleSelectionChange}
          />
        </div>
      );
    }

    return (
      <MultiSelect
        items={items}
        selectedItems={selectedItems}
        showSelectedItems={false}
        onChange={this.props.onChange}
      />
    );
  }
}

let styles = {};

MultiCheckboxSelect = withStyles(styles)(MultiCheckboxSelect);
const enhance = compose(connectContext(ProjectContext.Consumer));
export default enhance(MultiCheckboxSelect);
