import * as actions from './actions';
import { Record } from '../transit';

const InitialState = Record({
  appVersion: "0"
}, 'lastUpdates');

export default function lastUpdatesReducer(state = new InitialState, action) {
  switch (action.type) { 
    case actions.ON_CHECK_APP_VERSION + "_SUCCESS": {
      return state.set('appVersion', action.payload.appVersion);
    }
	}
	 
  return state;
}
