import {getSnapshotData} from '../lib/utils/utils';

export const GET_FLOORS = 'GET_FLOORS';
export const GET_LOCAL_FLOORS = 'GET_LOCAL_FLOORS';
export const END_FLOORS_LISTENER = 'END_FLOORS_LISTENER';
export const CLEAR_LOCAL_FLOORS = 'CLEAR_LOCAL_FLOORS';
export const UPDATE_FLOOR = 'UPDATE_FLOOR';
export const UPDATE_LOCAL_FLOOR = 'UPDATE_LOCAL_FLOOR';
export const UPDATE_FLOORS = 'UPDATE_FLOORS';
export const UPDATE_LOCAL_FLOORS = 'UPDATE_LOCAL_FLOORS';
export const GET_NEW_FLOOR_ID = 'GET_NEW_FLOOR_ID';

export function getFloors(projectId, forceUpdate) {  
    return {
      type: GET_FLOORS,
      payload: getSnapshotData({api:'floors'},projectId)
  };
}

export function getLocalFloors(projectId, floors) {
  return {
    type: GET_LOCAL_FLOORS + "_SUCCESS",
    payload: { projectId, floors }
  }
}

export function endFloorsListener(projectId) {  
  return ({ dispatch, firebaseDatabase }) => {
    firebaseDatabase().ref('floors/' + projectId).off('value');
    return {
      type: END_FLOORS_LISTENER,
      payload: { projectId }
    };
  };
}

export function removeLocalFloors(projectId) {
  return {
    type: CLEAR_LOCAL_FLOORS,
    payload: { projectId },
  }
}


export function updateFloor(projectId, buildingId, floor) {
  return ({ firebase, firebaseDatabase }) => {
    const getPromise = async () => {
        if (!floor.id)
          floor.id = firebaseDatabase().ref('floors/' + projectId + '/' + buildingId).push().key;

        let updates = {};
        updates['floors/' + projectId + '/' + buildingId + '/' + floor.id] = floor;
        
        await firebase.update(updates);
        return { projectId, buildingId, floor };
    };

    return {
      type: UPDATE_FLOOR,
      payload: getPromise()
    };
  };
}

export function updateLocalFloor(projectId, buildingId, floor) {
  return {
    type: UPDATE_LOCAL_FLOOR + "_SUCCESS",
    payload: { projectId, buildingId, floor },
  };
}

export function updateFloors(projectId, buildingId, floors, replaceAll) {
  return ({ firebase, firebaseDatabase }) => {
    const getPromise = async () => {
      
      if (!projectId || !buildingId || !floors)
        return false;
      
      let updates = {};

      if (replaceAll) {
        let path = 'floors/' + projectId + '/' + buildingId;
        floors.loopEach((i, floor) => {
          floor = floor.toJS ? floor.toJS() : floor;
          if (!floor.id)
            floor.id = firebaseDatabase().ref('floors/' + projectId + '/' + buildingId).push().key;
            
          updates = updates.setNested([path, floor.id], floor);
        });

      } else {
        floors.loopEach((i, floor) => {
          floor = floor.toJS ? floor.toJS() : floor;
          if (!floor.id)
            floor.id = firebaseDatabase().ref('floors/' + projectId + '/' + buildingId).push().key;
            
          updates['floors/' + projectId + '/' + buildingId + '/' + floor.id] = floor;
        });
      }


      await firebase.update(updates);
      return { projectId, buildingId, floors };
    }

    return {
      type: UPDATE_FLOORS,
      payload: getPromise()
    }
  }
}

export function updateLocalFloors(projectId, buildingId, floors) {
  return {
    type: UPDATE_LOCAL_FLOORS + "_SUCCESS",
    payload: { projectId, buildingId, floors }
  }
}

export function getNewFloorId(projectId, buildingId) {
  return ({ firebaseDatabase }) => {
    let id = firebaseDatabase().ref('floors/' + projectId + '/' + buildingId).push().key;

    return {
      type: GET_NEW_FLOOR_ID,
      payload: { floorId: id }
    }
  }
}