import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import regularFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import collapse from '../../assets/img/icons/collapse.png';
import {useSelector} from 'react-redux';
import _ from "lodash";
import Text from './Text';
import theme from '../../assets/css/theme';

const useStyles = makeStyles(() => regularFormsStyle);

const checkForAnySubLevelSelection = (items) => {
  if (!items?.length) {
    return false;
  }

  return items.reduce((acc, item) => {
    if (item.checked || acc) {
      return true;
    }
    if (item.nested?.length) {
      acc = checkForAnySubLevelSelection(item.nested);
    }

    return acc;
  }, false);
};

const CheckRow = (props) => {
  const { value, intl, onSelectById, isMultiLevel, titlePropPath } = props;
  const { checked, id, nested } = value;
  const title = useMemo(() => _.get(value, titlePropPath, ''), [titlePropPath, value]);
  const classes = useStyles();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { rtl } = useSelector(state => ({
    rtl: state.app.rtl,
  }));

  const handleSelectionChangeById = useCallback(() => {
    onSelectById?.(id, !checked);
  }, [onSelectById, id, checked]);

  const handleCollapsedClick = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  useEffect(() => {
    if (checkForAnySubLevelSelection(nested)) {
      setIsCollapsed(false);
    }
  }, [nested]);

  return (
    <div style={{ alignItems: "center" }}>
      {Boolean(nested?.length) && <img
        src={collapse}
        onClick={handleCollapsedClick}
        style={{
          transform: isCollapsed ? (rtl ? 'rotate(180deg)' : '') : 'rotate(90deg)',
          padding: 0,
          height: 10,
          width: 10,
          transition: '0.2s',
          cursor: 'pointer',
          position: 'relative',
          left: 5,
        }}
      />}
      <Checkbox
        key={id}
        style={{
          [rtl ? 'marginRight' : 'marginLeft']: nested?.length ? theme.verticalMargin : 0,
        }}
        onChange={handleSelectionChangeById}
        checked={Boolean(checked)}
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        classes={{ checked: classes.checked }}
      />
      <Text style={{display: 'inline-block'}}>
        {value.getCementoTitle?.() || title}
      </Text>
      {Boolean(nested?.length) && <div
        style={{
          [rtl ? 'paddingRight' : 'paddingLeft']: 30,
          maxHeight: isCollapsed ? 0 : 10000,
          overflow: 'hidden',
        }}
      >
        {nested.map((v, index) => (
          <CheckRow
            intl={intl}
            key={`subcheckrow-${v.id}`}
            onSelectById={onSelectById}
            index={index}
            titlePropPath={titlePropPath}
            value={v}
            isMultiLevel={isMultiLevel}
          />
        ))}
      </div>}
    </div>
  );
}

export default CheckRow;