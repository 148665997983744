import React from "react";
import * as utils from '../../../common/lib/utils/utils';
import { connect } from 'react-redux';
import { compose, hoistStatics } from 'recompose';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../../../common/projects/contexts';
import { injectIntl } from 'react-intl';
import withStyles from "@material-ui/core/styles/withStyles";
import { LibraryBooks } from "@material-ui/icons";
import checklistsMessages from '../../../common/checklistItems/checklistItemMessages';
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Card from "../../components/Card/Card.jsx";
import CardHeader from "../../components/Card/CardHeader.jsx";
import CardIcon from "../../components/Card/CardIcon.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import validationFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import InputField from "../../components/CementoComponents/InputField";
import ObjectInputField from "../../components/CementoComponents/ObjectInputField";
import theme from "../../assets/css/theme";
import { CloseRounded } from "@material-ui/icons";
import plus_primary from '../../assets/img/icons/plus_primary.png';
import MultiCheckSelect from "../../components/CementoComponents/MultiCheckSelect";
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes'
import Text from "../../components/CementoComponents/Text";
import Select from "react-select";
import { companiesGroupsOptions } from "../Projects/ProjectManager";
import { baseRemoveNested } from "../ProjectManager/ProjectManager2_0";
import _ from 'lodash';
import Creatable from 'react-select/creatable'
import { getAllAvailableTags, getBaseSelectionListId } from "../../../common/propertiesTypes/funcs";
import InjectedStandardInput from "../../components/CementoComponents/StandardInput";
import FunctionalInput from "../../../common/app/components/FunctionalInput";
import Button from '../../app/standardComponents/Button';
import PropertyRow from "./PropertyRow";
import { SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc";
import CrossListSortable from "../../components/CementoComponents/CrossListSortable";
import Dragger from "../../components/CementoComponents/Dragger";

const readPermissionGroupsOptions = baseRemoveNested(companiesGroupsOptions, [["0011"], ["0012"], ["0013"], ["0014"], ["0015"], ["0016"]]);
const AVAILABLE_INPUT_TYPES = propertyTypes.VALID_PROPERTIES_ARRAY;
const isPropertySynchronizationLevelActive = false;

let businessTypeValues = {
  SelectionList: ['professions', 'companies', 'trades', 'equipmentStatus', 'equipmentStatusBooleanOnly', 'equipmentTypes', 'excavationsTestReason', 'formworkTest',
                  'formworkType','inspectorType', 'levelDeviceTest', 'levelTowersTestReason', 'scaffoldingTestReason', 'titles', 'members'],
  Array: ['companies'],
  Number: ['serialNumber'],
}

let universalIds = {
  String: ['phoneNumber', 'employeeCompany', 'equipmentCompany', 'equipmentDetails', 'idNumber', 'fullName', 'nationality', 'name1', 'name2', 'id1', 'id2', 'phone1', 'phone2', 'mail1', 'mail2', 'address1', 'address2'],
  Picture: ['avatar', 'acquisitionImages'],
  Boolean: ['isActive'],
  SelectionList: ['equipmentType', 'employeeTrade', 'employeeCompany', 'nationality', 'equipmentCompany', 'equipmentDetails'],
  Complex: ['hightCertification', 'review', 'siteTraining', '', 'acquisitionImages', 'unitApproval', 'unitApproval_preDelivery', 'unitApproval_managerTour', 'publicAreaApproval'],
}

let fileContentTypes = {
  DOC: 'doc',
  ORDER: 'order',
  DRAWING: 'drawing'
}

const TIME_ZONES_SELSECTION_OPIONS = ['Asia/Jerusalem'];

const PROPERTIES_VIEW_TYPES = propertyTypes.PROPERTIES_VIEW_TYPES

const LANGS = propertyTypes.LANGUAGES_MAP;
const syncLevelOptions = ['cemento', 'company']; // In the future we should keep this dynamic list if the database

class PropertyCard extends React.Component {
  constructor(props) {
    super(props);
    this.defaultSelectedRowData = [];
    this.setComponentData = this.setComponentData.bind(this);
    this.selectionListValuesChanged = this.selectionListValuesChanged.bind(this)
    this.removeValuesHandler = this.removeValuesHandler.bind(this);
    this.addValuesHandler = this.addValuesHandler.bind(this);
    this.tagsSelectorOnChange = this.tagsSelectorOnChange.bind(this);
    this.state = {
      universalIdsOptions: universalIds.Complex,
      createdUniversalIds: [],
      sortedTrades: [],
      sortedProjects: [],
      multipleSelect: [],
      locationsMap: {},
      propertyTags: null,
      allTagsAvailable: null
    };
  }

  loadUniversalIdsOptions(nextProps) {
    const { propertyType, propertiesTypes, subjectName } = nextProps || this.props;
    const { createdUniversalIds } = this.state;
    const currProp = propertyType || {};
    _.values(propertiesTypes.getNested([subjectName])).forEach(prop => {
      if (prop.universalId)
        _.get(universalIds, [prop.type], []).push(String(prop.universalId).trim());
    });

    let universalIdsOptions = [currProp.universalId, ..._.values(universalIds[currProp.type]), ..._.get(universalIds, 'Complex', [])];
        universalIdsOptions = universalIdsOptions.concat(createdUniversalIds);
        universalIdsOptions = _.chain(universalIdsOptions).uniq().compact().value();
        universalIdsOptions = universalIdsOptions.map(t => ({ id: t, title: t }));

    return universalIdsOptions;
  }

  componentWillMount() {
    this.setComponentData({}, this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps)
  }

  setComponentData(props, nextProps) {
    const { allTagsAvailable, propertyTags } = this.state;
    let newStateChanges = {};
    if (!propertyTags || (props.propertyType || {}).id !== (nextProps.propertyType || {}).id) {
      newStateChanges.propertyTags = _.get(nextProps, ['propertyType', 'tags']) || {};
    }

    if (props.isValDiff(nextProps, ['configurations', 'forms'])) {
      let formIdBySignaturesId = {};
      _.values(nextProps.getNested(['configurations', 'forms'])).filter(form => form.signatures).forEach(form => {
        const currentFormSignatures = form.signatures;
        _.keys(currentFormSignatures).forEach(signatureId => formIdBySignaturesId[signatureId] = form.id);
      })

      newStateChanges.formIdBySignaturesId = formIdBySignaturesId;
    }

    if (!allTagsAvailable) {
      getAllAvailableTags().then(res => this.setState({allTagsAvailable : res }));
    }

    if (props.getNested(['propertyType', 'universalId']) != nextProps.getNested(['propertyType', 'universalId'])) {
      newStateChanges.universalIdsOptions = this.loadUniversalIdsOptions(nextProps);
    }
    if (Object.keys(newStateChanges).length > 0)
      this.setState(newStateChanges);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.createdUniversalIds !== prevState.createdUniversalIds)
      this.setState({ universalIdsOptions: this.loadUniversalIdsOptions() });
  }

  tagsSelectorOnChange(newValue, actionMeta) {
    const actionType = actionMeta.action;
    const { onChange } = this.props;
    let newStateChanges = {};

    if (actionType === 'remove-value' || actionType === 'select-option') {
      if ((newValue || []).length === 0)
        _.set(newStateChanges, ['propertyTags'], {})
      else
        (newValue || []).forEach(tag => 
          _.set(newStateChanges, ['propertyTags', tag.label], true)
        );
    }
    else if (actionType === 'clear') {
      newStateChanges.propertyTags = {};
    }

    if (onChange && newStateChanges.propertyTags)
      onChange(['tags'], newStateChanges.propertyTags);

    if (_.keys(newStateChanges).length)
      this.setState(newStateChanges)
  }

  selectionListValuesChanged(newSelectionListValue, index) {
    const { propertyType, onChange, lang } = this.props;

    if (!propertyType || !propertyType.values)
      return null;

    let clone = propertyType.values.slice();

    clone.splice(index, 1, newSelectionListValue);

    if (onChange)
      onChange(['values'], clone);
  }

  removeValuesHandler(index) {
    const { onChange, propertyType } = this.props;

    if (!propertyType.values || propertyType.values.length < 1)
      return;

    let newValues = propertyType.values.slice();
    newValues.splice(index, 1);

    if (onChange)
      onChange(['values'], newValues)
  }

  addValuesHandler() {
    const { onChange, propertyType, lang } = this.props;

    let newValues = (propertyType.values || []).slice();
    newValues.push({ id: utils.getUniqueFirebaseId('properties/types'), title: { [lang]: "" } });

    if (onChange) {
      onChange(['values'], newValues)
    }
  }

  render() {
    const { style, configurations, propertyType = {}, propertiesTypes, propertiesSections, propertySection, sections, onChange } = this.props;
    const { itemMode, intl, editMode, trades, lang, subjectName, project, onValuesListsChanges, onValuesListsCreation, dynamicValuesLists } = this.props;
    let { universalIdsOptions, allTagsAvailable, propertyTags, selectedInnerPropToEditColWidth, formIdBySignaturesId, createdUniversalIds } = this.state;
    let mode = null;
    let currSection = propertySection;
    let currProperty = propertyType;
    let extraTypes = {};
    let signatureBehaviour = {};
    
    let arrayTypeAvailableTypes = [];
    const innerTypesArr = _.values(currProperty.innerTypes,).sort((a, b) => (a.ordinalNo && b.ordinalNo) ? a.ordinalNo - b.ordinalNo : String(a.id).localeCompare(String(b.id))) 
    const hasInnerTypes = [propertyTypes.ARRAY, propertyTypes.COMPLEX].includes(
      (currProperty || {}).type
    );
    if (currProperty && hasInnerTypes)
      arrayTypeAvailableTypes = _.values(_.get(propertiesTypes, [subjectName], {})).filter(p => Boolean(p && p.id)).map(p => ({title: `${(p || {}).getNested(['getTitle'])} - (${(propertiesSections || {}).getNested([subjectName, (p || {}).sectionId, 'getTitle'], '')})`, id: (p || {}).getNested(['id'])}));
    let arrayTypeAvailableTypesMap = _.mapKeys(arrayTypeAvailableTypes, t => t.id)
      
    if (itemMode && currProperty) mode = 'item';
    else if (!itemMode && currSection) mode = 'section';
    let sectionsOptions = (sections || []).map(currSection => ({ id: currSection.parent.id, title: currSection.parent.getNested(['title', lang]) }));
    if (currProperty && currProperty.type == propertyTypes.CERTIFICATION) {
      let otherPropsInSection = (sections || []).filter(currSection => currSection.parent.id == currProperty.sectionId) || [];
      (otherPropsInSection[0].childs || []).forEach(prop => { if (prop.type != propertyTypes.CERTIFICATION) extraTypes[prop.id] = { id: prop.id, title: prop.getNested(['title', lang]) } });
      (currProperty.extraTypes || []).forEach(extId => { if (extraTypes[extId]) extraTypes[extId].checked = true });
      [propertyTypes.UPLOAD_FILE, propertyTypes.SIGN_ON_PICTURE, propertyTypes.SIGN_ON_TEXT].forEach(signBhvId => { signatureBehaviour[signBhvId] = { id: signBhvId, title: signBhvId, checked: (currProperty.getNested(['settings', 'signatureBehaviour', signBhvId, 'enabled'], false)) }; });
    }

    const currentPropertySignatureId = _.chain(currProperty).get(['settings', 'signatures']).keys().head().value()

    if (editMode)
      return (
        <div style={{ ...style, overflow: 'scroll', position: 'sticky', top: 0 }}>
          {
            Boolean(mode == 'item') &&
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>


              <InputField
                key={currProperty.id + '_title'}
                name={'Title'}
                type={'String'}
                value={currProperty.getNested(['title', lang])}
                onChange={val => { if (onChange) onChange(['title', lang], val) }} />

              <InputField
                key={currProperty.id + '_type'}
                name={'Type'}
                type={'SelectionList'}
                values={AVAILABLE_INPUT_TYPES.map(t => ({ id: t, title: t }))}
                value={{ [currProperty.type]: currProperty.type }}
                onChange={val => {
                  if (onChange) {
                    let selectedType = Object.keys(val)[0];
                    onChange(['type'], selectedType)
                    if (selectedType == propertyTypes.CERTIFICATION) {
                      //onChange(['settings','certificationMonthsTTL'], 12);
                      onChange(['settings', 'isExpiration'], true);
                      onChange(['settings', 'isWarning'], true);
                      onChange(['settings', 'isFileMandatory'], true);
                    }
                  }
                }
              } />

              
              {Boolean(currProperty.type === propertyTypes.SIGNATURE) && (
                <>
                <InjectedStandardInput
                  key={currProperty.id + '_signatureFlow'}
                  type={'SelectionList'}
                  isMulti={false}
                  title={'Signature flow'}
                  value={{[formIdBySignaturesId[currentPropertySignatureId]] : formIdBySignaturesId[currentPropertySignatureId]}}
                  options={Object.values(configurations.getNested(['forms'], {})).filter(form => form.signatures).map(f => ({id: f.id, title: f.getNested(['title', lang], "")}) )}
                  onChange={val => {
                    if (onChange) {
                      const formId = _.chain(val).keys().head().value();
                      const formSignatures = configurations.getNested(['forms', formId, 'signatures']);
                      if (formSignatures)
                        onChange(['settings', 'signatures'], formSignatures);
                    }
                  }}
                />
                <InjectedStandardInput
                  key={currProperty.id + 'isShowSignatureValue'}
                  type={'Boolean'}
                  title={'הצג את החתימה'}
                  value={_.get(currProperty, ['settings', 'isShowSignatureValue'])}
                  onChange={val => {
                    if (onChange)
                      onChange(['settings', 'isShowSignatureValue'], val);
                  }}
                />
                </>
              )}
              {
                Boolean(hasInnerTypes) && <>
                  <InjectedStandardInput
                    isMulti
                    key={currProperty.id + '_innerTypes'}
                    title={'Inner Types'}
                    type={'SelectionList'}
                    options={arrayTypeAvailableTypes}
                    value={Object.keys(currProperty.innerTypes || {}).reduce((acc, id) => _.set(acc, [id], id), {})}
                    onChange={val => {
                      const newVal = val ? Object.values(val).reduce((acc, id) => _.set(acc, [id, 'id'], id), {}) : null;
                      if (onChange)
                        onChange(['innerTypes'], newVal);
                    }
                    }
                  />
                </>
              }

              <Text style={{ fontWeight: theme.strongBold, margin: '13px' }}>DYNAMIC PDF STUFF</Text>
              <div style={{ padding: theme.paddingX2 }}>

                  {
                    Boolean(currProperty.type == propertyTypes.COMPLEX) &&
                    <>
                      <Text style={{ fontWeight: theme.strongBold, margin: "10px" }}>סדר מאפיינים פנימיים בpdf</Text>
                      <CrossListSortable
                        masterList={_.sortBy(innerTypesArr, (item, index) => _.get(currProperty, ['UIStruct', 'pdf', '0', 'columns', item.id, 'ordinalNo'], index + 1))}
                        onChange={(newSortedArr) => {
                          let uiStructColsSettings = { ..._.get(currProperty, ['UIStruct', 'pdf', '0', 'columns'], {}) };
                          newSortedArr.forEach((item, index) => {
                            _.set(uiStructColsSettings, [item.id, 'ordinalNo'], index + 1);
                          });
                          if (onChange) onChange(['UIStruct', 'pdf', '0', 'columns'], uiStructColsSettings);
                        }}
                        sortableProps={{
                          disabled: false,
                          handle: "#dragger",
                          forceFallback: true,
                        }}
                      >
                        {(currChild, currChildIndex) => {
                          const currChildProperty = _.get(propertiesTypes, [subjectName, currChild.id]);

                          return (
                            <PropertyRow
                              disableDrag={false}
                              dragHandle={<Dragger dragId={'dragger'} />}
                              value={currChildProperty}
                              key={currProperty.id + "_innerTypes_SortableList_" + currChildIndex}
                              selectedPropertyId={project.id}
                              lang={lang}
                              isParent={false}
                              editable={false}
                              titlePath={['title', 'he']}
                              isActive
                            />
                          );
                        }}
                      </CrossListSortable>
                      <Text style={{ fontWeight: theme.strongBold, margin: "10px" }}>רוחב עמודה של מאפיין פנימי בpdf(%)</Text>
                      <div style={{ paddingLeft: 10, paddingRight: 10, display: "flex", flexDirection: "row", }} >
                        <InjectedStandardInput
                          key={currProperty.id + '_innerTypes_colWidth_selectedProp'}
                          title={'בחר מאפיין לעריכת רוחב'}
                          type={'SelectionList'}
                          options={[{ title: "עמודה ראשית (במערך בתוך מערך)", id: "main" }, ...Object.keys(currProperty.innerTypes || {}).map((prpId) => arrayTypeAvailableTypesMap[prpId])]}
                          value={{ [selectedInnerPropToEditColWidth]: selectedInnerPropToEditColWidth }}
                          onChange={selection => this.setState({ selectedInnerPropToEditColWidth: _.head(_.keys(selection)) })
                          }
                        />
                        <InjectedStandardInput
                          key={currProperty.id + '_innerTypes_colWidth'}
                          title={'בחר רוחב באחוזים'}
                          type={'Number'}
                          value={_.get(currProperty, ['UIStruct', 'pdf', '0', 'columns', selectedInnerPropToEditColWidth, 'width'])}
                          onChange={width => {
                            if (onChange)
                              onChange(['UIStruct', 'pdf', '0', 'columns', selectedInnerPropToEditColWidth, 'width'], width);
                          }}
                        />


                      </div>
                    </>
                }
                <div style={{ paddingLeft: '10px' }}>
                  <Text style={{ fontWeight: theme.strongBold, margin: "-10px" }}>UI STRUCT</Text>
                  <div>
                    <Text style={{ fontWeight: theme.strongBold, margin: "10px" }}>Collapsed view</Text>
                    <InjectedStandardInput
                      key={currProperty.id + 'shouldCalculateAggregatedCells-collapsed'}
                      title={'Should calculate aggregated cells value (for tables with collapsable rows)'}
                      type={'Boolean'}
                      value={_.get(currProperty, ['UIStruct', 'table', '0', 'collapsed', 'settings', 'shouldCalculateAggregatedCells'], false)}
                      onChange={value => {
                        if (onChange)
                          onChange(['UIStruct', 'table', '0', 'collapsed', 'settings', 'shouldCalculateAggregatedCells'], value);
                      }}
                    />
                  </div>
                  <div>
                    <Text style={{ fontWeight: theme.strongBold, margin: "10px" }}>Expanded view</Text>
                    <InjectedStandardInput
                      key={currProperty.id + 'shouldCalculateAggregatedCells-expanded'}
                      title={'Should calculate aggregated cells value (for tables with expanded rows)'}
                      type={'Boolean'}
                      value={_.get(currProperty, ['UIStruct', 'table', '0', 'expanded', 'settings', 'shouldCalculateAggregatedCells'], false)}
                      onChange={value => {
                        if (onChange)
                          onChange(['UIStruct', 'table', '0', 'expanded', 'settings', 'shouldCalculateAggregatedCells'], value);
                      }}
                    />
                  </div>
                </div>
                <InjectedStandardInput
                  key={currProperty.id + 'preDefined_layout'}
                  title={'predefined layout'}
                  type={'SelectionList'}
                  value={{ [_.get(currProperty, ['UIStruct', 'pdf', '0', 'predefinedLayout'])]: _.get(currProperty, ['UIStruct', 'pdf', '0', 'predefinedLayout']) }}
                  options={[currProperty.type == propertyTypes.ARRAY && 'table', 'inline', 'null'].map((option) => ({ id: option, title: option }))}
                  onChange={val => {
                    if (onChange) {
                      let predefinedLayout = _.head(_.keys(val));
                      predefinedLayout = (predefinedLayout == 'none') ? null : predefinedLayout;
                      onChange(['UIStruct', 'pdf', '0', 'predefinedLayout'], predefinedLayout);
                    }
                  }}
                />
                {
                  Boolean(currProperty.type == propertyTypes.SELECTION_LIST) &&
                  <InjectedStandardInput
                    key={currProperty.id + 'joinSelections'}
                    title={'שרשור בחירות בpdf'}
                    type={'Boolean'}
                    value={_.isNil(_.get(currProperty, ['UIStruct', 'pdf', '0', 'joinSelections'])) ? true : _.get(currProperty, ['UIStruct', 'pdf', '0', 'joinSelections'])}
                    onChange={val => {
                      if (onChange) {
                        onChange(['UIStruct', 'pdf', '0', 'joinSelections'], val);
                      }
                    }}
                  />
                }
                {Boolean(currProperty.type == propertyTypes.ARRAY) &&
                  <>
                    <InjectedStandardInput
                      key={currProperty.id + 'primaryColumnTitle_title'}
                      title={'כותרת עמודה ראשית (במערך בתוך מערך)'}
                    />
                    {
                      ['he', 'en', 'ar'].map((lang, index) => {
                        return <InjectedStandardInput
                          key={currProperty.id + 'primaryColumnTitle' + lang}
                          title={lang}
                          type={propertyTypes.STRING}
                          value={_.get(currProperty, ['UIStruct', 'pdf', '0', 'primaryColumn', 'title', lang])}
                          onChange={val => {
                            if (onChange) {
                              onChange(['UIStruct', 'pdf', '0', 'primaryColumn', 'title', lang], val);
                            }
                          }}
                        />;
                      })
                    }
                  </>
                }
              </div>

              {
                Boolean(
                  currProperty.type == propertyTypes.FILES_ARRAY ||
                  currProperty.type == propertyTypes.DRAWINGS_ARRAY ||
                  currProperty.type == propertyTypes.PDF
                ) &&
                <InputField
                  key={currProperty.id + '_defaultContentType'}
                  name={'Default content type'}
                  type={'SelectionList'}
                  values={Object.values(fileContentTypes).map(t => ({ id: t, title: t }))}
                  value={{ [currProperty.defaultContentType]: currProperty.defaultContentType }}
                  onChange={val => {
                    if (onChange) {
                      let selectedContentType = Object.keys(val)[0];
                      onChange(['defaultContentType'], selectedContentType);
                    }
                  }
                  } />
              }

            <Text style={{ fontWeight: theme.strongBold, margin: '13px' }}>DYNAMIC WEB STUFF</Text>
            <div style={{ padding: theme.paddingX2 }}>
                <InjectedStandardInput 
                  key={currProperty.id + 'text_alignment'}
                  title={'Text Alignment'}
                  type={'SelectionList'}
                  options={['start', 'center', 'end', 'null'].map((option) => ({ id: option, title: option }))}
                  value={{ [_.get(currProperty, ['UIStruct', 'table', '0', 'general', 'justifyContent'])]: _.get(currProperty, ['UIStruct', 'table', '0', 'general', 'justifyContent']) }}
                  onChange={val => {
                    if (onChange) {
                      let justifyContent = _.head(_.keys(val));
                      justifyContent = (justifyContent == 'null') ? null : justifyContent;
                      onChange(['UIStruct', 'table', '0', 'general', 'justifyContent'], justifyContent);
                    }
                  }}
                />
            </div>

              <InputField
                inputInfo="ע''י בחירת ערך באפשרות זו, נוכל לציין את האופי העסקי של סוג השדה"
                key={currProperty.id + '_businessType'}
                name={'Business Property'}
                type={'SelectionList'}
                values={(businessTypeValues[currProperty.type] || []).concat(businessTypeValues.Complex).map(t => ({ id: t, title: t }))}
                value={{ [currProperty.businessType]: currProperty.businessType }}
                onChange={val => { if (onChange) onChange(['businessType'], Object.keys(val)[0]); }} />

              <InputField
                inputInfo="ע''י בחירת ערך באפשרות זו, נוכל לזהות את השדה כשדה יחודי בעל משמעות קבועה משותפת לכל הפרוייקטים"
                key={currProperty.id + '_universalId'}
                name={'Universal Property'}
                type={'SelectionList'}
                isCreatable={true}
                onCreateOption={(uniId) => {
                  if (onChange)
                    onChange(['universalId'], uniId);
                  this.setState({ createdUniversalIds: [uniId].concat(createdUniversalIds) });
                }}
                values={universalIdsOptions}
                value={{ [currProperty.universalId]: currProperty.universalId }}
                onChange={val => {
                  if (onChange) {
                    let uniId = Object.keys(val || {})[0] || null;
                    onChange(['universalId'], uniId);
                    onChange(['isRequired'], null);
                    if (uniId == 'isActive') {
                      onChange(['hideOnMobile'], true);
                      onChange(['settings', 'defaultVal'], true);
                    }
                  }
                }
                } />

              <InputField
                key={currProperty.id + '_externalId'}
                inputInfo="מזהה למערכות חיצוניות של לקוחות העובדים עם סמנטו"
                name={'ExternalId'}
                type={'String'}
                value={currProperty.getNested(['externalId'])}
                onChange={val => { if (onChange) onChange(['externalId'], val) }}
                disabled={false}
              />
              <ObjectInputField
                inputInfo="תת מזהה למערכות חיצוניות של לקוחות העובדים עם סמנטו"
                title={'Sub External ID'}
                value = {{key: currProperty.getNested(['subExternalId', 'key']), value: currProperty.getNested(['subExternalId', 'value'])}}
                onChange={val => { if (onChange) onChange(['subExternalId'], val)}}
              />
              <InputField
                key={currProperty.id + '_width'}
                inputInfo="Each column in the table have default width, here you can define new ratio. For example if the default width is 10 and you define ratio of 1.5 so the width of this property(column) going to be 15"
                name={'Width Ratio(for tables)'}
                type={'String'}
                value={_.get(currProperty, ['settings', 'widthRatio'])}
                onChange={val => {
                  if (onChange) onChange(['settings', 'widthRatio'], val);
                }}
              />

              <InputField
                key={currProperty.id + '_parentSection'}
                name={'Parent section'}
                type={'SelectionList'}
                values={sectionsOptions}
                value={{ [currProperty.sectionId]: currProperty.sectionId }}
                onChange={val => { if (onChange) onChange(['sectionId'], Object.keys(val)[0]) } }
              />

              <InputField
                key={currProperty.id + '_viewType'}
                name={'View type'}
                type={'SelectionList'}
                values={Object.values(PROPERTIES_VIEW_TYPES).map(vt => ({ title: vt, id: vt }))}
                value={{ [_.get(currProperty, ['settings', 'viewType'])]: _.get(currProperty, ['settings', 'viewType']) }}
                onChange={val => { if (onChange) onChange(['settings', 'viewType'], Object.keys(val)[0]) } }
              />

              <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <Text style={{ fontWeight: theme.strongBold, marginBottom: '10px' }}>Property Tags</Text>
                <Creatable
                  isMulti
                  value={_.keys(propertyTags || {}).map(tag => ({label: tag, value: tag}))}
                  options={_.keys(allTagsAvailable || {}).map(tag => ({label: tag, value: tag}))}
                  onChange={(newValue, actionMeta) => this.tagsSelectorOnChange(newValue, actionMeta)}
                  onCreateOption={inputValue => {
                    let newTagsOptions = {...allTagsAvailable, [inputValue] : true};
                    let newPropertyTags = {...propertyTags, [inputValue] : true};
                    if (onChange)
                      onChange(['tags'], newPropertyTags)
                    this.setState({allTagsAvailable: newTagsOptions, propertyTags: newPropertyTags});
                  }}
                />
              </div>
              
              <FunctionalInput 
                key={currProperty.getCementoTitle() + currProperty.id}
                title={'Default value'}
                extraPropertiesTypes={{ [currProperty.id]: currProperty }}
                value={currProperty.getNested(['settings', 'defaultVal'], null)}
                onChange={(propId, newData) => {
                  if (onChange)
                    onChange(['settings', 'defaultVal'], newData);
                }}
                propId={currProperty.id}
              />

              <Button 
                title={'Clear default value'}
                isDisabled={_.isNil(currProperty.getNested(['settings', 'defaultVal']))}
                onClick={() => {
                  if (onChange)
                    onChange(['settings', 'defaultVal'], null);
                }}
                style={{ marginInline: 'auto' }}
              />

              {Boolean(currProperty.type === 'Date') &&
              <>
              <Text style={{ fontWeight: theme.strongBold, margin: '10px' }}>Date limitation</Text>
              <Text style={{ marginBottom: '10px', direction: 'ltr'}}>Adding to the chosen data the year limitation and checking if it smaller then this moment</Text>
              <InputField
                key={currProperty.id + '_DateLimitation_year'}
                name={'Date Limitation (years)'}
                type={'String'}
                value={currProperty.getNested(['settings', 'ageEnforcement', 'year'])}
                onChange={val => {
                  let path = ['settings', 'ageEnforcement', 'year'];

                  if (val ===0 || (typeof val === 'string' && _.isEmpty(val.trim()))) {
                    path = ['settings', 'ageEnforcement'];
                    val = null;
                  }

                  if (onChange)
                    onChange(path, val)
                  }
                }
                disabled={false}
              />
              <InputField
                key={currProperty.id + '_DateLimitation_errorMessageTitle'}
                name={'Error Message Title'}
                type={'String'}
                value={{title: _.get(currProperty, ['settings', 'ageEnforcement', 'errorMessage', 'errorTitle'], '')}.getCementoTitle()}
                onChange={val => { if (onChange) onChange(['settings', 'ageEnforcement', 'errorMessage', 'errorTitle', lang], val) }}
                disabled={false}
              />
              <InputField
                key={currProperty.id + '_DateLimitation_errorMessageContent'}
                name={'Error Message Content'}
                type={'String'}
                value={{title: _.get(currProperty, ['settings', 'ageEnforcement', 'errorMessage', 'errorContent'], '')}.getCementoTitle()}
                onChange={val => { if (onChange) onChange(['settings', 'ageEnforcement', 'errorMessage', 'errorContent', lang], val) }}
                disabled={false}
              />
              </>
              }
              {Boolean(currProperty.type === propertyTypes.DATE) && (
                <InputField 
                  key={currProperty.id + '_dateTimeZone'}
                  name={'Date Time Zone'}
                  type={'SelectionList'}
                  value={currProperty.getNested(['settings', 'timezone']) ? {[currProperty.getNested(['settings', 'timezone'])]: currProperty.getNested(['settings', 'timezone'])} : null}
                  values={TIME_ZONES_SELSECTION_OPIONS.map(tz => ({ title: tz, id: tz }))}
                  onChange={val => { if (onChange) onChange(['settings', 'timezone'], _.first(_.keys(val)) || null) }}
                  disabled={false}
                />
              )}
              {
                Boolean(propertyTypes.isMultiSupportedTypes[currProperty.type]) &&
                <InputField
                  key={currProperty.id + '_isMulti'}
                  name={'Is MultiSelect'}
                  type={'Boolean'}
                  value={currProperty.getNested(['settings', 'isMulti'], false)}
                  onChange={val => { if (onChange) onChange(['settings', 'isMulti'], val); }} />
              }
              {Boolean(currProperty.type === propertyTypes.ARRAY) && (
                <InputField
                  key={currProperty.id + '_generateSubTable'}
                  name={'Is generate sub table'}
                  type={'Boolean'}
                  value={currProperty.getNested(['settings', 'generateSubTable'], false)}
                  onChange={val => { if (onChange) onChange(['settings', 'generateSubTable'], val); }} 
                />
              )}
              <InputField
                key={currProperty.id + '_isArchivable'}
                name={'Is Archivable'}
                type={'Boolean'}
                value={currProperty.getNested(['settings', 'isArchivable'], false)}
                onChange={val => { if (onChange) onChange(['settings', 'isArchivable'], val) }} />

              <InputField
                key={currProperty.id + '_mandatory'}
                name={'Is Mandatory'}
                type={'Boolean'}
                value={currProperty.getNested(['mandatory'], false)}
                onChange={val => { if (onChange) onChange(['mandatory'], val) }} />

              {Boolean(currProperty.type === 'String') && (
                <InputField
                  key={currProperty.id + '_longText'}
                  name={'Is multiline text'}
                  type={'Boolean'}
                  value={_.get(currProperty, ['settings', 'longText'], true)}
                  onChange={val => { if (onChange) onChange(['settings', 'longText'], val) }}
                />
              )}

              {Boolean(currProperty.type == propertyTypes.CERTIFICATION) && (
                <>
                <InputField
                  key={currProperty.id + '_isFileMandatory'}
                  name={'Is file mandatory?'}
                  type={'Boolean'}
                  value={currProperty.getNested(['settings', 'isFileMandatory'], false)}
                  onChange={val => { if (onChange) onChange(['settings', 'isFileMandatory'], val) }}
                />
                <InputField
                  key={currProperty.id + '_isRequired'}
                  name={'isRequired'}
                  type={'Boolean'}
                  value={currProperty.getNested(['settings', 'isRequired'], false)}
                  onChange={val => { if (onChange) onChange(['settings', 'isRequired'], val) }} 
                />
                </>
              )}

              <InputField
                key={currProperty.id + '_showOnNullValue'}
                name={'Show on null'}
                type={'Boolean'}
                value={currProperty.getNested(['showOnNullValue'], false)}
                onChange={val => { if (onChange) onChange(['showOnNullValue'], val) }} />

              <InputField
                key={currProperty.id + '_hideOnMobile'}
                name={'Hide from mobile'}
                type={'Boolean'}
                value={currProperty.getNested(['hideOnMobile'], false)}
                onChange={val => { if (onChange) onChange(['hideOnMobile'], val) }} />

              <InputField
                key={currProperty.id + '_isPrimary'}
                name={'Is Primary property'}
                type={'Boolean'}
                value={currProperty.getNested(['isPrimary'], false)}
                onChange={val => { if (onChange) onChange(['isPrimary'], val) }} />

              <InputField
                key={currProperty.id + 'editable'}
                name={'Editable'}
                type={'Boolean'}
                value={currProperty.getNested(['editable'], false)}
                onChange={val => { if (onChange) onChange(['editable'], val) }} />


              <InputField
                key={currProperty.id + '_isGroups'}
                name={'Is Groups property'}
                type={'Boolean'}
                value={currProperty.id == 'groups'}
                onChange={val => {
                  let groupsId = val ? 'groups' : ('id' + new Date().getTime());
                  if (onChange) {
                    onChange(['id'], groupsId)
                    if (val) {
                      onChange(['mandatory'], true)
                      onChange(['hideOnMobile'], true)
                    }
                  }
                }} />

                <div style={{ display: 'flex', flexDirection: 'column', borderBottom: `1px solid ${theme.brandPrimary}` }}>
                  <Text style={{ fontWeight: 'bold', fontSize: theme.fontSizeH6 }}>Table column display title</Text>
                  {_.values(LANGS).map(lang => (
                    <InputField
                      key={currProperty.id + '_columnDisplayTitle_' + lang.code}
                      name={lang.name}
                      type={'String'}
                      value={currProperty.getNested(['settings', 'columnDisplayTitle', lang.code], '')}
                      onChange={val => { if (onChange) onChange(['settings', 'columnDisplayTitle', lang.code], val) }} 
                    />
                  ))}
                </div>

                  {
                    Boolean(isPropertySynchronizationLevelActive && subjectName === 'employeesInfo') &&
                    <GridItem xs={12} style={{ display: 'flex', flexDirection: 'column', padding: 2, border: '1px solid' }}>
                      <div style={{ textAlign: 'center'}}>
                        <Text style={{fontWeight: theme.strongBold}}>Synchronization level</Text>
                      </div>
                      {syncLevelOptions.map(syncLevelOption => {
                        return (
                          <InputField
                            key={currProperty.id + 'editable'}
                            name={`האם לסנכרן ברמת ${syncLevelOption}`}
                            type={'Boolean'}
                            value={_.get(currProperty, ['settings', 'syncLevel', syncLevelOption], false)}
                            onChange={val => { if (onChange) onChange(['settings', 'syncLevel', syncLevelOption], val) }}
                          />
                        )
                      })}
                    </GridItem>
                  }

              {Boolean(currProperty.type == propertyTypes.CERTIFICATION) &&
                <InputField
                  key={currProperty.id + '_shouldSendSms_'}
                  name={'Should send sms'}
                  type={'Boolean'}
                  value={currProperty.getNested(['settings', 'notifications', 'sms'], false)}
                  onChange={val => { 
                    if (onChange)
                     onChange(['settings', 'notifications', 'sms'], val) 
                  }} />
              }



              {Boolean(currProperty.type == 'SelectionList') &&
                <>
                  <InputField
                    key={`${currProperty.id}_isCreatable_bool`}
                    name={'isCreatable'}
                    type={'Boolean'}
                    value={Boolean(currProperty.isCreatable)}
                    onChange={val => {
                      if (onChange) {
                        onChange(['isCreatable'], val);
                        if (currProperty.isCreatable && !val)
                          onChange(['valuesListsIds'], null);
                      }
                    }} />
                  {
                    Boolean(currProperty.isCreatable)
                      ? <GridItem xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                        <ExternalValueListsSelector
                          propId={currProperty.id}
                          projectId={project.id}
                          value={currProperty.valuesListsIds}
                          dynamicValuesLists={dynamicValuesLists}
                          onListSelection={(val) => {
                            if (onChange)
                              onChange(['valuesListsIds'], val);
                          }}
                          onValuesListsChanges={onValuesListsChanges}
                          onValuesListsCreation={onValuesListsCreation}
                          lang={lang}
                        />
                      </GridItem>
                      : <GridItem xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ fontWeight: theme.strongBold }}>Values:</div>
                        <div style={{ backgroundColor: '#f1f1f1', flex: 1, padding: theme.paddingSize, margin: theme.paddingSize, height: 400, overflow: 'auto' }}>
                          <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', cursor: 'pointer' }} onClick={this.addValuesHandler}>
                            <img src={plus_primary} />
                          </div>
                          {(currProperty.values || []).map((curr, index) => <SelectionListValue lang={lang} value={curr} index={index} onRemove={this.removeValuesHandler} onChange={(newVal) => this.selectionListValuesChanged(newVal, index)} />)}
                        </div>
                      </GridItem>
                  }
                </>
              }

              {
                /////////////////////////////////////////////
                ///    CERTIFICATIONS STUFF GOES HERE!    ///
                /////////////////////////////////////////////
              }

              {Boolean(currProperty.type == propertyTypes.CERTIFICATION) &&
                <>
                  <MultiCheckSelect
                    hideSearch={true}
                    height={120}
                    style={{ padding: theme.paddingSize }}
                    items={signatureBehaviour}
                    titlePropPath={['title']}
                    onChange={(newItems, allSelectedMap, allUnheckedMap) => {
                      if (onChange) {
                        [propertyTypes.UPLOAD_FILE, propertyTypes.SIGN_ON_TEXT, propertyTypes.SIGN_ON_PICTURE]
                          .filter(x => {
                            onChange(['settings', 'signatureBehaviour', x, 'enabled'], Boolean(allSelectedMap[x]));
                            return Boolean(allSelectedMap[x]);
                          })
                          .forEach((item, index, arr) => {
                            if (arr.length == 1)
                              onChange(['settings', 'signatureBehaviour', 'default'], item);
                        });
                    }
                  }} />
                {
                  Boolean(currProperty.getNested(['settings', 'signatureBehaviour', propertyTypes.SIGN_ON_TEXT, 'enabled'])) &&
                  <InputField
                    key={currProperty.id + '_cementoSign_type'}
                    name={"simple or complex sign"}
                    type={'SelectionList'}
                    values={_.values(propertyTypes.CEMENTO_SIGN_BEHAVIOUR).map(v => ({ id: v, title: v }))}
                    value={{
                      [currProperty.getNested(['settings', 'signatureBehaviour', propertyTypes.SIGN_ON_TEXT, 'behaviour'], propertyTypes.CEMENTO_SIGN_BEHAVIOUR.complex)]: [currProperty.getNested(['settings', 'signatureBehaviour', propertyTypes.SIGN_ON_TEXT, 'behaviour'], propertyTypes.CEMENTO_SIGN_BEHAVIOUR.complex)]
                    }}
                    onChange={val => { if (onChange) onChange(['settings', 'signatureBehaviour', propertyTypes.SIGN_ON_TEXT, 'behaviour'], _.head(_.values(val))); }} />
                }
                
                  {
                    Boolean(Object.values(signatureBehaviour).filter(v => v.checked).length > 1) &&
                    <InputField
                      key={currProperty.id + '_defaultSignatureBehavior'}
                      name={'Default Signature Behavior'}
                      type={'SelectionList'}
                      values={Object.values(signatureBehaviour).filter(v => v.checked)}
                      value={{ [currProperty.getNested(['settings', 'signatureBehaviour', 'default'])]: currProperty.getNested(['settings', 'signatureBehaviour', 'default']) }}
                      onChange={val => {
                        if (onChange) onChange(['settings', 'signatureBehaviour', 'default'], Object.values(val || {})[0]);
                      }} />
                }

                {
                  Object.values(signatureBehaviour)
                    .map(currBehaviour =>
                      <InputField
                        key={`${currProperty.id}_${currBehaviour.id}_text`}
                        name={`${currBehaviour.title} configurable text`}
                        type={'String'}
                        value={currProperty.getNested(['settings', 'signatureBehaviour', currBehaviour.id,'text'])}
                        onChange={val => {
                          if (onChange) onChange(['settings', 'signatureBehaviour', currBehaviour.id, 'text'], (val == '') ? null : val);
                        }} />

                    )
                }

                {[
                  'isExpiration', 'isWarning',
                  ...Boolean([propertyTypes.SIGN_ON_PICTURE, propertyTypes.SIGN_ON_TEXT].some(method => currProperty.getNested(['settings', 'signatureBehaviour', method, 'enabled'])))
                    ? ['generatePDF']
                    : [],
                ].map(curr =>
                  <InputField
                    key={currProperty.id + '_' + curr}
                    name={curr}
                    type={'Boolean'}
                    value={currProperty.getNested(['settings', curr], false)}
                    onChange={val => { if (onChange) onChange(['settings', curr], val); }} />
                )
                }

                {
                  <InputField
                    key={`${currProperty.id}_daysToWarning`}
                    name={'daysToWarning'}
                    type={'Number'}
                    value={_.get(currProperty, ['settings', 'daysToWarning'], 30)}
                    onChange={val => {
                      if (onChange) onChange(['settings', 'daysToWarning'], Number(val));
                    }}
                  />
                }

                {
                  Boolean(currProperty.getNested(['settings', 'isExpiration'], false)) &&
                  [..._.values(propertyTypes.CERTIFICATIONS_TTL_TYPES)].map(curr =>
                    <InputField
                      key={currProperty.id + '_' + curr}
                      name={curr}
                      type={'Number'}
                      value={(currProperty.settings || {})[curr]}
                      onChange={val => {
                        if (onChange) {
                          onChange(['settings', curr], Number(val));
                          if (propertyTypes.CERTIFICATIONS_TTL_TYPES[curr]) {
                            let fieldToRemove = _.keys(propertyTypes.CERTIFICATIONS_TTL_TYPES).filter(type => (type != curr));
                            onChange(['settings', fieldToRemove], null);
                          }
                        }
                      }}
                    />
                  )}

                  <div key={currProperty.id + '_certificationText'}>
                    <InputField name={"Certification content"} />
                    {Object.values(LANGS).map((currLang, i) => (
                      <InputField
                        key={currProperty.id + '_certificationText_' + i}
                        inputInfo="תוכן ההסמכה עליו חותמים"
                        name={currLang.name}
                        type={'String'}
                        value={currProperty.getNested(['settings', 'certificationText', 'content', currLang.code])}
                        onChange={val => { if (onChange) onChange(['settings', 'certificationText', 'content', currLang.code], val); }}
                        disabled={false}
                      />
                    ))}
                  </div>

                  <MultiCheckSelect
                    height={500}
                    style={{ padding: theme.paddingSize }}
                    items={extraTypes}
                    titlePropPath={['title']}
                    onChange={(newItems, allSelectedMap) => {
                      if (onChange) {
                        let extraArray = Object.keys(allSelectedMap);
                        onChange(['extraTypes'], extraArray);
                      }
                    }} />



                  {
                    ///// CERTIFICATIONS ISSUES SETTINGS
                  }

                  <InputField
                    key={`${currProperty.id}_disable_cert_issues`}
                    name={'disable cert issues'}
                    type={'Boolean'}
                    value={currProperty.getNested(['settings', 'posts', 'disabled'], false)}
                    onChange={val => { if (onChange) onChange(['settings', 'posts', 'disabled'], val); }} />

                  <InputField
                    key={`${currProperty.id}_post_dueDate_type`}
                    name={'issues dueDate type'}
                    type={'SelectionList'}
                    values={_.values(['days', 'weeks', 'months']).map(v => ({ id: v, title: v }))}
                    value={{ [_.get(currProperty, ['settings', 'posts', 'dueDate', 'type'])]: _.get(currProperty, ['settings', 'posts', 'dueDate', 'type']) }}
                    onChange={val => {
                      if (onChange) onChange(['settings', 'posts', 'dueDate', 'type'], _.head(_.values(val)));
                    }}
                  />

                  <InputField
                    key={`${currProperty.id}_post_dueDate_amount`}
                    name={'issues dueDate amount'}
                    type={'Number'}
                    value={_.get(currProperty, ['settings', 'posts', 'dueDate', 'amount'])}
                    onChange={val => {
                      if (onChange) onChange(['settings', 'posts', 'dueDate', 'amount'], Number(val));
                    }}
                  />
                  
                  
                </>
              }
            </div>
          }
          {
            Boolean(mode == 'section') &&
            <>
              <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <InputField
                  key={currSection.id + '_title'}
                  name={'Title'}
                  type={'String'}
                  value={currSection.getNested(['title', lang])}
                  onChange={val => { if (onChange) onChange(['title', lang], val) }} />
              </div>
              <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <Text style={{ fontWeight: theme.strongBold, marginBottom: '10px' }}>Read permissions</Text>
                <Select
                  fullWidth
                  isMulti={true}
                  disableUnderline={true}
                  inputProps={{ disableUnderline: true }}
                  isClearable={true}
                  value={currSection.getNested(['permissions', 'read', 'groups'], []).map(perm => readPermissionGroupsOptions[perm])}
                  onChange={newSelectedPermissionsOpts => {
                    newSelectedPermissionsOpts = newSelectedPermissionsOpts.map(permOpt => permOpt.value);
                    if (onChange) onChange(['permissions', 'read', 'groups'], newSelectedPermissionsOpts);
                  }}
                  options={Object.values(readPermissionGroupsOptions)}
                />
              </div>
            </>
          }
        </div>
      );

    else
      return (
        <Card>
          <CardHeader color="warning" text>
            <CardIcon color="warning"><LibraryBooks color='wight' /></CardIcon>
          </CardHeader>
          <CardBody>
            {
              Boolean(mode == 'child') &&
              <GridContainer>
                <GridItem xs={4}>{intl.formatMessage(checklistsMessages.trade)} </GridItem>
                <GridItem xs={8}>{trades.getNested([String(currProperty.trade), 'getTitle'])}</GridItem>
                <GridItem xs={4}>{intl.formatMessage(checklistsMessages.description)}</GridItem>
                <GridItem xs={8}>{currProperty.description}</GridItem>
              </GridContainer>
            }
          </CardBody>
        </Card>
      );
  }
}

PropertyCard = injectIntl(PropertyCard);
PropertyCard = withStyles(validationFormsStyle)(PropertyCard)
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(null, {})
);
export default enhance(PropertyCard);
















class SelectionListValue extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.setComponentData = this.setComponentData.bind(this)
    this.onRemove = this.onRemove.bind(this);
    this.state = {
      value: {}
    };
  }

  componentWillMount() {
    this.setComponentData({}, this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps)
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};

    if (Object.keys(newStateChanges).length > 0)
      this.setState(newStateChanges);
  }

  onRemove() {
    this.props.onRemove(this.props.index);
  }

  onChange(val) {
    const { onChange, value } = this.props;
    let newValue = (value && value.toJS) ? value.toJS() : { ...value }
    newValue = newValue.setNested(['title', this.props.lang], val)
    if (onChange)
      onChange(newValue);
  }
  render() {
    const { lang, value } = this.props;

    return (
      <div style={{ display: 'flex', alignItems: 'center', backgroundColor: value.isDeleted ? 'gray' : null }}>
        {
          Boolean(!value.isDeleted) &&
          <CloseRounded style={{ margin: 5, fontSize: 16, borderRadius: '50%', cursor: 'pointer', color: 'white', backgroundColor: theme.brandPrimary, zIndex: theme.zIndexesLevels.three }} onClick={this.onRemove} />
        }
        <InputField
          disabled={false}
          nameSizeRatio={0.5}
          key={value.id}
          name={value.id}
          type={'String'}
          defaultValue={value.getNested(['title', lang])}
          onChange={this.onChange} />
      </div>
    );
  }
}

class ExternalValueListsSelector extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onAddValue = this.onAddValue.bind(this);
    this.onListSelection = this.onListSelection.bind(this);
    this.getMappedLists = this.getMappedLists.bind(this);

    const { propId } = props;

    this.state = {
      baseSelectionListId: getBaseSelectionListId(propId),
      lists: this.getMappedLists(),
      selectedListToEdit: null
    };
  }

  getMappedLists(props) {
    const { dynamicValuesLists, lang } = props || this.props;
    const lists = {};
    _.forIn(dynamicValuesLists, (list, listKey) => {
      const title = _.get(list, ['title', lang]) || _.head(_.values(list.title));
      lists[listKey] = { ...list, title };
    });
    return lists;
  }

  componentWillReceiveProps(newProps) {
    if (this.props.isValDiff(newProps, ['dynamicValuesLists'])) {
      const lists = this.getMappedLists(newProps);
      this.setState({ lists });
    }
  }

  onCreate(title) {
    const { baseSelectionListId } = this.state;
    const { onValuesListsCreation, propId, projectId } = this.props;
    if (_.isFunction(onValuesListsCreation))
      onValuesListsCreation(baseSelectionListId, title, propId);
    this.onListSelection(baseSelectionListId);
  }

  onListSelection(listId) {
    const { onListSelection } = this.props;
    if (_.isFunction(onListSelection))
      onListSelection(listId);
  }

  onChange(changedOption) {
    const { onValuesListsChanges } = this.props;
    const { selectedListToEdit } = this.state;
    if (_.isFunction(onValuesListsChanges))
      onValuesListsChanges(selectedListToEdit, changedOption.id, changedOption);
  }

  onAddValue() {
    const { baseSelectionListId } = this.state;
    const { projectId } = this.props;
    const newValueId = utils.getUniqueFirebaseId(`properties/selectionListValues/projects/${projectId}/${baseSelectionListId}`);
    const optionValue = { id: newValueId };
    this.onChange(optionValue);
  }

  onRemove(id) {
    const { selectedListToEdit, } = this.state;
    const { dynamicValuesLists } = this.props;
    let currVal = _.get(dynamicValuesLists, [selectedListToEdit, 'values', id]);
    this.onChange({ ...currVal, isDeleted: true });
  }


  render() {
    const { selectedListToEdit, lists } = this.state;
    const { value, dynamicValuesLists, lang, onListSelection } = this.props;

    return <>
      <InputField
        key={'ExternalValueListsSelector'}
        name={"edit property's selected values lists"}
        type={'SelectionList'}
        values={lists}
        value={value}
        isMulti={true}
        isCreatable={_.isEmpty(dynamicValuesLists)}
        onCreateOption={this.onCreate}
        onChange={this.onListSelection} />

      <InputField
        key={'valueListToEdit'}
        name={"select a list to edit (this may effect other properties)"}
        type={'SelectionList'}
        values={lists}
        value={{ [selectedListToEdit]: selectedListToEdit }}
        isMulti={false}
        onChange={val => {
          const selectedListToEdit = _.head(_.keys(val));
          this.setState({ selectedListToEdit });
        }}
      />
      {
        Boolean(selectedListToEdit) &&
        <div style={{ backgroundColor: '#f1f1f1', flex: 1, padding: theme.paddingSize, margin: theme.paddingSize, height: 400, overflow: 'auto' }}>
          <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', cursor: 'pointer' }} onClick={this.onAddValue}>
            <img src={plus_primary} />
          </div>
          {
              _.map(_.get(dynamicValuesLists, [selectedListToEdit, 'values']),
              (curr, index) => <SelectionListValue
                lang={lang}
                value={curr}
                index={index}
                onChange={this.onChange}
                onRemove={this.onRemove}
              />
            )
          }
        </div>
      }
    </>;
  }
}