import { Record } from '../transit';

const DailyReport = Record({
  type: null,
  createdTS: null,
  updatedTS: null,
  dateTS: null,
  id: null,
  description: null,
  safetyNotes: null,
  images: null,
  isLocal: null,
  isDeleted: null,
  weather: null,
  approver: null,
  competents: null,
  posts: null,
  subsOnSite: null,
  status: null,
  owner: null,
  signature: null,
  pdfFile: null,
  serialNumber: null,
}, 'dailyReport');

export default DailyReport;