import React from "react";
import theme from "../../assets/css/theme";
import PropertiesManager from "./PropertiesManager";
import InputField from "../../components/CementoComponents/InputField";
import SplitViewPage from "../../layouts/SplitViewPage";
import _ from "lodash";
import { SYSTEM_SUBJECTS_TYPES } from "../../../common/propertiesTypes/propertiesTypes";
import Button from '../../app/standardComponents/Button';
import { platformActions } from '../../../common/platformActions';
import StandardInput from '../../components/CementoComponents/StandardInput';
import { envParams } from '../../../common/configureMiddleware';
import { hideLoading, startLoading, startToast } from '../../../common/app/actions';
import { v4 as uuidV4 } from 'uuid';
import { connect } from 'react-redux';
import { ProjectContext } from '../../../common/projects/contexts';
import { compose } from 'recompose';
import { connectContext } from 'react-connect-context';
import Text from '../../components/CementoComponents/Text';

class PropertiesManagerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectName: "locationsInfo",
      selectedScope: "projects",
      selectedScopeId: props.selectedProjectId,
      setPinchasParams: {
        isNewProject: false,
        copyMenus: true,
      },
    };
  }

  handleSetPinchasProperties = async () => {
    const { selectedProjectId, startToast, hideLoading, startLoading } = this.props;
    const { isNewProject, copyMenus } = this.state.setPinchasParams;
    const { apiServer } = envParams;
    
    const url = `${apiServer}/v1/services/properties/duplicatePropertiesTypes`;
    const body = {
      projectIds: [selectedProjectId],
      updateCompany: 'isInitialMigration',
      copyMenus,
      isNewProject,
    }

    const operationId = 'setPinchas_' + uuidV4();

    startLoading({ operationId });
    let toastParams = { overlay: true };
    try {
      await platformActions.net.fetch(url, { method: 'POST', body: JSON.stringify(body) });
      toastParams.title = 'Successfully set pinchas clali';
      toastParams.message = 'You may reload a couple times at your convenience to get the changes if they are not already visible.\nIf still no luck, try taking a nap and if that still doesnt help, please contact a dev ❤️';
    }
    catch (error) {
      console.error('Error setting pinchas ->', error);
      toastParams.title = 'Failed to set pinchas';
      toastParams.message = 'I don\'t want you to panic but something went wrong while setting the pinchas clali.\nPlease open the console and contact the dev closest to your heart ❤️\n(just not me, thanks 😘).';
    }
    finally {
      hideLoading(operationId);
      startToast(toastParams);
    }
  }

  render() {
    const { history, match } = this.props;
    const { subjectName } = this.state;

    return (
      <SplitViewPage
        Main={
          <div style={{ flex:1, padding: theme.paddingSize, display:'flex', flexDirection:'column', minHeight:'100%'}}>
            <div style={{ padding: theme.padding, borderTop: `2px solid ${theme.brandPrimary}`, borderBottom: `2px solid ${theme.brandPrimary}` }}>
              <Text style={{ fontWeight: theme.bold, fontSize: theme.fontSizeH5, marginBottom: theme.margin }}>Copy pinchas clali properties to this project</Text>
              <StandardInput 
                title={'Is new project?'}
                disabled={false}
                type={'Boolean'}
                tooltip={{title: 'only required to know if we should initialize the projectsInfo subject'}}
                value={this.state.setPinchasParams.isNewProject}
                onChange={(value) => {
                  this.setState({
                    setPinchasParams: {
                      ...this.state.setPinchasParams,
                      isNewProject: value,
                    }
                  })
                }}
              />
              <StandardInput 
                title={'Copy menus?'}
                disabled={false}
                type={'Boolean'}
                tooltip={{title: 'this will copy the tables from pinchas project. Do not set if you are just copying the properties again and set up different tables already'}}
                value={this.state.setPinchasParams.copyMenus}
                onChange={(value) => {
                  this.setState({
                    setPinchasParams: {
                      ...this.state.setPinchasParams,
                      copyMenus: value,
                    }
                  })
                }}
              />
              <Button title={'Submit'} onClick={this.handleSetPinchasProperties} />
            </div>
            <InputField 
              style={{flex:'none'}}
              type={'SelectionList'} 
              key={'subjectName'} 
              name={'SubjectName:'} 
              value={{[subjectName]:subjectName}} 
              onChange={val => 
                this.setState({subjectName: Object.keys(val)[0]}) 
              } 
              values={SYSTEM_SUBJECTS_TYPES.map(subj => ({ id: subj, title: subj }))} />
            <PropertiesManager
              history={history}
              match={match}
              subjectName={subjectName}
            />
          </div>
        }
      />
    );
  }
}

const enhance = compose(
  connect(null, { startLoading, hideLoading, startToast }),
  connectContext(ProjectContext.Consumer),
);

export default enhance(PropertiesManagerPage);
