import React from "react";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { getAllUsers } from "../../../common/members/actions";
import Paper from "@material-ui/core/Paper";
import { SearchState, IntegratedFiltering } from "@devexpress/dx-react-grid";
import {
  Grid,
  Toolbar,
  SearchPanel,
  VirtualTable,
  Table,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import theme from "../../assets/css/theme";

class MembersManagerTable extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.formatMembers = this.formatMembers.bind(this);
    this.state = {
      remoteMode: !props.members,
      loading: !props.members,
      formatedMembers: [],
      columns: [
        { name: "phoneNumber", title: "Phone" },
        { name: "companyName", title: "Company" },
        { name: "displayName", title: "Name" },
      ],
    };
    this.TableRow = (({ row, ...restProps }) => {
      const { selectedRow } = this.state;
      const { onSelect } = this.props;
      let isSelected = selectedRow && row.id == selectedRow.id;
      return (
        <Table.Row
          {...restProps}
          onClick={() => {
            if (onSelect) onSelect(row.member);
            this.setState({ selectedRow: row });
          }}
          style={{
            cursor: "pointer",
            backgroundColor: isSelected ? theme.backgroundColor : null,
          }}
        />
      );
    }).bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.searchValue != prevState.searchValue)
      this.loadData(this.state.searchValue);
  }

  componentDidMount() {
    this.setComponentData({ firstMount: true }, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  async setComponentData(props, nextProps) {
    let newStateChanges = {};

    if (nextProps.members) {
      if (props.members != nextProps.members)
        newStateChanges.formatedMembers = this.formatMembers(nextProps.members);
    } else if (props.firstMount) {
      let res = await nextProps.getAllUsers(5);
      newStateChanges.formatedMembers = this.formatMembers(res.DBMembers);
      newStateChanges.loading = false;
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  formatMembers(members) {
    const { companies } = this.props;
    let foramted = [];
    (members || {}).loopEach((k, curr) => {
      foramted.push({
        id: curr.id,
        displayName: curr.displayName,
        companyName: companies.getNested([curr.companyId, "name"], ""),
        phoneNumber: curr.phoneNumber,
        lastActivity: 1,
        member: curr,
      });
    });
    return foramted;
  }

  async loadData(searchValue) {
    const { getAllUsers } = this.props;
    let newStateChanges = { loading: false };
    let response = this.formatMembers(await getAllUsers(5, searchValue));
    if (this.lastSearch == searchValue)
      newStateChanges.formatedMembers = response;
    this.setState(newStateChanges);
  }

  onSearch(searchValue) {
    this.setState({ loading: true, searchValue });
    this.lastSearch = searchValue;
  }

  render() {
    const { style } = this.props;
    const { formatedMembers, columns, loading, remoteMode } = this.state;
    return (
      <Paper style={style}>
        <div style={{ position: "relative", overflow: "hidden" }}>
          <Grid rows={formatedMembers} columns={columns}>
            <SearchState onValueChange={remoteMode ? this.onSearch : null} />
            {!remoteMode && <IntegratedFiltering />}
            <VirtualTable rowComponent={this.TableRow} />
            <TableHeaderRow />
            <Toolbar />
            <SearchPanel />
          </Grid>
          {loading && (
            <div
              style={{
                position: "absolute",
                top: 50,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: theme.zIndexes.membersManagerTableLoading,
                background: "rgba(255, 255, 255, .7)",
              }}
            >
              <CircularProgress
                style={{
                  position: "relative",
                  fontSize: "20px",
                  top: "calc(40% - 10px)",
                  left: "calc(50% - 10px)",
                }}
              />
            </div>
          )}
        </div>
      </Paper>
    );
  }
}

const styles = {
  textCenter: {
    textAlign: "center",
    alignItems: "center",
    alignContent: "center",
    justify: "center",
  },
};

MembersManagerTable = withStyles(styles)(MembersManagerTable);
const enhance = compose(
  connect(
    (state) => ({
      companies: state.companies.map,
    }),
    { getAllUsers }
  )
);
export default enhance(MembersManagerTable);
