import { defineMessages } from 'react-intl';

export default defineMessages({
  quickMenu: {
    defaultMessage: 'Quick Navigation',
    id: 'system.menu.title'
  },
  preview: {
    defaultMessage: 'Preview',
    id: 'system.buttons.preview'
  },
  cameraAccessTitle: {
    defaultMessage: 'Camera access',
    id: 'access.camera.title'
  },
  cameraExist: {
    defaultMessage: 'Camera already exist',
    id: 'sitecontrol.camera.exist'
  },
  cameraAccessMessage: {
    defaultMessage: 'Cemento App needs access to your camera to complete this action.',
    id: 'access.camera.message'
  },
  galleryAccessTitle: {
    defaultMessage: 'Media gallery access',
    id: 'access.gallery.title'
  },
  galleryAccessMessage: {
    defaultMessage: 'Cemento App needs access to your media gallery to complete this action.',
    id: 'access.gallery.message'
  },
  contactsAccessTitle: {
    defaultMessage: 'Contacts access',
    id: 'access.contacts.title'
  },
  contactsAccessMessage: {
    defaultMessage: 'Cemento App needs access to load your contact so we can find your co-workers users on Cemento.',
    id: 'access.contacts.message'
  },
  loadingMessage: {
    defaultMessage: 'Loading, Please wait...',
    id: 'system.loading.message'
  },
  anlizingMessage: {
    defaultMessage: 'Anlizing data...',
    id: 'system.anlizing.message'
  },
  savingMessage: {
    defaultMessage: 'Saving, Please wait...',
    id: 'system.saving.message'
  },
  detailedDateFormat: {
    defaultMessage: 'dddd, MM/DD/YYYY [at] h[:]mm A',
    id: 'system.date.detailedDateFormat'
  },
  longDateFormat: {
    defaultMessage: 'MM/DD/YYYY [at] h[:]mm A',
    id: 'system.date.format'
  },
  shortDateFormat: {
    defaultMessage: 'dddd, MM/DD/YYYY',
    id: 'system.date.shortFormat'
  },
  shorterDateFormat: {
    defaultMessage: 'dddd, MM/DD/YY',
    id: 'system.date.shorterDateFormat'
  },
  fullDateFormat: {
    defaultMessage: 'D/MM/YYYY',
    id: 'system.data.fullDateFormat'
  },
  onlyMonthAndDayFormat: {
    defaultMessage: 'MM/DD',
    id: 'system.date.onlyMonthAndDayFormat'
  },
  onlyDateFormat: {
    defaultMessage: 'MM/DD/YYYY',
    id: 'system.date.onlyDateFormat'
  },
  onlyDateFormatDash: {
    defaultMessage: 'MM-DD-YYYY',
    id: 'system.date.onlyDateFormatDash'
  },
  onlyTimeFormat: {
    defaultMessage: 'h[:]mm A',
    id: 'system.time.format'
  },
  tomorrow: {
    defaultMessage: 'Tomorrow',
    id: 'system.time.tomorrow'
  },
  today: {
    defaultMessage: 'Today',
    id: 'system.time.today'
  },
  yesterday: {
    defaultMessage: 'Yesterday',
    id: 'system.time.yesterday'
  },
  onlyMonthAndYeadFormat: {
    defaultMessage: 'MMM YYYY',
    id: 'system.date.onlyMonthAndYeadFormat'
  },
  fullMonthAndYear: {
    defaultMessage: 'MMMM YYYY',
    id: 'system.date.fullMonthAndYear'
  },
  specs: {
    defaultMessage: "Specs",
    id: "system.specs.title"
  },
  done: {
    defaultMessage: 'Done',
    id: 'system.buttons.done'
  },
  send: {
    defaultMessage: 'Send',
    id: 'system.buttons.send'
  },
  sendForSinging: {
    defaultMessage: 'Send for signing',
    id: 'system.buttons.sendForSinging'
  },
  next: {
    defaultMessage: 'Next',
    id: 'system.buttons.next'
  },
  skip: {
    defaultMessage: 'Skip',
    id: 'system.buttons.skip'
  },
  confirm: {
    defaultMessage: 'Confirm',
    id: 'system.buttons.confirm'
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'system.buttons.cancel'
  },
  filter: {
    defaultMessage: 'Filter',
    id: 'system.buttons.filter'
  },
  create: {
    defaultMessage: 'Create',
    id: 'system.buttons.create'
  },
  edit: {
    defaultMessage: 'Edit',
    id: 'app.buttons.edit',
  },
  save: {
    defaultMessage: 'Save',
    id: 'app.buttons.save',
  },
  saveChanges: {
    defaultMessage: "Save changes",
    id: "app.buttons.saveChanges",
  },
  changes: {
    defaultMessage: "Changes",
    id: "app.projectManager.changes"
  },
  optional: {
    defaultMessage: "Optional", // TODO: get final say on naming and translate
    id: "app.projectManager.optional"
  },
  showAsChecklistSignButton: {
    defaultMessage: "Show as checklist sign button?",
    id: "app.projectManager.showAsChecklistSignButton"
  },
  isShowSignatureValue: {
    defaultMessage: 'Should show signature image?',
    id: 'app.projectManager.isShowSignatureValue'
  },
  readOnly: {
    defaultMessage: "Read only",
    id: "app.projectManager.readOnly"
  },
  saveForAllCompaniesProject: {
    defaultMessage: "Update all projects of the current customer",
    id: "app.buttons.saveForAllCompaniesProject",
  },
  yes: {
    defaultMessage: 'Yes',
    id: 'system.buttons.yes'
  },
  no: {
    defaultMessage: 'No',
    id: 'system.buttons.no'
  },
  ok: {
    defaultMessage: 'Ok',
    id: 'system.buttons.ok'
  },
  reset: {
    defaultMessage: 'Reset',
    id: 'system.buttons.reset'
  },
  sortBy: {
    defaultMessage: 'Group by:',
    id: 'system.buttons.sortBy'
  },
  remove: {
    defaultMessage: 'Remove',
    id: 'system.buttons.remove'
  },
  add: {
    defaultMessage: "Add",
    id: "system.objects.add_"
  },
  addObject: {
    defaultMessage: "Add {contentType}",
    id: "system.objects.add"
  },
  editObject: {
    defaultMessage: "Edit {contentType}",
    id: "system.objects.edit"
  },
  imageSelectTitle: {
    defaultMessage: 'Capture/select image',
    id: 'system.imageSelect.title'
  },
  removeImageAlertTitle: {
    defaultMessage: 'Remove image',
    id: 'system.imageSelect.removeImageAlertTitle'
  },
  unsupportedFile: {
    defaultMessage: 'Unsupported file selected',
    id: 'system.files.unsupported'
  },
  linkedFile: {
    defaultMessage: 'Linked file',
    id: 'system.files.linked'
  },
  removeImageAlertMessage: {
    defaultMessage: 'Are you sure you want to remove this image?',
    id: 'system.imageSelect.removeImageAlertMessage'
  },
  start: {
    defaultMessage: 'Start',
    id: 'system.buttons.start'
  },
  unsavedAlert: {
    title: {
      defaultMessage: 'Unsaved changes',
      id: 'system.unsavedAlert.title'
    },
    content: {
      defaultMessage: 'There are unsaved changes. If you leave this page, you\'ll lose your changes. Do you really want to leave the page?',
      id: 'system.unsavedAlert.content'
    },
    yes: {
      defaultMessage: 'Discard Changes',
      id: 'system.unsavedAlert.button.yes'
    },
    no: {
      defaultMessage: 'Continue Editing',
      id: 'system.unsavedAlert.button.no'
    }
  },
  confirmCancelChangesAlert: {
    title: {
      defaultMessage: 'Unsaved changes',
      id: 'system.confirmCancelChangesAlert.title'
    },
    content: {
      defaultMessage: 'Are you sure you wish to cancel the modifications made?',
      id: 'system.confirmCancelChangesAlert.content'
    },
    yes: {
      defaultMessage: 'Discard Changes',
      id: 'system.confirmCancelChangesAlert.button.yes'
    },
    no: {
      defaultMessage: 'Continue Editing',
      id: 'system.confirmCancelChangesAlert.button.no'
    }
  },
  confirmSaveChangesAlert: {
    title: {
      defaultMessage: 'Save changes',
      id: 'system.confirmSaveChangesAlert.title'
    },
    content: {
      defaultMessage: 'Are you sure you wish to save the changes?',
      id: 'system.confirmSaveChangesAlert.content'
    },
    yes: {
      defaultMessage: 'Yes, I\'m sure',
      id: 'system.confirmSaveChangesAlert.button.yes'
    },
    no: {
      defaultMessage: 'No, continue editting',
      id: 'system.confirmSaveChangesAlert.button.no'
    }
  },
  signOutAlert: {
    title: {
      defaultMessage: 'Sign Out',
      id: 'system.signOutAlert.title'
    },
    content: {
      defaultMessage: 'Are you sure you want to sign out from Cemento',
      id: 'system.signOutAlert.content'
    },
    yes: {
      defaultMessage: 'Yes, I\'m sure',
      id: 'system.signOutAlert.button.yes'
    },
    no: {
      defaultMessage: 'No, take me back',
      id: 'system.signOutAlert.button.no'
    }
  },
  emptyResult: {
    defaultMessage: 'No result found',
    id: 'system.list.empty'
  },
  shareWith: {
    defaultMessage: 'Share with',
    id: 'system.list.shareWith'
  },
  close: {
    defaultMessage: 'Close',
    id: 'system.buttons.close'
  },
  importExcel: {
    defaultMessage: 'Import from Excel',
    id: 'projectManager.inputLabels.import'
  },
  exportExcel: {
    defaultMessage: 'Export to Excel',
    id: 'projectManager.inputLabels.export'
  },
  badgeNewPost: {
    defaultMessage: 'New Post',
    id: 'system.badge.newPost'
  },
  badgeNewPosts: {
    defaultMessage: '{counter} New Posts',
    id: 'system.badge.newPosts'
  },
  badgeNewComment: {
    defaultMessage: 'New Comment',
    id: 'system.badge.newComment'
  },
  badgeNewComments: {
    defaultMessage: '{counter} New Comments',
    id: 'system.badge.newComments'
  },
  uploading: {
    defaultMessage: 'Uploading',
    id: 'system.images.uploading'
  },
  updating: {
    defaultMessage: 'Updating, please wait...',
    id: 'system.updating.updating'
  },
  startingFileUpload: {
    defaultMessage: 'Starting file upload',
    id: 'system.updating.startingFileUpload'
  },
  fileUploadedCompleted: {
    defaultMessage: 'File upload completed',
    id: 'system.updating.fileUploadedCompleted'
  },
  selectCountryCode: {
    defaultMessage: 'Please select your country code',
    id: 'system.countryCode.select'
  },
  code_49: {
    defaultMessage: '+49 (Germany)',
    id: 'system.countryCode.49'
  },
  code_507: {
    defaultMessage: '+507 (Panama)',
    id: 'system.countryCode.507'
  },
  code_972: {
    defaultMessage: '+927 (Israel)',
    id: 'system.countryCode.972'
  },
  code_1: {
    defaultMessage: '+1 (USA)',
    id: 'system.countryCode.1'
  },
  allProject: {
    defaultMessage: 'Entire project',
    id: 'system.projects.all'
  },
  switchBuilding: {
    defaultMessage: 'Switch building',
    id: 'system.projects.switchBuilding'
  },
  clearAll: {
    defaultMessage: 'Clear all',
    id: 'system.clear.all'
  },
  clear: {
    defaultMessage: 'Clear',
    id: 'system.clear.Clear'
  },
  clearSelection: {
    defaultMessage: 'Clear selection',
    id: 'system.clear.clearSelection'
  },
  fillAll: {
    defaultMessage: 'Please fill all fields',
    id: 'system.fillAll.fields'
  },
  recommended: {
    defaultMessage: 'Recommended',
    id: 'system.select.recommended'
  },
  photosCounter: {
    defaultMessage: '{counter} photos',
    id: 'system.photos.counter'
  },
  general: {
    defaultMessage: 'General',
    id: 'system.general.general'
  },
  noValue: {
    defaultMessage: '---',
    id: 'system.general.noValue'
  },
  connectionError: {
    defaultMessage: 'Connection error',
    id: 'system.general.connectionError'
  },
  invalidDetails: {
    defaultMessage: 'Invalid details',
    id: 'system.general.invalidDetails'
  },
  invalidDetailsDescription: {
    defaultMessage: 'Invalid details, please correct relevant fields:\n\n{errors}',
    id: 'system.general.invalidDetailsDescription'
  },
  saveFailed: {
    defaultMessage: "Save failed",
    id: "system.general.saveFailed"
  },
  savedSuccessfully: {
    defaultMessage: "Saved successfully",
    id: "system.general.savedSuccessfully"
  },
  objectSavedSuccessfully: {
    defaultMessage: "{objectName} saved successfully",
    id: "system.general.objectSavedSuccessfully"
  },
  deletedSuccessfully : {
    defaultMessage: "Deleted successfully",
    id: "system.general.deletedSuccessfully"
  },
  objectDeletedSuccessfully : {
    defaultMessage: "{objectName} deleted successfully",
    id: "system.general.objectDeletedSuccessfully"
  },
  error: {
    defaultMessage: 'There was an error, please try again',
    id: 'system.general.error'
  },
  wizardSteps: {
    defaultMessage: 'Step {counter}: {description}',
    id: 'system.general.wizardSteps'
  },
  checkAndSubmit: {
    defaultMessage: 'Check & Submit',
    id: 'system.general.checkAndSubmit'
  },
  shareExternal: {
    defaultMessage: 'Export image',
    id: 'system.general.shareExternal'
  },
  search: {
    defaultMessage: "Search",
    id: 'system.general.search'
  },
  continue: {
    defaultMessage: "Continue",
    id: 'system.general.continue'
  },
  manage: {
    projectCustomer: {
      defaultMessage: "Customer",
      id: "system.manage.projectCustomer"
    },
    formsManager: {
      defaultMessage: "Forms manager",
      id: "system.manage.formsManager"
    },
    changesHaveNotBeenSaved: {
      defaultMessage: "Changes have not been saved, are you sure you wish to continue?",
      id: "system.manage.changesHaveNotBeenSaved"
    },
    leaveWithoutSave: {
      defaultMessage: "Leave without save",
      id: "system.manage.leaveWithoutSave"
    },
    checklistsManager: {
      defaultMessage: "Checklists Manager",
      id: 'system.manage.checklistsManager'
    },
    propertiesManager: {
      defaultMessage: "Properties Manager",
      id: 'system.manage.propertiesManager'
    },
    excelImport: {
      defaultMessage: "Import from Excel",
      id: 'system.manage.import'
    },
    excelExport: {
      defaultMessage: "Export to Excel",
      id: 'system.manage.export'
    },
    load: {
      defaultMessage: "Load project data...",
      id: 'system.manage.load'
    },
    companiesTypes: {
      defaultMessage: "Companies - Types in project",
      id: 'system.manage.companiesTypes'
    },
    companiesPermissions: {
      defaultMessage: "Companies - Permissions",
      id: 'system.manage.companiesPermissions'
    },
    checklistsInspector: {
      defaultMessage: "Checklsits - Inspector",
      id: 'system.manage.checklistsInspector'
    },
    checklistsVerifier: {
      defaultMessage: "Checklsits - Verifier",
      id: 'system.manage.checklistsVerifier'
    },
    checklistsPartial: {
      defaultMessage: "Checklsits - Partial",
      id: 'system.manage.checklistsPartial'
    },
    checklistsIrrelevant: {
      defaultMessage: "Checklsits - Irrelevant",
      id: 'system.manage.checklistsIrrelevant'
    },
    propertiesGroups: {
      defaultMessage: "Properties - Groups settings",
      id: 'system.manage.propertiesGroups'
    },
    projectMembers: {
      defaultMessage: "Project members",
      id: 'system.manage.projectMembers'
    },
    projectSettings: {
      defaultMessage: "Project settings",
      id: 'system.manage.projectSettings'
    },
    projectTimeZone: {
      defaultMessage: "Time zone",
      id: "system.manage.projectTimeZone"
    },
    projectLanguage: {
      defaultMessage: "Language",
      id: "system.manage.projectLanguage"
    },
    companyName: {
      defaultMessage: "Company name",
      id: "system.manage.companyName"
    },
    projectStartingTs: {
      defaultMessage: "Project starting time",
      id: "system.manage.projectStartingTs"
    },
    showOnlyWhenAdmin: {
      defaultMessage: "Show on admin",
      id: "system.manage.showOnlyWhenAdmin"
    },
    showQualityTab: {
      defaultMessage: "Enable Quality",
      id: "system.manage.showQualityTab"
    },
    showSafetyTab: {
      defaultMessage: "Enable Safety",
      id: "system.manage.showSafetyTab"
    },
    showDailyReportTab: {
      defaultMessage: "Enable Daily Report",
      id: "system.manage.showDailyReportTab"
    },
    showSpecTab: {
      defaultMessage: "Enable Specification",
      id: "system.manage.showSpecTab"
    },
    projectImage: {
      defaultMessage: "Project image",
      id: "system.manage.projectImage"
    },
    companyLogo: {
      defaultMessage: "Company logo",
      id: "system.manage.companyLogo"
    },
    delete: {
      defaultMessage: "Delete",
      id: 'system.manage.delete'
    },
    sureRemoveObject: {
      defaultMessage: 'are you sure you want to remove {objectName}?',
      id: 'system.manage.sureRemoveObject'
    },
    duplicate: {
      defaultMessage: "Duplicate",
      id: 'system.manage.duplicate'
    },
    saving: {
      defaultMessage: "Saving changes...",
      id: 'system.manage.saving'
    },
    importMode: {
      defaultMessage: "Import mode",
      id: 'system.manage.importMode'
    },
    parentsMode: {
      defaultMessage: "Edit categories",
      id: 'system.manage.parentsMode'
    },
    childsMode: {
      defaultMessage: "Edit items",
      id: 'system.manage.childsMode'
    },
    addLocationTitle: {
      defaultMessage: "Add\\Remove location",
      id: 'system.manage.addLocationTitle'
    },
    addLocationMessage: {
      defaultMessage: "Do you wish to apply changes to all checklist items?",
      id: 'system.manage.addLocationMessage'
    },
    hideChecklistTitleOnIssueDesc: {
      defaultMessage: "Hide checklist title on records descriptions",
      id: 'system.manage.hideChecklistTitleOnIssueDesc'
    },
    enableDistributionList: {
      defaultMessage: "Enable distribution list",
      id: 'system.manage.enableDistributionList'
    },
  },
  deleteChecklistAlert: {
    title: {
      defaultMessage: "Delete checklist",
      id: "system.checklistManager.deleteChecklistAlert.title"
    },
    content: {
      defaultMessage: "If you delete this checklist, all of its items as well as potential duplications will be deleted. Are you sure you wish to proceed?",
      id: "system.checklistManager.deleteChecklistAlert.message"
    },
  },
  deleteChecklistItemAlert: {
    title: {
      defaultMessage: "Delete checklist item",
      id: "system.checklistManager.deleteChecklistItemAlert.title"
    },
    content: {
      defaultMessage: "Are you sure you wish to delete this item?",
      id: "system.checklistManager.deleteChecklistItemAlert.message"
    },
  },
  deleteMemberProjectAlert:{
    title: {
      defaultMessage: "Delete project",
      id: "system.checklistManager.deleteMemberProjectAlert.title"
    },
    content: {
      defaultMessage: "Are you sure you wish to remove this project?",
      id: "system.checklistManager.deleteMemberProjectAlert.message"
    },
    extraWarning: {
      defaultMessage: 'This project have {num} members',
      id: "system.checklistManager.deleteMemberProjectAlert.extraWarning"
    }
  },
  uncheckLastRemainingLocation: {
    defaultMessage: "Unable to perform this action: At least one location must be selected. If you wish to not display the item, you may delete it.",
    id: "system.checklistManager.uncheckLastRemainingLocation"
  },
  invalidChangesChecklistManager: {
    defaultMessage: "The changes could not be saved because of the following errors:\n\n{errors}",
    id: "system.checklistManager.invalidChangesChecklistManager"
  },
  errorOnImport: {
    defaultMessage: "An error occured while importing the data: {error}",
    id: "system.errors.errorOnImport"
  },
  importError: {
    defaultMessage: "Error on import",
    id: "system.errors.importError"
  },
  excel: {
    foundDuplicatedId: {
      defaultMessage: 'Found duplicated ID ("{id}") in row {rowNumber}. This ID either already exists in the system or was used twice in this file.',
      id: "system.excel.foundDuplicatedId"
    },
    couldNotFindId: {
      defaultMessage: 'Found ID with no match in the system on row {rowNumber}. If you meant to create a new item, please make sure to leave ID cell empty.',
      id: "system.excel.couldNotFindId"
    },
    missingRequiredField: {
      defaultMessage: 'Missing required field {fields} for "{description}" on row {rowNumber}.',
      id: "system.excel.missingRequiredField"
    },
    emptyRow: {
      defaultMessage: "Row {rowNumber} was found empty",
      id: "system.excel.emptyRow"
    },
    wrongValueType: {
      defaultMessage: 'Found wrong value type in column "{name}" row {rowNumber}. Should have been a {types}.',
      id: "system.excel.wrongValueType"
    },
    missingColumn: {
      defaultMessage: 'Could not find column "{name}"',
      id: "system.excel.missingColumn"
    },
    types: {
      number: {
        defaultMessage: 'number',
        id: "system.excel.types.number"
      },
      boolean: {
        defaultMessage: 'boolean',
        id: "system.excel.types.boolean"
      },
      string: {
        defaultMessage: 'string',
        id: "system.excel.types.string"
      },
    },
  },
  moreThanOneLocation: {
    defaultMessage: 'More than one location type was selected on row {rowNumber}',
    id: "system.checklistManager.excelError.moreThanOneLocation"
  },
  noCorrespondingStage: {
    defaultMessage: 'Checklist "{description}" on row {rowNumber} was set without a corresponding stage',
    id: "system.checklistManager.excelError.noCorrespondingStage"
  },
  noCorrespondingChecklist: {
    defaultMessage: 'Checklist item "{description}" row {rowNumber} was set without a corresponding checklist',
    id: "system.checklistManager.excelError.noCorrespondingChecklist"
  },
  newStage: {
    defaultMessage: 'New stage',
    id: "system.checklistManager.newStage"
  },
  newChecklist: {
    defaultMessage: 'New checklist',
    id: "system.checklistManager.newChecklist"
  },
  newItem: {
    defaultMessage: 'New item',
    id: "system.checklistManager.newItem"
  },
  newProperty: {
    defaultMessage: 'New property',
    id: "system.checklistManager.newProperty"
  },
  newOption: {
    defaultMessage: 'New option',
    id: "system.checklistManager.newOption"
  },
  unableToSetDuplicatableFalse: {
    defaultMessage: "You cannot perform this action because this checklist already has duplications",
    id: "system.checklistManager.unableToSetDuplicatableFalse"
  },
  addNewStage: {
    defaultMessage: 'Add new stage',
    id: 'system.checklistManager.addNewStage'
  },
  addNewChecklist: {
    defaultMessage: "Add new checklist",
    id: "system.checklistManager.addNewChecklist"
  },
  addNewChecklistItem: {
    defaultMessage: "Add new checklist item",
    id: "system.checklistManager.addNewChecklistItem"
  },
  addNewProperty: {
    defaultMessage: 'Add new property',
    id: 'system.checklistManager.addNewProperty'
  },
  linkExistingProperty: {
    defaultMessage: 'Link existing property',
    id: 'system.checklistManager.linkExistingProperty'
  },
  addNewOption: {
    defaultMessage: 'Add new option',
    id: 'system.checklistManager.addNewOption'
  },
  description: {
    defaultMessage: "Description",
    id: "system.checklistManager.description"
  },
  duplicatable: {
    defaultMessage: "Duplicatable",
    id: "system.checklistManager.duplicatable"
  },
  enableDistributionList: {
    defaultMessage: "Enable email distribution list",
    id: "system.checklistManager.enableDistributionList"
  },
  periodic: {
    defaultMessage: "Periodic",
    id: "system.checklistManager.periodic"
  },
  extraDescription: {
    defaultMessage: "Extra info",
    id: "system.checklistManager.extraDescription"
  },
  trade: {
    defaultMessage: "Trade",
    id: "system.checklistManager.trade"
  },
  period: {
    defaultMessage: "Period",
    id: "system.checklistManager.period"
  },
  descriptionRequired: {
    defaultMessage: "Description required",
    id: "system.checklistManager.descriptionRequired"
  },
  drawingRequired: {
    defaultMessage: "Drawing required",
    id: "system.checklistManager.drawingRequired"
  },
  fileRequired: {
    defaultMessage: "Attachment required",
    id: "system.checklistManager.fileRequired"
  },
  imageRequired: {
    defaultMessage: "Image required",
    id: "system.checklistManager.imageRequired"
  },
  companyReadPermissions: {
    defaultMessage: "Company read permissions",
    id: "system.checklistManager.companyReadPermissions"
  },
  enablePartialButton: {
    defaultMessage: "Enable partial button",
    id: "system.checklistManager.enablePartialButton"
  },
  enableIrrelevantButton: {
    defaultMessage: "Enable irrelevant button",
    id: "system.checklistManager.enableIrrelevantButton"
  },
  weight: {
    defaultMessage: "Weight",
    id: "system.checklistManager.weight"
  },
  includeInGrade: {
    defaultMessage: 'Include in grade',
    id: "system.checklistManager.includeInGrade"
  },
  title: {
    defaultMessage: 'Title',
    id: "system.checklistManager.title"
  },
  option: {
    defaultMessage: 'Option',
    id: "system.checklistManager.option"
  },
  type: {
    defaultMessage: 'Type',
    id: "system.checklistManager.type"
  },
  isMulti: {
    defaultMessage: 'Multiple select',
    id: "system.checklistManager.isMulti"
  },

  isMissingLocation: {
    defaultMessage: 'is missing location',
    id: "system.checklistManager.isMissingLocation"
  },
  itemIsMissingTrade: {
    defaultMessage: 'is missing trade',
    id: "system.checklistManager.isMissingLocation"
  },
  isEmpty: {
    defaultMessage: 'is empty',
    id: "system.checklistManager.isMissingLocation"
  },
  selectType: {
    defaultMessage: 'Select type',
    id: "system.selectType"
  },
  selectSubject: {
    defaultMessage: 'Select subject',
    id: 'system.selectSubject'
  },
  properties: {
    defaultMessage: 'Properties',
    id: 'system.properties'
  },
  form: {
    defaultMessage: "Form",
    id: "system.form"
  },
  refresh: {
    defaultMessage: 'Refresh',
    id: 'system.refresh'
  },
  unableToEditAlert: {
    title: {
      defaultMessage: 'Update detected',
      id: 'system.unableToEditAlert.title'
    },
    message: {
      defaultMessage: 'Unable to edit',
      id: 'system.unableToEditAlert.message'
    },
  },
  fileCount: {
    defaultMessage: "{filesCounter} file(s)",
    id: 'system.inputField.fileCount'
  },
  notSupportedTitle: {
    defaultMessage: 'This browser is not compatible',
    id: 'system.browser.notSupportedTitle'
  },
  notSupportedDetails: {
    defaultMessage: "Please use 'Chrome' browser in order to use Cemento",
    id: 'system.browser.notSupportedDetails'
  },
  clickHere: {
    defaultMessage: "click here",
    id: 'system.clickHere'
  },
  selectPlaceholder: {
    defaultMessage: "Select...",
    id: 'system.selectPlaceholder'
  },
  userOptions: {
    defaultMessage: "User options",
    id: "system.userOptions"
  },
  offlineMode: {
    defaultMessage: "Working in offline mode",
    id: 'system.offlineMode'
  },
  none: {
    defaultMessage: "None",
    id: 'system.none'
  },
  advanced: {
    defaultMessage: "Advanced",
    id: 'system.advanced'
  },
  basic: {
    defaultMessage: "Basic",
    id: 'system.basic'
  },
  tryAgain: {
    defaultMessage: 'Try again',
    id: 'system.tryAgain'
  },
  cancelOperation: {
    defaultMessage: 'Are you sure you want to cancel?',
    id: 'system.cancelOperation'
  },
  errorOnSave: {
    defaultMessage: 'An error occured while trying to save. The changes were not saved. Please try again later.',
    id: 'system.error.errorOnSave'
  },
  existsCompanyName: {
    defaultMessage: 'There is already a company wite this name. Please use different name.',
    id: 'system.error.existsCompanyName'
  },
  importSuccess: {
    defaultMessage: 'Data was imported successfully.',
    id: 'system.importSuccess',
  },
  approveAndSign: {
    defaultMessage: 'To approve, please sign here',
    id: 'system.approveAndSign',
  },
  cementoSign: {
    defaultMessage: 'Sign using cemento',
    id: 'system.cementoSign',
  },
  scanSign: {
    defaultMessage: 'Scan a document',
    id: 'system.scanSign',
  },
  en: {
    defaultMessage: 'English',
    id: 'system.en',
  },
  fr: {
    defaultMessage: 'French',
    id: 'system.fr',
  },
  ar: {
    defaultMessage: 'Arabic',
    id: 'system.ar',
  },
  he: {
    defaultMessage: 'Hebrew',
    id: 'system.he',
  },
  es: {
    defaultMessage: 'Spanish',
    id: 'system.es',
  },
  ru: {
    defaultMessage: 'Russian',
    id: 'system.ru',
  },
  zh: {
    defaultMessage: 'Chinese',
    id: 'system.zh',
  },
  ti: {
    defaultMessage: 'Tigrinya',
    id: 'system.ti',
  },
  ro: {
    defaultMessage: 'Romanian',
    id: 'system.ro',
  },
  tr: {
    defaultMessage: 'Turkish',
    id: 'system.tr',
  },
  language: {
    defaultMessage: 'Language',
    id: 'system.language',
  },
  toolTips: {
    unEditableField: {
      defaultMessage: "The field cannot be edited",
      id: 'system.toolTips.unEditableField'
    },
  },
  isMandatory: { 
    defaultMessage: 'Is mandatory',
    id: 'system.mandatory'
  },
  locationsCounter: {
    defaultMessage: 'Quantity of locations in a group',
    id: 'system.locationsGroupManager.locationsCounter'
  },
  apply: { 
    defaultMessage: 'Apply',
    id: 'system.apply'
  },
  noCompany: { 
    defaultMessage: 'No company',
    id: 'system.noCompany',
  },
  noEquipmentType: { 
    defaultMessage: 'No equipment type',
    id: 'system.noEquipmentType',
  },
  status: {
    defaultMessage: 'status',
    id: 'system.status',
  },
  camerasMessages: {
    cameras: {
      defaultMessage: 'Cameras',
      id: 'system.camerasMessages.cameras',
    },
    cameraConfigurations: {
      defaultMessage: 'Camera Configurations',
      id: 'system.camerasMessages.cameraConfigurations',
    },
    syncProjectSuccess: {
      defaultMessage: 'Start Sync Employees To Cameras',
      id: 'system.camerasMessages.syncProjectSuccess',
    },
    syncProjectQuestion: {
      defaultMessage: 'Are you sure You Want To Sync Project?',
      id: 'system.camerasMessages.syncProjectQuestion',
    },
    lastKeepAlive: {
      defaultMessage: 'Last Keep Alive',
      id: 'system.camerasMessages.lastKeepAlive',
    },
    password: {
      defaultMessage: 'Password',
      id: 'system.camerasMessages.password',
    },
    username: {
      defaultMessage: 'Username',
      id: 'system.camerasMessages.username',
    },
    controlPanel: {
      defaultMessage: 'Control Panel',
      id: 'system.camerasMessages.controlPanel',
    },
    refresh: {
      defaultMessage: 'Refresh',
      id: 'system.camerasMessages.refresh',
    },
    disabled: {
      defaultMessage: 'Disabled',
      id: 'system.camerasMessages.disabled',
    },
    reboot: {
      defaultMessage: 'Reboot Camera',
      id: 'system.camerasMessages.reboot',
    },
    statusCheck: {
      defaultMessage: 'Check Camera Status',
      id: 'system.camerasMessages.statusCheck',
    },
    syncEmployees: {
      defaultMessage: 'Sync employees',
      id: 'system.camerasMessages.syncEmployees',
    },
    syncProject: {
      defaultMessage: 'Sync project',
      id: 'system.camerasMessages.syncProject',
    },
    syncEmployee: {
      defaultMessage: 'Sync employee',
      id: 'system.camerasMessages.syncEmployee',
    },
    active: {
      defaultMessage: 'Active',
      id: 'system.camerasMessages.active',
    },
    notActive: {
      defaultMessage: 'Not Active',
      id: 'system.camerasMessages.notActive',
    },
    notSynchronized: {
      defaultMessage: 'Not synchronized',
      id: 'system.camerasMessages.notSynchronized',
    },
    noAccessToCamera: {
      defaultMessage: 'No access to the camera',
      id: 'system.camerasMessages.noAccessToCamera',
    },
    expiredCertification: {
      defaultMessage: 'Expired certification',
      id: 'system.camerasMessages.expiredCertification',
    },
    missFullName: {
      defaultMessage: 'Missing full name',
      id: 'system.camerasMessages.missFullName',
    },
    missPhoto: {
      defaultMessage: 'Missing photo',
      id: 'system.camerasMessages.missPhoto',
    },
    notAllowed: {
      defaultMessage: 'Not allowed to access the site',
      id: 'system.camerasMessages.notAllowed',
    },
    syncWarning: {
      defaultMessage: 'Are you sure? This action will disable the camera for about an hour. Not recommended',
      id: 'system.camerasMessages.syncWarning',
    },
    syncEmployeeWarning: {
      defaultMessage: 'Are you sure you want to sync this employee? This action will disable the camera for few minutes',
      id: 'system.camerasMessages.syncEmployeeWarning'
    },
    rebootWarning: {
      defaultMessage: 'Are you sure you want to reboot the camera?',
      id: 'system.camerasMessages.rebootWarning',
    },
    rebootSuccess: {
      defaultMessage: 'The camera was rebooted successfully',
      id: 'system.camerasMessages.rebootSuccess',
    },
    lastSignal: {
      defaultMessage: 'Last signal from camera: ',
      id: 'system.camerasMessages.lastSignal',
    },
    totalEmployeesCount: {
      defaultMessage: 'Total employees in the camera: ',
      id: 'system.camerasMessages.totalEmployeesCount',
    },
    noData: {
      defaultMessage: 'No data',
      id: 'system.camerasMessages.noData',
    }
  },
  members: {
    defaultMessage: 'Members',
    id: 'system.members',
  },
  tapToSelect: {
    defaultMessage: 'Tap to select',
    id: 'system.tapToSelect',
  },
  tapToFill: {
    defaultMessage: 'Tap to fill',
    id: 'system.tapToFill',
  },
  archive: {
    defaultMessage: 'Archives',
    id: 'system.archive',
  },
  errors: {
    title: {
      defaultMessage: 'An error occured',
      id: 'system.errors.title',
    },
    fetchingErrorContent: {
      defaultMessage: 'An error has occured while fetching the data. Please try again later.',
      id: 'system.errors.fetchingErrorContent',
    }
  },
  syncing: {
    defaultMessage: 'syncing',
    id: 'system.syncing'
  },
  unableToPerformOperation: {
    title: {
      defaultMessage: 'Unable to perform operation',
      id: 'system.unableToPerformOperation.title'
    },
    contentWithCode: {
      defaultMessage: 'Something went wrong while trying to perform this operation.\nPlease try again later. (code: {errorCode})',
      id: 'system.unableToPerformOperation.contentWithCode'
    },
    contentWithoutCode: {
      defaultMessage: 'Something went wrong while trying to perform this operation.\nPlease try again later.',
      id: 'system.unableToPerformOperation.contentWithoutCode'
    },
  },
  notAllowedOffline: {
    title: {
      defaultMessage: 'Unable to perform operation',
      id: 'system.notAllowedOffline.title',
    },
    content: {
      defaultMessage: 'This operation cannot be performed while offline.\nPlease make sure you have an internet connection and try again.',
      id: 'system.notAllowedOffline.content'
    },
  },
  checkingRepository: {
    defaultMessage: 'Scanning repository, Please wait...',
    id: 'system.checkingRepository'
  },
  shouldAddCompanyToProject: {
    title: { 
      defaultMessage: 'Company does not exist in this project',
      id: 'system.shouldAddCompanyToProject.title'
    },
    content: { 
      defaultMessage: 'This company is not part of the project, do you wish to add it?',
      id: 'system.shouldAddCompanyToProject.content'
    },
  },
  employeeAlreadyExist: {
    title: {
      defaultMessage: 'Employee already present in project',
      id: 'system.employeeAlreadyExist.title'
    },
    content: {
      defaultMessage: 'This employee was found to already exist in the project. Do you wish to be redirected to him?',
      id: 'system.employeeAlreadyExist.content'
    },
  },
  noSignaturePermission: {
    defaultMessage: 'You are not authorized to sign',
    id: 'system.noSignaturePermission'
  },
  goBack: {
    defaultMessage: 'Go back',
    id: 'system.goBack'
  },
  latest: {
    defaultMessage: 'Current',
    id: 'system.latest'
  },
  version: {
    defaultMessage: 'Version',
    id: 'system.version'
  },
  nothingToDisplay: {
    defaultMessage: 'Nothing to display',
    id: 'system.nothingToDisplay'
  },
  select: {
    defaultMessage: 'Select',
    id: 'system.select'
  },
  addNew: {
    defaultMessage: 'Add new',
    id: 'system.addNew'
  },
  showMore: {
    defaultMessage: 'Show more',
    id: 'system.showMore'
  },
  showLess: {
    defaultMessage: 'Show less',
    id: 'system.showLess'
  },
  to: {
    defaultMessage: 'to',
    id: 'system.to'
  },
  ordinalNumber: {
    defaultMessage: '{number, selectordinal, one {#st} two {#nd} few {#rd} other {#th}}',
    id: 'system.ordinalNumber'
  },
  monthPickerTitle:{
    defaultMessage: 'Select month',
    id: 'system.monthPickerTitle'
  },
  updateVersion:{
    title:{
      defaultMessage: 'This version is no longer Supported.',
      id: 'system.updateVersion.title'
    },
    subTitle:{
      defaultMessage: 'Please update Cemento app to the latest version.',
      id: 'system.updateVersion.subTitle'
    },
    update:{
      defaultMessage: 'Update',
      id: 'system.updateVersion.update'
    },
    skip:{
      defaultMessage: 'Skip',
      id: 'system.updateVersion.skip'
    }
  }
});
