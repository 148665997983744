import { Record } from '../transit';

const DailyReportSummary = Record({
  id: null,
  dateTS: null,
  updatedTS: null,
  type: null,
  approver: null,
  images: null,  
  subsCounters: null,
  postsCounters: null,
  status: null,
  isDeleted: null
}, 'dailyReportSummary');

export default DailyReportSummary;