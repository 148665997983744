import React from "react";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import FormManager from "./FormsManager";

class FormManagerWrapper extends React.Component {
  getPageLang = () => {
    const { selectedProjectId, detailedProjects } = this.props;
    const defaultLang = "en";
    let project = detailedProjects.getNested([selectedProjectId]) || {};
    let projectToManage = project.toJS
      ? project.toJS()
      : Object.assign({}, project);
    let lang = projectToManage.getNested(["lang"], defaultLang) || defaultLang;

    return lang;
  };

  render() {
    const {
      configurations,
      selectedProjectId,
      checklists,
      stages,
      setHeaderParams
    } = this.props;
    let lang = this.getPageLang();
    return (
      <FormManager
        setHeaderParams={setHeaderParams}
        configurations={configurations}
        checklists={checklists}
        stages={stages && stages.toJS ? stages.toJS() : stages}
        lang={lang}
      />
    );
  }
}

const enhance = compose(
  connectContext(ProjectContext.Consumer)
  );

export default enhance(FormManagerWrapper);
