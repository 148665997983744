import { defineMessages } from 'react-intl';

export default defineMessages({
  newPostTitle: {
    defaultMessage: 'New Record',
    id: 'post.newPost.title'
  },
  newIssuetitle: {
    defaultMessage: 'New Task',
    id: 'post.newIssue.title'
  },
  shareWith: {
    defaultMessage: 'Share with',
    id: 'posts.newPost.shareWith'
  }, 
  shareWithAll: {
    defaultMessage: 'All project',
    id: 'posts.newPost.shareWithAll'
  }, 
  shareWithRelevantCompanies: {
    defaultMessage: 'Relevant companies',
    id: 'posts.newPost.shareWithRelevantCompanies'
  }, 
  shareWithMutliSelectTitle: {
    defaultMessage: 'Shared with',
    id: 'posts.newPost.shareWithMutliSelectTitle'
  }, 
  shareWithTitle: {
    defaultMessage: 'Share With',
    id: 'posts.newPost.shareWithTitle'
  }, 
  shareWithSection: {
    defaultMessage: 'All',
    id: 'posts.newPost.productMembers'
  }, 
  postPlaceholder: {
    defaultMessage: 'Describe the post here',
    id: 'posts.newPost.placeholder'
  }, 
  post: {
  	defaultMessage: 'Send',
    id: 'posts.newPost.post'
  }, 
  repostButton: {
    defaultMessage: 'Send and start new',
    id: 'posts.newPost.repost'
  }, 
  savePost: {
  	defaultMessage: 'Save',
    id: 'posts.newPost.savePost'
  }, 
  saveAndRepost: {
    defaultMessage: 'Save and start new',
    id: 'posts.newPost.saveAndRepost'
  }, 
  images: {
    defaultMessage: 'Images',
    id: 'posts.newPost.images'
  }, 
  cameraButton: {
    defaultMessage: 'Add Photo',
    id: 'posts.newPost.cameraButton'
  }, 
  markLocationButton: {
    defaultMessage: 'Place a marker on the drawing',
    id: 'posts.newPost.markLocationButton'
  },   
  convertToIssue: {
    defaultMessage: 'Convert to task',
    id: 'posts.newPost.convertToIssue'
  }, 
  mandatoryFieldsTitle: {
    defaultMessage: 'Missing fields',
    id: 'posts.newPost.mandatoryFieldsTitle'
  },
  mandatoryFields: {
    img: {
      defaultMessage: 'You have to take a picture before save',
      id: 'posts.newPost.mandatoryFields.img'
    },
    desc: {
      defaultMessage: 'You have to write a description before save',
      id: 'posts.newPost.mandatoryFields.desc'
    },
    drawing: {
      defaultMessage: 'You have to attach a drawing before save',
      id: 'posts.newPost.mandatoryFields.drawing'
    },
    file: {
      defaultMessage: 'You have to attach a file before save',
      id: 'posts.newPost.mandatoryFields.file'
    },
    assignee: {
      defaultMessage: 'You have add an assignee for this issue',
      id: 'posts.newPost.mandatoryFields.assignee'
    },
    general: {
      defaultMessage: 'You have to write a description or add a picture before save',
      id: 'posts.newPost.mandatoryFields.general'
    },
  },
  discardPostTitle: {
    defaultMessage: 'Discard',
    id: 'posts.newPost.discardTitle'
  },
  discardPostMessage: {
    defaultMessage: 'Are you sure you want to discard your changes? This task will not be saved',
    id: 'posts.newPost.discardMessage'
  },
  title_0: {
    defaultMessage: 'Create image',
    id: 'posts.newPost.title_0'
  }, 
  title_1: {
    defaultMessage: 'Add content',
    id: 'posts.newPost.title_1'
  }, 
  title_2: {
    defaultMessage: 'Select location',
    id: 'posts.newPost.title_2'
  }, 
  title_3: {
    defaultMessage: 'Select trade',
    id: 'posts.newPost.title_3'
  }, 
  title_4: {
    defaultMessage: 'Select assigned member',
    id: 'posts.newPost.title_4'
  }, 
  title_5: {
    defaultMessage: 'Select due date',
    id: 'posts.newPost.title_5'
  }, 
  title_6: {
    defaultMessage: 'Summary',
    id: 'posts.newPost.title_6'
  }, 
  title_7: {
    defaultMessage: 'Select category',
    id: 'posts.newPost.title_7'
  }, 
  zoomIn: {
    defaultMessage: 'Zoom in',
    id: 'posts.newPost.zoomIn'
  }, 
  drawOnMap: {
    defaultMessage: 'Draw on map',
    id: 'posts.newPost.drawOnMap'
  },
  selectDrawing: {
    defaultMessage: 'Select drawing',
    id: 'posts.newPost.selectDrawing'
  },
  processingImages: {
    defaultMessage: 'Processing images',
    id: 'posts.newPost.processingImages'
  },
  addToReport: {
    defaultMessage: 'Add to daily log',
    id: 'posts.newPost.addToReport'
  },
  trade: {
    defaultMessage: 'Trade',
    id: 'posts.newPost.trade'
  },
  subCategory: {
    defaultMessage: 'Category',
    id: 'posts.newPost.subCategory'
  },
  fine: {
    defaultMessage: 'Penalty Fine',
    id: 'posts.newPost.penalty'
  },
  fineAmount: {
    defaultMessage: 'Penalty Fine Amount',
    id: 'posts.newPost.penaltyAmount'
  },
  fineReceiver: {
    defaultMessage: 'Fine Receiver',
    id: 'posts.newPost.fineReceiver'
  },
  fineDescription: {
    defaultMessage: 'Reasons',
    id: 'posts.newPost.fineDescription'
  },
  fineDescriptionPlaceholder: {
    defaultMessage: 'Describe the incident',
    id: 'posts.newPost.fineDescriptionPlaceholder'
  },
  attachments: {
    defaultMessage: 'Attachments',
    id: 'posts.newPost.attachments'
  },
  attachmentsDefaultName: {
    defaultMessage: 'Attachment {number}',
    id: 'posts.newPost.attachmentsDefaultName'
  },
  requiredAction: {
    defaultMessage: 'Required action',
    id: 'posts.newPost.requiredAction'
  },
  severity: {
    defaultMessage: 'Severity',
    id: 'posts.newPost.severity'
  },
  title_requiredAction: {
    defaultMessage: 'Required action',
    id: 'posts.newPost.title_requiredAction'
  }, 
  title_fine: {
    defaultMessage: 'Penalty Fine',
    id: 'posts.newPost.title_fine'
  }, 
  requiredActionPlaceholder: {
    defaultMessage: 'Select required action or write one here',
    id: 'posts.newPost.requiredActionPlaceholder'
  }, 
  requiredActionAlert:{
    defaultMessage: 'Are you sure you want to ignore the action you wrote?',
    id: 'posts.newPost.requiredActionAlert'
  },
  generalInfo: {
    defaultMessage: 'General',
    id: 'posts.newPost.sections.generalInfo'
  }, 
  documentation: {
    defaultMessage: 'More info',
    id: 'posts.newPost.sections.documentation'
  }, 
  followUp: {
    defaultMessage: 'Follow up',
    id: 'posts.newPost.sections.followUp'
  }, 
  description: {
    defaultMessage: 'Description',
    id: 'posts.newPost.description'
  }, 
  newIssueTitle: {
    defaultMessage: 'Create new task',
    id: 'posts.newPost.newIssueTitle'
  }, 
  newRecordTitle: {
    defaultMessage: 'Create new record',
    id: 'posts.newPost.newRecordTitle'
  }, 
  refEquipment :{
    defaultMessage: 'Related equipment',
    id: 'posts.newPost.refEquipment'
  }
});
