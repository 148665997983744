import React from 'react';
import { connect } from 'react-redux';
import { compose, hoistStatics } from 'recompose';
import { connectContext } from 'react-connect-context';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

// actions
import {
	getProjectsByCompany,
	setProjectPermissions,
	updateProjectFields,
	updateExternalSettings,
	getProjectExternalSettings,
} from '../../../common/projects/actions';
import { ProjectContext } from '../../../common/projects/contexts';
import { getSafetyDefaultPointSystem } from '../../../common/safety/actions';
import { updateConfigurations } from '../../../common/configurations/actions';
import { uploadImage } from '../../../common/images/actions';
import {
	startGroupsListener,
	endGroupsListener,
	setProjectGroupRulesByCompanyIds,
} from '../../../common/groups/actions';

import { setCompanyType, setCompaniesImages } from '../../../common/companies/actions';
import { startToast } from '../../../common/app/actions';
import { uploadPropertiesInstances } from '../../../common/propertiesInstances/actions';
// messages
import safetyMessages from '../../../common/safety/safetyMessages';
import systemMessages from '../../../common/app/systemMessages';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import SplitViewPage from '../../layouts/SplitViewPage';
import MultiCheckSelect from '../../components/CementoComponents/MultiCheckSelect';
import { GridContainer, GridItem } from '../../components';
import MembersManager from '../Members/MembersManager';
import PropertiesGroupsManager from '../Properties/PropertiesGroupsManager';
import InputField from '../../components/CementoComponents/InputField';
import Text from '../../components/CementoComponents/Text';
import CollapsibleSection_DEPRECATED from '../../components/CementoComponents/CollapsibleSection_DEPRECATED';
import Select from 'react-select';
//
import theme from "../../assets/css/theme";
import loginPageStyle from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import * as companiesTypes from '../../../common/companies/companiesTypes'

export const companiesGroupsOptions = {
	'0001': { value: '0001', label: 'Project Admin' },
	'0004': { value: '0004', label: 'Project Lower admin' },
	'0002': { value: '0002', label: 'Global Viewer' },
	'0005': { value: '0005', label: 'Inspector' },
	'0010': { value: '0010', label: 'Web Permitted' },
	'0003': { value: '0003', label: 'Checklists Viewer' },
	'0011': { value: '0011', label: 'Checklists - irrelevant All' },
	'0012': { value: '0012', label: 'Checklists - irrelevant' },
	'0013': { value: '0013', label: 'Checklists - partial' },
	'0014': { value: '0014', label: 'Checklists - resolve' },
	'0015': { value: '0015', label: 'Checklists - confirm' },
	'0016': { value: '0016', label: 'Checklists - verifier' },
	'0030': { value: '0030', label: 'All Checklist Viewer' },
	'0040': { value: '0040', label: 'Safety Inspector' },
	'0050': { value: '0050', label: 'Safety Task closer - for Project Manager and Foreman' },  
};

class ProjectManager extends React.Component {
  constructor(props) {
    super(props);
    this.saveChanges = this.saveChanges.bind(this);
    this.confirmSaveChanges = this.confirmSaveChanges.bind(this);
    this.getCompanyType = this.getCompanyType.bind(this);
    this.getPropertiesGroupsManagerRef = this.getPropertiesGroupsManagerRef.bind(this);
    this.getProjectCompanyIdsByType = this.getProjectCompanyIdsByType.bind(this);
    this.updateCompanyChangesMap = this.updateCompanyChangesMap.bind(this);
    this.recalcHeader = this.recalcHeader.bind(this)
    this.setComponentData = this.setComponentData.bind(this);
    this.handleChangeProperties = this.handleChangeProperties.bind(this);
    this.handleExternalSettings = this.handleExternalSettings.bind(this);
    this.onProjectTimeChange = this.onProjectTimeChange.bind(this);
    this.onCompanyNameChange = this.onCompanyNameChange.bind(this);
    this.onTzLocationChange = this.onTzLocationChange.bind(this);
    this.onProjectalImageChange = this.onProjectalImageChange.bind(this);
    this.onCompanyImageChange = this.onCompanyImageChange.bind(this);
    this.updateSafetyAcrossAllCompaniesProjects = this.updateSafetyAcrossAllCompaniesProjects.bind(this);
    
    this.onLangChange = this.onLangChange.bind(this);
    this.state = {
      projectToManage: {},
      configurationsParams: {},
      projectCompaniesArray: [],
      projectCompaniesMap: {},
      projectPartialCompanies: {},
      projectIrrelevantCompanies: {},
      projectConfirmCompanies: {},
      projectConfirm2Companies: {},
      companiesTypesChanges: {},
      companiesGroupsChanges: {},
      companiesImagesMap: {},
      companiesDarkLogoMap: {},
      companiesGroups: {},
      externalSettings: {}
    }

    this.companiesTypesOptions = {
      [companiesTypes.COMPANY_TYPES_GC] : { value: companiesTypes.COMPANY_TYPES_GC, label: companiesTypes.COMPANY_TYPES_GC }, 
      [companiesTypes.COMPANY_TYPES_DEV] : { value: companiesTypes.COMPANY_TYPES_DEV, label: companiesTypes.COMPANY_TYPES_DEV }, 
      [companiesTypes.COMPANY_TYPES_SUB] : { value: companiesTypes.COMPANY_TYPES_SUB, label: companiesTypes.COMPANY_TYPES_SUB },
      [companiesTypes.COMPANY_TYPES_CONS] : { value: companiesTypes.COMPANY_TYPES_CONS, label: companiesTypes.COMPANY_TYPES_CONS },
      [companiesTypes.COMPANY_TYPES_PROP] : { value: companiesTypes.COMPANY_TYPES_PROP, label: companiesTypes.COMPANY_TYPES_PROP },
      [companiesTypes.COMPANY_TYPES_SUPPLIER] : { value: companiesTypes.COMPANY_TYPES_SUPPLIER, label: companiesTypes.COMPANY_TYPES_SUPPLIER}
    }
  }

  componentWillUnmount() {
    const { endGroupsListener } = this.props;
    endGroupsListener();
  }

  async componentWillMount() {
    const { startGroupsListener, selectedProjectId, getProjectExternalSettings, getSafetyDefaultPointSystem } = this.props;
    startGroupsListener();
    this.recalcHeader();
    this.setComponentData({}, this.props);

    let response = await getProjectExternalSettings(selectedProjectId);
    let safetyDefaultSettings = await getSafetyDefaultPointSystem();
    this.setState({safetyDefaultSettings, externalSettings: response.externalSettings});
  }

  componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps)
  }

  recalcHeader() {
    const { setHeaderParams } = this.props
    if (setHeaderParams)
      setHeaderParams({ headerComponent: null, sideBarParams: null });
  }
  
  setComponentData(props, nextProps) {    
    let newStateChanges = {};

    if (props.detailedProjects != nextProps.detailedProjects || props.members != nextProps.members || props.selectedProjectId != nextProps.selectedProjectId) {
      let project = nextProps.detailedProjects.getNested([nextProps.selectedProjectId]) || {};
      newStateChanges.projectToManage = project.toJS ? project.toJS() : Object.assign({}, project);
      delete newStateChanges.projectToManage.members;
    }

    if (props.configurations != nextProps.configurations)
      newStateChanges.configurationsParams = nextProps.configurations;
    
    let checklistsCompaniesPermissionsMaps = {
      confirm:'projectConfirmCompanies', 
      confirm2:'projectConfirm2Companies', 
      irrelevant:'projectIrrelevantCompanies', 
      partial:'projectPartialCompanies'
    };
    
    // Set project companies 
    if (props.projectCompanies != nextProps.projectCompanies || props.selectedProjectId != nextProps.selectedProjectId) {
      newStateChanges.projectCompaniesMap = {};
      checklistsCompaniesPermissionsMaps.loopEach((p, mapName) => newStateChanges[mapName] = {});
      nextProps.projectCompanies.loopEach((id, comp) => {
        newStateChanges.projectCompaniesMap[comp.id] = { id: comp.id, label: comp.name, company: comp };
        checklistsCompaniesPermissionsMaps.loopEach((p, mapName) => newStateChanges[mapName][comp.id] = { id: comp.id, label: comp.name, company: comp } );
      }) 
    }

    // Set project confirm/confirm2/irrelevant/partial companies 
    if (props.projectCompanies != nextProps.projectCompanies || 
        props.selectedProjectId != nextProps.selectedProjectId ||
        props.isValDiff(nextProps, ['projects', nextProps.selectedProjectId, 'permissions', 'checklistItems'])) {
      checklistsCompaniesPermissionsMaps.loopEach((permissionName, mapName) => {  
        newStateChanges[mapName] = newStateChanges[mapName] ? newStateChanges[mapName] : Object.assign({}, this.state[mapName]);
        let permittedCompanies = nextProps.getNested(['projects', nextProps.selectedProjectId, 'permissions', 'checklistItems', permissionName, 'companies'], {})
        permittedCompanies.loopEach(compId => {
          let comp = newStateChanges[mapName][compId];
          if (comp) newStateChanges[mapName][compId].checked = true;
        })
      });
    }
    
    if (newStateChanges.projectCompaniesMap)
      newStateChanges.projectCompaniesArray = Object.values(newStateChanges.projectCompaniesMap);

    // Set project companies groups
    if (props.projectCompanies != nextProps.projectCompanies || 
        props.getNested(['globalGroups', 'groups']) != nextProps.getNested(['globalGroups', 'groups']) ||
        props.getNested(['projectGroups', props.selectedProjectId, 'groups']) != nextProps.getNested(['projectGroups', nextProps.selectedProjectId, 'groups'])) {
      let globalGroups = nextProps.getNested(['globalGroups', 'groups']);
      let projectGroups = nextProps.getNested(['projectGroups', nextProps.selectedProjectId, 'groups']);
      let mergedRules = {};
      (globalGroups || {}).loopEach((id, group) => mergedRules[id] = (group.rules || []).map(r => ({ ...r, isGlobalRuleGroup: true })) );
      (projectGroups || {}).loopEach((id, group) => {
        if (!mergedRules[id])
          mergedRules[id] = (group.rules || []).slice();
        else 
          (group.rules || []).forEach(curr => mergedRules[id].push(curr));
      });

      if (globalGroups || projectGroups) {
        newStateChanges.companiesGroups = {};
        mergedRules.loopEach((groupId, groupsRules) => {
          groupsRules.forEach(r => {
            if (r.companyId) {
              if (!newStateChanges.companiesGroups[r.companyId])
                newStateChanges.companiesGroups[r.companyId] = [];
              newStateChanges.companiesGroups[r.companyId].push({...companiesGroupsOptions[groupId], isGlobalRuleGroup: r.isGlobalRuleGroup });
            }
            else if (r.companyType) {
              this.getProjectCompanyIdsByType(r.companyType).forEach(companyId => {
                if (!newStateChanges.companiesGroups[companyId])
                  newStateChanges.companiesGroups[companyId] = [];
                newStateChanges.companiesGroups[companyId].push({...companiesGroupsOptions[groupId], isGlobalRuleGroup: r.isGlobalRuleGroup });
              })
            }
          })
        })
      }
    }

    if (Object.keys(newStateChanges).length > 0)
      this.setState(newStateChanges);
  }

  updateSelectedCompanyArray(selectedCompanyArrayName, allItems) {
    this.setState({ [selectedCompanyArrayName] : allItems });
  }

  updateCompanyChangesMap(companyId, companyChangesMapName, e) {
    let changes = Object.assign({}, this.state[companyChangesMapName]);
    changes[companyId] = e;
    this.setState({ [companyChangesMapName]: changes })
  }

  getCompanyType(companyId, selectedProjectId) {
    const { projectCompanies } = this.props;
    
    return Object.keys(projectCompanies.getNested([companyId, 'types']))[0]
  }

  getProjectCompanyIdsByType(type) {
    const { projectCompaniesArray } = this.state;
    const { companies, selectedProjectId } = this.props;
    let ret = [];
    (projectCompaniesArray || []).forEach(curr => {
      let currType = this.getCompanyType(curr.id, selectedProjectId)
      if (type == currType)
        ret.push(curr.id);
    })
    return ret;
  }

  onCompanyNameChange(val) {
    let newCompany = Object.assign({}, this.state.projectToManage.company)
    newCompany.title = val;
    this.setState({ projectToManage: { ...this.projectToManage, company: newCompany }})
  }

  handleChangeProperties(path, value) {
    let projectToManage = this.state.projectToManage.setNested(path, value)
    this.setState({projectToManage})
  }

  handleConfigurationsChangeProperties(path, value) {
    let configurationsParams = this.state.configurationsParams.setNested(path, value);
    this.setState({configurationsParams})
  }

  handleExternalSettings(path, value) {
    let externalSettings = (this.state.externalSettings || {}).setNested(path, value)
    this.setState({externalSettings})
  }

  onProjectTimeChange(val) {
    this.setState({projectStartTS:val})
  }

  onTzLocationChange(id) {
    let tzLocation = id;
    this.setState({tzLocation})
  }

  onLangChange(id) {
    let lang = id;
    this.setState({lang})
  }

  onProjectalImageChange(img, projectCompanyMode) {
    if (projectCompanyMode) {
      let newCompany = Object.assign({}, this.state.company)
      newCompany.logo = img;
      this.setState({ uploadCompanyImage: true, company: newCompany })
    }
    else
      this.setState({ uploadProjectImage: true, projectImage: img })
  }

  onCompanyImageChange(img, companyId, darkLogo=false) {
    const { companiesImagesMap, companiesDarkLogoMap } = this.state;
    let clone = Object.assign({}, darkLogo ? companiesDarkLogoMap : companiesImagesMap);
    clone[companyId] = img;
    this.setState({ [darkLogo ? "companiesDarkLogoMap" : "companiesImagesMap"]: clone })
  }
  // TODO: remove once this bug (CEM-2839) is fixed and rename confirmSaveChanges back to saveChanges
  async saveChanges() {
    const { projectCompanies, startToast, selectedProjectId  } = this.props;
    const { projectConfirmCompanies, projectConfirm2Companies, projectPartialCompanies, projectIrrelevantCompanies } = this.state;

    const objectsToLoop = [projectConfirmCompanies, projectConfirm2Companies, projectPartialCompanies, projectIrrelevantCompanies];
    const numberOfCompanies = Object.keys(projectCompanies).length * objectsToLoop.length; // multiply cause we have the companies in all the objects
    let checkedCompanyCounter = 0;
    objectsToLoop.forEach(obj => {
      Object.values(obj).forEach(val => { if (val.checked) checkedCompanyCounter++; });
    });

    if (numberOfCompanies && numberOfCompanies === checkedCompanyCounter) {
      startToast({
        overlay: true, 
        mandatory: true,
        title: systemMessages.saveChanges,
        message: { defaultMessage: 'Be careful, all the companies are checked everywhere!! You sure you want to save?', id: 'someId' },  
        actions: [
          { message: systemMessages.yes, color: 'success', onClick: this.confirmSaveChanges},
          { message: systemMessages.no }
        ]
      });
      return;
    }

    this.confirmSaveChanges();
  }
  
  async confirmSaveChanges() {
    const { updateExternalSettings, updateConfigurations, setCompaniesImages, setProjectPermissions, setProjectGroupRulesByCompanyIds, setCompanyType, selectedProjectId, projects, updateProjectFields, uploadPropertiesInstances } = this.props;
    const { externalSettings, projectConfirmCompanies, projectConfirm2Companies, projectPartialCompanies, projectIrrelevantCompanies, projectImage, companiesTypesChanges, companiesImagesMap, companiesDarkLogoMap, companiesGroupsChanges, company, tzLocation, lang, projectStartTS, projectToManage, configurationsParams, uploadProjectImage, uploadCompanyImage } = this.state;
    
    let newStateChanges = {};

    if (this.PropertiesGroupsManagerRef)
      this.PropertiesGroupsManagerRef.saveAllChanges();

    if (setProjectPermissions)
      setProjectPermissions(
        projects.getNested([selectedProjectId], {}),
        Object.values(projectConfirmCompanies || {}),
        Object.values(projectConfirm2Companies || {}),
        Object.values(projectIrrelevantCompanies || {}),
        Object.values(projectPartialCompanies || {}));
        
    (companiesTypesChanges || {}).loopEach((companyId, type) => { setCompanyType(companyId, selectedProjectId, type.value)})
    setProjectGroupRulesByCompanyIds(selectedProjectId, companiesGroupsChanges);
    
    let companyObj = Object.assign({}, projectToManage.company);
    let projectImageURL = projectImage
    if (uploadProjectImage) {
      projectImageURL = await uploadImage(projectImage, "project_main/" + selectedProjectId + '/' + Date.now(), "projects");
      newStateChanges.uploadProjectImage = false;
      newStateChanges.projectImage = projectImageURL;
    }

    if (uploadCompanyImage) {
      newStateChanges.uploadCompanyImage = false;
      companyObj.logo = await uploadImage(company.logo, "project_company/" + selectedProjectId + '/' + Date.now(), "companies");
      newStateChanges.projectToManage = { ...newStateChanges.projectToManage, company: companyObj };
    }

    setCompaniesImages(companiesImagesMap)
    setCompaniesImages(companiesDarkLogoMap, 'darkLogo')
    
    newStateChanges.companiesImagesMap = {};
    newStateChanges.companiesDarkLogoMap = {};
    let fields = { ...projectToManage, ...newStateChanges.projectToManage };
    delete fields.permissions;
    updateProjectFields({ images: { main: projectImageURL }, company: companyObj, ...fields }, selectedProjectId);
    
    let newExternalSettings = Object.assign({}, externalSettings);
    delete newExternalSettings['menu'];
    updateExternalSettings(externalSettings, selectedProjectId);

    updateConfigurations(configurationsParams, selectedProjectId);

    if (Object.keys(newStateChanges).length > 0)
      this.setState(newStateChanges);
  }

  async updateSafetyAcrossAllCompaniesProjects() {
    const { startToast, updateExternalSettings, getProjectsByCompany } = this.props;
    const { externalSettings, projectToManage } = this.state;
    let currCompanyId = projectToManage['companyId'];
    if (!currCompanyId)
      return;

    let projects = await getProjectsByCompany(currCompanyId);
    let projectNames = Object.values(projects || {}).map(x => x.title).join('\n');
    
    if (!externalSettings.getNested(['grades', 'safety'])) // TODO: Show error to the user
      return;

    let safetyGrades = {grades:{safety:externalSettings.getNested(['grades', 'safety'])}};

    startToast({
        overlay: true, 
        mandatory: true,
        title: systemMessages.saveForAllCompaniesProject,
        message: projectNames, 
        actions: [
          { message: systemMessages.yes, color: 'success', onClick: (() => Object.values(projects || {}).forEach(project => updateExternalSettings(safetyGrades, project.id)))},
          { message: systemMessages.no }
        ]
      });
  }

  getPropertiesGroupsManagerRef(r) {
    this.PropertiesGroupsManagerRef = r;
  }

  render() {
    const { selectedProjectId, classes, rtl, projectCompanies, configurations } = this.props;
    const {projectImage, safetyDefaultSettings, projectCompaniesArray, projectConfirmCompanies, projectPartialCompanies, projectIrrelevantCompanies, projectConfirm2Companies, companiesGroups, companiesGroupsChanges, companiesTypesChanges, companiesImagesMap,companiesDarkLogoMap, projectToManage, externalSettings, configurationsParams } = this.state;
    let lang = projectToManage.getNested(['lang']);
    let tzLocation = projectToManage.getNested(['tzLocation']);
    let btnStyle = { borderRadius:'5px', display:'flex', padding:'0px 5px', minWidth: 75, height:30, alignSelf:'center', justifyContent:'center', alignItems:'center', margin: 5, border: '1px solid ' + theme.brandPrimary + '85', color: theme.brandPrimary, cursor: 'pointer' };
    let safetyForms = Object.values(configurations.getNested(['forms'], {})).filter(x => x.type == "safety");

    return (
      <SplitViewPage
        rtl={rtl}
        Main={
        <div style={{ display: 'flex', flex: 1, minHeight:'100%', flexDirection:'column', justifyContent:'space-between', padding: theme.paddingSize * 2 }}>
          <GridContainer style={{ paddingBottom: 3 * theme.paddingSize }}>
            <GridItem xs={8}>
              <GridContainer>
                <GridItem xs={12}>
                    <CollapsibleSection_DEPRECATED title={systemMessages.manage.companiesTypes} height={400}>
                      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div style={{ flex: 1 }}></div><div style={{ flex: 1 }}>Logo</div><div style={{ flex: 1 }}>DarkLogo</div><div style={{ flex: 1 }}></div>
                      </div>
                      <div style={{ padding: theme.paddingSize, height: '100%', width: '100%', flexDirection: 'column', overflow: 'auto' }}>
                        {(projectCompaniesArray||[]).map(c => 
                        <div key={c.id} style={{display:'flex', flexDirection:'row', alignItems: 'center', height:'40px'}}>
                          <div style={{flex:1}}>{c.label}</div>
                          <div style={{flex:1}}>
                          <InputField type={'Picture'} mode={'min'} key={`${selectedProjectId}_${c.id}_logo`} withResize={true} resizePercent={30} height={30} defaultValue={companiesImagesMap.hasOwnProperty(c.id) ?  (!_.isNil(companiesImagesMap[c.id]) ? { uri: _.get(companiesImagesMap, [c.id, 'uri']) } : null) : typeof c.company.logo == 'string' ? {uri: c.company.logo} : c.company.logo } onChange={(img) => this.onCompanyImageChange(img, c.id)}/>
                          </div>
                          <div style={{flex:1}}>

                              <InputField type={'Picture'} mode={'min'} key={`${selectedProjectId}_${c.id}_darkLogo`} withResize={true} resizePercent={30} height={30} defaultValue={companiesDarkLogoMap.hasOwnProperty(c.id) ?  (!_.isNil(companiesDarkLogoMap[c.id]) ? { uri: _.get(companiesDarkLogoMap, [c.id, 'uri']) } : null) : typeof c.company.darkLogo == 'string' ? {uri: c.company.darkLogo} : c.company.darkLogo } onChange={(img) => this.onCompanyImageChange(img, c.id, true)}/>
                          </div>
                          <div style={{flex:1}}>
                          <Select 
                            fullWidth
                            disableUnderline={true}
                            MenuProps={{ className: classes.selectMenu }}
                            classes={{ select: classes.select }}
                            inputProps={{disableUnderline: true}}
                            value={companiesTypesChanges.hasOwnProperty(c.id) ? companiesTypesChanges[c.id] : this.companiesTypesOptions[this.getCompanyType(c.id, selectedProjectId)] }
                            onChange={(e) => this.updateCompanyChangesMap(c.id, 'companiesTypesChanges', e)}
                            options={Object.values(this.companiesTypesOptions).map(curr => curr)}/>
                          </div>
                      </div>)}
                    </div>
                  </CollapsibleSection_DEPRECATED>
                </GridItem>
                <GridItem xs={12}>
                  <CollapsibleSection_DEPRECATED title={systemMessages.manage.companiesPermissions} height={400}>
                    <div style={{padding: theme.paddingSize, height:'100%', width:'100%', flexDirection:'column', overflow:'auto'}}>
                        {(projectCompaniesArray||[]).map(c => 
                        <div key={c.id} style={{display:'flex', flexDirection:'row', alignItems: 'center', height:'40px'}}>
                          <div style={{flex:1}}>{c.label}</div>
                          <div style={{flex:1}}>
                            <Select 
                              fullWidth
                              isMulti={true}
                              disableUnderline={true}
                              MenuProps={{ className: classes.selectMenu }}
                              classes={{ select: classes.select }}
                              inputProps={{disableUnderline: true}}
                              isClearable={(companiesGroupsChanges.hasOwnProperty(c.id) 
                                ? companiesGroupsChanges[c.id] 
                                : (companiesGroups.hasOwnProperty(c.id) 
                                  ? companiesGroups[c.id] 
                                  : null)||[]).some(v => !v.isGlobalRuleGroup)}
                              value={companiesGroupsChanges.hasOwnProperty(c.id) 
                                ? companiesGroupsChanges[c.id] 
                                : (companiesGroups.hasOwnProperty(c.id) 
                                  ? companiesGroups[c.id] 
                                  : null)}
                              onChange={(e) => this.updateCompanyChangesMap(c.id, 'companiesGroupsChanges', e)}
                              options={Object.values(companiesGroupsOptions).map(curr => curr)}/>
                          </div>
                      </div>)}
                    </div>
                  </CollapsibleSection_DEPRECATED>
                </GridItem>
                <GridItem xs={12}>
                  <CollapsibleSection_DEPRECATED title={systemMessages.manage.checklistsInspector} height={400} contentStyle={multiCheckSelectStyle}>
                    <MultiCheckSelect items={projectConfirm2Companies} titlePropPath={['label']} onChange={(allItems, allSelectedMap) => this.updateSelectedCompanyArray('projectConfirm2Companies', allItems, allSelectedMap)}/>         
                  </CollapsibleSection_DEPRECATED>
                </GridItem>
                <GridItem xs={12}>
                  <CollapsibleSection_DEPRECATED title={systemMessages.manage.checklistsVerifier} height={400} contentStyle={multiCheckSelectStyle}>
                    <MultiCheckSelect items={projectConfirmCompanies} titlePropPath={['label']} onChange={(allItems, allSelectedMap) => this.updateSelectedCompanyArray('projectConfirmCompanies', allItems, allSelectedMap)}/>
                  </CollapsibleSection_DEPRECATED>
                </GridItem>
                <GridItem xs={12}>
                  <CollapsibleSection_DEPRECATED title={systemMessages.manage.checklistsPartial} height={400} contentStyle={multiCheckSelectStyle}>
                    <MultiCheckSelect items={projectPartialCompanies} titlePropPath={['label']} onChange={(allItems, allSelectedMap) => this.updateSelectedCompanyArray('projectPartialCompanies', allItems, allSelectedMap)}/>
                  </CollapsibleSection_DEPRECATED>
                </GridItem>
                <GridItem xs={12}>
                  <CollapsibleSection_DEPRECATED title={systemMessages.manage.checklistsIrrelevant} height={400} contentStyle={multiCheckSelectStyle}>
                    <MultiCheckSelect items={projectIrrelevantCompanies} titlePropPath={['label']} onChange={(allItems, allSelectedMap) => this.updateSelectedCompanyArray('projectIrrelevantCompanies', allItems, allSelectedMap)}/>
                  </CollapsibleSection_DEPRECATED>
                </GridItem>
                <GridItem xs={12}>
                  <CollapsibleSection_DEPRECATED title={systemMessages.manage.propertiesGroups}>
                    <PropertiesGroupsManager getRef={this.getPropertiesGroupsManagerRef} lang={lang}/>
                  </CollapsibleSection_DEPRECATED>
                </GridItem>
                <GridItem xs={12}>
                  <CollapsibleSection_DEPRECATED title={systemMessages.manage.projectMembers} height={800}>
                    <MembersManager projectScope={true} />
                  </CollapsibleSection_DEPRECATED>
                </GridItem>

                <GridItem xs={12}>
                  <CollapsibleSection_DEPRECATED title={'משקלי בטיחות'}>

                    <div style={{ width: '100%', padding: theme.paddingSize }}>
                    {['openedIssueSeverity3', 'openedIssueSeverity2', 'openedIssueDelaysDaySeverity3', 'openedIssueDelaysDaySeverity2', 'workersInvalidCertification', 'equipmentInvalidCertification'].map(currSafetyItem => 
                      <InputField placeholder={safetyDefaultSettings ? safetyDefaultSettings[currSafetyItem] : null} type={'Number'} key={selectedProjectId+currSafetyItem} name={safetyMessages.settings[currSafetyItem]} value={(externalSettings || {}).getNested(['grades','safety',currSafetyItem])} onChange={str => this.handleExternalSettings(['grades','safety',currSafetyItem], str)}/>
                    )}
                      <div>
                        <table style={styles.tableBorder}>
                          <tr>
                            <th style={styles.tableBorder}>
                              <span>name</span>
                            </th>
                            <th style={styles.tableBorder}>
                              <span>days</span>
                            </th>
                            <th style={styles.tableBorder}>
                              <span>points per day</span>
                            </th>
                          </tr>




                        {
                          safetyForms.map(form => {
                            return (
                              <tr>
                              <td style={styles.tableBorder}>
                                <span>{form.getNested(['title', lang])}</span>
                              </td>
                              {['maximumDaysBetweenSurveys', 'siteSafetySurveyPointsPerDayOverMaxDays'].map(currSafetyItem => 
                                <>
                                  <td style={styles.tableBorder}>
                                    <span>
                                      <InputField placeholder={safetyDefaultSettings ? safetyDefaultSettings[currSafetyItem] : null} type={'Number'} key={selectedProjectId+currSafetyItem} value={(externalSettings || {}).getNested(['grades', 'safety', 'forms', form.id, currSafetyItem])} onChange={str => this.handleExternalSettings(['grades','safety','forms',form.id,currSafetyItem], str)}/>
                                    </span>
                                  </td>
                                </>
                              )}
                            </tr>
                          )
                            
                            // Show each active safety report 
                            // Define points per active safety report

                            let fields = ['maximumDaysBetweenSurveys', 'siteSafetySurveyPointsPerDayOverMaxDays'].map(currSafetyItem => 
                              <InputField placeholder={safetyDefaultSettings ? safetyDefaultSettings[currSafetyItem] : null} type={'Number'} key={selectedProjectId+currSafetyItem} name={safetyMessages.settings[currSafetyItem]} value={(externalSettings || {}).getNested(['grades','safety',currSafetyItem])} onChange={str => this.handleExternalSettings(['grades','safety',currSafetyItem], str)}/>
                            )

                            return <><span>{form.getNested(['title', lang])}</span>{fields}</>
                          })
                        }
                        </table>
                      
                      </div>
                    </div>
                    <div style={{ alignSelf: 'flex-start', marginBottom: theme.margin, marginLeft: theme.padding, flexDirection:'row-reverse', display:'flex' }}>
                      <div style={{ width:250 }}>
                        <div style={btnStyle} onClick={this.updateSafetyAcrossAllCompaniesProjects}><Text>{systemMessages.saveForAllCompaniesProject}</Text></div>
                      </div>
                    </div>

                  </CollapsibleSection_DEPRECATED>
                </GridItem>

                

                {false && <Text style={{cursor: 'pointer', margin: theme.margin}} onClick={() => this.props.history.push(this.props.history.location.pathname.replace('projectManager', 'formsManager')) }>הגדרות טפסים</Text>}
              </GridContainer>
            </GridItem>
            <GridItem xs={4}>
              <GridContainer spacing={8}>
                <GridItem xs={12}>
                  <CollapsibleSection_DEPRECATED alwaysOpen={true} title={systemMessages.manage.projectSettings}>
                    <div style={{ width: '100%', padding: theme.paddingSize }}>
                    <InputField 
                      key={selectedProjectId + '_readCompanies2'} 
                      name={systemMessages.manage.projectCustomer}
                      type={'SelectionList'}
                      values={Object.values(projectCompanies).map(comp=>({id: comp.id, title: comp.name }))} 
                      //defaultValue={projectToManage['companyId'] ? Object.values(projectToManage['companyId'])[0] : null}
                      value={projectToManage['companyId'] ? {[projectToManage['companyId']]:projectToManage['companyId']} : null}
                      onChange={val => this.handleChangeProperties(['companyId'], Object.keys(val)[0])}/>

                      <InputField type={'SelectionList'} key={selectedProjectId+'tzLocation'} name={systemMessages.manage.projectTimeZone} value={{[tzLocation]:tzLocation}} onChange={val => this.handleChangeProperties(['tzLocation'],Object.keys(val)[0]) } values={[{id: "Asia/Jerusalem", title: "Asia/Jerusalem"}]}/>
                      <InputField type={'SelectionList'} key={selectedProjectId+'lang'} name={systemMessages.manage.projectLanguage} value={{[lang]:lang}} onChange={val => {this.handleChangeProperties(['lang'], Object.keys(val)[0]) }} values={[{id: "en", title: "English"}, {id: "es", title: "Spanish"}, {id: "he", title: "Hebrew"}]}/>
                      <InputField type={'String'} key={selectedProjectId+'companyName'} name={systemMessages.manage.companyName} value={projectToManage.getNested(['company','title'])} onChange={str => this.handleChangeProperties(['company','title'], str)}/>
                      <InputField type={'Date'}   key={selectedProjectId+'projectStartTS'} name={systemMessages.manage.projectStartingTs} value={projectToManage.getNested(['projectStartTS'])} onChange={val => this.handleChangeProperties(['projectStartTS'], val)}/>
                      <InputField type={'Boolean'} key={selectedProjectId+'showOnAdmin'} name={systemMessages.manage.showOnlyWhenAdmin} value={projectToManage.getNested(['showOnAdmin'])} onChange={b => this.handleChangeProperties(['showOnAdmin'], b)}/>
                      <InputField type={'Boolean'} key={selectedProjectId+'safety'} name={systemMessages.manage.showSafetyTab} value={configurationsParams.getNested(['features', 'safety', 'isActive'])} onChange={b => this.handleConfigurationsChangeProperties(['features', 'safety', 'isActive'], b)}/>
                      <InputField type={'Boolean'} key={selectedProjectId+'quality'} name={systemMessages.manage.showQualityTab} value={configurationsParams.getNested(['features', 'quality', 'isActive'], true)} onChange={b => this.handleConfigurationsChangeProperties(['features', 'quality', 'isActive'], b)}/>
                      <InputField type={'Boolean'} key={selectedProjectId+'dailyReport'} name={systemMessages.manage.showDailyReportTab} value={configurationsParams.getNested(['features', 'dailyReport', 'isActive'], true)} onChange={b => this.handleConfigurationsChangeProperties(['features', 'dailyReport', 'isActive'], b)}/>
                      <InputField type={'Boolean'} key={selectedProjectId + 'spec'} name={systemMessages.manage.showSpecTab} value={configurationsParams.getNested(['features', 'spec', 'isActive'])} onChange={b => this.handleConfigurationsChangeProperties(['features', 'spec', 'isActive'], b)} />                      
                      <InputField type={'Boolean'} key={selectedProjectId + 'calcAllStatuses'} name={'חשב בטבלה התקדמות בעבור כל הסטוטסים'} value={configurationsParams.getNested(['checklists', 'calcAllStatuses'])} onChange={b => this.handleConfigurationsChangeProperties(['checklists', 'calcAllStatuses'], b)} />
                      <InputField type={'Boolean'} key={selectedProjectId + 'hideChecklistTitleOnIssueDesc'} name={systemMessages.manage.hideChecklistTitleOnIssueDesc} value={configurationsParams.getNested(['checklists', 'hideChecklistTitleOnIssueDesc'])} onChange={b => this.handleConfigurationsChangeProperties(['checklists', 'hideChecklistTitleOnIssueDesc'], b)} />
                      <InputField type={'Boolean'} key={selectedProjectId + 'enableDistributionList'} name={systemMessages.manage.enableDistributionList} value={configurationsParams.getNested(['checklists', 'enableDistributionList'])} onChange={b => this.handleConfigurationsChangeProperties(['checklists', 'enableDistributionList'], b)} />
                        
                      <div style={{display:'flex', alignItems:'center'}}>
                        <InputField type={'Picture'} mode={'min'} style={{padding:'5px 10px'}} key={selectedProjectId+'proj'} withResize={false} name={systemMessages.manage.projectImage} defaultValue={typeof projectImage == 'string' ? {uri: projectImage} : projectImage} onChange={(img) => this.onProjectalImageChange(img, false)}/>
                        <InputField type={'Picture'} mode={'min'} style={{padding:'5px 10px'}} key={selectedProjectId+'comp'} withResize={true} resizePercent={50} name={systemMessages.manage.companyLogo} defaultValue={projectToManage.company && projectToManage.company.logo && typeof projectToManage.company.logo == 'string' ? {uri: projectToManage.company.logo} : null} onChange={(img) => this.onProjectalImageChange(img, true)}/>
                      </div>
                    </div>
                  </CollapsibleSection_DEPRECATED>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              
            </GridItem>
          </GridContainer>
          <div style={{ flexDirection:'row-reverse', display:'flex' }}>
            <div style={{ width:250 }}>
              <div style={btnStyle} onClick={this.saveChanges}><Text>{systemMessages.saveChanges}</Text></div>
            </div>
          </div>
        </div>
        }
      />        
    );
  }
}

const styles = {
	textCenter: {
		textAlign: 'center',
		alignItems: 'center',
		alignContent: 'center',
		justify: 'center',
	},
	tableBorder: {
		borderColor: 'black',
		borderWidth: 1,
		borderStyle: 'solid',
	},
};

const multiCheckSelectStyle = { marginTop: "10px" };

ProjectManager = withStyles(theme.combineStyles(loginPageStyle, styles))(ProjectManager);
ProjectManager = withRouter(ProjectManager);
const enhance = compose(
	connectContext(ProjectContext.Consumer),
	connect(
		state => ({
			globalGroups: state.groups.globalGroups,
			projectGroups: state.groups.projectGroups,
			rtl: state.app.rtl,
		}),
		{
			setCompaniesImages,
			setProjectPermissions,
			startToast,
			getProjectsByCompany,
			updateProjectFields,
			updateConfigurations,
			updateExternalSettings,
			getProjectExternalSettings,
			getSafetyDefaultPointSystem,
			setProjectGroupRulesByCompanyIds,
			setCompanyType,
			startGroupsListener,
			endGroupsListener,
			uploadPropertiesInstances,
		},
	),
);
export default enhance(ProjectManager);
