import projectManagerMessages from '../../../../common/app/projectManagerMessages';
import webActions from '../../../webActions';
import { Section, Field } from './projectManagerConfig';
import * as periodStatus from '../../../../common/checklistItemsInstances/clItemInstancePeriods'
import checklistItemMessages  from '../../../../common/checklistItems/checklistItemMessages';
import systemMessages from '../../../../common/app/systemMessages';

const {
  description,
  duplicatable,
  enableDistributionList,
  periodic,
  extraDescription,
  trade,
  period,
  descriptionRequired,
  drawingRequired,
  fileRequired,
  imageRequired,
  companyReadPermissions,
  enablePartialButton,
  enableIrrelevantButton,
  weight,
  includeInGrade,
  basic,
  advanced,
  isMandatory,
} = systemMessages;

const { periods } = checklistItemMessages;

// Intermediate object for values coming from async functions
const specialValues = {

};

///////////////////////////////////////////////////////
//////////////////// Stages Fields ////////////////////
///////////////////////////////////////////////////////

const stageTitle = new Field(description, 'String', ['title'], null, { fullWidth: true });

const stageIncludeInGrade = new Field(includeInGrade, 'Boolean', ['includeInGrade'], null, { fullWidth: true });

///////////////////////////////////////////////////////
////////////////// Checklist Fields ///////////////////
///////////////////////////////////////////////////////

const checklistDescription = new Field(description, 'String', ['description'], null, { fullWidth: true });

const checklistDuplicatable = new Field(duplicatable, 'Boolean', ['duplicatable'], null, { fullWidth: true });

const checklistEnableDistributionList = new Field(enableDistributionList, 'Boolean', ['enableDistributionList'], null, { fullWidth: true });

const checklistPeriodic = new Field(periodic, 'Boolean', ['type'], null, { fullWidth: true });

const checklistCompanyReadPermissions = new Field(companyReadPermissions, 'SelectionList', ['permissions', 'read', 'companies'], [], { fullWidth: true });

const checklistUniversalId = new Field('Universal ID', 'String', ['universalId'], null, { fullWidth: true });


const checklistLocations = new Field('checklistLocations', 'SelectionList', ['locations'], [{id: 'buildings', title: 'Building'}, {id: 'floors', title: 'Floors'}, {id: 'units', title: 'Units'}], { fullWidth: true });

///////////////////////////////////////////////////////
/////////////// ChecklistItem Fields //////////////////
///////////////////////////////////////////////////////

const checklistItemDescription = new Field(description, 'String', ['description'], null, { fullWidth: true });

const checklistItemExtraDescription = new Field(extraDescription, 'String', ['extraDescription'], null, { fullWidth: true });

const checklistItemTrade = new Field(trade, 'SelectionList', ['trade'], [], { fullWidth: true });

const checklistItemPeriodOptions = Object.values(periodStatus).map(statusVal => ({ id: statusVal, title: periods[statusVal] }))
const checklistItemPeriod = new Field(period, 'SelectionList', ['period'], checklistItemPeriodOptions, { fullWidth: true, autoSort: false });

const checklistItemDescriptionRequired = new Field(descriptionRequired, 'Boolean', ['requirements', 'desc'], null, { fullWidth: true });

const checklistItemDrawingRequired = new Field(drawingRequired, 'Boolean', ['requirements', 'drawing'], null, { fullWidth: true });

const checklistItemFileRequired = new Field(fileRequired, 'Boolean', ['requirements', 'file'], null, { fullWidth: true });

const checklistItemImageRequired = new Field(imageRequired, 'Boolean', ['requirements', 'img'], null, { fullWidth: true });

const checklistItemEnablePartialButton = new Field(enablePartialButton, 'Boolean', ['permissions', 'actions', 'partial'], null, { fullWidth: true });

const checklistItemEnableIrrelevantButton = new Field(enableIrrelevantButton, 'Boolean', ['permissions', 'actions', 'irrelevant'], null, { fullWidth: true });

const checklistItemWeight = new Field(weight, 'String', ['weight'], null, { fullWidth: true });

const checklistItemUniversalId = new Field('Universal ID', 'String', ['universalId'], null, { fullWidth: true });

const checklistItemLocations = new Field('checklistItemLocations', 'SelectionList', ['locations'], [{id: 'buildings', title: 'Building'}, {id: 'floors', title: 'Floors'}, {id: 'units', title: 'Units'}], { fullWidth: true });

const checklistItemIsMandatory = new Field(isMandatory, 'Boolean', ['isMandatory'], null, { fullWidth: true });

///////////////////////////////////////////////////////
////////////////// Stage Sections /////////////////////
///////////////////////////////////////////////////////

const stageBasicSection = new Section(basic, [
  stageTitle,
], {}, 'basic');

const stageAdvancedSection = new Section(advanced, [
  stageIncludeInGrade,
], {}, 'advanced');

///////////////////////////////////////////////////////
///////////////// Checklist Sections //////////////////
///////////////////////////////////////////////////////

const checklistBasicSection = new Section(basic, [
  checklistDescription,
  checklistCompanyReadPermissions,
], {}, 'basic');

const checklistAdvancedSection = new Section(advanced, [
  checklistDuplicatable,
  checklistPeriodic,
  checklistEnableDistributionList,
  checklistUniversalId,
], {}, 'advanced');

///////////////////////////////////////////////////////
/////////////// ChecklistItem Sections ////////////////
///////////////////////////////////////////////////////

const checklistItemBasicSection = new Section(basic, [
  checklistItemDescription,
  checklistItemExtraDescription,
  checklistItemTrade,
], {}, 'basic');

const checklistItemAdvancedSection = new Section(advanced, [
  checklistItemImageRequired,
  checklistItemDescriptionRequired,
  checklistItemFileRequired,
  checklistItemDrawingRequired,
  checklistItemEnablePartialButton,
  checklistItemEnableIrrelevantButton,
  checklistItemWeight, 
  checklistItemIsMandatory,
  checklistItemUniversalId,
], {}, 'advanced');






export const stageSections = [
  stageBasicSection,
  stageAdvancedSection,
];

export const checklistSections = [
  checklistBasicSection,
  checklistAdvancedSection,
];

export const checklistItemSections = [
  checklistItemBasicSection,
  checklistItemAdvancedSection,
];