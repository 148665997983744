import { threadSleep } from "../../common/lib/utils/utils";
import { platformActions } from "../../common/platformActions";
import ExtraError from "../../common/lib/errors/extraError";
import { onError } from "../../common/app/funcs";

const MODE_WRITE_TRANSACTION = "MODE_WRITE_TRANSACTION";
const MODE_QUARY = "MODE_QUARY";
async function realmWaiterWrapper(realmInstance, maxRetries, currRetry, sleepDuration, mode, query = null, realmFilterdInstance) {
	if (!realmInstance.isInTransaction) {
		if (mode == MODE_QUARY) 
		try {
			return realmFilterdInstance.filtered(query);
		}catch (err) {
			platformActions.sentry.notify(err, { function: 'realmWaiterWrapper', query });
		}
		else if (mode == MODE_WRITE_TRANSACTION)
			return realmInstance.beginTransaction();
	}
	
	if (currRetry > maxRetries)
		throw new ExtraError(`realmWaiterWrapper ~  Slept few times, And the transaction is still active`, {mode, retries: currRetry, maxRetries, query})

	const sleepIncreaseRate = 1.3;
	await threadSleep(sleepDuration);
	return await realmWaiterWrapper(realmInstance, maxRetries, currRetry + 1, sleepDuration * sleepIncreaseRate, mode, query, realmFilterdInstance);
}

export async function realmTransactionRetry(realmInstance, maxRetries = 10, currRetry = 1, sleepDuration = 200) {
	await realmWaiterWrapper(realmInstance, maxRetries, currRetry, sleepDuration, MODE_WRITE_TRANSACTION);
}

export function realmSafeQuary(realmFilterdInstance, query, maxRetries = 30, currRetry = 1, sleepDuration = 200) {
	try {
		return realmFilterdInstance.filtered(query);
	} catch (err) {
		return [];
	}
	//return await realmWaiterWrapper(realmInstance, maxRetries, currRetry, sleepDuration, MODE_QUARY, query, realmFilterdInstance);
}

/**
 * 
 * @param {Realm} realm 
 * @param {Realm.Results<T & Realm.Object>} collection 
 * @param {Function} listener 
 * @param {number} sleepDuration
 * @param {number} maxRetries
 * @param {number} currRetry
 * @param {number} sleepIncreaseRate
 */
export const addSafeRealmListener  = (realm, collection, listener, sleepDuration = 10, maxRetries = 30, currRetry = 1, sleepIncreaseRate = 1.25, _timeoutRef = { current: null }) => {
	if (!realm.isInTransaction) {
		collection.addListener(listener);
	}
	else if (currRetry > maxRetries) {
		onError({
			errorMessage: 'realmWaiterWrapper ~  Slept few times, And could not add the listener',
			methodMetaData: {
				name: 'addSafeRealmListener',
				args: { retries : currRetry, maxRetries },
				}
			});
	} 
	else {
		_timeoutRef.current = setTimeout(() => {
			addSafeRealmListener(realm, collection, listener, sleepDuration * sleepIncreaseRate, maxRetries, currRetry + 1, sleepIncreaseRate, _timeoutRef);
		}, sleepDuration);
	}
	

	return () => clearTimeout(_timeoutRef.current);
  };
