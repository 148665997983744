import _ from "lodash";
import FunctionalInput from "../../../common/app/components/FunctionalInput";
import analyticsMessages from "../../../common/analytics/analyticsMessages";
import { injectIntl } from "react-intl";
import { getFormStatusParams } from "../../../common/forms/formsStatusStates";
import * as propertyTypes from "../../../common/propertiesTypes/propertiesTypes";
import theme from "../../../common/app/theme";

function TableToolTip(props)  {
    const { columnId, columnoriginId, columnSubjectName, instanceParentValueOrigin, originalValue, cellObject, universalId, shouldLimitResults = false, maxAmountOfElemnts = 3 } = props;
    const { intl } = props;
    const uniqeKey = columnId + '__' + cellObject.id;
    const isParentColumn = _.isEqual(columnoriginId, columnId) || _.isNil(columnoriginId);
    const propId = isParentColumn ? columnId : columnoriginId;
    const filteredParent = !isParentColumn ? _.filter(instanceParentValueOrigin, (val) => _.isEqual(originalValue, _.get(val, 'data', {}))) : null;
    const toolTipValue = isParentColumn ? originalValue : filteredParent;
    const amountOfElemnts = _.reduce(toolTipValue, (sum, currVal) => {return sum + _.has(currVal, 'data')}, 0);
    const shouldSlice = shouldLimitResults && amountOfElemnts > maxAmountOfElemnts;
    const slicedToolTip = shouldSlice ? _.pick(toolTipValue, _.slice(Object.keys(toolTipValue), 0, maxAmountOfElemnts)) : toolTipValue;
    const statusText = universalId === propertyTypes.UNIVERAL_IDS.status ? getFormStatusParams(cellObject) : null;
    const statusValue = !_.isNil(statusText?.message) ? intl.formatMessage(statusText.message) : '';

    return (
        <>
            <FunctionalInput
                alignCenter
                key={uniqeKey}
                inputKey={uniqeKey}
                value={universalId === propertyTypes.UNIVERAL_IDS.status ? statusValue : slicedToolTip}
                disabled
                withResize={false}
                autoSort={false}
                propId={propId}
                subjectName={columnSubjectName}
                mode={'card'}
                valueStyle={{fontSize: theme.fontSmallH6}}
                noValueComponentValueStyle={{fontSize: theme.fontSmallH6}}
            />
            {shouldSlice && <h3>{intl.formatMessage(analyticsMessages.table.tooltip.additionalValues, {amountAdditionalValues : amountOfElemnts - maxAmountOfElemnts})}</h3>}
        </>
    );
};

export default injectIntl(TableToolTip);