import * as actions from './actions';
import * as lastUpdatesActions from '../lastUpdates/actions';
import { REPLACE_USER } from '../auth/actions';

import Building from './building';

import { OrderedMap, Map } from 'immutable';
import { Record } from '../transit';
import { DEFAULT_LAST_UPDATE_TS } from '../app/constants';

const InitialState = Record({
  map: OrderedMap(),
  didLoad: OrderedMap(),
  lastUpdated: Map(),
  lastUpdateAvailable: Map(),  
}, 'buildings');

const initialState = new InitialState;

export default function buildingReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    
    case actions.GET_LOCAL_BUILDINGS + "_SUCCESS":
    case actions.GET_BUILDINGS + "_SUCCESS": {
      if (!action.payload?.projectId)
        return state;
      const { projectId, buildings } = action.payload;

      if (action.payload?.buildings) {
        let buildingsMap = new OrderedMap();
  
        Object.values(buildings).forEach(building => {
          building = new Building({ ...(building.toJS ? building.toJS() : building) });
          buildingsMap = buildingsMap.set(building.id, building);
        });
        state = state.setIn(['map', projectId], buildingsMap);
      }

      state = state.setIn(['didLoad', projectId], true);
      state = state.setIn(['lastUpdated', projectId], state.lastUpdateAvailable.get(projectId, DEFAULT_LAST_UPDATE_TS));
      return state;
    }

    case lastUpdatesActions.GET_LAST_UPDATES: {
      if (!action.payload || !action.payload.projects)
        return state;

      const { projects } = action.payload;

      Object.keys(projects).forEach(projectId => {
        let TS = projects.getNested([projectId, 'buildings', 'lastUpdateTS'], DEFAULT_LAST_UPDATE_TS)
        let curr = state.getNested(['lastUpdateAvailable', projectId], null)
        if (curr < TS) state = state.setIn(['lastUpdateAvailable', projectId], TS)
      });

      return state;
    }

    case actions.CLEAR_LOCAL_BUILDINGS: {
      const { projectId } = action.payload;
      if (!projectId)
        return state;

      return state.deleteIn(['map', projectId]);
    }


    case actions.END_BUILDINGS_LISTENER: {
      const { projectId } = action.payload;
      if (!projectId)
        return state;

      state = state.setIn(['didLoad', projectId], false);
      return state.deleteIn(['map', projectId]);
    }

    case actions.UPDATE_LOCAL_BUILDING + "_SUCCESS":
    case actions.UPDATE_BUILDING + "_SUCCESS": {
      let { projectId, building } = action.payload;   

      if (!action.payload || !building)
        return state;
      building = building.toJS ? building.toJS() : building;
      return state.setIn(['map', projectId, building.id], new Building({...building, isLocal: true}));
    }

    case actions.UPDATE_BUILDINGS + "_SUCCESS": {
      const { projectId, buildings } = action.payload;

      if (!projectId || !buildings)
        return state;

      buildings.loopEach((i, building) => {
        state = state.setIn(['map', projectId, building.id], new Building({...building, isLocal: true}));
      });

      return state;
    }
    
    case REPLACE_USER + "_SUCCESS": {
      return initialState;
    }
  }

  return state;
}