import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';

import {OBJECT_TYPES, objectToProperties} from '../../../common/propertiesTypes/convertor';
import SplitViewPage from '../../layouts/SplitViewPage';
import useDrawings from '../../hooks/useDrawings';
import useLocationGroups from '../../hooks/useLocationGroups';
import systemMessages from '../../../common/app/systemMessages';
import drawingsMessages from '../../../common/drawings/drawingsMessages';
import {hideLoading, startLoading, startToast} from "../../../common/app/actions";
import {uploadStatus} from '../../components/CementoComponents/UploadsComponent';
import newProjectMessages from '../../../common/projects/newProjectMessages';

const DrawingCardAdapter = ({
                              drawingObject,
                              closeSelectedDrawing,
                              mainComponent,
                              selectDrawing,
}) => {
  const dispatch = useDispatch();
  const [sideStack, setSideStack] = useState(null);
  const [fileUploadStatus, setFileUploadStatus] = useState(null);
  const [pendingSave, setPendingSave] = useState(false);
  const {
    locationGroupsValues, unitTagsValues, floorTagsValues,
    buildingTagsValues,
  } = useLocationGroups();
  const { rtl, trades, intl } = useSelector(state => ({
    rtl: state.app.rtl,
    trades: state.trades?.map,
    intl: state.app.intl,
  }));
  const { createDrawing, updateDrawing, canUpdate } = useDrawings();
  const cachedValue = useRef({});

  useEffect(() => {
    if (fileUploadStatus === uploadStatus.SUCCESS && pendingSave) {
      stopUploadingAnimation();
      onSave(cachedValue.current);
    }
  }, [fileUploadStatus, pendingSave, onSave]);

  const startUploadingAnimation = useCallback(() => {
    dispatch(startLoading({
      title: systemMessages.uploading,
      overlay: true,
      hideOnBackgroundPress: false,
      operationId: 'uploadingDrawing',
    }));
  }, []);

  const stopUploadingAnimation = useCallback(() => {
    dispatch(hideLoading('uploadingDrawing'));
  }, []);

  const onSave = useCallback(async (updates) => {
    if (fileUploadStatus === uploadStatus.IN_PROGRESS) {
      startUploadingAnimation();
      setPendingSave(true);
      cachedValue.current = {
        ...cachedValue.current,
        ...updates
      };
      return;
    }
    let drawing;
    if (!drawingObject.id) {
      drawing = await createDrawing(updates, drawingObject);
    } else {
      drawing = await updateDrawing(updates, drawingObject);
    }

    if (drawing) {
      cachedValue.current = {};
      setPendingSave(false);
      setFileUploadStatus(null);
      dispatch(startToast({
        title: systemMessages.objectSavedSuccessfully,
        values: { objectName: `Drawing` },
        type: 'success',
      }));
      selectDrawing(drawing, true);
    } else {
      dispatch(startToast({
        title: systemMessages.error,
        type: 'error',
      }));
    }
  }, [drawingObject, createDrawing, updateDrawing, closeSelectedDrawing, fileUploadStatus, startUploadingAnimation]);

  const onChange = useCallback((propId, data, status) => {
    if (propId === 'uri' && data && status) {
      setFileUploadStatus(status);

      if (status === uploadStatus.SUCCESS) {
        drawingObject.uriRemote = data; // this uri will be actually saved
        cachedValue.current.uriRemote = data;
      } else {
        if (data.title) {
          drawingObject.title = data.title;
          cachedValue.current.title = data.title;
        }

        drawingObject.uri = data.uri; // this uri will be displayed only in preview
        drawingObject.ext = data.extension;
        cachedValue.current.ext = data.extension;

        updateSideStack();
      }
    }
    if (propId === 'uri' && !data) {
      setFileUploadStatus(null);
      cachedValue.current = {};
      drawingObject.uri = null;
      drawingObject.ext = null;
    }
    if (propId === 'category') {
      drawingObject.category = data ? Object.keys(data)?.[0] : null;
    }
    if (propId !== 'uri' && propId !== 'category') {
      drawingObject[propId] = data;

      if (propId === 'locationType') {
        updateSideStack();
      }
    }
  }, [drawingObject]);

  useEffect(() => {
    updateSideStack();
  }, [drawingObject?.id, trades, locationGroupsValues, onSave, onChange]);

  const updateSideStack = () => {
    if (!drawingObject) {
      setSideStack(null);

      return;
    }

    const { types, mapping, instances, sections } = objectToProperties(drawingObject, OBJECT_TYPES.DRAWINGS);

    if (trades && types?.category) {
      types.category.values = trades;
    }

    types['-unitsTags'].values = unitTagsValues;
    types['-floorsTags'].values = floorTagsValues;
    types['-buildingsTags'].values = buildingTagsValues;
    types.locationType.values = [
      {
        id: '-buildings',
        title: intl.formatMessage(newProjectMessages.locationTypes.buildings),
        ordinalNo: 3
      },
      {
        id: '-floors',
        title: intl.formatMessage(newProjectMessages.locationTypes.floors),
        ordinalNo: 2
      },
      {
        id: '-units',
        title: intl.formatMessage(newProjectMessages.locationTypes.units),
        ordinalNo: 1
      },
    ];
    if (drawingObject.locationType) {
      const locationTypeString = _.first(_.keys(drawingObject.locationType));

      types.specificLocations.settings.filterType = locationTypeString.replace('-', '');
    }

    setSideStack([{
      type: "objectPropertiesPage",
      props: {
        objectId: drawingObject.id,
        objectTypeName: drawingObject?.id ? '' : drawingsMessages.add,
        objectName: drawingObject?.id ? drawingObject.title : '',
        onSave,
        onChange,
        types,
        sections,
        mapping,
        instances,
      },
    }]);
  }

  const mainCardStyles = {
    width: '95vw',
    maxWidth: '95vw',
  };

  return (
    <SplitViewPage
      rtl={rtl}
      mode={'modal'}
      onSideClose={closeSelectedDrawing}
      ratio={3 / 5}
      Main={mainComponent}
      SideStack={sideStack}
      isCreateMode={!drawingObject?.id}
      isEditAllowed={canUpdate}
      mainCardStyles={mainCardStyles}
      isForceUpdateEnabled
    />
  );
};

export default DrawingCardAdapter;