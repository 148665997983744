import React from 'react';

// Components
import InputField from './InputField';
import Button from './Button';

// Others
import theme from '../../assets/css/theme';
import _ from 'lodash';

//In order to use correctly this component you will need to provide:
//  * onSave function - the component will take care to remove forbidden characters and sand a map of legal keys and the emails as values to save func.
//                      if you do sent onSave function you must send includeUpdateButton=true
//  * emailsList

//
//Optional functions/variables to provide:
// * onChange - if you want to change the default behavior
// * name
// * key

class EmailsInputField extends React.Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.onSave = this.onSave.bind(this);
		this.state = {
			emailsList: [],
			value: '',
		};
	}

	componentWillMount() {
		this.setComponentData({ firstMount: true }, this.props);
	}

	componentWillReceiveProps(nextProps) {
		this.setComponentData(this.props, nextProps);
	}

	setComponentData = (props, nextProps) => {
		let newStateChanges = {};

		if (!_.isEqual(props.emailsList, nextProps.emailsList)) {
			newStateChanges.emailsList = _.values(nextProps.emailsList);
			newStateChanges.value = newStateChanges.emailsList.join(', ');
		}

		if (_.keys(newStateChanges).length) this.setState(newStateChanges);
	};

	/**
	 * Returns an array of emails from the given string
	 * @param {string} value
	 * @returns {string[]}
	 */
	getEmailsFromValue = value => {
		const splitString = value.split(',');
		let emailsSet = new Set();
		splitString.forEach(email => {
			const emailTrimmed = email.trim();
			if (emailTrimmed) emailsSet.add(email.trim());
		});

		return Array.from(emailsSet);
	};

	onChange(val) {
		const { onChange } = this.props;
		if (onChange) onChange(val);
		else {
			this.setState({ emailsList: this.getEmailsFromValue(val) });
		}

		this.setState({ value: val });
	}

	// send to the onSave function coming from props a map of the formatted emails
	onSave() {
		const { onSave } = this.props;
		let { emailsList } = this.state;
		let emailsMap = {};

		_.values(emailsList).forEach(email => {
			emailsMap[email.replaceAll('.', '_')] = email.trim();
		});

		if (onSave) onSave(emailsMap);
	}

	render() {
		const { style = {}, name, editMode, key, includeUpdateButton, buttonStyle = {}, buttonText } = this.props;
		const { value } = this.state;

		return (
			<>
				<InputField
					key={key || `emails_input_field`}
					disabled={!editMode}
					name={name || 'רשימת תפוצה'}
					type={'String'}
					value={value}
					onChange={this.onChange}
					style={{ marginBottom: theme.margin * 2 - 10, fontSize: theme.fontSizeH5 - 2, ...style }}
				/>
				{Boolean(includeUpdateButton) && (
					<Button
						title={buttonText || 'עדכן רשימת תפוצה'}
						style={{ textAlign: 'center', width: '30%', ...buttonStyle }}
						onClick={this.onSave}
					/>
				)}
			</>
		);
	}
}

export default EmailsInputField;
