import { Record } from '../transit';

const RCLReport = Record({
  id: null,
  type: null,
  createdTS: null,
  updatedTS: null,
  dateTS: null, 
  checklists: null,
  notes: null,
  isLocal: null,
  status: null,
  serialNumber: null,
}, 'rclReport');

export default RCLReport;