import { removeProjectsStorage } from "../projects/actions";
import { getSnapshotData } from '../lib/utils/utils';

export const GET_PROPERTIES_MAPPINGS = 'GET_PROPERTIES_MAPPINGS';
export const SAVE_GROUPS_MAPPINGS = 'SAVE_GROUPS_MAPPINGS';
export const CLEAN_PROPERTIES_MAPPINGS_CACHE_DATA = 'CLEAN_PROPERTIES_MAPPINGS_CACHE_DATA';

export function getPropertiesMappings(projectId, forceUpdate) {
  return {
    type: GET_PROPERTIES_MAPPINGS,
    payload: getSnapshotData({ api: 'properties/mappings', firebase: 'properties/mapping/projects', resourceName: 'propertiesMappings' }, projectId)
  };
}

export function saveGroupsMappings(projectId, subjectName, propertyTypeId, groupsMappingsMap) {
  return ({ validate, firebase, firebaseDatabase, removeEmpty, getState, dispatch, realmInstance, platformActions }) => {
    const getPromise = async () => {
      if (!projectId || !groupsMappingsMap || Object.keys(groupsMappingsMap).length == 0)
        return;

      var updates = {};
      updates['properties/mapping/projects/' + projectId + '/' + subjectName + '/' + propertyTypeId] = groupsMappingsMap;
      firebase.update(updates);
      return { projectId };
    };

    return {
      type: SAVE_GROUPS_MAPPINGS,
      payload: getPromise()
    };
  };
}



export function cleanPropertiesMappingsCachedData() {
  return ({ dispatch, getState, realmInstance, platformActions }) => {
    const getPromise = async () => {
      let projectIdsArray = [];
      let projectStateToRemove = [
        ['propertiesMappings', 'locationsMapping'],
        ['propertiesMappings', 'groupsMapping'],
        ['propertiesMappings', 'lastUpdated'],
        ['propertiesMappings', 'map'],
      ];

      getState().getNested(['projects', 'map'], {}).loopEach((k, p) => projectIdsArray.push(p.id));
      dispatch(removeProjectsStorage(projectIdsArray, projectStateToRemove));

    };
    return {
      type: CLEAN_PROPERTIES_MAPPINGS_CACHE_DATA,
      payload: getPromise()
    };
  };
}

