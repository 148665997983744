import * as actions from './actions';
import { getAppState } from '../configureMiddleware';
import Title from './title';
import { Map } from 'immutable';
import { Record } from '../transit';

const InitialState = Record({
  map: Map(),
  lastUpdateTS: 0
}, 'titles');

export default function titlesReducer(state = new InitialState, action) {
  switch (action.type) {
    case actions.GET_TITLES + "_SUCCESS": {
      if (!action.payload)
        return state;

      const { lastUpdateTS, titles } = action.payload;    
      var newMap = new Map();
      (titles || {}).loopEach((key, currTitle) => { 
        if (currTitle) {
          newMap = newMap.set(currTitle.id, new Title({ 
            id: currTitle.id, 
            types: currTitle.types, 
            title: currTitle.title, 
            systemTitle: currTitle.getNested(['title', 'en'],'')
          })); 
        }
      })
      state = state.set('lastUpdateTS', lastUpdateTS ? lastUpdateTS : 0);
      return state.set('map', newMap);
    }

    case actions.CLEAN_CACHED_TITLES: {
      return state.set('lastUpdateTS', 0);
    }
  }
  
  return state;
}
