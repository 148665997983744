import invariant from 'invariant';
import storage from 'redux-storage';
import storageDebounce from 'redux-storage-decorator-debounce';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { APP_STORAGE_LOAD, SAVE_APP_STORAGE } from '../app/actions';
import { isCollection } from 'immutable';
import { CementoRecordObject, cementoRecords, fromJSON, toJSON } from '../transit';
import { platformActions } from '../platformActions'
import { toJSDeep } from '../app/funcs';
import _fp from 'lodash/fp';
import _ from 'lodash';
import { tryCatch, debugParams } from '../lib/utils/utils';
import { getProjectStatePathes, getGlobalStatePathes, globalScopeSaveOnProjectStateToSave } from './statePathes';


const shouldMigrateFeatureToObject = (() => {
	/** @param {string} featureName */
	return featureName => Boolean(cementoRecords[featureName]);
})();

const invariantFeatureState = (state, feature) => invariant(
  isCollection(state[feature]),
  `Storage persists only immutable iterables. '${feature}' is something else.`
);

const isImmutable = value =>
	Boolean(value && (value.toJS || (typeof value === 'object' && (Object.values(value)[0] || {}).toJS)));

const updateGlobalState = (state, storageStateJson) => {

  if (!storageStateJson)  // TODO: The workaround is ugly but it should work. If a value exist the it return a huge string. if not then it reutun only {}. For performance reason we don't want to parse the entire string for this checkup
    return state;
  
  let stateLoadFeatures = {};
  const globalStateToSave = getGlobalStatePathes();
  globalStateToSave.forEach(([feature, ...featurePath]) => {
    if (!stateLoadFeatures[feature]) {
      stateLoadFeatures[feature] = {};
    }
    if (featurePath && featurePath.length > 0) {
      stateLoadFeatures[feature][featurePath[0]] = true;
    }
  });

  for (const { feature, featurePath, value } of storageStateJson) {
    try {
      if (stateLoadFeatures[feature] && stateLoadFeatures[feature][featurePath]) {
        const isFeatureMigratedToObject = shouldMigrateFeatureToObject(feature);
        
        let parsedValue = isFeatureMigratedToObject 
                            ? (typeof value === 'string' ? tryCatch(() => fromJSON(value)).data || value : value)
                            : fromJSON(value);
        if (isFeatureMigratedToObject && parsedValue && (isImmutable(parsedValue) || platformActions.app.getPlatform() !== "web"))
          parsedValue = toJSDeep(parsedValue, CementoRecordObject);

        state[feature] = state[feature].setIn(featurePath, parsedValue);
        _.set(lastFeaturesStateByScopeId, ['global',feature], state[feature]);
      }
      else 
        console.warn('Feature should not be saved: ' + feature + " path:" + featurePath)
    } catch (error) {
      // Shouldn't happen, but if the data's invalid, there's not much we can do.
      console.warn('ERROR ON LOADING STATE')
      console.warn(error)
    }
  }

  state = state.setNested(['app', 'storageLoaded'], true);
  return state;
};

var lastFeaturesStateByScopeId = {};
const updateProjectState = (state, payload) => {
  const { projectSavedJson, projectId } = payload;
  
  if (!projectId) {
    console.warn('MISSING projectId for load!!!!!')
    return state;
  }

  if (!projectSavedJson)
    return state;
  
  let newState = Object.assign({}, state)
  var stateLoadFeatures = {};

  let projectPathesToSave = getProjectStatePathes();
  projectPathesToSave.forEach(([feature, ...featurePath]) => {
    if (!stateLoadFeatures[feature])
      stateLoadFeatures[feature] = {};
    if (featurePath && featurePath.length > 0)
      stateLoadFeatures[feature][featurePath[0]] = true;
  });

  for (const { feature, featurePath, value } of projectSavedJson) {
    try {
      if (stateLoadFeatures[feature] && stateLoadFeatures[feature][featurePath]) {
        const isFeatureMigratedToObject = shouldMigrateFeatureToObject(feature);

        let parsedValue = isFeatureMigratedToObject 
                            ? (typeof value === 'string' ? tryCatch(() => fromJSON(value)).data || value : value)
                            : fromJSON(value);
        if (isFeatureMigratedToObject && parsedValue)// && (isImmutable(parsedValue) || platformActions.app.getPlatform() !== "web"))
          parsedValue = toJSDeep(parsedValue, CementoRecordObject);

        newState[feature] = newState[feature].setIn(featurePath.concat(projectId), parsedValue);
        _.set(lastFeaturesStateByScopeId, [projectId, feature], newState[feature]);
      }
      else
        console.warn('Feature should not be saved: ' + feature + " path:" + featurePath)
    } catch (error) {
      // Shouldn't happen, but if the data's invalid, there's not much we can do.
      console.warn('ERROR ON LOADING STATE')
      console.warn(error)
    } 
  }

  newState['projects'] = newState['projects'].setIn(['projectReducersLoaded', projectId], true);
  return newState;
};

const saveGlobalStorage = (state, engine, forceSave) => {
  var didLoad = state && state.app && state.app.storageLoaded;
  if (!didLoad) {
    console.warn('SAVE BEFORE LOAD!!!!!')
    return;
  }
  saveToStorage(state, getGlobalStatePathes(), 'global', forceSave);
}

const saveType = 'async';
const saveProjectStorage = (state, payload, deleteAfterSave) => {
  if (payload && payload.projectId)
    var { projectId } = payload;
  else
    var projectId = state.ui.currProject;

  if (process.env.NODE_ENV !== 'production' && !projectId) {
    console.log('MISSING projectId for save!!!!!')
    return;
  }

  saveToStorage(state, getProjectStatePathes(), projectId);
  saveToStorage(state, globalScopeSaveOnProjectStateToSave, 'global');
  if (deleteAfterSave)
    delete lastFeaturesStateByScopeId[projectId];
}

const saveToStorage = async (state, stateFieldsToSave, scopeId, forceSave) => {
  if (debugParams.disableSaveToStorage)
    return;
    
	if (!scopeId) {
		console.warn('MISSING key for save!!!!!');
		return;
	}

	let saveState = [];
	let lastCurrState = lastFeaturesStateByScopeId[scopeId] || {};
	let changesStatesList = {};
	stateFieldsToSave.forEach(([feature, ...featurePath]) => {
		let currFeaturePath = scopeId != 'global' ? featurePath.concat(scopeId) : featurePath;
		if (
			forceSave ||
			!(
				Boolean(lastCurrState) &&
				lastCurrState.getNested([feature].concat(currFeaturePath)) == state.getNested([feature].concat(currFeaturePath))
			)
		) {
			saveState.push({
				feature,
				featurePath,
				value: state.getNested([feature].concat(currFeaturePath)),
			});
			changesStatesList[feature] = true;
		}
	});

	Object.keys(changesStatesList).forEach(feature => {
		_.set(lastFeaturesStateByScopeId, [scopeId, feature], state.getNested([feature]));
	});

	for (const { feature, featurePath, value } of saveState) {
		const isFeatureMigratedToObject = shouldMigrateFeatureToObject(feature);

		let configKey = '@' + feature + '_' + featurePath + ':' + scopeId;
		try {
			let isDelete = false;
			let formattedJSON = null;
			if (value == null || value == undefined) isDelete = true;
			else {
				if (isFeatureMigratedToObject) {
					const formattedValue = isImmutable(value) ? toJSDeep(value, CementoRecordObject) : value; // The dixie would save the CementoRecordObject as an object in anycase

					formattedJSON = platformActions.app.getPlatform() !== 'web' ? JSON.stringify(formattedValue) : formattedValue;
				} else formattedJSON = toJSON(value);

				if (process.env.NODE_ENV !== 'production' && formattedJSON.length > 5000)
					console.log(formattedJSON.length + ' : ' + configKey);
			}
			if (platformActions.app.getPlatform() == 'ios' || platformActions.app.getPlatform() == 'web') {
				if (isDelete) platformActions.storage.removeItem(configKey);
				else platformActions.storage.setItem(configKey, formattedJSON);
			} else {
				var configName = 'cemento_' + configKey + '.cfg';
				var fileLocation = platformActions.fs.getDocumentDirectoryPath() + '/cemento/' + configName;
				if (isDelete)
					platformActions.fs.deleteFile(fileLocation).catch(error => {
						platformActions.sentry.notify(error, {
							function: 'saveToStorage',
							action: 'delete',
							fileLocation,
						});
					});
				else
					platformActions.fs.writeFile(fileLocation, formattedJSON, 'utf8').catch(error => {
						platformActions.sentry.notify(error, {
							function: 'saveToStorage',
							action: 'write',
							fileLocation,
							formatedJsonLength: (formattedJSON || '').length,
						});
					});
			}
		} catch (err) {
			console.error('ERROR SAVING STORAGE', configKey, value, err);
		}
	}
};

const saveBothStorages = state => {
	return new Promise((resolve, reject) => {
		saveProjectStorage(state);
		saveGlobalStorage(state);
		resolve();
	});
};

const storageFilter = engine => ({
	...engine,
	save(state) {
		return saveBothStorages(state);
	},
});

const createStorageMiddleware = storageEngine => {
	let decoratedEngine = storageFilter(storageEngine);
	decoratedEngine = storageDebounce(decoratedEngine, 30000);
	return storage.createMiddleware(decoratedEngine);
};

const cleanProjectState = (state, payload) => {
  let projectId;
  if (payload && payload.projectId)
    projectId = payload.projectId;
  else
    projectId = state.ui.currProject;

  let newState = state;
  if (projectId) {
    newState = Object.assign({}, state);
    const projectStateToClean = getProjectStatePathes();
    (projectStateToClean).forEach(([feature, ...featurePath]) => {
      const projectFeaturePath = featurePath.concat(projectId);
      if (feature === 'projects' && featurePath.includes('didLoad'))
        return;

      newState[feature] = newState[feature].deleteIn(projectFeaturePath);
    });
  }

  newState['projects'] = newState['projects'].setIn(['projectReducersLoaded', projectId], false);

  return newState;
}

const saveGlobalStorageDebounced = AwesomeDebouncePromise(saveGlobalStorage, 1000);

export const updateStateOnStorageLoad = reducer => (state, action) => {
	if (action.type === APP_STORAGE_LOAD) state = updateGlobalState(state, action.payload);
	else if (action.type === 'LOAD_PROJECT_STORAGE' + '_SUCCESS') {
		state = updateProjectState(state, action.payload);
	}

	state = reducer(state, action);

	if (action.type === 'SAVE_PROJECT_STORAGE' + '_SUCCESS') saveProjectStorage(state, action.payload, false);

	if (action.type === 'LEAVE_PROJECT') {
		saveProjectStorage(state, action.payload, true);
		saveGlobalStorage(state);
		state = cleanProjectState(state, action.payload);
	} else if (action.type == SAVE_APP_STORAGE || (action.payload && action.payload.immediateGlobalStorageSave)) {
		// The "SAVE_APP_STORAGE" is on false because it happen when the app come back and stuck the UI
		saveGlobalStorageDebounced(state);
	}

	return state;
};

export default function configureStorage(initialState, createStorageEngine) {
	const storageEngine = createStorageEngine && createStorageEngine(`redux-storage:${initialState.config.appName}`);
	const storageMiddleware = storageEngine && createStorageMiddleware(storageEngine);

	return {
		STORAGE_SAVE: storage.SAVE,
		storageEngine,
		storageMiddleware,
	};
}
