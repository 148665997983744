import React from "react";
import theme from "../../assets/css/theme";

import toggleRight from "../../assets/img/icons/toggleRight.png";
import toggleLeft from "../../assets/img/icons/toggleLeft.png";
import { compose, hoistStatics } from "recompose";

import { connectContext } from "react-connect-context";
import moment from "moment";
import _ from "lodash";
import {
  TableContext,
  ReportContext,
} from "../../../common/analytics/contexts";
import { safeFormatMessage } from '../../../common/app/funcs';
import { injectIntl } from '../../../common/app/myInjectIntl';

const INDENTATION_WIDTH = 8;
const BORDER_HEIGHT = 1;
class TableCollapsibleCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMouseInside: false,
    };
    this.mouseMovementHandler = this.mouseMovementHandler.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.forwardRef)
      this.props.forwardRef.current = { component: this };
  }

  mouseMovementHandler() {
    const { cell, rowsWithChildrens, id } = this.props;
    const { isMouseInside } = this.state;
    let isExpandable =
      (cell && cell.row && Object.keys(cell.row.subRows || {}).length) ||
      rowsWithChildrens[id];
    if (isExpandable) this.setState({ isMouseInside: !isMouseInside });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.forwardRef && this.props.isValDiff(nextProps, ["rowIndex"]))
      nextProps.forwardRef.current = { component: this };
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldComponentUpdate = false;
    if (this.state != nextState) shouldComponentUpdate = true;

    const titleChanged = this.props.title !== nextProps.title;
    const prevCellRowId = this.props.cell.row.id;
    const nextCellRowId = nextProps.cell.row.id;
    const prevSelectedCellId =
      this.props.selectedCell && this.props.selectedCell.cell.row.id;
    const nextSelectedCellId =
      nextProps.selectedCell && nextProps.selectedCell.cell.row.id;
    const wasThisRowSelected = prevSelectedCellId === prevCellRowId;
    const isThisRowSelected = nextSelectedCellId === nextCellRowId;

    shouldComponentUpdate = Boolean(
      shouldComponentUpdate ||
        titleChanged ||
        (wasThisRowSelected && !isThisRowSelected) ||
        (!wasThisRowSelected && isThisRowSelected)
    );

    if (!shouldComponentUpdate)
      shouldComponentUpdate =
        this.props.visibleRows != nextProps.visibleRows ||
        this.props.locationId != nextProps.locationId ||
        this.props.rowsWithChildrens != nextProps.rowsWithChildrens;

    return shouldComponentUpdate;
  }

  handleCellClick = () => {
    // !!!!! WARNING !!!!! do not change the name, there are references to this function in TableWrapper.js
    const {
      id,
      cell,
      firstColumnCellClickHandler,
      rowsWithChildrens,
      cellClickHandler,
      rowsLevel,
      dismissClickHandler,
      rowIndex,
    } = this.props;

    const isLastRowLevel = cell.row.rowLevel === rowsLevel;
    const isRowExpandable = Boolean(rowsWithChildrens[id]);

    if (isLastRowLevel && !dismissClickHandler && cellClickHandler)
      cellClickHandler(cell, undefined, undefined, rowIndex);
    else if (isRowExpandable && firstColumnCellClickHandler)
      firstColumnCellClickHandler(cell.row);
  };

  render() {
    const {
      id,
      forwardRef,
      title,
      cell,
      firstColumnCellClickHandler,
      visibleRows,
      selectedCell,
      rowsWithChildrens,
      rtl,
      cellHeight,
      cellClickHandler,
      rowsLevel,
      dismissClickHandler,
      colWidth,
      intl,
    } = this.props;
    const { isMouseInside } = this.state;
    const columnId = cell.column.mainColumnId;
    const rowValues = cell.row.values;
    let isExpanded =
      visibleRows &&
      visibleRows[cell.row.id] &&
      rowsLevel &&
      rowsLevel - 1 === cell.row.rowLevel;
    let marginRight =
      (cell.row.rowLevel || 1) - 1 * theme.verticalMargin + theme.margin;

    let style = {
      alignItems: "center",
      zIndex: theme.zIndexes.tableCollapsibleCell,
      position: "sticky",
      right: 0,
      backgroundColor: "inherit",
    };
    const isLastRowLevel = cell.row.rowLevel === rowsLevel;
    const isFirstOfMultipleCollapsible = rowsLevel > 2 && cell.row.rowLevel == 1
    const isRowExpandable = Boolean(rowsWithChildrens[id]);
    const expandableTable = rowsLevel > 1;

    if ((isRowExpandable || isLastRowLevel) && !dismissClickHandler)
      style.cursor = "pointer";

    let cellContent =
      title || (rowValues[columnId] && rowValues[columnId].displayValue);

    const dateFormatString = (
      cell.row?.values?.[columnId]?.displayParams?.dateFormat ||
      cell.column?.original?.original?.settings?.dateFormat ||
      null
    );

    if (dateFormatString)
      cellContent = moment(cellContent).format(safeFormatMessage(intl, dateFormatString));

    let isRowSelected = false;
    if (
      selectedCell &&
      selectedCell.cell &&
      selectedCell.cell.row &&
      cell &&
      cell.row &&
      selectedCell.cell.row.id == cell.row.id
    ) {
      isRowSelected = true;
    }

    let rowNestedLevel = (cell.row.rowLevel || 1) - 1;
    let indentationWidth =
      isLastRowLevel && cell.row.parentId ? INDENTATION_WIDTH : 0;

    let paddingIndentation = marginRight + marginRight * rowNestedLevel;
    return (
      <td
        key={id}
        onMouseEnter={this.mouseMovementHandler}
        onMouseLeave={this.mouseMovementHandler}
        className={"FirstColumn"}
        style={{ ...style, paddingBottom: isRowSelected ? BORDER_HEIGHT : 0 }}
        onClick={this.handleCellClick}
      >
        {Boolean(isRowSelected) && !dismissClickHandler && (
          <div
            style={{
              backgroundColor: theme.brandPrimary,
              height: BORDER_HEIGHT,
              width: "100%",
            }}
          />
        )}

        <div
          style={{
            background: theme.brandPrimary,
            position: "absolute",
            top: -1,
            bottom: 0,
            [rtl ? "right" : "left"]: 0,
            width: indentationWidth,
            zIndex: theme.zIndexesLevels.ten,
          }}
        />

        <div
          style={{
            [rtl ? "paddingRight" : "paddingLeft"]: paddingIndentation,
            zIndex: theme.zIndexes.tableCollapsibleCell + 2,
            backgroundColor: isExpanded ? theme.brandPrimary : "inherit",
            display: "flex",
            alignItems: "center",
            justifyContent: expandableTable ? "flex-start" : "center",
            height: cellHeight - (isRowSelected ? BORDER_HEIGHT * 3 : 0),
          }}
        >
          <span
            title={cellContent}
            style={{
              fontWeight: isRowExpandable ? 900 : 400,
              color: isExpanded ? "white" : theme.fontColor,
              fontSize: isFirstOfMultipleCollapsible ? theme.fontSizeH6 : theme.fontSize,
              textAlign: "initial",
              width: colWidth-paddingIndentation,
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow:"hidden"
            }}
          >
            {cellContent}
          </span>

          {Boolean(isRowExpandable) && (
            <img
              src={toggleRight}
              style={{
                transform: isExpanded ? "rotate(270deg)" : "rotate(90deg)",
                height: 10,
                opacity: isRowExpandable && isMouseInside ? 1 : 0,
                transition: "opacity 0.5s",
                position: "absolute",
                bottom: theme.margin,
                [rtl ? "left" : "right"]: theme.margin,
              }}
            />
          )}
        </div>
        {Boolean(isRowSelected) && !dismissClickHandler && (
          <div
            style={{
              backgroundColor: theme.brandPrimary,
              height: BORDER_HEIGHT,
              width: "100%",
            }}
          />
        )}
      </td>
    );
  }
}

const enhance = compose(
  connectContext(ReportContext.Consumer),
  connectContext(TableContext.Consumer),
  injectIntl,
);
export default enhance(TableCollapsibleCell);
