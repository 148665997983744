import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose, hoistStatics } from 'recompose';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../../../common/projects/contexts';
import propertiesMessages from '../../../common/propertiesTypes/propertiesMessages';
import { NoValueComponent } from './InputField';
import { getLocationTitlesParams } from '../../../common/locations/func';
import _ from 'lodash';

const LOCATION_TITLE_SEPARATOR = ', ';
class CondencedLocationNames extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			locationsNamesParams: [],
		};
	}

	componentDidMount() {
		this.setComponentData({}, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		this.setComponentData(this.props, nextProps);
	}

	setComponentData = (props, nextProps) => {
		let newStateChanges = {};

		let projectStructureChanged =
			props.isValDiff(nextProps, ['buildings']) ||
			props.isValDiff(nextProps, ['floors']) ||
			props.isValDiff(nextProps, ['units']);

		if (props.isValDiff(nextProps, ['locations']) || projectStructureChanged)
			newStateChanges.locationsNamesParams = this.getNamesParams(nextProps);

		if (Object.keys(newStateChanges).length) this.setState(newStateChanges);
	};

	getNamesParams = nextProps => {
		const { locations, intl, buildings, floors, units } = nextProps || this.props;
		const locationIds = _.values(locations).map(location => location.id);
		return getLocationTitlesParams(intl, buildings, floors, units, locationIds, LOCATION_TITLE_SEPARATOR);
	};

	renderLocationNameParams = (locationNameParams, index) => {
		let name = '';

		if (locationNameParams.titleRoot) name += `${locationNameParams.titleRoot} `;

		name += locationNameParams.titles.join(LOCATION_TITLE_SEPARATOR);

		return <div key={'selectedLocation-' + name}>{`- ${name}`}</div>;
	};

	render() {
		const { style = {} } = this.props;
		const { locationsNamesParams } = this.state;

		return (
			<>
				<div style={style}>
					{Boolean((locationsNamesParams || []).length) ? (
						locationsNamesParams.map(this.renderLocationNameParams)
					) : (
						<NoValueComponent defaultText={propertiesMessages.empty} />
					)}
				</div>
			</>
		);
	}
}

CondencedLocationNames = injectIntl(CondencedLocationNames);
const enhance = compose(
	connectContext(ProjectContext.Consumer),
	connect(state => ({}), {}),
);

export default enhance(CondencedLocationNames);
