import React from 'react';
import { compose, hoistStatics } from 'recompose';
import { connectContext } from 'react-connect-context';
import _ from 'lodash';

// assets
import '../../assets/css/table.css';
import theme from '../../assets/css/theme';

import NoItemsFound from '../../components/CementoComponents/NoItemsFound';
import analyticsMessages from '../../../common/analytics/analyticsMessages';
import Paging from '../../components/CementoComponents/Paging';

import { columnTypes } from '../../../common/analytics/funcs';
import { TableContext, ReportContext } from '../../../common/analytics/contexts';
import TableExpandColumn from './TableExpandColumn';
import TableFirstColumn from './TableFirstColumn';
import TableSection from './TableSection';

export const sectionHeight = 17 * 3;
const defaultPaginationStep = 25;

class Table extends React.Component {
	constructor(props) {
		super(props);
		this.rowsWithValuesCalculator = this.rowsWithValuesCalculator.bind(this);
		const { rowsWithValues, rowsWithChildrens } = this.rowsWithValuesCalculator(props);

		this.state = { currPage: 0, rowsWithValues, rowsWithChildrens, tableContext: { rowsWithChildrens } };
	}

	shouldComponentUpdate(nextProps, nextState) {
		var shouldComponentUpdate =
			this.props.minHeight != nextProps.minHeight ||
			this.props.isValDiff(nextProps, ['sortColumn']) ||
			this.state.isValDiff(nextState, ['currPage']) ||
			this.props.isValDiff(nextProps, ['data']) ||
			this.props.isValDiff(nextProps, ['expandedColumns']) ||
			this.props.isValDiff(nextProps, ['visibleRows']) ||
			this.props.isValDiff(nextProps, ['columnSections']);

		return shouldComponentUpdate;
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.isValDiff(nextProps, ['sortColumn']) || this.props.isValDiff(nextProps, ['rowsFilterTS']))
			this.setState({ currPage: 0 });
		if (
			this.props.isValDiff(nextProps, ['columnSections']) ||
			this.props.isValDiff(nextProps, ['columnFilter']) ||
			this.props.isValDiff(nextProps, ['showRowsWithEmptyValue']) ||
			this.props.isValDiff(nextProps, ['data']) ||
			this.props.isValDiff(nextProps, ['allRows'])
		) {
			const { rowsWithValues, rowsWithChildrens } = this.rowsWithValuesCalculator(nextProps);
			this.setState({ rowsWithValues, rowsWithChildrens, tableContext: { rowsWithChildrens } });
		}
	}

	rowsWithValuesCalculator(props) {
		const { columnSections, columnFilter, showRowsWithEmptyValue, allRows } = props;
		// This is only a visual filter, collapse and expand, not effecting the calcualtion
		const data = Object.values(props.data || {});
		// TODO: Remove this to TableWrapper so the pagination counter would be correct
		let rowsWithChildrens = {};
		let rowsWithValues = {};
		for (let i = 0; Boolean(data) && i < data.length; i++) {
			let row = data[i];

			let didFoundCellWithValue = false;
			(columnSections || []).forEach((columnSection, sectionIndex, arr) => {
				if (columnSection.columns)
					columnSection.columns.forEach(currColumn => {
						if (Boolean(columnFilter) && !columnFilter(currColumn)) return null;

						// Add the parent value that says he have kids
						if (row.parentId && !rowsWithChildrens[row.parentId] && row.values[currColumn.HeaderValue] != null) {
							rowsWithChildrens[row.parentId] = true;
							if (
								allRows[row.parentId] &&
								allRows[row.parentId].parentId &&
								!rowsWithChildrens[allRows[row.parentId].parentId]
							)
								rowsWithChildrens[allRows[row.parentId].parentId] = true;
						}
						if (Object.keys(row.subRows || {}).length) rowsWithChildrens[row.id] = true;

						if (
							!didFoundCellWithValue &&
							(showRowsWithEmptyValue || typeof row.values[currColumn.HeaderValue] != 'undefined')
						)
							didFoundCellWithValue = true;
					});
			});

			if (didFoundCellWithValue) {
				rowsWithValues[row.id] = true;
				if (row.parentId && !rowsWithValues[row.parentId]) rowsWithValues[row.parentId] = true;

				if (
					row.parentId &&
					allRows[row.parentId] &&
					allRows[row.parentId].parentId &&
					!rowsWithValues[allRows[row.parentId].parentId]
				)
					rowsWithValues[allRows[row.parentId].parentId] = true;
			}
		}

		// Check if there was really a change, that may effect re-renders of items
		let didRowsWithValuesFoundDiff = false;
		let didRowsWithChildrensDiff = false;

		if (this.state) {
			didRowsWithValuesFoundDiff = !_.isEqual(this.state.rowsWithValues, rowsWithValues);
			didRowsWithChildrensDiff = !_.isEqual(this.state.rowsWithChildrens, rowsWithChildrens);
		} else {
			didRowsWithValuesFoundDiff = true;
			didRowsWithChildrensDiff = true;
		}

		if (!didRowsWithValuesFoundDiff) rowsWithValues = this.state.rowsWithValues;
		if (!didRowsWithChildrensDiff) rowsWithChildrens = this.state.rowsWithChildrens;

		return { rowsWithValues, rowsWithChildrens };
	}

	render() {
		const {
			columnSections,
			style,
			minHeight,
			visibleRows,
			expandedColumns,
			columnFilter,
			rowsFilter,
			showRowsWithEmptyValue,
			allRows,
			tableRef,
			clearExpanded,
			afterExpandOrFoldColumn,
			expandableTable,
			paginationStep = defaultPaginationStep,
			customWidth,
		} = this.props;
		const { currPage, rowsWithValues, tableContext } = this.state;

		const data = Object.values(this.props.data || {});
		let visibleRowsWitchValues = {};

		let filteredRows = Object.values(allRows || {}).filter(x => rowsWithValues[x.id]);
		filteredRows.forEach(x => {
			if (!Boolean(rowsFilter) || (allRows[x.id] && rowsFilter(allRows[x.id], visibleRows)))
				visibleRowsWitchValues[x.id] = true;
		});

		let allPagesRowsCount = 0;
		if (!expandableTable) {
			allPagesRowsCount = Object.keys(visibleRowsWitchValues || {}).length;
			let paginationStart = currPage * paginationStep;
			filteredRows = (filteredRows || []).slice(paginationStart, paginationStart + paginationStep);

			visibleRowsWitchValues = {};
			filteredRows.forEach(x => {
				if (!Boolean(rowsFilter) || (allRows[x.id] && rowsFilter(allRows[x.id], visibleRows)))
					visibleRowsWitchValues[x.id] = true;
			});
		}

		let sectionsColumnsComponents = [];
		(columnSections || []).forEach((currColumnSection, i, arr) => {
			if (i === 0) currColumnSection.style.zIndex = theme.zIndexes.tableMainSectionColumn;
			sectionsColumnsComponents.push(
				<TableSection
					key={'section-header-'.concat(currColumnSection.original.id)}
					section={currColumnSection}
					columnFilter={columnFilter}
				/>,
			);

			if (i > 0 && i < arr.length - 1)
				sectionsColumnsComponents.push(
					<th
						key={'sep-2-'.concat(currColumnSection.original.id)}
						style={{ borderTopStyle: 'none', backgroundColor: 'white', top: 0, zIndex: theme.zIndexes.tableSectionColumn, position: 'sticky' }}
					>
						<div style={{ width: 10 }} />
					</th>,
				);
		});

		let columnsComponents = [];
		(columnSections || []).forEach((currColumnSection, i, arr) => {
			if (currColumnSection.columns) {
				currColumnSection.columns.forEach(currColumn => {
					if (!Boolean(columnFilter) || columnFilter(currColumn)) {
						let addClassNames = currColumn.headerClassName || '';
						addClassNames.concat(' column');
						if (expandedColumns == currColumn.mainColumnId) addClassNames = addClassNames.concat(' expended');
						let isSubColumn = Boolean(currColumn.columnType == columnTypes.sub);
						if (isSubColumn) return;

						if (currColumn.isFirst)
							columnsComponents.push(
								<TableFirstColumn
									key={'expand-location'}
									data={data}
									rowsWithValues={visibleRowsWitchValues}
									rowsFilter={rowsFilter}
									column={currColumn}
									subColumns={currColumn}
									customWidth={customWidth}
								/>,
							);
						else
							columnsComponents.push(
								<TableExpandColumn
									afterExpandOrFold={afterExpandOrFoldColumn}
									key={'expand-'.concat(currColumn.original.id)}
									column={currColumn}
									subColumns={Boolean(currColumn && currColumn.original) ? currColumn.subColumns : null}
									data={data}
									rowsWithValues={visibleRowsWitchValues}
									rowsFilter={rowsFilter}
								/>,
							);
					}
				});
			}

			if (i > 0 && i < arr.length - 1)
				columnsComponents.push(
					<th
						key={'sep-3-'.concat(currColumnSection.original.id)}
						style={{ backgroundColor: 'white', top: -1, position: 'sticky' }}
					>
						<div style={{ width: 10 }} />
					</th>,
				);
		});

		let opacityStyle = {
			position: 'absolute',
			top: sectionHeight + 1,
			bottom: 0,
			right: 0,
			left: 0,
			zIndex: theme.zIndexes.tableColumn,
			backgroundColor: 'transparent',
			cursor: 'pointer',
			transition: 'opacity 1s',
		};
		if (expandedColumns)
			// Make as items of it's own, with opacity on and off and animation
			opacityStyle = Object.assign(opacityStyle, { visibility: 'visible', opacity: 0.52 });
		else opacityStyle = Object.assign(opacityStyle, { visibility: 'hidden', opacity: 0 });

		let opacityDiv = <tr onClick={clearExpanded ? clearExpanded : undefined} style={opacityStyle} />;

		if (columnsComponents && columnsComponents.length == 1)
			return <NoItemsFound minHeight={minHeight} message={analyticsMessages.NoValuesFound} />;

		return (
			<TableContext.Provider value={tableContext}>
				<table
					ref={tableRef}
					className='AnalyticsTable'
					style={{ ...style, minHeight: minHeight, display: 'inline-block' }}
				>
					<tbody style={{ position: 'relative', display: 'inline-block' }}>
						{opacityDiv}
						<tr>{sectionsColumnsComponents}</tr>

						<tr>{columnsComponents}</tr>
					</tbody>
				</table>
				<div style={{ display: 'flex', height: 60 }}>
					{Boolean(!expandableTable) && (
						<Paging
							style={{
								display: 'flex',
								width: '100%',
								alignSelf: 'center',
								justifyContent: 'center',
								alignItems: 'center',
								left: '50vw',
								bottom: theme.paddingSize,
								zIndex: theme.zIndexes.tablePagination,
							}}
							currPage={currPage}
							onClick={page => this.setState({ currPage: page })}
							dataCount={allPagesRowsCount}
							countPerPage={paginationStep}
						/>
					)}
				</div>
			</TableContext.Provider>
		);
	}
}

const enhance = compose(connectContext(ReportContext.Consumer));
export default enhance(Table);

Table.defaultProps = {
	paginationStart: 0,
};
