import * as actions from './actions';
import * as lastUpdatesActions from '../lastUpdates/actions';
import { REPLACE_USER } from '../auth/actions';

import Unit from './unit';

import { OrderedMap, Map } from 'immutable';
import { Record } from '../transit';
import { DEFAULT_LAST_UPDATE_TS } from '../app/constants';

const InitialState = Record({
  map: OrderedMap(),
  didLoad: OrderedMap(),
  lastUpdated: Map(),
  lastUpdateAvailable: Map(),  
}, 'units');

const initialState = new InitialState;

export default function unitReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    case actions.GET_UNITS + "_STARTED": {
      if (!action.payload)
        return state;

      const { projectId } = action.payload;
      return state.setIn(['didLoad', projectId], false);
    }

    case actions.GET_LOCAL_UNITS + "_SUCCESS":
    case actions.GET_UNITS + "_SUCCESS": {
      if (!action.payload?.projectId)
        return state;

      const { projectId, units } = action.payload;

      if (units) {
        let unitsMap = new OrderedMap();
        Object.entries(units).forEach(([buildingId, buildingUnits = {}]) => {
          let buildingUnitsMap = new OrderedMap();
  
          Object.values(buildingUnits).forEach(unit => {
            unit = new Unit({ ...(unit.toJS ? unit.toJS() : unit) });
            buildingUnitsMap = buildingUnitsMap.set(unit.id, unit);
          });
  
          unitsMap = unitsMap.set(buildingId, buildingUnitsMap);
        });
  
        state = state.setIn(['map', projectId], unitsMap);
      }
    
      state = state.setIn(['didLoad', projectId], true);
      state = state.setIn(['lastUpdated', projectId], state.lastUpdateAvailable.get(projectId, DEFAULT_LAST_UPDATE_TS));
      
      return state;
    }

    case actions.UPDATE_LOCAL_UNIT + "_SUCCESS":
    case actions.UPDATE_UNIT + "_SUCCESS": {
      
      if (!action.payload)
        return state;

      const { projectId, buildingId, unit } = action.payload;
      if (!projectId || !buildingId || !unit)
        return state;

      state = state.setIn(['map', projectId, buildingId, unit.id], new Unit({...unit, isLocal: true}));

      return state;
    }

    case actions.UPDATE_LOCAL_UNITS + "_SUCCESS":
    case actions.UPDATE_UNITS + "_SUCCESS": {
      
      if (!action.payload)
        return state;
      const { projectId, buildingId, units } = action.payload;

      if (!projectId || !buildingId || !units)
        return state;
      
      units.loopEach((i, unit) => {
        unit = unit.toJS ? unit.toJS() : unit;
        state = state.setIn(['map', projectId, buildingId, unit.id], new Unit({ ...unit, isLocal: true }));
      });

      return state;
    }

    case lastUpdatesActions.GET_LAST_UPDATES: {
      if (!action.payload || !action.payload.projects)
        return state;

      const { projects } = action.payload;

      Object.keys(projects).forEach(projectId => {
        let TS = projects.getNested([projectId, 'units', 'lastUpdateTS'], DEFAULT_LAST_UPDATE_TS);
        let curr = state.getNested(['lastUpdateAvailable', projectId], null)
        if (curr < TS) state = state.setIn(['lastUpdateAvailable', projectId], TS)
      });

      return state;
    }

    case actions.CLEAR_LOCAL_UNITS: {
      const { projectId } = action.payload;
      if (!projectId)
        return state;

      return state.deleteIn(['map', projectId]);
    }


    case actions.END_UNITS_LISTENER: {
      const { projectId } = action.payload;

      if (!projectId)
        return state;

      state = state.setIn(['didLoaded', projectId], false);
      return state.deleteIn(['map', projectId]);
    }

    case REPLACE_USER + "_SUCCESS": {
      return initialState;
    }
  }

  return state;
}