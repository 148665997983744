import * as actions from './actions';
import * as lastUpdatesActions from '../lastUpdates/actions';
import PropertiesMapping from './propertiesMapping';
import { Map } from 'immutable';
import { Record } from '../transit';
import _ from 'lodash';
import { DEFAULT_LAST_UPDATE_TS } from '../app/constants';

const InitialState = Record({
  map: Map(),
  didLoad: Map(),
  lastUpdated: Map(),
  lastUpdateAvailable: Map(),  
}, 'propertiesMappings');

const initialState = new InitialState;

export default function propertiesMappingsReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {

    case lastUpdatesActions.GET_LAST_UPDATES: {
        if (!action.payload || !action.payload.projects)
          return state;
  
        const { projects } = action.payload;
  
        Object.keys(projects).forEach(projectId => {
          let TS = projects.getNested([projectId, 'properties-mapping', 'lastUpdateTS'], DEFAULT_LAST_UPDATE_TS);
          let curr = state.getNested(['lastUpdateAvailable', projectId], null)
          if (curr < TS) state = state.setIn(['lastUpdateAvailable', projectId], TS);
        });
  
        return state;
      }

    case actions.GET_PROPERTIES_MAPPINGS + "_SUCCESS": {
      
      if (!action.payload?.projectId)
        return state;
      
      const { projectId, propertiesMappings } = action.payload;
      if (propertiesMappings) {
        let nextPropertiesMappings = new Map();
        _.forIn(propertiesMappings, (subjectPropsMappings, subjectName) => {
          _.forIn(subjectPropsMappings, (maps, mapType) => {
            _.forIn(maps, (currMap, id) => {
              nextPropertiesMappings = nextPropertiesMappings.setIn([subjectName, mapType, id], new PropertiesMapping({ id, properties: currMap.properties }));
            });
          });
        });
  
        state = state.setIn(['map', projectId], nextPropertiesMappings);
      }

      state = state.setIn(['didLoad', projectId], true);
      state = state.setIn(['lastUpdated', projectId], state.lastUpdateAvailable.get(projectId, DEFAULT_LAST_UPDATE_TS))

      return state;
    }
  }

  return state;
}