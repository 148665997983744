import * as actions from './actions';

import Groups from './groups';
import { Map } from 'immutable';
import { Record } from '../transit';

const InitialState = Record({
  globalGroups: null,
  projectGroups: Map(),
}, 'groups');

export default function groupsReducer(state = new InitialState, action) {
  switch (action.type) {
    case actions.GET_GROUPS: {
      if (!action.payload)
        return state;
      const { groups } = action.payload;
      state = state.set('globalGroups', new Groups({groups: groups.global}));
      var newMap = new Map();
      if (groups && groups.projects)
        groups.projects.loopEach((projectId, groups) => { 
          if (groups) {
            newMap = newMap.set(projectId, new Groups({groups})); 
          }
        })
      return state.set('projectGroups', newMap);
    }
  }
  
  return state;
}
