import React from "react";
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose, hoistStatics } from 'recompose';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../../../common/projects/contexts';
import withStyles from "@material-ui/core/styles/withStyles";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import _ from 'lodash';

// Components
import NoItemsFound from "../../components/CementoComponents/NoItemsFound";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import ChecklistRow from "./ChecklistRow.js";
import CollapsibleSection from '../../components/CementoComponents/CollapsibleSection';
import CrossListSortable from "../../components/CementoComponents/CrossListSortable";
import AddNewButton from "../../components/CementoComponents/AddNewButton";
import CollapsableChecklistSection from "./CollapsibleChecklist";

// Others
import { lokiInstance } from '../../../common/configureMiddleware';
import { startToast } from '../../../common/app/actions';
import { flattenObject } from '../../../common/app/funcs';
import { getNewStageId, updateLocalStages, deleteNewStage } from '../../../common/stages/actions'
import { duplicateChecklist, getLocalChecklists, updateLocalChecklists, getNewChecklistId, deleteNewChecklist } from '../../../common/checklists/actions'
import { ChecklistPageContext } from '../../../common/checklists/contexts'
import { ChecklistContext } from '../../../common/checklists/contexts'
import { getLocalChecklistItems, updateLocalChecklistItems, getNewChecklistItemId, deleteNewChecklistItem } from '../../../common/checklistItems/actions'
import { getChecklistItemInstances } from '../../../common/checklists/funcs'
import * as instancesStatus from "../../../common/checklistItemsInstances/clItemInstanceStatus";
import { bulkStatusUpdate } from "../../../common/checklistItemsInstances/actions";
import checklistItemMessages from "../../../common/checklistItems/checklistItemMessages"
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import trash from "../../assets/img/icons/trash.png";
import theme from '../../assets/css/theme';
import * as permissionsFunc from '../../../common/permissions/funcs';
import { CLI_PERIODS_DAILY } from '../../../common/checklistItemsInstances/clItemInstancePeriods';
import { draftValidator } from '../../../common/ui/actions';
import systemMessages from "../../../common/app/systemMessages";
import Button from "../../app/standardComponents/Button";

const noValueConstantArray = [];

const CREATE_STAGE_OBJECT_FROM_CHECKLIST_WITH_STRING_ONLY_AS_STAGE = false;
class Checklists extends React.Component {
  constructor(props) {
    super(props);
    this.onRowClick = this.onRowClick.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.setChecklistsArray = this.setChecklistsArray.bind(this);
    this.lokiPostsListener = this.lokiPostsListener.bind(this);
    this.handleCollectiveStatusChange = this.handleCollectiveStatusChange.bind(this);
    this.handleSelectSection = this.handleSelectSection.bind(this);
    this.mapChecklistsByStage = this.mapChecklistsByStage.bind(this);
    this.handleMoveThingsAround = this.handleMoveThingsAround.bind(this);
    this.handleDeleteChecklist = this.handleDeleteChecklist.bind(this);
    this.handleConfirmDeleteChecklist = this.handleConfirmDeleteChecklist.bind(this);
    this.handleDeleteChecklistItem = this.handleDeleteChecklistItem.bind(this);
    this.handleConfirmDeleteChecklistItems = this.handleConfirmDeleteChecklistItems.bind(this);
    this.handleAddNewChecklist = this.handleAddNewChecklist
    this.handleAddNewChecklistItem = this.handleAddNewChecklistItem.bind(this);
    this.initChecklistStages = this.initChecklistStages.bind(this);
    this.makeStageObj = this.makeStageObj.bind(this);
    this.onAddNew = this.onAddNew.bind(this);
    this.state = {
      checklistsSections: [],
      selectedProjectLocations: {},
      instancesDocsAndIssues: {},
      instancesIdsArray: [],
      locationsArray: [],
      sortedTrades: [],
      currActiveSection: {},
      currFocusSection: {},
      newStageIds: {},
      newChecklistIds: {},
      newChecklistItemIds: {},
    };

    this.checklistContext = {
      isEditMode: this.props.isEditMode, 
      extraDataEditMode: this.props.extraDataEditMode,
      selectedItemId: null,
      instancesDocsAndIssues: this.state.instancesDocsAndIssues,
    }
  }

  componentWillUnmount() {
    this.lokiPosts.cementoOff('checklistPostInstancesListener');
  }

  componentWillMount() {
    this.lokiPosts = lokiInstance.getCollection('posts');
    this.lokiPosts.cementoOn('checklistPostInstancesListener', this.lokiPostsListener);
    this.setComponentData({}, this.props)
  }

  
  componentWillReceiveProps(nextProps) {
    if (CREATE_STAGE_OBJECT_FROM_CHECKLIST_WITH_STRING_ONLY_AS_STAGE)
      if (Object.keys(nextProps.checklists || {}).length) {
        const { selectedProjectId, checklists, stages, updateLocalStages, updateLocalChecklists } = nextProps;
        let { updatedStages, updatedChecklists } = this.initChecklistStages(stages, checklists);

        if (updatedStages || updatedChecklists) {
          if (updatedStages && updateLocalStages) updateLocalStages(selectedProjectId, updatedStages);
          if (updatedChecklists && updateLocalChecklists) updateLocalChecklists(selectedProjectId, updatedChecklists);    
          return;
        }
      }

    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    const { stages, checklists, checklistItems, checklistInstances, locationsData, filteredChecklists, aggregateMode, buildings, floors, units, selectedProjectId } = props;    
    let newStateChanges = {};
    
    if (nextProps.sideCardClosed && props.isValDiff(nextProps, ['sideCardClosed'])) {
      newStateChanges.selectedItemId = null;
      newStateChanges.selectedChecklistId = null;
      newStateChanges.focusedChecklistId = null;
      newStateChanges.currFocusSection = {};
      newStateChanges.currActiveSection = {};
    }

    if ((nextProps.sideCardClosed && props.isValDiff(nextProps, ['sideCardClosed'])) || (locationsData != nextProps.locationsData))
      newStateChanges.selectedChecklistIndex = null;

    if (locationsData != nextProps.locationsData ||
        aggregateMode != nextProps.aggregateMode || 
        buildings != nextProps.buildings || 
        floors != nextProps.floors || 
        units != nextProps.units || 
        stages != nextProps.stages ||
        checklists != nextProps.checklists || 
        checklistItems != nextProps.checklistItems ||
        filteredChecklists != nextProps.filteredChecklists ||
        checklistInstances != nextProps.checklistInstances) {
      let res = this.setChecklistsArray(nextProps);
      newStateChanges.lastOrdinalNo = res.lastOrdinalNo;
      newStateChanges.instancesIdsArray = res.instancesIdsArray;
      newStateChanges.checklistsSections = res.checklistsSections;
      newStateChanges.checklistsMappedByStage = res.checklistsMappedByStage;
      newStateChanges.instancesDocsAndIssues = res.instancesDocsAndIssues;
      newStateChanges.myDefaultActionStatus = res.myDefaultActionStatus;
    }

    if (props.isValDiff(nextProps, ['isEditMode'])) {
      newStateChanges.newStageIds = {};
      newStateChanges.newChecklistIds = {};
      newStateChanges.newChecklistItemIds = {};
    }

    if (Object.keys(newStateChanges).length > 0)
      this.setState(newStateChanges);
  }

  initChecklistStages(stages, checklists) {
    const { intl, getNewStageId, selectedProjectId } = this.props;
    const { locale: localLang } = intl;

    let updatedStages = {};
    let updatedChecklists = {};
    let existingStageNameMap = {};

    stages.loopEach((i, stage) => {
      existingStageNameMap = existingStageNameMap.setNested([stage.getCementoTitle()], stage);
    });
    
    checklists.loopEach((checklistId, checklist) => {
      let checklistStageName = checklist.getNested(['stage'], 'None');
      let checklistStageId = checklist.getNested(['stageId'], null);
      let shouldChecklistUpdate = !Boolean(checklistStageId && stages.getNested([checklistStageId]));
      let checklistStage = stages.getNested([checklistStageId], existingStageNameMap.getNested([checklistStageName], null));

      if (!shouldChecklistUpdate)
        return;

      if (!checklistStage) {
        let newStageId = getNewStageId(selectedProjectId).payload.id;
        let stage = {
          title: { [localLang]: checklistStageName },
          id: newStageId,
        }
        updatedStages = updatedStages.setNested([checklistStageName], stage);
        checklist = checklist.setNested(['stageId'], newStageId);
      } else {
        checklist = checklist.setNested(['stageId'], checklistStage.getNested(['id']));
      }

      updatedChecklists = updatedChecklists.setNested([checklistId], checklist);
    })

    return { 
      updatedStages:     Boolean(Object.keys(updatedStages).length)     && updatedStages,
      updatedChecklists: Boolean(Object.keys(updatedChecklists).length) && updatedChecklists,
    }
  }

  onRowClick(selectedStage, selectedChecklist = null, selectedChecklistIndex = null, selectedChecklistItem = null, isNew = false) {
    const { onClick, draftValidator, isChecklistManager } = this.props;
    const { checklistsSections } = this.state;

    const draftAction = () => {
      let locationId = checklistsSections.getNested([selectedChecklistIndex, 'locationId']);
      if (onClick)
        onClick(selectedStage, selectedChecklist, selectedChecklistItem, locationId, isNew);
  
      this.setState({
        selectedItemId: (selectedChecklistItem || {}).getNested(['id']),
        selectedChecklistId: !selectedChecklistItem ? (selectedChecklist || {}).getNested(['id']) : null,
        focusedChecklistId: (selectedChecklist || {}).getNested(['id']),
        currFocusSection: { [selectedStage.getNested(['id'])]: true },
        currActiveSection: selectedChecklist || selectedChecklistItem ? {} : { [selectedStage.getNested(['id'])]: true },
        selectedChecklistIndex
      });
    }

    if (isChecklistManager)
      draftAction();
    else
      draftValidator(draftAction);
  }
  
  lokiPostsListener(collectionName) {
    if (collectionName == 'posts') {
      let instancesDocsAndIssues = this.getInstancesDocsAndIssues(this.state.instancesIdsArray, this.props.selectedProjectId);
      this.setState({instancesDocsAndIssues});
    }
  }

  getInstancesDocsAndIssues(instancesIdsArray, projectId) {
    const { relevantPostsHandler } = this.props
    let posts = this.lokiPosts.cementoFind({'projectId' : projectId, 'checklistItemInstance.id': { '$in': instancesIdsArray } });
    let map = {};
    posts.forEach(p => {
      if (!map[p.checklistItemInstance.id])
        map[p.checklistItemInstance.id] = { issues: [], docs: [] };
      map[p.checklistItemInstance.id][p.isIssue?'issues':'docs'].push(p);
    });
    if (relevantPostsHandler) 
      relevantPostsHandler(posts);
    return map;
  }

  createChecklistObject(checklist, loc = {}, forceHideDuplicationNo) {
    const { isChecklistManager } = this.props;
    let title = checklist.description;
    let duplicationNo = checklist.duplicationNo || (checklist.duplicatable ? 1 : null);
    let extraInfoText = checklist.getNested(['locations', loc.type, loc.id, "extraInfo", "text"], false);
    if (!forceHideDuplicationNo && duplicationNo && !isChecklistManager)
      title += ` - ${duplicationNo}`;
    if (extraInfoText)
      title += ` (${extraInfoText})`;

    return { title, checklist, locationId: loc.id, locationTitle: loc.title, counters: { total: 0, grade: 0 }, trades: {}, items: [], rejected: 0, total: 0, grade: 0, resolved: 0, confirmed2: 0, confirmed: 0 };
  }

  setChecklistsArray(nextProps) {
    const { checklists, checklistItems, checklistInstances, locationsData, filteredChecklists, filteredChecklistsItem, viewer, selectedProjectId, isChecklistManager } = nextProps;
    let checklistMap = {};
    let locationsArray = [];
    let instancesIdsArray = [];
    let lastItem = null;
    let highestOrdinalNo = 0;

    if (isChecklistManager && checklists) {
      checklists.loopEach((checklistId, checklist) => {
        if (checklist.getNested(['isDeleted'], false))
          return;
        checklist = checklist.toJS ? checklist.toJS() : checklist;
        checklistMap[checklistId] = this.createChecklistObject(checklist);
      });
    }

    if (Array.isArray(locationsData))
      locationsArray = locationsData;
    else {
      let locationDataType = locationsData.type + 's';
      let locationDataId = locationsData[locationsData.type + 'Id'];
      locationsArray = [{ type: locationDataType, id: locationDataId }]
    }
    const _checklists = permissionsFunc.safeToJS(checklists);
    const checklistsByOriginChecklistId = _.groupBy(checklists, 'originChecklist');
    _.values(permissionsFunc.safeToJS(checklistItems)).forEach((item) => {
      if (item.isDeleted || filteredChecklistsItem && !filteredChecklistsItem[item.id])
        return;
      lastItem = item;
      _.keys(item.checklistIds).forEach((checklistId) => {
        if (filteredChecklists && !filteredChecklists[checklistId])
          return;
        let parentChecklist = _checklists[checklistId];
        locationsArray.forEach(loc => {
          let isInLocation = isChecklistManager || (parentChecklist && parentChecklist.getNested(['locations', loc.type, loc.id]) && !item.getNested(['onlyLocations'])) || item.getNested(['onlyLocations', loc.type, loc.id]);
          if (parentChecklist && isInLocation) {  
            const isChecklistDuplicate = Boolean(parentChecklist.originChecklist);
            const hasDuplicationOnLocation = Boolean(checklistsByOriginChecklistId[parentChecklist.id]?.filter?.(chk => chk.locations?.[loc.type]?.[loc.id])?.length)
            const forceHideDuplicationNo = !(isChecklistDuplicate || hasDuplicationOnLocation);
            if (parentChecklist.getNested(['ordinalNo'], -1) > highestOrdinalNo)
              highestOrdinalNo = parentChecklist.ordinalNo;

            let sectionId = isChecklistManager ? checklistId : checklistId + loc.id;
            if (!checklistMap[sectionId]) {
              checklistMap[sectionId] =  this.createChecklistObject(parentChecklist, loc, forceHideDuplicationNo);
            }
            let filteredChecklistInstances = checklistInstances.getNested([selectedProjectId, item.id, loc.id], [])             
            let currInstance = getChecklistItemInstances(parentChecklist.id, filteredChecklistInstances);
            let currStatus = currInstance ? currInstance.status : null;
            
            if (currInstance) instancesIdsArray.push(currInstance.id);
            
            if (currStatus != instancesStatus.CLI_STATUS_IRRELEVANT)
              checklistMap[sectionId].counters.total += (item.weight||1);

            if (currStatus && currStatus != instancesStatus.CLI_STATUS_NONE && currStatus != instancesStatus.CLI_STATUS_IRRELEVANT) {
              if (!checklistMap[sectionId].counters[currStatus]) checklistMap[sectionId].counters[currStatus] = 0;
              checklistMap[sectionId].counters[currStatus] += (item.weight||1);
            }

            checklistMap[sectionId].items.push({ item, currInstance, title: item.description, locationId:loc.id, initialParentChecklistId: parentChecklist.getNested(['id']) });
            checklistMap[sectionId].trades[item.trade] = nextProps.trades.getNested([item.trade, 'getTitle']);
          }
        })
      })
    })

    let myDefaultActionStatus = null;
    if (lastItem) {
      if (permissionsFunc.getActionPermissions(viewer, selectedProjectId, "checklistItems", "confirm2", lastItem))  myDefaultActionStatus = instancesStatus.CLI_STATUS_CONFIRMED_2;
      else if (permissionsFunc.getActionPermissions(viewer, selectedProjectId, "checklistItems", "confirm", lastItem))  myDefaultActionStatus = instancesStatus.CLI_STATUS_CONFIRMED;
      else if (permissionsFunc.getActionPermissions(viewer, selectedProjectId, "checklistItems", "resolve", lastItem))  myDefaultActionStatus = instancesStatus.CLI_STATUS_RESOLVED;
    }

    let instancesDocsAndIssues = this.getInstancesDocsAndIssues(instancesIdsArray, selectedProjectId)
    let sortedArray = Object.values(checklistMap);
    sortedArray = sortedArray.sort((a, b) =>
      (a.checklist.ordinalNo == b.checklist.ordinalNo) ?
        (a.checklist.duplicationNo - b.checklist.duplicationNo) :
        (a.checklist.ordinalNo - b.checklist.ordinalNo)
    );
    sortedArray.forEach(element => element.items = element.items.sort((a,b) => (a.item.ordinalNo || 0) - (b.item.ordinalNo || 0)));
    let checklistsMappedByStage = this.mapChecklistsByStage(sortedArray, nextProps);

    return { checklistsSections: sortedArray, instancesDocsAndIssues, instancesIdsArray, myDefaultActionStatus, checklistsMappedByStage, lastOrdinalNo: highestOrdinalNo };
  }

  async handleCollectiveStatusChange(newStatus, locationId, allRelevantItems, allRelevantInstances, checklistId) {
    const { bulkStatusUpdate, selectedProjectId, startToast } = this.props;
    let { requirementsExists } = await bulkStatusUpdate(newStatus, selectedProjectId, locationId, allRelevantItems, allRelevantInstances, true, checklistId);
    if (requirementsExists) startToast({ title: checklistItemMessages.cannotBulkChangeMessage.message, type: 'error' });
  }

  makeStageObj(stage, lang) {
    if (!stage || stage.getNested(['isDeleted'], false))
      return false;

    let stageTitle = stage.getNested(['title'], {});
    return {
      stageTitle: stageTitle[lang] ? stageTitle[lang] : Object.values(stageTitle)[0],
      stageId: stage.getNested(['id']),
      stage,
      checklists: [],
    };
  }

  mapChecklistsByStage(checklistSections, props) {
    const { isChecklistManager, setChecklistsCompleteInfo, stages, lang, setChecklistsMappedByStage } = props;

    let checklistsMappedByStage = {};
    let completeChecklistsInfo = {};
    let hiddenStagesId = {};

    let duplicatedChecklistIdsMap = {};
    checklistSections.forEach(checklistInfo => {
      let currChecklistId = checklistInfo.getNested(['checklist', 'id']);
      let currStageId = checklistInfo.getNested(['checklist', 'stageId'], null);
      let originChecklistId = checklistInfo.getNested(['checklist', 'originChecklist'], false);

      if (!currStageId || !originChecklistId)
        return;
      
      duplicatedChecklistIdsMap[originChecklistId] = _.concat(duplicatedChecklistIdsMap.getNested([originChecklistId], []), [currChecklistId]);
    });
    
    checklistSections.forEach((checklistInfo, i) => {
      let currChecklistId = checklistInfo.getNested(['checklist', 'id']);
      let currStageId = checklistInfo.getNested(['checklist', 'stageId'], null);
      let currStage = stages.getNested([currStageId], null);
      let originChecklistId = checklistInfo.getNested(['checklist', 'originChecklist'], false);
      let currChecklistItemIds = checklistInfo.getNested(['items'], []).map(item => item.getNested(['item', 'id']));
      let duplicatedChecklistIds = duplicatedChecklistIdsMap.getNested([currChecklistId], false);
      
      if (isChecklistManager && checklistInfo.getNested(['checklist', 'contentType']) === 'employeesHealthy') {
        hiddenStagesId = hiddenStagesId.setNested([currStageId], currStageId);
        return;
      }

      completeChecklistsInfo = completeChecklistsInfo.setNested([currChecklistId], { id: currChecklistId, itemsIds: currChecklistItemIds, originChecklistId, duplicatedChecklistIds });
      
      if (!checklistsMappedByStage.getNested([currStageId], null) && currStageId) {
        const stageObject = this.makeStageObj(currStage, lang);
        if (stageObject)
          checklistsMappedByStage[currStageId] = stageObject;
      }
      
      if (!checklistsMappedByStage[currStageId] || isChecklistManager && originChecklistId)
        return;

      checklistInfo = checklistInfo.setNested(['duplicatedChecklistIds'], duplicatedChecklistIds);
      checklistInfo = checklistInfo.setNested(['globalCheckListIndex'], i);
      checklistsMappedByStage[currStageId].checklists.push(checklistInfo);
    });

    if (isChecklistManager)
      stages.loopEach((stageId, stage) => {
        if (!checklistsMappedByStage.getNested([stageId], false) && !hiddenStagesId.getNested([stageId], false)) {
          let stageObj = this.makeStageObj(stage, lang);
          if (stageObj)
            checklistsMappedByStage = checklistsMappedByStage.setNested([stageId], stageObj);
        }
      });

    let toRet = Object.values(checklistsMappedByStage);

    if (setChecklistsCompleteInfo) setChecklistsCompleteInfo(completeChecklistsInfo);
    if (setChecklistsMappedByStage) setChecklistsMappedByStage(toRet);

    return toRet;
  }

  debouncedReduxUpdate = AwesomeDebouncePromise((updatedChecklistsMappedByStage, shouldUpdateItems = false) => {
    const { checklists, selectedProjectId, getLocalChecklists, getLocalChecklistItems } = this.props;
    let extractedChecklistItems = {};
    let updatedChecklists = [];
    let updatedChecklistItems = [];
    let currOrdinalNo = 1;
    (updatedChecklistsMappedByStage || {}).loopEach((i, stage) => {
      stage.checklists.loopEach((i, checklist) => {
        if (!shouldUpdateItems) {
          let updatedChecklist = checklist.checklist.setNested(['ordinalNo'], currOrdinalNo);
              updatedChecklist = updatedChecklist.setNested(['stage'], stage.stageTitle);
              updatedChecklist = updatedChecklist.setNested(['stageId'], stage.stageId);

          updatedChecklists.push(updatedChecklist);

          let duplicatedChecklistIds = checklist.getNested(['duplicatedChecklistIds'], false);
          if (duplicatedChecklistIds) {
            duplicatedChecklistIds.loopEach((i, id) => {
              let updatedDupChecklist = checklists.getNested([id]);
                  updatedDupChecklist = updatedDupChecklist.setNested(['ordinalNo'], currOrdinalNo);
                  updatedDupChecklist = updatedDupChecklist.setNested(['stage'], stage.stageTitle);
                  updatedDupChecklist = updatedDupChecklist.setNested(['stageId'], stage.stageId);
              
              updatedChecklists.push(updatedDupChecklist);
            });
          }
          currOrdinalNo++;
        } else extractedChecklistItems = extractedChecklistItems.setNested([checklist.getNested(['checklist', 'id'])], checklist.items);
      });
    });
    
    // extractedChecklistItems will be empty if shouldUpdateItems is false
    extractedChecklistItems.loopEach((currChecklistId, items) => {
      items.loopEach((itemIndex, item) => {
        let updatedItem = item.item.setNested(['ordinalNo'], itemIndex + 1);
        if (item.initialParentChecklistId !== currChecklistId) {
          let sampleItemPermissions = items.getNested([(itemIndex === 0 ? 1 : 0), 'item', 'permissions'], null);
          updatedItem = updatedItem.setNested(['permissions'], sampleItemPermissions);
          updatedItem = updatedItem.setNested(['checklistIds'], _.omit(updatedItem.getNested(['checklistIds']), item.initialParentChecklistId));
          updatedItem = updatedItem.setNested(['checklistIds', currChecklistId], { id: currChecklistId });
        }
        updatedChecklistItems.push(updatedItem);
      });
    });
    
    if (getLocalChecklists && Object.values(updatedChecklists).length) getLocalChecklists(selectedProjectId, updatedChecklists);
    if (getLocalChecklistItems && Object.values(updatedChecklistItems).length) getLocalChecklistItems(selectedProjectId, updatedChecklistItems);
  }, 500);

  handleMoveThingsAround(updatedChecklistsMappedByStage, shouldUpdateItems = false) {
    this.setState({ checklistsMappedByStage: updatedChecklistsMappedByStage }, () => this.debouncedReduxUpdate(updatedChecklistsMappedByStage, shouldUpdateItems));
  }

  // STAGE FUNCTIONS ---
  handleAddNewStage() {
    const { updateLocalStages, selectedProjectId, getNewStageId, lang, intl, stages } = this.props;
    let stageId = getNewStageId(selectedProjectId).payload.id;
    
    let ordinalNo = 1;
    stages.loopEach(stage => ordinalNo = stage.ordinalNo && stage.ordinalNo >= ordinalNo ? stage.ordinalNo + 1 : ordinalNo);

    let stage = {
      title: { [lang]: intl.formatMessage(systemMessages.newStage) },
      id: stageId,    
      ordinalNo,
    }

    this.setState(prevState => prevState.setNested(['newStageIds', stageId], stageId));
    if (updateLocalStages)
      updateLocalStages(selectedProjectId, { [stageId]: stage });
    
    this.onAddNew(stage);
  }

  handleDeleteStage(currStage) {
    const { startToast } = this.props;
		const { yes, no } = systemMessages;
		const { title, content } = systemMessages.deleteChecklistAlert;
    
    startToast({
      overlay: true,
      mandatory: true,
      title: title,
      message: content,
      actions: [{ message: yes, onClick: () => this.handleConfirmDeleteStage(currStage), color: 'success' }, { message: no }],
    });
  }

  handleConfirmDeleteStage(currStage) {
    const { selectedProjectId, updateLocalStages, deleteNewStage } = this.props;
    const { newStageIds } = this.state;
    const { stage, stageId, checklists } = currStage;

    if (newStageIds.getNested([stageId], false)) {
      this.setState(prevState => _.unset(prevState, ['newStageIds', stageId]));
      if (deleteNewStage)
        deleteNewStage(selectedProjectId, stage);
    } else {
      if (updateLocalStages)
        updateLocalStages(selectedProjectId, { [stageId]: stage.setNested(['isDeleted'], true) });
    }

    checklists.forEach(checklistSection => {
      this.handleConfirmDeleteChecklist(checklistSection);
    });
  }

  // CHECKLIST FUNCTIONS ---
  handleAddNewChecklist(currStage) {
    const { selectedProjectId, updateLocalChecklists, getNewChecklistId, intl } = this.props;
    const { newStageIds, lastOrdinalNo } = this.state;
    const { stage, stageTitle, stageId, checklists } = currStage;
    
    let newChecklistId = getNewChecklistId(selectedProjectId).payload.id;
    let ordinalNo;
    if (!checklists.length) {
      if (newStageIds.getNested([stageId], false))
        ordinalNo = lastOrdinalNo + 1;
      else
        ordinalNo = 1;
    } else ordinalNo = checklists.getNested([checklists.length - 1, 'checklist', 'ordinalNo']) + 1;

    let newChecklist = { stage: stageTitle, stageId, ordinalNo, id: newChecklistId, description: intl.formatMessage(systemMessages.newChecklist) };

    this.setState(prevState => prevState.setNested(['newChecklistIds', newChecklistId], newChecklistId).setNested(['lastOrdinalNo'], ordinalNo));
    if (updateLocalChecklists) updateLocalChecklists(selectedProjectId, { [newChecklistId]: newChecklist });
    
    this.onAddNew(stage, newChecklist);
  }

  handleDeleteChecklist(currChecklistSection) {
		const { startToast } = this.props;
		const { yes, no } = systemMessages;
		const { title, content } = systemMessages.deleteChecklistAlert;
    
    startToast({
      overlay: true,
      mandatory: true,
      title: title,
      message: content,
      actions: [{ message: yes, onClick: () => this.handleConfirmDeleteChecklist(currChecklistSection), color: 'success' }, { message: no }],
    });
  }
  
  handleConfirmDeleteChecklist(currChecklistSection) {
    const { selectedProjectId, updateLocalChecklists, checklists, deleteNewChecklist } = this.props;
    const { newChecklistIds } = this.state;
    
    let originChecklist = currChecklistSection.getNested(['checklist']);
        originChecklist = originChecklist.setNested(['isDeleted'], true);
    
    let checklistItemsToDelete = currChecklistSection.getNested(['items'], false);
    if (checklistItemsToDelete)
      this.handleConfirmDeleteChecklistItems(checklistItemsToDelete);
    
    if (newChecklistIds.getNested([originChecklist.id], false)) {
      deleteNewChecklist(selectedProjectId, originChecklist);
      this.setState(prevState => _.unset(prevState, ['newChecklistIds', originChecklist.id]))
      return;
    }

    let duplicatedChecklistIds = currChecklistSection.getNested(['duplicatedChecklistIds'], []);

    let checklistsToUpdate = {[originChecklist.id]: originChecklist}
    duplicatedChecklistIds.loopEach((i, checklistId) => {
      let checklist = checklists.getNested([checklistId]);
          checklist = checklist.setNested(['isDeleted'], true);

      checklistsToUpdate = checklistsToUpdate.setNested([checklistId], checklist);
    });

    if (updateLocalChecklists) updateLocalChecklists(selectedProjectId, checklistsToUpdate);
  }

  // CHECKLIST ITEM FUNCTIONS ---
  handleAddNewChecklistItem(currStage, currChecklistSection) {
    const { selectedProjectId, updateLocalChecklistItems, getNewChecklistItemId } = this.props;

    let currChecklist   = currChecklistSection.getNested(['checklist'], {});
    let period          = currChecklistSection.getNested(['checklist', 'type']) === 'routine' ? CLI_PERIODS_DAILY : null;

    let lastItemIndex            = currChecklistSection.getNested(['items'], ['']).length - 1;
    let checklistReadPermissions = currChecklistSection.getNested(['items', lastItemIndex, 'item', 'permissions', 'read'], null);
    let newItemPermissions       = { ...(checklistReadPermissions ? { read: checklistReadPermissions } : {}), actions: { irrelevant: true } };
    let newItemOrdinalNo         = currChecklistSection.getNested(['items', lastItemIndex, 'item', 'ordinalNo'], 0) + 1;
    let newItemChecklistIds      = currChecklistSection.getNested(['items', lastItemIndex, 'item', 'checklistIds'], { [currChecklist.id]: currChecklist.id });
    let newItemId = getNewChecklistItemId(selectedProjectId).payload.id;

    let newItem = { description: 'New item', id: newItemId, ordinalNo: newItemOrdinalNo, permissions: newItemPermissions, checklistIds: newItemChecklistIds, weight: 1, period }

    this.setState(prevStage => prevStage.setNested(['newChecklistItemIds', newItemId], newItemId));
    if (updateLocalChecklistItems) updateLocalChecklistItems(selectedProjectId, { [newItemId]: newItem });
  
    this.onAddNew(currStage.stage, currChecklist, newItem);
  }

  handleDeleteChecklistItem(currChecklistItem) {
		const { startToast } = this.props;
		const { yes, no } = systemMessages;
		const { title, content } = systemMessages.deleteChecklistItemAlert;
    
    startToast({
      overlay: true,
      mandatory: true,
      title: title,
      message: content,
      actions: [{ message: yes, onClick: () => this.handleConfirmDeleteChecklistItems(currChecklistItem), color: 'success' }, { message: no }],
    });
  }

  handleConfirmDeleteChecklistItems(checklistItemsToDelete) {
    const { selectedProjectId, updateLocalChecklistItems, deleteNewChecklistItem } = this.props;
    const { newChecklistItemIds } = this.state;
    let checklistItemsToUpdate = {};
    checklistItemsToDelete.loopEach((i, item) => {
      item = item.getNested(['item']);
      if (newChecklistItemIds.getNested([item.id], false)) {
        deleteNewChecklistItem(selectedProjectId, item);
        this.setState(prevState => _.unset(prevState, ['newChecklistItemIds', item.id]));
        return;
      }
      item = item.setNested(['isDeleted'], true);
      
      checklistItemsToUpdate = checklistItemsToUpdate.setNested([item.id], item);
    });

    if (updateLocalChecklistItems) updateLocalChecklistItems(selectedProjectId, checklistItemsToUpdate);
  }

  onAddNew(stage, checklist = null, checklistItem = null) {
    this.onRowClick(stage, checklist, null, checklistItem, true);
  }

  handleSelectSection(key) {
		const { currActiveSection } = this.state;

		if (!currActiveSection[key]) this.setState({ currActiveSection: { [key]: true } });
  }

  render() {
    const { rtl, minMode, openAll, width, maxCharsInItem, intl, locationsData, isEditMode, isChecklistManager, hideStage, extraDataEditMode, toggleExtraDataEditMode, isAggregated } = this.props;
    const { checklistsSections, focusedChecklistId, selectedChecklistId, selectedItemId, instancesDocsAndIssues, myDefaultActionStatus, checklistsMappedByStage, currActiveSection, currFocusSection, selectedChecklistIndex } = this.state;

    let defaultOpen = (checklistsSections.length == 1 || openAll);
    let miniEmptyComp = <NoItemsFound compStyles={{ height: theme.headerHeight }} locationMode={Boolean(!locationsData || Object.values(locationsData).length == 0)}/>;
    if (!checklistsMappedByStage || checklistsMappedByStage.length == 0)
      if (!isEditMode)
        return <NoItemsFound locationMode={Boolean(!locationsData || Object.values(locationsData).length == 0)}/>;
    
    this.checklistContext.isEditMode = isEditMode;
    this.checklistContext.extraDataEditMode = extraDataEditMode;
    this.checklistContext.selectedItemId = selectedItemId;
    this.checklistContext.instancesDocsAndIssues = instancesDocsAndIssues;

    return (
    <>
      {/* <div onClick={() => {
        const { duplicateChecklist } = this.props;
        duplicateChecklist('-MB-RRbpaV3ARVn4Kzlz', '-MCl3WGDHjxRY5w4gSyv', 'unit', "-MB-SwuGE2L8BrsnJaQ6", { 'text': 'text' });
      }}>Click here</div> */}
      <CrossListSortable
        key={'masterSortableMouahaha'}
        keyId={'masterSortableMouahaha'}
        masterList={checklistsMappedByStage}
        onChange={this.handleMoveThingsAround}
        listEmptyComp={miniEmptyComp}
        sortableProps={{
          disabled: !isEditMode,
          group: 'stages',
          multiDrag: true,
          multiDragKey: 'shift',
          handle: '#dragger',
          forceFallback: true,
        }}
      >
          {(currStage, sectionIndex) => {
          return (
            <CollapsibleSection
              childrenOnly={hideStage}
              key={currStage.stageId}
              keyId={currStage.stageId}
              section={{ title: currStage.stageTitle }}
              onSelect={() => {this.handleSelectSection(currStage.stageId); this.onRowClick(currStage.stage);}}
              isSelected={currActiveSection.getNested([currStage.stageId], false)}
              isFocused={currFocusSection.getNested([currStage.stageId], false)}
              draggerId={isEditMode ? 'dragger' : null}
              stripIcons={
                isEditMode 
                  ? [{icon: trash, onClick: () => this.handleDeleteStage(currStage), style: { height: '18px' }}] 
                  : null
              }
              mainContainerStyle={{ paddingBottom: hideStage ? 0 : theme.paddingSize }}
              footerContainerStyle={{ display: 'flex', justifyContent: 'center' }}
              footerComponent={Boolean(isEditMode) && <Button withPlus title={systemMessages.addNewChecklist} onClick={() => this.handleAddNewChecklist(currStage)}/>}
            >
              <CrossListSortable
                key={currStage.stageId}
                keyId={currStage.stageId}
                masterList={checklistsMappedByStage}
                pathToListToSort={[sectionIndex, 'checklists']}
                listEmptyComp={hideStage ? null : miniEmptyComp}
                onChange={this.handleMoveThingsAround}
                sortableProps={{
                  disabled: !isEditMode,
                  group: 'checklists',
                  multiDrag: true,
                  multiDragKey: 'shift',
                  handle: '#dragger',
                  forceFallback: true,
                }}
              >
                {(currChecklistSection, currChecklistIndex) => {
                  let isChecklistSelected = isAggregated ? (selectedChecklistIndex === currChecklistSection.globalCheckListIndex) : (selectedChecklistId === currChecklistSection.checklist.id);
                  let isChecklistFocused = !isAggregated && focusedChecklistId === currChecklistSection.checklist.id;
                  return (
                    <GridContainer
                      key={'checklistWrapperNumber' + currChecklistSection.getNested(['checklist', 'id'], currChecklistIndex) + currChecklistIndex}
                      style={{
                        maxWidth: 1000,
                        width: width || 'auto',
                        transition: "all 300ms ease 0s"
                      }}
                    >
                      <ChecklistContext.Provider value={{
                        ...this.checklistContext,
                        locationsData,
                        itemsArr: _.get(checklistsMappedByStage, [sectionIndex, 'checklists', currChecklistIndex, 'items'], noValueConstantArray),
                      }}>
                        <CollapsableChecklistSection
                        rtl={rtl}
                        intl={intl}
                        open={isChecklistSelected || defaultOpen}
                        onCollectiveAction={(newStatus) => this.handleCollectiveStatusChange(
                          newStatus,
                          currChecklistSection.locationId,
                          currChecklistSection.items.map(curr => curr.item),
                          currChecklistSection.items.map(curr => curr.currInstance),
                          currChecklistSection.checklist.id
                        )}
                        onClick={() => {
                          this.onRowClick(currStage.stage, currChecklistSection.checklist, currChecklistSection.globalCheckListIndex);
                        }}
                        isSelected={isChecklistSelected}
                        isFocused={isChecklistFocused}
                        onDelete={() => this.handleDeleteChecklist(currChecklistSection)}
                        enableCollectiveAction={currChecklistSection.items.filter(item => ((item.currInstance == null || item.currInstance.status == instancesStatus.CLI_STATUS_NONE) && Object.keys(item.getNested(['item', 'extraData'], {})).length == 0)).length > 0}
                        defaultActionStatus={myDefaultActionStatus}
                        checklistSection={currChecklistSection}
                        minMode={minMode}
                        isEditMode={isEditMode}
                        isChecklistManager={isChecklistManager}
                        footerContainerStyle={{ display: 'flex', justifyContent: 'center', paddingTop: theme.verticalMargin, paddingBottom: theme.verticalMargin }}
                        footerComponent={Boolean(isEditMode) && <Button withPlus title={systemMessages.addNewChecklistItem} onClick={() => this.handleAddNewChecklistItem(currStage, currChecklistSection)} />}
                      >
                        <CrossListSortable
                          key={'sortable' + currChecklistSection.checklist.id}
                          keyId={'sortable' + currChecklistSection.checklist.id}
                          masterList={checklistsMappedByStage}
                          pathToListToSort={[sectionIndex, 'checklists', currChecklistIndex, 'items']}
                          onChange={(update) => this.handleMoveThingsAround(update, true)}
                          listEmptyComp={miniEmptyComp}
                          sortableProps={{
                            disabled: !isEditMode,
                            group: 'checklistItems',
                            multiDrag: true,
                            multiDragKey: 'shift',
                            handle: '#dragger',
                            forceFallback: true,
                          }}
                        >
                          {(curr, currItemIndex) => (
                            <ChecklistRow
                              key={curr.item.id}
                              keyId={curr.item.id}
                              currItemId={curr.item.id}
                              checklistId={currChecklistSection.checklist.id}
                              linksMode="hide"
                              isDraggable={true}
                              minTrades={minMode}
                              maxChars={maxCharsInItem}
                              minMode={minMode}
                              onClick={() => this.onRowClick(currStage.stage, currChecklistSection.checklist, currChecklistSection.globalCheckListIndex, curr.item)}
                              onDelete={() => this.handleDeleteChecklistItem([curr])}
                              parentChecklistFocused={currChecklistSection.checklist.id === focusedChecklistId}
                              checklistType={currChecklistSection.checklist.type}
                              isChecklistManager={isChecklistManager}
                              toggleExtraDataEditMode={toggleExtraDataEditMode}
                            />
                          )}
                        </CrossListSortable>
                      </CollapsableChecklistSection>
                      </ChecklistContext.Provider>
                    </GridContainer>
                  );
                }}
              </CrossListSortable>
            </CollapsibleSection>
          );
        }}
      </CrossListSortable>
      {Boolean(isEditMode) && <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><Button withPlus title={systemMessages.addNewStage} style={{ ...addNewButtonStyles, justifySelf: 'center'}} onClick={() => this.handleAddNewStage()}/></div>}
    </>
    );
  }
}

const addNewButtonStyles = {
  margin: theme.margin,
}


Checklists = withStyles(buttonStyle)(Checklists);
Checklists = injectIntl(Checklists);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connectContext(ChecklistPageContext.Consumer),
  connect(state => ({
    checklistInstances: state.checklistItemsInstances.map, 
    rtl: state.app.rtl,
  }), 
  { startToast,
    bulkStatusUpdate,
    draftValidator,
    
    duplicateChecklist,
    getLocalChecklists,
    getLocalChecklistItems,
    
    updateLocalStages,
    updateLocalChecklists,
    updateLocalChecklistItems,
    
    getNewStageId,
    getNewChecklistId,
    getNewChecklistItemId,
    
    deleteNewStage,
    deleteNewChecklist,
    deleteNewChecklistItem,
  })
);

export default enhance(Checklists);