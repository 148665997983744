import React from "react";
import { injectIntl } from "react-intl";
import { connectContext } from "react-connect-context";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";
import theme from "../../../common/app/theme";

import InnerCollapsible from "../../components/CementoComponents/InnerCollapsible";
import InnerCollapsibleRow from "../../components/CementoComponents/InnerCollapsibleRow";
import SelectLocations from "../../components/CementoComponents/SelectLocations";
import StandardInput from "../../components/CementoComponents/StandardInput";
import MenuScrollbar from "../../components/CementoComponents/MenuScrollbar";

import { ProjectContext } from "../../../common/projects/contexts";
import { draftValidator, onDraftModeChange } from "../../../common/ui/actions";
import { startToast } from "../../../common/app/actions";

import { groupSections } from "./config/LocationsGroupsManagerCardConfig";

import propertiesMessages from "../../../common/propertiesTypes/propertiesMessages";
import systemMessages from "../../../common/app/systemMessages";
import { lokiInstance } from "../../../common/configureMiddleware";
import {
  updateLocationsGroup,
  getNewGroupId,
} from "../../../common/propertiesTypes/actions";

import { uploadPropertiesInstances } from "../../../common/propertiesInstances/actions";
import projectManagerMessages from "../../../common/app/projectManagerMessages";
import { fetchQuasiStatics } from "../../../common/quasiStatics/funcs";
import { DEFAULT_SYSTEM_LANGUAGE } from "../../../common/app/constants";

class LocationsGroupsManagerCard extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.calcSection = this.calcSection.bind(this);
    this.calcInputField = this.calcInputField.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.onSave = this.onSave.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.lokiPropertyInstancesListener =
      this.lokiPropertyInstancesListener.bind(this);
    this.getGroupInstances = this.getGroupInstances.bind(this);

    this.state = {
      locationChanged: false,
    };
  }

  async UNSAFE_componentWillMount() {
    this.lokiPropertyInstances =
      lokiInstance.getCollection("propertyInstances");
    this.lokiPropertyInstances.cementoOn(
      "locationInfoPageInstancesListener",
      this.lokiPropertyInstancesListener
    );

    this.setComponentData({}, this.props);
  }

  componentWillUnmount() {
    const { onDraftModeChange } = this.props;
    onDraftModeChange(false);
    this.lokiPropertyInstances.cementoOff("locationInfoPageInstancesListener");
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  lokiPropertyInstancesListener(collectionName) {
    const { selectedGroup } = this.state;
    const { selectedProjectId } = this.props;

    if (collectionName === "propertyInstances") {
      let newStateChanges = {};
      let lokiPropertyInstances = this.lokiPropertyInstances.cementoFind({
        projectId: selectedProjectId,
        subjectName: "locationsInfo",
        propId: "groups",
      });
      newStateChanges.groupInstancesArray = this.getGroupInstances(
        lokiPropertyInstances,
        selectedGroup.id
      );
      this.setState(newStateChanges);
    }
  }

  isValidGroup() {
    const { selectedGroup } = this.state;
    const { startToast } = this.props;
    let isValid = true;

    if (!selectedGroup.title || !selectedGroup.types) {
      isValid = false;
      startToast({
        title: `'Description' and 'Type' are required`,
        type: 'error',
      });
    }

    return isValid;
  }

  async onSave() {
    const {
      onGroupUpdate,
      creation,
      selectedProjectId,
      uploadPropertiesInstances,
      updateLocationsGroup,
      startToast,
      onCancel,
    } = this.props;
    const {
      selectedGroup,
      allGroups,
      locationChanged,
      groupInstancesArray,
      currentGroupLocations,
    } = this.state;

    if (!this.isValidGroup()) {
      return;
    }

    const selectedGroupId = selectedGroup.id;
    const newGroups = { ...allGroups };

    _.set(newGroups, selectedGroupId, selectedGroup);

    await updateLocationsGroup(
      selectedProjectId,
      Object.values(newGroups)
    );

    if (locationChanged) {
      let newGroupInstances = [];
      Object.values(currentGroupLocations || {}).forEach((loc) => {
        let isExistInstance = _.findIndex(
          groupInstancesArray,
          (i) => i.parentId === loc.id
        );
        if (isExistInstance !== -1) {
          let newExistsInstance = _.get(groupInstancesArray, [isExistInstance]);
          newExistsInstance = _.set(
            newExistsInstance,
            ["data", selectedGroupId],
            selectedGroupId
          );
          newGroupInstances.push(newExistsInstance);
        } else {
          let newInstance = {
            data: { [selectedGroupId]: selectedGroupId },
            subjectName: "locationsInfo",
            propType: "SelectionList",
            propId: "groups",
            parentId: loc.id,
          };
          newGroupInstances.push(newInstance);
        }
      });
      (groupInstancesArray || []).forEach((instance) => {
        let shouldBeDeleted = !_.get(currentGroupLocations, [
          instance.parentId,
        ]);
        if (shouldBeDeleted) {
          let updatedInstance = { ...instance };
          delete updatedInstance.data[selectedGroupId];
          newGroupInstances.push(updatedInstance);
        }
      });
      await uploadPropertiesInstances(
        selectedProjectId,
        newGroupInstances,
        "locationsInfo"
      );
    }

    if (creation && onCancel) onCancel();

    if (onGroupUpdate) onGroupUpdate(selectedGroup);

    startToast({ title: projectManagerMessages.groupUpdated, type: "success" });
  }

  async handleConfirmDelete() {
    const {
      objectId,
      onGroupUpdate,
      onCancel,
      selectedProjectId,
      updateLocationsGroup,
      uploadPropertiesInstances,
    } = this.props;
    const { allGroups, selectedGroup, groupInstancesArray } = this.state;

    let newSelectedGroup = { ...selectedGroup, isDeleted: true };
    let newGroups = { ...allGroups };
    let newGroupInstancesArray = [...groupInstancesArray];

    newGroupInstancesArray.forEach((instance) => {
      if (_.get(instance, ["data", objectId], false))
        delete instance.data[objectId];
    });

    delete newGroups[objectId];

    await updateLocationsGroup(selectedProjectId, Object.values(newGroups));
    await uploadPropertiesInstances(
      selectedProjectId,
      newGroupInstancesArray,
      "locationsInfo"
    );

    if (onGroupUpdate) onGroupUpdate(newSelectedGroup);
    if (onCancel) onCancel();
  }

  getGroupInstances(allGroupsInstances, groupId) {
    const { objectId } = this.props;
    let groupInstancesArray = [];

    if (allGroupsInstances) {
      allGroupsInstances.forEach((currInstance) => {
        if (currInstance.data && currInstance.data[groupId || objectId]) {
          groupInstancesArray.push(currInstance);
        }
      });
    }

    return groupInstancesArray;
  }

  async setComponentData(props, nextProps) {
    const {
      project,
      creation,
      getNewGroupId,
      selectedProjectId,
      objectId,
      onCardLoad,
      hideNavigation,
      editMode,
      draftValidator,
      changeEditMode = () => {},
    } = nextProps;

    let newStateChanges = {};
    let newSideCardEditMode = nextProps.editMode;

    let groupsArray = _.get(nextProps, [
      "propertiesTypes",
      "locationsInfo",
      "groups",
      "values",
    ]);
    let { groupsSubTypes } = await fetchQuasiStatics("groupsSubTypes");
    newStateChanges.groupsSubTypes = groupsSubTypes;

    let typeNameToIdMap = {};
    let typeIdToNameMap = {};
    Object.values(groupsSubTypes || {}).forEach((groupType) => {
      _.set(typeNameToIdMap, groupType.value, groupType.id);
      _.set(typeIdToNameMap, groupType.id, groupType.value);
    });
    newStateChanges.typeNameToIdMap = typeNameToIdMap;
    newStateChanges.typeIdToNameMap = typeIdToNameMap;

    let groupsMap = {};
    (groupsArray || []).forEach((g) => {
      _.set(groupsMap, g.id, {
        id: g.id,
        title: { [project?.lang || DEFAULT_SYSTEM_LANGUAGE]: g.getCementoTitle() },
        types: g.types,
      });
    });
    newStateChanges.allGroups = groupsMap;

    let lokiPropertyInstances = this.lokiPropertyInstances.cementoFind({
      projectId: selectedProjectId,
      subjectName: "locationsInfo",
      propId: "groups",
    });
    let groupInstancesArray = this.getGroupInstances(lokiPropertyInstances);
    newStateChanges.groupInstancesArray = groupInstancesArray;

    let objectChanged =
      !_.isEqual(
        _.get(props, ["propertiesTypes", "locationsInfo", "groups", "values"]),
        _.get(nextProps, [
          "propertiesTypes",
          "locationsInfo",
          "groups",
          "values",
        ])
      ) || props.objectId !== nextProps.objectId;

    if (!creation && objectChanged) {
      let currentGroupObject = groupsMap[objectId];
      let currentGroupLocations = {};
      Object.values(groupInstancesArray).forEach((gInstance) => {
        currentGroupLocations[gInstance.parentId] = { id: gInstance.parentId };
      });
      newStateChanges.selectedGroup = currentGroupObject;
      newStateChanges.currentGroupLocations = currentGroupLocations;
      newStateChanges.selectedGroupType = Object.keys(groupsMap[objectId] || {})
        .filter((t) => _.get(groupsSubTypes, t))
        .map((t) => typeIdToNameMap[t])[0];
    } else if (creation && objectChanged) {
      const newId = getNewGroupId(selectedProjectId).payload.newId;
      newStateChanges.selectedGroup = { id: newId };
    }

    if (props.objectId !== nextProps.objectId) newSideCardEditMode = false;

    if (_.isNil(nextProps.objectId) || nextProps.creation) {
      newSideCardEditMode = true;
      if (props.objectId !== nextProps.objectId) {
        const newId = getNewGroupId(selectedProjectId).payload.newId;
        newStateChanges.selectedGroup = { id: newId };
      }
      newStateChanges.currentGroupLocations = {};
    }

    let nextSelectedGroup =
      newStateChanges.selectedGroup || this.state.selectedGroup;

    let headerOptions = {
      title: _.get(nextSelectedGroup, ["title", project?.lang || DEFAULT_SYSTEM_LANGUAGE], ""),
      editable: true,
      onDelete: () => this.onDelete(),
      onCancel: () => draftValidator(() => this.handleCancel()),
      onSave: () => this.onSave(),
    };

    onCardLoad(headerOptions, {});
    hideNavigation(editMode);

    this.setState(newStateChanges, () => {
      if (nextProps.editMode !== newSideCardEditMode)
        changeEditMode(newSideCardEditMode);
    });
  }

  handleCancel() {
    const { changeEditMode, onClose, creation } = this.props;

    if (changeEditMode) changeEditMode(false);
    if (creation && onClose) onClose();
  }

  onDelete() {
    const { startToast } = this.props;
    const { removeGroup } = propertiesMessages;

    startToast({
      overlay: true,
      mandatory: true,
      message: removeGroup,
      actions: [
        {
          message: systemMessages.yes,
          color: "danger",
          onClick: () => this.handleConfirmDelete(),
        },
        { message: systemMessages.no },
      ],
    });
  }

  handleInputChange(pathToValue, val) {
    const { selectedGroup } = this.state;
    const { onDraftModeChange, project } = this.props;

    let updatedValue = val;

    if (pathToValue.includes("types") && val) {
      const type = Object.keys(val)[0];
      updatedValue = { [type]: type };
    } else if (pathToValue.includes("title")) {
      updatedValue = { [project?.lang || DEFAULT_SYSTEM_LANGUAGE]: val };
    }


    const newGroupsObject = { ...selectedGroup };
    _.set(newGroupsObject, pathToValue, updatedValue);
    onDraftModeChange(true);
    this.setState({ selectedGroup: newGroupsObject });
  }

  handleLocationChange(newLocationsList) {
    const { onDraftModeChange } = this.props;

    let newLocationsObject = {};
    Object.values(newLocationsList).forEach((location) => {
      if (location.hasOwnProperty("selected") && Boolean(location.selected))
        newLocationsObject = _.set(newLocationsObject, location.id, {
          id: location.id,
        });
    });

    onDraftModeChange(true);
    this.setState({
      currentGroupLocations: newLocationsObject,
      locationChanged: true,
    });
  }

  calcInputField(field, key, sectionId) {
    let { name, type, pathToValue, values = null, props = {} } = field;
    const { editMode, creation, intl, project } = this.props;
    const { selectedGroup } = this.state;
    let disableTypeInput = pathToValue.includes("types") && !creation;

    const valueObj = _.get(selectedGroup, pathToValue);
    const valueKey = Object.keys(valueObj || {})[0];
    let formattedValue = {};
    if (type === "SelectionList" && valueKey) {
      formattedValue = {
        [valueKey]: valueKey
      };
    }
    if (pathToValue.includes("title")) {
      formattedValue = valueObj && valueObj[project?.lang || DEFAULT_SYSTEM_LANGUAGE];
    }

    return (
      <InnerCollapsibleRow doPropagate fullWidth>
        <StandardInput
          key={"locationGroupManager_" + pathToValue.join("-") + key}
          id={"locationGroupManager_" + pathToValue.join("-") + key}
          title={name}
          type={type}
          value={formattedValue}
          options={
            values &&
            values.map((v) => ({
              id: v.id,
              title: intl.formatMessage(v.title),
            }))
          }
          disabled={disableTypeInput || !editMode}
          onChange={(val) => this.handleInputChange(pathToValue, val)}
          {...props}
        />
      </InnerCollapsibleRow>
    );
  }

  calcSection(section) {
    const { title, fields, props, id } = section;
    const {
      selectedGroup, currentGroupLocations, typeIdToNameMap
    } = this.state;
    const { editMode } = this.props;
    let groupType =
      selectedGroup && Object.keys(_.get(selectedGroup, ["types"], {}) || {})[0];
    let filterType = typeIdToNameMap && groupType && typeIdToNameMap[groupType];

    return (
      <InnerCollapsible
        fullWidth
        key={"managerCard_" + title + id}
        keyId={"managerCard_" + title + id}
        title={title}
        open={true}
        isFocused={true}
        isSelected={true}
        {...props}
      >
        <div style={{ minHeight: 310 }}>
          {fields.map((field, index) => this.calcInputField(field, index, id))}
          {Boolean(filterType) && <InnerCollapsibleRow doPropagate fullWidth>
            <SelectLocations
              isMulti
              isEditMode={editMode}
              selectedLocations={currentGroupLocations}
              showSelectType={false}
              filterType={filterType}
              onSelectionChange={this.handleLocationChange}
            />
          </InnerCollapsibleRow>}
        </div>
      </InnerCollapsible>
    );
  }

  render() {
    return (
      <MenuScrollbar>
        <div style={{ padding: theme.padding * 2 }}>
          {groupSections.map((section) => this.calcSection(section))}
        </div>
      </MenuScrollbar>
    );
  }
}

LocationsGroupsManagerCard = injectIntl(LocationsGroupsManagerCard);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      rtl: state.app.rtl,
      companies: state.companies.map,
    }),
    {
      startToast,
      draftValidator,
      onDraftModeChange,
      updateLocationsGroup,
      uploadPropertiesInstances,
      getNewGroupId,
    }
  )
);

export default enhance(LocationsGroupsManagerCard);
