import React from "react";
import { injectIntl } from "react-intl";
import { connectContext } from "react-connect-context";
import { connect } from "react-redux";
import { hoistStatics } from "recompose";
import { compose } from "redux";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import _ from "lodash";

// Components
import InputField from "../../components/CementoComponents/InputField";
import MenuScrollbar from "../../components/CementoComponents/MenuScrollbar";
import SelectLocations from "../../components/CementoComponents/SelectLocations";

import SelectExtraData from "../../components/CementoComponents/SelectExtraData";
// Others
import { ProjectContext } from "../../../common/projects/contexts";
import theme from "../../assets/css/theme";
import {
  stageSections,
  checklistSections,
  checklistItemSections,
} from "./config/checklistManagerCardConfig";
import {
  updateLocalChecklistItem,
  updateLocalChecklistItems,
} from "../../../common/checklistItems/actions";
import { updateLocalChecklists } from "../../../common/checklists/actions";
import { ChecklistPageContext } from "../../../common/checklists/contexts";
import { startToast } from "../../../common/app/actions";
import systemMessages from "../../../common/app/systemMessages";
import { CLI_PERIODS_DAILY } from "../../../common/checklistItemsInstances/clItemInstancePeriods";
import { updateLocalStages } from "../../../common/stages/actions";
import InnerCollapsible from "../../components/CementoComponents/InnerCollapsible";
import InnerCollapsibleRow from "../../components/CementoComponents/InnerCollapsibleRow";

class ChecklistManagerCard extends React.Component {
  constructor(props) {
    super(props);
    this.calcInputField = this.calcInputField.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.propagateDuplicatedChecklists =
      this.propagateDuplicatedChecklists.bind(this);
    this.propagateChecklistInfoToItems =
      this.propagateChecklistInfoToItems.bind(this);
    this.dispatchBasedOnCardType = this.dispatchBasedOnCardType.bind(this);
    this.debouncedReduxUpdate = this.debouncedReduxUpdate.bind(this);
    this.handleChecklistInputChange =
      this.handleChecklistInputChange.bind(this);
    this.handleChecklistItemInputChange =
      this.handleChecklistItemInputChange.bind(this);
    this.updateReduxChecklists = this.updateReduxChecklists.bind(this);
    this.updateReduxChecklistItem = this.updateReduxChecklistItem.bind(this);
    this.getSelectedLocationType = this.getSelectedLocationType.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleChecklistLocationChange =
      this.handleChecklistLocationChange.bind(this);
    this.handleChecklistItemLocationChange =
      this.handleChecklistItemLocationChange.bind(this);
    this.handleStageInputChange = this.handleStageInputChange.bind(this);
    this.updateReduxStage = this.updateReduxStage.bind(this);
    this.handleSectionClick = this.handleSectionClick.bind(this);
    this.calcSection = this.calcSection.bind(this);

    this.state = {
      cardType: "",
      currActiveSection: null,
      currOpenedSections: {},
    };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({ firstMount: true }, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  componentDidUpdate(preProps, prevState) {
    const { stage, checklist, checklistItem, cardType } = this.state;
    if (
      prevState.cardType != cardType ||
      prevState.checklistItem != checklistItem ||
      prevState.checklist != checklist ||
      prevState.stage != stage
    )
      this.handleCardLoad();
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};

    if (nextProps.checklistItem) {
      let nextChecklistItemId = nextProps.getNested(["checklistItem", "id"]);
      if (
        !props.checklistItem ||
        !_.isEqual(props.checklistItem, nextProps.checklistItem)
      )
        newStateChanges.checklistItem = nextProps.getNested(
          ["checklistItems", nextChecklistItemId],
          {}
        );
      else if (
        props.isValDiff(nextProps, ["checklistItems", nextChecklistItemId])
      ) {
        newStateChanges.checklistItem = nextProps.getNested(
          ["checklistItems", nextChecklistItemId],
          {}
        );
        newStateChanges.checklistItem = newStateChanges.checklistItem.toJS
          ? newStateChanges.checklistItem.toJS()
          : newStateChanges.checklistItem;
      }
    }

    if (nextProps.checklist) {
      let nextChecklistId = nextProps.getNested(["checklist", "id"]);
      if (!props.checklist || !_.isEqual(props.checklist, nextProps.checklist))
        newStateChanges.checklist = nextProps.getNested(
          ["checklists", nextChecklistId],
          {}
        );
      else if (props.isValDiff(nextProps, ["checklists", nextChecklistId])) {
        newStateChanges.checklist = nextProps.getNested(
          ["checklists", nextChecklistId],
          {}
        );
        newStateChanges.checklist = newStateChanges.checklist.toJS
          ? newStateChanges.checklist.toJS()
          : newStateChanges.checklist;
      }

      if (newStateChanges.checklist)
        newStateChanges.selectedLocationType = this.getSelectedLocationType(
          newStateChanges.checklist
        );
    }

    if (nextProps.stage) {
      let nextStageId = nextProps.getNested(["stage", "id"]);
      if (!props.stage || !_.isEqual(props.stage, nextProps.stage))
        newStateChanges.stage = nextProps.getNested(
          ["stages", nextStageId],
          {}
        );
      else if (props.isValDiff(nextProps, ["stages", nextStageId])) {
        newStateChanges.stage = nextProps.getNested(
          ["stages", nextStageId],
          {}
        );
        newStateChanges.stage = newStateChanges.stage.toJS
          ? newStateChanges.stage.toJS()
          : newStateChanges.stage;
      }
    }

    newStateChanges.cardType = null;
    if (
      Boolean(nextProps.stage && nextProps.checklist && nextProps.checklistItem)
    )
      newStateChanges.cardType = "checklistItem";
    else if (Boolean(nextProps.stage && nextProps.checklist))
      newStateChanges.cardType = "checklist";
    else if (Boolean(nextProps.stage)) newStateChanges.cardType = "stage";

    const oldStageId = (this.props.stage || {}).id;
    const oldChecklistId = (this.props.checklist || {}).id;
    const oldChecklistItemId = (this.props.checklistItem || {}).id;
    if (
      (newStateChanges.stage &&
        (newStateChanges.stage.id !== oldStageId || !oldStageId)) ||
      (newStateChanges.checklist &&
        (newStateChanges.checklist.id !== oldChecklistId || !oldChecklistId)) ||
      (newStateChanges.checklistItem &&
        (newStateChanges.checklistItem.id !== oldChecklistItemId ||
          !oldChecklistItemId))
    ) {
      newStateChanges.currActiveSection = "basic";
      newStateChanges.currOpenedSections = {
        [newStateChanges.currActiveSection]: true,
      };
    }

    if (Boolean(Object.keys(newStateChanges).length))
      this.setState(newStateChanges, props.firstMount && this.handleCardLoad);
  }

  handleCardLoad() {
    const { lang, onCardLoad, isEditMode } = this.props;
    const { stage, checklist, checklistItem } = this.state;

    let title = this.dispatchBasedOnCardType(
      () => stage.getNested(["title", lang]),
      () => checklist.getNested(["description"]),
      () => checklistItem.getNested(["description"]),
      ""
    );

    if (onCardLoad) {
      let headerParams = {
        editable: false,
        title,
      };

      onCardLoad(headerParams, null, isEditMode, undefined, title);
    }
  }

  updateReduxStage() {
    const { selectedProjectId, updateLocalStages } = this.props;
    const { stage } = this.state;

    if (stage && updateLocalStages)
      updateLocalStages(selectedProjectId, { stage });
  }

  updateReduxChecklists() {
    const {
      selectedProjectId,
      updateLocalChecklists,
      updateLocalChecklistItems,
    } = this.props;
    const { checklistsToUpdate, checklistItemsToUpdate } = this.state;

    if (checklistItemsToUpdate && updateLocalChecklistItems)
      updateLocalChecklistItems(selectedProjectId, checklistItemsToUpdate);
    if (checklistsToUpdate && updateLocalChecklists)
      updateLocalChecklists(selectedProjectId, checklistsToUpdate);

    this.setState({ checklistItemsToUpdate: null, checklistsToUpdate: null });
  }

  updateReduxChecklistItem() {
    const { selectedProjectId, updateLocalChecklistItem } = this.props;
    const { checklistItem } = this.state;

    if (updateLocalChecklistItem && checklistItem)
      updateLocalChecklistItem(selectedProjectId, checklistItem);
  }

  debouncedReduxUpdate() {
    if (!this.debouncedUpdate)
      this.debouncedUpdate = AwesomeDebouncePromise(() => {
        this.dispatchBasedOnCardType(
          this.updateReduxStage,
          this.updateReduxChecklists,
          this.updateReduxChecklistItem
        );
        this.debouncedUpdate = false;
      }, 500);

    this.debouncedUpdate();
  }

  getSelectedLocationType(checklist) {
    let selectedLocationType = checklist.getNested(["locations"]);

    if (typeof selectedLocationType === "object") {
      selectedLocationType = Object.keys(selectedLocationType);
      selectedLocationType = selectedLocationType[0] || null;
    }

    return selectedLocationType;
  }

  calcSection(section, index) {
    const {
      currActiveSection,
      currOpenedSections,
      cardType,
      checklist,
      checklistItem,
      selectedLocationType,
    } = this.state;
    const { isEditMode } = this.props;

    const { title, fields, props, show, id } = section;

    let selectedLocations = this.dispatchBasedOnCardType(
      () => {},
      () => checklist.getNested(["locations", selectedLocationType], null),
      () => {
        let itemLocations = checklistItem.getNested(
          ["onlyLocations", selectedLocationType],
          false
        );

        if (!itemLocations)
          return checklist.getNested(["locations", selectedLocationType], null);

        return itemLocations;
      }
    );
    return (
      Boolean(show) && (
        <InnerCollapsible
          fullWidth
          key={"managerCard_" + title + id}
          keyId={"managerCard_" + title + id}
          title={title}
          open={currOpenedSections.getNested([id], false)}
          isFocused={currActiveSection === id}
          isSelected={currActiveSection === id}
          onClick={() => this.handleSectionClick(id, true)}
          {...props}
        >
          <div onClick={() => this.handleSectionClick(id)}>
            {fields.map((field, index) =>
              this.calcInputField(field, index, id)
            )}
            {Boolean(
              fields.length && cardType !== "stage" && id === "basic"
            ) && (
              <InnerCollapsibleRow doPropagate fullWidth>
                <SelectLocations
                  isMulti
                  onlyLocations={
                    cardType === "checklistItem"
                      ? checklist.getNested(
                          ["locations", selectedLocationType],
                          null
                        )
                      : null
                  }
                  selectedLocations={selectedLocations}
                  filterType={selectedLocationType}
                  onChangeType={(val) =>
                    this.handleInputChange(["locations"], val)
                  }
                  onSelectionChange={this.handleLocationChange}
                  isEditMode={isEditMode}
                  showSelectType={cardType !== "checklistItem"}
                />
              </InnerCollapsibleRow>
            )}
            {Boolean(
              fields.length && cardType === "checklistItem" && id === "basic"
            ) && (
              <InnerCollapsibleRow doPropagate fullWidth>
                <SelectExtraData
                  isEditMode={isEditMode}
                  extraDataObj={checklistItem.getNested(["extraData"], {})}
                  subjectName={"checklistItemsInfo"}
                  onChange={(newExtraDataObj) =>
                    this.handleInputChange(["extraData"], newExtraDataObj)
                  }
                />
              </InnerCollapsibleRow>
            )}
          </div>
        </InnerCollapsible>
      )
    );
  }

  handleSectionClick(sectionId, setOpen) {
    this.setState((prevState) => {
      const {
        currOpenedSections: prevOpenedSections,
        currActiveSection: prevActiveSection,
      } = prevState;
      let newStateChanges = {};

      if (setOpen)
        newStateChanges.currOpenedSections = prevOpenedSections.setNested(
          [sectionId],
          !prevOpenedSections.getNested([sectionId])
        );

      if (prevActiveSection !== sectionId)
        newStateChanges.currActiveSection = sectionId;

      return newStateChanges;
    });
  }

  calcInputField(field, key, sectionId) {
    const { cardType, selectedLocationType } = this.state;
    const {
      isEditMode,
      trades,
      projectCompanies,
      checklistsCompleteInfo,
      checklistItemsMap,
      stage,
      checklist,
      checklistItem,
      lang,
      intl,
      focusOnInput,
    } = this.props;
    let { name, type, pathToValue, values = null, props = {} } = field;
    const { style, overWriteStyle = false, ...rest } = props;

    let autoFocus = false;
    let value = this.state.getNested([cardType, ...pathToValue], null);

    key =
      "inputs-" +
      key +
      "-" +
      sectionId +
      "-" +
      this.dispatchBasedOnCardType(
        () => stage.getNested(["id"]),
        () => checklist.getNested(["id"]),
        () => checklistItem.getNested(["id"])
      );

    // Handle special value
    if (type === "SelectionList" && pathToValue[0] === "locations") {
      value = selectedLocationType;
    }

    // Handle special name
    if (cardType === "stage" && pathToValue[0] === "title") {
      name = `${intl.formatMessage(name)} (${lang})`;
      pathToValue = pathToValue.concat(lang);
      value = this.state.getNested([cardType, ...pathToValue], null);
    }

    // Handle special InputFields
    if (type === "SelectionList" && typeof value !== "object")
      value = { [value]: value };

    // Handle SelectionList special values
    if (
      type === "SelectionList" &&
      pathToValue[0] === "trade" &&
      values.length === 0
    )
      trades.loopEach((id, trade) => {
        if (trade.getNested(["getTitle"]))
          values.push({ id, title: trade.getNested(["getTitle"]) });
      });
    else if (
      type === "SelectionList" &&
      pathToValue[0] === "permissions" &&
      checklistsCompleteInfo &&
      checklistItemsMap
    ) {
      if (values.length === 0)
        Object.entries(projectCompanies).forEach(([id, company]) =>
          values.push({ id, title: company.name })
        );

      let sampleItemId = checklistsCompleteInfo.getNested([
        checklist.getNested(["id"]),
        "itemsIds",
        0,
      ]);
      value = Object.values(
        checklistItemsMap.getNested([sampleItemId, ...pathToValue], [])
      )[0];
      value = { [value]: value };
    }

    // Handle special condion rendering
    if (type === "Boolean" && pathToValue[0] === "type")
      if (checklist.getNested(["duplicatable"])) return;

    if (type === "Boolean" && pathToValue[0] === "duplicatable")
      if (checklist.getNested(["type"]) === "routine") return;

    // Handle focus
    if (focusOnInput && ["description", "title"].includes(pathToValue[0]))
      autoFocus = true;

    return (
      <InnerCollapsibleRow doPropagate fullWidth>
        <InputField
          autoFocus={autoFocus}
          style={overWriteStyle ? style : { ...inputStyles, ...(style || {}) }}
          key={"checklistManager_" + pathToValue.join("-") + key}
          id={"checklistManager_" + pathToValue.join("-") + key}
          onChange={(val) => this.handleInputChange(pathToValue, val)}
          disabled={
            cardType === "checklistItem" && pathToValue[0] === "locations"
              ? true
              : !isEditMode
          }
          name={name}
          type={type}
          value={value}
          values={values}
          {...rest}
        />
      </InnerCollapsibleRow>
    );
  }

  handleInputChange(pathToValue, val) {
    let stateChanges = this.dispatchBasedOnCardType(
      () => this.handleStageInputChange(pathToValue, val),
      () => this.handleChecklistInputChange(pathToValue, val),
      () => this.handleChecklistItemInputChange(pathToValue, val)
    );

    this.setState(stateChanges, this.debouncedReduxUpdate);
  }

  handleStageInputChange(pathToValue, val) {
    const { stage } = this.state;

    let updatedStage = stage.setNested(pathToValue, val);

    return { stage: updatedStage };
  }

  handleChecklistInputChange(pathToValue, val) {
    const { checklist } = this.state;
    const { startToast } = this.props;
    let checklistsToUpdate = null;
    let checklistItemsToUpdate = null;
    let shouldPropagateToDupsChecklists = true;

    switch (pathToValue[0]) {
      case "type":
        val = val ? "routine" : null;
        checklistItemsToUpdate = this.propagateChecklistInfoToItems(
          pathToValue,
          val
        );
        break;

      case "permissions":
        checklistItemsToUpdate = this.propagateChecklistInfoToItems(
          pathToValue,
          val
        );
        return { checklistItemsToUpdate };

      case "duplicatable":
        if (
          checklist.getNested(["duplicatable"]) &&
          checklist.getNested(["duplicationNo"]) >= 1
        ) {
          startToast({ title: systemMessages.unableToSetDuplicatableFalse });
          return;
        }
        break;

      case "locations":
        val = { [val]: {} };
        checklistItemsToUpdate = this.propagateChecklistInfoToItems(
          ["onlyLocations"],
          null
        );
        shouldPropagateToDupsChecklists = false;
        break;

      default:
        break;
    }

    let originChecklist = checklist.setNested(pathToValue, val);
    checklistsToUpdate = shouldPropagateToDupsChecklists
      ? this.propagateDuplicatedChecklists(originChecklist, pathToValue, val)
      : { [originChecklist.id]: originChecklist };

    return {
      checklist: originChecklist,
      checklistsToUpdate,
      checklistItemsToUpdate,
    };
  }

  handleChecklistItemInputChange(pathToValue, val) {
    const { checklistItem } = this.state;

    switch (pathToValue[0]) {
      case "weight":
        if (val.includes(".") || !Number.isInteger(Number(val))) return;
        val = parseInt(val);
        break;
      case "extraData":
        break;
      default:
        if (val instanceof Object) val = Object.values(val)[0];
        break;
    }

    return { checklistItem: checklistItem.setNested(pathToValue, val) };
  }

  dispatchBasedOnCardType(
    stageActionFunc,
    checklistActionFunc,
    checklistItemsActionFunc,
    defaultReturn = null
  ) {
    const { cardType } = this.state;

    switch (cardType) {
      case "stage":
        return stageActionFunc();
      case "checklist":
        return checklistActionFunc();
      case "checklistItem":
        return checklistItemsActionFunc();
      default:
        return defaultReturn;
    }
  }

  propagateDuplicatedChecklists(originChecklist, pathToValue, val) {
    const { checklistsCompleteInfo, checklists } = this.props;

    let originChecklistId = originChecklist.getNested(["id"]);
    let idsChecklistsToUpdate = checklistsCompleteInfo.getNested([
      originChecklistId,
      "duplicatedChecklistIds",
    ]);
    let updatedChecklists = { [originChecklistId]: originChecklist };
    if (idsChecklistsToUpdate) {
      idsChecklistsToUpdate.loopEach((i, id) => {
        let updatedChecklist = checklists.getNested([id]);
        updatedChecklist = updatedChecklist.setNested(pathToValue, val);

        updatedChecklists = updatedChecklists.setNested([id], updatedChecklist);
      });
    }

    return updatedChecklists;
  }

  propagateChecklistInfoToItems(pathToValue, val) {
    const { checklist, selectedLocationType } = this.state;
    const { checklistsCompleteInfo, checklistItemsMap } = this.props;
    let checklistItemsIds = checklistsCompleteInfo.getNested([
      checklist.getNested(["id"]),
      "itemsIds",
    ]);

    let recalculatedPathToValue = pathToValue;
    switch (pathToValue[0]) {
      case "type":
        recalculatedPathToValue = ["period"];
        val = val === "routine" ? CLI_PERIODS_DAILY : null;
        break;
      default:
        break;
    }

    let updatedChecklistItems = checklistItemsIds.map((id) => {
      let item = checklistItemsMap.getNested([id]);

      if (recalculatedPathToValue[0] === "onlyLocations") {
        let itemLocations = item.getNested(["onlyLocations"], null);
        if (itemLocations === null) return item;

        // Remove location if removed from checklist
        if (val)
          itemLocations
            .getNested([selectedLocationType], {})
            .loopEach((i, loc) => {
              if (!val.getNested([selectedLocationType, loc.id], false))
                delete itemLocations[selectedLocationType][loc.id];
            });

        // If in the end the itemLocations are the same as the checklist, set onlyLocations to null
        if (_.isEqual(val, itemLocations)) val = null;
        else val = itemLocations;
      }

      return item.setNested(recalculatedPathToValue, val);
    });

    return updatedChecklistItems;
  }

  handleLocationChange(newLocationsList) {
    this.dispatchBasedOnCardType(
      () => {},
      () => this.handleChecklistLocationChange(newLocationsList),
      () => this.handleChecklistItemLocationChange(newLocationsList)
    );
  }

  handleChecklistLocationChange(newLocationsList) {
    const { checklist } = this.state;
    let originChecklist = this.generalUpdateLocationFunc(
      checklist,
      ["locations"],
      newLocationsList
    );

    if (!originChecklist) return;

    let newLocationsVal = originChecklist.getNested(["locations"]);
    let checklistsToUpdate = { [originChecklist.id]: originChecklist };

    let checklistItemsToUpdate = this.propagateChecklistInfoToItems(
      ["onlyLocations"],
      newLocationsVal
    );

    this.setState(
      {
        checklist: originChecklist,
        checklistsToUpdate,
        checklistItemsToUpdate,
      },
      this.debouncedReduxUpdate
    );
  }

  handleChecklistItemLocationChange(newLocationsList) {
    const { checklistItem } = this.state;

    let item = this.generalUpdateLocationFunc(
      checklistItem,
      "onlyLocations",
      newLocationsList
    );

    if (!item) return;

    this.setState({ checklistItem: item }, this.debouncedReduxUpdate);
  }

  generalUpdateLocationFunc(itemToUpdate, originPathToValue, newLocationsList) {
    const { selectedLocationType } = this.state;

    let newLocationsObj = { [selectedLocationType]: {} };
    newLocationsList.loopEach((i, loc) => {
      if (loc.hasOwnProperty("selected") && Boolean(loc.selected))
        newLocationsObj = newLocationsObj.setNested(
          [selectedLocationType, loc.id],
          { id: loc.id }
        );
    });

    return itemToUpdate.setNested([originPathToValue], newLocationsObj);
  }

  render() {
    let sections = this.dispatchBasedOnCardType(
      () => stageSections,
      () => checklistSections,
      () => checklistItemSections,
      []
    );

    return (
      <MenuScrollbar isSmooth={true}>
        <div style={{ padding: theme.padding * 2 }}>
          {sections.map(this.calcSection)}
        </div>
      </MenuScrollbar>
    );
  }
}

const inputStyles = {
  fontSize: theme.fontSizeH6,
  // marginBottom: theme.margin - 5,
};

const styles = {
  cardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: theme.headerHeightSecondary,
    minHeight: theme.headerHeightSecondary,
    backgroundColor: theme.headerInfoColor,
    fontWeight: theme.strongBold,
  },
  inputFieldsContainer: {
    padding: theme.paddingSize * 2,
    transition: "all 150ms ease 0s",
    marginBottom: theme.margin,
  },
  tabHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: theme.headerHeightSecondary,
    backgroundColor: theme.backgroundColorBright,
    borderBottom: "2px solid " + theme.backgroundColor,
    paddingLeft: 2 * theme.paddingSize,
    paddingRight: 2 * theme.paddingSize,
  },
  headerSectionStyle: {
    alignItems: "center",
    textAlign: "center",
    marginBottom: theme.paddingSize,
    color: theme.headerColorDark,
    fontFamily: "Assistant - Semi Bold",
    fontSize: theme.fontSizeH6,
    fontWeight: theme.bold,
  },
};

ChecklistManagerCard = injectIntl(ChecklistManagerCard);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connectContext(ChecklistPageContext.Consumer),
  connect((state) => ({}), {
    updateLocalStages,
    updateLocalChecklists,
    updateLocalChecklistItem,
    updateLocalChecklistItems,
    startToast,
  })
);

export default enhance(ChecklistManagerCard);
