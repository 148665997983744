import { Record } from '../transit';
import * as projectStages from './projectStages';

const Project = Record({
  createdAt: null,
  lastUpdate: null,
  counter: null,
  fue: null,
  id: null,
  address: null,
  city: null,
  clients: {},
  description: null,
  images: null,
  companyId: null,
  company: null,
  title: null,
  clientAvatarUrl: null,
  owner: {},
  location: {},
  permissions: null,
  members: null,
  deletedMembers: null, // Use to load the deatails 
  type: null,
  settings: null,
  stage: projectStages.CONSTRUCTION,
  about: null,
  tzLocation: null,
  lang: null,
  periodicCL_Alerts: null,
  showOnAdmin:null,
  country: null,
  region: null,
}, 'project');

export default Project;
