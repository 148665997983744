import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {getUniqueFirebaseId} from '../../../common/lib/utils/utils';
import _fp from 'lodash/fp';
import Image from './Image';
import theme from '../../assets/css/theme';
import {PictureUpload} from '../index';
import {NoValueComponent} from './InputField';
import propertiesMessages from '../../../common/propertiesTypes/propertiesMessages';
import PDF from '../../assets/img/icons/pdf.png';
import moment from 'moment';
import systemMessages from '../../../common/app/systemMessages';
import Text from './Text';
import Button from '../../app/standardComponents/Button';
import {injectIntl} from 'react-intl';
import {uploadFirebaseStatus, uploadImage} from '../../../common/images/actions';
import {ValueComponent} from './StandardInput';
import UploadProgressIndicator from './UploadProgressIndicator';

const ICON_IMAGE_MAX_WIDTH = 160;
const DEFAULT_MAX_CHARS = 150;
const ICON_IMAGE_MAX_SIZE = 68;

export const uploadStatus = {
  IN_PROGRESS: 'inProgress',
  SUCCESS: 'success',
  ERROR: 'error',
}

const getFileTypes = (type) => {
  if (type === 'Picture') {
    return '.png,.jpeg,.jpg';
  }

  if (type === 'PDF') {
    return '.pdf';
  }

  return '.png,.jpeg,.jpg,.pdf';
}

const UploadsComponent = ({
  settings,
  type,
  onChange,
  value,
  multiple,
  immediateUpload,
  disabled,
  mode,
  onCardClick,
  onImageSelect,
  size,
  withResize,
  resizePercent,
  openPDFInWebPage,
  intl,
  noValueComponentValueStyle,
  renderPreview,
}) => {
  const [isArchivable, setIsArchivable] = useState(false);
  const [visibleArchives, setVisibleArchives] = useState({});
  const [replace, setReplace] = useState(false);
  const [accept, setAccept] = useState(getFileTypes(type));
  const [progress, setProgress] = useState(null);

  useEffect(() => {
    setIsArchivable(settings?.isArchivable || false);
  }, [settings?.isArchivable]);

  useEffect(() => {
    setReplace(type === 'Picture' || type === 'PDF');
  }, [type]);

  useEffect(() => {
    if (settings?.accept) {
      setAccept(settings?.accept);
    } else {
      setAccept(getFileTypes(type));
    }
  }, [settings?.accept, type]);

  const onUploadStatusChange = ({status, progress}) => {
    if (status === uploadFirebaseStatus.RUNNING) {
      setProgress(progress);

      if (progress === 100) {
        setTimeout(() => setProgress(null), 600);
      }
    } else {
      setProgress(null);
    }
  }

  const onFileUploadSubmit = (uploadedChange, refFileIndex, uriPropPath) => {
    let uploadedArray = Array.isArray(uploadedChange) ? [...uploadedChange] : [ uploadedChange ];

    if (uriPropPath)
      uploadedArray = uploadedArray.map(uploadRow => {
        uploadRow = {
          ...uploadRow,
          [uriPropPath]: uploadRow.data,
        };
        delete uploadRow.data;
        return uploadRow;
      });


    let newValArray = [];
    const hasRefFileIndex = !_.isNil(refFileIndex);

    if (isArchivable) {
      uploadedArray = uploadedArray.map(file => ({
        ...file,
        version: 1,
        fileRefId: getUniqueFirebaseId(),
      }));
    }

    if (replace) {
      newValArray = uploadedArray;
    } else {
      newValArray = value.slice();
      if (hasRefFileIndex) {
        const originalRef = newValArray[refFileIndex];
        if (originalRef) {
          const updatedOriginalFileRef = {
            ...originalRef,
            isArchive: true,
            fileRefId: originalRef.fileRefId || getUniqueFirebaseId(),
            version: originalRef.version || 1 ,
          };
          newValArray[refFileIndex] = updatedOriginalFileRef;
          uploadedArray[0].version = updatedOriginalFileRef.version + 1;
          uploadedArray[0].fileRefId = updatedOriginalFileRef.fileRefId;
        }
      }

      newValArray = newValArray.concat(uploadedArray);
    }

    if (hasRefFileIndex) {
      setVisibleArchives({});
    }

    if (!multiple && immediateUpload) {
      const { name, uri, extension } = newValArray[0];
      const img = {
        data: uri,
        extension,
      };

      onChange(newValArray[0], uploadStatus.IN_PROGRESS);
      uploadImage(img, name,'drawings', undefined, undefined, onUploadStatusChange).then((remoteUrl) => {
        onChange(remoteUrl, uploadStatus.SUCCESS);
      }, (e) => {
        onChange(uri, uploadStatus.ERROR);
      });

      return;
    }

    onChange?.(multiple ? newValArray : newValArray[0]);
  }

  const onRemoveFile = (index) => {
    let newValArray = null;

    const isArchivesVisible = Boolean(Object.values(visibleArchives).filter(Boolean).length);

    if (multiple && Number.isInteger(index)) {
      newValArray = _fp.set([index, 'isDeleted'], true, value);
      const file = _.get(newValArray, [index], null);
      if (file && file.fileRefId) {
        const [indexOfLatestFileVersion, latestArchiveFile] = newValArray.reduce(([indexOfLatestArchiveFile, latestFileArchive], currFile, index) => {
          const isFileArchiveOfTargetFile = currFile && !currFile.isDeleted && currFile.isArchive && (currFile.fileRefId === file.fileRefId);

          return (
            isFileArchiveOfTargetFile && (!latestFileArchive || currFile.version > latestFileArchive.version)
              ? [index, currFile]
              : [indexOfLatestArchiveFile, latestFileArchive]
          );
        }, []);

        if (latestArchiveFile) {
          newValArray = _fp.set([indexOfLatestFileVersion, 'isArchive'], false, newValArray);
        } else if (isArchivesVisible) {
          setVisibleArchives({});
        }
      } else if (isArchivesVisible) {
        setVisibleArchives({});
      }

    }

    onChange?.(newValArray);
  }

  const getFileRows = () => {
    const isArchivesVisible = Boolean(Object.values(visibleArchives).filter(Boolean).length);
    const filesArray = [...value];
    const maxImagesPerRow = mode === "card" ? 3 : 5;
    const filesByRefId = filesArray.reduce((acc, file, index) => {
      if (!file || (file?.isDeleted)) {
        return acc;
      }
      const path = [
        file.fileRefId || getFileSrc(file),
        _.get(acc, [file.fileRefId || getFileSrc(file)], []).length || 0,
      ]
      return _.set(acc, path, {
        ...file,
        originalIndex: index
      });
    }, {});
    const fileComponents = [];

    Object.entries(filesByRefId).forEach(([fileRefId, files]) => {
      files.sort((a, b) => b.originalIndex - a.originalIndex)// Because we sort, first file is the original file
      .forEach((file, index) => {

        if (isArchivable && !disabled && index === 0 && isArchivesVisible && visibleArchives[fileRefId])
          fileComponents.push({
            fileRefId,
            renewButton: true,
            component: getUploadImageComponent(file.originalIndex, 'uri')
          });

        if ((isArchivesVisible && visibleArchives[fileRefId]) || (!isArchivesVisible && !file.isArchive))
          fileComponents.push(file);
      });
    });

    const fileRows = [[]];
    fileComponents.forEach((file, index) => {
      if (fileRows[fileRows.length - 1].length === maxImagesPerRow)
        fileRows.push([]);
      fileRows[fileRows.length - 1].push(file);
    });

    if (!disabled && !isArchivesVisible) {
      if (fileRows[fileRows.length - 1].length === maxImagesPerRow)
        fileRows.push([]);
      if (multiple || !filesArray?.length) {
        fileRows[fileRows.length-1].push({
          addButton: true,
          component: getUploadImageComponent(undefined, 'uri')
        });
      }
    }

    if (fileComponents.length === 1 && !multiple && renderPreview) {
      renderPreview(fileComponents[0]);
    }

    return fileRows;
  };

  const getImageIcon = (file, index, source, onSelect) => {
    const src = source || getFileSrc(file);
    const isArchivesVisible = Object.values(visibleArchives).filter(Boolean).length;
    const onRemove = Boolean(!isArchivable || isArchivesVisible && !file.isArchive)
      && (() => onRemoveFile(multiple ? index : null));

    return (
      <Image
        regularImage={true}
        onRemove={onRemove}
        showButtonsOnHover={!disabled}
        onClick={onSelect || onImageSelect}
        src={src}
        imageContainerStyle={{ maxWidth: ICON_IMAGE_MAX_SIZE }}
      />
    );
  };

  const getUploadImageComponent = (refFileIndex, uriPropPath) => {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          border: `1px solid ${theme.brandNeutralLight}80`,
          height: '100%'
        }}
      >
        <div style={{ alignSelf: 'center', padding: 10 }}>
          <PictureUpload
            mode={mode}
            multiple={!_.isNil(refFileIndex) ? false : multiple}
            accept={accept}
            height={size}
            withResize={withResize}
            resizePercentFromDefaultResize={resizePercent}
            onSubmit={(uploadedArray) => onFileUploadSubmit(uploadedArray, refFileIndex, uriPropPath)}
          />
        </div>
      </div>
    );
  };

  const onVisibleArchiveClick = (fileRefId) => {
    return () => setVisibleArchives({
      ...visibleArchives,
      [fileRefId]: !visibleArchives[fileRefId],
    });
  };

  const getFileSrc = (file) => file && (file.data ? file.data : file.uri);

  const maxImagesPerRow = mode === "card" ? 3 : 5;
  const fileRows = getFileRows();
  const isArchivesVisible = Boolean(Object.values(visibleArchives).filter(Boolean).length);

  if (fileRows.length === 1 && _.isEmpty(_.head(fileRows))) {
    return <NoValueComponent defaultText={propertiesMessages.empty} noValueComponentValueStyle={noValueComponentValueStyle}/>;
  }

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-start' }}>
      {fileRows.map((row) => (
        <>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-start' }}>
            {row.map((file) => {
              const fileUri = file.uriPdf || file.uri;
              let imageComp = null;

              if (file.addButton || file.renewButton)
                imageComp = file.component;
              else if (['pdf', 'application/pdf'].includes(file.type) || ['pdf', 'application/pdf'].includes(file.extension) || file.uriPdf)
                imageComp = (
                  <div style={{ display: 'flex', flex: 1, flexDirection:'column', position:'relative', width: '100%', height: '100%', alignItems: 'center'  }}>
                    {Boolean(openPDFInWebPage)
                      ? getImageIcon(file, file.originalIndex, PDF, () => { if (onImageSelect) onImageSelect(fileUri, true)})
                      : Boolean(fileUri)
                        ? <a href={fileUri} target="_blank">{getImageIcon(file, file.originalIndex, PDF, () => {})}</a>
                        : getImageIcon(file, file.originalIndex, PDF, () => {})
                    }
                  </div>
                );
              else
                imageComp = (
                  <div style={{ display: 'flex', flex: 1, flexDirection:'column', position:'relative', alignItems: 'center' }}>
                    {getImageIcon(file, file.originalIndex)}
                  </div>
                );
              return (
                <div
                  key={getFileSrc(file)}
                  style={{
                    alignItems: 'center',
                    padding: 5,
                    flex: ('1 0 ' + (100 / maxImagesPerRow) + '%'),
                    maxWidth: ICON_IMAGE_MAX_WIDTH ,
                    position: 'relative',
                  }}
                >
                  {imageComp}
                  <UploadProgressIndicator progress={progress} />
                </div>
              );
            })}
          </div>
          <div style={{ display:'flex', flexDirection:'row', justifyContent: 'flex-start' }}>
            {(!row || !row.length) ?
              <ValueComponent defaultText={propertiesMessages.empty} />
              :
              row.map((file) => {
                if (file.addButton)
                  return null;
                const isShowArchivesButton = isArchivable && ((isArchivesVisible && file.renewButton || (disabled && !file.isArchive)) || (!isArchivesVisible && !file.isArchive && !file.addButton));
                const fileRefId = file.fileRefId || getFileSrc(file);
                const fileTitle = file.title || moment(file.uploadTS || file.updatedTS).format(intl.formatMessage(systemMessages.fullDateFormat))

                return (
                  <div
                    key={getFileSrc(file)}
                    style={{
                      display: 'flex',
                      justifyContent: file.renewButton ? 'flex-start' : 'space-between',
                      flexDirection: 'column',
                      padding: 5,
                      flex: ('1 0 ' + (100 / maxImagesPerRow) + '%'),
                      maxWidth: ICON_IMAGE_MAX_WIDTH ,
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {Boolean(isArchivesVisible && !file.renewButton) && (
                        <div style={{ fontWeight: 'bold', padding: theme.verticalMargin, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-wrap' }}>
                          {intl.formatMessage(systemMessages.version)}: {file.version || 1} {Boolean(!file.isArchive) && `(${intl.formatMessage(systemMessages.latest).toLowerCase()})`}
                        </div>
                      )}
                      <Text style={{ padding: theme.verticalMargin, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-wrap' }} maxChars={DEFAULT_MAX_CHARS} maxLines={1}>
                        {fileTitle}
                      </Text>
                    </div>
                    {isShowArchivesButton ? (
                        <Button
                          style={{ marginLeft: 0, marginRight: 0, maxWidth: '100%', minWidth: 'unset', width: 'unset' }}
                          onClick={onVisibleArchiveClick(fileRefId)}
                        >
                          {visibleArchives[fileRefId] ? systemMessages.goBack : systemMessages[disabled ? 'archive' : 'edit']}
                        </Button>
                      ) : <div />
                    }
                  </div>
                );
              })}
          </div>
        </>
      ))}
    </div>
  );
};

export default injectIntl(UploadsComponent);