import Color from 'color';
import commonTheme from '../../../common/app/theme';

const zIndexes = {
  one: 1,
  two: 2,
  three: 10, 
  four: 500, 
  five: 503, 
  six: 1000, 
  seven: 1001, 
  eight: 1004, 
  nine: 1005, 
  ten: 1100, 
  eleven: 1090, 
  twelve: 1105, 
  thirteen: 1200,
  fourteen: 1202,
  fifteen: 1300, 
  sixteen: 1500,
  seventeen: 4999, 
  eighteen: 5000, 
  nineteen: 5001, 
  twenty: 7000, 
  twentyOne: 9998, 
  twentyTwo: 9999,
  twentyThree: 10000,
  twentyFour: 10001,
  twentyFive: 11000,
  twentySix: 100000,
  max: 10000000,
}

const webTheme = {
    tooltipMenusZIndex: 10000,
    zIndexes: {
      imageCarouselModal: zIndexes.max,
      alertsModal: zIndexes.max + 1,
      scrollBar: zIndexes.nineteen,
      numberBadge: zIndexes.two,
      selectableUsers: zIndexes.four,
      locationPickerModal: zIndexes.twentyFour,
      selectionListOption: zIndexes.four,
      companyTableRow: zIndexes.six,
      filterMenuBackdrop: zIndexes.twentyTwo,
      header: zIndexes.twentyThree,
      headerTitles: zIndexes.four,
      headerLinksSecondary: zIndexes.fifteen,
      headerLinks: zIndexes.twentyTwo,
      headerSideLinks: zIndexes.four,
      splitViewModal: zIndexes.twentyThree,
      splitViewCard: zIndexes.eighteen,
      checkToggleButton: zIndexes.three,
      dashboard: zIndexes.sixteen,
      drawingZoomIcon: zIndexes.six - 1, // 999
      building: zIndexes.seventeen,
      floor: zIndexes.four - 400, // 100
      unit: zIndexes.one,
      membersManagerTableLoading: zIndexes.six,
      usersManagementCard: zIndexes.four,
      sideBarNav: zIndexes.eighteen,
      projectSelectBackdrop: zIndexes.six,
      projectSelector: zIndexes.twenty,
      QCAnalyticsColumn: zIndexes.eleven,
      newCertificateInput: zIndexes.six,
      propertyAnalyticsDatePicker: zIndexes.twentyFour,
      tableMainSectionColumn: zIndexes.nineteen,
      tableSectionColumn: zIndexes.seventeen,
      tablePagination: zIndexes.twentyThree,
      tableColumn: zIndexes.eight,
      tableCollapsibleCell: zIndexes.thirteen,
      tableColumnHeader: zIndexes.seven,
      tableExpandColumn: zIndexes.nine,
      tableFirstColumn: zIndexes.seventeen,
    },
    zIndexesLevels: zIndexes,
    nativeBrowserScrollbarSize: 17, 
    lineHeight: 1.5,
    verySmallFontSize: 8,
    smallFontSize: 10,
    mediumFontSize: 12,
    fontSize: 14,
    fontSizeH1: 40,
    fontSizeH2: 32,
    fontSizeH3: 28,
    fontSizeH4: 24,
    fontSizeH5: 20,
    fontSizeH6: 16,
    get fontSizeH6Plus2() { return this.fontSizeH6 + 2 },
    inputFontSize: 18,

    noBold: 100,
    bold: 400,
    strongBold: 700,
    
    minWidth: 75,

    defaultFont:{
      fontSize: '14px',
      fontFamily: 'Assistant',
      fontWeight: "300",
      color: '#2c2c2c',
      lineHeight: "1.5em"
    },
    subFont:{
      fontSize: '11px',
      fontFamily: 'Assistant',
      fontWeight: "400",
      color: '#929292',
      lineHeight: "1.5em" 
    },

    borderRadius: 6,
    circleBorderRadius: 50,

    headerHeight: 60,
    headerHeightSecondary: 54,
    sidebarWidth: 310, //340,
    smallSidebarWidth: 160, //340,
    
    paddingSize: 15,
    rowHeight: 60,
    
    textColor: '#636363',
    textColorDark: '#2e231d',
    textColorGray: '#c0bcba',
    headerColorDark: '#2c2c2c',
    headerInfoColor: '#e5e5e5',
    
    backgroundColor: '#f6f6f6',
    backgroundColorBright: '#ffffff',
    backgroundColorSelected: '#fffaf6',
    backgroundColorHover: '#f3e5da',

    brandPrimary: '#f27d22',
    brandNeutral: '#929292',
    brandNeutralLight: '#a2a2a2',
    brandNeutralDark:'#626161',

    borderLineNeutral: '1px solid #929292',
    borderLineNeutralLight: '1px solid #a2a2a2',
    borderLineHeaderInfo: '1px solid #e5e5e5',
    borderLineHeaderSelection: '1px solid #cccccc',

    inverseTextColor: '#eee',
    inverseBackgroundColor: '#2C2C2C',
    placeholderTextColor: '#BDBDBD',
    iconColor: '#272727',
    get commentsBackColor() { return this.easyLight(this.foregroundColor); },
  


    brandSuccess: '#81C784',
    bransSuccessDark: '#18b251',
    brandInfo: '#3A423A',
    brandWarning: '#FFB300',
    brandDanger: '#E57373',
    brandRealDanger: '#ff3b3b',
    softBackground: '#75777a',
    darkSeparator: '#404142',
    clickable: '#3a51c4',


    inputBorderBottomColor: '#d2d2d2',
  
    commentColor: '',
    get listHeader() { return ({ fontSize: this.smallFontSize, color: this.infoText, fontWeight: 'bold'})},
  
    activeOpacity: .5,
    disabledOpacity: .3,
  
    easyLighter: .05,
    easyDarken: .15,
    darken: .3,
    lighten: .3,
  
    separatorColor: '#E0E0E0',
    margin: 15,
    padding: 10,
    verticalMargin: 5,
    actionBarHeader: 40,
    //get placeholderTextColor() { return this.light(this.textColor); },
    //get infoText() { return this.light(this.textColor); },
    infoText: '#6b6565',

    innerCollapsibleRowBackground: '#fff',
    innerCollapsibleRowSelectedBackground: '#f6efe9',
    get innerCollapsibleBrandPrimaryBorders() {
      return this.brandPrimary + 80;
    },
  
    header: {
      headerStyle: {
        backgroundColor: '#272727'//this.headerColor
      },
      headerTitleStyle: {
        color: '#fff'//this.headerTextColor
      },
      headerTintColor: '#fff',
    },
  
    get headerBarStyle() { 
      return {
        headerStyle: {
          backgroundColor: this.headerColor
        },
        headerTitleStyle: {
          color: this.headerTextColor
        },
        headerTintColor: this.headerTextColor,
      };
    }, 
  
    get headerTabsStyle() { 
      return {
        activeTintColor: this.brandPrimary,
        inactiveTintColor: this.headerTextColor,
        inactiveBackgroundColor: this.headerColor,
        style: {
          backgroundColor: this.headerColor,
        },
        indicatorStyle: {
          backgroundColor: this.brandPrimary,
        }
      };
    }, 
  
    get BottomTabsStyle() { 
      return {
          activeTintColor: this.brandPrimary,
          inactiveTintColor: this.headerTextColor,
          inactiveBackgroundColor: this.headerColor,
          labelStyle: {
            margin: 0,
            padding: 0
          },
          iconStyle : {
            margin: 0,
            padding: 0
          },
          style: {
            backgroundColor: this.headerColor,
          },
          indicatorStyle: {
            backgroundColor: this.brandPrimary,
            position: 'absolute',
            top: 0,
          }
      };
    }, 
  
    
      
    get basicSelectStyles() { 
      return {
        container: styles => ({ ...styles, width: '100%', height: 3 * this.paddingSize, marginTop: 5, }),
        control: styles => ({ ...styles, backgroundColor: '#e2e2e9', border: '0px', boxShadow: '0px', height: 3 * this.paddingSize, minHeight: 3 * this.paddingSize }),
        indicatorSeparator: styles => ({ visibility: 'hidden'}),
        option: styles => ({ ...styles, zIndex: zIndexes.three, cursor:'pointer' }),
        menuList: styles => ({ ...styles, zIndex: zIndexes.three }),
        menu: styles => ({ ...styles, zIndex: zIndexes.three }),
      }
    },
      
    get selectStyles() { 
      return {
        input: styles => ({ ...styles, ...this.defaultFont }),
        singleValue: styles => ({ ...styles, ...this.defaultFont }),
        container: styles => ({ ...styles, backgroundColor: 'transparent', minWidth: 100, minHeight: 15, border: '0px', borderRadius: '0px', flex:1 }),
        control: (styles, { isFocused } = {}) => ({ ...styles, backgroundColor: 'white', border: "1px solid #dee0e5", boxShadow: 0, cursor: 'pointer', minHeight: 0, ...(isFocused ? { '&:hover': {border: `1px solid ${this.brandPrimary}`} } : {}) }),
        dropdownIndicator: styles => ({ ...styles, color: '#808184', lineHeight: 2, padding: 0, paddingLeft: 5, paddingRight: 5 }),
        indicatorSeparator: styles => ({ visibility: 'hidden' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            ...this.defaultFont,
            zIndex: zIndexes.fourteen,
            cursor: 'pointer',
            backgroundColor: this.backgroundColorBright,
            color: isDisabled
              ? '#ccc'
              : isSelected
                ? this.brandPrimary
                : isFocused ? this.brandPrimary : this.brandNeutralDark,
            cursor: isDisabled ? 'not-allowed' : 'default',
          };
        },
        menuList: styles => ({ ...styles, zIndex: zIndexes.fourteen, borderRadius: '0px' }),
        menu: styles => ({ ...styles, zIndex: zIndexes.fourteen, borderRadius: '0px' }),
      }
    },


  
    easyDark(color) { return Color(color).darken(this.easyDarken); },
    dark(color) { return Color(color).darken(this.darken); },
    light(color) { return Color(color).lighten(this.lighten); },
    easyLight(color) { return Color(color).darken(this.easyLighter); },    
    combineStyles(...styles) {
      return function CombineStyles(theme) {
        const outStyles = styles.map((arg) => {
          // Apply the "theme" object for style functions.
          if (typeof arg === 'function') {
            return arg(theme);
          }
          // Objects need no change.
          return arg;
        });

        return outStyles.reduce((acc, val) => Object.assign(acc, val));
      };
    },

    webShadow: { boxShadow: '0 0 18px rgba(0, 0, 0, 0.1)' },
  };

const theme = Object.assign({}, commonTheme, webTheme);
export default theme;  

