import { OrderedMap, Map } from 'immutable';
import { CementoRecordObject, Record } from '../transit';

import * as actions from './actions';
import * as appActions from '../app/actions';
import * as lastUpdatesActions from '../lastUpdates/actions';
import * as clInstancesActions from '../checklistItemsInstances/actions';
import * as clInstancesStatus from '../checklistItemsInstances/clItemInstanceStatus';
import { REPLACE_USER } from '../auth/actions';

import Checklist from './checklist';
import checklistSubscription from './checklistSubscription';
import ChecklistStatusData from './checklistStatusData';
import _ from 'lodash';
import { DEFAULT_LAST_UPDATE_TS } from '../app/constants';

const InitialState = Record({
  map: new CementoRecordObject,
  subscriptions: new CementoRecordObject,
	lastUpdated: new CementoRecordObject,
  lastUpdateAvailable: new CementoRecordObject,  
  subscriptionsLastUpdated:new CementoRecordObject,
  subscriptionsLastUpdateAvailable: new CementoRecordObject,
	didLoad: new CementoRecordObject,
	locationsStatus: new CementoRecordObject, 
}, 'checklists', false);

const initialState = new InitialState;

export default function checklistReducer(state = initialState, action) {
	if (!(state instanceof InitialState)) return initialState;

	switch (action.type) {

    case lastUpdatesActions.GET_LAST_UPDATES: {
      if (!action.payload || !action.payload.projects)
        return state;

      const { projects } = action.payload;
      Object.keys(projects).forEach(projectId => {
        let TS = projects.getNested([projectId,'checklists-checklists','lastUpdateTS'], 1);
        let curr = state.getNested(['lastUpdateAvailable', projectId], null)
        if (curr < TS) state = state.setIn(['lastUpdateAvailable', projectId ], TS);      
      });

      Object.keys(projects).forEach(projectId => {
        let TS = projects.getNested([projectId,'checklists-subscriptions','lastUpdateTS'], 1);
        let curr = state.getNested(['subscriptionsLastUpdateAvailable', projectId], null)
        if (curr < TS) state = state.setIn(['subscriptionsLastUpdateAvailable', projectId ], TS);      
      });
      
      return state;
    }
    case actions.REMOVE_DUPLICATED_CHECKLIST + "_SUCCESS":
    case actions.DUPLICATE_CHECKLIST + "_SUCCESS":
    case actions.EDIT_CHECKLIST_EXTRA_DATA + "_SUCCESS":
      {
        if (!action.payload || !action.payload.checklists)
          return state;

        const { projectId, checklists } = action.payload;

        let checklistsMap = state.getNested(['map', projectId], new CementoRecordObject);
        _.forIn(checklists, (currChecklist, currChecklistKey) => {
          if (_.has(currChecklist, 'id'))
            checklistsMap = checklistsMap.set(currChecklist.id, new Checklist({ ...currChecklist }));
          if (_.isNull(currChecklist))
            checklistsMap = checklistsMap.delete(currChecklistKey);
        });

        state = state.setIn(['map', projectId], checklistsMap);
        return state;
      }

    case actions.GET_LOCAL_CHECKLISTS + "_SUCCESS":
    case actions.GET_CHECKLISTS + "_SUCCESS": {
      if (!action.payload?.projectId)
        return state;

      const { projectId, checklists } = action.payload;
      if (checklists) {
        let checklistsMap = new CementoRecordObject;
        checklists.loopEach((currChecklistKey, currChecklist) => {
          if (_.has(currChecklist, 'id')) {
            currChecklist = currChecklist.toJS ? currChecklist.toJS() : currChecklist;
            checklistsMap = checklistsMap.set(currChecklist.id, new Checklist({ ...currChecklist }));
          }
        });
  
        state = state.setIn(['map', projectId], checklistsMap);
      }
      
      state = state.setIn(['didLoad', projectId], true);
      state = state.setIn(['lastUpdated', projectId], state.getNested(['lastUpdateAvailable', projectId], DEFAULT_LAST_UPDATE_TS));
      return state;
    }

    case actions.UPDATE_LOCAL_CHECKLISTS: {
      const { projectId, checklists } = action.payload;
      if (!projectId || !checklists)
        return state;
      
      checklists.loopEach((key, checklist) => {
        checklist = checklist.toJS ? checklist.toJS() : checklist;
        if (!checklist.createdAt) checklist.createdAt = new Date().getTime();
        
        state = state.setIn(['map', projectId, key], new Checklist({...checklist}));
      });

      return state;
    }

    case actions.DELETE_NEW_CHECKLIST: {
      const { projectId, checklist } = action.payload;

      if (!projectId || !checklist)
        return state;

      return state.deleteIn(['map', projectId, checklist.id])
    }

    case actions.GET_CHECKLISTS_SUBSCRIPTION + "_SUCCESS": 
    case actions.UPDATE_CHECKLISTS_SUBSCRIPTION + "_SUCCESS": {
      
      const { projectId, subscriptions, updateOnly } = action.payload;

      var subscriptionsMap = updateOnly ? (state.getIn(['subscriptions', projectId]) || new CementoRecordObject): new CementoRecordObject;
        Object.keys(subscriptions).forEach((currSubscriptionKey) => {
        let currSubscription = subscriptions[currSubscriptionKey];
        subscriptionsMap = subscriptionsMap.set(currSubscriptionKey, new checklistSubscription({...currSubscription}))
        });

      state = state.setIn(['subscriptionsLastUpdated', projectId], state.getNested(['subscriptionsLastUpdateAvailable', projectId])); 
      return state.setIn(['subscriptions', projectId], subscriptionsMap);
    }

    case actions.GET_CHECKLIST_STATUS_JSON + "_SUCCESS": {
      const { projectId, reportJson, checklistId, checklistItemId } = action.payload;
      var locationsStatusMap = new CementoRecordObject;
      (reportJson || {}).loopEach((buildingKey, currBuilding) => {
        (currBuilding.floors || {}).loopEach((floorKey, currFloor) => {
          locationsStatusMap = locationsStatusMap.set(currFloor.id, currFloor.data ? new ChecklistStatusData({...currFloor.data}) : null);
          (currFloor.units || {}).loopEach((unitKey, currUnit) => {
            locationsStatusMap = locationsStatusMap.set(currUnit.id, currUnit.data ? new ChecklistStatusData({...currUnit.data}) : null);
          });
        });
      });

      return state.setIn(['locationsStatus', projectId], { statusMap: locationsStatusMap, checklistId, checklistItemId });
    }

    // case clInstancesActions.GET_CHECKLIST_ITEM_INSTANCES: {
    //   //const { projectId, checklistItemsInstances, prevInstances, ?????? } = action.payload;
    //   //checklistItemsInstances.loopEach((id, val) => {
    //   //    state = updateChecklistStatusMap(state, val, ??????, projectId, val.status, prevInstances[id], false); // TODO: this code fires clInstancesActions.GET_CHECKLIST_ITEM_INSTANCES wich doesnt have indications about new issues.... so the FALSE flag is BUG!
    //   //});
    //   break;
    // }

    case clInstancesActions.CREATE_CHECKLIST_ITEM_INSTANCE:
    case clInstancesActions.CREATE_CHECKLIST_ITEM_INSTANCE + '_SUCCESS':
    case clInstancesActions.UPDATE_CHECKLIST_ITEM_INSTANCE_STATUS + '_SUCCESS': {
      const { checklistItemInstance, instanceParentChecklistId, projectId, newStatus, prevStatus, totalItems, issueCreationIndication } = action.payload;
      if (checklistItemInstance && instanceParentChecklistId && projectId && newStatus && prevStatus && totalItems && issueCreationIndication)
        return updateChecklistStatusMap(state, checklistItemInstance, instanceParentChecklistId, projectId, newStatus, prevStatus, totalItems, issueCreationIndication);
      return state;
    }

    case actions.CLEAR_CHECKLIST_STATUS_JSON: {
      return state.setIn(['locationsStatus', action.payload.projectId], null)
    }
    case actions.CLEAN_ALL_CACHED_CHECKLIST_ITEMS_AND_INSTANCES+ "_SUCCESS":
    case actions.CLEAN_CHECKLISTS_CACHE_DATA + '_SUCCESS': {
      return initialState;
    }
	}

	return state;
}

function updateChecklistStatusMap(state, checklistItemInstance, instanceParentChecklistId, projectId, newStatus, prevStatus, totalItems, issueCreationIndication) {
	
	let locationStatus = state.getNested(['locationsStatus', projectId])
	if (locationStatus) {
		locationStatus = locationStatus.getNested(['statusMap', checklistItemInstance.locationId])
		if (!locationStatus) locationStatus = { rejectedItems: 0, confirmedItems: 0, resolvedItems: 0, confirmed_2Items: 0, totalItems: totalItems }
		let statusChecklistId = state.getNested(['locationsStatus', projectId, 'checklistId']);
		let statusChecklistItemId = state.getNested(['locationsStatus', projectId, 'checklistItemId']);

		if (statusChecklistId && statusChecklistId != instanceParentChecklistId || 
			statusChecklistItemId && statusChecklistItemId != checklistItemInstance.checklistItemId)
			return state;

		let currentStatus = newStatus ? newStatus : checklistItemInstance.status;
		let previousStatus = prevStatus ? prevStatus : null;
		let actions = {
			previousStatus : { status: previousStatus, action: -1 },
			currentStatus : { status: currentStatus, action: +1 },
		}

		actions.loopEach((i, x) => {
			if (x.status) {
				if (x.status == clInstancesStatus.CLI_STATUS_CONFIRMED) locationStatus = locationStatus.set('confirmedItems', locationStatus.confirmedItems  + x.action);
        else if (x.status == clInstancesStatus.CLI_STATUS_CONFIRMED_2) locationStatus = locationStatus.set('confirmed_2Items', locationStatus.confirmed_2Items  + x.action);
        else if (x.status == clInstancesStatus.CLI_STATUS_RESOLVED) locationStatus = locationStatus.set('resolvedItems', locationStatus.resolvedItems  + x.action);        
				else if (x.status == clInstancesStatus.CLI_STATUS_REJECTED || x.status == clInstancesStatus.CLI_STATUS_REJECTED_PLUS) {                
					locationStatus = locationStatus.set('rejectedItems', locationStatus.rejectedItems + x.action);
					if (x.status == clInstancesStatus.CLI_STATUS_REJECTED_PLUS || issueCreationIndication)
						locationStatus = locationStatus.set('issuesCounter', Boolean(locationStatus.issuesCounter) ? (locationStatus.issuesCounter + x.action) : (0 + x.action));
				}
			}
		})

		state = state.setIn(['locationsStatus', projectId, 'statusMap', checklistItemInstance.locationId], locationStatus)
	}

	return state;
}
