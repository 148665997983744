import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { Router, Route, Switch, } from "react-router-dom";
import CompanySafetyTable from '../CompanySafety/CompanySafetyTable';
import { fetchQuasiStatics } from '../../../common/quasiStatics/funcs';
import qs from 'qs';
import { setLang } from '../../../common/app/actions';
class PdfContainerView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formUniversalIdsMap: null
    };
  }


  UNSAFE_componentWillMount() {
    const { history, setLang } = this.props;
    let queryParams = qs.parse(history.getNested(['location', 'search'], {}), { ignoreQueryPrefix: true });
    let { lang, userId } = queryParams;
    if (lang)
      setLang(lang);
    this.fetchFormsData();
    this.setState({ userId });
  }


  async fetchFormsData() {
    let { forms } = await fetchQuasiStatics('forms');
    this.setState({ formUniversalIdsMap: forms });
  }

  render() {
    let { history, intl, rtl } = this.props;
    const { formUniversalIdsMap, userId } = this.state;

    return (
      <Router history={history}>
        <Switch>
          <Route
            path="/pdf/safety/company/:selectedCompanyId"
            render={props => formUniversalIdsMap ? <CompanySafetyTable history={props.history} match={props.match} selectedCompanyId={props.getNested(['match', 'params', 'selectedCompanyId'])} userId={userId} rtl={rtl} formUniversalIdsMap={formUniversalIdsMap} intl={intl} isPdfMode={true} /> : <></>}
          />
        </Switch>
      </Router>
    );
  }
}


PdfContainerView = connect(state => ({
  rtl: state.app.rtl,
  // loggedIn: state.auth.loggedIn,
  // viewer: state.users.viewer,
  // loading: state.app.loading,
  // toast: state.app.toast,
  // alert: state.app.alert,
  // storageLoaded: state.app.storageLoaded,
  // auth: state.auth,
  // users: state.users,
  // formUniversalIdsMap: state.quasiStatics.formUniversalIdsMap,
  // urlParams: state.ui.urlParams,

}),
  {
    setLang
  })(PdfContainerView);
export default PdfContainerView;