import { useEffect, useRef, useState } from "react";

/**
 * 
 * @returns {[React.MutableRefObject<any>, boolean]}
 */
const useHover = () => {
  const [isHover, setIsHover] = useState(false);
  
  const componentRef = useRef(null);

  useEffect(() => {
    if (componentRef.current) {
      componentRef.current.onmouseenter = () => setIsHover(true);
      componentRef.current.onmouseleave = () => setIsHover(false)
    }
  }, [componentRef.current]);

  return [componentRef, isHover];
}

export default useHover;