import {getSnapshotData} from '../lib/utils/utils';

export const GET_BUILDINGS = 'GET_BUILDINGS';
export const GET_LOCAL_BUILDINGS = 'GET_LOCAL_BUILDINGS';
export const END_BUILDINGS_LISTENER = 'END_BUILDINGS_LISTENER';
export const CLEAR_LOCAL_BUILDINGS = 'CLEAR_LOCAL_BUILDINGS';
export const UPDATE_BUILDING = 'UPDATE_BUILDING';
export const UPDATE_BUILDINGS = 'UPDATE_BUILDINGS';
export const UPDATE_LOCAL_BUILDING = 'UPDATE_LOCAL_BUILDING';
export const UPDATE_BUILDING_FLOORS = 'UPDATE_BUILDING_FLOORS';
export const GET_NEW_BUILDING_ID = 'GET_NEW_BUILDING_ID';

export function getBuildings(projectId, forceUpdate) { 
  return {
    type: GET_BUILDINGS,
    payload: getSnapshotData({api:'buildings'},projectId)
};
}

export function getLocalBuildings(projectId, buildings) {
  return {
    type: GET_LOCAL_BUILDINGS + "_SUCCESS",
    payload: { projectId, buildings }
  }
}


export function updateBuilding(projectId, building) {
  return ({ firebase, firebaseDatabase }) => {
    const getPromise = async () => {
      if (!building.id) {
        building.createdAt = new Date().getTime();
        building.id = firebaseDatabase().ref('buildings/' + projectId).push().key;
      }

      if (!building.createdAt)
        building.createdAt = new Date().getTime();

      let updates = {};
      updates['buildings/' + projectId + '/' + building.id] = building;
      await firebase.update(updates);
      return { projectId, building };
    };

    return {
      type: UPDATE_BUILDING,
      payload: getPromise()
    };
  };
}

export function updateBuildings(projectId, buildings, replaceAll) {
  return ({ firebase, firebaseDatabase }) => {
    const getPromise = async () => {

      if (!projectId || !buildings)
        return false;
      
      let updates = {};

      if (replaceAll) {
        let path = 'buildings/' + projectId;
        buildings.loopEach((i, building) => {
          building = building.toJS ? building.toJS() : building;

          if (!building.id){
            building.createdAt = new Date().getTime();
            building.id = firebaseDatabase().ref('buildings/' + projectId).push().key;
          }
            
          updates = updates.setNested([path, building.id], building);
        });

      } else {
        buildings.loopEach((i, building) => {
          if (!building.id) {
            building.createdAt = new Date().getTime();
            building.id = firebaseDatabase().ref('buildings/' + projectId).push().key;
          }
  
          updates['buildings/' + projectId + '/' + building.id] = building;
        });
      }


      await firebase.update(updates);
      return { projectId, buildings };
    };

    return {
      type: UPDATE_BUILDINGS,
      payload: getPromise()
    };
  };
}

export function updateBuildingEmailsList(projectId, buildingId, emailsMap, isActiveAlready) {
  return ({ firebase, platformActions, apiServer }) => {
    const getPromise = async () => {

      if (!projectId || !buildingId || !emailsMap)
        return false;
      
      let updates = {};
      updates[`templates/configurations/projects/${projectId}/specs/notifications/drawing/emails/lists/locations/${buildingId}`] = emailsMap;
      updates[`templates/configurations/projects/${projectId}/specs/notifications/drawing/emails/isActive`] = true;

      await firebase.update(updates);
      
      // TODO: This whole thing should use the updateConfigurations
      await new Promise(resolve => { 
				setTimeout(async () => {
					await platformActions.net.fetch(apiServer + `/v1/services/templates/merge?templateSubject=configurations&scope=projects&scopeId=${projectId}`);
					resolve();
				}, 5000)
			})

      return { projectId, projects: { projectId } };
    };

    return {
      type: UPDATE_BUILDING,
      payload: getPromise()
    };
  };
}

export function getNewBuildingId(projectId) {
  return ({ firebaseDatabase }) => {
      if (!projectId)
        return false;

      let key = firebaseDatabase().ref('buildings/' + projectId).push().key;

    return {
      type: GET_NEW_BUILDING_ID,
      payload: { buildingId: key }
    }
  }
}


export function updateLocalBuilding(projectId, building) {
  return {
    type: UPDATE_LOCAL_BUILDING + "_SUCCESS",
    payload: { projectId, building }
  }
}

export function removeLocalBuildings(projectId) {
  return {
    type: CLEAR_LOCAL_BUILDINGS,
    payload: { projectId }
  }
}

export function endBuildingsListener(projectId) {
  return ({ dispatch, firebaseDatabase }) => {
    firebaseDatabase().ref('buildings/' + projectId).off('value');
    return {
      type: END_BUILDINGS_LISTENER,
      payload: { projectId }
    }
  }
} 

export function updateBuildingFloors(projectId, building, minFloor, maxFloor) {
  return ({ firebase, firebaseDatabase }) => {
    const getPromise = async () => {
      let updates = {};
      updates['buildings/' + projectId + '/' + building.id + '/minFloor'] = minFloor;
      updates['buildings/' + projectId + '/' + building.id + '/maxFloor'] = maxFloor;
      await firebase.update(updates)

      return { projectId, building };
    };

    return {
      type: UPDATE_BUILDING_FLOORS,
      payload: getPromise()
    };
  };
}
