import React from "react";
import { connect  } from 'react-redux';
import { compose } from 'recompose';
import { injectIntl } from "react-intl";
import { removeEmpty, uploadFileToStorage } from '../../../common/lib/utils/utils';

import { getNewFormId } from '../../../common/configurations/actions';
import { getQuasiStatics } from '../../../common/quasiStatics/actions';

import { withRouter } from 'react-router-dom'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import SplitViewPage from "../../layouts/SplitViewPage";
import MultiCheckSelect from "../../components/CementoComponents/MultiCheckSelect";

// funcs
import { updateForms } from "../../../common/configurations/funcs";

// actions
import theme from "../../assets/css/theme";
import trash from '../../assets/img/icons/trash.png';
import { startToast } from "../../../common/app/actions";
import { upsertForm } from '../../../common/forms/actions';
import { exportFormPDF } from "../../../common/pdf/actions";

import loginPageStyle from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import { GridContainer, GridItem, Card, CardHeader, PictureUpload } from "../../components";

import Modal from '../../components/CementoComponents/Modal';
import ImageCarousel from '../../components/CementoComponents/ImageCarousel';
import InputField from "../../components/CementoComponents/InputField";
import Text from "../../components/CementoComponents/Text";
import systemMessages from '../../../common/app/systemMessages'; 
import _ from 'lodash';
import { LANGUAGES_MAP } from '../../../common/propertiesTypes/propertiesTypes'

import CollapsibleSection_DEPRECATED from "../../components/CementoComponents/CollapsibleSection_DEPRECATED";
import { ProjectContext } from "../../../common/projects/contexts";
import { connectContext } from "react-connect-context";
import { companiesGroupsOptions } from "../Projects/ProjectManager";
import StandardInput from "../../components/CementoComponents/StandardInput";
import usersMessages from '../../../common/users/usersMessages';



const convertStringToObjectValues = (form, prop) => {
  let values = _.get(form, [prop], {});
  if (values && typeof values === 'string') {
    const array = values.split(/[ ,]+/) || [];

    let valuesMap = {};
    for (let item of array) {
      valuesMap[item.replaceAll('.', '_')] = item;
    }
    return valuesMap;
  }

  return values;
}

class FormManager extends React.Component {
  constructor(props) {
    super(props);
    this.saveChanges = this.saveChanges.bind(this);
    
    this.recalcHeader     = this.recalcHeader.bind(this)
    this.setComponentData = this.setComponentData.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormUpdate = this.handleFormUpdate.bind(this);
    this.createNewForm    = this.createNewForm.bind(this);
    this.deleteForm       = this.deleteForm.bind(this);
    this.clearChecklists  = this.clearChecklists.bind(this);
    

    this.updateSelectedChecklistArray = this.updateSelectedChecklistArray.bind(this);

    this.state = {
    }
  }

  componentWillUnmount() {
    
  }

  async componentWillMount() {
    const { getQuasiStatics } = this.props;

    this.recalcHeader();
    this.setComponentData({}, this.props);
    getQuasiStatics(0);
  }

  componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps)
  }

  recalcHeader() {
    const { setHeaderParams } = this.props
    if (setHeaderParams)
      setHeaderParams({ headerComponent: null, sideBarParams: null });
  }
  
  setComponentData(props, nextProps) {    
    let newStateChanges = {};

    
    if (newStateChanges.projectCompaniesMap)
      newStateChanges.projectCompaniesArray = Object.values(newStateChanges.projectCompaniesMap);

    if (_.keys(newStateChanges).length > 0)
      this.setState(newStateChanges);
  }
 
  handleFormChange(selectedFormId) {
    const { configurations } = this.props;
    let selectedForm = configurations.getNested(['forms', selectedFormId], {});
    if (_.get(selectedForm, ['docxUri']))
      _.set(selectedForm, ['docx'], {scopes: _.get(selectedForm, ['scopes'])});
    this.setState({selectedForm});
  }

  async handleFormPreview() {
    const { project, viewer, upsertForm, exportFormPDF } = this.props;
    const { selectedForm } = this.state;
    const formTemplateID = _.get(selectedForm, ['id']);
    const selectedLocation = _.get(selectedForm, ['previewForm', 'selectedLocation', 'fullLocation']);
    const newFormInstance = {
      createdAt: Date.now(),
      isDocx: true,
      location: selectedLocation,
      formTemplateId: formTemplateID,
      type: 'temp'
    };
    const upsertFormRes = await upsertForm(_.get(project, ['id']), viewer, newFormInstance, 'temp');
    const exportFormPdfArgs = {
      project,
      formId: _.get(upsertFormRes, ['reportId']),
      formType: 'temp',
      displayLoading: true,
      isListenerMode: true
    };
    const pdfRes = await exportFormPDF(exportFormPdfArgs);
    this.handleFormUpdate(['previewForm', 'previewURI'], _.get(pdfRes, ['uri']));
    this.setState({showFormPreview: true})
  }

  closeFormPreview() {
    this.setState({showFormPreview: false});
  }

  clearChecklists() {
    this.handleFormUpdate([''], null)
  }

  async handleFormUpdate(path, val) {
    const { selectedForm } = this.state;
    let newSelectedForm = selectedForm.setNested(path, val);
    
    newSelectedForm = removeEmpty(newSelectedForm, 'handleFormUpdate');

    this.setState({selectedForm: newSelectedForm});
  }

  createNewForm() {
    const { getNewFormId } = this.props;
    let newSelectedForm = {};
    newSelectedForm.id = getNewFormId().payload;
    this.setState({selectedForm: newSelectedForm});
  }

  async deleteForm() {
    const { startToast, intl, selectedProjectId } = this.props;
    const { selectedForm } = this.state;
    let newStateChanges = {};   

    if (!selectedForm || !selectedForm.id)
      return;

    const didDeleteSucceeded = await updateForms(selectedProjectId, selectedForm.id, {id: selectedForm.id, isDeleted: true});

    startToast({
      overlay: false,
      mandatory: true,
      title: didDeleteSucceeded ? systemMessages.objectDeletedSuccessfully : systemMessages.errorOnSave,
      values: { objectName: intl.formatMessage(systemMessages.form) },
      actions: [ { message: systemMessages.ok , color: didDeleteSucceeded ? 'success' : 'danger' } ],
      type: didDeleteSucceeded ? "success" : "error"
    }); 

    if (_.keys(newStateChanges).length > 0)
      this.setState(newStateChanges);
  }

  async saveChanges() {
    const { startToast, intl, selectedProjectId } = this.props;
    let { selectedForm } = this.state;
    let newStateChanges = {};   

    if (!selectedForm || !selectedForm.id) {
      return;
    }

    selectedForm.targetEmails = convertStringToObjectValues(selectedForm, 'targetEmails');
    selectedForm.universalIds = convertStringToObjectValues(selectedForm, 'universalIds');

    if (_.get(selectedForm, ['docx'])) {
      const localFilePath = _.get(selectedForm, ['docx', 'file', 'uri']);
      if (localFilePath) {
        const pathInStorage = `docx/templates/projects/${selectedProjectId}`;
        selectedForm.docxUri = await uploadFileToStorage(pathInStorage, localFilePath, selectedForm.getCementoTitle());
      }
      const scopesInTemplate = _.get(selectedForm, ['docx', 'scopes']);
      selectedForm.scopes = _.mapValues(scopesInTemplate, () => {return true;});
      delete selectedForm.docx;
    }

    if (_.get(selectedForm, ['useLogo'])) {
      const localImagePath = _.get(selectedForm, ['logoFile', 'uri']);
      const pathInStorage = `formsInfo/${selectedProjectId}/${_.get(selectedForm, ['id'])}`;
      selectedForm.logo = await uploadFileToStorage(pathInStorage, localImagePath, `${_.get(selectedForm, ['title', 'he'])}_logo`);
    }
    delete selectedForm.logoFile;
    delete selectedForm.useLogo;
    
    const didSaveSucceeded = await updateForms(selectedProjectId, selectedForm.id, selectedForm);
    
    startToast({
      overlay: false,
      mandatory: true,
      title: didSaveSucceeded ? systemMessages.objectSavedSuccessfully : systemMessages.errorOnSave,
      values: { objectName: intl.formatMessage(systemMessages.form) },
      actions: [ { message: systemMessages.ok , color: didSaveSucceeded ? 'success' : 'danger' } ],
      type: didSaveSucceeded ? "success" : "error"
    }); 

    if (_.keys(newStateChanges).length > 0)
      this.setState(newStateChanges);
  }

  updateSelectedChecklistArray(allItems, path) {
    let checklistItems = {};
    (allItems || []).filter(x => x.checked).forEach(x => (checklistItems[x.id] = true))

    if (!_.keys(checklistItems).length)
      checklistItems = null;

    this.handleFormUpdate(path, checklistItems);
  }

  render() {
    const { rtl, configurations, checklists, getNewFormId, lang, formUniversalIdsMap, stages, titles: _titles, trades: _trades, intl } = this.props;
    const titles = _titles && _titles.toJS ? _titles.toJS() : _titles;
    const trades = _trades && _trades.toJS ? _trades.toJS() : _trades;

    const { projectToManage, selectedForm, showFormPreview } = this.state;

    let targetEmails = _.get(selectedForm, ['targetEmails'], {});
    if (_.isObject(targetEmails))
      targetEmails = _.values(targetEmails).join(' , ');

    let universalIds = _.get(selectedForm, ['universalIds'], {});
    if (_.isObject(universalIds))
      universalIds = _.values(universalIds).join(' , ');

    let btnStyle = { borderRadius: '5px', display: 'flex', padding: '0px 5px', minWidth: 75, height: 30, alignSelf: 'center', justifyContent: 'center', alignItems: 'center', margin: 5, border: '1px solid ' + theme.brandPrimary + '85', color: theme.brandPrimary, cursor: 'pointer' };
    const containerWidth = 400;

    let forms = Object.values(configurations.getNested(['forms'], {}));
    let currForm = selectedForm && selectedForm.id ? { [selectedForm['id']]: selectedForm.getNested(['title', lang], "") } : null;

    let checklistsArray = [];
    if (selectedForm)
      Object.values(checklists || {}).forEach(checklist => {
        let targetType = (selectedForm.type == "safety") ? "routine" : null;

        if (targetType != checklist.type)
          return;
        
        const stageTitle = _.get(stages, [checklist.stageId, 'title', lang]);
        const label = stageTitle 
          ? stageTitle + ' / ' + checklist.description
          : checklist.description;

        checklistsArray.push({ id: checklist.id, label , checked: selectedForm.getNested(['checklists', 'preDefined', checklist.id], false) });
      });

    let exportableChecklistsArray = [];

    if (selectedForm)
      Object.values(checklists || {}).forEach(checklist => {
        let targetType = (selectedForm.type == "safety") ? "routine" : null;

        if (targetType != checklist.type)
          return;

        exportableChecklistsArray.push({ id: checklist.id, label: (_.get(stages, [checklist.stageId, 'title', 'he'], _.get(stages, [checklist.stageId, 'title', 'en'], "")) + " / ") + checklist.description, checked: selectedForm.getNested(['exportableChecklists', checklist.id], false) });
      });

    let langs = [];
    let langsNames = [];

    Object.values(LANGUAGES_MAP).forEach(lang => {
      langs.push(lang.code);
      langsNames.push(lang.name);
    });

    const inputFieldStyle = { width: 300, marginRight: theme.verticalMargin };
    const inputFieldWrapperStyle = { display: 'flex', flexDirection: 'row', alignItems: 'center', width: 300 };

    const separator = (<div style={{ backgroundColor: theme.separatorColor, width: '100%', height: 0.5, marginTop: theme.paddingSize, paddingRight: theme.paddingSize, paddingLeft: theme.paddingSize }} />);
    const shortSeparator = (<div style={{ backgroundColor: theme.separatorColor, width: '50%', height: 1, marginTop: theme.paddingSize, paddingRight: theme.paddingSize, paddingLeft: theme.paddingSize }} />);

    let kindOfPermissionsArray = [
      { subject: 'groups', values: _.values(companiesGroupsOptions).map(g => ({ id: g.value, title: g.label })) },
      { subject: 'trades', values: _.values(trades).map(trade => ({ id: trade.id, title: trades.getNested([String(trade.id), 'getTitle']) })) },
      { subject: 'title', values: _.values(titles).map(title => ({ id: title.id, title: titles.getNested([String(title.id), 'getTitle']) })) },
    ];
    return (
      <SplitViewPage
        rtl={rtl}
        Main={
          <div style={{ display: 'flex', flex: 1, minHeight: '100%', flexDirection: 'column', justifyContent: 'space-between', padding: theme.paddingSize }}>
            <GridContainer style={{ paddingBottom: 3 * theme.paddingSize, alignSelf: 'stretch', width: '100%' }}>
              <GridItem xs={8}>
                <GridContainer style={{ padding: theme.paddingSize }}>
                  <GridItem xs={12}>
                    <InputField
                      key={'_formManager'}
                      name={systemMessages.manage.formsManager}
                      type={'SelectionList'}
                      values={forms}
                      //defaultValue={selectedForm && selectedForm.formId ? {[selectedForm['id']]:selectedForm.title[lang]} : null}
                      value={currForm}
                      onChange={val => this.handleFormChange(Object.keys(val)[0])} />
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Text style={{ cursor: 'pointer' }} onClick={this.createNewForm}>צור דוח חדש</Text>
                      <Text style={{ cursor: 'pointer' }} onClick={this.deleteForm}>מחק דוח נוכחי</Text>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>

              {Boolean(currForm) &&
                <>
                  <GridItem xs={12}>
                    <CollapsibleSection_DEPRECATED title={"כללי"}>
                      <GridContainer style={{ padding: theme.paddingSize, width: '100%' }}>
                      <Text style={{ fontWeight: 'bold', marginBottom: theme.verticalMargin }}>שם הטופס</Text>
                      {langs.map((currLang, i) => (
                        <GridItem xs={12} key={'formTitle' + currLang}>
                          <div style={inputFieldWrapperStyle}>
                            <Text>{langsNames[i]}</Text>
                            <InputField type={'String'} key={'formTitle' + currLang} value={selectedForm.getNested(['title', currLang])} onChange={str => this.handleFormUpdate(['title', currLang], str)} />
                          </div>
                        </GridItem>
                      ))}

                      {separator}
                      <InputField
                        key={'_formManager'}
                        name={systemMessages.manage.formsManager}
                        type={'SelectionList'}
                        values={Object.values(formUniversalIdsMap.toJS() || {})}
                        defaultValue={{ [_.head(_.keys(selectedForm.getNested(['universalIds'])))]: _.head(_.keys(selectedForm.getNested(['universalIds']))) }}
                        onChange={val => this.handleFormUpdate(['universalIds'], val)}
                      />

                      <GridItem xs={12} style={{ paddingTop: theme.paddingSize }}>
                        <Text style={{ fontWeight: 'bold', marginBottom: theme.verticalMargin }}>הגדרות בטיחות</Text>
                        <InputField type={'Boolean'} key={'hasProperties'} name={'Does the form have properties?'} value={Boolean(selectedForm.hasProperties)} onChange={async (b) => { await this.handleFormUpdate(['hasProperties'], b); }} />
                        <InputField type={'Boolean'} key={'safety'} name={'האם טופס בטיחות?'} value={Boolean(selectedForm.type == "safety")} onChange={async (b) => { await this.handleFormUpdate(['type'], b ? "safety" : null); this.updateSelectedChecklistArray(null, ['exportableChecklists']); }} />
                        <InputField type={'Boolean'} key={'unitApproval'} name={'האם טופס מסירת דירה?'} value={Boolean(selectedForm.tradeId == "1119")} onChange={async (b) => { await this.handleFormUpdate(['tradeId'], b ? "1119" : null); }} />
                        {Boolean(selectedForm.type == "safety") &&
                          <>
                          <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                            <Text>הצג ציון</Text>
                            <InputField type={'Boolean'} key={'checklist-showGrade'} value={Boolean(selectedForm.getNested(['settings', 'showSafetyGrade']))} onChange={b => this.handleFormUpdate(['settings', 'showSafetyGrade'], b)} />
                          </div>


                          <Text style={{ fontWeight: 'bold', paddingTop: theme.paddingSize }}>סטטיסטיקות</Text>
                          <InputField type={'Boolean'} key={'safety-stats-employeesExpirations'} name={'כשירות עובדים'} value={Boolean(selectedForm.getNested(['dashboards', 'employeesExpirations', 'visible']))} onChange={b => this.handleFormUpdate(['dashboards', 'employeesExpirations', 'visible'], b)} />
                          <InputField type={'Boolean'} key={'safety-stats-equipmentExpirations'} name={'כשירות ציוד'} value={Boolean(selectedForm.getNested(['dashboards', 'equipmentExpirations', 'visible']))} onChange={b => this.handleFormUpdate(['dashboards', 'equipmentExpirations', 'visible'], b)} />
                          <InputField type={'Boolean'} key={'safety-stats-safetyPostsStats'} name={'סקירת ליקויי בטיחות'} value={Boolean(selectedForm.getNested(['dashboards', 'safetyPostsStats', 'visible']))} onChange={b => this.handleFormUpdate(['dashboards', 'safetyPostsStats', 'visible'], b)} />

                            <Text style={{ fontWeight: 'bold', paddingTop: theme.paddingSize }}>משימות</Text>
                            <InputField type={'Boolean'} key={'posts-openedFromReport'} name={'משימות שנפתחו בדוח'} value={Boolean(selectedForm.getNested(['posts', 'openedFromReport']))} onChange={b => this.handleFormUpdate(['posts', 'openedFromReport'], b)} />
                            <InputField type={'Boolean'} key={'posts-userSelectedIssues'} name={'משימות שנבחרו ידנית'} value={Boolean(selectedForm.getNested(['posts', 'userSelectedIssues']))} onChange={b => this.handleFormUpdate(['posts', 'userSelectedIssues'], b)} />
                          </>

                        }
                      </GridItem>
                      {separator}
                      <GridItem xs={12} style={{ paddingTop: theme.paddingSize }}>
                        <Text style={{ fontWeight: 'bold', paddingTop: theme.paddingSize }}>פרטי דו"ח</Text>
                        <InputField type={'Boolean'} key={'posts-reportDetails'} name={'הצג פרטי יצירת הדו"ח'} value={Boolean(selectedForm.getNested(['settings', 'showCreationDetails']))} onChange={b => this.handleFormUpdate(['settings', 'showCreationDetails'], b)} />
                        <InputField type={'Boolean'} key={'posts-settings-expandSinglePost'} name={'הצג פוסט בודד בצורה מפורטת ב- PDF'} value={Boolean(selectedForm.getNested(['settings', 'expandSinglePost']))} onChange={b => this.handleFormUpdate(['settings', 'expandSinglePost'], b)} />
                        <Text style={{ fontWeight: 'bold' }}>{`הרשאת טופס קריאה`}</Text>
                        {kindOfPermissionsArray.map(({ subject, values }) =>  { return ( 
                        <>
                        <div style={{ flexDirection: 'row', display: 'flex', width: containerWidth}}>
                        <Text>{subject}</Text>
                        <InputField type={'Boolean'}
                          style={{ flex: 0 }}
                          key={`enable_${subject}_permissions`}
                          value={Boolean(selectedForm.getNested(['permissions', 'read', subject], false))}
                          onChange={b => this.handleFormUpdate(['permissions', 'read', subject], b ? [] : null)}
                        />
                        <InputField
                          isMulti
                          key={`${subject}_permissions_list`}
                          type={'SelectionList'}
                          values={values}
                          defaultValue={_.mapKeys(_.get(selectedForm, ['permissions', 'read', subject]))}
                          value={currForm}
                          onChange={val => this.handleFormUpdate(['permissions', 'read', subject], _.keys(val))}
                        />
                      </div>
                      </>)})}
                      <Text style={{ fontWeight: 'bold' }}>{`הרשאת טופס יצירה`}</Text>
                      {kindOfPermissionsArray.map(({ subject, values}) => { return (
                        <>
                        <div style={{ flexDirection: 'row', display: 'flex', width: containerWidth }}>
                          <Text>{subject}</Text>
                          <InputField type={'Boolean'}
                            style={{ flex: 0 }}
                            key={`enable_${subject}_permissions`}
                            value={Boolean(selectedForm.getNested(['permissions', 'create', subject], false))}
                            onChange={b => this.handleFormUpdate(['permissions', 'create', subject], b ? [] : null)}
                          />
                          <InputField
                            isMulti
                            key={`${subject}_permissions_list`}
                            type={'SelectionList'}
                            values={values}
                            defaultValue={_.mapKeys(_.get(selectedForm, ['permissions', 'create', subject]))}
                            value={currForm}
                            onChange={val => this.handleFormUpdate(['permissions', 'create', subject], _.keys(val))}
                          />
                        </div>
                        </>
                      )})}
                      </GridItem>
                      {separator}
                      <GridItem xs={12} style={{ paddingTop: theme.paddingSize }}>
                        <Text style={{ fontWeight: 'bold', marginBottom: theme.verticalMargin }}>הגדרות מובייל</Text>
                        <div style={inputFieldWrapperStyle}>
                          <Text>{'צבע כפתור הדו"ח'}</Text>
                          <InputField style={inputFieldStyle} type={'String'} key={'mobileIconProps' + 'buttonColor'} value={selectedForm.getNested(['mobileIconProps', 'buttonColor'])} onChange={str => this.handleFormUpdate(['mobileIconProps', 'buttonColor'], str)} />
                        </div>
                        <div style={inputFieldWrapperStyle}>
                          <Text>{'סוג האייקון'}</Text>
                          <InputField style={inputFieldStyle} type={'String'} key={'mobileIconProps' + 'iconName'} value={selectedForm.getNested(['mobileIconProps', 'iconName'])} onChange={str => this.handleFormUpdate(['mobileIconProps', 'iconName'], str)} />
                        </div>
                        <a href="https://oblador.github.io/react-native-vector-icons/" target="_blank">Icons can be found here - under Ionicons</a>
                      </GridItem>

                      {separator}
                      <GridItem xs={12} style={{ paddingTop: theme.paddingSize }}>
                        <Text style={{ fontWeight: 'bold', marginBottom: theme.verticalMargin }}>חתימות</Text>

                        {Object.values(selectedForm.getNested(['signatures'], {})).filter(sig => Boolean(sig)).map(sig =>
                          <div key={'main-' + sig.id} style={{ marginBottom: theme.margin }}>
                            <div style={{ flexDirection: 'row', display: 'flex' }}>

                              <Text style={{ fontWeight: 'bold' }}>{sig.getNested(['title', lang], "[ללא כותרת]")} </Text>
                              <div style={{ cursor: 'pointer', marginLeft: 5, marginRight: theme.verticalMargin }} onClick={() => this.handleFormUpdate(['signatures', sig.id], null)}><img style={{ width: 13, height: 14 }} src={trash} alt="מחק חתימה" /></div>
                            </div>
                            <div style={inputFieldWrapperStyle}>
                              <Text>{'מספר'}</Text>
                              <InputField style={inputFieldStyle} type={'Number'} key={sig.id + 'signatures' + 'title' + 'ordinalNo'} value={selectedForm.getNested(['signatures', sig.id, 'ordinalNo'])} onChange={num => this.handleFormUpdate(['signatures', sig.id, 'ordinalNo'], num)} />
                            </div>

                            <div style={inputFieldWrapperStyle}>
                              <InputField
                                key={'sigType'}
                                name={'סוג חתימה'}
                                type={'SelectionList'}
                                values={[{ id: null, title: 'None' }, { id: 'viewer', title: 'viewer' }, { id: 'custom', title: 'custom' }]}
                                defaultValue={{ [sig.type]: sig.type }}
                                onChange={val => this.handleFormUpdate(['signatures', sig.id, 'type'], Object.keys(val || {}).length ? Object.keys(val || {})[0] : null)} />
                            </div>
                            <Text style={{ fontWeight: 'bold' }}>כותרת החתימה</Text>
                            {langs.map((currLang, i) => (
                              <div style={inputFieldWrapperStyle} key={sig.id + 'signatures' + 'title' + currLang}>
                                <Text>{langsNames[i]}</Text>
                                <InputField style={inputFieldStyle} multiline type={'String'} key={sig.id + 'signatures' + 'title' + currLang} value={selectedForm.getNested(['signatures', sig.id, 'title', currLang])} onChange={str => this.handleFormUpdate(['signatures', sig.id, 'title', currLang], str)} />
                              </div>
                            ))}

                            <Text style={{ fontWeight: 'bold' }}>טקסט לחתימה</Text>
                            {langs.map((currLang, i) => (
                              <div style={{...inputFieldWrapperStyle, width: '100%'}} key={sig.id + 'signatures' + 'text' + currLang}>
                                <Text>{langsNames[i]}</Text>
                                <InputField style={{...inputFieldStyle, width: '100%'}} type={'String'} key={sig.id + 'signatures' + 'text' + currLang} multiline={true} value={selectedForm.getNested(['signatures', sig.id, 'multiLangText', 'content', currLang])} onChange={str => this.handleFormUpdate(['signatures', sig.id, 'multiLangText', 'content', currLang], str)} />
                              </div>
                            ))}

                            {langs.map((currLang, i) => (
                              <div style={{...inputFieldWrapperStyle, width: '100%'}} key={sig.id + 'signatures' + 'text' + currLang}>
                                <Text>{langsNames[i]}</Text>
                                <InputField 
                                  name={i === 0 ? 'Before save alert title' : ''}
                                  key={sig.id + 'signatures' + 'alert_title' + currLang} 
                                  style={{...inputFieldStyle, width: '100%'}} 
                                  type={'String'} 
                                  value={selectedForm.getNested(['signatures', sig.id, 'alert', 'title', currLang])} 
                                  onChange={str => this.handleFormUpdate(['signatures', sig.id, 'alert', 'title', currLang], str)} 
                                />
                              </div>
                            ))}
                            {langs.map((currLang, i) => (
                              <div style={{...inputFieldWrapperStyle, width: '100%'}} key={sig.id + 'signatures' + 'text' + currLang}>
                                <Text>{langsNames[i]}</Text>
                                <InputField 
                                  key={sig.id + 'signatures' + 'alert_content' + currLang} 
                                  name={i === 0 ? 'Before save alert message' : ''}
                                  style={{...inputFieldStyle, width: '100%'}} 
                                  type={'String'} 
                                  value={selectedForm.getNested(['signatures', sig.id, 'alert', 'content', currLang])} 
                                  onChange={str => this.handleFormUpdate(['signatures', sig.id, 'alert', 'content', currLang], str)} 
                                />
                              </div>
                            ))}
                            {langs.map((currLang, i) => (
                              <div style={{...inputFieldWrapperStyle, width: '100%'}} key={sig.id + 'signatures' + 'text' + currLang}>
                                <Text>{langsNames[i]}</Text>
                                <InputField 
                                  key={sig.id + 'signatures' + 'alert_confirm' + currLang} 
                                  name={i === 0 ? 'Before save alert confirm button' : ''}
                                  style={{...inputFieldStyle, width: '100%'}} 
                                  type={'String'} 
                                  value={selectedForm.getNested(['signatures', sig.id, 'alert', 'confirm', currLang])} 
                                  onChange={str => this.handleFormUpdate(['signatures', sig.id, 'alert', 'confirm', currLang], str)} 
                                />
                              </div>
                            ))}

                            <InputField
                              type={'String'}
                              key={`${sig.id}_universalId_sig`}
                              name={'הוסף universalId לחתימה'}
                              value={_.get(selectedForm, ['signatures', sig.id, 'universalId'], '')}
                              onChange={val => this.handleFormUpdate(['signatures', sig.id, "universalId"], val)}
                            />


                            <div style={inputFieldWrapperStyle}>
                              <InputField type={'Boolean'} key={'signaturesIsMandatory'} name={'חייב בחתימה'} value={selectedForm.getNested(['signatures', sig.id, 'isMandatory'], false)} onChange={val => this.handleFormUpdate(['signatures', sig.id, 'isMandatory'], val)} />
                            </div>

                            {kindOfPermissionsArray.map(({ subject, values }) => <>
                                <Text style={{ fontWeight: 'bold' }}>{`הרשאת חתימה - ${subject}`}</Text>
                                <div style={{ flexDirection: 'row', display: 'flex', width: containerWidth }}>
                                  <InputField type={'Boolean'}
                                    style={{ flex: 0 }}
                                    key={`enable_${subject}_permissions`}
                                    value={Boolean(sig.getNested(['permissions', 'write', subject], false))}
                                    onChange={b => this.handleFormUpdate(['signatures', sig.id, 'permissions', 'write', subject], b ? [] : null)}
                                  />
                                  <InputField
                                    isMulti
                                    key={`${subject}_permissions_list`}
                                    type={'SelectionList'}
                                    values={values}
                                    defaultValue={_.mapKeys(_.get(sig, ['permissions', 'write', subject]))}
                                    value={currForm}
                                    onChange={val => this.handleFormUpdate(['signatures', sig.id, 'permissions', 'write', subject], _.keys(val))}
                                  />
                                </div>
                              </>)
                            }

                            <InputField
                              key={`shouldGetNextSignatureMember-${sig.id}`}
                              name={'Should get next signature member?'}
                              type={'Boolean'}
                              defaultValue={false}
                              onChange={val => this.handleFormUpdate(['signatures', sig.id, 'nextSignatureMember', 'enabled'], val)}
                            />
                            
                             {Boolean(sig.nextSignatureMember?.enabled) && (
                              <div style={{ display: 'flex', flexDirection: 'column', borderTop: `1px solid ${theme.brandPrimary}`, borderBottom: `1px solid ${theme.brandPrimary}` }}>
                                <Text>{`Get next member caption (if no caption entered, will default to "${intl.formatMessage(usersMessages.memberToNotify)}"): `}</Text>
                                {Object.values(LANGUAGES_MAP).map(({ code, name }) => {
                                  return (
                                    <InputField 
                                      key={`getNextSigMemberInput-${sig.id}-${code}`}
                                      name={name}
                                      type={'String'}
                                      onChange={val => this.handleFormUpdate(['signatures', sig.id, 'nextSignatureMember', 'caption', code], val)}
                                      value={sig.nextSignatureMember.caption?.[code] || ''}
                                    />
                                  )
                                })}
                              </div>
                            )}

                            {Boolean(sig.nextSignatureMember?.enabled) && (
                              <div style={{ display: 'flex', flexDirection: 'column', borderTop: `1px solid ${theme.brandPrimary}`, borderBottom: `1px solid ${theme.brandPrimary}` }}>
                                <Text>{`Get next member caption (if no caption entered, will default to "${intl.formatMessage(usersMessages.memberToNotify)}"): `}</Text>
                                {Object.values(langsMap).map(({ code, name }) => {
                                  return (
                                    <InputField 
                                      key={`getNextSigMemberInput-${sig.id}-${code}`}
                                      name={name}
                                      type={'String'}
                                      onChange={val => this.handleFormUpdate(['signatures', sig.id, 'nextSignatureMember', 'caption', code], val)}
                                      value={sig.nextSignatureMember.caption?.[code] || ''}
                                    />
                                  )
                                })}
                              </div>
                            )}
                            <div style={{ borderTop: `1px solid ${theme.brandPrimary}`, borderBottom: `1px solid ${theme.brandPrimary}` }}>
                              <Text style={{ fontWeight: 'bold' }}>מאפייני החתימה</Text>
                              {Object.values(sig.components || {}).filter(Boolean).map((currComp, i) => (
                                <div key={'some sig' + sig.id + currComp.id} style={{ borderBottom: `1px solid ${theme.brandPrimary}` }}>
                                  <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    <Text>שדה {i + 1} - ({Boolean(currComp.type == "avatar") ? "תמונה" : "טקסט"})</Text>
                                    <div style={{ cursor: 'pointer', marginLeft: 5, marginRight: theme.verticalMargin }} onClick={() => this.handleFormUpdate(['signatures', sig.id, 'components', currComp.id], null)}><img style={{ width: 13, height: 14 }} src={trash} alt="מחק שדה מהחתימה" /></div>
                                  </div>
                                  <InputField 
                                    name={'Input placement'}
                                    type={'SelectionList'}
                                    values={[{  title: 'Above', id: 'above' }, sig.type !== 'viewer' && { title: 'Embeded', id: 'embeded' }].filter(Boolean)}
                                    value={{ [currComp.placement || 'embeded']: currComp.placement || 'embeded' }}
                                    onChange={val => {
                                      const placement = _.keys(val)[0];
                                      let newComponent = Object.assign({}, currComp, { placement });
                                      if (placement === 'embeded' && currComp.title)
                                        newComponent.title = null;
                                      this.handleFormUpdate(['signatures', sig.id, 'components', currComp.id], newComponent);
                                    }}
                                    isClearable={false}
                                  />
                                  <>
                                    {Boolean(currComp.type == "text") &&
                                      [currComp.placement === 'above' && {name: 'Input title', type: 'title'}, {name: 'Input placeholder', type: 'placeholder'}].filter(Boolean).map(inputParams => (
                                        <React.Fragment key={sig.id + currComp.id + 'signatures' + inputParams.type}>
                                          <Text>{inputParams.name}</Text>
                                          {langs.map((currLang, i) => (
                                            <div style={inputFieldWrapperStyle} key={sig.id + currComp.id + 'signatures' + inputParams.type + currLang}>
                                              <Text>{langsNames[i]}</Text>
                                              <InputField
                                                key={sig.id + currComp.id + 'signatures' + inputParams.type + currLang + inputParams.type + 'input'}
                                                style={inputFieldStyle} 
                                                type={'String'} 
                                                value={selectedForm.getNested(['signatures', sig.id, 'components', currComp.id, inputParams.type, currLang])} 
                                                onChange={str => this.handleFormUpdate(['signatures', sig.id, 'components', currComp.id, inputParams.type, currLang], str)} 
                                              />
                                            </div>
                                          ))}
                                        </React.Fragment>
                                      ))
                                    }

                                    <div style={inputFieldWrapperStyle} >
                                      <Text>{'הוסף universalId'}</Text>
                                      <InputField
                                        type={'String'}
                                        style={inputFieldStyle}
                                        value={_.get(selectedForm, ['signatures', sig.id, 'components', currComp.id, 'universalId'], '')}
                                        onChange={val => this.handleFormUpdate(['signatures', sig.id, 'components', currComp.id, 'universalId'], val)} />
                                    </div>

                                  </>
                                </div>
                              ))}

                              <Text style={{ cursor: 'pointer' }} onClick={() => {
                                let newKey = getNewFormId().payload;
                                this.handleFormUpdate(['signatures', sig.id, 'components', newKey], Object.assign({ id: newKey, type: 'text' }, sig.type === 'viewer' && { placement: 'above' }));
                              }}>הוסף שדה הזנת טקסט לחותם</Text>

                              <Text style={{ cursor: 'pointer' }} onClick={() => {
                                let newKey = getNewFormId().payload;
                                  this.handleFormUpdate(['signatures', sig.id, 'components', newKey], { id: newKey, type: 'avatar' });
                                }}>הוסף הזנת תמונה לחותם
                              </Text>
                            </div>

                            {shortSeparator}
                          </div>
                        )}

                        <InputField
                          type={'String'}
                          key={'targetEmails'}
                          name={'רשימת תפוצה'}
                          value={targetEmails}
                          onChange={val => this.handleFormUpdate(['targetEmails'], val)}
                        />
                        <InputField
                          type={'String'}
                          name={'הוסף universalId חדש'}
                          value={universalIds}
                          onChange={val => this.handleFormUpdate(['universalIds'], val)}
                        />

                        {shortSeparator}

                        <Text style={{ cursor: 'pointer' }} onClick={() => {
                          let newKey = getNewFormId().payload;
                          this.handleFormUpdate(['signatures', newKey, 'id'], newKey);
                        }}>הוסף חתימה חדשה</Text>
                      </GridItem>


                    </GridContainer>
                  </CollapsibleSection_DEPRECATED>
                </GridItem>

                <GridItem xs={12}>

                  <CollapsibleSection_DEPRECATED title={"הגדרות אפליקציה"}>
                    {Boolean(selectedForm.type == "safety") &&
                      <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                        <Text>הגדר צ'קליסטים למצב תצוגה פתוח כברירת מחדל (Expand)</Text>
                        <InputField type={'Boolean'} key={'checklist-expandAll'} value={Boolean(selectedForm.getNested(['settings', 'expandAll'], true))} onChange={b => this.handleFormUpdate(['settings', 'expandAll'], b)} />
                      </div>
                    }

                    {Boolean(selectedForm.type != "safety") &&
                      <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                        <Text>אפשר ייצוא מכל צ'קליסט:</Text>
                        <InputField type={'Boolean'} key={'checklist-exportableFromEveryChecklists'} value={Boolean(selectedForm.getNested(['exportableFromEveryChecklists']))} onChange={b => this.handleFormUpdate(['exportableFromEveryChecklists'], b)} />
                      </div>
                    }

                  </CollapsibleSection_DEPRECATED>

                  <CollapsibleSection_DEPRECATED title={"הגדרות PDF"}>
                    <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                      <Text>האם להגדיר לוגו?</Text>
                      <StandardInput type={'Boolean'} key={'useLogo'} value={Boolean(_.get(selectedForm, ['useLogo']))} onChange={(value) => { this.handleFormUpdate(['useLogo'], value);}} />
                    </div>
                    {Boolean(_.get(selectedForm, ['useLogo'])) &&
                      <>
                        <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                          <StandardInput 
                            type={'FilesDropzone'}
                            key={'logoFilePath'}
                            accept={'.jpeg'}
                            value={_.get(selectedForm, ['logoFile'])}
                            onChange={(file) => {this.handleFormUpdate(['logoFile'], file)}}
                          />
                        </div>
                      </>
                    }
                    <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                      <Text>הצג לוגו חברה בתחתי הטופס</Text>
                      <InputField type={'Boolean'} key={'form-showScopeLogoOnFooter'} value={Boolean(selectedForm.getNested(['settings', 'showScopeLogoOnFooter']))} onChange={b => this.handleFormUpdate(['settings', 'showScopeLogoOnFooter'], b)} />
                    </div>

                    <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                      <Text>הצג תאריך יעד של משימה</Text>
                      <InputField type={'Boolean'} key={'post-displayPostDueDate'} value={Boolean(selectedForm.getNested(['settings', 'displayPostDueDate']))} onChange={b => this.handleFormUpdate(['settings', 'displayPostDueDate'], b)} />
                    </div>

                    <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                      <Text>הסתר צ'קליסטים ריקים</Text>
                      <InputField type={'Boolean'} key={'checklist-hideEmptyChecklists'} value={Boolean(selectedForm.getNested(['settings', 'hideEmptyChecklists']))} onChange={b => this.handleFormUpdate(['settings', 'hideEmptyChecklists'], b)} />
                    </div>

                    <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                      <Text>הצג ציון צ'קליסט</Text>
                      <InputField type={'Boolean'} key={'checklist-showGrade'} value={Boolean(selectedForm.getNested(['settings', 'showChecklistGrade']))} onChange={b => this.handleFormUpdate(['settings', 'showChecklistGrade'], b)} />
                    </div>

                    <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                      <Text>הצג ערכים מינימלים</Text>
                      <InputField type={'Boolean'} key={'checklist-showMinimalGrade'} value={Boolean(selectedForm.getNested(['settings', 'showChecklistMinimalValues']))} onChange={b => this.handleFormUpdate(['settings', 'showChecklistMinimalValues'], b)} />
                    </div>

                    <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                      <Text>הצג ממלאי בקרה</Text>
                      <InputField type={'Boolean'} key={'checklist-showUpdaters'} value={Boolean(selectedForm.getNested(['settings', 'showChecklistUpdaters']))} onChange={b => this.handleFormUpdate(['settings', 'showChecklistUpdaters'], b)} />
                    </div>

                    <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                      <Text>הצג תאריך השלמת בקרה</Text>
                      <InputField type={'Boolean'} key={'checklist-InitialCompleteDate'} value={Boolean(selectedForm.getNested(['settings', 'showChecklistInitialCompleteDate']))} onChange={b => this.handleFormUpdate(['settings', 'showChecklistInitialCompleteDate'], b)} />
                    </div>

                    <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                      <Text>הצג "שלב" בשם הצ'קליסט</Text>
                      <InputField type={'Boolean'} key={'checklist-showChecklistStage'} value={Boolean(selectedForm.getNested(['settings', 'showChecklistStage'], true))} onChange={b => this.handleFormUpdate(['settings', 'showChecklistStage'], b)} />
                    </div>
                    <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                      <Text>hide assignee</Text>
                      <InputField type={'Boolean'} key={'checklist-hideAssignee'} value={Boolean(selectedForm.getNested(['settings', 'hideAssignee'], false))} onChange={b => this.handleFormUpdate(['settings', 'hideAssignee'], b)} />
                    </div>
                    <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                      <Text>hide closed issues</Text>
                      <InputField type={'Boolean'} key={'checklist-hideClosedIssues'} value={Boolean(selectedForm.getNested(['settings', 'hideClosedIssues'], false))} onChange={b => this.handleFormUpdate(['settings', 'hideClosedIssues'], b)} />
                    </div>

                    <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                      <Text>הצג מיקום</Text>
                      <InputField type={'Boolean'} key={'checklist-showLocation'} value={Boolean(selectedForm.getNested(['settings', 'showLocation']))} onChange={b => this.handleFormUpdate(['settings', 'showLocation'], b)} />
                    </div>

                    <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                      <Text>הצג מקצוע בפריטי צ'קליסט</Text>
                      <InputField type={'Boolean'} key={'checklist-showTradeOnChecklistItem'} value={Boolean(selectedForm.getNested(['settings', 'showTradeOnChecklistItem']))} onChange={b => this.handleFormUpdate(['settings', 'showTradeOnChecklistItem'], b)} />
                    </div>

                    <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
												<Text>הצג משימות בסוף כל צ'קליסט</Text>
												<InputField type={'Boolean'} key={'checklist-showChecklistPosts'} value={Boolean(selectedForm.getNested(['settings', 'showChecklistPosts'], false))} onChange={b => this.handleFormUpdate(['settings', 'showChecklistPosts'], b)} />
											</div>

                    <Text>צ'קליסטים המופיעים בטופס</Text>
                    <MultiCheckSelect items={checklistsArray} titlePropPath={['label']} onChange={(allItems) => this.updateSelectedChecklistArray(allItems, ['checklists', 'preDefined'])} />

                    {Boolean((selectedForm.type != "safety") && !selectedForm.getNested(['exportableFromEveryChecklists'], false)) &&
                      <>
                        <Text>צ'קליסטים מהם ניתן לייצא:</Text>
                          <MultiCheckSelect items={exportableChecklistsArray} titlePropPath={['label']} onChange={(allItems) => this.updateSelectedChecklistArray(allItems, ['exportableChecklists'])} />
                        </>
                      }

                  </CollapsibleSection_DEPRECATED>
                  

                  <CollapsibleSection_DEPRECATED title={"הגדרות DOCX"}>
                    <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                      <Text>האם טופס מבוסס קובץ?</Text>
                      <StandardInput type={'Boolean'} key={'docx'} value={Boolean(_.get(selectedForm, ['docx']))} onChange={(value) => { this.handleFormUpdate(['docx'], value);}} />
                    </div>
                    {Boolean(_.get(selectedForm, ['docx'])) &&
                      <>
                        <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                          <Text>נושאים בקובץ</Text>
                          <StandardInput
                            type={'SelectionList'}
                            key={'templateScopes'}
                            rtl={rtl}
                            value={_.get(selectedForm, ['docx', 'scopes'])}
                            options={[
                              {id: "projectsInfo", title: "Projects"},
                              {id: "locationsInfo", title: "Locations"},
                              {id: "employeesInfo", title: "Employees"},
                              {id: "equipmentInfo", title: "Equipment"}
                            ]}
                            onChange={ val => {this.handleFormUpdate(['docx', 'scopes'], val)} }
                            isMulti={true}
                          />
                        </div>
                        <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                          <StandardInput 
                            type={'FilesDropzone'}
                            key={'docxFilePath'}
                            accept={'.docx'}
                            value={_.get(selectedForm, ['docx', 'file'])}
                            onChange={(file) => {this.handleFormUpdate(['docx', 'file'], file)}}
                          />
                        </div>
                      </>
                    }
                    {Boolean(_.get(selectedForm, ['docxUri'])) &&
                      <div style={{ ...inputFieldWrapperStyle, alignSelf: 'flex-start', paddingRight: theme.margin }}>
                        <div style={{ width: 100 }}>
                          <button style={btnStyle} onClick={() => { this.handleFormUpdate(['previewForm'], {}) }}><Text>{'תצוגה מקדימה'}</Text></button>
                            <Modal open={Boolean(_.get(selectedForm, ['previewForm']))} onClose={() => { this.handleFormUpdate(['previewForm']) }}>
                              <div style={{ margin: 50 }}>
                                <Text>בחר מיקום</Text>
                                <div style={{ width: 250 }}>
                                  <StandardInput
                                    type={'Location'}
                                    shouldReturnFullLocation={true}
                                    value={_.get(selectedForm, ['previewForm', 'selectedLocation', 'selectedLocation'])}
                                    onChange={value => { this.handleFormUpdate(['previewForm', 'selectedLocation'], value) }}
                                  />
                                </div>
                                <button style={btnStyle} onClick={() => this.handleFormPreview()}><Text>{'הצג טופס'}</Text></button>
                              </div>
                            </Modal>
                          </div>
                        </div>
                    }
                    {Boolean(showFormPreview) &&
                      <ImageCarousel
                        toolbar
                        pdfMode={true}
                        onClose={() => {this.closeFormPreview()}}
                        initialSlide={0}
                        items={[{ src: _.get(selectedForm, ['previewForm', 'previewURI']) }]} />
                    }
                  </CollapsibleSection_DEPRECATED>
                  </GridItem>
                </>
              }

            </GridContainer>
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <div style={{ width: 250 }}>
                <div style={btnStyle} onClick={this.saveChanges}><Text>{systemMessages.saveChanges}</Text></div>
              </div>
            </div>
          </div>
        }
      />
    );
  }
}

const styles = {
  textCenter: {
    textAlign: 'center', alignItems: 'center', alignContent: 'center', justify: 'center',
  }
};

FormManager = withStyles(theme.combineStyles(loginPageStyle, styles))(FormManager);
FormManager = withRouter(FormManager);
FormManager = injectIntl(FormManager);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(state => ({
    rtl: state.app.rtl,
    formUniversalIdsMap: state.quasiStatics.formUniversalIdsMap,
  }),
    { getNewFormId, getQuasiStatics, startToast, upsertForm, exportFormPDF })
);
export default enhance(FormManager);