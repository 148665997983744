import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import _ from "lodash";
// Components
import ChecklistPage from "../Checklists/ChecklistPage";

// Other
import { saveChecklists, DEPRECATED_saveChecklists, updateLocalChecklists, getChecklists, getNewChecklistId } from '../../../common/checklists/actions';
import systemMessages from '../../../common/app/systemMessages';
import { ProjectManagerContext } from '../../../common/projects/contexts';
import { updateLocalChecklistItems, getChecklistItems, getNewChecklistItemId } from '../../../common/checklistItems/actions';
import { getStages, updateLocalStages, getNewStageId } from '../../../common/stages/actions';
import { Field } from './config/projectManagerConfig';
import theme from '../../assets/css/theme';
import { startLoading, hideLoading, startToast } from '../../../common/app/actions';
import { baseRemoveNested } from './ProjectManager2_0';
import { saveProperties } from '../../../common/propertiesTypes/actions';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { getLocationsTitlesMap2, getWantedLocations } from '../Locations/funcs';

const useNewSaveFunction = false;

const largeWidth = 22;
const smallWidth = 13;

const excelSheetStructure = {
  id: { name: "ID", types: ["String"], style: { width: 30 } },
  isStage: {
    name: "Is stage?",
    types: ["Boolean"],
    style: { width: smallWidth },
  },
  isChecklist: {
    name: "Is checklist?",
    types: ["Boolean"],
    style: { width: smallWidth },
  },
  isChecklistItem: {
    name: "Is checklistItem?",
    types: ["Boolean"],
    style: { width: largeWidth },
  },
  isDeleted: {
    name: "Is deleted?",
    types: ["Boolean"],
    style: { width: smallWidth },
  },
  description: {
    name: "Description",
    types: ["String"],
    style: { width: largeWidth },
  },
  extraDescription: {
    name: "Extra Description",
    types: ["String"],
    style: { width: largeWidth },
  },
  tradeId: {
    name: "Trade ID",
    types: ["Number"],
    style: { width: smallWidth },
  },
  buildingTitles: {
    name: "Building titles",
    types: ["String"],
    style: { width: largeWidth },
  },
  floorTitles: {
    name: "Floor titles",
    types: ["String"],
    style: { width: largeWidth },
  },
  unitTitles: {
    name: "Unit titles",
    types: ["String"],
    style: { width: largeWidth },
  },
  imageRequired: {
    name: "Image required?",
    types: ["Boolean"],
    style: { width: largeWidth },
  },
  descriptionRequired: {
    name: "Description required?",
    types: ["Boolean"],
    style: { width: largeWidth },
  },
  attachmentRequired: {
    name: "Attachment required?",
    types: ["Boolean"],
    style: { width: largeWidth },
  },
  drawingRequired: {
    name: "Drawing required?",
    types: ["Boolean"],
    style: { width: largeWidth },
  },
  enablePartialButton: {
    name: "Enable partial button?",
    types: ["Boolean"],
    style: { width: largeWidth },
  },
  enableIrrelevantButton: {
    name: "Enable irrelevant button?",
    types: ["Boolean"],
    style: { width: largeWidth },
  },
  weight: { name: "Weight", types: ["Number"], style: { width: smallWidth } },
  isRoutine: {
    name: "Is routine?",
    types: ["Boolean", "Number"],
    style: { width: smallWidth },
  },
  isMandatory: {
    name: "Is mandatory?",
    types: ["Boolean"],
    style: { width: smallWidth },
  },
  isDuplicatable: {
    name: "Is duplicatable?",
    types: ["Boolean"],
    style: { width: smallWidth },
  },
  enableDistributionList: {
    name: "Enable distribution list?",
    types: ["Boolean"],
    style: { width: largeWidth },
  },
  companyReadPermissions: {
    name: "Company read permissions",
    types: ["String"],
    style: { width: largeWidth },
  },
  includeInGrade: {
    name: "Include in grade?",
    types: ["Boolean"],
    style: { width: smallWidth },
  },
};

class CheckListManager extends React.Component {
  constructor(props) {
    super(props);
    this.handleConfirmSave = this.handleConfirmSave.bind(this);
    this.handleConfirmCancel = this.handleConfirmCancel.bind(this);
    this.recalcHeader = this.recalcHeader.bind(this);
    this.getChecklistsCompleteInfo = this.getChecklistsCompleteInfo.bind(this);
    this.formatAndDisplayErrors = this.formatAndDisplayErrors.bind(this);
    this.checkForErrors = this.checkForErrors.bind(this);
    this.setChecklistsMappedByStage =
      this.setChecklistsMappedByStage.bind(this);
    this.prepareDataForExcelExport = this.prepareDataForExcelExport.bind(this);
    this.getLocationsExportTitlesArrs =
      this.getLocationsExportTitlesArrs.bind(this);
    this.extractExcelData = this.extractExcelData.bind(this);
    this.onImport = this.onImport.bind(this);
    this.setAllowEdit = this.setAllowEdit.bind(this);

    this.state = {
      checklistsCompleteInfo: {},
      checklistsMappedByStage: [],
      allowToggleEdit: false,
      // stagesBackup: {},
      // checklistsBackup: {},
      // checklistItemsBackup: {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.setComponentData(this.props, nextProps);
  }

  UNSAFE_componentWillMount() {
    this.handleConfirmCancel();
    this.setComponentData({}, this.props);
  }

  componentDidMount() {
    const { setIsChecklistManagerPage } = this.props;
    if (setIsChecklistManagerPage) setIsChecklistManagerPage(true);
    this.recalcHeader();
  }

  componentWillUnmount() {
    const { setIsChecklistManagerPage } = this.props;
    if (setIsChecklistManagerPage) setIsChecklistManagerPage(false);
    this.handleConfirmCancel();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isEditMode, intl } = this.props;
    const { allowToggleEdit } = this.state;
    if (
      prevProps.isEditMode !== isEditMode ||
      prevState.allowToggleEdit !== allowToggleEdit
    )
      this.recalcHeader();
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};

    if (
      !nextProps.isChecklistManagerPage &&
      nextProps.setIsChecklistManagerPage
    )
      nextProps.setIsChecklistManagerPage(true);

    if (
      props.isValDiff(["buildings"], nextProps) ||
      props.isValDiff(["floors"], nextProps) ||
      props.isValDiff(["units"], nextProps)
    ) {
      const { buildings, floors, units } = nextProps;
      newStateChanges.locationsTitles = getLocationsTitlesMap2(
        buildings.safeToJS(),
        floors.safeToJS(),
        units.safeToJS(),
        nextProps.intl
      );
    }

    const didStagesOrChecklistsOrChecklistItemsChanged = Boolean(
      props.isValDiff(nextProps, ["stages"]) ||
        props.isValDiff(nextProps, ["checklists"]) ||
        props.isValDiff(nextProps, ["checklistItems"])
    );

    if (!nextProps.hasModifications && nextProps.isEditMode) {
      const didPropTypesHadBeenChanged = !_.isEqual(
        props.propertiesTypes,
        nextProps.propertiesTypes
      );
      if (
        (Object.keys(props.getNested(["stages"], {})).length !== 0 &&
          didStagesOrChecklistsOrChecklistItemsChanged) ||
        didPropTypesHadBeenChanged
      )
        if (nextProps.setHasModifications) nextProps.setHasModifications(true);
    }

    if (
      (props.isEditMode && !nextProps.isEditMode) ||
      (!nextProps.isEditMode &&
        (didStagesOrChecklistsOrChecklistItemsChanged ||
          props.isValDiff(nextProps, ["propertiesTypes"])))
    ) {
      const { stages, checklists, checklistItems, propertiesTypes } = nextProps;

      newStateChanges.stagesBackup = stages;
      newStateChanges.checklistsBackup = checklists;
      newStateChanges.checklistItemsBackup = checklistItems;
      newStateChanges.propertiesTypesBackup = propertiesTypes;
    }

    const { selectedProjectId, isEditMode } = nextProps;
    if (isEditMode)
      if (
        props.isValDiff(nextProps, ["stagesLastUpdateTS", selectedProjectId]) ||
        props.isValDiff(nextProps, [
          "checklistsLastUpdateTS",
          selectedProjectId,
        ]) ||
        props.isValDiff(nextProps, [
          "checklistItemsLastUpdateTS",
          selectedProjectId,
        ])
      )
        if (didStagesOrChecklistsOrChecklistItemsChanged) {
          const { startToast } = nextProps;
          const {
            unableToEditAlert: { title, message },
            refresh,
          } = systemMessages;
          startToast({
            overlay: true,
            mandatory: true,
            title,
            message,
            actions: [
              {
                message: refresh,
                onClick: () => {
                  window.location.reload();
                },
                color: "success",
              },
            ],
          });
        }

    if (
      props.isValDiff(["importedFile"], nextProps) &&
      nextProps.importedFile
    ) {
      this.onImport(nextProps, false);
      if (nextProps.resetImportedFile) nextProps.resetImportedFile(true);
    }

    if (
      props.isValDiff(["appendImportedFile"], nextProps) &&
      nextProps.appendImportedFile
    ) {
      this.onImport(nextProps);
      if (nextProps.resetImportedFile) nextProps.resetImportedFile(true);
    }

    if (!this.allowEditIsSet) {
      if (!this.debouncedSetAllowEdit)
        this.debouncedSetAllowEdit = AwesomeDebouncePromise(() => {
          this.setAllowEdit(true);
        }, 3000);
      this.debouncedSetAllowEdit();
    }

    if (Object.keys(newStateChanges).length) this.setState(newStateChanges);
  }

  onImport(props, appendMode = true) {
    const {
      selectedProjectId,
      importedFile,
      appendImportedFile,
      updateLocalStages,
      updateLocalChecklists,
      updateLocalChecklistItems,
      startLoading,
      hideLoading,
      startToast,
    } = props;
    startLoading({
      title: systemMessages.loadingMessage,
      overlay: true,
      hideOnBackgroundPress: false,
    });

    let excelFile = appendMode ? appendImportedFile : importedFile;
    let toastData;
    setTimeout(() => {
      try {
        let { newStages, newChecklists, newChecklistItems } =
          this.extractExcelData(excelFile, appendMode);

        if (updateLocalStages) updateLocalStages(selectedProjectId, newStages);
        if (updateLocalChecklists)
          updateLocalChecklists(selectedProjectId, newChecklists);
        if (updateLocalChecklistItems)
          updateLocalChecklistItems(selectedProjectId, newChecklistItems);

        toastData = { title: systemMessages.importSuccess, type: "success" };
      } catch (err) {
        toastData = {
          overlay: true,
          mandatory: true,
          title: systemMessages.importError,
          message: systemMessages.errorOnImport,
          values: { error: String(err) },
          actions: [{ message: systemMessages.ok }],
        };
      } finally {
        hideLoading();
        startToast(toastData);
      }
    }, 1);
  }

  recalcHeader() {
    const { recalcHeaderWithOptions, calcInputField, rtl, viewer } = this.props;
    const { allowToggleEdit } = this.state;

    if (recalcHeaderWithOptions) {
      let options = {
        confirmSaveFunc: this.handleConfirmSave,
        confirmCancelFunc: this.handleConfirmCancel,
        allowToggleEdit,
        extraOnComp: (
          <div
            style={{
              flexGrow: 1,
              justifyContent: "flex-start",
              [rtl ? "marginLeft" : "marginRight"]: theme.verticalMargin,
              display: "flex",
            }}
          >
            {calcInputField(
              new Field(null, "Excel", ["importedFile"], null, {
                settings: {
                  importMethods: [
                    {
                      id: "fullMode",
                      label: "Import and replace all",
                      viewerPermissions: [{ adminMode: 1 }],
                    },
                    {
                      id: "appendMode",
                      ...(viewer.adminMode === 1
                        ? { label: "Import and append" }
                        : {}),
                    },
                  ],
                  exportMethods: [
                    {
                      label: "Export all",
                      getDataToExportFunc: () =>
                        this.prepareDataForExcelExport("fullMode"),
                      viewerPermissions: [{ adminMode: 1 }],
                    },
                    {
                      ...(viewer.adminMode === 1
                        ? { label: "Export without IDs" }
                        : {}),
                      getDataToExportFunc: () =>
                        this.prepareDataForExcelExport("partialMode"),
                    },
                  ],
                },
                style: { flex: 0, marginBottom: 0 },
                innerStyle: { alignItems: "center" },
              })
            )}
          </div>
        ),
      };

      recalcHeaderWithOptions(options);
    }
  }

  prepareDataForExcelExport(mode) {
    const { selectedProjectId } = this.props;
    const { checklistsMappedByStage } = this.state;
    const joinTitlesArr = (titlesArr) =>
      titlesArr
        .filter((val) => Boolean(val))
        .join(";")
        .replace(/\"/g, "''");
    let recalculatedSheetStructure;
    switch (mode) {
      case "partialMode":
        recalculatedSheetStructure = baseRemoveNested(excelSheetStructure, [
          ["id"],
          // ['buildingTitles'],
          // ['floorTitles'],
          // ['unitTitles'],
          // ['isRoutine'],
          // ['isDuplicatable'],
          // ['companyReadPermissions'],
        ]);
        break;

      case "fullMode":
      default:
        recalculatedSheetStructure = excelSheetStructure;
    }

    let validationRulesMap = {
      weight: {
        type: "whole",
        allowBlank: true,
      },
    };

    let data = {
      checklists: {
        sheetStructure: recalculatedSheetStructure,
        validationRulesMap,
        rowsData: [],
        sheetView: [{ state: "frozen", xSplit: 4, ySplit: 1 }],
      },
    };

    checklistsMappedByStage.loopEach((i, stageObj) => {
      // Stage
      const { stage, stageId, stageTitle, checklists } = stageObj;

      data.checklists.rowsData.push({
        id: stageId,
        isStage: true,
        isDeleted: stage.getNested(["isDeleted"]) || null,
        description: stageTitle,
        includeInGrade: stage.getNested(["includeInGrade"]) || null,
      });

      checklists.loopEach((i, checklistObj) => {
        // Checklist
        const { checklist, title: checklistTitle, items } = checklistObj;
        const { buildingTitlesArr, floorTitlesArr, unitTitlesArr } =
          this.getLocationsExportTitlesArrs(checklist, "locations");
        const sempleItemCompanyReadPermissions =
          Object.keys(
            (items[0] || {}).getNested(
              ["item", "permissions", "read", "companies"],
              {}
            )
          )[0] || null;

        data.checklists.rowsData.push({
          id: checklist.getNested(["id"]),
          isChecklist: true,
          isDeleted: checklist.getNested(["isDeleted"]) || null,
          description: checklistTitle,
          buildingTitles: joinTitlesArr(buildingTitlesArr),
          floorTitles: joinTitlesArr(floorTitlesArr),
          unitTitles: joinTitlesArr(unitTitlesArr),
          isRoutine: Boolean(checklist.getNested(["type"])) || null,
          isDuplicatable: checklist.getNested(["duplicatable"]) || null,
          companyReadPermissions: sempleItemCompanyReadPermissions,
          enableDistributionList:
            checklist.getNested(["enableDistributionList"]) || null,
        });

        items.loopEach((i, itemObj) => {
          // Item
          const { item, title: itemTitle } = itemObj;

          const { buildingTitlesArr, floorTitlesArr, unitTitlesArr } =
            this.getLocationsExportTitlesArrs(item, "onlyLocations");

          data.checklists.rowsData.push({
            id: item.getNested(["id"]),
            isChecklistItem: true,
            isDeleted: item.getNested(["isDeleted"]) || null,
            description: itemTitle,
            extraDescription: item.getNested(["extraDescription"], null),
            tradeId: item.getNested(["trade"], null),
            buildingTitles: joinTitlesArr(buildingTitlesArr),
            floorTitles: joinTitlesArr(floorTitlesArr),
            unitTitles: joinTitlesArr(unitTitlesArr),
            imageRequired: item.getNested(["requirements", "img"]) || null,
            descriptionRequired:
              item.getNested(["requirements", "desc"]) || null,
            attachmentRequired:
              item.getNested(["requirements", "file"]) || null,
            drawingRequired:
              item.getNested(["requirements", "drawing"]) || null,
            enablePartialButton:
              item.getNested(["permissions", "actions", "partial"]) || null,
            enableIrrelevantButton:
              item.getNested(["permissions", "actions", "irrelevant"]) || null,
            weight: item.getNested(["weight"], null),
            isRoutine: item.getNested(["period"], null),
            isMandatory: item.getNested(["isMandatory"], null),
          });
        });
      });
    });

    return { fileName: `checklists_${selectedProjectId}`, data };
  }

  getLocationsExportTitlesArrs(checklistOrItem, rootPathToLocations) {
    const { locationsTitles } = this.state;

    let buildingTitlesArr = [];
    let floorTitlesArr = [];
    let unitTitlesArr = [];

    [
      ["buildings", buildingTitlesArr],
      ["floors", floorTitlesArr],
      ["units", unitTitlesArr],
    ].forEach(([pathRest, arrToPushInto]) =>
      checklistOrItem
        .getNested([rootPathToLocations, pathRest], {})
        .loopEach((id, val) => {
          arrToPushInto.push(
            locationsTitles.getNested(["flatMapById", id, "fullExportTitle"])
          );
        })
    );

    return {
      buildingTitlesArr,
      floorTitlesArr,
      unitTitlesArr,
    };
  }

  extractExcelData(excelObj, appendMode) {
    const {
      lang,
      selectedProjectId,
      getNewStageId,
      getNewChecklistId,
      getNewChecklistItemId,
      buildings,
      floors,
      units,
      intl,
      projectCompanies,
    } = this.props;
    const { checklistsCompleteInfo, locationsTitles } = this.state;

    let { stages, checklists, checklistItems } = this.props;
    stages = stages.toJS ? stages.toJS() : stages;
    checklists = checklists.toJS ? checklists.toJS() : checklists;
    checklistItems = checklistItems.toJS
      ? checklistItems.toJS()
      : checklistItems;

    if (!excelObj) return false;

    let newStages = {};
    let newChecklists = {};
    let newChecklistItems = {};
    let usedIds = {};

    // Stage track
    let currStageId = null;
    let currStageName = null;
    let currStageIsDeleted = false;
    let currStageOrdinalNo = appendMode ? Object.keys(stages).length + 1 : 1;

    // Checklist track
    let currChecklistId = null;
    let currChecklistIsDeleted = false;
    let currChecklistDupChecklistIds = null;
    let currChecklistOrdinalNo = appendMode
      ? Object.keys(checklists).length + 1
      : 1;

    // ChecklistItem track
    let currChecklistItemPeriod = null;
    let currChecklistItemReadPermissions = null;
    let currChecklistItemOrdinalNo = 1;

    let recalculatedSheetStructure = !appendMode
      ? excelSheetStructure
      : baseRemoveNested(excelSheetStructure, [
          ["id"],
          // ['tradeId'],
          // ['buildingTitles'],
          // ['floorTitles'],
          // ['unitTitles'],
          // ['isRoutine'],
          // ['isDuplicatable'],
          // ['companyReadPermissions'],
        ]);

    excelObj.setGeneralSheetsStructure(recalculatedSheetStructure);
    excelObj.eachSheet((sheetName, sheet) => {
      let currChecklist = null;
      sheet.eachRow((row, rowNumber) => {
        const {
          id,
          isStage,
          isChecklist,
          isChecklistItem,
          isDeleted,
          description,
          extraDescription,
          tradeId,
          buildingTitles,
          floorTitles,
          unitTitles,
          imageRequired,
          descriptionRequired,
          attachmentRequired,
          drawingRequired,
          enablePartialButton,
          enableIrrelevantButton,
          weight,
          isRoutine,
          isDuplicatable,
          includeInGrade,
          companyReadPermissions,
          enableDistributionList,
          isMandatory,
        } = row.values;

        if (id && !appendMode) {
          if (stages[id] || checklists[id] || checklistItems[id]) {
            if (usedIds[id])
              throw intl.formatMessage(systemMessages.excel.foundDuplicatedId, {
                rowNumber,
                id,
              });
          } else {
            throw intl.formatMessage(systemMessages.excel.couldNotFindId, {
              rowNumber,
            });
          }

          usedIds[id] = true;
        }

        if (!isStage && !isChecklist && !isChecklistItem)
          throw intl.formatMessage(systemMessages.excel.missingRequiredField, {
            fields: ["isStage", "isChecklist", "isChecklistItem"].join(", "),
            description,
            rowNumber,
          }); // TODO: translate

        // STAGES -----------------------------------------------------------------------------
        if (isStage) {
          currChecklistId = null;

          const oldStage = stages[id] || {};
          let stage = {
            ...(oldStage.toJS ? oldStage.toJS() : oldStage),
            id: id ? id : getNewStageId(selectedProjectId).payload.id,
            title: {
              [lang]: description,
            },
            ordinalNo: currStageOrdinalNo,
            includeInGrade: includeInGrade === true ? true : false,
            isDeleted: isDeleted ? true : null,
          };

          if (!stage.isDeleted) currStageOrdinalNo++;

          currStageId = stage.id;
          currStageName = description;
          currStageIsDeleted = Boolean(stage.isDeleted);

          newStages[stage.id] = stage;
          return;
        }
        const selectedBuildings = getWantedLocations({
          locationsString: buildingTitles,
          type: "building",
          flatMapByExportTitle: locationsTitles.flatMapByExportTitle,
        });
        const selectedFloors = getWantedLocations({
          locationsString: floorTitles,
          type: "floor",
          flatMapByExportTitle: locationsTitles.flatMapByExportTitle,
        });
        const selectedUnits = getWantedLocations({
          locationsString: unitTitles,
          type: "unit",
          flatMapByExportTitle: locationsTitles.flatMapByExportTitle,
        });

        [selectedBuildings, selectedFloors, selectedUnits].forEach(
          (locations) => {
            if (Object.keys(locations.missingLocations).length)
              throw (
                "Could not find the following locations in row " +
                rowNumber +
                ": " +
                Object.keys(locations.missingLocations).join("; ")
              );
            else if (
              !(locations && Object.keys(locations.wantedLocations).length)
            )
              return;

            locations.locations = {};
            Object.values(locations.wantedLocations).forEach((loc) => {
              locations.locations[loc.id] = { id: loc.id };
            });
          }
        );

        const locationsObj = {
          ...(selectedBuildings.locations
            ? { buildings: selectedBuildings.locations }
            : {}),
          ...(selectedFloors.locations
            ? { floors: selectedFloors.locations }
            : {}),
          ...(selectedUnits.locations
            ? { units: selectedUnits.locations }
            : {}),
        };

        if (Object.keys(locationsObj).length > 1)
          throw intl.formatMessage(systemMessages.moreThanOneLocation, {
            rowNumber,
          });

        // CHECKLISTS -----------------------------------------------------------------------------
        if (isChecklist) {
          if (!currStageId)
            throw intl.formatMessage(systemMessages.noCorrespondingStage, {
              rowNumber,
              description,
            });

          const oldChecklist = checklists[id] || {};
          let checklist = {
            ...(oldChecklist.toJS ? oldChecklist.toJS() : oldChecklist),
            id: id ? id : getNewChecklistId(selectedProjectId).payload.id,
            description,
            type: isRoutine ? "routine" : null,
            duplicatable: isDuplicatable,
            ordinalNo: currChecklistOrdinalNo,
            locations: Object.keys(locationsObj).length ? locationsObj : null,
            stageId: currStageId,
            stage: currStageName,
            isDeleted: currStageIsDeleted || isDeleted ? true : null,
            enableDistributionList,
          };

          if (!checklist.isDeleted) currChecklistOrdinalNo++;

          currChecklistId = checklist.id;
          currChecklistIsDeleted = Boolean(checklist.isDeleted);
          currChecklistItemPeriod = isRoutine ? 100 : null;

          if (
            companyReadPermissions &&
            !_.get(projectCompanies, [companyReadPermissions])
          ) {
            let availableCompanyIdsNameList = Object.entries(
              projectCompanies
            ).map(
              ([companyId, companyInfo]) => `${companyInfo.name}: ${companyId}`
            );
            throw `Could not find company with company id "${companyReadPermissions}" in the system.\nAvailable options are: ${availableCompanyIdsNameList.join(
              "\n"
            )}`;
          }

          if (Boolean(checklist.duplicatable) && Boolean(checklist.type))
            throw `A checklist cannot be routine and duplicatable at the same time. (row ${rowNumber})`;

          currChecklistItemReadPermissions = Boolean(companyReadPermissions)
            ? {
                companies: { [companyReadPermissions]: companyReadPermissions },
              }
            : null;

          currChecklistItemOrdinalNo = 1;

          currChecklist = checklist;
          newChecklists[checklist.id] = checklist;

          currChecklistDupChecklistIds = _.get(
            checklistsCompleteInfo,
            [id, "duplicatedChecklistIds"],
            null
          );

          if (currChecklistDupChecklistIds) {
            currChecklistDupChecklistIds.forEach((checklistId) => {
              const oldDupChecklist = checklists[checklistId] || {};

              let updatedChecklist = {
                ...(oldDupChecklist.toJS
                  ? oldDupChecklist.toJS()
                  : oldDupChecklist),
                description,
                ordinalNo: currChecklistOrdinalNo,
                stageId: currStageId,
                stage: currStageName,
                isDeleted: currChecklistIsDeleted ? true : null,
              };

              newChecklists[checklistId] = updatedChecklist;
            });
          }
          return;
        }

        // CHECKLIST ITEM -------------------------------------------------------------------------
        if (isChecklistItem) {
          if (!currChecklistId)
            throw intl.formatMessage(systemMessages.noCorrespondingChecklist, {
              rowNumber,
              description,
            });

          const itemChecklist =
            currChecklist || checklists.getNested([currChecklistId]);

          const oldChecklistItem = checklistItems[id] || {};
          let checklistItem = {
            ...(oldChecklistItem.toJS
              ? oldChecklistItem.toJS()
              : oldChecklistItem),
            id: id ? id : getNewChecklistItemId(selectedProjectId).payload.id,
            description,
            extraDescription,
            isMandatory: isMandatory ? true : null,
            onlyLocations: Object.keys(locationsObj).length
              ? locationsObj
              : null,
            permissions: {
              actions: {
                irrelevant: enableIrrelevantButton ? true : null,
                partial: enablePartialButton ? true : null,
              },
              read: currChecklistItemReadPermissions,
            },
            ordinalNo: currChecklistItemOrdinalNo,
            trade: tradeId ? tradeId.toString() : null,
            period: currChecklistItemPeriod,
            weight,
            requirements: {
              desc: descriptionRequired ? true : null,
              drawing: drawingRequired ? true : null,
              file: attachmentRequired ? true : null,
              img: imageRequired ? true : null,
            },
            isDeleted:
              currStageIsDeleted || currChecklistIsDeleted || isDeleted
                ? true
                : null,
          };

          if (checklistItem.onlyLocations)
            Object.entries(checklistItem.onlyLocations).forEach(
              ([locationType, locations]) => {
                const checklistLocations = _.get(itemChecklist, [
                  "locations",
                  locationType,
                ]);
                if (
                  (!checklistLocations && Object.keys(locations).length) ||
                  Boolean(
                    Object.values(locations).filter(
                      (loc) => !checklistLocations[loc.id]
                    ).length
                  )
                )
                  throw `Checklist item locations can only be chosen from its parent checklist locations (row: ${rowNumber})`;
              }
            );

          checklistItem.checklistIds = (
            currChecklistDupChecklistIds || []
          ).reduce(
            (accObj, checklistId) => {
              accObj[checklistId] = { id: checklistId };
              return accObj;
            },
            { [currChecklistId]: { id: currChecklistId } }
          );

          if (!checklistItem.isDeleted) currChecklistItemOrdinalNo++;

          newChecklistItems[checklistItem.id] = checklistItem;
          return;
        }
      });
    });

    return { newStages, newChecklists, newChecklistItems };
  }

  getChecklistsCompleteInfo(checklistsCompleteInfo) {
    this.setState({ checklistsCompleteInfo });
  }

  handleConfirmSave() {
    const {
      stages,
      checklists,
      checklistItems,
      propertiesTypes,
      saveChecklists,
      selectedProjectId,
      saveProperties,
      viewer,
      DEPRECATED_saveChecklists,
    } = this.props;
    const {
      stagesBackup,
      checklistsBackup,
      checklistItemsBackup,
      propertiesTypesBackup,
    } = this.state;

    let errors = this.checkForErrors();

    if (Object.keys(errors).length) {
      this.formatAndDisplayErrors(errors);
      return false;
    }

    if (useNewSaveFunction) {
      let stagesDelta = this.getDelta(stages, stagesBackup);
      let checklistsDelta = this.getDelta(checklists, checklistsBackup);
      let checklistItemsDelta = this.getDelta(
        checklistItems,
        checklistItemsBackup
      );
      let propertiesDelta = this.getDelta(
        propertiesTypes,
        propertiesTypesBackup,
        true
      ); // -> { subjectName: { propsTree } }

      if (propertiesDelta && saveProperties)
        propertiesDelta.loopEach((subjectName, properties) =>
          saveProperties(
            selectedProjectId,
            subjectName,
            properties,
            null
          )
        );

      if (
        (stagesDelta || checklistsDelta || checklistItemsDelta) &&
        saveChecklists
      )
        saveChecklists(
          selectedProjectId,
          "projects",
          selectedProjectId,
          stagesDelta,
          checklistsDelta,
          checklistItemsDelta
        );
    } else {
      let propertiesDelta = this.getDelta(
        propertiesTypes,
        propertiesTypesBackup,
        true
      ); // -> { subjectName: { propsTree } }

      if (propertiesDelta && saveProperties)
        propertiesDelta.loopEach((subjectName, properties) =>
          saveProperties(
            selectedProjectId,
            subjectName,
            properties,
            null
          )
        );

      if (DEPRECATED_saveChecklists)
        DEPRECATED_saveChecklists(
          stages,
          checklists,
          checklistItems,
          "projects",
          selectedProjectId
        );
    }
  }

  getDelta(newObjs, originObjs, fullObjDelta = false) {
    let delta = {};
    newObjs.loopEach((objId, currNewObj) => {
      currNewObj = currNewObj.toJS ? currNewObj.toJS() : currNewObj;

      if (fullObjDelta && delta.getNested([objId], false)) return;

      Object.keys(currNewObj).forEach((key) => {
        let currNewVal = currNewObj[key].toJS
          ? currNewObj[key].toJS()
          : currNewObj[key];

        let currOriginObj = originObjs.getNested([objId], {});
        currOriginObj = currOriginObj.toJS
          ? currOriginObj.toJS()
          : currOriginObj;

        if (_.isEqual(currNewVal, currOriginObj[key])) return;

        if (fullObjDelta) delta = delta.setNested([objId], currNewObj);
        else delta = delta.setNested([objId, key], currNewVal);
      });
    });

    return Object.keys(delta).length ? delta : false;
  }

  checkForErrors() {
    const { checklistsCompleteInfo } = this.state;
    const { checklists, checklistItems, viewer } = this.props;

    let errors = {};
    if (!Boolean(viewer.adminMode)) {
      let errorsTemplate = {
        checklistId: null,
        itemId: null,
        missingLocation: false,
        missingTrade: false,
        isEmpty: false,
      };
      checklistsCompleteInfo.loopEach((i, info) => {
        const {
          originChecklistId,
          itemsIds,
          id: checklistId,
          duplicatedChecklistIds,
        } = info;
        let currChecklist = checklists.getNested([checklistId]);

        if (
          originChecklistId !== false ||
          currChecklist.getNested(["contentType"]) === "employeesHealthy"
        )
          return;

        if (!itemsIds.length)
          errors = errors.setNested([checklistId], {
            ...errors.getNested([checklistId], errorsTemplate),
            checklistId,
            isEmpty: true,
          });

        if (!currChecklist.getNested(["locations"], null))
          errors = errors.setNested([checklistId], {
            ...errors.getNested([checklistId], errorsTemplate),
            checklistId,
            missingLocation: true,
          });

        itemsIds.loopEach((i, itemId) => {
          if (!checklistItems.getNested([itemId, "trade"], null))
            errors = errors.setNested([itemId], {
              ...errors.getNested([itemId], errorsTemplate),
              checklistId,
              itemId,
              missingTrade: true,
            });
        });
      });
    }

    return errors;
  }

  formatAndDisplayErrors(errors) {
    const { stages, checklists, checklistItems, startToast, intl } = this.props;
    const {
      isMissingLocation,
      itemIsMissingTrade,
      isEmpty: isEmptyMessage,
    } = systemMessages;

    let formattedErrors = [];
    Object.values(errors).forEach(
      ({ checklistId, itemId, missingLocation, missingTrade, isEmpty }) => {
        let checklist = checklists.getNested([checklistId]);
        let stageName =
          checklist.getNested(["stage"]) ||
          stages.getNested([checklist.getNested(["stageId"])]);
        let checklistName = checklist.getNested(["description"]);
        let itemName = checklistItems.getNested([itemId, "description"], false);
        let errorMessages = [
          missingLocation ? intl.formatMessage(isMissingLocation) : false,
          missingTrade ? intl.formatMessage(itemIsMissingTrade) : false,
          isEmpty ? intl.formatMessage(isEmptyMessage) : false,
        ]
          .filter(Boolean)
          .join(", ");

        let errorLine = `${stageName} > ${checklistName} ${
          itemName ? `> ${itemName}` : ""
        } ${errorMessages}.`;

        formattedErrors.push(errorLine);
      }
    );

    startToast({
      overlay: true,
      mandatory: true,
      message: systemMessages.invalidChangesChecklistManager,
      values: { errors: formattedErrors.join("\n") },
      actions: [{ message: systemMessages.ok, type: "success" }],
    });
  }

  handleConfirmCancel() {
    const {
      selectedProjectId,
      getStages,
      getChecklists,
      getChecklistItems,
      viewer,
    } = this.props;

    this.setAllowEdit(false);

    if (getStages)
      getStages(selectedProjectId);
    if (getChecklists)
      getChecklists(viewer, selectedProjectId);
    if (getChecklistItems)
      getChecklistItems(viewer, selectedProjectId);
  } 
  
  setAllowEdit(bool) {
    this.allowEditIsSet = bool;
    this.setState({ allowToggleEdit: bool });
  }

  setChecklistsMappedByStage(checklistsMappedByStage) {
    this.setState({ checklistsMappedByStage });
  }

  render() {
    const { match, history, setHeaderParams } = this.props;
    return (
      <>
        <ChecklistPage
          match={match}
          history={history}
          setHeaderParams={setHeaderParams}
          onChecklistsCompleteInfoChange={this.getChecklistsCompleteInfo}
          setChecklistsMappedByStage={this.setChecklistsMappedByStage}
          isChecklistManager={true}
        />
      </>
    );
  }
}

CheckListManager = injectIntl(CheckListManager);

const enhance = compose(
  connectContext(ProjectManagerContext.Consumer),
  connect(
    (state) => ({
      stagesLastUpdateTS: state.stages.lastUpdated,
      checklistsLastUpdateTS: state.checklists.lastUpdated,
      checklistItemsLastUpdateTS: state.checklistItems.lastUpdated,
    }),
    {
      saveChecklists, // stages and checklistItems saved in same action as checklists
      DEPRECATED_saveChecklists, // TODO: remove once switch to new one above
      saveProperties,

      updateLocalStages,
      updateLocalChecklists,
      updateLocalChecklistItems,

      getNewStageId,
      getNewChecklistId,
      getNewChecklistItemId,

      getStages,
      getChecklists,
      getChecklistItems,

      startLoading,
      hideLoading,
      startToast,
    }
  )
);

export default enhance(CheckListManager);
