import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose, hoistStatics } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import theme from '../../assets/css/theme';
import { newId } from './funcs';
import { convertTextLinks, convertCementoML } from '../../../common/app/funcs';
import systemMessages from '../../../common/app/systemMessages';
import _ from 'lodash';

const defaultHeader1Transformer = string => (
	<div
		style={{
			fontWeight: 'bold',
			fontSize: theme.fontSizeH6,
			color: theme.brandPrimary,
			marginBottom: 2 * theme.verticalMargin,
		}}
	>
		{string}
	</div>
);
const defaultHeader2Transformer = string => (
	<div style={{ fontWeight: 'bold', marginTop: 2 * theme.verticalMargin }}>{string}</div>
);
const defaultSeparatorComponent = (
	<div
		style={{
			width: '100%',
			height: theme.rowHeight / 6,
			flexDirection: 'row',
			borderBottomWidth: 1,
			borderBottomStyle: 'solid',
			borderBottomColor: theme.separatorColor,
			marginBottom: theme.verticalMargin,
		}}
	/>
);
const returnEmptyString = () => '';

const defaultProps = {
  withTooltip: true,
}



class Text extends React.Component {
  constructor(props) {
    super(props);
    this.id = newId('text');
    this.state = {};
  }

  shouldComponentUpdate(nextProps) {
    let should = (
      !_.isEqual(this.props.key, nextProps.key) ||
      !_.isEqual(this.props.children, nextProps.children) ||
      !_.isEqual(this.props.values, nextProps.values) ||
      !_.isEqual(this.props.maxLines, nextProps.maxLines) ||
      !_.isEqual(this.props.maxChars, nextProps.maxChars) ||
      !_.isEqual(this.props.linksMode, nextProps.linksMode) ||
      !_.isEqual(this.props.tagsMode, nextProps.tagsMode) ||
      !_.isEqual(this.props.title, nextProps.title) ||
      !_.isEqual(this.props.enableTitle, nextProps.enableTitle) ||
      !_.isEqual(this.props.style, nextProps.style) ||
      !_.isEqual(this.props.classes, nextProps.classes) ||
      !_.isEqual(this.props.noSelect, nextProps.noSelect) ||
      !_.isEqual(this.props.onClick, nextProps.onClick)
    );

    return should;
  }

  handleClick = (e) => {
    const { onClick } = this.props;

    if (onClick)
      onClick(e);
  }
  
  render() {
    const {
      children, values, intl,
      maxLines, rtl, key, enableTitle,
      title, classes, noSelect,
      maxChars, linksMode, tagsMode,
      separatorComponent, header1TagTransformer,
      header2TagTransformer, withTooltip,
      onMouseOver, onMouseLeave,
      ...rest
    } = this.props;

    let style = this.props.style;
    let inner = children;
    if (children && typeof children === 'object' && children.defaultMessage) {
      let vals = undefined;
      if (values) {
        vals = {};
        values.loopEach((k, v) => vals[k] = (typeof v === 'object' && v.defaultMessage) ? intl.formatMessage(v) : v);
      }
      inner = intl.formatMessage(children, vals);
    }

    if (typeof inner == 'string' && inner.indexOf('\n') != -1)
      inner = inner.split('\n');
    else
      inner = [inner];

    return (inner.map((curr, index) => {
      if (_.isNil(curr))
        return null;
      let ret = curr;
      let linksPlaceholder = '';
      if (linksMode === 'convert')
        linksPlaceholder = intl.formatMessage(systemMessages.clickHere);
      if (tagsMode) {
        let h1Transformer = header1TagTransformer ? header1TagTransformer : defaultHeader1Transformer;
        let h2Transformer = header2TagTransformer ? header2TagTransformer : defaultHeader2Transformer;
        let separator = separatorComponent ? separatorComponent : defaultSeparatorComponent;

        if (tagsMode == 'hide') {
          h1Transformer = returnEmptyString;
          h2Transformer = returnEmptyString;
          separator = <></>;
        }

        ret = convertCementoML(curr, h1Transformer, h2Transformer, separator, linksMode, linksPlaceholder);

        if (tagsMode == 'hide') {
          ret = ret.join(' ');
        }
        else
          ret = ret.map(row => {
            return Boolean(typeof row == 'string' && row.indexOf('\n') != -1)
              ? <Text style={this.props.style}>{row}</Text>
              : row;
          });
      }
      else if (linksMode)
        ret = convertTextLinks(curr, linksPlaceholder);

      if ( typeof ret === 'string' && maxLines) {
        let maxCH = maxChars || 75;
        ret = ((ret || '').length < maxCH) ? ret : ret.slice(0, maxCH) + '...';
  
        let lineHeight = maxLines ? (style ? (style.lineHeight || style.fontSize || 1.4) : 1.4) : null;
        let fontSize = style ? (style.fontSize || theme.fontSize) : theme.fontSize;
        style = {
          ...(this.props.style || {}),
          display: (style && style.display) || '-webkit-inline-box',
          height: maxLines ? (fontSize * lineHeight * maxLines) : null, /* Fallback for non-webkit */
          fontSize: fontSize,
          lineHeight: lineHeight ? String(lineHeight) : null,
          '-webkit-line-clamp': String(maxLines),
          '-webkit-box-orient': 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        };
      }

      return (
        <div
          key={this.id + index}
          onClick={this.handleClick}
          className={noSelect ? classes.noSelect : null}
          title={withTooltip && (typeof title === 'string' ? title : Boolean(!tagsMode && enableTitle) ? curr : ret)}
          style={style}
          onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}
          {...rest}
        >
          {ret}
        </div>
      );
    }));
  }
}

let styles = {
	noSelect: {
		'-webkit-touch-callout': 'none' /* iOS Safari */,
		'-webkit-user-select': 'none' /* Safari */,
		'-khtml-user-select': 'none' /* Konqueror HTML */,
		'-moz-user-select': 'none' /* Firefox */,
		'-ms-user-select': 'none' /* Internet Explorer/Edge */,
		'user-select': 'none',
	},
};

const enhance = compose(
  withStyles(styles),
	injectIntl,
	connect(
		state => ({
			rtl: state.app.rtl,
		}),
		{},
	),
);

Text = enhance(Text);
Text.defaultProps = defaultProps;

export default Text;
