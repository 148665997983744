import * as actions from './actions';
import { firebaseActions } from '../lib/redux-firebase';
import Notification from './notification';

import { OrderedMap } from 'immutable';
import { Record } from '../transit';

const InitialState = Record({
  map: OrderedMap(),
  didLoad: OrderedMap(),
}, 'notifications');

const initialState = new InitialState;

export default function notificationReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    
    case actions.GET_NOTIFICATIONS: {
      state = state.setIn(['didLoad', action.projectId], true);

      if (!action.payload)
        return state;

      var notifications = new OrderedMap();
      var retArray = [];
      if (action.payload.notifications)
        retArray = Object.values(action.payload.notifications);

      var viewerId = action.payload.viewerId;
      retArray = retArray.sort(function(a, b){return a.createdAt && b.createdAt ? b.createdAt-a.createdAt : 0});

      for (var i = 0; i < retArray.length; i++) {
        var notification = new Notification(retArray[i]);
        notifications = notifications.set(notification.id, notification)
      }
      return state.setIn(['map', action.projectId], notifications);
    }

    case actions.END_NOTIFICATIONS_LISTENER: {
      state = state.setIn(['didLoad', action.projectId], false);
      return state.deleteIn(['map', action.projectId]);
    }
  }

  return state;
}


