import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import BurgerMenuIcon from '../../../assets/img/icons/BurgerMenu';
import theme from '../../../../common/app/theme';

const useStyles = rtl =>
	makeStyles(() => ({
		wrapper: {
			width: '100%',
		},
		card: {
			display: 'flex',
			width: '100%',
			border: '2px solid rgb(236 236 236)',
			cursor: 'pointer',
			boxShadow: '0px 0px 0px 0px grey',
			borderRadius: '8px',
			padding: theme.padding,
			backgroundColor: 'rgb(255 255 255)',
			transition: 'all 200ms linear',
			'&:hover': {
				boxShadow: '0px 0px 8px 0px grey',
				backgroundColor: 'rgb(255 250 246)',
			},
			'&:active': {
				opacity: '0.95',
			},
		},
		active: {
			border: '2px solid rgb(237 125 49)',
		},
		media: {
			width: '70%',
			minHeight: '175px',
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			padding: rtl ? '0 16px 0 0 !important' : '0 0 0 16px !important',
			justifyContent: 'space-between',
			width: '100%',
		},
		titleButton: {
			display: 'flex',
			justifyContent: 'space-between',
			width: '100%',
			alignItems: 'flex-start',
		},
		bold: {
			fontWeight: 'bold',
		},
	}))();

const CardListItem = ({
	title,
	subtitle,
	description,
	imageUrl,
	isActive = false,
	onCardClick,
	onMenuClick,
	onMount,
	children,
	...props
}) => {
	const parentRef = useRef(null);
	const buttonRef = useRef(null);
	const rtl = useSelector(state => state.app.rtl);
	const classes = useStyles(rtl);

	useEffect(() => {
		if (buttonRef?.current) {
			onMount(buttonRef.current);
		}
	}, [buttonRef]);

	return (
		<ListItem onClick={onCardClick} ref={parentRef} className={classes.wrapper} alignItems='flex-start' {...props}>
			<Card className={classNames(classes.card, { [classes.active]: isActive })}>
				<CardMedia className={classes.media} image={imageUrl} />
				<CardContent className={classes.content}>
					<div>
						<div className={classes.titleButton}>
							<div>
								<Typography className={classes.bold} variant='h5' color='textPrimary' component='p'>
									{title}
								</Typography>
								<Typography variant='body1' component='p'>
									{subtitle}
								</Typography>
								<Typography variant='body1' color='textSecondary' component='p'>
									{description}
								</Typography>
							</div>
							<IconButton
								onClick={e => {
									e.stopPropagation();
									onMenuClick();
								}}
								ref={buttonRef}
							>
								<BurgerMenuIcon fill='gray' />
							</IconButton>
						</div>
					</div>
					{typeof children === 'function' ? children({ parentRef, buttonRef }) : children}
				</CardContent>
			</Card>
		</ListItem>
	);
};

export default CardListItem;
