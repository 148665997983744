import * as appActions from '../app/actions';
import * as actions from './actions';
import { REPLACE_USER } from '../auth/actions';

import * as reportTypes from './reportTypes';
import DailyReport from './dailyReport';
import DailyReportSummary from './dailyReportSummary';
import RCLReport from './rclReport';
import RCLReportSummary from './rclReportSummary';

import { OrderedMap, Map } from 'immutable';
import { Record } from '../transit';

const InitialState = Record({
  map: OrderedMap(),
  inspectorReports: Map(),
  inspectorReportsLastUpdateTS: Map(),
  fullReports: Map(),
  lastUpdateTS: Map(),
  didLoad: Map(),
}, 'reports');

const initialState = new InitialState;

export default function reportReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {    
    case actions.GET_REPORTS_SUMMARY: {
      if (!action.payload)
        return state;
      state = state.setIn(['didLoad', action.projectId], true);

      if (!action.payload)
        return state;
      const { reports } = action.payload;
      
      var retArray = [];
      if (reports)
        retArray = Object.values(reports);

      var lastUpdateTS = 0;
 
      for (var i = 0; i < retArray.length; i++) {
        var currRport = retArray[i];

        let report = currRport.type == reportTypes.ROUTINE_CHECKLIST ? new RCLReportSummary({...currRport}) : new DailyReportSummary({...currRport});
        if (lastUpdateTS < report.updatedTS)
          lastUpdateTS = report.updatedTS;

        state = state.setIn(['map', action.projectId, report.id], report);

        if (report.isDeleted)
          state = state.deleteIn(['map', action.projectId, report.id]);
      }

      if (lastUpdateTS) {
        state = state.setIn(['lastUpdateTS', action.projectId], lastUpdateTS);

        var stateReports = state.map.get(action.projectId);
        if (stateReports) {
          let sortedReports = stateReports.sortBy(item => -item.updatedTS);
          state = state.setIn(['map', action.projectId], sortedReports); 
        }
      }

      return state;
    }

    case actions.END_REPORTS_SUMMARY_LISTENER: {
      if (action.payload && action.payload.projectId)
        state = state.setIn(['didLoad', action.payload.projectId], false);
      return state;
    }

    case actions.GET_FULL_REPORT: {
      if (!action.payload)
        return state;

      const { report } = action.payload;
      if (!report)
        return state;

      // Arrange the comments by their date
      if (report.comments) { 
        var orderedComment = Object.values(report.comments).sort(function(a, b){return a.createdAt && b.createdAt ? a.createdAt-b.createdAt : 0});
        var jsonOrderedComment = {};
        orderedComment.forEach(comment => jsonOrderedComment[comment.id] = comment)
        report.comments = jsonOrderedComment;
      }

      var lastUpdateTS = 0;
      var currRport = report.type == reportTypes.ROUTINE_CHECKLIST ? new RCLReport({...report}) : new DailyReport({...report});
      if (lastUpdateTS < currRport.updatedTS)
        lastUpdateTS = currRport.updatedTS;

      if (currRport.id && currRport.id != "")
        state = state.setIn(['fullReports', action.projectId, currRport.id], currRport);

      return state;
    }

    case appActions.CLEAN_CACHE: {
      return state = initialState;
    }

    case REPLACE_USER + "_SUCCESS": {
      return initialState;
    }

    // *** INSPECTOR
    case actions.GET_INSPECTION_REPORTS: {
      if (!action.payload)
        return state;

      const { reports } = action.payload;
      
      let retArray = [];
      if (reports)
        retArray = Object.values(reports);
      
      let lastUpdateTS = 0;
      for (let i = 0; i < retArray.length; i++) {
        let currRport = retArray[i];
        if (currRport) {
          state = state.setIn(['inspectorReports', action.projectId, currRport.id], currRport);

          if (lastUpdateTS < (currRport.updatedTS))
            lastUpdateTS = (currRport.updatedTS);
                      
          if (currRport.isDeleted)
            state = state.deleteIn(['inspectorReports', action.projectId, currRport.id]);
        }
      }

      if (lastUpdateTS)
        state = state.setIn(['inspectorReportsLastUpdateTS', action.projectId], lastUpdateTS);

      return state;
    }
  }

  return state;
}
