import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { startLoading } from "../app/actions";
import companiesMessages from "./companiesMessages";

import { uploadImage } from "../images/actions";
import { fetchCompanyById } from "./funcs";

export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANY = "GET_COMPANY";
export const END_COMPANIES_LISTENER = "END_COMPANIES_LISTENER";
export const CREATE_NEW_COMPANY = "CREATE_NEW_COMPANY";
export const NEW_COMPANY_CREATION = "NEW_COMPANY_CREATION";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const SET_COMPANY_TYPE = "SET_COMPANY_TYPE";
export const CLEAN_CACHED_COMPANIES = "CLEAN_CACHED_COMPANIES";
export const GET_SIMILAR_NAME_COMPANIES = "GET_SIMILAR_NAME_COMPANIES";
export const SET_COMPANIES_IMAGES = "SET_COMPANIES_IMAGES";

var unsubscribe = {};
const unsubscribeAll = () => {
  if (unsubscribe) {
    Object.values(unsubscribe).forEach((curr) => curr());
    unsubscribe = {};
  }
};

/**
 *
 * @param {{ [companyId: string]: string }} companyIdsMap
 * @returns
 */
export const getCompaniesById = (companyIdsMap) => {
  const getPromise = async () => {
    let arrCompanies = [];

    const companiesIdArr = Object.keys(companyIdsMap || {});
    if (companiesIdArr.length)
      arrCompanies = await Promise.all(
        companiesIdArr.map(
          async (companyId) => await fetchCompanyById(companyId)
        )
      );

    return { companies: arrCompanies };
  };

  return {
    type: GET_COMPANIES,
    payload: getPromise(),
  };
};

export function getCompanyByName(companyName) {
  return ({ firebaseFirestore }) => {
    const getPromise = async () => {
      const companySnapshot = await firebaseFirestore()
        .collection("companies")
        .where("name", "==", companyName)
        .get();

      let company = null;
      if (companySnapshot.docs.length !== 0)
        company = companySnapshot.docs[0].data();

      return { company };
    };

    return {
      type: GET_COMPANY,
      payload: getPromise(),
    };
  };
}

export function startCompaniesListener(projectId) {
  return ({ getState, firebaseFirestore, dispatch }) => {
    const getPromise = async () => {
      unsubscribeAll();

      if (!projectId) projectId = "global";

      const lastClientUpdateOfProject =
        getState().getNested(
          ["companies", "lastClientUpdatePerProject", projectId],
          -1
        ) + 1;

      let query = new firebaseFirestore.FieldPath("updatedTS");
      if (projectId != "global")
        query = new firebaseFirestore.FieldPath(
          "projects",
          projectId,
          "updatedTS"
        );

      unsubscribe[projectId] = firebaseFirestore()
        .collection("companies")
        .where(query, ">", lastClientUpdateOfProject)
        .onSnapshot(function(querySnapshot) {
          let arrCompanies = [];
          querySnapshot.forEach(element => { let currCompany = element.data(); if (_.get(currCompany, 'id')) arrCompanies.push(currCompany)});
          dispatch({ type: GET_COMPANIES, payload: { companies: arrCompanies, projectId }});
        });
    };

    return {
      type: GET_COMPANIES,
      payload: getPromise(),
    };
  };
}

export function endCompaniesListener(projectId) {
  if (!projectId) projectId = "global";

  unsubscribeAll();

  return {
    type: END_COMPANIES_LISTENER,
    payload: { projectId },
  };
}

export function createNewCompany(companyName, companyType, projectId) {
  return ({ firebaseFirestore, dispatch }) => {
    const getPromise = async () => {
      try {
        dispatch(
          startLoading({ title: companiesMessages.createNew, overlay: true })
        );

        if (!projectId) projectId = "global";
        var companySnapshot = await firebaseFirestore()
          .collection("companies")
          .where("name", "==", companyName)
          .get();

        if (companySnapshot.docs.length != 0) {
          var existsCompany = companySnapshot.docs[0].data();
          let compTypes = existsCompany.getNested(
            ["projects", projectId, "types"],
            {}
          );
          if (!compTypes[companyType]) {
            compTypes[companyType] = companyType;
            existsCompany = existsCompany.setNested(
              ["projects", projectId, "types"],
              compTypes
            );
            dispatch(setCompanyType(existsCompany.id, projectId, companyType));
          }
          dispatch({
            type: GET_COMPANIES,
            payload: {
              companies: { [existsCompany.id]: existsCompany },
              projectId,
            },
          });
          return existsCompany.id;
        } else {
          var now = new Date().getTime();
          const companyRef = firebaseFirestore()
            .collection("companies")
            .doc();
          let projectsScope = {};
          if (projectId != "global") {
            let types = {};
            if (companyType) types[companyType] = companyType;
            projectsScope[projectId] = { types, updatedTS: now };
          }
          let company = {
            id: companyRef.id,
            name: companyName,
            trades: {},
            projects: projectsScope,
            updatedTS: now,
          };

          await companyRef.set(company);
          dispatch({
            type: CREATE_NEW_COMPANY,
            payload: { success: true, company, projectId },
          });
          return companyRef.id;
        }
      } catch (error) {
        dispatch({ type: CREATE_NEW_COMPANY, payload: { success: false } });
        console.error(error);
      }
    };

    return {
      type: CREATE_NEW_COMPANY,
      payload: getPromise(),
    };
  };
}

export function setCompanyType(companyId, projectId, companyType) {
  return ({ firebaseFirestore, dispatch }) => {
    const getPromise = async () => {
      try {
        const companyRef = firebaseFirestore()
          .collection("companies")
          .doc(companyId);
        let update = {};
        update["projects." + projectId + ".types"] = {
          [companyType]: companyType,
        };

        await companyRef.update(update);
        return companyRef.id;
      } catch (error) {
        dispatch({ type: SET_COMPANY_TYPE, payload: { success: false } });
        console.error(error);
      }
    };

    return {
      type: SET_COMPANY_TYPE,
      payload: getPromise(),
    };
  };
}

export function setCompaniesImages(companiesImagesMap, imageKey = "logo") {
  return ({ firebaseFirestore, dispatch }) => {
    const getPromise = async () => {
      try {
        let uploadParams = [];
        (companiesImagesMap || {}).loopEach((id, image) =>
          uploadParams.push({ companyId: id, image })
        );
        if (uploadParams.length == 0) return false;

        var batch = firebaseFirestore().batch();
        await Promise.all(
          uploadParams.map(async ({ companyId, image }) => {
            let companyRef = firebaseFirestore()
              .collection("companies")
              .doc(companyId);
            let url = null;
            if (!_.isNil(image))
              url = await uploadImage(
                image,
                `company_${companyId}_${imageKey}`,
                "companies"
              );

            batch.update(companyRef, { [imageKey]: url });
          })
        );
        await batch.commit();
        return true;
      } catch (error) {
        dispatch({ type: SET_COMPANIES_IMAGES, payload: { success: false } });
        console.error(error);
      }
    };

    return {
      type: SET_COMPANY_TYPE,
      payload: getPromise(),
    };
  };
}

export function newCompanyCreation(
  companyName,
  companyTrades,
  companyProjects,
  companyLogo,
  companyDarkLogo
) {
  return ({ platformActions, apiServer }) => {
    const getPromise = async () => {
      var companyParams = {
        name: companyName,
        projects: companyProjects,
        trades: companyTrades,
        logo: companyLogo,
        darkLogo: companyDarkLogo,
      };

      try {
        for (const { img, key } of [
          { img: companyLogo, key: "logo" },
          { img: companyDarkLogo, key: "darkLogo" },
        ]) {
          if (
            !(
              img &&
              img.uri &&
              img.uri.startsWith &&
              !img.uri.startsWith("https://")
            )
          )
            continue;

          const imageUri = await uploadImage(
            img,
            "company_" + companyName + "_" + uuidv4() + "_" + key,
            "companies"
          );
          companyParams[key] = imageUri;
        }

        let resp = await platformActions.net.fetch(apiServer + '/v1/companies', {
          method: 'POST',
          body: JSON.stringify(companyParams)})
        
        var ret = await (resp.getJson());
       return ret;
      } catch (error){
        return { success: false, errorType: error }
      }
    };
    return {
      type: NEW_COMPANY_CREATION,
      payload: getPromise(),
    };
  };
}

export function updateCompany(
  companyId,
  companyName,
  companyTrades,
  companyProjects,
  companyLogo,
  companyDarkLogo
) {
  return ({ platformActions, apiServer }) => {
    const getPromise = async () => {
      let companyParams = {
        name: companyName,
        projects: companyProjects,
        trades: companyTrades,
        logo: companyLogo,
        darkLogo: companyDarkLogo,
      };

      for (const { img, key } of [
        { img: companyLogo, key: "logo" },
        { img: companyDarkLogo, key: "darkLogo" },
      ]) {
        const imgUri = _.get(img, ["uri"], img || ""); //if img is not an object then it's a string
        const isImgUploadedAlready = imgUri.startsWith("https://");
        if (!img || isImgUploadedAlready) continue;

        const uploadedImageUri = await uploadImage(
          { uri: img },
          "company_" + companyId + "_" + key,
          "companies"
        );
        companyParams[key] = uploadedImageUri;
      }

      try {
        let response = await platformActions.net.fetch(
          apiServer + "/v1/companies/" + encodeURIComponent(companyId),
          {
            method: "PUT",
            body: JSON.stringify(companyParams),
          }
        );

        response = await response.getJson();
        return response;
      } catch (error){
        return { success: false, errorType: error }
      }
    };
    return {
      type: UPDATE_COMPANY,
      payload: getPromise(),
    };
  };
}
