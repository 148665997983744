import * as actions from './actions';
import * as lastUpdatesActions from '../lastUpdates/actions';
import _ from 'lodash';
import { Map } from 'immutable';
import { Record } from '../transit';
import { DEFAULT_LAST_UPDATE_TS } from '../app/constants';

const InitialState = Record({
  map: Map(), 
  companiesMap: Map(),
  didLoad: Map(),
  lastUpdated: Map(),
  lastUpdateAvailable: Map(),
  debugConfigurations: Map(),
}, 'configurations');

const initialState = new InitialState;

export default function configurationsReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) 
  {
    case lastUpdatesActions.GET_LAST_UPDATES: {
        if (!action.payload || !action.payload.projects)
          return state;
  
        const { projects } = action.payload;
        Object.keys(projects).forEach(projectId => {
          let TS = projects.getNested([projectId,'configurations','lastUpdateTS'], DEFAULT_LAST_UPDATE_TS);
          let curr = state.getNested(['lastUpdateAvailable', projectId], null)
          if (curr < TS) state = state.setIn(['lastUpdateAvailable', projectId ], TS);
        });
  
        return state;
      }
  
    case actions.GET_CONFIGURATIONS + "_SUCCESS": {

      if (!action.payload?.scopeId)
        return state;

      const { configurations, scopeType, scopeId } = action.payload;
      if (configurations) {
        state = state.setIn([scopeType == 'companies' ? 'companiesMap' : 'map', scopeId], configurations);
      }
      
      state = state.setIn(['didLoad', scopeId], true);
      state = state.setIn(['lastUpdated', scopeId], state.lastUpdateAvailable.get(scopeId, DEFAULT_LAST_UPDATE_TS));
      return state;
    }

    case lastUpdatesActions.GET_SYNCED: {
      const projects = _.get(action, ['payload', 'projects']);
      _.forIn(projects, (project, projectId) => {
        if (project.config || _.isEqual(project.config, state.getNested(['debugConfigurations', projectId])))
          state = Boolean(project.config)
            ? state.setIn(['debugConfigurations', projectId], project.config)
            : state.removeIn(['debugConfigurations', projectId]);
      });
    }
  }

  return state;
}
