import React, { Component } from 'react';
import { connect  } from 'react-redux'; 
import { getViewer } from '../../../common/users/funcs';
import { getPropInstancesPerProject } from '../../../common/propertiesInstances/funcs';
import { getCompanyPropertiesMappings } from '../../../common/propertiesMappings/funcs';
import { getCompanyPropertiesTypesAndSections } from '../../../common/propertiesTypes/funcs';
import { ProjectContext } from '../../../common/projects/contexts';
import * as permissionsFunc from '../../../common/permissions/funcs'
import { cleanDynamicCachedData } from '../../../common/app/actions';
import { getMenus } from '../../../common/app/actions'; 
import { getConfigurations } from '../../../common/configurations/actions';
import { getCompanyEmployees } from '../../../common/employees/funcs';
import _ from 'lodash';
import { DEFAULT_VIEWS_CONFIGURATIONS } from '../../../common/app/constants'
import { CementoRecordObject } from '../../../common/transit';
import { getDefaultCompanyMenus, getProjectCombinedMenus, getDefaultProjectMenus } from '../../../common/menus/funcs';
window.permissionsFunc = permissionsFunc;

class ScopeContextPage extends Component {  
  constructor(props, context) {
    super(props, context);
    this.getProjectProperties = this.getProjectProperties.bind(this);
    this.getProjectCompanies = this.getProjectCompanies.bind(this);
    this.getProjectScopeMembers = this.getProjectScopeMembers.bind(this);
    this.setProjectCombinedMenus = this.setProjectCombinedMenus.bind(this);
    this.buildDefaultProjectMenus = this.buildDefaultProjectMenus.bind(this);
    this.dispatchBasedOnScope = this.dispatchBasedOnScope.bind(this);
    this.getCompanyStateChanges = this.getCompanyStateChanges.bind(this);
    this.getProjectStateChanges = this.getProjectStateChanges.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.criticalCodeValues = {};
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    const { detailedProjects, configurations, companiesConfigurations, children, checklists, checklistItems, trades, titles, projectLokiLoaded, projectReducersLoaded, projects, buildings, floors, units, rtl, loading, selectedProjectId, viewer } = this.props;
    let scope = this.props.getNested(['urlParams', 'scope']);

    let contextState = { 
      rtl,
      selectedProjectId,
      viewer, 
      loading,
      trades, 
      titles, 
      projectLokiLoaded: (scope == 'company' ? true : projectLokiLoaded.get(selectedProjectId)), 
      projectReducersLoaded: (scope == 'company' ? true : projectReducersLoaded.get(selectedProjectId)), 
      projects: (scope == 'company' ? this.state.projects || {} : projects),
      //relevantProjectIds: (scope == 'company' ? Object.keys(this.state.companyProjects || noValue) : [selectedProjectId]),
      detailedProjects: (scope == 'company' ? this.state.detailedProjects || noValue : detailedProjects),
      configurations:(scope == 'company' ? companiesConfigurations || {} : configurations).getNested([selectedProjectId], noValue),
      project: (scope == 'company' ? this.state.projects || {} : projects).getNested([selectedProjectId], arrayNoValue),
      lang: (scope == 'company' ? this.state.projects || {} : projects).getNested([selectedProjectId,'lang'],'en'),
      buildings: buildings.getNested([selectedProjectId], noValue), 
      floors: floors.getNested([selectedProjectId], noValue), 
      units: units.getNested([selectedProjectId], noValue),
    };

    this.setState(contextState, () => {
      this.setComponentData({ firstMount: true }, this.props);
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    const { detailedProjects, configurations, companiesConfigurations, trades, titles, projectLokiLoaded, projectReducersLoaded, projects, buildings, floors, units, rtl, loading, getConfigurations, viewer } = nextProps;

    this.setState({loading});
    
    let newStateChanges = this.dispatchBasedOnScope({ 
      company: () => this.getCompanyStateChanges, 
      project: () => this.getProjectStateChanges, 
      defaultAction: () => () => ({}),
      scope: nextProps.getNested(['urlParams', 'scope'])
    })(props, nextProps);  

    if (newStateChanges.scope != nextProps.getNested(['urlParams', 'scope']))
      newStateChanges.scope = nextProps.getNested(['urlParams', 'scope']);

    const scope = newStateChanges.scope || this.state.scope;
    const selectedProjectId = newStateChanges.selectedProjectId || this.state.selectedProjectId;

    const isCompanyMode = Boolean(scope == 'company');
    const isAdmin = Boolean(nextProps.getNested(['viewer', 'adminMode']) > 0);

    let nextProjects = (isCompanyMode ? newStateChanges.projects || this.state.projects : projects) || {};
        nextProjects = nextProjects.toJS ? nextProjects : Object.values(nextProjects); // On purpose not converting to JS. Using built in methods accordingly below
    let prevProjects = (isCompanyMode ? this.state.projects : props.projects) || {};
        prevProjects = prevProjects.toJS ? prevProjects : Object.values(prevProjects); // On purpose not converting to JS. Using built in methods accordingly below

    /// getting relevant companies configurations
    let prevCompanies = [];
    (prevProjects || []).forEach(p => prevCompanies.push(p.companyId));
    let nextCompanies = [];
    (nextProjects || []).forEach(p => nextCompanies.push(p.companyId));
    let newRelevantCompanies = _.difference(nextCompanies, prevCompanies).filter(_.identity);
    if (newRelevantCompanies.length && !isAdmin)
      _.uniq(newRelevantCompanies).forEach(companyId => getConfigurations('companies', companyId));

    const nextConfigurations = (isCompanyMode ? companiesConfigurations || {} : configurations).getNested([selectedProjectId], noValue)
      // TODO: Make the state and the context the same - no checklist vs permittedChecklist but only 1
      let nextState = { 
        //...this.state,
        rtl,
        trades, 
        titles, 
        projectLokiLoaded: (isCompanyMode ? true : projectLokiLoaded.get(selectedProjectId)),
        projectReducersLoaded: (isCompanyMode ? true : projectReducersLoaded.get(selectedProjectId)),
        projects: nextProjects,
        detailedProjects: (isCompanyMode ? this.state.detailedProjects || noValue : detailedProjects),
        configurations: nextConfigurations,
        viewsConfigurations : nextConfigurations.view || DEFAULT_VIEWS_CONFIGURATIONS,
        project: (detailedProjects || {}).getNested([selectedProjectId], arrayNoValue),
        lang: nextProjects.getNested([selectedProjectId, 'lang'], 'en'),
        buildings: buildings.getNested([selectedProjectId], noValue), 
        floors: floors.getNested([selectedProjectId], noValue), 
        units: units.getNested([selectedProjectId], noValue),
        ...newStateChanges,
        loading
      };

      if (newStateChanges.companyProjects && (newStateChanges.companyProjects != this.state.companyProjects)) {
        nextState.relevantProjectIds = (isCompanyMode ? Object.keys(nextState.companyProjects || noValue) : [selectedProjectId]);
      }

    this.setState(nextState);
  }

  dispatchBasedOnScope({ company, project, defaultAction, scope = this.props.getNested(['urlParams', 'scope']) }) {
    switch (scope) {
      case ('company'):
        return typeof company === 'function' ? company() : company;

      case ('project'):
        return typeof project === 'function' ? project() : project;

      default:
        return typeof defaultAction === 'function' ? defaultAction() : defaultAction;
    }
  }

  /*
  || =========================== ||
  ||      Company scope stuff    ||
  || =========================== ||
  */

  getCompanyStateChanges(props, nextProps) {
    const loadEmployeesAndProjectProperties = false;
    const { getConfigurations } = nextProps;
    let newStateChanges = {};
      
    const companyIdChanged = props.isValDiff(nextProps, ['urlParams', 'selectedCompanyId']);
    const scopeChanged = props.isValDiff(nextProps, ['urlParams', 'scope']);

    // Set viewer
    if (companyIdChanged || props.isValDiff(nextProps, ['viewer']))
      newStateChanges.viewer = nextProps.viewer;

    const nextViewer = newStateChanges.viewer || this.state.viewer || null;
    // Set selectedProjectId // TODO: change project to scope
    if (companyIdChanged || scopeChanged) {
      newStateChanges.menus						  = getDefaultCompanyMenus();
      newStateChanges.selectedProjectId = nextProps.getNested(['urlParams', 'selectedCompanyId']);
      
      if (nextViewer && (!newStateChanges.selectedProjectId || newStateChanges.selectedProjectId === '_')) 
        newStateChanges.selectedProjectId = nextViewer.companyId;
    }

    
    const nextCompanyId = newStateChanges.selectedProjectId || this.state.selectedProjectId || null;

    // Set project and companyProjects // TODO: change project to scope
    if (nextCompanyId && nextProps.getNested(['companies', nextCompanyId]) && (companyIdChanged || props.isValDiff(nextProps, ['companies', nextCompanyId]))) {
      getConfigurations('companies', nextCompanyId);

      newStateChanges.project = this.formatCompanyToLookLikeProject(nextProps.getNested(['companies', nextCompanyId]));
      if (newStateChanges.project) {
        newStateChanges.companyProjects = {};
        Object.entries(newStateChanges.project.projects || {}).forEach(([currProjectId, currProjectInfo]) => {
          if (!currProjectInfo || (currProjectInfo && currProjectInfo.isDeleted) || (nextProps.projects && nextProps.projects.getNested([currProjectId, 'companyId']) !== nextCompanyId)) return;

          newStateChanges.companyProjects[currProjectId] = (nextProps.projects || {}).getNested([currProjectId], currProjectInfo);

          if (!newStateChanges.project.lang) // TODO: find more reliable way to set lang
            newStateChanges.project.lang = (nextProps.projects || {}).getNested([currProjectId, 'lang']);
        });
      }

      if (!newStateChanges.project.lang)
       newStateChanges.project.lang = 'en';
    }

    const nextCompany = newStateChanges.project || this.state.project || null; // TODO: change project to scope
    const nextCompanyProjects = newStateChanges.companyProjects || this.state.companyProjects || null;

    // Set projectCompanies // TODO: change project to scope
    if (companyIdChanged && nextCompanyProjects) { // TODO: add check if project changes
      newStateChanges.projectCompanies = {};
      Object.entries((nextProps.companies || {}).safeToJS()).forEach(([companyId, companyDetails]) => {
        Object.entries(companyDetails.projects || {}).forEach(([projectId, projectDetails]) => {
          if (nextCompanyProjects[projectId] && !projectDetails.isDeleted)
            newStateChanges.projectCompanies[companyId] = companyDetails;
        })
      })
    }
   
    // Set projectPermitted // TODO: change project to scope
    if (companyIdChanged)
      newStateChanges.projectPermitted = true;

    // Set projectLokiLoaded
    if (companyIdChanged)
      newStateChanges.projectLokiLoaded = true;

    // Set projects & detailedProjects
    if (companyIdChanged && nextCompanyProjects) {
      newStateChanges.projects = nextCompanyProjects.setNested([nextCompanyId], nextCompany);
      newStateChanges.detailedProjects = nextCompanyProjects.setNested([nextCompanyId], nextCompany);
    }

    // Set permittedStages & permittedChecklists & permittedChecklistItems
    if (companyIdChanged) {
      newStateChanges.stages = {};
      newStateChanges.checklists = {};
      newStateChanges.checklistItems = {};
    }

    if (companyIdChanged && nextCompanyProjects)
      newStateChanges.projectMembers = this.getCompanyScopeMembers(nextProps, nextCompanyProjects);
       
    // Set companyEmployees
    if (loadEmployeesAndProjectProperties) {
      if ((companyIdChanged || !this.state.companyEmployees) && nextCompanyId)
        getCompanyEmployees(nextCompanyId)
        .then(val => this.setState({employees:val}));

      // Set propertiesSections && propertiesTypes
      if ((companyIdChanged || !this.state.propertiesTypes) && nextCompanyId) { // TODO: add check if changed
        getCompanyPropertiesTypesAndSections(nextCompanyId)
        .then(({ companySubjectSections, companySubjectProperties }) => { this.setState({propertiesSections:companySubjectSections, propertiesTypes: companySubjectProperties}) });
      }

      // Set companyPropertiesMappings
      if ((companyIdChanged || !this.state.propertiesMappings) && nextCompanyId) // TODO: add check if changed
        getCompanyPropertiesMappings(nextCompanyId)
        .then(val => this.setState({propertiesMappings:val}));
        
      // Set propertiesInstancesPerProject && propertiesInstances
      if ((companyIdChanged || !this.state.propertiesInstances) && nextCompanyProjects) { // TODO: add check if changed
        getPropInstancesPerProject(nextCompanyProjects)
        .then(propertiesInstancesPerProject => {
          let propertiesInstances = this.getCompanyPropInstances(propertiesInstancesPerProject);
          this.setState({propertiesInstancesPerProject, propertiesInstances})
        });
      }
    } else {
      newStateChanges = {...newStateChanges, propertiesInstancesPerProject: {}, propertiesInstances: {}, propertiesMappings: {}, propertiesSections: {}, propertiesTypes: {}, employees: {}}
    }

    return newStateChanges;
  }

  getCompanyPropInstances(propInstancesPerProject) {
    let companyPropInstances = {};

    Object.values(propInstancesPerProject).forEach(projectSubjects => {
      Object.entries(projectSubjects).forEach(([subjectName, subjectProps]) => {
        companyPropInstances[subjectName] = { ...(companyPropInstances[subjectName] || {}), ...subjectProps }
      });
    });

    return companyPropInstances;
  }


  formatCompanyToLookLikeProject(company) {
    const { id, name, logo, darkLogo, ...restCompany } = company.toJS ? company.toJS() : company;
    return { 
      ...restCompany,
      id,
      companyId: id,
      title: name,
      images: {
        main: logo,
        secondary: darkLogo, // FIXME: is it secondary?
      },
    };
  }

  /*
  || =========================== ||
  ||      Project scope stuff    ||
  || =========================== ||
  */

  getProjectStateChanges(props, nextProps) {
    const { viewer, currProjectId, cleanDynamicCachedData } = props;
    const { adminUsers } = nextProps;
    let nextState = {};
    let difProject = currProjectId != nextProps.currProjectId;
    let nextProjectId = nextProps.currProjectId;

    // MUST STAY AS FIRST CONDITION SO OTHERS CAN RELAY ON nextState.viewer
    if (difProject || viewer != nextProps.viewer) {
      nextState.viewer = getViewer(nextProjectId);
      let viewerGroupsMap = {};
      (nextState.getNested(['viewer','groups'], [])).forEach(g => viewerGroupsMap[g] = true);
      let isCementoTeamViewer = nextState.viewer && adminUsers && adminUsers.getNested([nextState.viewer.id])
      nextState.projectPermitted = isCementoTeamViewer || viewerGroupsMap['0001'] || viewerGroupsMap['0010'] || viewerGroupsMap['0004'];
    }
  
    let projectViewer = nextState.viewer || this.state.viewer;

    if (difProject)
      nextState.selectedProjectId = nextProjectId;
    
    let viewerPermsChanged = false;    
    if (permissionsFunc.isViewerPermissionsChanged(viewer, nextProps.viewer, props.companies, nextProps.companies)) {
      cleanDynamicCachedData(); // TODO: Clean only relevant project data
      viewerPermsChanged = true;
    }

    if (difProject || props.isValDiff(nextProps, ['formsAggregatedValuesPerProject', nextProjectId])) {
      nextState.aggregatedValues = Object.assign(
        {},
        this.state.aggregatedValues,
        nextState.aggregatedValues,
        { ['formsInfo']: nextProps.getNested(['formsAggregatedValuesPerProject', nextProjectId], {}),
      });
    }

    if (difProject || props.isValDiff(nextProps, ['companies'])) {
      nextState.projectCompanies = this.getProjectCompanies(nextProps, nextProjectId);
    }

    if (difProject || props.isValDiff(nextProps, ['detailedProjects', nextProjectId, 'members']) ||  props.isValDiff(nextProps, ['members']))
      nextState.projectMembers = this.getProjectScopeMembers(nextProps, nextProjectId);

    if (difProject || viewerPermsChanged || props.isValDiff(nextProps, ['stages', nextProjectId])){
      // TODO: Add permissions to backend
      // nextState.stages = permissionsFunc.getFilteredMap(projectViewer, nextProjectId, 'stages', 'read', nextProps.getNested(['stages', nextProjectId], {}), 'stages', (projectViewer||{}).adminMode).permitted;
      const permittedStages = nextProps.getNested(['stages', nextProjectId], {});
      nextState.stages = Object.entries(permittedStages).reduce((acc, [id, stage]) => _.set(acc, id, new CementoRecordObject(stage)), {}); 
    }

    if (difProject || viewerPermsChanged || props.isValDiff(nextProps, ['checklists', nextProjectId])) {
      const permittedChecklists = permissionsFunc.getFilteredMap(projectViewer, nextProjectId, 'checklists', 'read', nextProps.getNested(['checklists', nextProjectId], {}), 'checklist', (projectViewer||{}).adminMode).permitted;
      nextState.checklists = Object.entries(permittedChecklists).reduce((acc, [id, checklist]) => _.set(acc, id, new CementoRecordObject(checklist)), {}); 
    }
    
    if (difProject || viewerPermsChanged || props.isValDiff(nextProps, ['checklistItems', nextProjectId])) {
      const permittedChecklistItems = permissionsFunc.getFilteredMap(projectViewer, nextProjectId, 'checklistItems', 'read', nextProps.getNested(['checklistItems', nextProjectId], {}), 'checklistItem', (projectViewer||{}).adminMode).permitted;
      nextState.checklistItems = Object.entries(permittedChecklistItems).reduce((acc, [id, checklistItem]) => _.set(acc, id, new CementoRecordObject(checklistItem)), {}); 
    }

    if (difProject || nextState.viewer || viewerPermsChanged || 
        props.isValDiff(nextProps, ['propertiesProject', nextProjectId]) ||
        props.isValDiff(nextProps, ['sectionsProject', nextProjectId]) || 
      props.isValDiff(nextProps, ['propertiesMappings', nextProjectId])) {
      let result = this.getProjectProperties(nextProps, nextProjectId, nextState.viewer || this.state.viewer);
      nextState.propertiesSections = {};
      Object.entries(result.propertiesSections || {}).forEach(([sectionType, sections]) => {
        nextState.propertiesSections[sectionType] = permissionsFunc.getFilteredMap(projectViewer, nextProjectId, 'propertiesSections', 'read', sections, null, (projectViewer || {}).adminMode).permitted;
      });

      nextState.propertiesTypes = {};
      Object.entries(result.propertiesTypes || {}).forEach(([propType, props]) => {
        if (!nextState.propertiesTypes[propType])
          nextState.propertiesTypes[propType] = {};

        const permittedSectionIds = Object.keys(nextState.propertiesSections[propType] || {});
        Object.entries(props).forEach(([propId, prop]) => {
          if (Boolean(prop.sectionId) && !permittedSectionIds.includes(prop.sectionId)) return;
          nextState.propertiesTypes[propType][propId] = prop;
        });
      });

      nextState.propertiesMappings = result.propertiesMappings;
    }

    if (difProject || nextState.propertiesTypes) { // With the new Array and complex types, some of the analytics menu items are generated from propertiesTypes
      const defaultMenu = this.buildDefaultProjectMenus(Object.assign({}, this.state, nextState));
      nextState.menus = defaultMenu;
      this.setProjectCombinedMenus(nextProjectId, defaultMenu);
    }
  
    return nextState;
  }
  
  buildDefaultProjectMenus(nextState) {
    nextState = nextState || this.state;
    const formsInfoPropTypes = _.get(nextState, ['propertiesTypes', 'formsInfo']);

    return getDefaultProjectMenus({ formsInfoPropTypes });
  }

  async setProjectCombinedMenus(projectId, defaultProjectMenu) {
    const { getMenus } = this.props;
    
    let { menus } = (await getMenus(projectId) || {});
    
    const combinedMenu = getProjectCombinedMenus({ menus, defaultProjectMenu })

    // Check to avoid overwriting populated menu because of async actions
    const formsOptionsInState = _.get(this.state, ['menus', 'forms', 'analytics', 'options']);
    const currFormsOptions = _.get(combinedMenu, ['forms', 'analytics', 'options']);
    if (projectId !== _.get(this.state, ['selectedProjectId']) || currFormsOptions.length >= formsOptionsInState.length)
      this.setState({ menus: combinedMenu });
  }

  getCompanyScopeMembers(nextProps, companyProjects) {
    let companyMembers = {};

    Object.keys(companyProjects).forEach(projectId => {
      const projectMembers = this.getProjectScopeMembers(nextProps, projectId);
      companyMembers = { ...companyMembers, ...projectMembers };
    });

    return companyMembers;
  }

  getProjectScopeMembers(nextProps, nextProjectId) {
    let projectMembers = {};
    let membersIds = Object.keys(nextProps.detailedProjects.getNested([nextProjectId, 'members'], {}));
    membersIds.forEach(id => {
      let curr = nextProps.getNested(['members', id]);
      if (curr) {
        let member = curr.toJS ? curr.toJS() : curr;
        let projectScope = member.getNested(['projects', nextProjectId]);
        Object.keys(projectScope || {}).forEach(key => member[key] = projectScope[key]);
        projectMembers[id] = member;
      }
    });

    return projectMembers;
  }

  getProjectCompanies(nextProps, nextProjectId) {
    let projectCompanies = {};
    if (!nextProjectId)
      return projectCompanies;
    nextProps.companies.loopEach((id, curr) => {
      let projectScope = curr.getNested(['projects', nextProjectId]);
      if (projectScope && !projectScope.isDeleted) {
        let comp = curr.toJS ? curr.toJS() : curr;  
        Object.keys(projectScope || {}).forEach(key => comp[key] = projectScope[key]);
        projectCompanies[comp.id] = comp;
      }
    })
    return projectCompanies;
  }

  getProjectProperties(nextProps, nextProjectId, viewer) {
    let propsCombineMap = {};
    let sectionsCombineMap = {};
    let propertiesMappings = {};
    let fillMapBySubjectName = (subjectName, list, mapToFill) => { if (!mapToFill[subjectName]) mapToFill[subjectName] = {}; (list || {}).loopEach((id,val) => mapToFill[subjectName][id] = new CementoRecordObject(val)) }
    nextProps.getNested(['propertiesProject', nextProjectId], {}).loopEach((subjectName, list) => fillMapBySubjectName(subjectName, list, propsCombineMap))
    nextProps.getNested(['sectionsProject', nextProjectId], {}).loopEach((subjectName, list) => fillMapBySubjectName(subjectName, list, sectionsCombineMap))
    propsCombineMap.loopEach((subjectName, properties) => propsCombineMap[subjectName] = permissionsFunc.getFilteredMap(viewer, nextProjectId, 'propertiesTypes', 'read', properties, 'property').permitted );
    propertiesMappings = nextProps.getNested(['propertiesMappings', nextProjectId], {});
    return { propertiesSections: sectionsCombineMap, propertiesTypes: propsCombineMap, propertiesMappings }
  }
  
  render() {
    const { detailedProjects, configurations, companiesConfigurations, children, checklists, checklistItems, trades, titles, projectLokiLoaded, projectReducersLoaded, projects, buildings, floors, units, rtl, loading } = this.props;
      

    // TODO: Remove all the scope == 'company' and handle all in the relevant functions above
    return (
      <ProjectContext.Provider value={this.state}>
        {children}
      </ProjectContext.Provider>
    );
  }
}
const noValue = {};
const arrayNoValue = [];
ScopeContextPage = connect(state => ({
  currProjectId: state.ui.currProject,
  viewer: state.users.viewer,
  loading: state.app.loading,
  rtl: state.app.rtl,
  auth: state.auth,
  users: state.users,
  companies: state.companies.map,
  members: state.members.map,
  urlParams: state.ui.urlParams,
  configurations: state.configurations.map,
  companiesConfigurations: state.configurations.companiesMap,  
  projectReducersLoaded: state.projects.projectReducersLoaded,
  projectLokiLoaded: state.projects.projectLokiLoaded,
  projects: state.projects.map,
  detailedProjects: state.projects.detailsMap,
  trades: state.trades.map,
  titles: state.titles.map,
  stages: state.stages.map,
  checklists: state.checklists.map,
  checklistItems: state.checklistItems.map,
  sectionsProject: state.propertiesTypes.projectSections,
  propertiesMappings: state.propertiesMappings.map,
  propertiesProject: state.propertiesTypes.projectProperties,
  buildings: state.buildings.map,
  floors: state.floors.map,
  units: state.units.map,
  formsAggregatedValuesPerProject: state.forms.aggregated,
  adminUsers: state.quasiStatics.adminUsers,
}), { cleanDynamicCachedData, getMenus, getConfigurations })(ScopeContextPage);

export default ScopeContextPage;
