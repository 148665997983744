import * as appActions from '../app/actions';
import * as actions from './actions';
import * as lastUpdatesActions from '../lastUpdates/actions';
import * as projectsAction from '../projects/actions'
import { REPLACE_USER } from '../auth/actions';

import { OrderedMap, Map } from 'immutable';
import { Record } from '../transit';

const InitialState = Record({
  didLoad: OrderedMap(),
  lastRevoked: Map(),
  lastRevokeAvailable: Map(),
  eventsDidLoad: Map(),
}, 'equipment');

const initialState = new InitialState;

export default function equipmentReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) { 
    case lastUpdatesActions.GET_LAST_UPDATES: {
      if (!action.payload || !action.payload.projects)
        return state;

      const { projects } = action.payload;
      Object.keys(projects).forEach(projectId => {
        let TS = projects.getNested([projectId,'equipment','lastUpdateTS'], 1);
        let curr = state.getNested(['lastRevokeAvailable', projectId], null);
        if (curr < TS)
          state = state.setIn(['lastRevokeAvailable', projectId], TS);
      });

      return state;
    }

    case actions.GET_EQUIPMENT: 
      return state.setIn(['didLoad', action.getNested(['payload','projectId'])], false);
    
    case projectsAction.LOKI_DID_LOAD: {
      if (action.getNested(['payload','loadedCollections','equipment']))
        state = state.setIn(['didLoad', action.getNested(['payload','projectId'])], action.getNested(['payload','status']));
      return state;
    }

    case actions.EQUIPMENT_DONE_LOADING: {
      if (!action.payload)
        return state;
      
      const { scopeId } = action.payload;
      state = state.setIn(['didLoad', scopeId], true);
      state = state.setIn(['lastRevoked', scopeId], state.getNested(['lastRevokeAvailable', scopeId]) || state.getNested(['lastRevoked', scopeId]) || 1); 

      return state;
    }
    

    case appActions.CLEAN_DYNAMIC_CACHE_DATA + "_SUCCESS":
    case REPLACE_USER + "_SUCCESS":
    case actions.CLEAN_CACHED_EQUIPMENT:
    case appActions.CLEAN_CACHE: {      
      //let lastRevokeAvailable = state.getNested(['lastRevokeAvailable'], {});  
      //let lastRevoked = state.getNested(['lastRevoked'], {});
      state = initialState;
      //lastRevoked.loopEach((projectId, last) => last ? state = state.setIn(['lastRevokeAvailable', projectId], last + 1) : 0)
      //lastRevokeAvailable.loopEach((projectId, last) => last ? state = state.setIn(['lastRevokeAvailable', projectId], last + 1) : 0)
      return state;
    }

    case actions.END_EQUIPMENT_LISTENER: {
      if (action.payload && action.payload.projectId)
        state = state.setIn(['didLoad', action.payload.projectId], false);
      return state;
    }
  }

  return state;
}