import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import { PROJECT_TYPE_COMPLEX_BUILDINGS } from "../../../common/projects/actions";
import * as issueStates from "../../../common/issues/issueStates.js";
import { lokiInstance } from "../../../common/configureMiddleware";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import {
  LabelImportantRounded,
  CloseRounded,
  CheckRounded,
} from "@material-ui/icons";
import * as permissionsFunc from "../../../common/permissions/funcs";
import {
  setDefaultTagSelected,
  isPostInValid,
} from "../../../common/posts/funcs";
import { startToast } from "../../../common/app/actions";
import { onDraftModeChange, draftValidator } from "../../../common/ui/actions";
import User from "../../components/CementoComponents/User";
import { GridContainer, GridItem } from "../../components";
import DisplayName from "../../components/CementoComponents/DisplayName";
import Image from "../../components/CementoComponents/Image";
import Comment from "./Comment";
import {
  endCommentsListener,
  getComments,
  deleteComment,
  createLocalComment,
  uploadNewPostComment,
} from "../../../common/comments/actions";
import {
  updateIssueState,
  updateIssueAssignTo,
  areAllOtherConnectedPosts_withWantedStatus,
  uploadNewPost,
  createLocalPost,
  getNewPostId,
  exportPostsPDF,
  deletePost,
} from "../../../common/posts/actions";
import { updateChecklistItemInstanceStatus } from "../../../common/checklistItemsInstances/actions";
import * as cliStatus from "../../../common/checklistItemsInstances/clItemInstanceStatus.js";
import { uploadImage } from "../../../common/images/actions";
import propertiesMessages from "../../../common/propertiesTypes/propertiesMessages";
import safetyMessages from "../../../common/safety/safetyMessages";
import issuesMessages from "../../../common/issues/issuesMessages";
import postsMessages from "../../../common/posts/postsMessages";
import systemMessages from "../../../common/app/systemMessages";
import newPostMessages from "../../../common/posts/newPostMessages";
import checklistItemMessages from "../../../common/checklistItems/checklistItemMessages";
import Text from "../../components/CementoComponents/Text";
import theme from "../../assets/css/theme";
import trash from "../../assets/img/icons/trash.png";
import NewTaskIcon from "../../assets/img/tasks/newtask.png";
import CommentIcon from "../../assets/img/tasks/comment.png";
import StatusUpdatedIcon from "../../assets/img/tasks/statusUpdated.png";
import actionClose from "../../assets/img/tasks/actionClose.png";
import actionComment from "../../assets/img/tasks/actionComment.png";
import actionReassign from "../../assets/img/tasks/actionReassign.png";
import actionReopen from "../../assets/img/tasks/actionReopen.png";
import PhotoIcon from "../../assets/img/tasks/photo.png";
import Date from "../../components/CementoComponents/Date";
import InputField from "../../components/CementoComponents/InputField";
import SelectableUsers from "../../components/CementoComponents/SelectableUsers";
import ImageCarousel from "../../components/CementoComponents/ImageCarousel";
import MenuScrollbar from "../../components/CementoComponents/MenuScrollbar";
import PdfIcon from "../../assets/img/icons/pdf.png";
import { getLocationTitle } from "../Locations/funcs";
import _ from "lodash";
import ObjectPropertiesPage from "../Properties/ObjectPropertiesPage";
import StandardInput from "../../components/CementoComponents/StandardInput";
import { getRelevantPropertyIds } from "../../../common/propertiesInstances/funcs";
import { SAFETY_TRADE_ID } from "../../../common/app/constants";
import { getFullLocationDetailsByIdNoProps } from "../../../common/locations/func";
import postsMenuMessages from '../../../common/posts/postsMenuMessages';
import usePosts from '../../../common/posts/hooks/usePosts';

const POST_INFO_SUBJECT_NAME = "postsInfo";

const severityColors = [
  { val: 3, color: "rgba(255,0,0,1)" },
  { val: 2, color: "rgba(255,179,0, 1)" },
  { val: 1, color: "rgba(129, 199, 132, 1)" },
];
const POST_DEFAULT_SEVERITY = 2;
const storyTypes = {
  new: 1,
  comment: 2,
  commentWithImage: 3,
  statusUpdated: 4,
};
const storyTypesImages = {
  1: NewTaskIcon,
  2: CommentIcon,
  3: PhotoIcon,
  4: StatusUpdatedIcon,
};
class Post extends React.PureComponent {
  constructor(props) {
    super(props);
    this.lokiPostsListener = this.lokiPostsListener.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.onEnterEditMode = this.onEnterEditMode.bind(this)

    let mode = props.getNested(["post", "mode"]);
    this.state = { mode, post: props.post };
  }

  componentWillUnmount() {
    this.lokiPosts.cementoOff("postPageListener");
  }

  UNSAFE_componentWillMount() {
    this.lokiPosts = lokiInstance.getCollection("posts");
    this.lokiPosts.cementoOn("postPageListener", this.lokiPostsListener);
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    const { changeEditMode = () => {}, onClose } = nextProps;

    let newStateChanges = {};
    let newSideCardEditMode = null;
    let newSideCardCreationMode = null;

    if (props.editMode !== nextProps.editMode)
      newStateChanges.mode = nextProps.editMode ? "draft" : null;

    if (
      props.isValDiff(nextProps, ["post", "id"]) ||
      props.isValDiff(nextProps, ["post"])
    ) {
      let nextPost = nextProps.post;

      if (!nextProps.getNested(['post', 'id']))
        newSideCardCreationMode = true;
      
      newStateChanges.post = nextPost;
      if (nextPost && nextPost.mode === "draft") {
        newSideCardEditMode = true;
        newStateChanges.mode = "draft";
      } else {
        newStateChanges.mode = null;
        if (nextProps.editMode) newSideCardEditMode = false;
      }
    }

    if (Object.keys(newStateChanges).length)
      this.setState(newStateChanges, () => {
        if (
          (nextProps.editMode !== newSideCardEditMode &&
            newSideCardEditMode !== null) ||
          newSideCardCreationMode !== null
        )
          changeEditMode(newSideCardEditMode, Boolean(newSideCardCreationMode));
      });
  }

  lokiPostsListener(collectionName) {
    const { mode, post } = this.state;
    if (collectionName == 'posts' && mode != "draft" && post.id)
      this.setState({ post });
  }

  onEnterEditMode(post) {
    this.setState({ mode: "draft", post });
  }

  render() {
    const {
      onSelect,
      onClose,
      onPostSave,
      hideNavigation,
      onCardLoad,
      changeEditMode,
      isSafety,
    } = this.props;
    const { mode, post } = this.state;

    if (!post) return null;

    if (mode == "draft") {
      return (
        <NewPostPage
          key={`post_draft_${post.id || "new"}`}
          onCardLoad={onCardLoad}
          hideNavigation={hideNavigation}
          post={post}
          onCancel={() => {
            this.setState({ mode: null });
            changeEditMode(false);
            if (!post || !post.id) {
              onSelect && onSelect(null);
            }
          }}
          onDone={(newPost) => {
            this.setState({ mode: null, post: newPost }); 
          }}
          onPostSave={onPostSave}
          isSafety={isSafety}
          onEnterEditMode={this.onEnterEditMode}
        />
      );
    } else
      return (
        <PostViewer
          onCardLoad={onCardLoad}
          onEnterEditMode={this.onEnterEditMode}
          {...this.props}
          post={post}
        />
      );
  }
}

export default Post = connectContext(ProjectContext.Consumer)(Post);

// const enhance = compose(
//   connectContext(ProjectContext.Consumer),
//   connect(state => ({}), { draftValidator })
// );

// export default Post = enhance(Post);

class PostViewer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.getRef = this.getRef.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onReassign = this.onReassign.bind(this);
    this.onExportPost = this.onExportPost.bind(this);
    this.onUpdateState = this.onUpdateState.bind(this);
    this.handleReassign = this.handleReassign.bind(this);
    this.createPostStory = this.createPostStory.bind(this);
    this.openImagesModal = this.openImagesModal.bind(this);
    this.closeImagesModal = this.closeImagesModal.bind(this);
    this.createPostButtons = this.createPostButtons.bind(this);
    this.handleUpdateState = this.handleUpdateState.bind(this);
    this.onSingleAction = this.onSingleAction.bind(this);
    this.getPermissions = this.getPermissions.bind(this);
    this.getImagesArray = this.getImagesArray.bind(this);

    this.handleCommentCreate = this.handleCommentCreate.bind(this);
    this.onCloseSingleAction = this.onCloseSingleAction.bind(this);
    this.onPostSelectedOrUnselected =
      this.onPostSelectedOrUnselected.bind(this);
    this.onSelectableReassignChange =
      this.onSelectableReassignChange.bind(this);

    let images = this.getImagesArray(props);

    this.defaultFooterFlags = {
      commentMode: false,
      reassignMode: false,
      allButtonsMode: true,
      selectedReassign: null,
    };
    this.state = {
      images,
      allButtonsMode: true,
      comments: [],
      ...this.defaultFooterFlags,
      pdfDisplay: null,
    };
  }

  componentWillUnmount() {
    const { onDraftModeChange, post, endCommentsListener, selectedProjectId, onUnmount } =
      this.props;
    if (endCommentsListener) endCommentsListener(selectedProjectId, post.id);
  }

  UNSAFE_componentWillMount() {
    this.lokiPropertyInstances =
      lokiInstance.getCollection("propertyInstances");
    this.setComponentData({ firstMount: true }, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isValDiff(this.props, [
        "comments",
        this.props.selectedProjectId,
        this.props.post.id,
      ])
    ) {
      let oldCommentsCount = 0;
      let newCommentsCount = 0;
      prevProps
        .getNested(
          ["comments", this.props.selectedProjectId, this.props.post.id],
          {}
        )
        .loopEach((id) => oldCommentsCount++);
      this.props
        .getNested(
          ["comments", this.props.selectedProjectId, this.props.post.id],
          {}
        )
        .loopEach((id) => newCommentsCount++);

      if (this.state.shouldScrollToStoryComment == newCommentsCount)
        this.postStoryRef.scrollTop = this.postStoryRef.scrollHeight;
    }
  }

  setComponentData(props, nextProps) {
    const {
      onCardLoad,
      selectedProjectId,
      viewer,
      onEnterEditMode,
      deletePost,
      onClose,
    } = this.props;
    let newStateChanges = {};

    const isPostDiff = !_.isEqual(props.post, nextProps.post);
    if (isPostDiff) {
      newStateChanges.images = this.getImagesArray(nextProps);
      newStateChanges.permissions = this.getPermissions(nextProps);
      newStateChanges.buttons = this.createPostButtons(
        nextProps,
        newStateChanges.permissions
      );

      let locationTitles = getLocationTitle(
        nextProps.getNested(["buildings"]),
        nextProps.getNested(["floors"]),
        nextProps.getNested(["units"]),
        nextProps.post.getNested(["location", "building", "id"]),
        nextProps.post.getNested(["location", "floor", "id"]),
        nextProps.post.getNested(["location", "unit", "id"]),
        null,
        nextProps.intl,
        nextProps.projects.getNested([selectedProjectId, "type"]) !=
          PROJECT_TYPE_COMPLEX_BUILDINGS
      );

      let headerOptions = {
        title: Object.values(locationTitles).join(" / "),
        postState: nextProps.getNested(["post", "issueState"], "documentation"),
        tradeIds: [nextProps.getNested(["post", "trade", "id"])],
        editable: Boolean(
          newStateChanges.getNested(["permissions", "editPostPermission"])
        ),
        onDelete: newStateChanges.getNested([
          "permissions",
          "deletePostPermission",
        ])
          ? () => {
              deletePost(nextProps.post, selectedProjectId);
              onClose();
            }
          : null,
        onPrint: () => this.onExportPost(nextProps.post),
        customIcons: [
          Boolean(!nextProps.post.isIssue && viewer.adminMode == 1) && {
            component: (
              <div
                style={{
                  fontSize: 11,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyItems: "center",
                  lineHeight: 0.73,
                }}
              >
                <span>Convert</span>
                <span>to</span>
                <span>task</span>
              </div>
            ),
            onClick: () =>
              onEnterEditMode({
                ...nextProps.post,
                isIssue: true,
                issueState: 300,
              }),
          },
        ],
      };
      if (onCardLoad) onCardLoad(headerOptions, null, null, null, null, null);
    }

    if (
      props.getNested([
        "comments",
        nextProps.selectedProjectId,
        nextProps.post.id,
      ]) !=
      nextProps.getNested([
        "comments",
        nextProps.selectedProjectId,
        nextProps.post.id,
      ])
    ) {
      newStateChanges.comments = [];
      nextProps
        .getNested(
          ["comments", nextProps.selectedProjectId, nextProps.post.id],
          {}
        )
        .loopEach((id, value) => newStateChanges.comments.push(value));
    }

    if (props.isValDiff(nextProps, ["post", "id"])) {
      this.onPostSelectedOrUnselected(props, nextProps); // Only for the detailed post
      newStateChanges = Object.assign(newStateChanges, this.defaultFooterFlags);
    }

    if (
      props.style != nextProps.style ||
      isPostDiff ||
      props.isValDiff(nextProps, [
        "comments",
        nextProps.selectedProjectId,
        nextProps.post.id,
      ])
    )
      newStateChanges.story = this.createPostStory(nextProps);

    if (
      props.firstMount ||
      props.isValDiff(nextProps, ["propertiesTypes", POST_INFO_SUBJECT_NAME]) ||
      props.isValDiff(nextProps, ["propertiesMappings", POST_INFO_SUBJECT_NAME]) ||
      props.isValDiff(nextProps, ['post', 'id']) ||
      props.isValDiff(nextProps, ['post', 'instances'])
    )
      newStateChanges.relevantPostProperties =
        this.getRelevantPostProperties(nextProps);

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  getRelevantPostProperties = (props) => {
    props = props || this.props;

    const isSafety = _.get(props, ["post", "trade", "id"]) === SAFETY_TRADE_ID;
    const isIssue = _.get(props, ["post", "isIssue"]);

    if (isSafety || !isIssue) return [];

    const postGroupId =
      _.chain(props)
        .get(["post", "instances", "groups", "data"])
        .values()
        .first()
        .value() ||
      _.chain(props)
        .get(["propertiesTypes", POST_INFO_SUBJECT_NAME, "groups", "values"])
        .first()
        .get("id")
        .value();
    const postInstances = _.get(props, ["post", "instances"]);
    const relevantPropIds = getRelevantPropertyIds(
      _.get(props, ["propertiesMappings"]),
      POST_INFO_SUBJECT_NAME,
      postGroupId,
      postInstances
    );

    return relevantPropIds
      .map((propId) =>
        _.get(props, ["propertiesTypes", POST_INFO_SUBJECT_NAME, propId])
      )
      .filter(Boolean);
  };

  createPostStory(nextProps) {
    let story = [];
    story.push({
      id: storyTypes.new,
      type: storyTypes.new,
      title: <Text>{issuesMessages.story.created}</Text>,
      subTitle: (
        <div style={{ display: "flex" }}>
          <DisplayName
            style={{
              ...theme.defaultFont,
              fontSize: 12,
              fontWeight: theme.bold,
            }}
            message={issuesMessages.story.by}
            messageValueProperty={"name"}
            userId={nextProps.getNested(["post", "owner", "id"])}
          />
          <Date style={{ paddingLeft: 5, paddingRight: 5 }} format={{ detailed: true }}>
            {nextProps.getNested(["post", "createdAt"])}
          </Date>
        </div>
      ),
      content: (
        <Text style={{ maxWidth: "40vw" }}>
          {nextProps.getNested(["post", "title"])}
        </Text>
      ),
      imageURIs: this.getImagesArray(nextProps),
    });

    let commentsArray = nextProps.getNested(
      ["comments", nextProps.selectedProjectId, nextProps.post.id],
      []
    );
    if (commentsArray.valueSeq)
      commentsArray = commentsArray.valueSeq().toArray();
    commentsArray = commentsArray.sort((a, b) => a.createdAt - b.createdAt);
    commentsArray.loopEach((index, comment) => {
      let currStory = {
        id: comment.id,
        createdAt: comment.createdAt,
        type: storyTypes.statusUpdated,
        subTitle: (
          <div style={{ display: "flex" }}>
            <DisplayName
              style={{
                ...theme.defaultFont,
                fontSize: 12,
                fontWeight: theme.bold,
              }}
              message={issuesMessages.story.by}
              messageValueProperty={"name"}
              userId={comment.getNested(["owner", "id"])}
            />
            <Date
              style={{ paddingLeft: 5, paddingRight: 5 }}
              showDetailed={true}
            >
              {comment.createdAt}
            </Date>
          </div>
        ),
      };
      if (comment.type == "status" && comment.owner) {
        if (comment.data.status == issueStates.ISSUE_STATE_RESOLVED)
          currStory.title = <Text>{issuesMessages.story.resolved}</Text>;
        else if (comment.data.status == issueStates.ISSUE_STATE_OPENED)
          currStory.title = <Text>{issuesMessages.story.reopend}</Text>;
        else if (comment.data.status == issueStates.ISSUE_STATE_CLOSED)
          currStory.title = <Text>{issuesMessages.story.closed}</Text>;
      } else if (
        (comment.type == "forward" || comment.type == "forwardIssue") &&
        comment.getNested(["data", "newAssignTo", "id"])
      )
        currStory.title = (
          <DisplayName
            messageValueProperty={"name"}
            userId={comment.data.newAssignTo.id}
            message={issuesMessages.story.reassigned}
          />
        );
      else if (comment.content || comment.images) {
        if (comment.images) {
          currStory.imageURIs = [];
          comment.images.loopEach((k, v) => {
            if (v && v.uri) currStory.imageURIs.push(v.uri);
          });
          if (currStory.imageURIs.length == 0) currStory.imageURIs = null;
        }
        currStory.type = comment.images
          ? storyTypes.commentWithImage
          : storyTypes.comment;
        currStory.title = currStory.imageURIs ? (
          <Text values={{ count: currStory.imageURIs.length }}>
            {issuesMessages.story.uploaded}
          </Text>
        ) : (
          <Text>{issuesMessages.story.comment}</Text>
        );
        currStory.content = (
          <Text style={{ maxWidth: "40vw" }}>{comment.content}</Text>
        );
      }

      story.push(currStory);
    });

    return story;
  }

  getPermissions(nextProps) {
    const { selectedProjectId, post, viewer } = nextProps;
    
    let editPostPermission = permissionsFunc.isPermitted(viewer, selectedProjectId, 'posts', 'edit', post, 'post');
    let deletePostPermission = viewer && viewer.adminMode == 1;//permissionsFunc.isPermitted(viewer, selectedProjectId, 'posts', 'delete', post, 'post');
    
    if (!post.isIssue)
      return ({editPostPermission, deletePostPermission});

    return ({
      reOpenPermission: permissionsFunc.isPermitted(viewer, selectedProjectId, 'posts', 'reOpen', post, 'post'),
      reAssignPermission: permissionsFunc.isPermitted(viewer, selectedProjectId, 'posts', 'reAssign', post, 'post'),
      openOrClosePermission: permissionsFunc.isPermitted(viewer, selectedProjectId, 'posts', 'openOrClose', post, 'post'),
      resolvePermission: permissionsFunc.isPermitted(viewer, selectedProjectId, 'posts', 'resolve', post, 'post'),
      closePermission: permissionsFunc.isPermitted(viewer, selectedProjectId, 'posts', 'close', post, 'post'),
      editPostPermission,
      deletePostPermission,
    });
  }

  createPostButtons(nextProps, postPermissions) {
    const { post, hideButtons } = nextProps;

    let allActionButtons = {
      comment: {
        label: postsMessages.commentButton,
        func: () => this.onSingleAction("comment"),
        icon: actionComment,
        style: {},
      },
      close: {
        label: issuesMessages.closeButton,
        func: () => {
          this.onUpdateState(issueStates.ISSUE_STATE_CLOSED);
        },
        icon: actionClose,
        style: {},
      },
      reopen: {
        label: issuesMessages.reopenButton,
        func: () => {
          this.onUpdateState(issueStates.ISSUE_STATE_OPENED);
        },
        icon: actionReopen,
        style: {},
      },
      resolve: {
        label: issuesMessages.resolveButton,
        func: () => {
          this.onUpdateState(issueStates.ISSUE_STATE_RESOLVED);
        },
        icon: actionClose,
        style: {},
      },
      reassign: {
        label: issuesMessages.forwardButton,
        func: () => this.onSingleAction("reassign"),
        icon: actionReassign,
        style: {},
      },
    };
    let buttons = [];
    buttons.push(allActionButtons.comment);

    //let postPermissions = this.getPermissions(nextProps);
    if (!hideButtons && post.isIssue) {
      if (!post.issueState || post.issueState == issueStates.ISSUE_STATE_OPENED) {
        if (postPermissions.openOrClosePermission || postPermissions.closePermission)  buttons.push(allActionButtons.close);
        else if (postPermissions.resolvePermission) buttons.push(allActionButtons.resolve);
        if (postPermissions.reAssignPermission)     buttons.push(allActionButtons.reassign);
      }
      else if (post.issueState == issueStates.ISSUE_STATE_RESOLVED) {
        if (postPermissions.openOrClosePermission || postPermissions.closePermission)
          buttons.push(allActionButtons.close);
        if (postPermissions.openOrClosePermission)
          buttons.push(allActionButtons.reopen);
        if (postPermissions.reAssignPermission) 
          buttons.push(allActionButtons.reassign);
      } else if (post.issueState == issueStates.ISSUE_STATE_CLOSED)
        if (postPermissions.reOpenPermission)
          buttons.push(allActionButtons.reopen);
    }

    return buttons;
  }

  getImagesArray(nextProps) {
    let images = [];
    if (nextProps.post) {
      images = Object.values(nextProps.post.images || {})
        .filter(image => !image.isDeleted);
    }
    if (images.length == 0) return null;
    return images.map((curr) => {
      if (curr && curr.uri) return curr.uri;
    });
  }

  openImagesModal(selectedStoryIndex, selectedImageIndex) {
    const { story } = this.state;
    let imagesArray = [];
    let initialIndex = 0;
    (story || []).forEach((currStory, storyIndex) => {
      if (!currStory.imageURIs) return;
      initialIndex +=
        storyIndex < selectedStoryIndex ? currStory.imageURIs.length : 0;
      currStory.imageURIs.forEach((currImage, imageIndex) => {
        imagesArray.push({ description: currStory.content, src: currImage });
        initialIndex +=
          storyIndex == selectedStoryIndex && imageIndex < selectedImageIndex
            ? 1
            : 0;
      });
    });

    this.setState({
      imagesModalObject: { images: imagesArray, selectedIndex: initialIndex },
    });
  }

  closeImagesModal() {
    this.setState({ imagesModalObject: null });
  }

  async handleCommentCreate(comment, parentId) {
    const {
      createLocalComment,
      uploadNewPostComment,
      selectedProjectId,
      viewer,
    } = this.props;
    const { comments } = this.state;
    this.setState({
      commentMode: false,
      shouldScrollToStoryComment: (comments || []).length + 1,
    });
    let imageFile = null;
    let commentImageRef = null;
    if (comment.images) {
      let imageId = Object.keys(comment.images || {})[0];
      commentImageRef = comment.images[imageId];
      imageFile = commentImageRef.data;
      delete commentImageRef.data;
    }

    const response = await createLocalComment(
      viewer,
      selectedProjectId,
      parentId,
      comment,
      !Boolean(imageFile)
    );
    if (!response) return;
    let newComment = response.comment;

    if (response && imageFile) {
      commentImageRef.uri = await uploadImage(
        imageFile,
        selectedProjectId + "/" + newComment.id + "_" + commentImageRef.id,
        "comments"
      );
      delete commentImageRef.uploading;
      newComment.images = {};
      newComment.images[commentImageRef.id] = commentImageRef;
    }

    await uploadNewPostComment(viewer, selectedProjectId, parentId, newComment);
    this.setState({allButtonsMode: true});
  }

  onPostSelectedOrUnselected(props, nextProps) {
    const { endCommentsListener, getComments, selectedProjectId } = nextProps;

    if (props.post && props.post.id)
      endCommentsListener(selectedProjectId, props.post.id);
    if (nextProps.post && nextProps.post.id)
      getComments(selectedProjectId, nextProps.post.id);
  }

  onSelectableReassignChange(selectedItem) {
    this.setState({ selectedReassign: selectedItem.user });
  }

  onReassign() {
    const { post, startToast } = this.props;
    const { selectedReassign, comments } = this.state;
    if (selectedReassign) {
      startToast({
        overlay: true,
        mandatory: true,
        title: issuesMessages.updateAssignee,
        message: issuesMessages.reassignAlertMessage,
        values: { name: selectedReassign.displayName },
        actions: [
          {
            message: systemMessages.yes,
            onClick: () => this.handleReassign(post, selectedReassign),
            color: "success",
          },
          { message: systemMessages.no },
        ],
      });
      this.onCloseSingleAction(null, {
        shouldScrollToStoryComment: (comments || []).length + 1,
      });
    }
  }

  onUpdateState(newState) {
    const { startToast } = this.props;

    let messages = {
      [issueStates.ISSUE_STATE_RESOLVED]: issuesMessages.resolveAlertMessage,
      [issueStates.ISSUE_STATE_CLOSED]: issuesMessages.closeAlertMessage,
      [issueStates.ISSUE_STATE_OPENED]: issuesMessages.reOpenAlertMessage,
    };
    startToast({
      overlay: true,
      mandatory: true,
      title: issuesMessages.updateState,
      message: messages[newState],
      actions: [
        {
          message: systemMessages.yes,
          onClick: () => this.handleUpdateState(newState),
          color: "success",
        },
        { message: systemMessages.no },
      ],
    });
  }

  handleReassign(post, assignTo) {
    const { updateIssueAssignTo, viewer, selectedProjectId } = this.props;
    updateIssueAssignTo(viewer, post, selectedProjectId, assignTo);
  }

  isChecklistInstancePropertiesFulfilled(extraData, checklistInstance) {
    let ids = [];
    let ret = true;
    _.forIn(extraData, (prop) => {
      if (!prop.optional && !prop.readOnly) {
        let id = _.get(checklistInstance, ["extraData", prop.id, "instanceId"]);
        if (!id) ret = false;
        else ids.push({ id });
      }
    });
    let instances = this.lokiPropertyInstances.cementoFind({ $or: ids });
    instances.forEach((propInstance) => {
      let data = propInstance.data;
      if (_.isNil(data) || data == "") ret = false;
    });
    return ret;
  }

  handleUpdateState(newState) {
    const { comments } = this.state;
    const {
      post,
      updateIssueState,
      startToast,
      viewer,
      selectedProjectId,
      areAllOtherConnectedPosts_withWantedStatus,
      updateChecklistItemInstanceStatus,
      checklists,
    } = this.props;
    let { checklistInstances, checklistItems } = this.props;

    updateIssueState(viewer, post, selectedProjectId, newState);

    if (post.checklistItemInstance) {
      let checklistItemInstanceId = _.get(post, [
        "checklistItemInstance",
        "id",
      ]);
      checklistInstances = (
        checklistInstances.toJS
          ? checklistInstances.toJS()
          : { ...checklistInstances }
      )[selectedProjectId];
      let instancesMap = {};
      _.forIn(checklistInstances, (locations) => {
        _.forIn(locations, (checklistInstances) => {
          _.values(checklistInstances).forEach(
            (instance) => (instancesMap[instance.id] = instance)
          );
        });
      });

      let checklistItemInstance = _.get(instancesMap, [
        checklistItemInstanceId,
      ]);
      let checklistItemId = _.get(checklistItemInstance, ["checklistItemId"]);
      let extraData = _.get(checklistItems, [checklistItemId, "extraData"]);
      if (extraData) {
        let isFulfilled = this.isChecklistInstancePropertiesFulfilled(
          extraData,
          checklistItemInstance
        );
        if (!isFulfilled) {
          startToast({
            title:
              checklistItemMessages.extraData.missingExtraDataAlertSubTitle,
            type: "error",
          });
          return;
        }
      }

      let { allOthersWithWantedState, myDefaultActionStatus } =
        areAllOtherConnectedPosts_withWantedStatus(
          post.checklistItemInstance.id,
          post.id,
          selectedProjectId,
          newState,
          viewer
        ).payload;

      let issueStateToChecklistStatusMap = {
        [issueStates.ISSUE_STATE_CLOSED]: myDefaultActionStatus,
        [issueStates.ISSUE_STATE_RESOLVED]: cliStatus.CLI_STATUS_RESOLVED,
        [issueStates.ISSUE_STATE_OPENED]: cliStatus.CLI_STATUS_REJECTED,
      };
      let messages = {
        [issueStates.ISSUE_STATE_RESOLVED]:
          issuesMessages.alsoResolveItemAlertMessage,
        [issueStates.ISSUE_STATE_CLOSED]:
          issuesMessages.alsoConfirmItemAlertMessage,
        [issueStates.ISSUE_STATE_OPENED]: issuesMessages.alsoRejectItemMessage,
      };

      if (
        allOthersWithWantedState &&
        issueStateToChecklistStatusMap[newState]
      ) {
        const bHideAlert = this.props.getNested([
          "configurations",
          "alerts",
          "hideAlertOfConnectedPostsStateUpdate",
        ]);
        if (bHideAlert)
          updateChecklistItemInstanceStatus(
            selectedProjectId,
            post.checklistItemInstance.id,
            issueStateToChecklistStatusMap[newState]
          );
        else
          startToast({
            overlay: true,
            mandatory: true,
            title: issuesMessages.connectedChecklist,
            message: messages[newState],
            actions: [
              {
                message: systemMessages.yes,
                color: "success",
                onClick: () =>
                  updateChecklistItemInstanceStatus(
                    selectedProjectId,
                    post.checklistItemInstance.id,
                    issueStateToChecklistStatusMap[newState]
                  ),
              },
              { message: systemMessages.no },
            ],
          });
      }
    }
    this.props.setCardHeaderParams({ postState: newState });
    this.setState({ shouldScrollToStoryComment: (comments || []).length + 1 });
  }

  onCloseSingleAction(event, additionStateChanges) {
    this.setState({ ...this.defaultFooterFlags, ...additionStateChanges });
  }

  onSingleAction(type) {
    if (type == "comment")
      this.setState({
        commentMode: true,
        reassignMode: false,
        allButtonsMode: false,
        selectedReassign: null,
      });
    else if (type == "reassign")
      this.setState({
        commentMode: false,
        reassignMode: true,
        allButtonsMode: false,
        selectedReassign: null,
      });
  }

  async onExportPost() {
    const { post, exportPostsPDF, viewer, selectedProjectId } = this.props;
    let pdf = await exportPostsPDF([post], selectedProjectId, viewer);
    let pdfDisplay = null;
    if (pdf && pdf.uri && pdf.uri.startsWith("http")) pdfDisplay = pdf.uri;

    this.setState({ pdfDisplay });
  }
  hidePdfDisplay = () => this.setState({ pdfDisplay: null });

  onClick() {
    const { onSelect, post } = this.props;
    if (onSelect && post) onSelect(post);
  }

  getRef(node) {
    this.postStoryRef = node;
  }

  render() {
    const {
      post,
      classes,
      selectedProjectId,
      rtl,
      requiredActions,
      subCategoriesMap,
      projectMembers,
      viewer,
      deleteComment,
      propertiesTypes,
    } = this.props;
    const {
      images,
      commentMode,
      story,
      buttons,
      reassignMode,
      imagesModalObject,
      allButtonsMode,
      pdfDisplay,
      relevantPostProperties,
    } = this.state;

    if (!post) return null;

    let assignToOtherMembers = null;

    if (reassignMode) {
      assignToOtherMembers = Object.assign({}, projectMembers);
      let currentAssignee = post.assignTo ? post.assignTo.id : null;
      delete assignToOtherMembers[currentAssignee];
    }

    const postsStageObj = { title: post.stageTitle };
    const postsChecklistObj = { title: post.checklistTitle };

    const isSafetyPost = _.get(post, ["trade", "id"]) === SAFETY_TRADE_ID;

    return (
      <>
        {Boolean(post && buttons) && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              minHeight: theme.headerHeightSecondary,
              padding: 20,
              borderBottom: theme.borderLineNeutralLight + "40",
            }}
          >
            {Boolean(commentMode) && (
              <div style={{ 
                  alignItems: "center", 
                  display: "flex", 
                  flex: 1, 
                  [rtl ? "paddingRight" : "paddingLeft"]: 10 
                }}>
                  <Comment
                    style={{ backgroundColor: theme.backgroundColorBright, paddingRight: '25px' }}
                    parentId={post.id}
                    onCreate={this.handleCommentCreate}
                    newCommentMode={true}
                  />
                   <CloseRounded
                    style={{
                      display: "flex",
                      alignSelf: "center",
                      justifyContent: "center",
                      fontSize: 16,
                      lineHeight: "16px",
                      cursor: "pointer",
                    }}
                    onClick={this.onCloseSingleAction}
                  />
              </div>
            )}
            {Boolean(reassignMode) && (
              <GridContainer spacing={8} alignItems={"center"}>
                <GridItem xs={10}>
                  <SelectableUsers
                    onChange={this.onSelectableReassignChange}
                    users={assignToOtherMembers}
                    autoFocus={true}
                  />
                </GridItem>
                <GridItem xs={1}>
                  <CheckRounded
                    style={{
                      display: "flex",
                      alignSelf: "center",
                      justifyContent: "center",
                      fontSize: 16,
                      lineHeight: "16px",
                      cursor: "pointer",
                    }}
                    onClick={this.onReassign}
                  />
                </GridItem>
                <GridItem xs={1}>
                  <CloseRounded
                    style={{
                      display: "flex",
                      alignSelf: "center",
                      justifyContent: "center",
                      fontSize: 16,
                      lineHeight: "16px",
                      cursor: "pointer",
                    }}
                    onClick={this.onCloseSingleAction}
                  />
                </GridItem>
              </GridContainer>
            )}
            {Boolean(allButtonsMode) && (
              <GridContainer spacing={8}>
                {Boolean(buttons) &&
                  buttons.map(
                    (currButton) => (
                      /*<GridItem xs={12 / buttons.length}>*/
                      <Button
                        key={currButton.label.id}
                        fullWidth={false}
                        onClick={currButton.func ? currButton.func : undefined}
                        className={classes.outlinedButtons}
                        style={currButton.style}
                      >
                        <img
                          style={{ marginRight: 7, marginLeft: 7 }}
                          src={currButton.icon}
                        />
                        <Text>{currButton.label}</Text>
                      </Button>
                    )
                    /*</GridItem>*/
                  )}
              </GridContainer>
            )}
          </div>
        )}

        <MenuScrollbar
          isSmooth={true}
          style={{ flex: 1 }}
          getScrollRef={this.getRef}
        >
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <GridContainer
              spacing={8}
              style={{
                padding: theme.paddingSize,
                paddingRight: 2 * theme.paddingSize,
                paddingLeft: 2 * theme.paddingSize,
              }}
            >
              {Boolean(post.isIssue) && (
                <GridItem
                  xs={6}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Text
                    className={classes.p}
                    style={{ ...theme.subFont, marginBottom: 5 }}
                  >
                    {issuesMessages.assignToTitle}
                  </Text>
                  {Boolean(post.getNested(["assignTo", "id"])) ? (
                    <User
                      avatarSize={25}
                      style={{ marginTop: 2 }}
                      companyMode={true}
                      hideAvatar={false}
                      detailedOverflow={!images}
                      userId={post.getNested(["assignTo", "id"])}
                    />
                  ) : (
                    <Text
                      style={{
                        ...theme.defaultFont,
                        fontWeight: theme.bold,
                        color: theme.brandNeutral,
                      }}
                    >
                      {propertiesMessages.empty}
                    </Text>
                  )}
                </GridItem>
              )}

              {Boolean(post.isIssue) && (
                <GridItem
                  xs={6}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Text style={{ ...theme.subFont, marginBottom: 5 }}>
                    {issuesMessages.dueDateTitle}
                  </Text>
                  {Boolean(post.dueDate) ? (
                    <Date
                      style={{ ...theme.defaultFont, fontWeight: theme.bold }}
                    >
                      {post.dueDate}
                    </Date>
                  ) : (
                    <Text
                      style={{
                        ...theme.defaultFont,
                        fontWeight: theme.bold,
                        color: theme.brandNeutral,
                      }}
                    >
                      {propertiesMessages.empty}
                    </Text>
                  )}
                </GridItem>
              )}

              {Boolean(post.stageTitle) && (
                <GridItem
                  xs={6}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Text style={{ ...theme.subFont, marginBottom: 5 }}>
                    {checklistItemMessages.stage}
                  </Text>
                  <Text
                    style={{
                      ...theme.defaultFont,
                      fontWeight: theme.bold,
                      color: theme.brandNeutral,
                    }}
                  >
                    {postsStageObj.getCementoTitle()}
                  </Text>
                </GridItem>
              )}

              {Boolean(post.checklistTitle) && (
                <GridItem
                  xs={6}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Text style={{ ...theme.subFont, marginBottom: 5 }}>
                    {postsMenuMessages.checklist}
                  </Text>
                  <Text
                    style={{
                      ...theme.defaultFont,
                      fontWeight: theme.bold,
                      color: theme.brandNeutral,
                    }}
                  >
                    {postsChecklistObj.getCementoTitle()}
                  </Text>
                </GridItem>
              )}

              {relevantPostProperties.map((postProperty) => {
                if (postProperty.id === "groups") return;

                const propertyTitle = postProperty.getCementoTitle();
                const propertyValue = _.get(post, [
                  "instances",
                  postProperty.id,
                  "data",
                ]);

                return (
                  <GridItem
                    xs={6}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <StandardInput
                      key={postProperty.id}
                      mode={"card"}
                      title={propertyTitle}
                      type={postProperty.type}
                      businessType={postProperty.businessType}
                      placeholder={propertiesMessages.empty}
                      options={postProperty.values}
                      value={propertyValue}
                      settings={postProperty.settings}
                      disabled
                      noValueComponentValueStyle={{lineHeight: theme.lineHeight, fontFamily: theme.subFont.fontFamily, fontWeight: theme.subFont.fontWeight, fontSize: theme.fontSize}}
                      valueStyle={{ ...theme.defaultFont, fontWeight: theme.bold, color: propertyValue ? null : theme.brandNeutral }}
                      titleStyle={{ ...theme.subFont, marginBottom: 5 }}
                      style={{ ...theme.defaultFont, fontWeight: theme.bold }}
                    />
                  </GridItem>
                );
              })}

              {Boolean(
                post.attachments && Object.values(post.attachments).length
              ) && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text style={{ ...theme.subFont, marginBottom: 5 }}>
                    {newPostMessages.attachments}
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {Object.values(post.attachments).map((attch, i) => (
                      <div
                        style={{
                          [`margin${rtl ? "Left" : "Right"}`]:
                            theme.verticalMargin,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          this.setState({ pdfDisplay: attch.uri || attch.url })
                        }
                      >
                        <img
                          src={PdfIcon}
                          style={{ color: theme.brandPrimary, height: 70 }}
                        />
                        <Text
                          values={{ number: i + 1 }}
                          key={attch.uri || attch.url}
                          style={{
                            ...theme.defaultFont,
                            fontWeight: theme.bold,
                            textAlign: "center",
                          }}
                        >
                          {attch.title ||
                            newPostMessages.attachmentsDefaultName}
                        </Text>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {Boolean(post.isIssue && isSafetyPost) && (
                <GridItem
                  xs={6}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Text style={{ ...theme.subFont, marginBottom: 5 }}>
                    {newPostMessages.fine}
                  </Text>
                  {Boolean(post.getNested(["fine", "amount"])) ? (
                    <Text
                      style={{ ...theme.defaultFont, fontWeight: theme.bold }}
                    >
                      {post.getNested(["fine", "amount"])}
                    </Text>
                  ) : (
                    <Text
                      style={{
                        ...theme.defaultFont,
                        fontWeight: theme.bold,
                        color: theme.brandNeutral,
                      }}
                    >
                      {propertiesMessages.empty}
                    </Text>
                  )}
                </GridItem>
              )}

              {Boolean(post.isIssue && isSafetyPost) && (
                <GridItem
                  xs={6}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Text style={{ ...theme.subFont, marginBottom: 5 }}>
                    {newPostMessages.requiredAction}
                  </Text>
                  {Boolean(post.requiredAction) ? (
                    <Text
                      style={{ ...theme.defaultFont, fontWeight: theme.bold }}
                    >
                      {post.getNested(
                        ["requiredAction", "customText"],
                        post.requiredAction.id &&
                          requiredActions.getNested([
                            post.requiredAction.id,
                            "getTitle",
                          ])
                      )}
                    </Text>
                  ) : (
                    <Text
                      style={{
                        ...theme.defaultFont,
                        fontWeight: theme.bold,
                        color: theme.brandNeutral,
                      }}
                    >
                      {propertiesMessages.empty}
                    </Text>
                  )}
                </GridItem>
              )}

              {Boolean(isSafetyPost) && (
                <GridItem
                  xs={6}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Text style={{ ...theme.subFont, marginBottom: 5 }}>
                    {newPostMessages.subCategory}
                  </Text>
                  {Boolean(
                    post.trade &&
                      post.trade.id &&
                      post.subCategory &&
                      post.subCategory.id
                  ) ? (
                    <Text
                      style={{ ...theme.defaultFont, fontWeight: theme.bold }}
                    >
                      {subCategoriesMap.getNested([
                        post.trade.id,
                        post.subCategory.id,
                        "getTitle",
                      ])}
                    </Text>
                  ) : (
                    <Text
                      style={{
                        ...theme.defaultFont,
                        fontWeight: theme.bold,
                        color: theme.brandNeutral,
                      }}
                    >
                      {propertiesMessages.empty}
                    </Text>
                  )}
                </GridItem>
              )}

              {Boolean(isSafetyPost) && (
                <GridItem
                  xs={6}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Text style={{ ...theme.subFont, marginBottom: 5 }}>
                    {newPostMessages.severity}
                  </Text>
                  {Boolean(post.severity) ? (
                    <div style={{ display: "flex" }}>
                      <div style={{ display: "flex", width: 60, height: 20 }}>
                        {severityColors.map((curr) => (
                          <Text
                            key={curr.val}
                            style={{
                              ...theme.defaultFont,
                              fontWeight: theme.bold,
                              flex: 1,
                              textAlign: "center",
                              alignSelf: "center",
                              backgroundColor: curr.color,
                              opacity: post.severity == curr.val ? 1 : 0.4,
                              border:
                                post.severity == curr.val
                                  ? "1px solid " + theme.brandNeutralDark
                                  : "none",
                            }}
                          >
                            {curr.val}
                          </Text>
                        ))}
                      </div>
                      <Text
                        style={{
                          ...theme.defaultFont,
                          fontWeight: theme.bold,
                          margin: "0px 5px",
                        }}
                      >
                        {safetyMessages.severity.title[post.severity]}
                      </Text>
                    </div>
                  ) : (
                    <Text
                      style={{
                        ...theme.defaultFont,
                        fontWeight: theme.bold,
                        color: theme.brandNeutral,
                      }}
                    >
                      {propertiesMessages.empty}
                    </Text>
                  )}
                </GridItem>
              )}

              {Boolean(
                pdfDisplay && pdfDisplay != undefined && pdfDisplay != null
              ) && (
                <ImageCarousel
                  items={[{ src: pdfDisplay }]}
                  pdfMode={true}
                  onClose={this.hidePdfDisplay}
                  toolbar={true}
                />
              )}
            </GridContainer>
            <div
              style={{
                flexDirection: "column",
                padding: 2 * theme.paddingSize,
                paddingTop: theme.paddingSize,
              }}
            >
              <Text style={{ ...theme.subFont, marginBottom: 5 }}>
                {issuesMessages.story.title}
              </Text>
              {story.map((s, storyIndex) => {
                let Title = s.title;
                let SubTitle = s.subTitle;
                let Content = s.content;
                let lastStory = storyIndex == story.length - 1;
                let StoryIcon = storyTypesImages[s.type] ? (
                  <img src={storyTypesImages[s.type]} />
                ) : (
                  <LabelImportantRounded />
                );

                return (
                  <div key={s.id + s.createdAt} style={{ display: "flex" }}>
                    <div
                      style={{
                        width: 25,
                        [rtl ? "marginRight" : "marginLeft"]: 8,
                        [rtl ? "borderRight" : "borderLeft"]: lastStory
                          ? ""
                          : theme.borderLineNeutral + "80",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          color: theme.brandPrimary,
                          position: "relative",
                          [rtl ? "right" : "left"]: -8,
                        }}
                      >
                        {StoryIcon}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ flexDirection: "column" }}>
                        <div
                          style={{
                            ...theme.defaultFont,
                            fontSize: 13,
                            lineHeight: "13px",
                            fontWeight: theme.strongBold,
                          }}
                        >
                          {Title}
                        </div>
                        <div
                          style={{
                            ...theme.subFont,
                            flexDirection: "row",
                            display: "flex",
                            fontSize: 12,
                          }}
                        >
                          {SubTitle}
                          {Boolean(
                            s.id != 1 && viewer && viewer.adminMode == 1
                          ) && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                [rtl ? "paddingLeft" : "paddingRight"]: 5,
                              }}
                              onClick={() =>
                                deleteComment(
                                  selectedProjectId,
                                  "post",
                                  post.id,
                                  s.id
                                )
                              }
                            >
                              <div
                                style={{
                                  cursor: "pointer",
                                  marginLeft: 5,
                                  marginRight: 5,
                                }}
                              >
                                <img
                                  style={{ width: 13, height: 14 }}
                                  src={trash}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          ...theme.defaultFont,
                          fontWeight: theme.bold,
                          color: theme.brandNeutralDark,
                          marginTop: theme.paddingSize,
                          marginBottom: theme.paddingSize * 2,
                          flexDirection: "column",
                        }}
                      >
                        {Boolean(typeof Content === "object")
                          ? Content
                          : Content}
                        {Boolean(s.imageURIs) && (
                          <GridContainer
                            spacing={16}
                            style={{
                              marginTop: Content ? theme.paddingSize / 3 : 0,
                            }}
                          >
                            {s.imageURIs.map((curr, i) =>
                              !curr ? null : (
                                <GridItem
                                  key={curr}
                                  xs={
                                    i == s.imageURIs.length - 1 && i % 2 == 0
                                      ? 12
                                      : 6
                                  }
                                >
                                  <Image
                                    src={curr}
                                    containerStyle={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.openImagesModal(storyIndex, i)
                                    }
                                  />
                                </GridItem>
                              )
                            )}
                          </GridContainer>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </MenuScrollbar>

        {Boolean(imagesModalObject) && (
          <ImageCarousel
            onClose={this.closeImagesModal}
            initialSlide={
              imagesModalObject ? imagesModalObject.selectedIndex : 0
            }
            items={imagesModalObject.images}
          />
        )}

        {/* {Boolean(pdfDisplay) && 
            <ImageCarousel items={[{ src: pdfDisplay }]} pdfMode={true} onClose={() => this.setState({pdfDisplay: null})} toolbar={true}/>
          } */}
      </>
    );
  }
}

let styles = {
  p: {
    overflow: "hidden",
    fontSize: "0.8vw",
    textOverflow: "ellipsis",
    margin: "0px",
    whiteSpace: "wrap",
  },
  outlinedButtons: {
    fontSize: "10px",
    borderRadius: 0,
    color: theme.brandNeutral,
    backgroundColor: "transparent",
  },
  cardSectionsStyles: {
    padding: theme.paddingSize + "px 30px",
    boxShadow: "rgba(0, 0, 0, 0.08) 0px 1px 20px 0px",
    marginBottom: theme.paddingSize,
    display: "flex",
    flexDirection: "column",
  },
};

const withPostsHook = PostViewerClass => props => {
  const propsPost = props.post;
  const { currViewPosts } = usePosts({ 
    contentType: propsPost.trade.id === SAFETY_TRADE_ID ? 'safety' : undefined,
    inIds: [propsPost.id],
  });

  return <PostViewerClass 
    {...props}
    post={currViewPosts[0] || propsPost}
  />
}

const enhance = compose(
  injectIntl,
  withStyles(styles),
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      rtl: state.app.rtl,
      trades: state.trades.map,
      comments: state.comments.map,
      requiredActions: state.quasiStatics.requiredActionsMap,
      subCategoriesMap: state.quasiStatics.subCategoriesMap,
      checklistInstances: state.checklistItemsInstances.map,
      urlParams: state.ui.urlParams,
    }),
    {
      endCommentsListener,
      deletePost,
      deleteComment,
      getComments,
      onDraftModeChange,
      areAllOtherConnectedPosts_withWantedStatus,
      updateChecklistItemInstanceStatus,
      exportPostsPDF,
      startToast,
      createLocalComment,
      uploadNewPostComment,
      updateIssueState,
      updateIssueAssignTo,
    }
  ),
  withPostsHook,
);

PostViewer = enhance(PostViewer);

class NewPostPage extends React.Component {
  constructor(props) {
    super(props);
    this.getRef = this.getRef.bind(this);
    this.getSortedTrades = this.getSortedTrades.bind(this);
    this.onPostChange = this.onPostChange.bind(this);
    this.savePost = this.savePost.bind(this);
    this.setDefaultTagSelected = this.setDefaultTagSelected.bind(this);
    this.fieldsRef = {};

    this.objectPropertiesPageRef = null;
    this.state = {
      mandatoryData: {},
      post: {},
      sortedTrades: [],
      sortedSubCategories: [],
      safetyMode: false,
      checklistInstanceCallback: null,
    };
  }

  componentDidMount() {
    const { onCardLoad, onCancel, draftValidator, post } = this.props;
    let contentType =
      post && post.isIssue ? issuesMessages.task : postsMessages.documentation;
    let creationMode = Boolean(post && post.mode == "draft");
    onCardLoad(
      {
        editable: true,
        onCancel: () => draftValidator(() => onCancel()),
        onSave: () => this.savePost(),
      },
      {},
      true,
      creationMode,
      contentType,
      true
    );
  }

  componentWillUnmount() {
    const { onDraftModeChange } = this.props;
    onDraftModeChange(false);
  }

  setDefaultTagSelected(inPost) {
    const { post } = this.state;
    const { projectMembers, selectedProjectId, viewer } = this.props;

    var newPost = Object.assign({}, inPost || post);
    newPost = setDefaultTagSelected(
      newPost,
      projectMembers,
      viewer,
      selectedProjectId
    );

    this.setState({ post: newPost });

    return newPost;
  }

  async savePost(reopenNewPostWizard) {
    const {
      viewer,
      uploadNewPost,
      getNewPostId,
      createLocalPost,
      projects,
      track,
      selectedProjectId,
      onEnterEditMode,
      onPostSave,
      startToast,
    } = this.props;
    const {
      onClose,
      onDone,
      checklistItemInstance,
      type,
      reportId,
      isCloseOnSave = true,
    } = this.props;
    const { checklistInstanceCallback, mandatoryData, isNewPost } = this.state;

    if (this.fieldsRef) {
      let errors = {};
      this.fieldsRef.loopEach((propId, ref) => {
        let err = ref.component.checkErrors();
        if (err) errors[propId] = err.join(", ");
      });
      if (Object.keys(errors).length) {
        startToast({
          title: systemMessages.invalidDetailsDescription,
          values: { errors: Object.values(errors).join("\n") },
          type: "error",
        });
        return false;
      }
    }
    let post = Object.assign({}, this.state.post);
    if (post.titlePrefix) {
      post.title = `${post.titlePrefix} ${post.title}`;
      _.unset(post, ["titlePrefix"]);
    }

    let notValidKey = isPostInValid(post, mandatoryData);
    if (notValidKey) {
      startToast({ title: newPostMessages.mandatoryFields[notValidKey] });
      return false;
    }

    if (
      typeof (this.objectPropertiesPageRef || {}).handleSave === "function"
    ) {
      const isUpdateSucceeded =
        await this.objectPropertiesPageRef.handleSave();
      if (!isUpdateSucceeded) return false;
    }

    this.setState({ uploading: true });

    try {
      if (this.didPress) return false;
      this.didPress = true;

      post = this.setDefaultTagSelected(post);

      if (checklistItemInstance)
        post.checklistItemInstance = checklistItemInstance;

      if (post.isIssue) {
        if (isNewPost) {
          post.issueState = issueStates.ISSUE_STATE_OPENED;
          post.issueOpener = { id: viewer.id };
        }
      }

      // Rename each post image id because we cannot have "/" in the key
      let typeArray = ["images", "attachments"];
      for (let index = 0; index < typeArray.length; index++) {
        let attchType = typeArray[index];
        if (post[attchType]) {
          var imagesArray = Array.isArray(post[attchType])
            ? post[attchType]
            : Object.values(post[attchType]);
          post[attchType] = {};
          for (var i = 0, j = imagesArray.length; i < j; i++) {
            if (
              (imagesArray[i].file || imagesArray[i].uri) &&
              !(imagesArray[i].uri && imagesArray[i].uri.startsWith("http"))
            ) {
              var newId = uuidv4();
              post[attchType][newId] = imagesArray[i];
              imagesArray[i].id = newId; // TODO: Fix this all images algorithm it made me puke a little
              post[attchType][newId].uploading = true;
              post[attchType][newId].uri = await uploadImage(
                imagesArray[i],
                selectedProjectId + "/" + post.id + "_" + newId,
                "posts"
              );
              if (post[attchType][newId].uri) {
                delete post[attchType][newId]["uploading"];
                delete post[attchType][newId]["isLocal"];
              }
            } else if (imagesArray[i].id) {
              post[attchType][imagesArray[i].id] = imagesArray[i];
            }
          }
        }
      }

      // Add the post locally only
      post.mode = null;
      post = (await createLocalPost(viewer, selectedProjectId, post)).post;
      post = Object.assign({}, { ...post });

      if (!reopenNewPostWizard) {
        if (onClose && isCloseOnSave) onClose();
        if (onPostSave) onPostSave(post);
      }

      if (reportId) post.reportId = reportId;

      if (checklistInstanceCallback) {
        let instanceId = checklistInstanceCallback(post);
        post.checklistItemInstance = { id: instanceId };
      }

      let callBack = () =>
        startToast({
          title: post.isIssue
            ? postsMessages.issueSaved
            : postsMessages.postSaved,
          type: "success",
        });

      var retPromise = uploadNewPost(
        viewer,
        selectedProjectId,
        post,
        false,
        callBack
      );
      var ret = await retPromise;
      if (!ret) return false;
      post = ret.post;

      if (onDone && !reopenNewPostWizard) onDone(post);

      if (reopenNewPostWizard) {
        const { assignTo, location, dueDate, trade, isIssue } = post;
        var newPost = Object.assign(
          {},
          { isIssue, assignTo, location, dueDate, trade, mode: "draft" }
        );
        this.setState({ post: newPost, uploading: false, didChange: false });
        if (_.isFunction(onEnterEditMode)){
          onEnterEditMode(newPost);
        }
      }

      return post;
    } finally {
      this.didPress = false;
    }
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    const { sortedTrades, sortedSubCategories, post, isSafety, isNewPost } =
      this.state;
    let newStateChanges = {};

    let nextPost = post;
    if (
      props.isValDiff(nextProps, ["isSafety"]) ||
      props.isValDiff(nextProps, ["urlParams", "contentType"])
    )
      newStateChanges.safetyMode = Boolean(
        nextProps.getNested(["urlParams", "contentType"]) === "safety" ||
          nextProps.isSafety
      );

    const nextSefetyMode = newStateChanges.safetyMode || isSafety;
    if (props.isValDiff(nextProps, ["post"])) {
      nextPost = { ...(nextProps.post || {}) };

      if (!nextPost.id) {
        newStateChanges.isNewPost = true;
        nextPost.id =
          _.get(post, "id") ||
          nextProps.getNewPostId(nextProps.selectedProjectId).payload;
      } else if (isNewPost) newStateChanges.isNewPost = false;

      if (nextPost.trade && nextPost.trade.id)
        nextPost = nextPost.setNested(
          ["trade", "id"],
          nextPost.trade.id.toString()
        );
      else if (nextSefetyMode) nextPost.trade = { id: SAFETY_TRADE_ID };

      if (nextSefetyMode && _.isNil(nextPost.severity))
        nextPost.severity = POST_DEFAULT_SEVERITY;

      const checklistInstanceCallback = nextPost.checklistInstanceCallback;
      const mandatoryData = nextPost.mandatoryData || {};
      delete nextPost.mandatoryData;
      delete nextPost.checklistInstanceCallback;

      newStateChanges = {
        ...newStateChanges,
        checklistInstanceCallback,
        mandatoryData,
        post: nextPost,
      };
    }

    if (
      sortedTrades.length == 0 ||
      (props.trades != nextProps.trades && nextProps.trades.size > 0)
    )
      newStateChanges.sortedTrades = this.getSortedTrades(nextProps.trades);

    let postTrade = nextPost.getNested(["trade", "id"]);
    if (
      sortedSubCategories.length == 0 ||
      (props.subCategoriesMap.get(postTrade) !=
        nextProps.subCategoriesMap.get(postTrade) &&
        nextProps.subCategoriesMap.get(postTrade).size > 0)
    )
      this.setSortedSubCategories(postTrade);

    if (props.projectMembers != nextProps.projectMembers)
      newStateChanges.assignToMembers = Object.assign(
        {},
        nextProps.projectMembers || {}
      );

    this.setState({ ...newStateChanges });
  }

  getRef(node) {
    this.postStoryRef = node;
  }

  setSortedSubCategories(postTrade) {
    if (postTrade) {
      let { subCategoriesMap } = this.props;
      let sortedSubCategories = this.getSortedTrades(
        subCategoriesMap.get(postTrade) || {}
      );
      this.setState({ sortedSubCategories });
    }
  }

  getSortedTrades(trades) {
    let sortedTrades = [];
    trades.loopEach((i, t) => {
      if (t.getNested(["getTitle"]) && t.getNested(["getTitle"]))
        sortedTrades.push(t);
    });
    return sortedTrades.sort((a, b) =>
      a.getNested(["getTitle"]).localeCompare(b.getNested(["getTitle"]))
    );
  }

  onPostChange(path, val) {
    const { onDraftModeChange } = this.props;
    if (!path) return;
    let post = this.state.post.setNested(
      Array.isArray(path) ? path : [path],
      val
    );

    // var post = Object.assign({}, this.state.post);
    // post[key] = val;
    onDraftModeChange(true);
    this.setState({ post });
  }

  render() {
    const { classes, rtl, urlParams, requiredActions, lang } = this.props;
    const { mandatoryData, sortedTrades, sortedSubCategories, post, safetyMode = false, assignToMembers, showObjectPropertiesPage } = this.state;
    let headerSectionStyle = { display:'flex', justifyContent: 'center', alignItems:'center', margin: '10px 0px', color: theme.brandPrimary, fontFamily: "Assistant - Semi Bold", fontSize: 16, fontWeight: theme.strongBold, [rtl?'marginLeft':'marginRight']:3 }

    let currentAssignee = _.get(post, ['assignTo'], null);

    const isSafetyPost = _.get(post, ['trade', 'id']) === SAFETY_TRADE_ID || safetyMode;
    const newPostId = _.get(post, 'id');

    let locationId = (
      post.getNested(['location', 'unit', 'id']) ||
      post.getNested(['location', 'floor', 'id']) ||
      post.getNested(['location', 'building', 'id']) ||
      urlParams.getNested(['unitId']) ||
      urlParams.getNested(['floorId']) ||
      urlParams.getNested(['buildingId'])
    );

    if (locationId == "_")
      locationId = null;

    let selectedLocation = post.location
      ? {
          buildingId: post.getNested(["location", "building", "id"]),
          floorId: post.getNested(["location", "floor", "id"]),
          unitId: post.getNested(["location", "unit", "id"]),
        }
      : {
          buildingId:
            urlParams.getNested(["buildingId"]) == "_"
              ? null
              : urlParams.getNested(["buildingId"]),
          floorId:
            urlParams.getNested(["floorId"]) == "_"
              ? null
              : urlParams.getNested(["floorId"]),
          unitId:
            urlParams.getNested(["unitId"]) == "_"
              ? null
              : urlParams.getNested(["unitId"]),
        };

    const isPostMissingMandatoryFields =
      !post.title &&
      Object.values(post.images || {}).length == 0 &&
      Object.values(post.attachments || {}).length == 0;

    return (
      <>
        <MenuScrollbar
          isSmooth={true}
          style={{ flex: 1 }}
          getScrollRef={this.getRef}
        >
          <div
            className={classes.cardSectionsStyles}
            style={{
              transition: "all 150ms ease 0s",
              backgroundColor: theme.backgroundColorBright,
            }}
          >
            <Text style={headerSectionStyle}>
              {newPostMessages.generalInfo}
            </Text>

            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              {Boolean(!safetyMode) && (
                <InputField
                  key={"trade"}
                  name={issuesMessages.trade}
                  type={"SelectionList"}
                  businessType={"trades"}
                  style={{ padding: "10px 0px" }}
                  placeholder={newPostMessages.title_3}
                  onChange={(val) => {
                    let trade = _.isObject(val) ? Object.keys(val)[0].toString() : null;
                    this.onPostChange(["trade", "id"], trade);
                    this.setSortedSubCategories(trade);
                  }}
                  values={sortedTrades
                    .map((trade) => ({
                      id: trade.id,
                      title: trade.getCementoTitle(false),
                    }))
                    .filter((trade) => !_.isEmpty(trade.title))}
                  defaultValue={
                    Boolean(post.getNested(["trade", "id"]))
                      ? { [post.trade.id]: post.trade.id }
                      : null
                  }
                  onRef={(e, c) => {
                    this.fieldsRef["trade"] = { element: e, component: c };
                  }}
                />
              )}

              {Boolean(sortedSubCategories.length > 0) && (
                <InputField
                  key={"subCategory"}
                  name={newPostMessages.subCategory}
                  type={"SelectionList"}
                  businessType={"subCategory"}
                  style={{ padding: "10px 0px" }}
                  placeholder={newPostMessages.title_3}
                  onChange={(val) =>
                    this.onPostChange(
                      ["subCategory", "id"],
                      Object.keys(val)[0].toString()
                    )
                  }
                  values={sortedSubCategories.map((t) => ({
                    id: t.id,
                    title: t.getCementoTitle(),
                  }))}
                  defaultValue={
                    Boolean(post.getNested(["subCategory", "id"]))
                      ? { [post.subCategory.id]: post.subCategory.id }
                      : null
                  }
                  onRef={(e, c) => {
                    this.fieldsRef["subCategory"] = {
                      element: e,
                      component: c,
                    };
                  }}
                />
              )}

              <InputField
                type={"Location"}
                style={{ padding: "10px 0px" }}
                key={"location"}
                rtl={rtl}
                mode={"card"}
                name={issuesMessages.location}
                placeholder={newPostMessages.title_2}
                defaultValue={locationId ? { [locationId]: locationId } : null}
                onChange={(val) => {
                  const firstId = _.head(_.keys(val));
                  const locationFullDetails = getFullLocationDetailsByIdNoProps(firstId);
                  const locationIds = locationFullDetails.locationIds;
                  if (locationFullDetails.locationFound) {
                    let newLocation = {};
                    if (locationIds.buildingId)
                      newLocation = newLocation.setNested(
                        ["building", "id"],
                        locationIds.buildingId
                      );
                    if (locationIds.floorId)
                      newLocation = newLocation.setNested(
                        ["floor", "id"],
                        locationIds.floorId
                      );
                    if (locationIds.unitId)
                      newLocation = newLocation.setNested(
                        ["unit", "id"],
                        locationIds.unitId
                      );
                    this.onPostChange("location", newLocation);
                  }
                }}
                onRef={(e, c) => {
                  this.fieldsRef["Location"] = { element: e, component: c };
                }}
              />
              {Boolean(post.isIssue) && (
                <InputField
                  key={"assign"}
                  style={{ padding: "10px 0px" }}
                  prop={{
                    mandatory: true,
                    title: issuesMessages.assignTo,
                    type: null,
                  }}
                  value={currentAssignee}
                  onRef={(e, c) => {
                    this.fieldsRef["assign"] = { element: e, component: c };
                  }}
                  name={issuesMessages.assignTo}
                >
                  <SelectableUsers
                    showLine={true}
                    defaultValue={currentAssignee}
                    placeholder={newPostMessages.title_4}
                    onChange={(assignTo) =>
                      this.onPostChange("assignTo", assignTo)
                    }
                    users={assignToMembers}
                  />
                </InputField>
              )}
              <InputField
                type={"String"}
                style={{ padding: "10px 0px" }}
                key={"title"}
                prop={{
                  title: newPostMessages.description,
                  type: "String",
                  mandatory: Boolean(
                    mandatoryData.desc || isPostMissingMandatoryFields
                  ),
                }}
                rtl={rtl}
                mode={"card"}
                defaultValue={post.title}
                name={newPostMessages.description}
                placeholder={newPostMessages.title_1}
                onChange={(val) => this.onPostChange("title", val)}
                onRef={(e, c) => {
                  this.fieldsRef["title"] = { element: e, component: c };
                }}
                multiline
              />
              <InputField
                type={"DrawingsArray"}
                prop={{
                  title: newPostMessages.images,
                  type: "DrawingsArray",
                  mandatory: Boolean(
                    mandatoryData.img || isPostMissingMandatoryFields
                  ),
                }}
                settings={{ accept: ".png,.jpeg,.jpg" }}
                mode={"card"}
                key={"images"}
                style={{ padding: "10px 0px" }}
                withResize={false}
                name={newPostMessages.images}
                defaultValue={post.images ? Object.values(post.images) : null}
                onChange={(images) => {
                  this.onPostChange("images", images);
                }}
                onRef={(e, c) => {
                  if (e?.id) this.fieldsRef[e.id] = { element: e, component: c };
                }}
              />

              <InputField
                type={"DrawingsArray"}
                key={"attachments"}
                mode={"card"}
                accept='.pdf'
                style={{ padding: "10px 0px" }}
                prop={{
                  mandatory: Boolean(
                    mandatoryData.file || isPostMissingMandatoryFields
                  ),
                  title: newPostMessages.attachments,
                  type: "DrawingsArray",
                }}
                withResize={false}
                name={newPostMessages.attachments}
                defaultValue={
                  post.attachments ? Object.values(post.attachments) : null
                }
                onChange={(attachments) => {
                  this.onPostChange("attachments", attachments);
                }}
                onRef={(e, c) => {
                  this.fieldsRef["attachments"] = { element: e, component: c };
                }}
              />

              {Boolean(post.isIssue) && (
                <InputField
                  key={"dueDate"}
                  name={issuesMessages.dueDate}
                  type={"Date"}
                  style={{ padding: "10px 0px" }}
                  defaultValue={post.dueDate}
                  onChange={(dueDate) => this.onPostChange("dueDate", dueDate)}
                  onRef={(e, c) => {
                    this.fieldsRef["dueDate"] = { element: e, component: c };
                  }}
                />
              )}

              {Boolean(isSafetyPost) && (
                <InputField
                  key={"severity"}
                  style={{ padding: "10px 0px" }}
                  prop={{
                    mandatory: true,
                    title: newPostMessages.severity,
                    type: null,
                  }}
                  onRef={(e, c) => {
                    this.fieldsRef["severity"] = { element: e, component: c };
                  }}
                  value={post.getNested(["severity"])}
                  name={newPostMessages.severity}
                >
                  <div
                    style={{
                      display: "flex",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <div style={{ display: "flex", width: 60, height: 20 }}>
                      {severityColors.map((curr) => (
                        <Text
                          key={curr.val}
                          style={{
                            ...theme.defaultFont,
                            fontWeight: theme.bold,
                            flex: 1,
                            textAlign: "center",
                            alignSelf: "center",
                            backgroundColor: curr.color,
                            opacity: post.severity == curr.val ? 1 : 0.4,
                            border:
                              post.severity == curr.val
                                ? "1px solid " + theme.brandNeutralDark
                                : "none",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            this.onPostChange(["severity"], curr.val)
                          }
                        >
                          {curr.val}
                        </Text>
                      ))}
                    </div>
                    <Text
                      style={{
                        ...theme.defaultFont,
                        fontWeight: theme.bold,
                        margin: "0px 5px",
                      }}
                    >
                      {safetyMessages.severity.title[post.severity]}
                    </Text>
                  </div>
                </InputField>
              )}
            </div>
          </div>
          {Boolean(isSafetyPost) && (
            <div
              className={classes.cardSectionsStyles}
              style={{
                transition: "all 150ms ease 0s",
                backgroundColor: theme.backgroundColorBright,
              }}
            >
              <Text style={headerSectionStyle}>{newPostMessages.followUp}</Text>
              <InputField
                key={"requiredAction"}
                name={newPostMessages.requiredAction}
                type={"SelectionList"}
                businessType={"requiredAction"}
                style={{ padding: "10px 0px" }}
                placeholder={newPostMessages.requiredActionPlaceholder}
                onChange={(val) => {
                  let id = Object.keys(val)[0].toString();
                  let customText = requiredActions.getNested([id, "getTitle"]);
                  this.onPostChange("requiredAction", { id, customText });
                }}
                defaultValue={
                  Boolean(post.getNested(["requiredAction", "id"]))
                    ? { [post.requiredAction.id]: post.requiredAction.id }
                    : null
                }
                onRef={(e, c) => {
                  this.fieldsRef["requiredAction"] = {
                    element: e,
                    component: c,
                  };
                }}
                values={[
                  ...requiredActions
                    .valueSeq()
                    .map((t) => ({ id: t.id, title: t.getCementoTitle() })),
                  Boolean(post.getNested(["requiredAction", "customText"])) && {
                    id: "-1",
                    title: post.getNested(["requiredAction", "customText"]),
                  },
                ]}
                isCreatable={true}
                onCreateOption={(customText) =>
                  this.onPostChange("requiredAction", { customText, id: "-1" })
                }
                createLabel={newPostMessages.createRequiredAction}
              />

              <InputField
                key={"fineAmount"}
                name={newPostMessages.fine}
                placeholder={newPostMessages.fineAmount}
                type={"Number"}
                style={{ padding: "10px 0px" }}
                defaultValue={post.getNested(["fine", "amount"])}
                onChange={(amount) =>
                  this.onPostChange(["fine", "amount"], amount)
                }
                onRef={(e, c) => {
                  this.fieldsRef["fine"] = { element: e, component: c };
                }}
              />
              {Boolean(post.getNested(["fine", "amount"], 0) > 0) && (
                <>
                  <InputField
                    key={"fineReceiver"}
                    style={{ padding: "10px 0px" }}
                    prop={{
                      title: newPostMessages.fineReceiver,
                      type: null,
                    }}
                    value={
                      Boolean(post.getNested(["fine", "fineReciver"]))
                        ? true
                        : null
                    }
                    onRef={(e, c) => {
                      this.fieldsRef["fineReceiver"] = {
                        element: e,
                        component: c,
                      };
                    }}
                    name={newPostMessages.fineReceiver}
                  >
                    <SelectableUsers
                      showLine={true}
                      defaultValue={post.getNested(["fine", "fineReciver"])}
                      placeholder={newPostMessages.fineReceiver}
                      onChange={(receiver) =>
                        this.onPostChange(["fine", "fineReciver"], receiver)
                      }
                      users={assignToMembers}
                    />
                  </InputField>
                  <InputField
                    type={"String"}
                    style={{ padding: "10px 0px" }}
                    key={"fineDescription"}
                    prop={{
                      title: newPostMessages.fineDescription,
                      type: "String",
                    }}
                    rtl={rtl}
                    defaultValue={post.getNested(["fine", "description"])}
                    name={newPostMessages.fineDescription}
                    placeholder={newPostMessages.fineDescriptionPlaceholder}
                    onChange={(val) =>
                      this.onPostChange(["fine", "description"], val)
                    }
                    onRef={(e, c) => {
                      this.fieldsRef["fineDescription"] = {
                        element: e,
                        component: c,
                      };
                    }}
                  />
                </>
              )}
            </div>
          )}
          {Boolean(post.isIssue && !isSafetyPost) && (
            <div
              className={classes.cardSectionsStyles}
              style={{
                display: showObjectPropertiesPage ? "inherit" : "none" ,
                transition: "all 150ms ease 0s",
                backgroundColor: theme.backgroundColorBright,
              }}
            >
              <Text style={headerSectionStyle}>
                {newPostMessages.documentation}
              </Text>

              <div
                style={{ display: "flex", flex: 1, flexDirection: "column" }}
              >
                <ObjectPropertiesPage
                  onGroupsFound={(didFind) => this.setState({showObjectPropertiesPage:didFind})}
                  mode={"card"}
                  objectId={newPostId}
                  subjectName={POST_INFO_SUBJECT_NAME}
                  createObjectMode
                  useSections={false}
                  useMenusScrollsBar={false}
                  onRef={(ref) => (this.objectPropertiesPageRef = ref)}
                  inputsContainerStyle={{
                    padding: "10px 0px",
                    flexDirection: "column",
                  }}
                />
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 15,
            }}
          >
            <div
              onClick={() => {
                this.savePost();
              }}
              style={{
                cursor: "pointer",
                margin: 5,
                border: "1px solid #00000010",
                backgroundColor: theme.backgroundColorBright,
                color: theme.brandPrimary,
                padding: "5px 15px",
                borderRadius: 25,
                boxShadow: "#00000015 0px 0px 10px 0px",
              }}
            >
              <Text>{newPostMessages.savePost}</Text>
            </div>

            {Boolean(!post.checklistItemInstance) && (
              <div
                onClick={() => {
                  this.savePost(true);
                }}
                style={{
                  cursor: "pointer",
                  margin: 5,
                  border: "1px solid #00000010",
                  backgroundColor: theme.backgroundColorBright,
                  color: theme.brandPrimary,
                  padding: "5px 15px",
                  borderRadius: 25,
                  boxShadow: "#00000015 0px 0px 10px 0px",
                }}
              >
                <Text>{newPostMessages.saveAndRepost}</Text>
              </div>
            )}
          </div>
        </MenuScrollbar>
      </>
    );
  }
}

NewPostPage = injectIntl(NewPostPage);
NewPostPage = withStyles(styles)(NewPostPage);
const NewPostPageEnhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      rtl: state.app.rtl,
      trades: state.trades.map,
      requiredActions: state.quasiStatics.requiredActionsMap,
      subCategoriesMap: state.quasiStatics.subCategoriesMap,
      urlParams: state.ui.urlParams,
    }),
    {
      uploadNewPost,
      createLocalPost,
      getNewPostId,
      onDraftModeChange,
      draftValidator,
      startToast,
    }
  )
);
NewPostPage = NewPostPageEnhance(NewPostPage);
