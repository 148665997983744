import { primaryColor } from "../../material-dashboard-pro-react.jsx";
import mainTheme from '../../../css/theme'

const accordionStyle = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: "20px",
    '&:first-child': {
      borderRadius: '7px',
    },
    '&:last-child': {
      borderRadius: '7px',
    },
  },
  expansionPanel: {
    marginTop: '15px',
    borderRadius: '0 6px 6px',
    boxShadow:'none',
    transition: "all 150ms ease-in",
    '&:first-child': {
      borderRadius: '7px',
    },
    '&:last-child': {
      borderRadius: '7px',
    },
    "&:before": {
      display: "none !important"
    }
  },
  expansionPanelExpanded: {
    borderRadius: '0 6px 6px',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    border: '1px solid ' + mainTheme.brandPrimary
  },
  expansionPanelSummary: {
    justifyContent: 'center',
    minHeight: "auto !important",
    borderTopRightRadius: "7px",
    borderTopLeftRadius: "7px",
    padding: "5px",
    color: "#3C4858",
    "&:hover": {
      color: primaryColor
    }
  },
  expansionPanelSummaryExpaned: {
    color: primaryColor,
    "& $expansionPanelSummaryExpandIcon": {
      [theme.breakpoints.up("md")]: {
        top: "auto !important"
      },
      transform: "rotate(180deg)",
      [theme.breakpoints.down("sm")]: {
        top: "10px !important"
      }
    }
  },
  expansionPanelSummaryContent: {
    margin: "0 !important"
  },
  expansionPanelSummaryExpandIcon: {
    [theme.breakpoints.up("md")]: {
      top: "auto !important"
    },
    transform: "rotate(0deg)",
    color: "inherit",
    [theme.breakpoints.down("sm")]: {
      top: "10px !important"
    }
  },
  expansionPanelSummaryExpandIconExpanded: {},
  title: {
    display: 'flex',
    flex: 1,
    fontSize: "15px",
    fontWeight: "bolder",
    marginTop: "0",
    marginBottom: "0",
    color: "inherit"
  },
  expansionPanelDetails: {
    padding: "15px 0px 5px"
  }
});

export default accordionStyle;
