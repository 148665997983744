import _ from 'lodash';
import { platformActions } from '../../common/platformActions';
import { envParams } from '../../common/configureMiddleware';
import { flattenObject, onError }  from '../../common/app/funcs';
import { DEFAULT_PERMISSIONS_PATH_DELIMETER } from '../../common/permissions/funcs';

const DEFAULT_OBJECT_SOURCE = 'project';

export const encodeFilterToSearch = (filterObject, originalSearch, filterUrlKey = 'filter',) => {
  const filterString = Object.entries(filterObject || {}).reduce((acc, [key, val]) => `${acc}&${key}=${val.toString()}`, '');

  let newSearch = new URLSearchParams(originalSearch);
  if (filterString)
    newSearch.set(filterUrlKey, encodeURI(filterString));
  else
    newSearch.delete(filterUrlKey);

  return newSearch.toString();
}

export const decodeFiltersFromSearch = (locationSearch, filterUrlKey = 'filter', pathDelimeter = DEFAULT_PERMISSIONS_PATH_DELIMETER) => {
  const urlSearchParamsObj = new URLSearchParams(locationSearch);
  const filterParams = decodeURI(urlSearchParamsObj.get(filterUrlKey) || '');
  const filterParamsObj = new URLSearchParams(filterParams);

  let originalFilterObj = {};
  let filterQuery = {};
  filterParamsObj.forEach((paramVal, paramKey) => {
    const objKey = paramKey.indexOf('TARGET') !== -1 ? 'target' : `target${pathDelimeter}${paramKey}`;
    const parsedVal = paramVal.split(',');
    filterQuery[objKey] = parsedVal.length === 1 ? parsedVal[0] : parsedVal;
    originalFilterObj[paramKey] = parsedVal.reduce((acc, val) => _.set(acc, [val], val), {});
  });

  return { cementoQuery: Object.keys(filterQuery).length ? [filterQuery] : null, originalFilterObj };
}