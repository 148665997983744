import {getSnapshotData} from '../lib/utils/utils';

export const LOAD_PROJECT_TRADES = 'LOAD_PROJECT_TRADES';
export const CLEAN_CACHED_TRADES = 'CLEAN_CACHED_TRADES';
export const GET_TRADES = 'GET_TRADES';

export function getTrades(inLastUpdateTS) {
  const getPromise = async () => {
    const snapShot = await getSnapshotData({ api: 'trades' });
    return { ...(snapShot || {}), lastUpdateTS: inLastUpdateTS };
  };
  return {
    type: GET_TRADES,
    payload: getPromise()
  };
}

export function setProjectTrades(projectId, members, projectChecklistItems) {

  let trades = {};
  (members || []).map(member => {
    Object.keys(member.trades || {}).forEach(tradeId => {
      if (Boolean(!trades[tradeId]))
        trades[tradeId] = { id: tradeId };
    });
  });

  if (projectChecklistItems)
    projectChecklistItems.loopEach((id, checklistItem) => {
      if (checklistItem && checklistItem.trade && !trades[checklistItem.trade])
        trades[checklistItem.trade] = { id: checklistItem.trade };
    });

  return {
    type: LOAD_PROJECT_TRADES,
    payload: { projectId, trades }
  };
}

