import React from "react";
import { compose, hoistStatics } from "recompose";
import { connect } from "react-redux";

// Components
import Text from "../../components/CementoComponents/Text";

// Others
import theme from "../../assets/css/theme";

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    const { onClick } = this.props;
    if (onClick) onClick(e);
  }

  render() {
    const { rtl, title, style = {}, values } = this.props;
    return (
      <>
        <div
          onClick={this.onClick}
          style={{
            cursor: "pointer",
            margin: 5,
            border: "1px solid #00000010",
            backgroundColor: theme.backgroundColorBright,
            color: theme.brandPrimary,
            padding: "5px 15px",
            borderRadius: 25,
            boxShadow: "#00000015 0px 0px 10px 0px",
            textAlign: "center",
            ...style,
          }}
        >
          {Boolean(title) && <Text values={values}>{title}</Text>}
        </div>
      </>
    );
  }
}

const enhance = compose(
  connect(
    (state) => ({
      rtl: state.app.rtl,
    }),
    {}
  )
);

export default enhance(Button);
