import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { connectContext } from 'react-connect-context';
import { ProjectContext, FiltersSortsContext } from '../../../common/projects/contexts';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { track, setReportingScope } from '../../../common/lib/reporting/actions';
import {
	endChecklistItemsInstancesListener,
	getChecklistItemsInstances,
} from '../../../common/checklistItemsInstances/actions';
import Analytics from './QCAnalytics';
import PropertyAnalytics from '../Properties/PropertyAnalytics';
import ProjectManager from './ProjectManager';
import ProjectManager2_0 from '../ProjectManager/ProjectManager2_0';
import FormsManagerWrapper from '../Forms/FormManagerProjectWrapper';
import LocationContainerPage from '../../views/Locations/LocationContainerPage';
import { getBuildings } from '../../../common/buildings/actions';
import { saveURLParams, saveUIParams } from '../../../common/ui/actions';
import { getPropertiesTypes, getPropertiesTypeById } from '../../../common/propertiesTypes/actions';
import { getPropertiesMappings } from '../../../common/propertiesMappings/actions';
import { getPropertiesInstances, endPropertiesInstancesListener } from '../../../common/propertiesInstances/actions';
import { getFloors } from '../../../common/floors/actions';
import { getUnits } from '../../../common/units/actions';
import { getConfigurations } from '../../../common/configurations/actions';
import { setProjectTrades } from '../../../common/trades/actions';
import { getLastUpdatesForProjects } from '../../../common/lastUpdates/actions';
import { getPosts, endPostsListener } from '../../../common/posts/actions';
import { getUsersByProject } from '../../../common/members/actions';
import { startCompaniesListener } from '../../../common/companies/actions';
import { setLastVisitedProject } from '../../../common/users/actions';
import {
	getUserProjects,
	getProjectDetails,
	enterProject,
	leaveProject,
	loadProjectStorage,
	saveProjectStorage,
} from '../../../common/projects/actions';
import { getChecklists } from '../../../common/checklists/actions';
import { getChecklistItems } from '../../../common/checklistItems/actions';
import { startLoading, hideLoading, setLang } from '../../../common/app/actions';
import { endProjectListener } from '../../../common/lib/utils/utils';

import ChecklistsManager from '../Checklists/ChecklistsManager';
import PropertiesManagerPage from '../Properties/PropertiesManagerPage';
import NoItemsFound from '../../components/CementoComponents/NoItemsFound';
import theme from '../../assets/css/theme';
import projectsMessages from '../../../common/projects/projectsMessages';
import Dashboard from '../Dashboard/Dashboard.js';
import { HeaderLinks } from '../../components';
import Companies from '../Companies/Companies.js';
import { getStages } from '../../../common/stages/actions';
import _ from 'lodash';
import CamerasManagerPage from '../Cameras/CamerasManagerPage';
import { PostsHOC } from '../../../common/posts/hooks/usePosts';
import { DEFAULT_LAST_UPDATE_TS } from '../../../common/app/constants';

const qs = require('qs');


const PROPERTY_ANALYTICS_ROUTES_CONTENT_TYPES = [
  'forms',
  'info',
  'safety',
  'siteControl'
];

class ProjectContainerPage extends React.Component {
  constructor(props) {
    super(props);
    this.checkProjectDidLoad = this.checkProjectDidLoad.bind(this);
    this.checkIfLastUpdateAvailableChanged = this.checkIfLastUpdateAvailableChanged.bind(this);
    this.handleUnmountBeforeBrowserExit = this.handleUnmountBeforeBrowserExit.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.setHeaderParams = this.setHeaderParams.bind(this);
    this.handleEnterProject = this.handleEnterProject.bind(this);
    this.handleLeaveProject = this.handleLeaveProject.bind(this);
    this.handleUnmount = this.handleUnmount.bind(this);
    this.updateLanguageIfDiff = this.updateLanguageIfDiff.bind(this);
    this.closeProjectsListener = this.closeProjectsListener.bind(this);
    this.projectsIndications = { listeners: {}, companiesListener: {} };
    this.loadingInterval = {};
    this.state = {
      filteredPosts: [],
      filterVal: "",
    };
  }

  handleUnmount(beforeBrowserExit) {
    const { selectedProjectId, projectReducersLoaded } = this.props;
    if (selectedProjectId) {
      if (!beforeBrowserExit || (beforeBrowserExit && projectReducersLoaded))
        this.handleLeaveProject(selectedProjectId);
    }
    this.props.hideLoading();
    window.removeEventListener("beforeunload", this.handleUnmountBeforeBrowserExit);
  }

  handleUnmountBeforeBrowserExit() {
    this.handleUnmount(true);
  }

  componentWillUnmount() {
    this.handleUnmount();
  }

  componentWillMount() {
    window.addEventListener("beforeunload", this.handleUnmountBeforeBrowserExit);
    let selectedProjectId = this.props.match.params.selectedProjectId;

    if (selectedProjectId)
      this.handleEnterProject({}, this.props);
    this.setComponentData({}, this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { match } = this.props;

    this.handleEnterProject(this.props, nextProps);
    if (match.params.selectedProjectId && match.params.selectedProjectId != nextProps.match.params.selectedProjectId)
      this.handleLeaveProject(match.params.selectedProjectId);

    this.setComponentData(this.props, nextProps);
  }

  async handleEnterProject(props, nextProps) {
    const { getUsersByProject, loadProjectStorage, enterProject, setLastVisitedProject, projects, track, setReportingScope, } = nextProps;
    let URL_projectId = props.getNested(["match", "params", "selectedProjectId"]);
    let nextURL_projectId = nextProps.getNested(["match", "params", "selectedProjectId"]);

    if (nextURL_projectId && !this.waitForIntlProviderRerender) {
      if (URL_projectId != nextURL_projectId) {
        track("enterProject", { eventProjectId: nextURL_projectId });

        // IMPORTANT! - this happens before enterProject() in case the lenguage of the page is going to rerander all page...
        setLastVisitedProject(
          projects.getNested([nextURL_projectId], {}),
          projects.getNested([nextURL_projectId], {}),
          projects.getNested([URL_projectId], {}),
          true,
          false,
        );

        this.updateLanguageIfDiff(nextProps, nextURL_projectId);
        if (this.waitForIntlProviderRerender) return; // IMPORTANT! - setLang() is going to rerender the intlProvider and everything else, so in that case we "waitForIntlProviderRerender=true" and break the sequence

        enterProject(nextURL_projectId);
        setReportingScope({
          viewer: nextProps.viewer,
          projectId: nextURL_projectId,
        });
      } else if (nextURL_projectId == nextProps.selectedProjectId) {
        let projectId = props.selectedProjectId;
        let nextProjectId = nextProps.selectedProjectId;
        if (!nextProjectId || nextProjectId == "null") return null;

        this.updateLanguageIfDiff(nextProps, nextProjectId);
        if (this.waitForIntlProviderRerender) return; // IMPORTANT! - setLang() is going to rerender the intlProvider and everything else, so in that case we "waitForIntlProviderRerender=true" and break the sequence

        if (nextProps.projectReducersLoaded)
          this.checkProjectDidLoad(props, nextProps);

        if (projectId !== nextProjectId && !nextProps.projectReducersLoaded) {
          await loadProjectStorage(nextProjectId, undefined, projectId);
          this.checkProjectDidLoad(props, nextProps);
        }
        // Only after => projectStorage is loaded || we change projects and projectStorage already loaded || we're in the same project but lastUpdateAvailable has been chenaged
        else if ((props.projectReducersLoaded != nextProps.projectReducersLoaded && nextProps.projectLokiLoaded) ||
          (props.projectLokiLoaded != nextProps.projectLokiLoaded && nextProps.projectReducersLoaded) ||
          (projectId != nextProjectId && nextProps.projectReducersLoaded && nextProps.projectLokiLoaded) ||
          (nextProps.projectReducersLoaded && nextProps.projectLokiLoaded && this.checkIfLastUpdateAvailableChanged(props, nextProps)) ||
          //(props.companiesDidLoad != nextProps.companiesDidLoad && nextProps.companiesDidLoad) ||
          (props.storageCleaned != nextProps.storageCleaned)) {

            if (props.storageCleaned != nextProps.storageCleaned)
              this.closeProjectsListener(nextProjectId);

            this.getProjectData(props, nextProps);
        }

        if (nextProps.getNested(["detailedProjects", nextProjectId, "members"]) != props.getNested(["detailedProjects", nextProjectId, "members"])) {
          getUsersByProject(nextProjectId);
        }
      }
    }
  }

  updateLanguageIfDiff(nextProps, projectId) {
    const { setLang } = nextProps;
    if (!this.waitForIntlProviderRerender) {
      let newLang = nextProps.getNested(["projectsMap", projectId, "lang"], "en");
      let currLang = nextProps.lang;
      if (currLang != newLang) {
        this.waitForIntlProviderRerender = true;
        setLang(newLang);
      }
    }
  }
  
  closeProjectsListener(projectId) {   
    if (this.projectsIndications.listeners[projectId]) {
      this.projectsIndications.listeners[projectId] = false;
      try {
        endProjectListener(projectId);
      }
      catch (err) {
        this.projectsIndications.listeners[projectId] = true;
      }
    }
  }

  handleLeaveProject(projectId) {
    const { loading, hideLoading, leaveProject } = this.props;
    this.closeProjectsListener(projectId);
    leaveProject(projectId);
    if (loading) hideLoading();
    if (this.saveProjectStorageInterval) clearInterval(this.saveProjectStorageInterval);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};
    if (props.isValDiff(nextProps, ["urlParams", "scope"]))
      if (nextProps.getNested(["urlParams", "scope"]) == "company") {
        let selectedCompanyId = nextProps.getNested(["urlParams", "selectedCompanyId"], "_");
        nextProps.history.push(`/main/companyContainerPage/${selectedCompanyId}/_`);
      }


    if (props.isValDiff(nextProps, ["match", "params", "contentType"]))
      nextProps.saveURLParams({ contentType: nextProps.getNested(["match", "params", "contentType"]) });

    if (props.isValDiff(nextProps, ["match", "params", "section"]))
      nextProps.saveURLParams({ section: nextProps.getNested(["match", "params", "section"]) });

    if (props.isValDiff(nextProps, ["location", "search"])) {
      let queryParams = qs.parse(nextProps.getNested(["location", "search"], "?").replace("?", ""));
      nextProps.saveURLParams({ queryParams });
    }

    if (props.isValDiff(nextProps, ['location', 'hash'])) 
      nextProps.saveURLParams({ hash: nextProps.getNested2(['location', 'hash'], '').slice(1) });

    if (props.isValDiff(nextProps, ["rtl"]))
      document.body.style.direction = nextProps.rtl ? "rtl" : "ltr";

    if (nextProps.cleanCacheRevokes && props.cleanCacheRevokes != nextProps.cleanCacheRevokes)
      this.getProjectData(props, nextProps);

    if (props.isValDiff(nextProps, ["filtersView"]) ||
      props.isValDiff(nextProps, ["filters"]) ||
      props.isValDiff(nextProps, ["buildings"]) ||
      props.isValDiff(nextProps, ["viewer"]) ||
      props.isValDiff(nextProps, ["urlParams", "contentType"]) ||
      props.isValDiff(nextProps, ["urlParams", "page"]) ||
      props.isValDiff(nextProps, ["location", "search"]) ||
      props.isValDiff(nextProps, ["detailedProjects", nextProps.selectedProjectId]) ||
      props.isValDiff(nextProps, ["selectedProjectId"])){
      newStateChanges = { ...newStateChanges, filterVal: '' }
    }

    this.checkAndHandleSpecificPropsUpdates(props, nextProps)

    if (Object.keys(newStateChanges).length > 0)
      this.setState(newStateChanges);
  }


  checkAndHandleSpecificPropsUpdates(prevProps, nextProps) {
    const { getPropertiesTypeById, selectedProjectId, specificPropertiesUpdatesAvailable: _specificPropertiesUpdatesAvailable } = nextProps;
    const specificPropertiesUpdatesAvailable = (_specificPropertiesUpdatesAvailable && _specificPropertiesUpdatesAvailable.toJS) ? _specificPropertiesUpdatesAvailable.toJS() : _specificPropertiesUpdatesAvailable;
    const currProjectsSpecificPropertiesUpdatesAvailable = _.get(specificPropertiesUpdatesAvailable, selectedProjectId)
    _.forIn(currProjectsSpecificPropertiesUpdatesAvailable, (subjectProps, subjectName) => {
      _.forIn(subjectProps, (prop, propId) => {
        let prevLastUpdateTS = prevProps.getNested(['specificPropertiesUpdatesAvailable', selectedProjectId, subjectName, propId, 'lastUpdateTS']);
        let nextLastUpdateTS = (prop || {}).lastUpdateTS;
        let isChanged = prevLastUpdateTS != nextLastUpdateTS;
        if (nextLastUpdateTS && isChanged) {
          getPropertiesTypeById(subjectName, propId, selectedProjectId);
        }
      });
    });
  }

  clearFilterVal = () => {
    this.setState({ filterVal: "" });
  };

  setFilterVal = filterVal => {
    this.setState({ filterVal });
  };

  checkIfLastUpdateAvailableChanged(props, nextProps) {
    const { selectedProjectId } = nextProps;
    let bIsLastUpdateAvailableChanged = (
      props.isValDiff(nextProps, ["projectsLastUpdateAvailable", selectedProjectId]) ||
      props.isValDiff(nextProps, ["configurationsLastUpdateAvailable", selectedProjectId]) ||
      props.isValDiff(nextProps, ["buildingsLastUpdateAvailable", selectedProjectId]) ||
      props.isValDiff(nextProps, ["floorsLastUpdateAvailable", selectedProjectId]) ||
      props.isValDiff(nextProps, ["unitsLastUpdateAvailable", selectedProjectId]) ||
      props.isValDiff(nextProps, ["drawingsLastUpdateAvailable", selectedProjectId]) ||
      props.isValDiff(nextProps, ["checklistsLastUpdateAvailable", selectedProjectId]) ||
      props.isValDiff(nextProps, ["checklistItemsLastUpdateAvailable", selectedProjectId]) ||
      props.isValDiff(nextProps, ["propertiesTypesLastUpdateAvailable", selectedProjectId]) ||
      props.isValDiff(nextProps, ["propertiesMappingsLastUpdateAvailable", selectedProjectId]) ||
      props.isValDiff(nextProps, ["instancesLastRevokeAvailable", selectedProjectId]) ||
      props.isValDiff(nextProps, ["postsLastRevokeAvailable", selectedProjectId]) ||
      props.isValDiff(nextProps, ["propertyInstancesLastRevokeAvailable", selectedProjectId])
    );
    return bIsLastUpdateAvailableChanged;
  }

  checkProjectDidLoad(props, nextProps) {
    const { hideLoading, saveProjectStorage, selectedProjectId, startLoading } = nextProps;
    let propsRelevantForLoadingIndication = [
      ["buildingsDidLoad", selectedProjectId],
      ["floorsDidLoad", selectedProjectId],
      ["unitsDidLoad", selectedProjectId],
      ["postsDidLoad", selectedProjectId],
      ["trades", "size"],
    ];
    let allNextPropsLoaded = true;
    let atLeastOneOfPrevPropsWasntLoadedYet = false;

    propsRelevantForLoadingIndication.forEach(propPath => {
      allNextPropsLoaded = allNextPropsLoaded && Boolean(nextProps.getNested(propPath));
      atLeastOneOfPrevPropsWasntLoadedYet = atLeastOneOfPrevPropsWasntLoadedYet || !Boolean(props.getNested(propPath));
    });

    // Means that the last relevant project storage was loaded
    // if we are in dashboard page URL
    let isDashboardLocation = nextProps.getNested(['location', 'pathname'], '').indexOf('dashboard') != -1;
    if (isDashboardLocation && (nextProps.loading || this.props.loading)) {
      hideLoading();
      //return;
    }

    // Means that the last relevant project storage was loaded
    if ((allNextPropsLoaded && atLeastOneOfPrevPropsWasntLoadedYet) ||
      (allNextPropsLoaded && props.selectedProjectId != nextProps.selectedProjectId)) {
      if (!this.loadingInterval[nextProps.selectedProjectId])
        this.loadingInterval[nextProps.selectedProjectId] = setInterval((() => {
          if (nextProps.loading || this.props.loading) {
            hideLoading();
            clearInterval(this.loadingInterval[nextProps.selectedProjectId]);
            this.loadingInterval[nextProps.selectedProjectId] = null;
            delete this.loadingInterval[nextProps.selectedProjectId];
          }
        }).bind(this), 100);

      setTimeout(() => saveProjectStorage(selectedProjectId), 6000);
      clearInterval(this.saveProjectStorageInterval);
      this.saveProjectStorageInterval = setInterval(() => saveProjectStorage(selectedProjectId), 30000);
    } else if (!isDashboardLocation && !nextProps.loading && !allNextPropsLoaded)
      startLoading({ title: projectsMessages.loadingProjectData, overlay: true });

    if (!selectedProjectId)
      hideLoading();
  }

  async getProjectData(props, nextProps, force) {
    const {
      getPropertiesTypes, getPropertiesMappings, getPropertiesInstances,
      startCompaniesListener, getProjectDetails, getBuildings,
      getFloors, getUnits,
      getConfigurations, getChecklistItems, getChecklists,
      getChecklistItemsInstances, getPosts, getStages,
    } = nextProps;

    const nextProjectId = nextProps.selectedProjectId;

    if (!nextProjectId || nextProjectId == "null") {
      return null;
    }
    const configLastUpdatedAtLocal = nextProps.getNested(["configurationsLastUpdated", nextProjectId], 0);
    const configLastUpdatedAtRemote = nextProps.getNested(["configurationsLastUpdateAvailable", nextProjectId], 1);
    //load conf in *await* only if first time
    if (!nextProps.getNested(["configurationsDidLoad", nextProjectId])) {
      await getConfigurations('projects', nextProjectId);
    } else if(configLastUpdatedAtLocal < configLastUpdatedAtRemote) {
      getConfigurations('projects',nextProjectId);
    }


    if (!nextProps.getNested(["projectReducersLoaded"]) || nextProps.getNested(["projectsLastUpdated", nextProjectId], 0) < nextProps.getNested(["projectsLastUpdateAvailable", nextProjectId], DEFAULT_LAST_UPDATE_TS))
      getProjectDetails(nextProjectId);

    if (!nextProps.getNested(["buildingsDidLoad", nextProjectId]) || nextProps.getNested(["buildingsLastUpdated", nextProjectId], 0) < nextProps.getNested(["buildingsLastUpdateAvailable", nextProjectId], DEFAULT_LAST_UPDATE_TS))
      getBuildings(nextProjectId);

    if (!nextProps.getNested(["floorsDidLoad", nextProjectId]) || nextProps.getNested(["floorsLastUpdated", nextProjectId], 0) < nextProps.getNested(["floorsLastUpdateAvailable", nextProjectId], DEFAULT_LAST_UPDATE_TS))
      getFloors(nextProjectId);

    if (!nextProps.getNested(["unitsDidLoad", nextProjectId]) || nextProps.getNested(["unitsLastUpdated", nextProjectId], 0) < nextProps.getNested(["unitsLastUpdateAvailable", nextProjectId], DEFAULT_LAST_UPDATE_TS))
      getUnits(nextProjectId);

    if (!nextProps.getNested(["propertiesMappingsDidLoad", nextProjectId]) || nextProps.getNested(["propertiesMappingsLastUpdated", nextProjectId], 0) < nextProps.getNested(["propertiesMappingsLastUpdateAvailable", nextProjectId], DEFAULT_LAST_UPDATE_TS))
      getPropertiesMappings(nextProjectId);

    if (!nextProps.getNested(["propertiesTypesDidLoad", nextProjectId]) || nextProps.getNested(["propertiesTypesLastUpdated", nextProjectId], 0) < nextProps.getNested(["propertiesTypesLastUpdateAvailable", nextProjectId], DEFAULT_LAST_UPDATE_TS))
      getPropertiesTypes(nextProjectId);

    if (nextProps.viewer) {
      if (!nextProps.getNested(["stagesDidLoad", nextProjectId]) || nextProps.getNested(["stagesLastUpdated", nextProjectId], 0) < nextProps.getNested(["stagesLastUpdateAvailable", nextProjectId], DEFAULT_LAST_UPDATE_TS)) {
        getStages(nextProjectId);
      }

      if (!nextProps.getNested(["checklistsDidLoad", nextProjectId]) || nextProps.getNested(["checklistsLastUpdated", nextProjectId], 0) < nextProps.getNested(["checklistsLastUpdateAvailable", nextProjectId], DEFAULT_LAST_UPDATE_TS)) {
        getChecklists(nextProps.viewer, nextProjectId);
      }

      if (!nextProps.getNested(["checklistItemsDidLoad", nextProjectId]) || nextProps.getNested(["checklistItemsLastUpdated", nextProjectId], 0) < nextProps.getNested(["checklistItemsLastUpdateAvailable", nextProjectId], DEFAULT_LAST_UPDATE_TS)) {
        getChecklistItems(nextProps.viewer, nextProjectId);
      }

      if (nextProps.viewer && nextProjectId && !this.projectsIndications.listeners[nextProjectId]) {
        try {
          this.projectsIndications.listeners[nextProjectId] = true;
          let revokeInstances = nextProps.getNested(["instancesLastRevoked", nextProjectId], 0) < nextProps.getNested(["instancesLastRevokeAvailable", nextProjectId], 0);
          let revokePosts = nextProps.getNested(["postsLastRevoked", nextProjectId], 0) < nextProps.getNested(["postsLastRevokeAvailable", nextProjectId], 0);
          
          const propertyInstancesSubjects = ["locationsInfo", "employeesInfo", "equipmentInfo", "checklistItemsInfo", "formsInfo", 'postsInfo'];
          propertyInstancesSubjects.forEach(subjectName => {
            let revokePropsInstances = nextProps.getNested(["propertyInstancesLastRevoked", nextProjectId, subjectName], 0) < nextProps.getNested(["propertyInstancesLastRevokeAvailable", nextProjectId, subjectName], 0);
            getPropertiesInstances(nextProps.viewer,nextProjectId, subjectName, revokePropsInstances);
          });
  
          getChecklistItemsInstances(nextProps.viewer, nextProjectId, revokeInstances);
          getPosts(nextProps.viewer, nextProjectId, revokePosts);
  
          startCompaniesListener(!nextProps.viewer.adminMode ? nextProjectId : null);
        }
        catch (err) {
          this.projectsIndications.listeners[nextProjectId] = false;
        }
      }
    }
  }

  setHeaderParams(headerParams) {
    const { match, uiParams, menus, saveUIParams } = this.props;
    let contentType = match.params.contentType;
    let isOnlySideNav = Boolean((!headerParams || !headerParams.headerComponent) && menus[contentType]);
    if (uiParams.getNested(["onlySideNav"]) != isOnlySideNav)
      saveUIParams({ onlySideNav: isOnlySideNav });
    this.setState({ headerParams });
  }

  getPostsFilters = () => {
    return {
      contentType: this.props.getNested(['urlParams', "contentType"]) == "safety" ? 'safety' : null,
      postsType: this.props.getNested(['urlParams', "contentType"]) == "records" ? 'records' : this.props.getNested(['urlParams', "queryParams", "itemType"], null),
      locationSearch: this.props.getNested(['location', 'search'], null), // For the filter component, will be looking at the "filter" param in the URL
      filterValue: this.state.filterVal,
    }
  }

  render() {
    const { match, menus, projectPermitted, intl, location, selectedProjectId, viewer, project } = this.props;
    const { headerParams, filterVal } = this.state;

    if (!selectedProjectId)
      return null;

    let URL = match.url.endsWith("/") ? match.url : match.url + "/";
    let query = this.props.getNested(["location", "search"], "");

    if (!projectPermitted)
      return (<NoItemsFound key={"projectPermitted"} style={{ fontSize: 22, color: theme.brandPrimary, margin: 5 }} message={projectsMessages.notPermittedToWebProject} />);

    // return <InputTesting />

    return (
      <PostsHOC filters={this.getPostsFilters()}>
        {({ filteredPosts, currViewPosts }) => (
          <div style={{ display: "flex", flexDirection: headerParams && headerParams.headerComponent ? "column" : "row", flex: 1 }}         >
            <HeaderLinks key="secondaryHeader" defaultSelection={null} headerParams={headerParams} routingMode={true} menus={menus} menuMode={true} intl={intl} location={location} isSecondary={true} />
            <Switch>
              <Route path="/main/projectContainerPage/:selectedProjectId/qa/analytics/:reportId" render={props => (<Analytics setHeaderParams={this.setHeaderParams} history={props.history} location={props.location} match={props.match} />)} />
              <Route path="/main/projectContainerPage/:selectedProjectId/qa/analytics" render={props => (<Analytics setHeaderParams={this.setHeaderParams} history={props.history} location={props.location} match={props.match} />)} />
              <Route path="/main/projectContainerPage/:selectedProjectId/qa/dashboard" render={props => (<Dashboard setHeaderParams={this.setHeaderParams} contentType={"qa"} history={props.history} location={props.location} match={props.match} />)} />
              {PROPERTY_ANALYTICS_ROUTES_CONTENT_TYPES.map(contentType => (
                <Route
                  key={`route_${contentType}_analytics_report`}
                  path={`/main/projectContainerPage/:selectedProjectId/${contentType}/analytics/:reportId`}
                  render={props => (
                    <PropertyAnalytics
                      setHeaderParams={this.setHeaderParams}
                      contentType={contentType}
                      history={props.history}
                      location={props.location}
                      match={props.match}
                    />
                  )}
                />
              ))}
              {PROPERTY_ANALYTICS_ROUTES_CONTENT_TYPES.map(contentType => (
                <Route
                  key={`route_${contentType}_analytics`}
                  path={`/main/projectContainerPage/:selectedProjectId/${contentType}/analytics`}
                  render={props => (
                    <PropertyAnalytics
                      setHeaderParams={this.setHeaderParams}
                      contentType={contentType}
                      history={props.history}
                      location={props.location}
                      match={props.match}
                    />
                  )}
                />
              ))}
              <Route path="/main/projectContainerPage/:selectedProjectId/safety/dashboard" render={props => (<Dashboard setHeaderParams={this.setHeaderParams} contentType={"safety"} history={props.history} location={props.location} match={props.match} />)} />
              {false && <Route path="/main/projectContainerPage/:selectedProjectId/companies" render={props => (<Companies setHeaderParams={this.setHeaderParams} history={props.history} location={props.location} match={props.match} />)} />}
              {false && <Route path="/main/projectContainerPage/:selectedProjectId/safety/multiProjectOverview" render={props => (<Dashboard multiProjectViewMode={true} setHeaderParams={this.setHeaderParams} contentType={"safety"} history={props.history} location={props.location} match={props.match} />)} />}
              <Route path="/main/projectContainerPage/:selectedProjectId/tasksView/dashboard" render={props => (<Dashboard setHeaderParams={this.setHeaderParams} contentType={"issues"} history={props.history} location={props.location} match={props.match} />)} />

              <Route path="/main/projectContainerPage/:selectedProjectId/projectManager/locationsGroupsManagement" render={props => (<PropertyAnalytics setHeaderParams={this.setHeaderParams} contentType={"projectManager"} history={props.history} location={props.location} match={props.match} customSubMenuType={'settings'} customReportId={'locationsGroupsManagement'} />)} />
              <Route path="/main/projectContainerPage/:selectedProjectId/camerasDashboard" render={props => (<CamerasManagerPage setHeaderParams={this.setHeaderParams} history={props.history} match={props.match} />)} />

              <Route path="/main/projectContainerPage/:selectedProjectId/projectManager/formsManager" render={props => (<FormsManagerWrapper setHeaderParams={this.setHeaderParams} history={props.history} match={props.match} />)} />
              <Route path="/main/projectContainerPage/:selectedProjectId/projectManager/:section" render={props => (<ProjectManager2_0 setHeaderParams={this.setHeaderParams} contentType={"projectManager"} history={props.history} match={props.match} />)} />
              {/*old project manager*/}
              <Route path="/main/projectContainerPage/:selectedProjectId/projectManager2" render={props => (<ProjectManager setHeaderParams={this.setHeaderParams} history={props.history} match={props.match} />)} />

              {Boolean(viewer.adminMode) && <Route path="/main/projectContainerPage/:selectedProjectId/oldChecklistsManager" render={props => (<ChecklistsManager setHeaderParams={this.setHeaderParams} history={props.history} match={props.match} />)} />}

              <Route path="/main/projectContainerPage/:selectedProjectId/PropertiesManager" render={props => (<PropertiesManagerPage setHeaderParams={this.setHeaderParams} history={props.history} match={props.match} projectDetails={project} />)} />
              <Route path="/main/projectContainerPage/:selectedProjectId/records/dashboard" render={props => (<Dashboard setHeaderParams={this.setHeaderParams} contentType={"records"} history={props.history} location={props.location} match={props.match} />)} />
              <Route path="/main/projectContainerPage/:selectedProjectId/issues/dashboard" render={props => (<Dashboard setHeaderParams={this.setHeaderParams} contentType={"issues"} history={props.history} location={props.location} match={props.match} />)} />
              <Route path="/main/projectContainerPage/:selectedProjectId/:contentType/locationContainerPage/:buildingId/:floorId/:unitId" render={props => (<LocationContainerPage setHeaderParams={this.setHeaderParams} history={props.history} location={props.location} match={props.match} filteredPosts={filteredPosts} currViewPosts={currViewPosts} filterVal={filterVal} clearFilterVal={this.clearFilterVal} setFilterVal={this.setFilterVal} />)} />
              <Route path="/main/projectContainerPage/:selectedProjectId/:contentType/locationContainerPage" render={props => (<LocationContainerPage setHeaderParams={this.setHeaderParams} history={props.history} location={props.location} match={props.match} filteredPosts={filteredPosts} currViewPosts={currViewPosts} filterVal={filterVal}  clearFilterVal={this.clearFilterVal} setFilterVal={this.setFilterVal} />)} />
              <Route path="/main/projectContainerPage/:selectedProjectId/info" render={() => <Redirect to={URL + "analytics" + query} />} />
              <Route path="/main/projectContainerPage/:selectedProjectId/forms" render={() => <Redirect to={URL + "analytics" + query} />} />
              <Route path="/main/projectContainerPage/:selectedProjectId/safety" render={() => <Redirect to={URL + "dashboard" + query} />} />
              <Route path="/main/projectContainerPage/:selectedProjectId/qa" render={() => <Redirect to={URL + "dashboard" + query} />} />
              <Route path="/main/projectContainerPage/:selectedProjectId/issues" render={() => <Redirect to={URL + "dashboard" + query} />} />
              <Route path="/main/projectContainerPage/:selectedProjectId/records" render={() => <Redirect to={URL + "dashboard" + query} />} />
              <Route exact path="/main/projectContainerPage/:selectedProjectId/_" render={() => <Redirect to={URL.replace("/_", "/issues/dashboard") + query} />} />
              <Route path="/main/projectContainerPage/:selectedProjectId/projectManager" render={() => <Redirect to={URL + "projectProperties" + query} />} />
            </Switch>
          </div>
        )}
      </PostsHOC>
    );
  }
};

ProjectContainerPage = injectIntl(ProjectContainerPage);
ProjectContainerPage = withRouter(ProjectContainerPage);
const enhance = compose(
	connectContext(ProjectContext.Consumer),
	connectContext(FiltersSortsContext.Consumer),
	connect(
		state => ({
			projectsLastUpdateAvailable: state.projects.lastUpdateAvailable,
			configurationsLastUpdateAvailable: state.configurations.lastUpdateAvailable,
			buildingsLastUpdateAvailable: state.buildings.lastUpdateAvailable,
			floorsLastUpdateAvailable: state.floors.lastUpdateAvailable,
			unitsLastUpdateAvailable: state.units.lastUpdateAvailable,
			drawingsLastUpdateAvailable: state.drawings.lastUpdateAvailable,
			stagesLastUpdateAvailable: state.stages.lastUpdateAvailable,
			checklistsLastUpdateAvailable: state.checklists.lastUpdateAvailable,
			checklistItemsLastUpdateAvailable: state.checklistItems.lastUpdateAvailable,
			propertiesTypesLastUpdateAvailable: state.propertiesTypes.lastUpdateAvailable,
			propertiesMappingsLastUpdateAvailable: state.propertiesMappings.lastUpdateAvailable,

			allCompanies: state.companies.map,
			allMembers: state.members.map,

			tradesDidLoad: state.trades.didLoad,
			configurationsDidLoad: state.configurations.didLoad,
			buildingsDidLoad: state.buildings.didLoad,
			floorsDidLoad: state.floors.didLoad,
			unitsDidLoad: state.units.didLoad,
			drawingsDidLoad: state.drawings.didLoad,
			postsDidLoad: state.posts.didLoad,
			companiesDidLoad: state.companies.didLoad,
			instancesDidLoad: state.checklistItemsInstances.didLoad,
			stagesDidLoad: state.stages.didLoad,
			checklistsDidLoad: state.checklists.didLoad,
			checklistItemsDidLoad: state.checklistItems.didLoad,
			propertiesTypesDidLoad: state.propertiesTypes.didLoad,
			propertiesMappingsDidLoad: state.propertiesMappings.didLoad,
			propertiesInstancesDidLoad: state.propertiesInstances.didLoad,

			stagesLastUpdated: state.stages.lastUpdated,
			projectsLastUpdated: state.projects.lastUpdated,
			configurationsLastUpdated: state.configurations.lastUpdated,
			buildingsLastUpdated: state.buildings.lastUpdated,
			floorsLastUpdated: state.floors.lastUpdated,
			unitsLastUpdated: state.units.lastUpdated,
			drawingsLastUpdated: state.drawings.lastUpdated,
			checklistsLastUpdated: state.checklists.lastUpdated,
			checklistItemsLastUpdated: state.checklistItems.lastUpdated,
			propertiesTypesLastUpdated: state.propertiesTypes.lastUpdated,
			specificPropertiesUpdatesAvailable: state.propertiesTypes.specificPropertiesUpdatesAvailable,
			propertiesMappingsLastUpdated: state.propertiesMappings.lastUpdated,
			cleanCacheRevokes: state.ui.cleanCacheRevokes,
			instancesLastRevoked: state.checklistItemsInstances.lastRevoked,
			propertyInstancesLastRevoked: state.propertiesInstances.lastRevoked,
			postsLastRevoked: state.posts.lastRevoked,
			instancesLastRevokeAvailable: state.checklistItemsInstances.lastRevokeAvailable,
			propertyInstancesLastRevokeAvailable: state.propertiesInstances.lastRevokeAvailable,
			postsLastRevokeAvailable: state.posts.lastRevokeAvailable,

			projectsMap: state.projects.map,
			lang: state.app.lang,
			rtl: state.app.rtl,
			urlParams: state.ui.urlParams,
			uiParams: state.ui.uiParams,
			storageCleaned: state.app.storageCleaned,
		}),
		{
			getLastUpdatesForProjects,
			getPropertiesInstances,
			getPropertiesTypes,
			getPropertiesMappings,
			setProjectTrades,
			endChecklistItemsInstancesListener,
			endPropertiesInstancesListener,
			getChecklistItemsInstances,
			getBuildings,
			getFloors,
			getUnits,
			getConfigurations,
			setLastVisitedProject,
			saveProjectStorage,
			enterProject,
			leaveProject,
			loadProjectStorage,
			getChecklistItems,
			getChecklists,
			getStages,
			getUserProjects,
			getProjectDetails,
			getUsersByProject,
			startCompaniesListener,
			startLoading,
			hideLoading,
			getPosts,
			endPostsListener,
			setLang,
			track,
			saveUIParams,
			saveURLParams,
			setReportingScope,
			getPropertiesTypeById,
		},
	),
);
export default enhance(ProjectContainerPage);
