import * as actions from './actions';
import PropertySection from './propertySection';
import PropertySelectionValue from './propertySelectionValue';
import Property from './property';
import * as lastUpdatesActions from '../lastUpdates/actions';
import { Map, OrderedMap } from 'immutable';
import { CementoRecordObject, Record } from '../transit';
import _ from 'lodash';
import { DEFAULT_LAST_UPDATE_TS } from '../app/constants';

const InitialState = Record({
	didLoad: new CementoRecordObject,
  projectSections: new CementoRecordObject,
  projectProperties: new CementoRecordObject,
  lastUpdateTS: 0,
  lastUpdated: new CementoRecordObject,
  lastUpdateAvailable: new CementoRecordObject,  
  specificPropertiesUpdatesAvailable: new CementoRecordObject 
}, 'propertiesTypes', false);

const initialState = new InitialState;

export default function propertiesReducer(state = new InitialState, action) {
  switch (action.type) {
    case lastUpdatesActions.GET_LAST_UPDATES: {
      if (!action.payload || !action.payload.projects)
        return state;

      const { projects: _projects } = action.payload;
      const projects = _projects && _projects.toJS ? _projects.toJS() : _projects;

      Object.keys(projects).forEach(projectId => {
        let TS = projects.getNested([projectId, 'properties-types', 'lastUpdateTS'], DEFAULT_LAST_UPDATE_TS);

        let specificUpdatedPropsBySubject = _.omit(projects.getNested([projectId, 'properties-types']), 'lastUpdateTS');
        if (!_.isEmpty(specificUpdatedPropsBySubject))
          _.forIn(specificUpdatedPropsBySubject, (currSubjectProps, subjectName) => {
            state = state.setIn(['specificPropertiesUpdatesAvailable', projectId, subjectName], currSubjectProps);
          });

        let curr = state.getNested(['lastUpdateAvailable', projectId], null);
        if (curr < TS) state = state.setIn(['lastUpdateAvailable', projectId], TS);
      });

      return state;
    }

    //// after scpecific prop was fetched and saved to state remove it from specificPropertiesUpdatesAvailable


    case actions.UPDATE_GROUP + "_SUCCESS":
    case actions.UPDATE_LOCAL_PROPERTIES: {
      if (!action.payload)
        return state;
        
      const { projectId, subjectName, properties } = action.payload;

      if (!projectId || !properties)
        return state;
      
      properties.loopEach((propId, prop) => {
        prop = prop.toJS ? prop.toJS() : prop;
        prop = new Property({ ...prop });
        
        state = state.setIn(['projectProperties', projectId, subjectName, prop.id], prop);
      });
      return state;
    }

    case actions.DELETE_LOCAL_PROPERTY: {
      const { projectId, subjectName, propertyId } = action.payload;

      if (!projectId || !subjectName || !propertyId)
        return state;

      return state.deleteIn(['projectProperties', projectId, subjectName, propertyId]);
    }

    case actions.GET_PROPERTIES_TYPES_BY_ID + '_SUCCESS':
    case actions.GET_PROPERTIES_TYPES + "_SUCCESS": {
      if (!action.payload?.projectId)
        return state;

      const { properties, projectId, clearOtherProperties = true } = action.payload;
      if (properties || clearOtherProperties) {
        let sectionsMap = (clearOtherProperties === true)
          ? new CementoRecordObject()
          : state.getNested(['projectSections', projectId]);
        let propertiesMap = (clearOtherProperties === true)
          ? new CementoRecordObject()
          : state.getNested(['projectProperties', projectId]);
  
        (properties || {}).loopEach((subjectName, currPropTypes) => {
          if (subjectName == 'sections' || subjectName == 'properties') return;
          currPropTypes && currPropTypes.getNested(['sections'], {}).loopEach((key, curr) => {
            if (curr) {
              sectionsMap = sectionsMap.setIn([subjectName, key],
                new PropertySection({ ...curr, id: key }));
            }
          });
          currPropTypes && currPropTypes.getNested(['properties'], {}).loopEach((key, curr) => {
            if (!curr) return;

            let values = curr?.values
              ? curr.values.map(v => new PropertySelectionValue(_.assign({}, v, { isDeleted: Boolean(v?.isUnselectable) })))
              : null;

            propertiesMap = propertiesMap.setIn([subjectName, key],
              new Property({
                ...curr,
                id: key,
                values,
                title: curr.title,
                editable: _.isNil(curr.editable) ? true : curr.editable
              }));
            state = state.removeIn(['specificPropertiesUpdatesAvailable', projectId, subjectName, key, 'lastUpdateTS'])
          });
        });

        state = state.setIn(['projectSections', projectId], sectionsMap);
        state = state.setIn(['projectProperties', projectId], propertiesMap);
      }

      state = state.setIn(['didLoad', projectId], true);
      state = state.setIn(['lastUpdated', projectId], state.getIn(['lastUpdateAvailable', projectId], DEFAULT_LAST_UPDATE_TS));
      return state;
    }
    case actions.PUT_SELECTION_LIST_OPTION + "_SUCCESS": 
    case actions.DELETE_SELECTION_LIST_OPTION + "_SUCCESS": {
      if (!action.payload)
        return state;

      const { projectId, subjectName, propId, changedValues } = action.payload;
      let originalValues = state.getNested(['projectProperties', projectId, subjectName, propId, 'values']);
      originalValues = originalValues && originalValues.toJS ? originalValues.toJS() : originalValues;
      let mergedValues = {};
      _.forIn(originalValues, (val, key) => _.set(mergedValues, [key], (val && val.toJS ? val.toJS() : val)));
      _.forIn(changedValues, (val, key) => _.set(mergedValues, [key], val));

      mergedValues = _.map(mergedValues, v => new PropertySelectionValue(_.assign({}, v, { isDeleted: v?.isUnselectable || v?.isDeleted })));

      return state.setIn(['projectProperties', projectId, subjectName, propId, 'values'], mergedValues);
    }
  }

  return state;
}