import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
	userWrapper: {
		display: 'flex',
		paddingTop: '24px',
	},
	userInfo: {
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: '12px',
	},
	image: {
		'& + *': {
			marginRight: '8px',
		},
	},
}));

const CardUser = ({ name, description, image }) => {
	const classes = useStyles();

	return (
		<div className={classes.userWrapper}>
			<Avatar src={image} className={classes.image} />
			<div className={classes.userInfo}>
				<Typography variant='body2' component='p'>
					{name}
				</Typography>
				<Typography variant='body2' component='p'>
					{description}
				</Typography>
			</div>
		</div>
	);
};

export default CardUser;
