// import 'react-calendar/dist/Calendar.css'; // must leave this import so the one below overwrites it
// import './calendar-styles.css';
import React, { useCallback, useState } from "react";
import theme from "../../../assets/css/theme";
import moment from "moment";
import Button from "../../../app/standardComponents/Button";
// import ReactCalendar from 'react-calendar';
// import { useSelector } from 'react-redux';
import { getRoundedDate } from "../../../../common/lib/utils/utils";
import Datetime from "react-datetime";
import systemMessages from "../../../../common/app/systemMessages";
import _ from "lodash";

const DOT_MARK_SIZE = 5;

// const Calendar2 = (props) => {
//   const { lang } = useSelector(state => ({ lang: state.app.lang }));
//   const { onSelectDone, markedDates, isDoneOnSelect, defaultValue } = props;

//   const [value, setValue] = useState(props.value);

//   const handleChange = useCallback(/** @param {Date | Date[]} newDate */ (newDate) => {
//       if (Array.isArray(newDate))
//         return; // TODO: handle this case, not relevant for now

//       let newTS = getRoundedDate(newDate).timestamp;
//       setValue(newTS);
//       if (isDoneOnSelect)
//         handleSelectDone(newTS);
//   }, [isDoneOnSelect, handleSelectDone]);

//   const handleSelectDone = useCallback((newTS) => {
//     if (newTS && onSelectDone)
//       onSelectDone(newTS);
//   }, [onSelectDone]);

//   const renderDateStatus = useCallback(
//     /**
//      *
//      * @param {import('react-calendar').CalendarTileProperties} props
//      * @returns
//      */
//     (props) => {
//       let dotMark = null;

//       const currTS = getRoundedDate(props.date).timestamp;
//       if (props.view === 'month' && markedDates && ((markedDates[currTS] || {}).colors || []).length) {
//         const { colors } = markedDates[currTS];
//         dotMark = colors.map(color => (
//           <div style={{ position: 'relative', height: 0, width: 0, margin: 'auto' }}>
//             <div style={{ position: 'absolute', height: DOT_MARK_SIZE, width: DOT_MARK_SIZE, backgroundColor: color, borderRadius: 50, transform: 'translate(-50%, 50%)', top: 0, left: 0, marginTop: 7 }} />
//           </div>
//         ));
//       }

//       return dotMark;
// 	}, [markedDates]);

//   const size = props.size || '100%';
//   return (
//     <div style={{ height: size, width: size }}>
//       <ReactCalendar
//         locale={props.lang || lang || 'en'}
//         showNeighboringMonth={true}
//         tileContent={renderDateStatus}
//         onChange={handleChange}
//         value={defaultValue ? new Date(defaultValue) : new Date()}
//       />
//     </div>
//   );
// }

/**
 * @typedef MarkedDate
 * @property {string[]} colors - array of color strings
 * @typedef CalendarProps
 * @property {{ [timestamp: number]: MarkedDate}} [markedDates]
 * @property {(newTS: number) => void} [onDone]
 * @property {(newTS: number) => void} [onChange]
 * @property {number} [initialValue]
 * @property {string | { id: string, defaultMessage: string }} [doneButtonTitle] - default: `systemMessages.done`
 * @property {number} [maxTS]
 *
 * @param {CalendarProps} props
 * @returns
 */

const Calendar = (props) => {
  const {
    markedDates,
    onDone,
    onChange,
    initialValue,
    doneButtonTitle,
    maxTS,
  } = props;

  const [value, setValue] = useState(initialValue || null);

  const handleChange = useCallback(
    /**
     * @param {moment.Moment | ''} newDate
     */
    (newDate) => {
      if (!newDate) return;

      let newValue = getRoundedDate(newDate.toDate()).timestamp;
      if (value !== newValue) setValue(newValue);

      if (onChange) onChange(newValue);
    },
    [onChange, value]
  );

  const handleDone = useCallback(() => {
    if (onDone) onDone(value);
  }, [value, onDone]);

  const getDotStatus = useCallback(
    /**
     * @param {Date} date
     * @returns
     */
    (date) => {
      let dotMark = null;

      const currTS = getRoundedDate(date).timestamp;
      if (
        markedDates &&
        Boolean(_.get(markedDates, [currTS, "colors", "length"]))
      ) {
        const { colors } = markedDates[currTS];
        dotMark = (
          <div
            key={currTS}
            style={{
              position: "relative",
              height: 0,
              width: 0,
              margin: "auto",
            }}
          >
            <div
              style={{
                position: "absolute",
                display: "flex",
                justifyContent:
                  colors.length === 1 ? "center" : "space-between",
                width: colors.length * DOT_MARK_SIZE + 2,
                height: DOT_MARK_SIZE,
                transform: "translate(-50%, 50%)",
                top: 0,
                left: 0,
                marginTop: -10,
              }}
            >
              {colors.map((color) => (
                <div
                  key={`${color}_${currTS}`}
                  style={{
                    height: DOT_MARK_SIZE,
                    width: DOT_MARK_SIZE,
                    borderRadius: 50,
                    backgroundColor: color,
                  }}
                />
              ))}
            </div>
          </div>
        );
      }

      return dotMark;
    },
    [markedDates]
  );

  const renderDay = useCallback(
    /**
     * @type {import("react-datetime").DatetimepickerProps['renderDay']}
     * @param {{ onClick: () => void, 'data-value': number, 'data-month': number, 'data-year': number }} props
     * @param {moment.Moment | null} currMoment
     */
    (props, currMoment, selectedDate) => {
      const currDate = currMoment.toDate();
      const isDisabled = Boolean(maxTS && currDate.getTime() > maxTS);
      const { onClick, className, ...dataAttributes } = props;
      return (
        <td
          className={className}
          onClick={!isDisabled ? onClick : undefined}
          {...dataAttributes}
          data-enabled={String(!isDisabled)}
        >
          <div {...dataAttributes} style={{ opacity: isDisabled ? 0.4 : 1 }}>
            {dataAttributes["data-value"]}
          </div>
          {getDotStatus(currDate)}
        </td>
      );
    },
    [getDotStatus]
  );

  const renderView = useCallback(
    /**
     * @type {import("react-datetime").DatetimepickerProps['renderView']}
     * @param {import("react-datetime").ViewMode} viewMode
     */
    (viewMode, renderDefault) => {
      return (
        <div>
          {renderDefault()}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: theme.verticalMargin,
            }}
          >
            <Button
              title={doneButtonTitle || systemMessages.done}
              primaryColor={theme.brandPrimary}
              onClick={handleDone ? handleDone : undefined}
              isDisabled={!value}
            />
          </div>
        </div>
      );
    },
    [handleDone, doneButtonTitle]
  );

  return (
    <Datetime
      input={false}
      timeFormat={false}
      renderDay={renderDay}
      renderView={renderView}
      onChange={handleChange}
      initialValue={initialValue ? new Date(initialValue) : null}
    />
  );
};

export default Calendar;
