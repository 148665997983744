import * as actions from './actions';
import * as lastUpdatesActions from '../lastUpdates/actions';

import Stage from './stage';

import { OrderedMap, Map } from 'immutable';
import { CementoRecordObject, Record } from '../transit';
import { DEFAULT_LAST_UPDATE_TS } from '../app/constants';

const InitialState = Record({
	map: new CementoRecordObject,
	didLoad: new CementoRecordObject,
	lastUpdated: new CementoRecordObject,
		lastUpdateAvailable: new CementoRecordObject
	}, 'stages', false);

const initialState = new InitialState();

export default function stageReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) 
    return initialState;

	switch (action.type) {

		case actions.GET_STAGES + '_SUCCESS': {
      if (!action.payload?.projectId)
        return state;

			const { projectId, stages } = (action.payload || {});

      if (stages) {
				let stagesMap = new CementoRecordObject;
				stages.loopEach((stageId, currStage) => {
					currStage = currStage.toJS ? currStage.toJS() : currStage;
					
					stagesMap = stagesMap.set(currStage.id, new Stage({ ...currStage }));
				});
				state = state.setIn(['map', projectId], stagesMap);
			}

			state = state.setIn(['didLoad', projectId], true);
			state = state.setIn(['lastUpdated', projectId], state.getNested(['lastUpdateAvailable', projectId], DEFAULT_LAST_UPDATE_TS));

			return state;
		}

		
		case lastUpdatesActions.GET_LAST_UPDATES: {
      if (!action.payload || !action.payload.projects)
        return state;
    
      const { projects } = action.payload;
      Object.keys(projects).forEach(projectId => {
        let TS = projects.getNested([projectId,'checklists-stages','lastUpdateTS'], DEFAULT_LAST_UPDATE_TS);
        let curr = state.getNested(['lastUpdateAvailable', projectId], null)
        if (curr < TS) state = state.setIn(['lastUpdateAvailable', projectId], TS);
      });
    
      return state;
		}
		
		case actions.DELETE_NEW_STAGE: {
			const { projectId, stage } = (action.payload || {});

      if (!projectId || !stage) 
        return state;

			return state.deleteIn(['map', projectId, stage.id]);
		}

		case actions.CLEAR_LOCAL_STAGES:
		case actions.END_STAGES_LISTENER: {
			if (!action.payload)
				return state;
			const { projectId } = action.payload;
      if (!projectId) 
        return state;

			state = state.setIn(['didLoad', projectId], false);
			return state.deleteIn(['map', projectId]);
		}

		case actions.UPDATE_LOCAL_STAGES:
    case actions.UPDATE_STAGES + '_SUCCESS': {
			const { projectId, stages } = (action.payload || {});

      if (!projectId || !stages) 
        return state;

			stages.loopEach((i, stage) => {
				stage = stage.toJS ? stage.toJS() : { ...stage };

				if (!stage.createdAt) stage.createdAt = new Date().getTime();

				state = state.setIn(['map', projectId, stage.id], new Stage({ ...stage, isLocal: true }));
			});

			return state;
		}
	}

	return state;
}
