export class Section {
  constructor(title, fields, props = {}, id = null, show = true) {
    this.title  = title;
    this.fields = fields;
    this.props  = props;
    this.show   = show;
    this.id     = id;
  }
}

// name --> name of the input field to display
// type --> Type of input field to use
// pathToValue --> Path for 'getNested'/'setNested' to get/set the value in the state (needs to start at SAME name as in state (or you can just add them here and not on the state))
// values --> only for SelectionList input type --> values available to choose for the user (HAVE to be { id: id, value: value } ) value saved to the state will be the ID


export class Field {
  constructor(name, type, pathToValue, values = null, props = {}, defaultValue = false) {
    this.name = name;
    this.type = type;
    this.pathToValue = pathToValue;
    this.props = props;
    this.defaultValue = defaultValue;
    if (values !== null) this.values = values;
  }
}