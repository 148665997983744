import { startLoading, startToast } from '../app/actions';
import { platformActions } from "../../common/platformActions";
import { envParams } from '../configureMiddleware';
import { getResourceById, getSnapshotData } from '../lib/utils/utils';
import systemMessages from '../app/systemMessages';
import _ from 'lodash';
import { onError } from '../app/funcs';
import { track } from '../lib/reporting/actions';

export const GET_PROPERTIES_TYPES = 'GET_PROPERTIES_TYPES';
export const GET_PROPERTIES_TYPES_BY_ID = 'GET_PROPERTIES_TYPES_BY_ID';
export const SAVE_PROPERTIES = 'SAVE_PROPERTIES';
export const UPDATE_LOCAL_PROPERTIES = 'UPDATE_LOCAL_PROPERTIES';
export const DELETE_LOCAL_PROPERTY = 'DELETE_LOCAL_PROPERTY';
export const GET_NEW_ID = 'GET_NEW_ID';
export const GET_NEW_GROUP_ID = 'GET_NEW_GROUP_ID';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const PUT_SELECTION_LIST_OPTION = 'PUT_SELECTION_LIST_OPTION';
export const DELETE_SELECTION_LIST_OPTION = 'DELETE_SELECTION_LIST_OPTION';


export function getPropertiesTypes(scopeKey) {
    return {
      type: GET_PROPERTIES_TYPES,
      payload: getSnapshotData({ api: 'properties', firebase: `properties/types/projects`, forceMSClientConfig: true }, scopeKey)
   };
}

export function getPropertiesTypeById(subjectName, propId, scopeKey, clearOtherProperties = false,) {
  const getPromise = async () => {
    const res = await getResourceById({ resourceName: 'properties', api: 'properties', firebase: `properties/types/projects`, queryParams: { subjectName }, forceMSClientConfig: true }, scopeKey, propId, _.noop, true);
    let { properties: prop } = res;
    let payload = { projectId: scopeKey, clearOtherProperties };
    _.set(payload, ['properties', subjectName, 'properties', propId], prop);

    return payload;
  };

  return {
    type: GET_PROPERTIES_TYPES_BY_ID,
    payload: getPromise()
  };
}

export function updateLocalProperties(projectId, subjectName, properties) {
  return {
    type: UPDATE_LOCAL_PROPERTIES,
    payload: { projectId, subjectName, properties },
  }
}

export function deleteLocalProperty(projectId, subjectName, propertyId) {
  return {
    type: DELETE_LOCAL_PROPERTY,
    payload: { projectId, subjectName, propertyId },
  }
}

export function saveProperties(projectId, subjectName, properties, sections, dynamicValuesLists) {
  return ({ dispatch }) => {
    const getPromise = async () => {
      let success = true;

      if (projectId) {
        dispatch(startLoading({ title: systemMessages.manage.saving, overlay: true }));

        try {
          await platformActions.net.fetch(`${envParams.apiServer}/v1/services/properties/manager/update`,
            {
              method: 'POST',
              body: JSON.stringify({
                projectId,
                subjectName,
                properties: _.mapKeys(properties, (val, key) => val.id),
                sections: _.mapKeys(sections, (val, key) => val.id),
                dynamicValuesLists
              })
            });
        }
        catch (error) {
          console.error(error);
          success = false
        }
        finally {
          dispatch({ type: SAVE_PROPERTIES, payload: { success: success, project: projectId } });
          return { success };
        }
      }
    }

    return {
      type: SAVE_PROPERTIES,
      payload: getPromise()
    };
  };
}

export function getNewId(projectId, subjectName, isSection) {
  return ({ firebaseDatabase }) => {
    let path = isSection ? '/sections' : '/properties';
    var push = firebaseDatabase().ref('properties/types/projects/' + projectId + '/' + subjectName + path).push();
    return {
      type: GET_NEW_ID,
      payload: { id: push.key }
    };
  };
}

export function getNewGroupId(projectId) {
  return ({ firebaseDatabase }) => {
    var newId = firebaseDatabase().ref('properties/types/projects/' + projectId + '/locationsInfo/properties/groups/values').push().key;
    return {
      type: GET_NEW_GROUP_ID,
      payload: { newId }
    };
  };
}


//will serve us for delete, add and edit. All based on the newGroupsObject object.
export function updateLocationsGroup(projectId, newGroupObject) {
  return ({ dispatch, firebase, firebaseDatabase }) => {
    const getPromise = async () => {

      if (projectId) {
        let dbUpdates = {}
        dispatch(startLoading({ title: systemMessages.manage.saving, overlay: true }));
        if (newGroupObject) {
          dbUpdates['properties/types/projects/' + projectId + '/locationsInfo/properties/groups/values'] = newGroupObject;
        }
        // empty 'types' assigned to 'undefined' somewhere. firebase can't work with 'undefined' values,
        // so used stringify/parse to get rid of all possible undefined values
        await firebase.update(JSON.parse(JSON.stringify(dbUpdates)));
        let groupObject = await firebaseDatabase().ref('properties/types/projects/' + projectId + '/locationsInfo/properties/groups').once('value');

        // dispatch({ type: UPDATE_LOCAL_PROPERTIES, payload: { projectId, subjectName: 'locationsInfo', properties: { 'groups': groupObject.val() } } });
        return { projectId, subjectName: 'locationsInfo', properties: { 'groups': groupObject.val() } };
      }
    }

    return {
      type: UPDATE_GROUP,
      payload: getPromise()
    };
  };
}

const checkValues = values => {
  let ret = {};
  if (!_.isArray(values))
    ret.errorMessage = "values is expected to be an array";
  else if (values.some(v => !_.has(v, 'title')))
    ret.errorMessage = "some values are missing a title";
  ret.success = !Boolean(ret.errorMessage);
  return ret;
};

export function putSelectionListOptions({ subjectName, propId, values, projectId }) {
  return ({ dispatch }) => {
    const getPromise = async () => {
      dispatch(track('putSelectionListOptions', { subjectName, propId, values, projectId }));
      dispatch(startLoading({ title: systemMessages.savingMessage, overlay: true, hideOnBackgroundPress: false }));
      let success = true;
      let resp;

      let check = checkValues(values);

      if (!check.success)
        onError({
          errorMessage: check.errorMessage,
          methodMetaData: {
            name: 'putSelectionListOptions',
            args: { subjectName, propId, values, projectId }
          }
        });

      try {
        resp = await platformActions.net.fetch(`${envParams.apiServer}/v1/properties/${propId}/values?projectId=${projectId}&subjectName=${subjectName}`,
          {
            method: 'PUT',
            body: JSON.stringify({ values: _.values(values) })
          });
        resp = await (resp.getJson());

      }
      catch (error) {
        success = false;
        onError({
          alertParams:{
            title: systemMessages.saveFailed
          },
          errorMessage: 'putSelectionListOptions failed',
          error,
          methodMetaData: {
            name: 'putSelectionListOptions',
            args: { subjectName, propId, values, projectId },
          }
        });
      }
      finally {
        if (success) {
          dispatch(startToast({ title: systemMessages.savedSuccessfully }));
        }
        return { success, projectId, subjectName, propId, changedValues: _.get(resp, ['values']) };
      }
    };
    return {
      type: PUT_SELECTION_LIST_OPTION,
      payload: getPromise()
    };
  };
}

export function removeSelectionListOption({ subjectName, propId, valueId, projectId }) {
  return ({ dispatch }) => {
    const getPromise = async () => {
      dispatch(track('removeSelectionListOption', { subjectName, propId, valueId, projectId }));
      dispatch(startLoading({ title: systemMessages.savingMessage, overlay: true, hideOnBackgroundPress: false }));
      let success = true;
      let resp;
      try {
        resp = await platformActions.net.fetch(`${envParams.apiServer}/v1/properties/${propId}/values?projectId=${projectId}&subjectName=${subjectName}`,
          {
            method: 'DELETE',
            body: JSON.stringify({ valuesToDelete: [valueId] })
          });
        resp = await (resp.getJson());
      }
      catch (error) {
        success = false;
        onError({
          alertParams:{
            title: systemMessages.saveFailed
          },
          errorMessage: 'removeSelectionListOption failed',
          error,
          methodMetaData: {
            name: 'removeSelectionListOption',
            args: { subjectName, propId, valueId, projectId },
          }
        });
      }
      finally {
        if (success) {
          dispatch(startToast({ title: systemMessages.deletedSuccessfully }));
        }
        return { success, projectId, subjectName, propId, changedValues: _.get(resp, ['values']) };
      }
    };
    return {
      type: DELETE_SELECTION_LIST_OPTION,
      payload: getPromise()
    };
  };
}
