import { startLoading } from '../app/actions';
import systemMessages from '../app/systemMessages';
import * as itemsActions from '../checklistItems/actions';
import { removeProjectsStorage } from '../projects/actions';
import { upsertForm } from '../forms/actions';
import Checklist from './checklist';
import { getSnapshotData } from '../lib/utils/utils';
import * as stagesActions from '../stages/actions';
import { removeEmpty } from '../lib/utils/utils';
import _ from 'lodash';
import { splitInBatches } from '../app/funcs';

export const SAVE_CHECKLISTS = 'SAVE_CHECKLISTS';
export const GET_CHECKLISTS_TEMPLATES = 'GET_CHECKLISTS_TEMPLATES';
export const GET_CHECKLISTS = 'GET_CHECKLISTS';
export const GET_CHECKLISTS_SUBSCRIPTION = 'GET_CHECKLISTS_SUBSCRIPTION';
export const UPDATE_CHECKLISTS_SUBSCRIPTION = 'UPDATE_CHECKLISTS_SUBSCRIPTION';
export const SAVE_CHECKLISTS_FROM = 'SAVE_CHECKLISTS_FROM';

export const EXPORT_CHECKLIST_AS_PDF = 'EXPORT_CHECKLIST_AS_PDF';
export const IS_CHECKLIST_PERMITTED = 'IS_CHECKLIST_PERMITTED';
export const GET_CHECKLIST_STATUS_JSON = 'GET_CHECKLIST_STATUS_JSON';
export const CLEAR_CHECKLIST_STATUS_JSON = 'CLEAR_CHECKLIST_STATUS_JSON';
export const GET_NEW_CHECKLIST_ID = 'GET_NEW_CHECKLIST_ID';
export const DUPLICATE_CHECKLIST = 'DUPLICATE_CHECKLIST';
export const REMOVE_DUPLICATED_CHECKLIST = 'REMOVE_DUPLICATED_CHECKLIST';
export const EDIT_CHECKLIST_EXTRA_DATA = 'EDIT_CHECKLIST_EXTRA_DATA';
export const CLEAN_CHECKLISTS_CACHE_DATA = 'CLEAN_CHECKLISTS_CACHE_DATA';
export const GET_NEW_EMAIL_ID = 'GET_NEW_EMAIL_ID';
export const CLEAN_ALL_CACHED_CHECKLIST_ITEMS_AND_INSTANCES = 'CLEAN_ALL_CACHED_CHECKLIST_ITEMS_AND_INSTANCES';

export const GET_LOCAL_CHECKLISTS = 'GET_LOCAL_CHECKLISTS';
export const UPDATE_LOCAL_CHECKLISTS = 'UPDATE_LOCAL_CHECKLISTS';
export const DELETE_NEW_CHECKLIST = 'DELETE_NEW_CHECKLIST';

/**
 * @typedef {{ id: string }} LocationValue
 * @typedef Checklist
 * @property {string} id
 * @property {string} description
 * @property {{ units?: LocationValue, floor?: LocationValue, building?: LocationValue }} locations
 * @property {number} ordinalNo
 * @property {string} [UIType]
 * @property {string} [contentType]
 * @property {string} [tags]
 * @property {string} [type]
 * @property {string} stage - Name of the stage
 * @property {string} [stageId]
 * @property {boolean} [duplicatable]
 * @property {string} [originChecklist] - Id of the original checklist from which it was duplicated
 * @property {number} createdTS
 * @property {boolean} [enableDistributionList]
 * @property {number} [duplicationNo]
 * @property {boolean} [isDeleted]
 */


export function getNewEmailId(projectId) {
  return ({ firebaseDatabase }) => {
    try {
      var push = firebaseDatabase().ref('checklists/' + projectId + '/subscription').push();
      return {
        type: GET_NEW_EMAIL_ID,
        payload: { id: push.key }
      };
    } catch (error) {
      throw error;
    }
  };
}


export function escapeFirebaseKey(email) {
  return email.replace("/", "").replace(".", "").replace("#", "").replace("$", "").replace("[", "").replace("]", "").replace("]", "");
}


export function saveChecklistForm(viewer, selectedProjectId, checklistId, location, formTemplate, signatures, formBusinessType) {
  return ({ dispatch }) => {
    const getPromise = async () => {
      let localDate = new Date().getTime();

      let checklists = null;
      if (!formTemplate || !formTemplate.getNested(['checklists', 'preDefined', checklistId]))
        checklists = { 'random': { [checklistId]: true } };

      let updatedReport = {
        createdAt: localDate,
        isDocx: Boolean(_.get(formTemplate, ['uri'])),
        signatures,
        checklists,
        location: _.pickBy(location, i => i != '_'),
        formTemplateId: formTemplate.id,
        type: formBusinessType
      };

      const upsertFormRet = (await dispatch(upsertForm(selectedProjectId, viewer, updatedReport)));
      const { form } = upsertFormRet;

      return _.clone(form);
    };

    return {
      type: SAVE_CHECKLISTS_FROM,
      payload: getPromise()
    };
  };
}

export function updateChecklistsSubscriptions(projectId, buildingId, stageId, assignToList, emailList, subscriptionKey) {
  return ({ firebaseDatabase, removeEmpty, firebase }) => {
    const getPromise = async () => {
      if (projectId) {
        var pushKey = subscriptionKey || firebaseDatabase().ref(`checklists/${projectId}/subscriptions`).push().key;
        let currItem = {
          id: pushKey,
          projectId,
          buildingId,
          stageId,
          members: assignToList
        };
        let firebaseValidEmailList = {};
        Object.keys(emailList || {}).forEach(eKey => {
          firebaseValidEmailList[escapeFirebaseKey(eKey)] = emailList[eKey];
        });
        currItem.emails = firebaseValidEmailList;

        let dbUpdates = {};
        dbUpdates[`checklists/${projectId}/subscriptions/${pushKey}`] = removeEmpty(currItem, 'updateChecklistsSubscriptions');
        await firebase.update(dbUpdates);
        let subscriptions = { [currItem.id]: currItem };
        return { projectId, subscriptions, updateOnly: true };
      }
    };

    return {
      type: UPDATE_CHECKLISTS_SUBSCRIPTION,
      payload: getPromise()
    };
  };
}

export function getChecklistsSubscriptions(projectId) {
  return ({ firebaseDatabase }) => {
    const getPromise = async () => {
      if (projectId) {
        let snapshot = await firebaseDatabase().ref('checklists/' + projectId + '/subscriptions').once('value');
        let subscriptions = snapshot.val() || {};
        return { subscriptions, projectId };
      }
    };

    return {
      type: GET_CHECKLISTS_SUBSCRIPTION,
      payload: getPromise()
    };
  };
}

export function updateLocalChecklists(projectId, checklists) {
  return {
    type: UPDATE_LOCAL_CHECKLISTS,
    payload: { projectId, checklists }
  };
}

export function deleteNewChecklist(projectId, checklist) {
  return {
    type: DELETE_NEW_CHECKLIST,
    payload: { projectId, checklist }
  };
}

export function getLocalChecklists(projectId, checklists) {
  return {
    type: GET_LOCAL_CHECKLISTS + "_SUCCESS",
    payload: { projectId, checklists }
  };
}

export function getChecklists(viewer, projectId, adminMode) {
  const getPromise = async () => {
    let snapshot = await getSnapshotData({ api: 'checklists', firebase: `checklists`, firebaseSuffix:`checklists` }, projectId)
    return snapshot
  }
  return {
    type: GET_CHECKLISTS,
    payload: getPromise()
  };
}

export function getMergedChecklistsFromTemplates(scope, scopeId) {
  return ({ dispatch, platformActions, apiServer }) => {
    const getPromise = async () => {
      let toRet = { success: true };
      if (!scope && !scopeId)
        return toRet;

      try {
        dispatch(startLoading({ title: systemMessages.loadingMessage, overlay: true }));
        let checklistsResp = await (platformActions.net.fetch(`${apiServer}/v1/gateway/templates?ids=["checklists"]&scope=${scope}&scopeId=${scopeId}`));
        let checklistItemsResp = await (platformActions.net.fetch(`${apiServer}/v1/gateway/templates?ids=["checklistItems"]&scope=${scope}&scopeId=${scopeId}`));
        let jsonChecklistsResp = await checklistsResp.json();
        let jsonChecklistItemsResp = await checklistItemsResp.json();
        toRet.checklists = jsonChecklistsResp;
        toRet.checklistItems = jsonChecklistItemsResp;
      }
      catch (error) {
        console.error(error);
        toRet.success = false;
      }
      finally {
        dispatch({ type: GET_CHECKLISTS_TEMPLATES, payload: { success: toRet.success, scope, scopeId } });
        return toRet;
      }
    };

    return {
      type: GET_CHECKLISTS_TEMPLATES,
      payload: getPromise()
    };
  };
}

export function saveChecklists(projectId, scope, scopeId, stagesDelta, checklistDeltas, checklistItemDeltas) {
  return ({ dispatch, platformActions, apiServer }) => {
    const getPromise = async () => {
      let success = true;
      let mergeData = {};
      if (scope && scopeId && (stagesDelta || checklistDeltas || checklistItemDeltas)) {
        dispatch(startLoading({ title: systemMessages.manage.saving, overlay: true }));

        if (stagesDelta)
          stagesDelta.loopEach((stageId, stageDelta) => {
            mergeData = mergeData.setNested(['stages', stageId], stageDelta);
          });

        if (checklistDeltas)
          checklistDeltas.loopEach((checklistId, checklistDelta) => {
            mergeData = mergeData.setNested(['checklists', checklistId], checklistDelta);
          });

        if (checklistItemDeltas)
          checklistItemDeltas.loopEach((itemId, itemDelta) => {
            mergeData = mergeData.setNested(['checklistItems', itemId], itemDelta);
          });

        if (Object.keys(mergeData).length) {
          try {
            let updatedChecklistsAndItems = await platformActions.net.fetch(`${apiServer}/v1/templates?scope=${scope}&scopeId=${scopeId}`, {
              method: 'POST',
              body: JSON.stringify({ data: mergeData }),
            });

            let { stages, checklists, checklistItems } = (await updatedChecklistsAndItems.getJson()) || {};

            if (stages) {
              stages = removeEmpty(stages, 'saveChecklists_stages');
              dispatch({
                type: stagesActions.UPDATE_LOCAL_STAGES,
                payload: { projectId, stages }
              });
            }

            if (checklists) {
              checklists = removeEmpty(checklists, 'saveChecklists_checklists');
              dispatch({
                type: UPDATE_LOCAL_CHECKLISTS,
                payload: { projectId, checklists }
              });
            }

            if (checklistItems) {
              checklistItems = removeEmpty(checklistItems, 'saveChecklists_checklistItems');
              dispatch({
                type: itemsActions.UPDATE_LOCAL_CHECKLIST_ITEMS,
                payload: { projectId, checklistItems }
              });
            }
          }
          catch (err) {
            console.error(err);
            success = false;
          }
          finally {
            dispatch({ type: SAVE_CHECKLISTS, payload: { success: success, scope, scopeId } });
            return { success: success };
          }
        }
      }
    };

    return {
      type: SAVE_CHECKLISTS,
      payload: getPromise()
    };
  };
}

export function DEPRECATED_saveChecklists(stages, checklists, checklistItems, scope, scopeId) {
  return ({ dispatch, firebase, platformActions, apiServer }) => {
    const getPromise = async () => {
      let success = true;
      let newMergeData = {};
      if (scope && (stages || checklists || checklistItems)) {

        stages = stages.toJS ? stages.toJS() : stages;
        checklists = checklists.toJS ? checklists.toJS() : checklists;
        checklistItems = checklistItems.toJS ? checklistItems.toJS() : checklistItems;

        let dbUpdates = {};
        let scopePath = scope == 'templates' ? scope : `${scope}/${scopeId}`;
        dispatch(startLoading({ title: systemMessages.manage.saving, overlay: true }));
        platformActions.net.fetch(`${apiServer}/v1/services/utils/revokeResultsCache?scope=${scope}&scopeId=${scopeId}&subjectsType=["checklists", "checklistItems"]`);
        if (stages)
          Object.entries(stages).forEach(([i, currStage]) => {
            currStage = currStage.toJS ? currStage.toJS() : currStage;
            dbUpdates[`templates/stages/${scopePath}/${currStage.id}`] = currStage;
            newMergeData = newMergeData.setNested(['stages', currStage.id], currStage);
          });

        if (checklists)
          Object.entries(checklists).forEach(([i, currChecklist]) => {
            currChecklist = currChecklist.toJS ? currChecklist.toJS() : currChecklist;
            delete currChecklist.source;
            dbUpdates[`templates/checklists/${scopePath}/${currChecklist.id}`] = currChecklist;
            newMergeData = newMergeData.setNested(['checklists', currChecklist.id], currChecklist);
          });

        if (checklistItems)
          Object.entries(checklistItems).forEach(([i, currItem]) => {
            currItem = currItem.toJS ? currItem.toJS() : currItem;
            delete currItem.source;
            dbUpdates[`templates/checklistItems/${scopePath}/${currItem.id}`] = currItem;
            newMergeData = newMergeData.setNested(['checklistItems', currItem.id], currItem);
          });

        try {
          if (Object.keys(dbUpdates).length > 500) {
            const updateBatchesArr = splitInBatches(dbUpdates, 100);
            await Promise.all(updateBatchesArr.map(async updateBatch => {
              return await firebase.update(updateBatch);
            }));
          }
          else
            await firebase.update(dbUpdates);

          setTimeout(async () => {
            await platformActions.net.fetch(`${apiServer}/v1/services/templates/merge?scope=${scope}&scopeId=${scopeId}`);
            /* platformActions.net.fetch(`${apiServer}/v1/templates?scope=${scope}&scopeId=${scopeId}`, {
              method: 'POST',
              body : JSON.stringify({ data: newMergeData })
            }); */
          }, 5000);
        }
        catch (error) {
          console.error(error);
          success = false;
        }
        finally {
          if (process.env.NODE_ENV !== 'production')
            console.log('funcInputs:', { funcInputs: { stages, checklists, checklistItems, scope, scopeId }, dbUpdates, newMergeData, scopePath, success }); // Not this one Idan, not this one..
          dispatch({ type: SAVE_CHECKLISTS, payload: { success: success, scope, scopeId } });
          return { success: success };
        }
      }
    };

    return {
      type: SAVE_CHECKLISTS,
      payload: getPromise()
    };
  };
}

export function getNewChecklistId(projectId) {
  return ({ firebaseDatabase }) => {
    try {
      let push = firebaseDatabase().ref('checklists/' + projectId + '/checklists').push();
      return {
        type: GET_NEW_CHECKLIST_ID,
        payload: { id: push.key }
      };
    } catch (error) {
      throw error;
    }
  };
}

export function clearChecklistStatusReport(projectId) {
  return {
    type: CLEAR_CHECKLIST_STATUS_JSON,
    payload: { projectId }
  };
}

export function getChecklistStatusReport(projectId, checklistId, checklistItemId, viewer, toDate) {
  return ({ dispatch, apiServer, platformActions }) => {
    const getPromise = async () => {
      var jsonToRet = null;
      var success = true;
      try {
        dispatch(startLoading({ title: systemMessages.loadingMessage, overlay: true }));

        var resp = await (platformActions.net.fetch(apiServer + '/v1/services/checklists/checklistProgressStatus', {
          'method': 'POST',
          'body': JSON.stringify({
            "projectId": projectId,
            "checklistId": checklistId,
            "checklistItemId": checklistItemId,
          })
        }));

        jsonToRet = await (resp.getJson());
      }
      catch (error) {
        console.error(error);
        success = false;
      }
      finally {
        dispatch({ type: GET_CHECKLIST_STATUS_JSON, payload: { success: success, projectId: projectId, viewer } });
      }

      return { reportJson: jsonToRet, projectId: projectId, checklistId, checklistItemId };
    };
    return {
      type: GET_CHECKLIST_STATUS_JSON,
      payload: getPromise()
    };
  };
}

export function duplicateChecklist(projectId, checklistId, locationType, locationId, extraInfo) {
  return ({ dispatch, apiServer, platformActions }) => {
    const getPromise = async () => {
      let success = true;
      dispatch(startLoading({ title: systemMessages.loadingMessage, overlay: true }));
      let newData;

      try {
        let resp = await platformActions.net.fetch(`${apiServer}/v1/checklists`, {
          method: 'POST',
          body: JSON.stringify({
            source: checklistId,
            projectId,
            locationType,
            locationId,
            extraInfo
          }),
        });

        newData = await resp.getJson();

      }
      catch (error) {
        console.error(error);
        success = false;
      }
      finally {
        dispatch({ type: DUPLICATE_CHECKLIST, payload: { success } });
        return {
          success, projectId, ...(newData || {}),
        };

      };
    };
    return {
      type: DUPLICATE_CHECKLIST,
      payload: getPromise()
    };

  };
};

export function editChecklistExtraInfo(projectId, checklistId, locationType, locationId, extraInfo) {
  return ({ dispatch, apiServer, platformActions }) => {
    const getPromise = async () => {
      let success = true;
      dispatch(startLoading({ title: systemMessages.loadingMessage, overlay: true }));
      let newData;

      try {
        let resp = await platformActions.net.fetch(`${apiServer}/v1/checklists/${checklistId}?projectId=${projectId}&locationType=${locationType}&locationId=${locationId}`, {
          method: 'PATCH',
          body: JSON.stringify({
            extraInfo
          }),
        });

        newData = await resp.getJson();

      }
      catch (error) {

        console.error(error);
        success = false;

      }
      finally {

        dispatch({ type: EDIT_CHECKLIST_EXTRA_DATA, payload: { success } });
        return {
          success, projectId, ...(newData || {}),
        };

      };
    };
    return {
      type: EDIT_CHECKLIST_EXTRA_DATA,
      payload: getPromise()
    };

  };
};

export function removeDuplicatedChecklist(projectId, checklistId, locationType, locationId) {
  return ({ dispatch, apiServer, platformActions }) => {
    const getPromise = async () => {
      let success = true;
      dispatch(startLoading({ title: systemMessages.loadingMessage, overlay: true }));

      let newData;
      try {

        let resp = await platformActions.net.fetch(`${apiServer}/v1/checklists/${checklistId}?projectId=${projectId}&locationType=${locationType}&locationId=${locationId}`, {
          method: 'DELETE'
        });

        newData = await resp.getJson();

      }
      catch (error) {

        console.error(error);
        success = false;

      }
      finally {

        dispatch({ type: REMOVE_DUPLICATED_CHECKLIST, payload: { success } });
        return {
          success, projectId, ...(newData || {}),
        };

      };
    };
    return {
      type: REMOVE_DUPLICATED_CHECKLIST,
      payload: getPromise()
    };
  };
};

export function cleanChecklistsCachedData() {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      let projectIdsArray = [];
      let projectStateToRemove = [
        ['checklists', 'subscriptions'],
        ['checklists', 'subscriptionsLastUpdated'],
        ['checklists', 'map'],
        ['checklists', 'lastUpdated']
      ];

      getState().getNested(['projects', 'map'], {}).loopEach((k, p) => projectIdsArray.push(p.id));
      await dispatch(await removeProjectsStorage(projectIdsArray, projectStateToRemove));

    };
    return {
      type: CLEAN_CHECKLISTS_CACHE_DATA,
      payload: getPromise()
    };
  };
};