import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Text from "./Text";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import postsMessages from "../../../common/posts/postsMessages";
import NothingToDisplay from "../../../common/app/components/NothingToDisplay";
import theme from "../../assets/css/theme";

class NoItemsFound extends React.PureComponent {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};

    //////////////////////////////
    //////////////////////////////
    //////////////////////////////

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  render() {
    const {
      message,
      locationMode,
      minHeight,
      treatEntireProjectAsNoLocation,
      style,
      compStyles = {},
      onClick,
    } = this.props;
    let buildingId = this.props.getNested(["urlParams", "buildingId"]);
    let locationId = this.props.getNested(["urlParams", "urlParamLocationId"]);
    let textStyle = style || {
      color: theme.brandPrimary,
      paddingBottom: minHeight ? "25vh" : null,
      fontSize: theme.fontSizeH6,
      fontWeight: theme.bold,
    };
    let height = minHeight ? minHeight + theme.headerHeight : "50vh";
    const componentStyle = Object.assign(
      {
        display: "flex",
        flex: 1,
        height,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        cursor: onClick ? "pointer" : "auto",
      },
      compStyles
    );
    if (
      locationMode &&
      (!locationId || (treatEntireProjectAsNoLocation && buildingId == "all"))
    )
      return (
        <div onClick={onClick ? onClick : undefined} style={componentStyle}>
          <Text style={textStyle}>
            {this.props.children || postsMessages.selectLocation}
          </Text>
        </div>
      );
    return (
      <div onClick={onClick ? onClick : undefined} style={componentStyle}>
        <NothingToDisplay>
          {message || this.props.children || postsMessages.empty}
        </NothingToDisplay>
      </div>
    );
  }
}

let styles = {};

NoItemsFound = withStyles(styles)(NoItemsFound);
const enhance = compose(
  connect(
    (state) => ({
      urlParams: state.ui.urlParams,
    }),
    {}
  )
);
export default enhance(NoItemsFound);
