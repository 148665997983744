import { Map } from 'immutable';
import { Record } from '../transit';
import * as lastUpdatesActions from '../lastUpdates/actions';

import * as appActions from '../app/actions';
import * as actions from './actions';
import * as projectsAction from '../projects/actions'
import { REPLACE_USER } from '../auth/actions';
import _ from 'lodash';
import PropertyInstance from './propertyInstance';

const InitialState = Record({
  lastRevoked: Map(),
  lastRevokeAvailable: Map(),
  sync: Map(),
  lastSynced: Map(),
  didLoad: Map(),
  recentlySigningUsers: Map()
}, 'propertiesInstances');

const initialState = new InitialState;

export default function propertiesInstancesReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    
    case lastUpdatesActions.GET_LAST_UPDATES: {
      if (!action.payload || !action.payload.projects)
        return state;
    
      const { projects } = action.payload;
      Object.keys(projects).forEach(projectId => {
        projects.getNested([projectId], {}).loopEach((key, val) => {
          if (key.startsWith('properties-instances-')) {
            let subjectName = key.substring('properties-instances-'.length);
            let TS = val.lastUpdateTS || 1;
            let curr = state.getNested(['lastRevokeAvailable', projectId, subjectName], 0)
            if (curr < TS) state = state.setIn(['lastRevokeAvailable', projectId, subjectName], TS);            
          }
        });
      });
    
      return state;
    }

    case lastUpdatesActions.GET_SYNCED: {
      if (!action.payload || !action.payload.projects)
        return state;

      const { projects } = action.payload;
      _.forIn(projects, (objectTypes, projectId) => {
        let syncOptions = _.get(objectTypes, ['propertiesInstances'], {})
        let TS = syncOptions.lastUpdateTS || 1;
        let curr = state.getNested(['sync', projectId, 'lastUpdateTS'], 0)
        if (curr < TS) {
          state = state.setIn(['sync', projectId], syncOptions);
        }
      })

      return state;
    }

    case lastUpdatesActions.SYNCED + "_PROPERTIES_INSTANCES_SUCCESS": {
      if (!action.payload || !action.payload.projectId || !action.payload.lastSynced)
        return state;

      const { projectId, lastSynced } = action.payload;
      state = state.setIn(['lastSynced', projectId], lastSynced);
      return state;
    }
    case projectsAction.LOKI_DID_LOAD: {
      if (action.getNested(['payload','loadedCollections','propertyInstances']))
        state = state.setIn(['didLoad', action.getNested(['payload','projectId'])], action.getNested(['payload','status']));
      return state;
    }

    case actions.GET_PROPERTIES_INSTANCES_FETCHING: {     
      if (!action.payload || !action.payload.scopeId)
        return state;
      const { scopeId, subjectName } = action.payload;
      state = state.setIn(['lastRevoked', scopeId, subjectName], state.getNested(['lastRevokeAvailable', scopeId, subjectName]) || state.getNested(['lastRevoked', scopeId, subjectName]) || 1); 
      return state.setIn(['didLoad', scopeId], true);
    }


    case actions.END_PROPERTIES_INSTANCES_LISTENER: {
      if (action.payload && action.payload.scopeId)
        state = state.setIn(['didLoad', action.payload.scopeId], false);
      return state;
    }

    case appActions.CLEAN_DYNAMIC_CACHE_DATA + "_SUCCESS":
    case REPLACE_USER + "_SUCCESS":
    case actions.CLEAN_CACHED_INSTANCES:
    case appActions.CLEAN_CACHE: {      
      //let lastRevokeAvailable = state.getNested(['lastRevokeAvailable'], {});  
      //let lastRevoked = state.getNested(['lastRevoked'], {});
      state = initialState;
      //lastRevoked.loopEach((scopeId, scope) => {
      //  scope.loopEach((subjectName, last) => last ? state = state.setIn(['lastRevokeAvailable', scopeId, subjectName], last + 1) : 0);
      //})
      //lastRevokeAvailable.loopEach((scopeId, scope) => {
      //  scope.loopEach((subjectName, last) => last ? state = state.setIn(['lastRevokeAvailable', scopeId, subjectName], last + 1) : 0);
      //})
      return state;
    }
    case actions.SET_RECENTLY_SIGNING_USERS: {
      const { userId, projectId, signaturesContext } = action.payload;
      if (userId && projectId && signaturesContext)
        state = state.setIn(['recentlySigningUsers', projectId, signaturesContext, userId], { lastUse: Date.now() });
      return state;
    }
  }

  return state;
}

