import ExtraError from '../lib/errors/extraError'
export const GET_SAFETY_GRADE = 'GET_SAFETY_GRADE';
export const END_SAFETY_GRADE = 'END_SAFETY_GRADE';
export const GET_SAFETY_POINTING_SYSTEM = 'GET_SAFETY_POINTING_SYSTEM';

export function getSafetyGrade(projectId) {	
    return ({ dispatch, firebaseDatabase }) => {
        const getPromise = async () => {
          try {
            firebaseDatabase().ref('grades/' + projectId + "/safety/general/").orderByChild('itemDate').limitToLast(1).on('value', function(snapshot) {
                dispatch({ type: GET_SAFETY_GRADE, payload: { grades: snapshot.val(), projectId }, projectId: projectId});
              });		  	
          } catch (error) {
              throw new ExtraError('getSafetyGrade error', {projectId}, error);
          }
      }

    return {
      type: GET_SAFETY_GRADE,
      payload: getPromise()
    };
  };
}


export function getSafetyDefaultPointSystem() {	
  return ({ firebaseDatabase }) => {
      const getPromise = async () => {
        
        let ret = {
          openedIssueSeverity3: 10,
          openedIssueSeverity2: 5,

          openedIssueDelaysDaySeverity3: 2,
          openedIssueDelaysDaySeverity2: 1,

          workersInvalidCertification  : 10,
          equipmentInvalidCertification: 10,

          maximumDaysBetweenSurveys: 0,
          siteSafetySurveyPointsPerDayOverMaxDays: 0
        }

        return ret;

    }

    return {
      type: GET_SAFETY_POINTING_SYSTEM,
      payload: getPromise()
    };
  };
}

export function endSafetyGradeListener(projectId) {	
    return ({ firebaseDatabase }) => {
        firebaseDatabase().ref('grades/' + projectId + "/safety/general/").off('value');
  
      return {
        type: END_SAFETY_GRADE,
        payload: { projectId }
      };
    };
  }