import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose, hoistStatics } from 'recompose';
import { connectContext } from 'react-connect-context';
import _ from 'lodash';
import _fp from 'lodash/fp';
import { Switch, Route } from 'react-router-dom';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import CamerasManger from '../Cameras/CamerasManagerPage';

// Main components
import CreateProject from './CreateProject';
import ProjectProperties from './ProjectProperties';
import ProjectStructure from './ProjectStructure';
import ChecklistManager from './ChecklistManager';

// Components
import CollapsibleSection from '../../components/CementoComponents/CollapsibleSection';
import InputField from '../../components/CementoComponents/InputField';
import Text from '../../components/CementoComponents/Text';

// Actions
import {
	updateProjectFields,
	updateExternalSettings,
	enterProject,
	PROJECT_TYPE_BUILDING,
	PROJECT_TYPE_COMPLEX_BUILDINGS,
} from '../../../common/projects/actions';
import {
	getLocalBuildings,
	updateLocalBuilding,
	removeLocalBuildings,
	getBuildings,
	getNewBuildingId,
	updateBuildings,
} from '../../../common/buildings/actions';
import {
	getLocalFloors,
	removeLocalFloors,
	getFloors,
	updateLocalFloor,
	getNewFloorId,
	updateFloors,
} from '../../../common/floors/actions';
import { onDraftModeChange, draftValidator } from '../../../common/ui/actions';
import {
	getLocalUnits,
	removeLocalUnits,
	getUnits,
	updateLocalUnit,
	updateLocalUnits,
	getNewUnitId,
	updateUnits,
} from '../../../common/units/actions';
import { uploadImage } from '../../../common/images/actions';
import { updateConfigurations } from '../../../common/configurations/actions';
import { startToast, startLoading, hideLoading } from '../../../common/app/actions';

// Other
import { ProjectContext, ProjectManagerContext } from '../../../common/projects/contexts';
import propertiesMessages from '../../../common/propertiesTypes/propertiesMessages';
import systemMessages from '../../../common/app/systemMessages';
import theme from '../../assets/css/theme';
import projectManagerMessages from '../../../common/app/projectManagerMessages';
import { getFullLocationByPropValue } from '../../../common/locations/func';

export function baseRemoveNested(obj, pathOrPaths, maxDepthReached) {
	if (!pathOrPaths) return obj;

	if (Array.isArray(pathOrPaths[0])) {
		if (maxDepthReached) return obj;
		for (let p of pathOrPaths) {
			obj = baseRemoveNested(obj, p, true);
		}
		return obj;
	}
	return _.omit(obj, pathOrPaths.join('.'));
}

const initialState = {
	structureWasImportedAtLeastOnce: false,
	hasModifications: false,
	isEditMode: false,
	isEditing: false,
	currActiveSection: {},
	// Project properties:
	projectDetails: {},
	configurations: {},
	modifiedValues: {},
	// Create project:
	isCreateProjectPage: false,
	structureWasImportedAtLeastOnce: false,
	followStructure: false,
	newProjectDetails: {},
	projectStructure: {},
	buildingsOverview: {
		buildingId: null,
		floorId: null,
		unitId: null,
	},
	// Project structure:
	locationData: {},
	isProjectStructurePage: false,
	/* newFloors, // Upon import
  newBuilding,
  newUnits, */
	// Checklist manager:
	isChecklistManagerPage: false,
	locationItemData: {},
	checklistsBackup: {}, // Map from Redux store
	checklistItemsBackup: {}, // Map from Redux store
};

const excelSheetStructure = {
	unitId: { name: 'ID', types: ['String'] },
	floorNumber: { name: 'Floor', types: ['Number'], allowEmptyCells: false },
	unitName: { name: 'Unit name', types: ['String'] },
	isDeleted: { name: 'isDeleted?', types: ['Boolean'], isOptional: true },
};

class ProjectManager extends React.Component {
	constructor(props) {
		super(props);
		this.setComponentData = this.setComponentData.bind(this);
		this.toggleEdit = this.toggleEdit.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleConfirmCancel = this.handleConfirmCancel.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSelectSection = this.handleSelectSection.bind(this);
		this.handleImportedExcel = this.handleImportedExcel.bind(this);
		this.recalcHeaderWithOptions = this.recalcHeaderWithOptions.bind(this);
		this.calcInputField = this.calcInputField.bind(this);
		this.setBuildingType = this.setBuildingType.bind(this);
		this.calcSection = this.calcSection.bind(this);
		this.setBuildingOverview = this.setBuildingOverview.bind(this);
		this.resetImportedFile = this.resetImportedFile.bind(this);
		this.uploadImgIfNecessaryAndReturnUpdated = this.uploadImgIfNecessaryAndReturnUpdated.bind(this);
		this.convertExcel = this.convertExcel.bind(this);
		this.injectFloors = this.injectFloors.bind(this);
		this.resetState = this.resetState.bind(this);
		this.setHasModifications = this.setHasModifications.bind(this);
		this.updateLocation = this.updateLocation.bind(this);
		this.setProjectToReturnTo = this.setProjectToReturnTo.bind(this);
		this.setIsProjectStructurePage = this.setIsProjectStructurePage.bind(this);
		this.setIsCreateProjectPage = this.setIsCreateProjectPage.bind(this);
		this.setIsChecklistManagerPage = this.setIsChecklistManagerPage.bind(this);
		this.setLocationData = this.setLocationData.bind(this);
		this.setLocationItemData = this.setLocationItemData.bind(this);
		this.saveCurrProjectSructure = this.saveCurrProjectSructure.bind(this);
		this.updateLocalProjectStructure = this.updateLocalProjectStructure.bind(this);

		this.state = initialState;
	}

	setComponentData(props = this.props, force = false) {
		if (!props.isEditting || force) {
			let projectDetails = props.detailedProjects.getNested([props.selectedProjectId], false);
			let configurations = props.configurations;

			if (projectDetails && configurations) {
				let newState = {
					projectDetails: projectDetails.toJS ? projectDetails.toJS() : projectDetails,
					configurations,
				};

				this.setState(prevState => {
					prevState = baseRemoveNested(prevState, ['modifiedImages']);
					return { ...prevState, ...newState };
				});
			}
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { projectDetails, isEditMode, buildingsOverview } = this.state;
		const { selectedProjectId, detailedProjects, members, units, history } = this.props;

		let projectDetailsIsEmpty = Object.entries(projectDetails || {}).length === 0;
		let projectChanged =
			selectedProjectId != nextProps.selectedProjectId || detailedProjects != nextProps.detailedProjects;
		let membersChanged = members != nextProps.members;

		if (projectDetailsIsEmpty || projectChanged || membersChanged) {
			this.setComponentData(nextProps);
		}

		// if (isEditMode && (history || {}).getNested(['location', 'pathname'], null) != nextProps.getNested(['history', 'location', 'pathname'], null))
		// draftValidator(this.handleConfirmCancel);
	}

	UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
		const { importedFile, hasModifications, locationData } = this.state;
		const { units } = this.props;
		const { onDraftModeChange } = this.props;
		if (nextState.buildingsOverview.type == 'unit') {
			const { buildingId, unitId } = nextState.buildingsOverview;
			let nextUnit = nextProps.units.getNested([buildingId, unitId], {});

			if (
				nextState.locationData.getNested(['ordinalNo'], null) != nextUnit.getNested(['ordinalNo'], null) ||
				nextState.locationData.getNested(['floor', 'num'], null) != nextUnit.getNested(['floor', 'num'], null)
			)
				this.setLocationData(nextUnit.toJS ? nextUnit.toJS() : nextUnit);
		}

		if (onDraftModeChange && hasModifications !== nextState.hasModifications)
			onDraftModeChange(nextState.hasModifications);

		if (!importedFile && nextState.importedFile) this.handleImportedExcel(nextState);
	}

	componentDidMount() {
		const { hasModifications } = this.state;
		const { draftValidator, onDraftModeChange, intl } = this.props;

		if (onDraftModeChange) onDraftModeChange(hasModifications);
	}

	componentDidUpdate(prevProps, prevState) {
		const { hasModifications, headerOptions, isEditMode } = this.state;

		if (isEditMode && prevState.hasModifications !== hasModifications) this.recalcHeaderWithOptions(headerOptions);
	}

	toggleEdit() {
		this.setState(prevState => {
			const { isEditMode, isEditing } = prevState;

			let hasModifications = false;

			return {
				isEditMode: !isEditMode,
				isEditing: !isEditing,
				hasModifications,
				modifiedValues: {},
				currActiveSection: {},
			};
		});
	}

	handleSave(confirmFunc) {
		const { hasModifications, isCreateProjectPage } = this.state;
		const { startToast, intl } = this.props;
		const { title, content, yes, no } = systemMessages.confirmSaveChangesAlert;
		const {
			inputLabels: { buildingName, buildingGroundFloor, buildingTopFloor, projectName },
			sideNav,
		} = projectManagerMessages;

		let errors = [];
		let validProjectStructure = true;
		if (isCreateProjectPage) {
			const { projectStructure, newProjectDetails } = this.state;
			if (!newProjectDetails.getNested(['title'], '')) {
				validProjectStructure = false;
				errors.push(intl.formatMessage(projectName));
			} else if (
				Object.values(projectStructure.getNested(['buildings'], {})).length !==
				projectStructure.getNested(['numOfBuildings'], null)
			) {
				validProjectStructure = false;
				errors.push(intl.formatMessage(sideNav.projectStructure));
			}

			let currBuildingNumber = 1;
			projectStructure.getNested(['buildings'], {}).loopEach((i, building) => {
				let floorsAreWrong =
					parseInt(building.getNested(['minFloor'], 100000)) > parseInt(building.getNested(['maxFloor'], -100000));
				let noBuildingName = !Boolean(building.getNested(['title'], '').length);

				if (floorsAreWrong || noBuildingName) {
					validProjectStructure = false;
					if (floorsAreWrong)
						errors.push(
							`${intl.formatMessage(buildingGroundFloor)} / ${intl.formatMessage(
								buildingTopFloor,
							)} ${currBuildingNumber}`,
						);
					if (noBuildingName) errors.push(`${intl.formatMessage(buildingName)} ${currBuildingNumber}`);
				}
				currBuildingNumber++;
			});
		}

		if (!validProjectStructure) {
			const { invalidDetailsDescription } = systemMessages;
			startToast({
				title: invalidDetailsDescription,
				type: 'error',
				values: { errors: errors.join('\n') },
			});
			return;
		}

		let enhancedFunc = () => {
			let success = confirmFunc();
			if (success === false) {
				// TODO: display error
				return;
			}
			const { objectSavedSuccessfully, changes } = systemMessages;
			startToast({
				title: objectSavedSuccessfully,
				type: 'success',
				values: { objectName: changes },
			});
			this.toggleEdit();
		};

		if (hasModifications) {
			startToast({
				overlay: true,
				mandatory: true,
				title: title,
				message: content,
				actions: [{ message: yes, onClick: enhancedFunc, color: 'success' }, { message: no }],
			});
			return;
		}

		this.toggleEdit();
	}

	handleCancel(confirmFunc) {
		const { hasModifications } = this.state;
		const { startToast } = this.props;

		const { title, content, yes, no } = systemMessages.confirmCancelChangesAlert;

		let enhancedFunc = () => {
			confirmFunc();
			this.handleConfirmCancel();
		};

		if (hasModifications) {
			startToast({
				overlay: true,
				mandatory: true,
				title: title,
				message: content,
				actions: [{ message: yes, onClick: enhancedFunc, color: 'success' }, { message: no }],
			});
			return;
		}

		enhancedFunc();
	}

	handleConfirmCancel() {
		this.resetState(() => this.setComponentData(this.props, true));
	}

	handleInputChange(pathToValue, value, importType) {
		const { buildingsOverview, structureWasImportedAtLeastOnce } = this.state;
		const { selectedProjectId } = this.props;
		const { type, buildingId, floorId = null, unitId = null } = buildingsOverview;

		let pathRoot = pathToValue[0];
		let isLocationData = pathRoot === 'locationData';
		let formattedPath = isLocationData
			? [buildingId, type + 's', unitId ? unitId : floorId ? floorId : ''].filter(Boolean)
			: [pathToValue.slice(1).join('/')];
		let numBuildingsChanged = formattedPath[0] === 'numOfBuildings';
		let image = {};

		if (importType && importType === 'appendMode') pathRoot = 'appendImportedFile';
		// Preprocessing value
		if (typeof value !== 'string' && value instanceof Object && Object.entries(value || {}).length > 0) {
			if (value.hasOwnProperty('uri')) {
				image.pathRoot = pathRoot;
				image.uploadData = {
					data: value.uri,
					extension: value.extension,
				};
			} else if (
				value instanceof Object &&
				!['importedFile', 'appendImportedFile'].includes(pathRoot) &&
				!pathToValue.includes('location')
			)
				value = Object.values(value)[0];

			if (pathToValue.includes('maxFloor') || pathToValue.includes('minFloor')) value = parseInt(value);
		}

		if (pathRoot === 'configurations' && ['listeners', 'clientMS', 'V2'].every(key => pathToValue.includes(key))) {
			if (!value || value === 'disabled')
				value = { isActive: false };
			else
				value = { isActive: true, type: value };
		}

		this.setState(prevState => {
			pathToValue = [pathRoot, ...pathToValue.slice(1)];
			let newState = prevState.setNested(pathToValue, value);

			newState = newState.setNested(
				['modifiedValues', pathRoot, ...formattedPath],
				(value || {}).hasOwnProperty('uri') ? value.uri : isLocationData ? newState.locationData : value,
			);

			if (!prevState.hasModifications) newState = newState.setNested(['hasModifications'], true);
			if (Object.entries(image).length > 0) newState = newState.setNested(['modifiedImages', ...formattedPath], image);

			// Handle change in num of buildings (only createProjectPage)
			if (numBuildingsChanged) {
				if (value < prevState.getNested(['projectStructure', 'numOfBuildings'], 0)) {
					// if num of buildings decreased
					Object.keys(newState.getNested(['projectStructure', 'buildings'], {})).forEach((buildingId, index) => {
						// Remove created buildings if necessary;
						if (index + 1 > value) {
							newState = baseRemoveNested(newState, [
								['projectStructure', 'buildings', buildingId],
								['projectStructure', 'floors', buildingId],
								['projectStructure', 'units', buildingId],
								...['maxFloor', 'title', 'minFloor'].map(propertyName => [
									'modifiedValues',
									'projectStructure',
									`buildings/${buildingId}/${propertyName}`,
								]),
							]);
						}
					});

					const { buildings, floors, units } = newState.getNested(['projectStructure'], {});
					this.updateLocalProjectStructure(buildings, floors, units);
				}

				if (!prevState.structureWasImportedAtLeastOnce && !prevState.followStructure)
					newState = newState.setNested(['followStructure'], true);
			}

			if (!this.debouncedUpdateLocation)
				this.debouncedUpdateLocation = AwesomeDebouncePromise(newState => {
					this.updateLocation(newState.getNested(['locationData'], null), buildingsOverview.type, buildingId);
					this.debouncedUpdateLocation = false;
				}, 500);

			if (Object.keys(newState.locationData).length > 0) this.debouncedUpdateLocation(newState);

			return newState;
		});
	}

	updateLocation(newLocationData, type, buildingId) {
		const { selectedProjectId, updateLocalBuilding, updateLocalFloor, updateLocalUnit } = this.props;

		switch (type) {
			case 'building':
				updateLocalBuilding(selectedProjectId, newLocationData);
				break;
			case 'floor':
				updateLocalFloor(selectedProjectId, buildingId, newLocationData);
				break;
			case 'unit':
				updateLocalUnit(selectedProjectId, buildingId, newLocationData);
				break;
			default:
				return;
		}
	}

	handleSelectSection(key) {
		const { isEditMode, currActiveSection } = this.state;

		if (isEditMode && !currActiveSection[key]) this.setState({ currActiveSection: { [key]: true } });
	}

	setBuildingType(type) {
		this.setState(prevState => prevState.setNested(['newProjectDetails', 'type'], type));
	}

	setLocationData(locationData) {
		this.setState({ locationData });
	}

	setLocationItemData(locationItemData) {
		this.setState({ locationItemData });
	}

	setBuildingOverview(buildingsOverview) {
		this.setState({ buildingsOverview });
	}

	setHasModifications(bool) {
		this.setState({ hasModifications: bool });
	}

	setProjectToReturnTo(projectId) {
		this.setState({ projectToReturnTo: projectId });
	}

	setIsProjectStructurePage(bool) {
		this.setState({ isProjectStructurePage: bool });
	}

	setIsChecklistManagerPage(bool) {
		this.setState({ isChecklistManagerPage: bool });
	}

	setIsCreateProjectPage(bool) {
		this.setState({ isCreateProjectPage: bool });
	}

	async resetState(nextActionFunc = () => {}) {
		const { enterProject, getBuildings, getFloors, getUnits, selectedProjectId } = this.props;
		const { projectToReturnTo, isProjectStructurePage, isCreateProjectPage, isChecklistManagerPage } = this.state;
		if (isProjectStructurePage || isCreateProjectPage) {
			await getBuildings(isCreateProjectPage ? projectToReturnTo : selectedProjectId);
			await getFloors(isCreateProjectPage ? projectToReturnTo : selectedProjectId);
			await getUnits(isCreateProjectPage ? projectToReturnTo : selectedProjectId);
		}

		this.setState({ ...initialState, isChecklistManagerPage });

		if (nextActionFunc) nextActionFunc();
	}

	resetImportedFile(keepData = false) {
		const { selectedProjectId, removeLocalBuildings, removeLocalFloors, removeLocalUnits, isCreateProjectPage } =
			this.props;
		const { followStructure } = this.state;

		this.setState(prevState => {
			let newState = prevState.setNested(['importedFile'], null);
			newState = newState.setNested(['appendImportedFile'], null);
			if (!keepData) {
				removeLocalBuildings(selectedProjectId);
				removeLocalFloors(selectedProjectId);
				removeLocalUnits(selectedProjectId);
				newState = baseRemoveNested(
					newState,
					['floors', 'units'].map(path => ['projectStructure', path]),
				);
				if (isCreateProjectPage || !followStructure)
					newState = baseRemoveNested(newState, ['projectStructure', 'buildings']);
			}

			return newState;
		});
	}

	async uploadImgIfNecessaryAndReturnUpdated(obj, uploadEndPoint) {
		try {
			const { modifiedImages } = this.state;
			const { pathInObj, targetFileName, serverFolder } = uploadEndPoint;
			const { pathRoot, uploadData } = modifiedImages.getNested(pathInObj, {});

			if (uploadData.hasOwnProperty('data') && typeof targetFileName === 'string' && typeof serverFolder === 'string') {
				let imageUrl = uploadData.data ? await uploadImage(uploadData, targetFileName, serverFolder) : null;
				return obj.setNested([pathRoot, ...pathInObj], imageUrl);
			}
			return baseRemoveNested(obj, /* obj.removeNested( */ [pathRoot, ...pathInObj]);
		} catch {
			return obj;
		}
	}

	convertExcel(excelObj, projectStructure) {
		const { isProjectStructurePage, followStructure } = this.state;
		const { buildings, selectedProjectId, getNewUnitId, floors, units } = this.props;

		let newBuildings = {};
		let newFloors = {};
		let newUnits = {};
		let propsToRemove = [['floors'], ['units']];
		if (isProjectStructurePage) propsToRemove.push(['buildings']);

		let nextProjectStructure = baseRemoveNested(projectStructure, propsToRemove);

		excelObj.setGeneralSheetsStructure(excelSheetStructure);
		if (followStructure && Object.values(excelObj.sheets || {}).length !== projectStructure.numOfBuildings) {
			throw `The number of buildings set and the number of building structures in the excel don't match.`;
		}

		let buildingsToSearch = _.get(nextProjectStructure, ['buildings'], buildings);
		buildingsToSearch = buildingsToSearch.toJS ? buildingsToSearch.toJS() : buildingsToSearch;

		let currBuildingOrdinalNo = 1;
		excelObj.eachSheet((sheetName, sheet) => {
			let lowestFloor = null;
			let highestFloor = null;
			let currBuildingId = null;
			let currBuilding = null;
			Object.entries(buildingsToSearch).forEach(([buildingId, building]) => {
				if (
					((building || {}).getCementoTitle() || buildingId).trim().toLowerCase() === sheetName.trim().toLowerCase()
				) {
					currBuilding = building;
					currBuildingId = buildingId;
				}
			});

			if (followStructure && !currBuildingId)
				// TODO: add follow structure + make sure input fields refer to the right object (buildingId)
				throw `Could not match building names`;

			if (!currBuildingId) {
				let newBuilding = this.createNewBuilding(sheetName);
				_.set(newBuildings, [newBuilding.id], newBuilding);
				currBuilding = newBuilding;
				currBuildingId = newBuilding.id;
			}

			currBuilding.ordinalNo = currBuildingOrdinalNo;

			let prevFloorNum = null;
			let newOrdinalNo = 1;

			sheet.eachRow((row, rowNumber) => {
				const { unitId, unitName } = row.values;
				let { floorNumber } = row.values;

				floorNumber = Number(floorNumber);

				if (highestFloor === null || floorNumber > highestFloor) highestFloor = floorNumber;

				if (lowestFloor === null || floorNumber < lowestFloor) lowestFloor = floorNumber;

				if (floorNumber !== prevFloorNum) {
					prevFloorNum = floorNumber;

					const {
						locationFound,
						locationType,
						locationObjects = {},
					} = getFullLocationByPropValue(
						buildings,
						floors,
						units,
						{ num: floorNumber },
						{
							inBuildingId: currBuildingId,
							skipBuildings: true,
							skipUnits: true,
						},
					);
					let { floor } = locationObjects;
					if (!(locationFound && locationType === 'floor' && floor)) {
						floor = this.createNewFloor(currBuildingId, floorNumber);
						_.set(newFloors, [currBuildingId, floor.id], floor);
					}

					floor = floor.toJS ? floor.toJS() : floor;
					nextProjectStructure = _fp.set(['floors', currBuildingId, floor.id], floor, nextProjectStructure);
				}

				if (!unitId && !unitName) return; // empty floors

				let newUnit;
				if (unitId)
					newUnit = {
						id: unitId,
						floor: {
							num: floorNumber,
						},
						title: unitName,
						ordinalNo: newOrdinalNo,
					};
				else {
					newUnit = this.createNewUnit(currBuildingId, unitName, floorNumber, newOrdinalNo);
					_.set(newUnits, [currBuildingId, newUnit.id], newUnit);
				}

				nextProjectStructure = _fp.set(['units', currBuildingId, newUnit.id], newUnit, nextProjectStructure);
				newOrdinalNo++;
			});

			highestFloor = Math.ceil(highestFloor);
			lowestFloor = Math.ceil(lowestFloor);

			if (followStructure && (currBuilding.maxFloor !== highestFloor || currBuilding.minFloor !== lowestFloor)) {
				throw `The floors don't match specifications.`; // TODO: same as project structure
			}

			if (highestFloor > currBuilding.getNested(['maxFloor'], -1000000))
				currBuilding = currBuilding.setNested(['maxFloor'], highestFloor);

			if (lowestFloor < currBuilding.getNested(['minFloor'], 1000000))
				currBuilding = currBuilding.setNested(['minFloor'], lowestFloor);

			nextProjectStructure = _fp.set(['buildings', currBuildingId], currBuilding, nextProjectStructure);
			currBuildingOrdinalNo++;
		});

		return { nextProjectStructure, newBuildings, newFloors, newUnits };
	}

	createNewBuilding(name, minFloor = null, maxFloor = null) {
		const { selectedProjectId, getNewBuildingId } = this.props;

		const { buildingId } = getNewBuildingId(selectedProjectId).payload;

		return {
			id: buildingId,
			title: name,
			minFloor,
			maxFloor,
			createdAt: Date.now(),
		};
	}

	createNewFloor(buildingId, floorNum) {
		const { selectedProjectId, getNewFloorId } = this.props;
		const { floorId } = getNewFloorId(selectedProjectId, buildingId).payload;

		return {
			id: floorId,
			num: floorNum,
		};
	}

	createNewUnit(buildingId, title, floorNum, ordinalNo) {
		const { selectedProjectId, getNewUnitId } = this.props;
		const { unitId } = getNewUnitId(selectedProjectId, buildingId).payload;

		return {
			id: unitId,
			floor: {
				num: floorNum,
			},
			title: title,
			ordinalNo,
		};
	}

	saveCurrProjectSructure() {
		const { units, project, selectedProjectId, updateProjectFields, updateBuildings, updateFloors, updateUnits } =
			this.props;
		const { projectStructure, isProjectStructurePage } = this.state;
		let { buildings, floors } = this.props;
		buildings = buildings.toJS ? buildings.toJS() : buildings;
		if (
			!Object.keys(buildings).length &&
			!isProjectStructurePage &&
			Object.keys(projectStructure.buildings || {}).length
		) {
			// createProject if only set building structure without import
			Object.entries(projectStructure.buildings).forEach(([buildingId, building]) => (building.id = buildingId));
			let nextProjectStructure = this.injectFloors(projectStructure);
			const { buildings: nextBuildings, floors: nextFloors } = nextProjectStructure;
			buildings = nextBuildings;
			floors = nextFloors;
		}

		const projectType = project.getNested(['type']);
		const numOfBuildings = Object.keys(buildings).length;
		if (numOfBuildings > 1 && projectType !== PROJECT_TYPE_COMPLEX_BUILDINGS)
			// TODO: replace with constant type
			updateProjectFields({ type: PROJECT_TYPE_COMPLEX_BUILDINGS }, selectedProjectId);
		else if (numOfBuildings === 1 && projectType !== PROJECT_TYPE_BUILDING)
			updateProjectFields({ type: PROJECT_TYPE_BUILDING }, selectedProjectId);
		if (updateBuildings) updateBuildings(selectedProjectId, buildings, true);
		Object.keys(buildings).forEach(buildingId => {
			if (updateFloors) updateFloors(selectedProjectId, buildingId, floors.getNested([buildingId]), true);
			if (updateUnits) updateUnits(selectedProjectId, buildingId, units.getNested([buildingId]), true);
		});
	}

	injectFloors(nextProjectStructure) {
		const { buildings } = nextProjectStructure;

		Object.entries(buildings).forEach(([buildingId, buildingData]) => {
			const { minFloor, maxFloor } = buildingData;
			let floorsData = {};
			for (let currFloorNum = minFloor; currFloorNum <= maxFloor; currFloorNum++) {
				const newFloor = this.createNewFloor(buildingId, currFloorNum);
				floorsData = floorsData.setNested([newFloor.id], newFloor);
			}
			nextProjectStructure = nextProjectStructure.setNested(['floors', buildingId], floorsData);
		});

		return nextProjectStructure;
	}

	handleImportedExcel(nextState) {
		const { buildings, startToast, startLoading, hideLoading } = this.props;
		const {
			isProjectStructurePage,
			isChecklistManagerPage,
			projectStructure = {},
			importedFile,
			isCreateProjectPage,
		} = nextState;

		if (isChecklistManagerPage) return;

		let nextProjectStructure = projectStructure;
		if (!nextProjectStructure.getNested(['buildings'], false))
			nextProjectStructure = nextProjectStructure.setNested(
				['buildings'],
				buildings.toJS ? buildings.toJS() : buildings,
			);

		Object.keys(nextProjectStructure.getNested(['buildings'], {})).forEach(buildingId => {
			nextProjectStructure = nextProjectStructure.setNested(['buildings', buildingId, 'id'], buildingId);
		});

		startLoading({
			title: systemMessages.loadingMessage,
			overlay: true,
			hideOnBackgroundPress: false,
		});

		let toastData;
		setTimeout(() => {
			// Gives time for startLoading to start loading.
			try {
				const {
					nextProjectStructure: newNextProjectStructure,
					newBuildings,
					newFloors,
					newUnits,
				} = this.convertExcel(importedFile, nextProjectStructure);

				if (isCreateProjectPage && !_.isNil(newNextProjectStructure.floors) && !Object.keys(newNextProjectStructure.floors).length)
					newNextProjectStructure = this.injectFloors(newNextProjectStructure);

				const { buildings: nextBuildings, floors, units } = newNextProjectStructure;
				this.updateLocalProjectStructure(nextBuildings, floors, units);

				if (isProjectStructurePage) this.resetImportedFile(true);
				this.setState({
					projectStructure: newNextProjectStructure,
					newBuildings,
					newFloors,
					newUnits,
					structureWasImportedAtLeastOnce: true,
				});
				toastData = { title: systemMessages.importSuccess, type: 'success' };
			} catch (error) {
				console.log('error', error);
				this.resetImportedFile(true);
				toastData = {
					overlay: true,
					mandatory: true,
					title: systemMessages.importError,
					message: systemMessages.errorOnImport,
					values: { error },
					actions: [{ message: systemMessages.ok }],
				};
			} finally {
				hideLoading();
				startToast(toastData);
			}
		}, 1);
	}

	updateLocalProjectStructure(buildings, floors, units) {
		const { selectedProjectId, getLocalBuildings, getLocalFloors, getLocalUnits } = this.props;

		if (buildings && getLocalBuildings) getLocalBuildings(selectedProjectId, buildings);
		if (floors && getLocalFloors) getLocalFloors(selectedProjectId, floors);
		if (units && getLocalUnits) getLocalUnits(selectedProjectId, units);
	}

	recalcHeaderWithOptions(options) {
		const { setHeaderParams } = this.props;
		const { isEditMode, hasModifications } = this.state;
		const { mainContainer, buttonContainer, textButton } = headerComponentStyles;
		const {
			customComp,
			editOffMessage,
			editOffComp,
			editOnComp,
			extraOnComp,
			confirmCancelFunc = this.handleConfirmCancel,
			confirmSaveFunc = () => {},
			editOffFunc = () => {},
			allowToggleEdit = true,
		} = options;

		let headerComponent = null;

		if (customComp) headerComponent = customComp;
		else {
			headerComponent = (
				<div style={mainContainer}>
					{Boolean(isEditMode) ? (
						<>
							{Boolean(editOnComp) ? (
								editOnComp
							) : (
								<>
									<div
										style={{
											...buttonContainer,
											color: hasModifications ? theme.brandPrimary : theme.brandNeutralLight,
										}}
										onClick={() => this.handleSave(confirmSaveFunc)}
									>
										<Text style={{ ...textButton, fontWeight: theme.strongBold }}>{propertiesMessages.save}</Text>
									</div>
									<div style={buttonContainer} onClick={() => this.handleCancel(confirmCancelFunc)}>
										<Text style={textButton}>{propertiesMessages.cancel}</Text>
									</div>
									{Boolean(extraOnComp) && extraOnComp}
								</>
							)}
						</>
					) : (
						<>
							<div
								style={{
									...buttonContainer,
									...(!allowToggleEdit ? { cursor: 'not-allowed', color: theme.brandNeutralLight } : {}),
								}}
								onClick={() => {
									if (!allowToggleEdit) return;
									editOffFunc();
									this.toggleEdit();
								}}
							>
								<div style={textButton}>
									{Boolean(editOffComp) ? (
										editOffComp
									) : (
										<Text>{editOffMessage ? editOffMessage : systemMessages.edit}</Text>
									)}
								</div>
							</div>
						</>
					)}
				</div>
			);
		}

		if (setHeaderParams)
			setHeaderParams({
				headerComponent: headerComponent,
				sideBarParams: { alwaysOpen: true },
			}); //false, open: true, disabled: false } });
		this.setState({ headerOptions: options });
	}

	calcSection(section, key) {
		const { viewer } = this.props;
		const { isEditMode, currActiveSection } = this.state;
		const { rtl } = this.props;

		const { title, fields, props } = section;
		const { adminOnly = false, ...rest } = props;
		let { show } = section;

		if (adminOnly && !viewer.adminMode) show = false;

		return (
			show && (
				<CollapsibleSection
					key={'manager_' + title + key}
					rtl={rtl}
					open={key === 0 ? true : false}
					section={{ title: title }}
					isSelected={isEditMode ? currActiveSection.getNested([key], false) : false}
					onSelect={() => this.handleSelectSection(key)}
					{...rest}
				>
					{Boolean(fields) && fields.map(this.calcInputField)}
				</CollapsibleSection>
			)
		);
	}

	calcInputField(field, key) {
		const { isEditMode } = this.state;
		const { viewer, intl, projectCompanies } = this.props;
		const { name, type, pathToValue, values = null, props = {}, defaultValue = false } = field;
		const { style, overWriteStyle = false, editable = true, adminOnly = false, ...rest } = props;

		let value = this.state.getNested(pathToValue, null);

		if (value === null && defaultValue) {
			value = defaultValue;
			if (isEditMode) this.handleInputChange(pathToValue, value);
		}

		// Handle special InputFields
		if (type === 'SelectionList') {
			if (pathToValue.includes('maxFloor') || pathToValue.includes('minFloor'))
				value = (value || value === 0 ? value : '').toString();

			if (pathToValue.includes('companyId') && !values.length)
				Object.entries(projectCompanies).forEach(([id, company]) => values.push({ id, title: company.name }));

			if (['clientMS', 'V2', 'listeners'].every(key => pathToValue.includes(key))) {
				if (!value?.isActive)
					value = 'disabled';
				else
					value = value.type;
			}
			value = { [value]: value };
		}

		if (['Picture', 'PDF', 'DrawingsArray', 'FilesDropzone'].includes(type))
			if (typeof value === 'string') value = { uri: value };
			else if (!value) value = [];

		let adminOnlyEditMode = adminOnly ? isEditMode && Boolean(viewer.adminMode) : isEditMode;

		let disabled = editable ? !adminOnlyEditMode : true;
		let keyId = 'manager_' + this.props.selectedProjectId + pathToValue[0] + key;
		return (
			<InputField
				style={overWriteStyle ? style : { ...inputStyles, ...(style || {}) }}
				key={keyId}
				id={keyId}
				onChange={(val, importType) => this.handleInputChange(pathToValue, val, importType)}
				disabled={disabled}
				name={name}
				type={type}
				value={value}
				values={values ? values : null}
				inputInfo={
					!Boolean(viewer.adminMode) && adminOnly && isEditMode
						? intl.formatMessage(systemMessages.unableToEditAlert.message)
						: null
				}
				{...rest}
			/>
		);
	}

	render() {
		const { rtl } = this.props;

		return (
			<ProjectManagerContext.Provider
				value={{
					...this.state,
					...this.props,
					handleInputChange: this.handleInputChange,
					calcInputField: this.calcInputField,
					uploadImgIfNecessaryAndReturnUpdated: this.uploadImgIfNecessaryAndReturnUpdated,
					handleCancel: this.handleCancel,
					toggleEdit: this.toggleEdit,
					handleConfirmCancel: this.handleConfirmCancel,
					handleSave: this.handleSave,
					handleSelectSection: this.handleSelectSection,
					setBuildingType: this.setBuildingType,
					calcSection: this.calcSection,
					setBuildingOverview: this.setBuildingOverview,
					resetImportedFile: this.resetImportedFile,
					recalcHeaderWithOptions: this.recalcHeaderWithOptions,
					setLocationData: this.setLocationData,
					setHasModifications: this.setHasModifications,
					setProjectToReturnTo: this.setProjectToReturnTo,
					injectFloors: this.injectFloors,
					setIsProjectStructurePage: this.setIsProjectStructurePage,
					onLocationItemChange: this.setLocationItemData,
					setIsChecklistManagerPage: this.setIsChecklistManagerPage,
					setIsCreateProjectPage: this.setIsCreateProjectPage,
					saveCurrProjectSructure: this.saveCurrProjectSructure,
					wrapperStyles,
					headerComponentStyles,
				}}
			>
				<OffsetWrapper rtl={rtl}>
					<Switch>
						<Route
							path='/main/projectContainerPage/:selectedProjectId/projectManager/createProject'
							render={() => <CreateProject />}
						/>
						<Route
							path='/main/projectContainerPage/:selectedProjectId/projectManager/projectProperties'
							render={() => <ProjectProperties />}
						/>
						<Route
							path='/main/projectContainerPage/:selectedProjectId/projectManager/projectStructure'
							render={() => <ProjectStructure />}
						/>
						<Route
							path='/main/projectContainerPage/:selectedProjectId/projectManager/ChecklistManagement'
							render={() => <ChecklistManager />}
						/>
						<Route
							path='/main/projectContainerPage/:selectedProjectId/projectManager/camerasDashboard'
							render={() => <CamerasManger />}
						/>
					</Switch>
				</OffsetWrapper>
			</ProjectManagerContext.Provider>
		);
	}
}

const wrapperStyles = {
	width: 'auto',
	flexGrow: 1,
};

const commonFontSize = theme.fontSizeH5 - 2;

const headerComponentStyles = {
	mainContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row-reverse',
		height: '100%',
		margin: '0px ' + 2 * theme.margin + 'px',
	},
	buttonContainer: {
		display: 'flex',
		alignItems: 'center',
		paddingRight: theme.verticalMargin,
		cursor: 'pointer',
	},
	textButton: {
		margin: theme.verticalMargin,
		fontSize: commonFontSize,
	},
};

const inputStyles = {
	marginBottom: theme.margin * 2 - 10,
	fontSize: commonFontSize,
};

ProjectManager = injectIntl(ProjectManager);

const enhance = compose(
	connectContext(ProjectContext.Consumer),
	connect(
		state => ({
			rtl: state.app.rtl,
			tempProject: state.projects.tempProject,
			draftMode: state.ui.inDraftMode,
		}),
		{
			onDraftModeChange,
			draftValidator,
			startToast,
			updateProjectFields,
			updateExternalSettings,
			updateConfigurations,
			enterProject,

			getBuildings,
			getNewBuildingId,
			getLocalBuildings,
			updateBuildings,
			updateLocalBuilding,
			removeLocalBuildings,

			getFloors,
			getNewFloorId,
			getLocalFloors,
			updateFloors,
			updateLocalFloor,
			removeLocalFloors,

			getUnits,
			getLocalUnits,
			getNewUnitId,
			updateLocalUnit,
			updateUnits,
			updateLocalUnits,
			removeLocalUnits,

			startLoading,
			hideLoading,
		},
	),
);

export default enhance(ProjectManager);

// ############################################################################################

function OffsetWrapper(props) {
	const { rtl, children } = props;
	const { headerHeight, headerHeightSecondary, sidebarWidth } = theme;

	const offsetTop = headerHeight + (headerHeightSecondary ? headerHeightSecondary : 0);
	const offsetSide = sidebarWidth;

	return (
		<div
			style={{
				position: 'absolute',
				top: offsetTop,
				overflowY: 'scroll',
				[rtl ? 'right' : 'left']: offsetSide,
				height: 'calc(100vh - ' + offsetTop + 'px)',
				width: 'calc(100vw - ' + offsetSide + 'px)',
				display: 'flex',
			}}
		>
			{children}
		</div>
	);
}
