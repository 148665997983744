import * as actions from './actions';
import * as lastUpdatesActions from '../lastUpdates/actions';
import { REPLACE_USER } from '../auth/actions';
import Drawing from './drawing';
import { OrderedMap, Map } from 'immutable';
import { Record } from '../transit';
import {BUILDINGS, FLOORS, UNITS} from './actions'
import {convertDrawingsV2ToV1} from './funcs';
import { DEFAULT_LAST_UPDATE_TS } from '../app/constants';

const InitialState = Record({
  map: Map(),
  lastUpdated: Map(),
  lastUpdateAvailable: Map(),
  didLoad: OrderedMap(),
  settings: OrderedMap(),
}, 'drawings');

const initialState = new InitialState;
export const DRAWINGS_KEY = 'drawings/v2';

export default function drawingReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    case actions.GET_DRAWING_SETTINGS + "_SUCCESS": {
      if (!action.payload)
        return state;
      const { projectId, settings } = action.payload;
      if (!settings || !settings.all)
        return state.setIn(['settings', projectId, 'empty'], true);

      var projectSettings = settings.all;
      return state.setIn(['settings', projectId, 'all'], projectSettings);
    }

    case lastUpdatesActions.GET_LAST_UPDATES: {
      if (!action.payload || !action.payload.projects)
        return state;

      const { projects } = action.payload;
      const arrDrawingsTypes = [BUILDINGS, FLOORS, UNITS];

      Object.keys(projects).forEach(projectId => {
        if (projects[projectId]) {
          arrDrawingsTypes.forEach(drawingType => {
            let TS = projects.getNested([projectId, 'drawings', drawingType, 'lastUpdateTS'], DEFAULT_LAST_UPDATE_TS);
            let curr = state.getNested(['lastRevokeAvailable', projectId, drawingType], 0)
            if (curr < TS) state = state.setIn(['lastUpdateAvailable', projectId, drawingType], TS)
          })
        }
      });

      return state;
    }

    case actions.GET_DRAWINGS + "_SUCCESS": {
      try {
        const { projectId, drawingType } = action.payload;
        const drawings = convertDrawingsV2ToV1(action.payload[DRAWINGS_KEY], projectId);
        state = state.setIn(['didLoad', projectId], true);

        state = setDrawings(drawings, state, projectId, drawingType);
        //var lastUpdateAvailable = state.getNested(['lastUpdateAvailable', projectId, drawingType], 1);
        if (state.lastUpdateAvailable.get(projectId) &&
          state.lastUpdateAvailable.get(projectId).get(drawingType))
          state = state.setIn(['lastUpdated', projectId, drawingType], state.lastUpdateAvailable.get(projectId).get(drawingType));
      }
      catch (err) {
        console.log(err);
      }

      return state;
    }

    case `${actions.UPDATE_DRAWINGS}_SUCCESS`: {
      const { drawingsUpdates, projectId } = action.payload;
      const drawings = convertDrawingsV2ToV1(drawingsUpdates, projectId);

      return setDrawings(drawings, new InitialState, projectId);
    }

    case actions.CLEAN_DRAWINGS_CACHE_DATA:
    case REPLACE_USER + "_SUCCESS": {
      return initialState;
    }
  }

  return state;
}

const setDrawings = (drawings, state, projectId) => {
  if (!drawings) {
    return state;
  }
  drawings.loopEach((currBuildingKey, currBuilding) => {
    let buildingDrawings = state.getNested(['map', projectId, currBuildingKey]);

    if (!buildingDrawings) {
      buildingDrawings = new Map();
    }

    currBuilding.loopEach((currUnitKey, currUnit) => {
      currUnit.loopEach((currDrawingKey, currDrawing) => {
        const drawing = new Drawing({ type: currDrawing?.locationData?.locationType, ...currDrawing });
        buildingDrawings = buildingDrawings.setIn([currUnitKey, drawing.id], drawing)
      });
    });

    state = state.setIn(['map', projectId, currBuildingKey], buildingDrawings);
  });

  return state;
}

