import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import theme from '../../assets/css/theme';
import CompanyTable from '../../components/CementoComponents/CompanyTable/CompanyTable';
import MenuScrollbar from '../../components/CementoComponents/MenuScrollbar';
import _ from 'lodash';
import moment from 'moment';
import StandardInput from '../../components/CementoComponents/StandardInput';
import systemMessages from '../../../common/app/systemMessages';
import safetyMessages from '../../../common/safety/safetyMessages';
import { startLoading, hideLoading } from '../../../common/app/actions';
import { connect } from 'react-redux';
import { compose, hoistStatics } from 'recompose';
import Text from '../../components/CementoComponents/Text';
import RefreshIcon from '@material-ui/icons/Refresh';
import TimeFilter, {
	defaultOption as defaultTSFilter,
	timeFilteringOptions,
} from '../../components/CementoComponents/TimeFilter';
import HoverWrapper from '../../components/CementoComponents/HoverWrapper';
import companiesMessages from '../../../common/companies/companiesMessages';
const ALL_PROJECTS_STATS="allProjectsStats"
class CompanySafetyTable extends Component {
	constructor(props) {
		super(props);
		this.handleTimeStampFilter = this.handleTimeStampFilter.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.handleProjectClick = this.handleProjectClick.bind(this);

		let startTS = _.get(timeFilteringOptions, [defaultTSFilter, 'getStartTS'], _.noop)();
		let endTS = _.get(timeFilteringOptions, [defaultTSFilter, 'getEndTS'], _.noop)();

		this.state = {
			columns: [],
			selectedCompanyId: null,
			tablesData: [],
			selectedTimeStampFilter: timeFilteringOptions[defaultTSFilter],
			startTS,
			endTS,
		};
	}

	UNSAFE_componentWillMount() {
		this.setComponentData({}, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setComponentData(this.props, nextProps);
	}

	async setComponentData(props, nextProps) {
		let stateChanges = {};

		if (
			nextProps.isValDiff(props, ['urlParams', 'selectedCompanyId']) ||
			nextProps.isValDiff(props, ['selectedCompanyId'])
		) {
			let newSelectedCompanyId =
				nextProps.getNested(['urlParams', 'selectedCompanyId']) || nextProps.getNested(['selectedCompanyId']);
			this.fetchData(newSelectedCompanyId);
			this.recalcHeader(newSelectedCompanyId);
			stateChanges.selectedCompanyId = newSelectedCompanyId;
		}

		if (Object.values(stateChanges).length > 0) this.setState(stateChanges);
	}

	async fetchData(newSelectedCompanyId = null) {
		const { startTS = null, endTS = null } = this.state;
		const { fetchCompanyTablesData, companies, selectedCompanyId,intl } = this.props;

		if (fetchCompanyTablesData) {
			let tablesData = await fetchCompanyTablesData('safety', newSelectedCompanyId, startTS, endTS);
			const allProjectsStatsIndex = _.findIndex(tablesData, i => i.id == ALL_PROJECTS_STATS);
			tablesData[allProjectsStatsIndex] = _.merge(tablesData[allProjectsStatsIndex], {
				isSummaryRow: true,
				projectName: companies.getNested([selectedCompanyId || newSelectedCompanyId, 'name']),
				project: {
					title: companies.getNested([selectedCompanyId || newSelectedCompanyId, 'name']),
					address:intl.formatMessage(companiesMessages.companyData),
					image: companies.getNested([selectedCompanyId || newSelectedCompanyId, 'logo'])
				}
			});
			let columns = [...safetyTableConfig.columns, ...this.getReportColumns(tablesData)];
			this.setState({ tablesData, columns });
		}
	}

	getReportColumns(data) {
		const { formUniversalIdsMap, intl } = this.props;
		let reports = {};
		data.forEach(project => {
			Object.keys(project.reports || {}).forEach(reportId => {
				let report = formUniversalIdsMap.getNested([reportId]);
				if (!report) return;

				let pathToValue = ['reports', reportId, 'countOfTours'];
				let title = report.description
					? report.getNested(['description', intl.locale], Object.values(report.getNested(['description']))[0])
					: report.title;

				if (!reports[reportId])
					reports[reportId] = {
						section: 'lastDays',
						headerClassName: 'safetyReportCol',
						name: reportId,
						title,
						displayEmptyCell: true,
						type: 'string',
						path: pathToValue,
						getColor: val => (val == 0 ? `${theme.brandNeutralLight}80` : 'inherit'),
						customStyle: { fontSize: theme.fontSizeH5 },
					};
			});
		});
		return Object.values(reports);
	}

	handleTimeStampFilter(startTS, endTS, selectedTimeStampFilter) {
		let stateChanges = {
			selectedTimeStampFilter,
			startTS,
			endTS,
		};

		this.setState(stateChanges, this.fetchData);
	}

	recalcHeader(companyId) {
		const { setHeaderParams, isPdfMode, rtl, intl } = this.props;

		if (!setHeaderParams || isPdfMode) return;

		let sideBarParams = { open: false, outerClickClose: true };
		let headerComponent = (
			<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
				<TimeFilter key={`company_${companyId}_timeFilter`} onChange={this.handleTimeStampFilter} isClearable={false} />
				<HoverWrapper
					props={{ color: 'inherit' }}
					hoverProps={{ color: theme.brandPrimary }}
					renderItem={({ color }) => (
						<div
							onClick={() => this.fetchData()}
							style={{
								display: 'flex',
								alignItems: 'center',
								padding: 2 * theme.padding,
								cursor: 'pointer',
							}}
						>
							<RefreshIcon style={{ fontSize: theme.fontSizeH3, color }} />
							<Text
								style={{
									fontWeight: 700,
									[`margin${rtl ? 'Right' : 'Left'}`]: theme.margin / 3,
									color,
								}}
							>
								{systemMessages.refresh}
							</Text>
						</div>
					)}
				/>
			</div>
		);
		setHeaderParams({ headerComponent, sideBarParams });
	}

	handleProjectClick(projectId) {
		const { onProjectClick } = this.props;
		const innerPath = 'safety/dashboard';
		if (onProjectClick) onProjectClick(projectId, innerPath);
	}

	render() {
		const { tablesData, selectedTimeStampFilter, columns = [] } = this.state;
		const { rtl, isPdfMode } = this.props;

		if (!tablesData) return;

		let Table = (
			<div style={{ display: 'flex' }}>
				<div style={styles.tableContainer}>
					<div id={'safetyTable'}>
						<CompanyTable
							sections={safetyTableConfig.sections}
							columns={columns}
							data={tablesData}
							rtl={rtl}
							onProjectClick={this.handleProjectClick}
							isPdfMode={isPdfMode}
							timeFilterTitle={selectedTimeStampFilter.tableTitle}
						/>
					</div>
				</div>
			</div>
		);

		return isPdfMode ? (
			Table
		) : (
			<MenuScrollbar isSmooth={false} withHorizontalScroll={true}>
				{Table}
			</MenuScrollbar>
		);
	}
}

const enhance = compose(
	connect(state => ({
		companies: state.companies.map
	}), {
		startLoading,
		hideLoading,
	}),
	injectIntl,
);
export default enhance(CompanySafetyTable);

const styles = {
	tableContainer: {
		display: 'flex',
		backgroundColor: theme.foregroundColor,
	},
	pdfModeTableContainer: {
		display: 'flex',
		backgroundColor: theme.foregroundColor,
	},
};

const safetyTableConfig = {
	sections: [
		{
			name: 'project',
			title: safetyMessages.safetyTable.project,
		},
		{
			name: 'safetyGrade',
			title: safetyMessages.safetyTable.safetyGrade,
			withBorders: true,
		},
		{
			name: 'openIssues',
			title: safetyMessages.safetyTable.openSafetyIssues,
			withBorders: true,
		},
		{
			name: 'employeesCertifications',
			title: safetyMessages.safetyTable.employeesCertifications,
			withBorders: true,
		},
		{
			name: 'equipmentReviews',
			title: safetyMessages.safetyTable.equipmentReviews,
			withBorders: true,
		},
		{
			name: 'safetySuperiorInspection',
			title: safetyMessages.safetyTable.inspectorTours,
			withBorders: true,
		},
		{
			name: 'lastDays',
			title: safetyMessages.safetyTable.recentUse,
			injectTimeFilterTitle: true,
			withBorders: true,
		},
	],
	columns: [
		{
			section: 'project',
			name: 'projectImage',
			type: 'image',
			path: ['project', 'image'],
			width: 100,
			summaryRowImageCustomStyle: {
				objectFit: 'contain',
				objectPosition: 'center'
			},

		},
		{
			section: 'project',
			name: 'projectName',
			type: 'col',
			customStyle: {
				padding: theme.padding / 2,
				alignItems: 'flex-start',
			},
			subRows: [
				{
					type: 'string',
					path: ['project', 'title'],
					customStyle: {
						alignItems: 'flex-start',
						fontSize: theme.fontSizeH6,
						height: 'auto',
						overflow: 'hidden',
					},
					width: 'auto',
				},
				{
					type: 'string',
					path: ['project', 'address'],
					customStyle: { flex: 1 },
					width: 'auto',
				},
			],
			width: 210,
		},
		{
			section: 'safetyGrade',
			title: safetyMessages.safetyTable.currentGrade,
			name: 'safetyGrade',
			type: 'grade',
			// width: styles.row.height,
			customStyle: { padding: null },
			path: ['safetyGrade', 'currGrade'],
			hideConditionPath: ['safetyGrade', 'hideSafetyGrade'],
			width: 100,
			default: -1,
		},
		{
			section: 'safetyGrade',
			title: safetyMessages.safetyTable.last14SafetyGrades,
			name: 'last14SafetyGrades',
			type: 'graph',
			width: 260,
			customStyle: { alignItems: null },
			defaultValue: [],
			path: ['safetyGrade', 'historyGrades'],
		},
		{
			section: 'openIssues',
			title: safetyMessages.safetyTable.openSafetyIssues,
			name: 'openIssuesHigh',
			width: 100,
			customStyle: { fontSize: theme.fontSizeH5 },
			type: 'counter',
			title: safetyMessages.safetyTable.openSafetyIssuesHigh,
			path: ['issues', 'open', 'high'],
			defaultValue: 0,
			counterColorType: 'danger',
		},
		{
			section: 'openIssues',
			name: 'openIssuesMed',
			width: 100,
			customStyle: { fontSize: theme.fontSizeH5 },
			type: 'counter',
			title: safetyMessages.safetyTable.openSafetyIssuesMedium,
			path: ['issues', 'open', 'med'],
			defaultValue: 0,
			counterColorType: 'warning',
		},
		{
			section: 'openIssues',
			name: 'openIssuesMed',
			width: 100,
			customStyle: { fontSize: theme.fontSizeH5 },
			type: 'counter',
			title: safetyMessages.safetyTable.openSafetyIssuesLow,
			path: ['issues', 'open', 'low'],
			defaultValue: 0,
			counterColorType: 'low',
		},
		{
			section: 'employeesCertifications',
			name: 'employeesCertificationsExpired',
			type: 'counter',
			title: safetyMessages.safetyTable.employeesCertificationsExpired,
			width: 100,
			customStyle: { fontSize: theme.fontSizeH5 },
			path: ['certifications', 'employees', 'expired'],
			defaultValue: 0,
			counterColorType: 'danger',
		},
		{
			section: 'employeesCertifications',
			name: 'employeesCertificationsWarning',
			type: 'counter',
			title: safetyMessages.safetyTable.employeesCertificationsWarning,
			width: 100,
			customStyle: { fontSize: theme.fontSizeH5 },
			path: ['certifications', 'employees', 'warning'],
			defaultValue: 0,
			counterColorType: 'warning',
		},

		{
			section: 'equipmentReviews',
			type: 'counter',
			title: safetyMessages.safetyTable.equipmentReviewsExpired,
			path: ['certifications', 'equipment', 'expired'],
			defaultValue: 0,
			counterColorType: 'danger',
			width: 100,
			customStyle: { fontSize: theme.fontSizeH5 },
		},
		{
			section: 'equipmentReviews',
			type: 'counter',
			title: safetyMessages.safetyTable.equipmentReviewsWarning,
			path: ['certifications', 'equipment', 'warning'],
			defaultValue: 0,
			counterColorType: 'warning',
			width: 100,
			customStyle: { fontSize: theme.fontSizeH5 },
		},
		{
			section: 'safetySuperiorInspection',
			name: 'lastSafetySuperiorInspection',
			title: safetyMessages.safetyTable.lastInspectorTour,
			type: 'col',
			path: ['reports', 'safetySuperiorInspection'],
			width: 170,
			subRows: [
				{
					type: 'string',
					path: ['reports', 'safetySuperiorInspection', 'lastTour'],
					width: 150,
					// customStyle: styles.reportSubCell,
					valueFormatter: (value, intl) =>
						value ? moment(value).format('l') : safetyMessages.safetyTable.noInspectionTour,
					getColor: val => (val ? 'inherit' : theme.brandRealDanger),
				},
				{
					type: 'string',
					path: ['reports', 'safetySuperiorInspection', 'daysSinceLastTour'],
					defaultValue: 0,
					customStyle: {
						// ...styles.reportSubCell,
						alignItems: 'start',
					},
					valueFormatter: (value, intl) =>
						value
							? intl.formatMessage(safetyMessages.safetyTable.daysAgo, {
									value,
							  })
							: '',
				},
			],
		},
		{
			section: 'lastDays',
			title: safetyMessages.safetyTable.weeklyOpenIssues,
			name: 'weeklyOpenIssues',
			getColor: val => (val == 0 ? `${theme.brandNeutralLight}80` : 'inherit'),
			type: 'string',
			path: ['issues', 'new', 'weekly'],
			defaultValue: 0,
			customStyle: {
				fontSize: theme.fontSizeH5,
			},
		},
		{
			section: 'lastDays',
			title: safetyMessages.safetyTable.weeklyEmployees,
			name: 'weeklyEmployees',
			getColor: val => (val == 0 ? `${theme.brandNeutralLight}80` : 'inherit'),
			type: 'string',
			path: ['certifications', 'employees', 'new', 'weekly'],
			defaultValue: 0,
			customStyle: {
				fontSize: theme.fontSizeH5,
			},
		},
		{
			section: 'lastDays',
			title: safetyMessages.safetyTable.weeklyEquipment,
			name: 'weeklyEquipment',
			getColor: val => (val == 0 ? `${theme.brandNeutralLight}80` : 'inherit'),
			type: 'string',
			path: ['certifications', 'equipment', 'new', 'weekly'],
			defaultValue: 0,
			customStyle: {
				fontSize: theme.fontSizeH5,
			},
		},
	],
};
