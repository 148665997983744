import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import theme from "../../assets/css/theme";
import { onError } from '../../../common/app/funcs';
import { startLoading, hideLoading } from '../../../common/app/actions';
//import PDFViewer from 'pdf-viewer-reactjs';
//import PDFViewer from 'pdf-viewer-reactjs';

import { Document, Page, pdfjs  } from 'react-pdf';
import { connect } from "react-redux";
import systemMessages from "../../../common/app/systemMessages";
import ImagePanZoom from './ImagePanZoom';
//import { Document, Page } from 'react-pdf/build/entry.noworker';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
console.log('pdfjs.version', pdfjs.version);

class ZoomImage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      fileSrc: '',
    };
  }

  componentWillMount() {
    this.setComponentData({}, this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData = (props, nextProps) => {
    if (props.src !== nextProps.src || props.pdfMode !== nextProps.pdfMode)
      this.getFileSrc(nextProps).then(fileSrc => this.setState({ fileSrc }));
  }

  getFileSrc = async (props) => {
    const { src, pdfMode, startLoading, hideLoading } = props || this.props;

    let fileSrc = src;

    
    if (pdfMode) { // Firebase sends the file with content-type application/octet-stream so we need to try convert it to application/pdf
      const loadingId = 'loading-image';

      startLoading({ title: systemMessages.loadingMessage, operationId: loadingId });

      try {
        let blob = await fetch(src).then(r => r.blob());

        if (blob.type !== 'application/pdf') {
          blob = blob.slice(0, blob.size, 'application/pdf');
          fileSrc = URL.createObjectURL(blob);
        }
      }
      catch (error) {
        onError({
          errorMessage: 'Failed to download file to display',
          error,
          methodMetaData: {
            name: 'ImageCarousel.getFileSrc',
            args: { src, pdfMode },
          },
          errorMetaData: { src, pdfMode },
        });
      }

      hideLoading(loadingId);
    }

    return fileSrc;
  }

  render() {
    const { maxWidth, maxHeight, pdfMode, toolbar = false } = this.props;
    const { fileSrc } = this.state;
    const style = {
      height: maxHeight,
      width: maxWidth,
      overflow: pdfMode? "hidden" : 'visible',
    };

    return (
      <div
        style={{
          overflow: pdfMode ? 'auto' : 'hidden',
          overflowY: pdfMode ? 'auto' : 'hidden',
          height: maxHeight,
        }}
      >
        {pdfMode ?
          <embed
            style={style}
            type="application/pdf"
            src={`${fileSrc}#toolbar=${Number(toolbar)}`}
          />
          :
          <ImagePanZoom style={style} src={fileSrc} />
        }
      </div>
    );
  }
}


let styles = {
  pic: {
//    transition: 'transform .5s ease-out'
  }
}

ZoomImage = connect(state => ({}), { startLoading, hideLoading })(ZoomImage);
export default withStyles(styles)(ZoomImage);