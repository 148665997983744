import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose } from "recompose";
import { connectContext } from "react-connect-context";
import _ from "lodash";
import { ProjectContext } from "../../../common/projects/contexts";
import { uploadPropertiesInstances } from "../../../common/propertiesInstances/actions";
import { saveGroupsMappings } from "../../../common/propertiesMappings/actions";
import { lokiInstance } from "../../../common/configureMiddleware";
import withStyles from "@material-ui/core/styles/withStyles";
import MultiCheckSelect from "../../components/CementoComponents/MultiCheckSelect";
import InputField from "../../components/CementoComponents/InputField";
import theme from "../../assets/css/theme";
import loginPageStyle from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import { getGroupsInstances, getObjectsTitlesMap } from "./funcs";
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';
import { SYSTEM_SUBJECTS_TYPES } from "../../../common/propertiesTypes/propertiesTypes";
import { safeToJS } from '../../../common/permissions/funcs';

const SEPARATOR = '/-/';
const concatText = (...strings) => strings.join(SEPARATOR);
const splitText = (text) => text.split(SEPARATOR);


class PropertiesGroupsManager extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.saveAllChanges = this.saveAllChanges.bind(this);
    this.onLocationGroupsChange = this.handleObjectGroupChange.bind(this);
    this.handleGroupSelect = this.handleGroupSelect.bind(this);
    this.lokiPropertyInstancesListener = this.lokiPropertyInstancesListener.bind(this);
    this.state = {
      subjectName: "locationsInfo",
      mappingsChanged: false,
      isObjectsMappingChanged: false,
      groupsInstancesMap: {},
      objectsMap: {},
      allGroupsArray: [],
      selectedGroupId: null,
      selectedGroupingPropertyId: 'groups',
      selectedScope: 'project'
    }
  }

  lokiPropertyInstancesListener(collectionName) {
    const { objectsMap, allGroupsArray, subjectName, selectedGroupingPropertyId, isObjectsMappingChanged } = this.state;
    const { selectedProjectId } = this.props;
    if (collectionName == "propertyInstances") {
      let newStateChanges = {};
      let lokiPropertyInstances = this.lokiPropertyInstances.cementoFind({
        projectId: selectedProjectId,
        subjectName: subjectName,
        propId: selectedGroupingPropertyId,
      });

      let newInstancesByUniqueKey = Object.assign({}, isObjectsMappingChanged && this.state.instancesByUniqueKey);
      let newCheckedObjectsByUniqueKey = Object.assign({}, isObjectsMappingChanged && this.state.checkedObjectsByUniqueKey);
      lokiPropertyInstances.forEach(instance => {
        const { id, parentId, data, propId, isDeleted } = instance;
        
        const uniqueKey = concatText(subjectName, propId, parentId);
        newInstancesByUniqueKey[uniqueKey] = Object.assign({}, newInstancesByUniqueKey[uniqueKey], { [id]: instance });

        const dataId = _.first(_.values(data));
        if (dataId) {
          const checkedUniqueKey = concatText(subjectName, propId, dataId, parentId);
          newCheckedObjectsByUniqueKey[checkedUniqueKey] = (
            isObjectsMappingChanged && !_.isNil(newCheckedObjectsByUniqueKey[checkedUniqueKey]) 
              ? newCheckedObjectsByUniqueKey[checkedUniqueKey]
              : !isDeleted
          );
        }
      });

      if (!_.isEqual(newInstancesByUniqueKey, this.state.instancesByUniqueKey)) {
        newStateChanges.instancesByUniqueKey = newInstancesByUniqueKey;
        if (isObjectsMappingChanged) {
          this.handleAlertSomeoneElseIsEditing(Date.now());
        }
      }

      if (!_.isEqual(newCheckedObjectsByUniqueKey, this.state.checkedObjectsByUniqueKey)) {
        newStateChanges.checkedObjectsByUniqueKey = newCheckedObjectsByUniqueKey;
      }

      newStateChanges.groupsInstancesMap = getGroupsInstances(
        selectedProjectId,
        objectsMap,
        allGroupsArray,
        lokiPropertyInstances
      );
      this.setState(newStateChanges);
    }
  }

  componentWillUnmount() {
    this.lokiPropertyInstances.cementoOff("locationInfoPageInstancesListener");
  }

  UNSAFE_componentWillMount() {
    this.lokiPropertyInstances =
      lokiInstance.getCollection("propertyInstances");
    this.lokiPropertyInstances.cementoOn(
      "locationInfoPageInstancesListener",
      this.lokiPropertyInstancesListener
    );
    this.setComponentData({}, this.props, {}, this.state);
  }

  componentDidMount() {
    if (this.props.getRef) this.props.getRef(this);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (
      this.state.subjectName != nextState.subjectName ||
      this.state.selectedGroupingPropertyId !==
        nextState.selectedGroupingPropertyId
    )
      this.setComponentData(this.props, nextProps, this.state, nextState);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.selectedProjectId !== this.props.selectedProjectId ||
      [
        ['subjectName'],
        ['selectedGroupingPropertyId'],
        ['objectsMap'],
        ['allGroupsArray'],
      ].some(path => prevState.isValDiff(this.state, path))
    ) {
      this.lokiPropertyInstancesListener("propertyInstances");
    }
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps, this.state, this.state);
  }

  handleGroupSelect(groupId) {
    this.setState({ selectedGroupId: groupId });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { subjectName, selectedGroupId, selectedGroupingPropertyId, objectsMap } = this.state;
    const shouldComponentUpdate = (
      subjectName !== nextState.subjectName ||
      selectedGroupId !== nextState.selectedGroupId ||
      selectedGroupingPropertyId !== nextState.selectedGroupingPropertyId ||
      objectsMap != nextState.objectsMap ||
      this.state.isValDiff(nextState, ['groupsInstancesMap']) ||
      this.state.isValDiff(nextState, ['allGroupsArray']) ||
      this.state.isValDiff(nextState, ['checkedPropertiesByMapping']) ||
      this.state.isValDiff(nextState, ['checkedObjectsByUniqueKey'])
    )

    return shouldComponentUpdate;
  }
  handleAlertSomeoneElseIsEditing(updateTimestamp = this.props.propertiesMappingLastUpdated) {
    window.alert(`Careful!\n\nLast modifications: ${(new Date(updateTimestamp)).toUTCString()}\n\nSomeone may be editing this mapping at the moment. I would recommend you check around for someone modifying it and reloading the page if possible. Otherwise you may continue if you are sure of your changes but I do recommend you open a new tab and check for changes.`);
  }

  setComponentData(props, nextProps, state, nextState) {
    let newStateChanges = {};

    const nextSubjectName = nextState.subjectName || state.subjectName;

    if (!_.isEqual(props.selectedProjectId, nextProps.selectedProjectId))
      newStateChanges.selectedScopeId = nextProps.selectedProjectId;

    // Get locations titles
    if (
      state.subjectName != nextSubjectName ||
      props.objects != nextProps.objects ||
      props.buildings != nextProps.buildings ||
      props.floors != nextProps.floors ||
      props.units != nextProps.units
    )
      newStateChanges.objectsMap = getObjectsTitlesMap(
        nextProps,
        nextSubjectName
      );

    // Get all groups and selectedGroupId
    if (state.subjectName != nextSubjectName || state.selectedGroupingPropertyId !== nextState.selectedGroupingPropertyId ||
        !_.isEqual(props.getNested(['propertiesTypes', nextSubjectName, nextState.selectedGroupingPropertyId]), nextProps.getNested(['propertiesTypes', nextSubjectName, nextState.selectedGroupingPropertyId]))) {
      newStateChanges.allGroupsArray = nextProps.getNested(['propertiesTypes', nextSubjectName, nextState.selectedGroupingPropertyId, 'values'])
      newStateChanges.allGroupsArray = newStateChanges.allGroupsArray ? newStateChanges.allGroupsArray.map(g => ({ id: g.id,  label: g.getCementoTitle(), title: g.getCementoTitle(),})) : null;
      newStateChanges.selectedGroupId = newStateChanges.allGroupsArray && newStateChanges.allGroupsArray.length ? newStateChanges.allGroupsArray[0].id : null;
    }

    if (
      [
        ['subjectName'],
      ].some(statePath => state.isValDiff(nextState, statePath)) ||
      [
        ['selectedProjectId'],
        ['propertiesMappings', nextSubjectName],
        ['propertiesTypes', nextSubjectName],
        ['propertiesSections', nextSubjectName],
      ].some(propPath => props.isValDiff(nextProps, propPath))
    ) {
      let newCheckedPropertiesByMapping = _.cloneDeep(nextState.checkedPropertiesByMapping) || {};
      _.entries(safeToJS(nextProps.propertiesMappings)?.[nextSubjectName]).forEach(([groupingPropId, mappingBySelection]) => {
        _.entries(mappingBySelection).forEach(([propSelectionId, mapping]) => {
          if (!mapping.properties?.length) return;
          mapping.properties.forEach(propId => {
            _.set(
              newCheckedPropertiesByMapping, 
              [nextSubjectName, groupingPropId, propSelectionId, propId],
              (nextState.propertiesMappingDidChange &&
              nextState.checkedPropertiesByMapping?.[nextSubjectName]?.[groupingPropId]?.[propSelectionId]?.[propId] === false)
                ? false 
                : true,
            );
          })
        })
      });

      if (!_.isEqual(newCheckedPropertiesByMapping, nextState.checkedPropertiesByMapping)) {
        newStateChanges.checkedPropertiesByMapping = newCheckedPropertiesByMapping;
      }
    }

    if (nextState.propertiesMappingDidChange && props.propertiesMappingLastUpdated !== nextProps.propertiesMappingLastUpdated) {
      this.handleAlertSomeoneElseIsEditing(nextProps.propertiesMappingLastUpdated);
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  async saveAllChanges() {
    const { uploadPropertiesInstances, saveGroupsMappings, selectedProjectId } = this.props;
    const { isObjectsMappingChanged, subjectName, propertiesMappingDidChange, checkedPropertiesByMapping, modifiedMappingsByKey, checkedObjectsByUniqueKey, instancesByUniqueKey } = this.state;

    let instancesToSaveBySubjectName = {};
    Object.entries(checkedObjectsByUniqueKey).forEach(([uniqueKey, isActive]) => {
      const [_subjectName, propId, dataId, parentId] = splitText(uniqueKey);

      const originalInstance = _.values(instancesByUniqueKey[concatText(subjectName, propId, parentId)]).find(i => !i.isDeleted) || {};
      let instance = Object.assign({}, originalInstance);

      if (isActive && _.isEmpty(instance)) {
        instance = {
          propId, 
          parentId,
          data: { [dataId]: dataId },
          propType: propertyTypes.SELECTION_LIST,
        };
      }

      if (isActive) {
        if (instance.isDeleted)
          instance.isDeleted = false;

        if (instance.data?.[dataId] !== dataId)
          instance.data = { [dataId]: dataId };
      }
      else if (instance) {
        if (!instance.isDeleted)
          instance.isDeleted = true;
      }

      if (!_.isEqual(instance, originalInstance)) {
        if (!instancesToSaveBySubjectName[_subjectName])
          instancesToSaveBySubjectName[_subjectName] = [];

        instancesToSaveBySubjectName[_subjectName].push(instance);
      }
    });


    if (isObjectsMappingChanged) {
      for (const [_subjectName, instances] of Object.entries(instancesToSaveBySubjectName)) {
        await uploadPropertiesInstances(selectedProjectId, instances, _subjectName);
      }
    }
    
    if (propertiesMappingDidChange) {
      for (const [_subjectName, grouping] of _.entries(checkedPropertiesByMapping)) {
        for (const [groupingPropId, propSelection] of _.entries(grouping)) {
          if (!modifiedMappingsByKey[concatText(_subjectName, groupingPropId)]) continue;

          let newMapping = {};
          for (const [propSelectionId, mapping] of _.entries(propSelection)) {
            _.set(
              newMapping, 
              propSelectionId, 
              { 
                properties: _.entries(mapping).reduce(
                  (acc, [propId, isActive]) => isActive ? [...acc, propId] : acc
                , []).sort((a, b) => a.localeCompare(b)),
              },
            );
          }

          await saveGroupsMappings(
            selectedProjectId,
            _subjectName,
            groupingPropId,
            newMapping,
          );
        }
      }
    }

    this.setState({ isObjectsMappingChanged: false, propertiesMappingDidChange: false, modifiedMappingsByKey: {} });
  }

  handleObjectGroupChange = (newItems) => {
    const { subjectName, selectedGroupingPropertyId, selectedGroupId, checkedObjectsByUniqueKey } = this.state;

    let newCheckedObjectsByUniqueKey = Object.assign({}, checkedObjectsByUniqueKey);
    _.values(newItems).forEach(object => {
      _.set(
        newCheckedObjectsByUniqueKey, 
        concatText(subjectName, selectedGroupingPropertyId, selectedGroupId, object.id),
        object.checked,
      );
    });

    this.setState({ checkedObjectsByUniqueKey: newCheckedObjectsByUniqueKey, isObjectsMappingChanged: true });
  }

  getMappingPropertiesForMultiCheck = () => {
    const { selectedGroupId, selectedGroupingPropertyId, subjectName, checkedPropertiesByMapping } = this.state;
    const { propertiesTypes } = this.props;

    const checkedMapping = _.get(
      checkedPropertiesByMapping,
      [subjectName, selectedGroupingPropertyId, selectedGroupId],
      {},
    )
    const subjectProperties = propertiesTypes[subjectName] || {};
    
    let props = {};

    _.values(subjectProperties).forEach(prop => {
      if (!prop.id) return;
      props[prop.id] = {
        ...prop,
        title: `${prop.getCementoTitle()} - (id: ${prop.id})`,
        checked: Boolean(checkedMapping[prop.id]),
      };
    });

    return props;
  }

  handleMappingSelect = (newPropsSelectionMap) => {
    const { checkedPropertiesByMapping, subjectName, selectedGroupingPropertyId, selectedGroupId, modifiedMappingsByKey } = this.state;

    let newGrouping = Object.assign({}, checkedPropertiesByMapping?.[subjectName]?.[selectedGroupingPropertyId]?.[selectedGroupId]);
    _.values(newPropsSelectionMap).forEach(prop => {
      if (newGrouping[prop.id] !== prop.checked) {
        newGrouping[prop.id] = prop.checked;
      }
    });

    let newCheckedPropertiesByMapping = _.cloneDeep(checkedPropertiesByMapping);
    _.set(newCheckedPropertiesByMapping, [subjectName, selectedGroupingPropertyId, selectedGroupId], newGrouping);
    const newModifiedMappingsByKey = Object.assign({}, modifiedMappingsByKey, { [concatText(subjectName, selectedGroupingPropertyId)]: true });
    this.setState({ checkedPropertiesByMapping: newCheckedPropertiesByMapping, propertiesMappingDidChange: true, modifiedMappingsByKey: newModifiedMappingsByKey });
  }

  render() {
    const { selectedProjectId, lang, propertiesTypes, propertiesSections, trades } = this.props;
    const { allGroupsArray, selectedGroupId, groupsInstancesMap, allGroupsMapping, subjectName, selectedGroupingPropertyId, selectedScope, selectedScopeId, objectsMap, checkedObjectsByUniqueKey } = this.state;
    let selectPropertyValues = _.reduce(_.get(propertiesTypes, [subjectName], {}), (acc, prop, propId) => {
      if (prop.type === propertyTypes.SELECTION_LIST)
        acc.push({ id: prop.id, title: _.get(propertiesSections, [subjectName, prop.sectionId], NO_SECTION).getCementoTitle() + " - " + prop.getCementoTitle() });
      return acc;
    }, []);
    const subjectType = subjectName.split('sInfo')[0];
    const objects = _.mapValues(objectsMap, object => {
      let title = typeof object.title === 'string' ? object.title : object.getCementoTitle();
      if (!title) {
        title = _.capitalize(subjectType);
      }

      title += ` (id: ${object.id})`;

      return Object.assign(
        {}, 
        object, 
        { 
          title, 
          checked: Boolean(checkedObjectsByUniqueKey?.[
            concatText(subjectName, selectedGroupingPropertyId, selectedGroupId, object.id)
          ]),
        }
      );
    });

    return (
      <div style={{ display:'flex', flex:1, flexDirection:'column', width:'100%', height:'100%'}}>
        <InputField
          nameSizeRatio={2/12}
          key={'subjectName'} 
          style={styles.inputStyle} 
          name={'SubjectName'}
          type={propertyTypes.SELECTION_LIST}
          allowBlank={false}
          values={SYSTEM_SUBJECTS_TYPES.map(t => ({id: t, title: t}) )}
          defaultValue={{[subjectName]: subjectName}}
          onChange={val => this.setState({ subjectName: Object.keys(val)[0]} )} />
        <InputField
          nameSizeRatio={2/12}
          key={'propertyType'} 
          style={styles.inputStyle} 
          name={'Select a property'}
          type={propertyTypes.SELECTION_LIST}
          allowBlank={false}
          values={selectPropertyValues}
          defaultValue={{[selectedGroupingPropertyId]: selectedGroupingPropertyId}}
          onChange={val => this.setState({ selectedGroupingPropertyId: Object.keys(val)[0]} )} />
        <InputField 
          nameSizeRatio={2/12} 
          type={propertyTypes.SELECTION_LIST} 
          key={selectedProjectId+'groupsPropSelection'} 
          style={styles.inputStyle} 
          name={'Select a group:'} 
          allowBlank={false}
          value={{ [selectedGroupId]: selectedGroupId }}
          onChange={(val) => this.handleGroupSelect(Object.keys(val)[0])}
          values={allGroupsArray}
        />
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            height: "inherit",
          }}
        >
          <div style={{ flex: 1 }}>
            <MultiCheckSelect 
              style={{ padding: theme.paddingSize }}
              items={this.getMappingPropertiesForMultiCheck()}
              titlePropPath={["getTitle"]}
              onChange={this.handleMappingSelect}
            />
          </div>
          <div style={{ flex: 1, height: "inherit" }}>
            <MultiCheckSelect
              style={{ padding: theme.paddingSize }}
              items={objects}
              titlePropPath={["title"]}
              onChange={this.handleObjectGroupChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  textCenter: {
    textAlign: 'center', alignItems: 'center', alignContent: 'center', justify: 'center',
  },
  buttonStyle: {
    borderRadius: theme.borderRadius -1 , display:'flex', minWidth: theme.minWidth, height: theme.rowHeight / 2, alignSelf:'center', justifyContent:'center', alignItems:'center', margin: theme.margin - 10, border: '1px solid ' + theme.brandPrimary + '85', color: theme.brandPrimary, cursor: 'pointer'
  },
  inputStyle: {
    padding:'15px 15px 0px 15px', flex:'None'
  }
};

PropertiesGroupsManager = withStyles(
  theme.combineStyles(loginPageStyle, styles)
)(PropertiesGroupsManager);
PropertiesGroupsManager = injectIntl(PropertiesGroupsManager);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect((state) => ({
    propertiesMappingLastUpdated: state.propertiesMappings.lastUpdated
  }), { uploadPropertiesInstances, saveGroupsMappings })
);
export default enhance(PropertiesGroupsManager);

let NO_SECTION = {
  getTitle: () => {
    return "NO_SECTION";
  },
};
