import { Record } from '../transit';

const RCLReportSummary = Record({
  id: null,
  dateTS: null,
  updatedTS: null,
  type: null,
  totalItems: 1,
  confirmedItems: 0,
  rejectedItems: 0,
}, 'rclReportSummary');

export default RCLReportSummary;