import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { connectContext } from "react-connect-context";
import ImageCarousel from "../../components/CementoComponents/ImageCarousel";
import { ProjectContext } from "../../../common/projects/contexts";
import {
  BUILDING,
  FLOOR,
  UNIT,
} from "../../../common/drawings/actions";
import NoItemsFound from "../../components/CementoComponents/NoItemsFound";
import Text from "../../components/CementoComponents/Text";
import Drawing from "../Drawings/Drawing";
import theme from "../../assets/css/theme";
import { injectIntl } from "react-intl";
import view_blocks_active from "../../assets/img/tasks/view_blocks_active.png";
import view_lines_active from "../../assets/img/tasks/view_lines_active.png";
import view_lines from "../../assets/img/tasks/view_lines.png";
import view_blocks from "../../assets/img/tasks/view_blocks.png";
import CollapsibleSection_DEPRECATED from "../../components/CementoComponents/CollapsibleSection_DEPRECATED";
import collapse from "../../assets/img/icons/collapse.png";
import Sorts from "../Menus/Sorts";
import drawingsMessages from "../../../common/drawings/drawingsMessages.js";
import moment from "moment";
import systemMessages from "../../../common/app/systemMessages";
import TextFilter from "../Posts/TextFilter";
import DrawingCardAdapter from './DrawingCardAdapter';
import plus_primary from '../../assets/img/icons/plus_primary.png';
import DrawingsSubscriber from './DrawingsSubscriber';
import * as permissionsFunc from '../../../common/permissions/funcs';
import FeatureNotActivePage from "../../layouts/FeatureNotActivePage";

const maxExpandableDrawings = 10;
const BLOCKS = "blocks";
const LINES = "lines";

class DrawingsPage extends React.Component {
  constructor(props) {
    super(props);

    this.splitData = this.splitData.bind(this);
    this.SummariesRefList = {};
    this.recalcHeader = this.recalcHeader.bind(this);
    this.handleAddDrawingClick = this.handleAddDrawingClick.bind(this);
    this.onImageCarouselClose = this.onImageCarouselClose.bind(this);
    this.generateDrawingsComponent = this.generateDrawingsComponent.bind(this);
    this.closeSelectedDrawing = this.closeSelectedDrawing.bind(this);
    this.drawingSelectHandler = this.drawingSelectHandler.bind(this);
    this.state = {
      viewMode: BLOCKS,
      expandSections: {},
      sortDirection: 1,
      filterVal: "",
      isActive: false
    };
  }

  UNSAFE_componentWillMount() {
    this.recalcHeader();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      drawings,
      drawingsSettings,
      locationId,
      defaultData,
      selectedProjectId,
      buildings,
    } = nextProps;

    let projectId = selectedProjectId;
    if (
      this.props.locationId != locationId ||
      this.props.defaultData != defaultData ||
      this.props.drawings != drawings ||
      this.props.drawingsSettings != drawingsSettings ||
      this.props.getNested(["drawingsDidLoad", projectId]) !=
        nextProps.getNested(["drawingsDidLoad", projectId]) ||
      this.props.urlParams.getNested(["queryParams", "sort"]) !=
        nextProps.urlParams.getNested(["queryParams", "sort"])
    ) {
      const allDrawingWithLocations = this.generateDrawingsMap(nextProps);

      const drawingLocationsByUri = {};
      Object.values(allDrawingWithLocations).forEach((drawing) => {
        const key = drawing.uri ? drawing.uri : drawing.uriPdf || drawing.id;
        drawingLocationsByUri[key] = drawing.locations;
      });

      let unitDrawings;
      if (
        locationId === "all" ||
        (buildings.size === 1 &&
          buildings.get(selectedProjectId).keySeq().last() === locationId)
      ) {
        unitDrawings = allDrawingWithLocations;
      } else {
        unitDrawings = this.fetchCategoryNames(
          drawings,
          drawingsSettings,
          false,
          nextProps
        );
        for (let key in unitDrawings) {
          const drawing = unitDrawings[key];
          const uri = drawing.uri ? drawing.uri : drawing.uriPdf || drawing.id;
          drawing.locations = drawingLocationsByUri[uri];
        }
      }

      const allData = this.splitData(unitDrawings, defaultData, nextProps);

      let expandSections = {};
      allData.forEach((section) => (expandSections[section.id] = false));

      Boolean(allData.length === 1) && (expandSections[allData[0].id] = true);

      this.setState({ allData, expandSections, unitDrawings }, () =>
        this.recalcHeader(nextProps)
      );
    }

    if (this.state.isActive !== nextProps.getNested(["configurations", "features", "drawings", "isActive"], false))
      this.setState({isActive : nextProps.getNested(["configurations", "features", "drawings", "isActive"], false)})
  }

  componentDidUpdate(prevProps, prevState) {
    const { unitDrawings } = this.state;
    const { defaultData } = this.props;
    if (prevState.filterVal !== this.state.filterVal) {
      const filteredData = this.getFilterData(unitDrawings);
      const allData = this.splitData(filteredData, defaultData, this.props);
      const expandSections = {};
      allData.forEach((section) => {
        expandSections[section.id] =
          filteredData.length < maxExpandableDrawings;
      });
      this.setState({ allData, expandSections });
    }
  }

  getFilterData = (unitDrawings = this.setState.unitDrawings) => {
    if (!unitDrawings) return;
    const { trades, intl } = this.props;
    const { filterVal } = this.state;
    return Object.values(unitDrawings).filter(
      (drawing) =>
        drawing.title.includes(filterVal) ||
        trades.getNested([drawing.category, "getTitle"]).includes(filterVal) ||
        intl
          .formatMessage(drawingsMessages.subtitles[drawing.type])
          .includes(filterVal)
    );
  };

  onSortChangeDirection = () =>
    this.setState(
      { sortDirection: this.state.sortDirection * -1 },
      this.recalcHeader
    );

  setFilterVal = (filterVal) => this.setState({ filterVal }, this.recalcHeader);
  clearFilterVal = () => this.setFilterVal("");

  handleAddDrawingClick = () => {
    this.drawingSelectHandler({}, location);
  };

  recalcHeader(nextProps) {
    const { viewMode, sortDirection, filterVal } = this.state;
    const {
      setHeaderParams, locationTitle, rtl,
      viewer, selectedProjectId,
    } = nextProps || this.props;

    const headerComponent = (
      <div
        style={{
          color: theme.headerColorDark,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0 32px",
          fontFamily: "Assistant - Semi Bold",
          fontSize: 16,
          fontWeight: 700,
          width: " 100%",
        }}
      >
        <Text>
          <span
            onClick={this.toggleExpandAll}
            style={{
              [rtl ? "paddingLeft" : "paddingRight"]: 15,
              cursor: "pointer",
            }}
          >
            <img
              src={collapse}
              style={{
                transform: this.isAnyExpanded()
                  ? "rotate(90deg)"
                  : "rotate(180deg)",
                height: 10,
                transition: "0.2s",
              }}
            />
          </span>
          {locationTitle.join(rtl ? " / " : "  ")}
        </Text>
        <div
          style={{
            height: 50,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            fontSize: 14,
          }}
        >
          <TextFilter
            value={filterVal}
            onChange={this.setFilterVal}
            clearFilterVal={this.clearFilterVal}
            containerStyle={{ maxHeight: 35 }}
          />
          <Sorts
            style={{ margin: 0 }}
            direction={sortDirection}
            onSortChangeDirection={this.onSortChangeDirection}
            sortMenu={[
              { title: drawingsMessages.sort["date"], key: "date" },
              { title: drawingsMessages.sort["trade"], key: "category" },
              { title: drawingsMessages.sort["type"], key: "type" },
            ]}
            defaultSort="category"
          />
          {permissionsFunc.getActionPermissions(viewer, selectedProjectId, 'drawings', 'create') &&
            <div
              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
              onClick={() => this.drawingSelectHandler({})}
            >
              <div>
                <img src={plus_primary} />
              </div>
              <Text
                style={{ margin: theme.verticalMargin, fontWeight: theme.strongBold }}
                values={{contentType: drawingsMessages.objectsNames.drawing}}
              >
                {systemMessages.addObject}
              </Text>
            </div>
          }
          <img
            src={viewMode == BLOCKS ? view_blocks_active : view_blocks}
            style={{
              alignSelf: "center",
              cursor: "pointer",
              [rtl ? "paddingRight" : "paddingLeft"]: theme.margin,
            }}
            onClick={this.blocksMode}
          />
          <img
            src={viewMode == LINES ? view_lines_active : view_lines}
            style={{
              alignSelf: "center",
              cursor: "pointer",
              [rtl ? "paddingRight" : "paddingLeft"]: theme.margin,
            }}
            onClick={this.linesMode}
          />
        </div>
      </div>
    );

    if (setHeaderParams)
      setHeaderParams({ headerComponent, sideBarParams: { open: false } });
  }

  generateDrawingsMap(nextProps) {
    const { selectedProjectId, drawings } = nextProps || this.props;
    const allDrawing = {};
    if (
      drawings.size < 1 ||
      !selectedProjectId ||
      !drawings.get(selectedProjectId)
    ) {
      // TODO: try with drawingDidLoad
      return [];
    }
    drawings
      .get(selectedProjectId)
      .valueSeq()
      .forEach((building) => {
        building.keySeq().forEach((location) => {
          building
            .get(location)
            .valueSeq()
            .forEach((drawing) => {
              let key = drawing.uri
                ? drawing.uri
                : drawing.uriPdf || drawing.id;
              if (!allDrawing[key]) {
                allDrawing[key] = drawing.toJS();
                allDrawing[key].locations = [];
              }
              allDrawing[key].locations.push(location);
            });
        });
      });
    const output = {};
    Object.values(allDrawing).forEach((drawing) => {
      drawing.locations = this.generateLocationsNames(drawing, nextProps);
      output[drawing.id] = drawing;
    });
    return output;
  }

  generateLocationsNames(drawing, nextProps) {
    const { buildings, floors, units, selectedProjectId } =
      nextProps || this.props;
    let drawingLocations = [];
    let groupedFloorsByBuilding = {};

    let locationTypeMap;
    switch (drawing.type) {
      case BUILDING:
        locationTypeMap = buildings;
        break;
      case FLOOR:
        locationTypeMap = floors;
        break;
      case UNIT:
        locationTypeMap = units;
        break;
      default:
        return;
    }

    locationTypeMap.get(selectedProjectId).forEach((building, buildingId) => {
      drawing.locations.forEach((drawingLocat) => {
        let location = {
          id: drawingLocat,
          type: drawing.type,
          buildingId: buildings
            .getNested([selectedProjectId], {})
            .getNested([buildingId], {})
            .getNested(["id"], ""),
        };
        let locat = building.get(drawingLocat);
        if (locat) {
          if (drawing.type !== BUILDING) {
            // location[drawing.type] = locat.get("id") || locat.get("title") || locat.get("num");
            if (drawing.type === UNIT)
              location.floorNum = locat.get("floor")["num"];
            else location.floorNum = locat.get("num");
          }
        }
        drawingLocations.push(location);
      });
    });
    ///group all floors here
    if (drawing.type === FLOOR) {
      drawingLocations.forEach((floorLocation) => {
        if (!groupedFloorsByBuilding[floorLocation.buildingId]) {
          groupedFloorsByBuilding[floorLocation.buildingId] = {
            type: floorLocation.type,
            buildingId: floorLocation.buildingId,
            floorNum: [],
          };
        }
        if (floorLocation.floorNum)
          groupedFloorsByBuilding[floorLocation.buildingId].floorNum.push(
            floorLocation.floorNum
          );
      });
      groupedFloorsByBuilding = Object.values(groupedFloorsByBuilding);
      const groupSequentialArray = (arr) => {
        const output = [];
        if (!arr.length) return output;
        let first = 0;
        for (let index = 1; index < arr.length; index += 1) {
          if (arr[index] !== arr[index - 1] + 1) {
            output.push(arr.slice(first, index));
            first = index;
          }
        }
        output.push(arr.slice(first, arr.length));
        return output;
      };

      groupedFloorsByBuilding.map((locat) => {
        locat.floorNum = groupSequentialArray(
          locat.floorNum.sort((a, b) => a - b)
        ).map((group) =>
          group.length === 1 ? group[0] : [group[0], group[group.length - 1]]
        );
        return locat;
      });
    }
    if (drawing.type === UNIT) {
      drawingLocations = drawingLocations.sort(
        (a, b) => b.floorNum - a.floorNum
      );
    }
    return drawing.type === FLOOR ? groupedFloorsByBuilding : drawingLocations;
  }

  fetchCategoryNames(drawings, drawingsSettings, expandSections, nextProps) {
    // TODO: Remove this into drawingsList
    const props = nextProps || this.props;
    const { selectedProjectId, locationType } = props;
    let nextBuildingId = props.getNested(["match", "params", "buildingId"]);
    let nextFloorId = props.getNested(["match", "params", "floorId"]);
    let nextUnitId = props.getNested(["match", "params", "unitId"]);

    const { trades } = this.props;

    var updatedDrawings = null;
    var projectDrawings = null;
    var currDrawings = null;

    if (nextBuildingId && drawings && drawings.get(selectedProjectId)) {
      projectDrawings = drawings.get(selectedProjectId);
      currDrawings = {}; //new OrderedMap();
      if (
        locationType === UNIT &&
        nextUnitId &&
        projectDrawings.get(nextBuildingId) &&
        projectDrawings.get(nextBuildingId).get(nextUnitId)
      ) {
        currDrawings = projectDrawings
          .get(nextBuildingId)
          .get(nextUnitId)
          .toJS();
        Object.values(currDrawings).forEach((curr) => {
          curr.type = UNIT;
        });
      }

      var separateFloorOnUnit = null;
      if (
        drawingsSettings &&
        drawingsSettings.get(selectedProjectId) &&
        drawingsSettings.get(selectedProjectId).get("all")
      )
        separateFloorOnUnit = Boolean(drawingsSettings.get(selectedProjectId).get("all").separateFloorOnUnit);

      if (
        ((locationType === UNIT && !separateFloorOnUnit) ||
          locationType === FLOOR) &&
        nextFloorId &&
        projectDrawings.get(nextBuildingId) &&
        projectDrawings.get(nextBuildingId).get(nextFloorId)
      ) {
        var floorDrawings = projectDrawings
          .get(nextBuildingId)
          .get(nextFloorId)
          .toJS();
        Object.values(floorDrawings).forEach((curr) => (curr.type = FLOOR));
        Object.assign(currDrawings, floorDrawings);
      }

      if (
        locationType == BUILDING &&
        projectDrawings.get(nextBuildingId) &&
        projectDrawings.get(nextBuildingId).get(nextBuildingId)
      ) {
        var buildingDrawings = projectDrawings
          .get(nextBuildingId)
          .get(nextBuildingId)
          .toJS();
        Object.values(buildingDrawings).forEach(
          (curr) => (curr.type = BUILDING)
        );
        Object.assign(currDrawings, buildingDrawings);
      }

      updatedDrawings = {};
      if (currDrawings)
        Object.values(currDrawings).map((drawing) => {
          let getTradeTitle = drawing.category
            ? trades.getNested([drawing.category, "getTitle"])
            : null;
          if (getTradeTitle) {
            drawing = Object.assign({}, drawing, {
              categoryTitle: getTradeTitle,
            });
            if (!drawing.title)
              drawing = Object.assign({}, drawing, { title: getTradeTitle });
          }

          updatedDrawings[drawing.id] = drawing;
        });
    }

    return updatedDrawings || {};
  }

  splitData = (drawings, defaultData, nextProps) => {
    const { intl, trades, urlParams } = nextProps || this.props;
    const sortBy = urlParams.getNested(["queryParams", "sort"]) || "category";
    const GENERAL_SECTION = "---";

    var ret = {};
    var retArray = defaultData ? defaultData.slice() : [];
    if (Object.values(drawings || {}).length) {
      var allDrawings = Object.values(drawings);
      allDrawings.loopEach((key, drawing) => {
        let currType;
        if (!drawing[sortBy]) currType = GENERAL_SECTION;
        else
          switch (sortBy) {
            case "category":
              currType =
                trades.getNested([drawing[sortBy], "getTitle"]) ||
                GENERAL_SECTION;
              break;

            case "date":
              currType = moment(drawing[sortBy]).format(
                intl.formatMessage(systemMessages.onlyDateFormat)
              );
              break;

            case "type":
              currType = drawingsMessages.subtitles[drawing[sortBy]]
                ? intl.formatMessage(
                    drawingsMessages.subtitles[drawing[sortBy]]
                  )
                : GENERAL_SECTION;
              break;

            default:
              currType = drawing[sortBy] || GENERAL_SECTION;
          }

        if (!ret[currType]) ret[currType] = { data: [], title: currType };

        let drawingsWithId = {
          ...drawing,
          title: drawing.title || "",
        };
        ret[currType].data.push(drawingsWithId);
      });
      ret.loopEach((key, value) => {
        let section = { id: key };
        section.title =
          value.title || intl.formatMessage(drawingsMessages.empty.general);
        section.size = Object.keys(value.data || {}).length;
        section.data = value.data
          .sort((a, b) => a.title.localeCompare(b.title))
          .sort((a, b) =>
            a.categoryTitle && b.categoryTitle
              ? a.categoryTitle.localeCompare(b.categoryTitle)
              : 1
          );

        retArray.push(section);
      });
    }
    return retArray;
  };

  onImageCarouselClose = () => this.setState({ selectedImages: null });

  linesMode = () => this.setState({ viewMode: LINES }, this.recalcHeader);
  blocksMode = () => this.setState({ viewMode: BLOCKS }, this.recalcHeader);

  toggleExpend = (id) => {
    const expandSections = { ...this.state.expandSections };
    expandSections[id] = expandSections[id] ? !expandSections[id] : true;
    this.setState({ expandSections }, this.recalcHeader);
  };

  isAnyExpanded = () =>
    this.state.expandSections.length < 1
      ? false
      : Object.values(this.state.expandSections).some((value) =>
          Boolean(value)
        );

  toggleExpandAll = () => {
    const expandSections = { ...this.state.expandSections };
    for (let key in expandSections) {
      expandSections[key] = !this.isAnyExpanded();
    }
    this.setState({ expandSections }, this.recalcHeader);
  };

  generateDrawingsComponent() {
    const {
      selectedImages,
      carouselModalPdfMode,
      viewMode,
      sortDirection,
      allData,
      unitDrawings,
    } = this.state;
    const { rtl, locationTitle, intl } = this.props;

    if (!allData?.length) {
      return (
        <NoItemsFound
          locationMode={true}
          treatEntireProjectAsNoLocation={true}
        />
      );
    }


    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          height: "inherit",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {allData
          .sort((a, b) => sortDirection * a.title.localeCompare(b.title))
          .map((currSection) => (
            <CollapsibleSection_DEPRECATED
              key={currSection.id}
              title={currSection.title}
              containerStyle={{
                boxShadow: "none",
                margin: 0,
                borderBottom: theme.borderLineHeaderInfo,
              }}
              titleStyle={{ paddingLeft: 36, paddingRight: 36, width: "100%" }}
              contentStyle={{
                flexWrap: "wrap",
                flexDirection: "unset",
                justifyContent: "start",
              }}
              titleBorderBottom={false}
              countBarValue={currSection.data.length || false}
              countBarTotalValue={Object.keys(unitDrawings).length || false}
              toggleExpend={() => this.toggleExpend(currSection.id)}
              expand={this.state.expandSections[currSection.id] || false}
            >
              {currSection.data.map((drawing, index, allData) => {
                return (
                  <Drawing
                    key={`${drawing.id}-${index}`}
                    locationTitle={locationTitle}
                    isSelected={
                      (this.state.selectedDrawing || {}).id === drawing.id
                    }
                    drawing={drawing}
                    onDrawingSelect={this.drawingSelectHandler}
                    onImageSelect={() => null}
                    rtl={rtl}
                    intl={intl}
                    viewMode={viewMode}
                    isLastChild={index + 1 === allData.length}
                  />
                );
              })}{" "}
            </CollapsibleSection_DEPRECATED>
          ))}

        {Boolean(selectedImages) && (
          <ImageCarousel
            pdfMode={carouselModalPdfMode}
            onClose={this.onImageCarouselClose}
            initialSlide={0}
            items={selectedImages}
          />
        )}
      </div>
    );
  }

  closeSelectedDrawing() {
    this.setState({
      selectedDrawing: null,
    });
  }

  drawingSelectHandler(drawing, forceUpdate) {
    const { selectedDrawing } = this.state;
    if (!drawing) {
      return null;
    }
    if (!drawing.id) {
      // preselect current location for a new drawing
      const buildingId = this.props.getNested(["match", "params", "buildingId"]);
      const floorId = this.props.getNested(["match", "params", "floorId"]);
      const unitId = this.props.getNested(["match", "params", "unitId"]);
      const locationId = [unitId, floorId, buildingId].find(id => id && id !== '_');
      drawing.specificLocations = {
        [locationId]: locationId
      };
    }

    if (forceUpdate || (selectedDrawing?.id !== drawing.id || !drawing.id)) {
      this.setState({ selectedDrawing: drawing });
    }
  }

  render() {
    const { selectedDrawing, isActive } = this.state;
    const { viewer } = this.props;

    if (!isActive && !(viewer.adminMode === 1)) {
        return (
          <FeatureNotActivePage title={drawingsMessages.notActive.title} content={drawingsMessages.notActive.content} />
        )
    }

    return (
      <>
        <DrawingsSubscriber />
        <DrawingCardAdapter
          drawingObject={selectedDrawing}
          mainComponent={this.generateDrawingsComponent()}
          closeSelectedDrawing={this.closeSelectedDrawing}
          selectDrawing={this.drawingSelectHandler}
        />
      </>
    );
  }
}

const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      drawings: state.drawings.map,
      drawingsDidLoad: state.drawings.didLoad,
      trades: state.trades.map,
      drawingsSettings: state.drawings.settings,
      units: state.units.map,
      floors: state.floors.map,
      buildings: state.buildings.map,
      urlParams: state.ui.urlParams,
    }),
    {}
  )
);

DrawingsPage = injectIntl(DrawingsPage);
export default enhance(DrawingsPage);
