import { removeProjectsStorage } from '../projects/actions';
import {getSnapshotData} from '../lib/utils/utils';


export const GET_CHECKLIST_ITEMS = 'GET_CHECKLIST_ITEMS';
export const GET_NEW_CHECKLIST_ITEM_ID = 'GET_NEW_CHECKLIST_ITEM_ID';
export const SAVE_CHECKLIST_ITEMS = 'SAVE_CHECKLIST_ITEMS';
export const CLEAN_CHECKLIST_ITEMS_CACHE_DATA = 'CLEAN_CHECKLIST_ITEMS_CACHE_DATA';
export const GET_LOCAL_CHECKLIST_ITEMS = 'GET_LOCAL_CHECKLIST_ITEMS';
export const UPDATE_LOCAL_CHECKLIST_ITEM = 'UPDATE_LOCAL_CHECKLIST_ITEM';
export const UPDATE_LOCAL_CHECKLIST_ITEMS = 'UPDATE_LOCAL_CHECKLIST_ITEMS';
export const DELETE_NEW_CHECKLIST_ITEM = 'DELETE_NEW_CHECKLIST_ITEM';

export function getLocalChecklistItems(projectId, checklistItems) {
  return {
    type: GET_LOCAL_CHECKLIST_ITEMS + "_SUCCESS",
    payload: { projectId, checklistItems }
  }
}

export function updateLocalChecklistItem(projectId, checklistItem) {
  return {
    type: UPDATE_LOCAL_CHECKLIST_ITEM,
    payload: { projectId, checklistItem }
  }
}

export function updateLocalChecklistItems(projectId, checklistItems) {
  return {
    type: UPDATE_LOCAL_CHECKLIST_ITEMS,
    payload: { projectId, checklistItems }
  }
}

export function deleteNewChecklistItem(projectId, checklistItem) {
  return {
    type: DELETE_NEW_CHECKLIST_ITEM,
    payload: { projectId, checklistItem }
  }
}

export function getChecklistItems(viewer, projectId, forceUpdate) { 
  return {
    type: GET_CHECKLIST_ITEMS,
    payload: getSnapshotData({ api: 'checklistItems', firebase: `checklists`, firebaseSuffix:`items` }, projectId)
	};
  }

export function getNewChecklistItemId(projectId) {
	return ({ firebaseDatabase }) => {
		try {
			let push = firebaseDatabase().ref('checklists/' + projectId + '/items').push();
			return {
				type: GET_NEW_CHECKLIST_ITEM_ID,
				payload: { id: push.key }
			};
		} catch (error) {
			throw error;
		}
	};
}

export function cleanChecklistItemsCachedData() {  
  return ({ dispatch, getState, realmInstance, platformActions }) => {
    const getPromise = async () => {
      let projectIdsArray = [];
      let projectStateToRemove = [
        ['checklistItems', 'map'],
        ['checklistItems', 'didLoad'],
        ['checklistItems', 'lastUpdated'],
      ];
  
      getState().getNested(['projects', 'map'], {}).loopEach((k, p) => projectIdsArray.push(p.id));
      await dispatch(await removeProjectsStorage(projectIdsArray, projectStateToRemove));
  
    };
    return {
      type: CLEAN_CHECKLIST_ITEMS_CACHE_DATA,
      payload: getPromise()
    };
  };
};