import PricingPage from "../views/Pages/PricingPage.js";
import LoginPage from "../views/Pages/LoginPage.js";
import NotSupported from "../views/Pages/NotSupported.js";
import RegisterPage from "../views/Pages/RegisterPage.js";

// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";
import MonetizationOn from "@material-ui/icons/MonetizationOn";

const pagesRoutes = [
  {
    path: "/pages/login-page",
    name: "Login Page",
    short: "Login",
    mini: "LP",
    icon: Fingerprint,
    component: LoginPage
  },
  {
    path: "/pages/browser-not-supported",
    name: "Login Page",
    short: "Login",
    mini: "LP",
    icon: Fingerprint,
    component: NotSupported
  },
  //{
  //  path: "/pages/register-page",
  //  name: "Register Page",
  //  short: "Register",
  //  mini: "RP",
  //  icon: PersonAdd,
  //  component: RegisterPage
  //},
  //{
  //  path: "/pages/pricing-page",
  //  name: "Pricing Page",
  //  short: "Pricing",
  //  mini: "PP",
  //  icon: MonetizationOn,
  //  component: PricingPage
  //},
  { redirect: true, path: "/pages", pathTo: "/pages/login-page", name: "Register Page" }
];

export default pagesRoutes;
