import { getSnapshotData } from '../lib/utils/utils';

export const CLEAN_CACHED_TITLES = 'CLEAN_CACHED_TITLES';
export const GET_TITLES = 'GET_TITLES';

export function getTitles(inLastUpdateTS) {
  const getPromise = async () => {
    const snapShot = await getSnapshotData({ api: 'titles' });
    return { ...(snapShot || {}), lastUpdateTS: inLastUpdateTS };
  };

  return {
    type: GET_TITLES,
    payload: getPromise()
  };
}

