import React from "react";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import { injectIntl } from "react-intl";
import withStyles from "@material-ui/core/styles/withStyles";
import { LibraryBooks, Delete, FileCopy } from "@material-ui/icons";
import MultiCheckboxSelect from "../../components/CementoComponents/MultiCheckboxSelect";
import * as periods from "../../../common/checklistItemsInstances/clItemInstancePeriods";
import checklistsMessages from "../../../common/checklistItems/checklistItemMessages";
import newProjectMessages from "../../../common/projects/newProjectMessages";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Card from "../../components/Card/Card.jsx";
import CardHeader from "../../components/Card/CardHeader.jsx";
import NavPills from "../../components/NavPills/NavPills.jsx";
import CardIcon from "../../components/Card/CardIcon.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import validationFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import InputField from "../../components/CementoComponents/InputField";
import MultiCheckSelect from "../../components/CementoComponents/MultiCheckSelect";
import theme from "../../assets/css/theme";
import MenuScrollbar from "../../components/CementoComponents/MenuScrollbar";
import _ from "lodash";

class ChecklistCard extends React.Component {
  constructor(props) {
    super(props);
    this.defaultSelectedRowData = [];
    this.getSortedTrades = this.getSortedTrades.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.state = {
      sortedTrades: [],
      sortedProjects: [],
      multipleSelect: [],
      locationsMap: {},
    };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    const { locations, selectedChecklist, trades, selectedChecklistItem } =
      props;
    let newStateChanges = {};

    if (trades != nextProps.trades && nextProps.trades.size > 0)
      newStateChanges.sortedTrades = this.getSortedTrades(nextProps.trades);

    let selectedLocationsRef = nextProps.getNested([
      "selectedChecklistItem",
      "onlyLocations",
    ])
      ? nextProps.getNested(["selectedChecklistItem", "onlyLocations"], {})
      : nextProps.getNested(["selectedChecklist", "locations"], {});

    if (
      locations != nextProps.locations ||
      selectedChecklist != nextProps.selectedChecklist ||
      selectedChecklistItem != nextProps.selectedChecklistItem
    ) {
      newStateChanges.locationsMap = { buildings: [], floors: [], units: [] };
      newStateChanges.locationsMap.loopEach((k, v) => {
        (nextProps.locations || {}).getNested([k], []).forEach((currLoc) => {
          if (
            !nextProps.itemMode ||
            (nextProps.itemMode &&
              nextProps.selectedChecklist.getNested(
                ["locations", k, currLoc.id],
                false
              ))
          )
            newStateChanges.locationsMap[k].push({
              id: currLoc.id,
              label: currLoc.title,
              checked: selectedLocationsRef.getNested([k, currLoc.id]),
            });
        });
      });
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  getSortedTrades(trades) {
    let sortedTrades = [];
    trades.loopEach((i, t) => {
      if (t.getNested(["getTitle"]) && t.getNested(["getTitle"]))
        sortedTrades.push(t);
    });
    return sortedTrades.sort((a, b) =>
      a.getNested(["getTitle"]).localeCompare(b.getNested(["getTitle"]))
    );
  }

  render() {
    const {
      classes,
      style,
      projectCompanies,
      extraData,
      selectedChecklistItem,
      selectedChecklist,
      sections,
      onChange,
      itemMode,
      intl,
      editMode,
      trades,
      lang,
    } = this.props;
    const { locationsMap, sortedTrades, disableDuplicatable } = this.state;
    let mode = null;
    let currChecklist = selectedChecklist;
    let currItem = selectedChecklistItem;
    if (itemMode && currItem) mode = "item";
    else if (!itemMode && currChecklist) mode = "checklist";
    let checklistsOptions = (sections || []).map((currSection) => ({
      id: currSection.parent.id,
      title: currSection.parent.description,
    }));
    let companiesSelection = [];
    (projectCompanies || {}).loopEach((id, comp) =>
      companiesSelection.push({ id, title: comp.name })
    );
    if (editMode)
      return (
        <div style={style}>
          <MenuScrollbar>
            <div style={{ padding: theme.paddingSize }}>
              {Boolean(mode == "item") && (
                <div
                  style={{ flex: 1, display: "flex", flexDirection: "column" }}
                >
                  <InputField
                    key={currItem.id + "_description"}
                    name={intl.formatMessage(checklistsMessages.description)}
                    type={"String"}
                    defaultValue={currItem.description}
                    onChange={(val) => {
                      if (onChange) onChange(["description"], val);
                    }}
                  />

                  <InputField
                    key={currItem.id + "_extraDescription"}
                    name={intl.formatMessage(
                      checklistsMessages.extraDescription
                    )}
                    type={"String"}
                    defaultValue={currItem.extraDescription}
                    onChange={(val) => {
                      if (onChange) onChange(["extraDescription"], val);
                    }}
                  />

                  <InputField
                    key={currItem.id + "_tradeselect"}
                    name={intl.formatMessage(checklistsMessages.trade)}
                    type={"SelectionList"}
                    values={sortedTrades
                      .map((trade) => ({
                        id: trade.id,
                        title: trade.getCementoTitle(false),
                      }))
                      .filter((trade) => !_.isEmpty(trade.title))}
                    defaultValue={{ [currItem.trade]: currItem.trade }}
                    onChange={(val) => {
                      if (onChange) onChange(["trade"], Object.keys(val)[0]);
                    }}
                  />

                  <InputField
                    key={currItem.id + "_periodsselect"}
                    name={intl.formatMessage(checklistsMessages.period)}
                    type={"SelectionList"}
                    values={[
                      { id: periods.CLI_PERIODS_DAILY, title: "Daily" },
                      { id: periods.CLI_PERIODS_WEEKLY, title: "Weekly" },
                      { id: periods.CLI_PERIODS_MONTHLY, title: "Monthly" },
                      { id: periods.CLI_PERIODS_QUARTERLY, title: "Quarterly" },
                      { id: periods.CLI_PERIODS_ON_DEMAND, title: "On Demand" },
                    ]}
                    defaultValue={{ [currItem.period]: currItem.period }}
                    onChange={(val) => {
                      if (onChange) onChange(["period"], Object.keys(val)[0]);
                    }}
                  />

                  <InputField
                    key={currItem.id + "_parentChecklist"}
                    name={intl.formatMessage(
                      checklistsMessages.connectedChecklist
                    )}
                    type={"SelectionList"}
                    values={checklistsOptions}
                    defaultValue={{ [currChecklist.id]: currChecklist.id }}
                    onChange={(val) => {
                      if (onChange) {
                        let id = Object.keys(val)[0];
                        let checklistIds = { [id]: { id: id } };
                        onChange(["checklistIds"], checklistIds);
                      }
                    }}
                  />

                  {["img", "desc", "file", "drawing"].map((currReq) => (
                    <InputField
                      key={currItem.id + currReq}
                      name={intl.formatMessage(
                        checklistsMessages.requirements[currReq]
                      )}
                      type={"Boolean"}
                      defaultValue={currItem.getNested(
                        ["requirements", currReq],
                        false
                      )}
                      onChange={(val) => {
                        if (onChange) onChange(["requirements", currReq], val);
                      }}
                    />
                  ))}

                  <InputField
                    key={currItem.id + "_isPartial"}
                    name={intl.formatMessage(checklistsMessages.isPartial)}
                    type={"Boolean"}
                    defaultValue={currItem.getNested(
                      ["permissions", "actions", "partial"],
                      false
                    )}
                    onChange={(val) => {
                      if (onChange)
                        onChange(["permissions", "actions", "partial"], val);
                    }}
                  />

                  <InputField
                    key={currItem.id + "_isIrrelevant"}
                    name={intl.formatMessage(checklistsMessages.isIrrelevant)}
                    type={"Boolean"}
                    defaultValue={currItem.getNested(
                      ["permissions", "actions", "irrelevant"],
                      false
                    )}
                    onChange={(val) => {
                      if (onChange)
                        onChange(["permissions", "actions", "irrelevant"], val);
                    }}
                  />

                  <InputField
                    key={currItem.id + "_weight"}
                    name={intl.formatMessage(checklistsMessages.weight)}
                    type={"Number"}
                    defaultValue={currItem.weight}
                    onChange={(val) => {
                      if (onChange && !isNaN(val) && Number(val))
                        onChange(["weight"], Number(val));
                    }}
                  />

                  <GridItem xs={12}>
                    <NavPills
                      color="warning"
                      tabs={["units", "floors", "buildings"].map((loc) => {
                        return {
                          tabButton: intl.formatMessage(
                            newProjectMessages.locationTypes[loc]
                          ),
                          tabContent: (
                            <MultiCheckSelect
                              height={500}
                              style={{ padding: theme.paddingSize }}
                              items={locationsMap[loc]}
                              titlePropPath={["label"]}
                              onChange={(newItems, allSelectedMap) => {
                                if (onChange) {
                                  let newLoc = {};
                                  allSelectedMap.loopEach(
                                    (id, selected) => (newLoc[id] = { id: id })
                                  );
                                  onChange(["onlyLocations", loc], newLoc);
                                }
                              }}
                            />
                          ),
                        };
                      })}
                    />
                  </GridItem>
                </div>
              )}
              {Boolean(mode == "checklist") && (
                <div
                  style={{ flex: 1, display: "flex", flexDirection: "column" }}
                >
                  <InputField
                    key={currChecklist.id + "_description"}
                    name={intl.formatMessage(checklistsMessages.description)}
                    type={"String"}
                    defaultValue={currChecklist.description}
                    onChange={(val) => {
                      if (onChange) onChange(["description"], val);
                    }}
                  />

                  <InputField
                    key={currChecklist.id + "_stage"}
                    name={intl.formatMessage(checklistsMessages.stage)}
                    type={"String"}
                    defaultValue={currChecklist.stage}
                    onChange={(val) => {
                      if (onChange) onChange(["stage"], val);
                    }}
                  />

                  <InputField
                    key={currChecklist.id + "_readCompanies"}
                    name={intl.formatMessage(
                      checklistsMessages.readPermissions
                    )}
                    type={"SelectionList"}
                    values={companiesSelection}
                    defaultValue={extraData.readCompanies}
                    onChange={(val) => {
                      if (onChange)
                        onChange(
                          ["permissions", "read", "companies"],
                          val,
                          true
                        );
                    }}
                  />

                  <InputField
                    key={currChecklist.id + "_isRoutine"}
                    name={"צ'קליסט מחזורי"}
                    type={"Boolean"}
                    defaultValue={
                      currChecklist.getNested(["type"]) == "routine"
                    }
                    onChange={(val) => {
                      if (onChange) {
                        this.setState({ disableDuplicatable: Boolean(val) });
                        onChange(["type"], val ? "routine" : null);
                        onChange(["period"], periods.CLI_PERIODS_DAILY, true);
                        if (currChecklist.getNested(["duplicatable"]))
                          onChange(["duplicatable"], false);
                      }
                    }}
                  />
                  {Boolean(!disableDuplicatable) && (
                    <>
                      <InputField
                        key={currChecklist.id + "_isDuplicatable"}
                        name={"ניתן לשכפול"}
                        type={"Boolean"}
                        defaultValue={currChecklist.getNested(
                          ["duplicatable"],
                          false
                        )}
                        onChange={(val) => {
                          if (onChange)
                            onChange(["duplicatable"], Boolean(val));
                        }}
                      />

                      <InputField
                        key={currChecklist.id + "_enableDistributionList"}
                        name={"אפשר שליחת לרשימת תפוצה אוטומטית"}
                        type={"Boolean"}
                        defaultValue={currChecklist.getNested(
                          ["enableDistributionList"],
                          false
                        )}
                        onChange={(val) => {
                          if (onChange)
                            onChange(["enableDistributionList"], Boolean(val));
                        }}
                      />
                    </>
                  )}
                  <GridItem xs={12}>
                    <NavPills
                      style={{ height: 100, width: 100 }}
                      color="warning"
                      tabs={["units", "floors", "buildings"].map((loc) => {
                        return {
                          tabButton: intl.formatMessage(
                            newProjectMessages.locationTypes[loc]
                          ),
                          tabContent: (
                            <MultiCheckSelect
                              height={500}
                              style={{ padding: theme.paddingSize }}
                              items={locationsMap[loc]}
                              titlePropPath={["label"]}
                              onChange={(newItems, allSelectedMap) => {
                                if (onChange) {
                                  let newLoc = {};
                                  allSelectedMap.loopEach(
                                    (id, selected) => (newLoc[id] = { id: id })
                                  );
                                  onChange(["locations", loc], newLoc);
                                }
                              }}
                            />
                          ),
                        };
                      })}
                    />
                  </GridItem>
                </div>
              )}
            </div>
          </MenuScrollbar>
        </div>
      );
    else
      return (
        <Card>
          <CardHeader color="warning" text>
            <CardIcon color="warning">
              <LibraryBooks color="wight" />
            </CardIcon>
          </CardHeader>
          <CardBody>
            {Boolean(mode == "child") && (
              <GridContainer>
                <GridItem xs={4}>
                  {intl.formatMessage(checklistsMessages.trade)}{" "}
                </GridItem>
                <GridItem xs={8}>
                  {trades.getNested([String(currItem.trade), "getTitle"])}
                </GridItem>
                <GridItem xs={4}>
                  {intl.formatMessage(checklistsMessages.description)}
                </GridItem>
                <GridItem xs={8}>{currItem.description}</GridItem>
              </GridContainer>
            )}
          </CardBody>
        </Card>
      );
  }
}

ChecklistCard = injectIntl(ChecklistCard);
ChecklistCard = withStyles(validationFormsStyle)(ChecklistCard);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(null, {})
);
export default enhance(ChecklistCard);
