import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { PostsSelectorComponent } from '../../../components/CementoComponents/StandardInput';
import postsMessages from '../../../../common/posts/postsMessages';
import useRefed from '../../../hooks/useRefed';

/** @param {PropertiesPostsSelectorProps} props */
const PropertiesPostsSelector = props => {
	const {
		postsType,
		isSafetyPostsOnly = false,
		isExpanded = false,
		isDisabled,
		openSideCard,
		onChange,
		selectedPosts,
	} = props;
	const [currViewPost, setCurrViewPost] = useState(null);
	const currViewPostRef = useRefed(currViewPost, [currViewPost]);

	const handleOpenSelectTasksSideCard = useCallback(() => {
		if (!openSideCard) return;

		const Component = () => <PropertiesPostsSelector {...props} isExpanded />;

		openSideCard(
			'_blankCard',
			{
				headerParams: { title: postsMessages.select[postsType] },
				objectName: postsType,
				componentId: `${postsType}-postsComponent-${isSafetyPostsOnly}`,
				noScrollBars: true,
			},
			Component,
		);
	}, Object.values(props));

	const handleChange = useCallback(
		newSelectedPosts => {
			if (onChange) onChange(newSelectedPosts);
		},
		[onChange],
	);

	const handlePostClick = useCallback(post => {
		if (isDisabled && _.get(post, 'id')) {
			if (openSideCard)
				openSideCard('post', { post, onUnmount: () => currViewPostRef.current && setCurrViewPost(null) });
			setCurrViewPost({ [post.id]: post.id });
		}
	}, [isDisabled, openSideCard]);

	const handleCreatPostClick = useCallback(() =>
		openSideCard?.('post', {
			post: { isIssue: postsType != 'records' ? true : null, mode: 'draft', isCloseOnSave: false },
			onPostSave: newPost => {
				handleChange(Object.assign({}, selectedPosts, { [newPost.id]: newPost.id }));
				handleOpenSelectTasksSideCard();
			},
			isSafety: isSafetyPostsOnly,
		}
	), [openSideCard, handleChange, selectedPosts, isSafetyPostsOnly, postsType]);

	return (
		<PostsSelectorComponent
			settings={{ postsType, isOnlySafety: isSafetyPostsOnly }}
			isExpanded={isExpanded}
			value={selectedPosts || {}}
			onChange={handleChange}
			isDisabled={isDisabled}
			currViewPost={currViewPost}
			onPostClick={handlePostClick}
			onSelectPostClick={handleOpenSelectTasksSideCard}
			onCreatePostClick={handleCreatPostClick
			}
		/>
	);
};

export default PropertiesPostsSelector;
