import React from "react";
import Avatar from "./Avatar";
import { CloseRounded } from "@material-ui/icons";
import theme from "../../assets/css/theme";

class Image extends React.PureComponent {
  constructor(props) {
    super(props);
    this.getRef = this.getRef.bind(this);
    this.setHover = this.setHover.bind(this);
    this.state = {
      hover: false
    };
  }

  setHover(event, hover) {
    const { onHover } = this.props; 
    let e = event.toElement || event.relatedTarget;
    if (e && e.parentElement && e.parentElement.id == 'removeButton')
      return;
    this.setState({ hover });
    if (onHover)
      onHover(hover);
  }

  getRef(n) {
    this.container = n ? n : this.container;
  }
   
  render() {
    const { src, containerStyle, imageStyle, imageContainerStyle, showButtonsOnHover, onRemove, onClick, shadow, regularImage, buttonStyle } = this.props;
    const { hover } = this.state;
    let container = {borderRadius:'0px'};
    if (containerStyle)
      container = Object.assign(container, containerStyle);
    return (
      <>
        {Boolean(showButtonsOnHover && hover) && 
        <div id="removeButton" style={{ position: 'absolute', display: 'flex', padding:5 }}>
          {Boolean(onRemove) && 
            <CloseRounded 
              onClick={(e) => { e.preventDefault(); if (onRemove) onRemove(); }}
              style={{ 
              margin:5, 
              fontSize:16, 
              borderRadius:'50%', 
              cursor:'pointer', 
              color:'white',
              backgroundColor:theme.brandNeutral+'80', 
              zIndex:10,
              ...(buttonStyle || {})
            }}/>}
        </div>
        }
        <div ref={this.getRef}
          onMouseOver={(e) => this.setHover(e,true)}
          onMouseOut={(e) => this.setHover(e,false)} 
          style={{...(imageContainerStyle || {width:'inherit', height:'inherit'}), cursor: onClick ? 'pointer': 'default' }}>
          {Boolean(regularImage) ? 
            <img 
              src={src}
              onClick={() => onClick ? onClick(src) : {}}
              shadow={shadow}
              style={imageStyle || {width: '100%', height: '100%', objectFit: 'contain'}}/>
            :
            <Avatar 
              src={src}
              type={'picture'}
              onClick={onClick ? onClick : undefined}
              shadow={shadow}
              avatarStyle={imageStyle}
              avatarContainerStyle={container}
            />
          }
        </div>
      </>) 
  }
}

export default Image;

