import React, { useMemo, useEffect, useCallback, useState } from 'react';
import getPlatformStandardInput from '../../app/platformComponents/PlatformStandardInput';
import * as propertyTypes from '../../propertiesTypes/propertiesTypes';
import { getTSArrayByTimeUnit } from '../../siteControl/funcs';
import { InputContext } from '../../functionalInputContext';
import moment from 'moment';
import _ from 'lodash';
import systemMessages from '../systemMessages';
import Select from 'react-select';
import theme from '../theme';

const MONTH = 'month';
const FORMAT = 'MMMM YYYY';
const DEFAULT_AMOUNT_OF_MONTHS_AGO = 3;

const MonthPicker = props => {
  const defaultMinTS = moment().subtract(DEFAULT_AMOUNT_OF_MONTHS_AGO, MONTH).valueOf();
  const defaultMaxTS = Date.now();
  const { minTS = defaultMinTS, maxTS = defaultMaxTS, onChange, value: _value, isOpen, shouldHideTitle, renderFunc } = props;
  const [value, setValue] = useState(_value);
  const PlatformStandardInput = useMemo(getPlatformStandardInput, []);

  const changeHandler = useCallback((selection) => {
    const startTS = _.values(selection)[0];
    const endTS = moment(startTS).endOf(MONTH).valueOf();
    setValue(startTS);
    if (_.isFunction(onChange)) {
      onChange({ startTS, endTS });
    }
  });

  const options = useMemo(() => {
    let options = {};
    const monthsTSArray = getTSArrayByTimeUnit({ startTS: minTS, endTS: maxTS, unit: MONTH });
    monthsTSArray.forEach(monthTS => {
      const title = moment(monthTS).format(FORMAT);
      options[String(monthTS)] = { title, id: monthTS };
    });
    return options;
  }, [minTS, maxTS]);


  const selectionValue = useMemo(() => {
    const ts = moment(value).startOf(MONTH).valueOf();
    return { [ts]: ts };
  }, [value]);


  const inputProps = {
    title: shouldHideTitle ? undefined : systemMessages.monthPickerTitle,
    value: selectionValue,
    onChange: changeHandler,
    options: options,
    type: propertyTypes.SELECTION_LIST,
    isClearable: false,
    isOpen,
  };

  if (_.isFunction(renderFunc))
    return renderFunc(inputProps);

  return <PlatformStandardInput {...inputProps} />;
};

export default MonthPicker;