import React from "react";
import { connect } from "react-redux";
import { connectContext } from "react-connect-context";
import {
  ProjectContext,
  ProjectManagerContext,
} from "../../../common/projects/contexts";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose, hoistStatics } from "recompose";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import theme from "../../assets/css/theme";
import SplitViewPage from "../../layouts/SplitViewPage";
import { GridContainer, GridItem } from "../../components";
import _ from "lodash";
import {
  hideLoading,
  startLoading,
  startToast,
} from "../../../common/app/actions";
import systemMessages from "../../../common/app/systemMessages";
import StandardInput from "../../components/CementoComponents/StandardInput";
import Button from "../../app/standardComponents/Button";

import InnerCollapsible from "../../components/CementoComponents/InnerCollapsible";
import {
  cameraMonitorInputFields,
  cameraConfigurationsInputFields,
  CAMERA_LOCATIONS,
} from "./config";
import {
  getCameras,
  getMonitor,
  CameraActionFactory,
  ActionTypes,
  updateCamera,
} from "./funcs";

const BUTTON_HEIGHT = 35;

class CamerasManagerPage extends React.Component {
  constructor(props) {
    super(props);
    this.cameraWasSelectedHandler = this.cameraWasSelectedHandler.bind(this);
    this.setProjectCamerasData = this.setProjectCamerasData.bind(this);
    this.saveCameraHandler = this.saveCameraHandler.bind(this);
    this.cancelHandler = this.cancelHandler.bind(this);
    this.editHandler = this.editHandler.bind(this);
    this.createNewCameraHandler = this.createNewCameraHandler.bind(this);
    this.state = {
      cameras: null,
      monitor: null,
      selectedCamera: null,
      errors: {},
    };
  }

  updateErrors(subject, error) {
    const { errors } = this.state;
    let updatedErrors = { ...errors };
    _.set(updatedErrors, [subject], error);

    this.setState({ updatedErrors });
  }

  async getCamerasMonitor(projectId) {
    let monitor =
      (await getMonitor(projectId).catch((error) => {
        this.updateErrors("monitor", error);
      })) || {};

    return monitor;
  }

  async getProjectCameras(projectId) {
    let cameras =
      (await getCameras(projectId).catch((error) => {
        this.updateErrors("cameras", error);
      })) || {};

    return cameras;
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  setComponentData(props, nextProps) {
    if (props.selectedProjectId !== nextProps.selectedProjectId)
      this.setProjectCamerasData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  async setProjectCamerasData() {
    const { selectedProjectId } = this.props;

    const [cameras, monitor] = await Promise.all([
      this.getProjectCameras(selectedProjectId),
      this.getCamerasMonitor(selectedProjectId),
    ]);

    this.setState({ cameras, monitor });
  }

  async cameraActionHandler(actionType) {
    const { startToast, selectedProjectId } = this.props;
    const { selectedCamera } = this.state;
    const action = CameraActionFactory(actionType);

    await new Promise((resolve) => {
      startToast({
        overlay: true,
        mandatory: true,
        title: systemMessages.camerasMessages.syncProjectQuestion,
        actions: [
          {
            message: systemMessages.continue,
            onClick: async () => {
              resolve(true);

              try {
                if (!_.isFunction(action)) throw new Error();

                await action(selectedProjectId, _.get(selectedCamera, ["id"]));
                startToast({
                  title: systemMessages.camerasMessages.syncProjectSuccess,
                });
              } catch (error) {
                startToast({ title: systemMessages.error });
              }
            },
            color: "success",
          },
          {
            message: systemMessages.cancel,
            onClick: () => resolve(false),
          },
        ],
      });
    });
  }

  renderInnerCollapsible(title, component) {
    return (
      <InnerCollapsible
        fullWidth
        title={title}
        mainContainerStyle={{
          backgroundColor: theme.innerCollapsibleRowBackground,
          border: theme.borderLineNeutralLight + "70",
        }}
      >
        {component}
      </InnerCollapsible>
    );
  }

  renderCameraController() {
    const { isCreateMode, selectedCamera, isEditMode } = this.state;

    if (!selectedCamera || isCreateMode || isEditMode) return null;

    const supportedCameraActions = [
      {
        id: "syncProject",
        title: systemMessages.camerasMessages.syncProject,
        onClick: () => this.cameraActionHandler(ActionTypes.SYNC),
      },
      {
        id: "reboot",
        title: systemMessages.camerasMessages.reboot,
        onClick: () => this.cameraActionHandler(ActionTypes.REBOOT),
      },
    ];

    const actionController = this.renderOptions(supportedCameraActions, {
      flexDirection: "column",
    });
    const innerCollapsibleTitle = systemMessages.camerasMessages.controlPanel;

    return this.renderInnerCollapsible(innerCollapsibleTitle, actionController);
  }

  renderMonitor() {
    const { selectedCamera, monitor, isEditMode, isCreateMode } = this.state;
    const selectedCameraMonitor = _.get(
      monitor,
      [_.get(selectedCamera, ["id"])],
      {}
    );
    const title = systemMessages.status;

    if (!selectedCamera || !selectedCameraMonitor || isCreateMode || isEditMode)
      return null;

    const cameraStatus = _.values(cameraMonitorInputFields).map((input) => {
      const { title, type, path } = input || {};

      return this.renderStandardInput(
        title,
        type,
        undefined,
        _.get(selectedCameraMonitor, path),
        undefined,
        {
          key: `camera_monitor_${type}_${path}`,
          disabled: true,
          isFocused: true,
        }
      );
    });

    return this.renderInnerCollapsible(title, cameraStatus);
  }

  renderCameraConfigurations() {
    const { isCreateMode, selectedCamera, isEditMode } = this.state;
    const title = systemMessages.camerasMessages.cameraConfigurations;

    if (!selectedCamera) return null;

    const cameraConfigurations = _.values(cameraConfigurationsInputFields).map(
      (input) => {
        const { title, type, path } = input || {};
        const onChangeHandler = (val) =>
          this.selectedCameraChangeHandler(path, val);

        return this.renderStandardInput(
          title,
          type,
          onChangeHandler,
          _.get(selectedCamera, path),
          undefined,
          {
            key: `camera_configurations_${type}_${path}`,
            disabled: Boolean(!isEditMode && !isCreateMode),
          }
        );
      }
    );

    return this.renderInnerCollapsible(title, cameraConfigurations);
  }

  renderStandardInput(title, type, onChange, value, options, extraInfo) {
    const { disabled, isFocused, key = "" } = extraInfo || {};

    return (
      <StandardInput
        disabled={Boolean(disabled)}
        isFocused={Boolean(isFocused)}
        key={key}
        titleStyle={Object.assign({}, STYLES.input.titleStyle)}
        valueStyle={Object.assign({}, STYLES.input.valueStyle)}
        key={key}
        title={title}
        type={type}
        options={options}
        value={value}
        onChange={onChange}
      />
    );
  }

  renderCameraContainer() {
    const { isCreateMode, selectedCamera, cameras } = this.state;
    const options = isCreateMode ? CAMERA_LOCATIONS : cameras;
    const selectedValue = _.get(options, [_.get(selectedCamera, ["id"])], null);

    const selectionListTitle = systemMessages.camerasMessages.cameras;

    return (
      <GridContainer style={{ alignSelf: "stretch", width: "100%" }}>
        <GridItem xs={8}>
          {this.renderPanelOptions()}
          {this.renderStandardInput(
            selectionListTitle,
            "SelectionList",
            this.cameraWasSelectedHandler,
            selectedValue,
            options
          )}
          {this.renderCameraConfigurations()}
          {this.renderMonitor()}
          {this.renderCameraController()}
        </GridItem>
      </GridContainer>
    );
  }

  selectedCameraChangeHandler(path, val) {
    const { selectedCamera } = this.state;
    let newSelectedCamera = { ...selectedCamera };
    _.set(newSelectedCamera, [path], val);

    this.setState({ selectedCamera: newSelectedCamera });
  }

  async saveCameraHandler() {
    const { selectedCamera } = this.state;
    const { selectedProjectId, startToast } = this.props;

    if (selectedCamera)
      try {
        await updateCamera(selectedProjectId, selectedCamera);
        startToast({
          title: systemMessages.objectSavedSuccessfully,
          values: { objectName: `${selectedCamera.id} camera` },
        });
        this.setState({ isCreateMode: false, isEditMode: false });
        this.setProjectCamerasData();
      } catch (error) {
        startToast({ title: systemMessages.error });
      }
  }

  cameraWasSelectedHandler(camera) {
    const { cameras, isCreateMode } = this.state;
    const { startToast } = this.props;
    const cameraLocation = _.head(_.values(camera));
    const isCameraExist = _.get(cameras, [cameraLocation]);

    let selectedCamera;

    if (isCreateMode && isCameraExist) {
      startToast({ title: systemMessages.cameraExist });
      return;
    } else if (isCreateMode)
      selectedCamera = _.cloneDeep(
        _.get(CAMERA_LOCATIONS, [cameraLocation]),
        {}
      );
    else selectedCamera = _.cloneDeep(_.get(cameras, [cameraLocation], {}));

    if (selectedCamera) this.setState({ selectedCamera });
  }

  cancelHandler() {
    const { isEditMode, selectedCamera, cameras } = this.state;
    let update = {
      isEditMode: false,
      isCreateMode: false,
      selectedCamera: null,
    };

    if (isEditMode) {
      let selectedCameraId = _.get(selectedCamera, ["id"]);
      let selectedCameraBeforeEditing = _.get(
        cameras,
        [selectedCameraId],
        null
      );
      _.set(update, ["selectedCamera"], selectedCameraBeforeEditing);
    }

    this.setState(update);
  }

  editHandler() {
    this.setState({ isEditMode: true, isCreateMode: false });
  }

  createNewCameraHandler() {
    this.setState({
      isEditMode: false,
      isCreateMode: true,
      selectedCamera: null,
    });
  }

  renderPanelOptions() {
    const { isEditMode, isCreateMode } = this.state;

    const panelOptions = {
      saveChanges: {
        id: "saveChanges",
        title: systemMessages.saveChanges,
        onClick: this.saveCameraHandler,
      },
      cancel: {
        id: "cancel",
        title: systemMessages.cancel,
        onClick: this.cancelHandler,
      },
      refresh: {
        id: "refresh",
        title: systemMessages.camerasMessages.refresh,
        onClick: this.setProjectCamerasData,
      },
      edit: {
        id: "edit",
        title: systemMessages.edit,
        onClick: this.editHandler,
      },
      create: {
        id: "create",
        title: systemMessages.create,
        onClick: this.createNewCameraHandler,
      },
    };

    let allowedOptionsInTheCurrentState;

    if (isEditMode || isCreateMode)
      allowedOptionsInTheCurrentState = [
        panelOptions.saveChanges,
        panelOptions.cancel,
      ];
    else
      allowedOptionsInTheCurrentState = [
        panelOptions.refresh,
        panelOptions.edit,
        panelOptions.create,
      ];

    return this.renderOptions(allowedOptionsInTheCurrentState);
  }

  renderOptions(options, flexStyle) {
    const style = {
      display: "flex",
      margin: `${theme.verticalMargin * 2}px 0`,
      justifyContent: "center",
    };

    if (flexStyle) _.assign(style, flexStyle);

    const res = (
      <div style={style}>
        {_.values(options).map((option) => {
          const { title, onClick, id } = option;

          return (
            <Button
              key={`button_${id}`}
              style={{
                marginLeft: 0,
                marginRight: 0,
                maxWidth: "100%",
                width: "unset",
              }}
              onClick={onClick ? onClick : undefined}
            >
              {title}
            </Button>
          );
        })}
      </div>
    );

    return res;
  }

  render() {
    const { rtl } = this.props;
    return (
      <SplitViewPage
        rtl={rtl}
        Main={
          <div
            style={{
              display: "flex",
              minHeight: "100%",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: theme.paddingSize,
            }}
          >
            <GridContainer
              style={{
                paddingBottom: 3 * theme.paddingSize,
                alignSelf: "stretch",
                width: "100%",
              }}
            >
              {this.renderCameraContainer()}
            </GridContainer>
          </div>
        }
      />
    );
  }
}

CamerasManagerPage = withStyles(buttonStyle)(CamerasManagerPage);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connectContext(ProjectManagerContext.Consumer),
  connect(
    (state) => ({
      uiParams: state.ui.uiParams,
      inDraftMode: state.ui.inDraftMode,
      companies: state.companies.map,
    }),
    { startToast, startLoading, hideLoading }
  )
);

export default enhance(CamerasManagerPage);

const STYLES = {
  input: {
    titleStyle: {
      padding: `${theme.verticalMargin}px ${theme.verticalMargin * 2}px`,
      fontWeight: "bold",
    },
    valueStyle: { padding: `0 ${theme.padding}px`, fontSize: theme.fontSize },
  },
};
