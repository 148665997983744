import { uploadImage } from '../images/actions';
import { startToast, performSafeAction, saveObjects } from '../app/actions';
import systemMessages from '../app/systemMessages';
import ExtraError from '../lib/errors/extraError';
import { splitInBatches } from '../app/funcs';
import _ from 'lodash';
import { fetchByTS, debugParams, replaceMaxUpdateTSIfNeeded } from '../lib/utils/utils';
import { schemasInfo, uploadObjectsDispatcher } from '../lib/offline-mode/config';
import { getAppState } from '../configureMiddleware';
import { platformActions } from "../platformActions";

export const CREATE_PROPERTIES_INSTANCE = 'CREATE_PROPERTIES_INSTANCE';
export const GET_PROPERTIES_INSTANCES = 'GET_PROPERTIES_INSTANCES';
export const GET_PROPERTIES_INSTANCES_FETCHING = 'GET_PROPERTIES_INSTANCES_FETCHING';
export const END_PROPERTIES_INSTANCES_LISTENER = 'END_PROPERTIES_INSTANCES_LISTENER';
export const UPDATE_PROPERTIES_INSTANCE = 'UPDATE_PROPERTIES_INSTANCE';
export const GET_NEW_PROPERTIES_INSTANCE_ID = 'GET_NEW_PROPERTIES_INSTANCE_ID';
export const CLEAN_CACHED_INSTANCES = 'CLEAN_CACHED_INSTANCES';
export const REMOVE_PROPERTIES_INSTANCE = 'REMOVE_PROPERTIES_INSTANCE';
export const GET_NEW_PROPERTY_INSTANCE_ID = 'GET_NEW_PROPERTY_INSTANCE_ID';
export const UPDATE_INTERNAL_INSTANCES = 'UPDATE_INTERNAL_INSTANCES';
export const SAVE_INSTANCES = 'SAVE_INSTANCES';
export const SET_RECENTLY_SIGNING_USERS = 'SET_RECENTLY_SIGNING_USERS';

let localPropInstances = {
  ///     explanation for this weird code: 
  ///     -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  ///      if you connection is really bad but the app doesn't see at as offline mode and you try to upload propInstances:  
  ///         - isConnected = true
  ///         - firebase.update is called with new propInstances
  ///         - firebase listener is called with isLocal:null
  ///         - propInstance may get stuck locally forever
  ///   
  ///      solution:   
  ///         - save each is local prop instance in this map
  ///         - remove from map only when firebase.update().then is happening - it happens only when update is really done
  ///

  map: {}, // locally created or modified prop instances
  isStuckLocal: function (id) {
    return Boolean(this.map[id]);
  },
  set: function (id) {
    this.map[id] = id;
  },
  unset: function (id) {
    if (this.map[id])
      delete this.map[id];
  },

};

export function getPropertiesInstances(viewer, scopeId, subjectName, cleanAll) {
  return ({ lokiInstance, realmInstance, platformActions }) => {
			
			if (cleanAll) 
        setPropertiesInstancesValues([], subjectName, 0, scopeId, realmInstance, lokiInstance, platformActions, cleanAll);

			let fetchParams={
        scope: 'projects',
				projectId:scopeId,
				viewer,
				resource:{
					name:'propertiesInstances',
          schemaInfo: schemasInfo.propertyInstances,
					doneLoading: GET_PROPERTIES_INSTANCES_FETCHING,
					subjectName,
					firebasePath:'properties/instances/projects'
				},
        saveFunc: (_data,_lastUpdate, checkClean, resourceName, callStackSource, _subjcetName)=> {
          if (debugParams.disableFetchByTSSave)
            return;
          return setPropertiesInstancesValues(_data, _subjcetName, _lastUpdate, scopeId, realmInstance, lokiInstance, platformActions);
        },
        getLastUpdateTS: ()=> getLastUpdateTS(realmInstance, lokiInstance, scopeId, subjectName)
			}
			fetchByTS(fetchParams);
		

    return {
      type: GET_PROPERTIES_INSTANCES,
      payload: { scopeId }
    };
  };
}

function getLastUpdateTS(realmInstance, lokiInstance, scopeId, subjectName) {
  let lastUpdateTS = 0;
  if (platformActions.app.getPlatform() == "web") {
    let lastUpdateTSObj = {};
    let lastUpdateTSObjArr = lokiInstance.getCollection('propertyInstances').chain().find({projectId: scopeId, subjectName}).simplesort("updatedTS", true).limit(1).data();
    if (lastUpdateTSObjArr.length) lastUpdateTSObj = lastUpdateTSObjArr[0];
    lastUpdateTS = lastUpdateTSObj.updatedTS;
  }
  else {
    lastUpdateTS = realmInstance.propertyInstances.objects('propertyInstance1').filtered(`projectId == "${scopeId}" AND subjectName == "${subjectName}"`).max('updatedTS');
  }

  return lastUpdateTS || 0;
}

function filterOutNonIsLocalInstancesThatWasNotUploaded(instance) {
  // check if instance was saved with isLocal:false, but according to localPropInstances.map wasn't uploaded yet
  // for more explanation check out the comments inside localPropInstances object at the top of the file
  return Boolean(instance.isLocal || !localPropInstances.isStuckLocal(instance.id));
}

function setPropertiesInstancesValues(propertiesInstances, subjectName, lastUpdateTS, scopeId, realmInstance, lokiInstance, platformActions, cleanAll) {
  let propInstanceToSave = _.filter(propertiesInstances, filterOutNonIsLocalInstancesThatWasNotUploaded);

  if (propInstanceToSave.length || cleanAll) {
    if (platformActions.app.getPlatform() == "web") {
      saveToLoki(propertiesInstances, subjectName, undefined, scopeId, lokiInstance, null, cleanAll);
    } else
      saveToRealm(propertiesInstances, subjectName, undefined, scopeId, realmInstance, null, cleanAll, platformActions);
  }
}

function saveToLoki(propertiesInstances = {}, subjectName, lastUpdateTS, scopeId, lokiInstance, ignoreTimestamp, cleanAll) {
  let propertiesInstancesValuesArray = Object.values(propertiesInstances || {});
  if (!cleanAll && propertiesInstancesValuesArray.length == 0)
    return
  
  let allInstances = [];
  let allDeletedIds = [];

  propertiesInstances = Object.values(propertiesInstances).sort((propInstanceA, propInstanceB) => ((propInstanceA.updatedTS || 0) > (propInstanceB.updatedTS || 0) ? -1 : 1));


  (propertiesInstances).forEach(propInstance => {
    propInstance.isDeleted
      ? allDeletedIds.push(propInstance.id)
      : allInstances.push({ ...propInstance.realmToObject(), subjectName: subjectName, projectId: scopeId });
  });

  if (cleanAll) {
    lokiInstance.getCollection('propertyInstances').cementoDelete({ projectId: scopeId, subjectName: subjectName });
    }  

  if (allDeletedIds.length)
    lokiInstance.getCollection('propertyInstances').cementoDelete({ id: { '$in': allDeletedIds } });

  lokiInstance.getCollection('propertyInstances').cementoUpsert(allInstances);
}

function saveToRealm(propertiesInstances, subjectName, lastUpdateTS, scopeId, realmInstance, ignoreTimestamp, cleanAll, platformActions) {
  if (propertiesInstances) propertiesInstances = _.pickBy(propertiesInstances, (x) => {
    if (x.isDeleted)
      return true;

    if (!Boolean(x.propId))
      platformActions.sentry.notify('propInstance without propId ', x);
    if (!Boolean(x.parentId))
      platformActions.sentry.notify('propInstance without parentId ', x);
    return Boolean(x.propId && x.parentId);
  });

  if (!cleanAll && Object.keys(propertiesInstances || {}).length == 0)
    return;

  let propertiesInstancesValuesArray = Object.values(propertiesInstances || {});
  if (!cleanAll && propertiesInstancesValuesArray.length == 0)
    return;

  propertiesInstances = propertiesInstancesValuesArray.sort((propInstanceA, propInstanceB) => (propInstanceA.updatedTS || 0) > (propInstanceB.updatedTS || 0) ? -1 : 1);
  let currBatchMaxLastUpdateTS = _.get(propertiesInstances, [0 , 'updatedTS'], 0);
  let realm = realmInstance.propertyInstances;
  realm.beginTransaction();
  try {
    if (cleanAll) {
      let allLocPropsInstances = realm.objects('propertyInstance1').filtered(`projectId = "${scopeId}" AND subjectName = "${subjectName}"`);
      realm.delete(allLocPropsInstances);
    }

    (propertiesInstances).forEach(propInstance => {

      let stringData = propInstance && propInstance.data !== null && propInstance.data !== undefined ? JSON.stringify(propInstance.data) : null;

      if (propInstance && propInstance.id) {
        let valueScope = propInstance.valueScope;
     
        if (!propInstance.isDeleted) {
          let propInstanceObj = { ...propInstance.realmToObject(), data: stringData, subjectName: subjectName, projectId: scopeId, valueScope, isLocal: (propInstance.isLocal ? true : null) };
          realm.create('propertyInstance1', propInstanceObj, 'modified');
        }
        else {
          let allInstances = realm.objects('propertyInstance1').filtered(`projectId = "${scopeId}" AND id = "${propInstance.id}"`);
          realm.delete(allInstances);
        }
           
      }
      else
        platformActions.sentry.notify('propertyInstance missing ID', propInstance);
    });
    
    
    replaceMaxUpdateTSIfNeeded(currBatchMaxLastUpdateTS, realm, 'propertyInstance1', `projectId = "${scopeId}" AND subjectName = "${subjectName}"`, subjectName);

    realm.commitTransaction();
  } catch (e) {
    realm.cancelTransaction();
    throw e;
  }
}

export function removePropertiesInstancesFromRealm(realmInstance, subjectName) {
  let propertiesInstances = realmInstance.propertyInstances;
  let allLocPropInstances = propertiesInstances.objects('propertyInstance1').filtered('subjectName = "' + subjectName + '"');
  
  propertiesInstances.beginTransaction();
  try {
    propertiesInstances.delete(allLocPropInstances);
    propertiesInstances.commitTransaction();
  } catch (e) {
    propertiesInstances.cancelTransaction();
    throw e;
  }
}

export async function removePropertiesInstancesFromLoki(lokiInstance, subjectName, legacyInstancesDelete) {
  await lokiInstance.getCollection('propertyInstances').cementoFullDelete({ subjectName: subjectName });
}

export function endPropertiesInstancesListener(scopeId, subjectName) {
  return ({ dispatch, firebaseDatabase }) => {
    let firebasePath = 'properties/instances/projects/' + scopeId + '/' + subjectName;
    firebaseDatabase().ref(firebasePath).off('value');
    firebaseDatabase().ref(firebasePath).off('child_added');
    firebaseDatabase().ref(firebasePath).off('child_changed');

    return {
      type: END_PROPERTIES_INSTANCES_LISTENER,
      payload: { scopeId }
    };
  };
}

export function uploadPropertiesInstances(projectId, propertiesInstancesArray, subjectName, shouldWaitForUpload = true) {
	return ({ firebaseDatabase, dispatch }) => {
		const getPromise = async () => {
			if (!projectId || !propertiesInstancesArray || propertiesInstancesArray.length === 0)
        return; 
        
			let propertiesInstancesToUpload = [];
			
			propertiesInstancesArray.forEach(currPropInstance => {
        if (!currPropInstance)
          return; // TODO: report

        currPropInstance = currPropInstance.realmToObject();
				if (currPropInstance.id) {
					if (_.isNil(currPropInstance.data))
						currPropInstance.isDeleted = true;
					propertiesInstancesToUpload.push(currPropInstance.realmToObject());
				}
				else {
          let newId = firebaseDatabase().ref('properties/instances/projects/' + projectId + '/' + subjectName).push().key;
					propertiesInstancesToUpload.push({ id: newId, ...currPropInstance });
				}
			});
      
      const promise = dispatch(saveInstances(projectId, subjectName, propertiesInstancesToUpload));
      if (!shouldWaitForUpload)
        return {
          instances: propertiesInstancesToUpload,
          projectId,
          subjectName,
          success: undefined
        };
      else
        return await promise;
    };
    
    return { 
      type: UPDATE_PROPERTIES_INSTANCE,
      payload: getPromise()
    };
	};
}

const saveInstances = (projectId, subjectName, instances, originalInstances = undefined) => {
  const getPromise = async () => {
    let success = null;
    if (projectId && subjectName && Object.keys(instances || {}).length) {
      instances = Object.values(instances)
                    .filter(instance => _.get(instance, 'data') !== undefined)
                    .map(instance => ({ ...instance, subjectName, projectId }));
  
      const actionRes = await uploadObjectsDispatcher({
        projectId,
        objectsToSave: instances,
        originalObjects: originalInstances,
        schemaInfo: schemasInfo.propertyInstances
      });
  
      success = actionRes.success;
    }

    return {
      projectId,
      subjectName,
      instances,
      success,
    };
  }

  return {
    type: SAVE_INSTANCES,
    payload: getPromise()
  }
}

export function getNewPropertyInstanceId(projectId, subjectName) {
  return ({ firebaseDatabase }) => {
    const newId = firebaseDatabase().ref('properties/instances/projects/' + projectId + '/' + subjectName).push().key;

    return {
      type: GET_NEW_PROPERTY_INSTANCE_ID,
      payload: { instanceId: newId }
    };
  };
}

export function removePropertiesInstance(projectId, subjectName, instanceId) {
  return ({ firebaseDatabase, removeEmpty, dispatch, realmInstance }) => {
    const getPromise = async () => {
      let success = true;
      if (!projectId || !subjectName || !instanceId)
        return;

      try {
        let path = `properties/instances/projects/${projectId}/${subjectName}/${instanceId}`;
        let update = {
          isDeleted: true,
          updatedTS: new Date().getTime()
        };
        await firebaseDatabase().ref(path).update(update);
      }
      catch (err) {
        success = false;
      }
      return { success };
    };
    return {
      type: REMOVE_PROPERTIES_INSTANCE,
      payload: getPromise()
    };
  };
}

export function uploadPropertyInstances(projectId, inPropertyInstances, newId, subjectName, saveLocally, updateFirebase, callback) {
  return ({ firebase, platformActions, realmInstance, removeEmpty, dispatch, lokiInstance }) => {
    const getPromise = async () => {
      let currTime = new Date().getTime();
      let updates = {};
      let success = true;
      let localPropertiesInstances = [];

      (inPropertyInstances || []).forEach(inPropertyInstance => {
        let propertyInstance;

        if (inPropertyInstance && inPropertyInstance.id) {
          propertyInstance = { ...inPropertyInstance.realmToObject() };
          propertyInstance.updatedTS = currTime;
        }
        else {
          propertyInstance = { ...inPropertyInstance.realmToObject() };
          //propertyInstance.id = newId;
          propertyInstance.createdTS = currTime;
          propertyInstance.updatedTS = currTime;
          propertyInstance = removeEmpty(propertyInstance, 'uploadPropertyInstances');
        }

        delete propertyInstance.action;
        delete propertyInstance.projectId;
        delete propertyInstance.subjectName;
        delete propertyInstance.uploadLocations;
        delete propertyInstance.instanceDataType;
        propertyInstance.isLocal = true;

        localPropInstances.set(propertyInstance.id); // for more explanation check out the comments inside localPropInstances object at the top of the file

        if (saveLocally)
          localPropertiesInstances.push(propertyInstance);
      });

      let uploadedPropertyInstances = [];
      let uploadFailedPropertyInstances = [];
      await Promise.all(localPropertiesInstances.map(async (propertyInstance) => {
        try {
          let isGlobal = propertyInstance.valueScope == 'global';

          let newPropertyInstance = { ...propertyInstance.realmToObject(), ...await uploadDeep(propertyInstance.realmToObject(), propertyInstance.id, propertyInstance.propId, currTime, subjectName, projectId, dispatch) };
          let newPropertyInstanceForUpload = {...newPropertyInstance};
          delete newPropertyInstanceForUpload.valueScope;
          delete newPropertyInstanceForUpload.isLocal;
          const updatePath = isGlobal
                              ? `properties/instances/global/${subjectName}/${propertyInstance.id}`
                              : `properties/instances/projects/${projectId}/${subjectName}/${propertyInstance.id}`;
          
          updates[updatePath] = newPropertyInstanceForUpload;
          uploadedPropertyInstances.push(newPropertyInstance);
        }
        catch (e) {
          uploadFailedPropertyInstances.push(propertyInstance);
          platformActions.sentry.notify('uploadPropertyInstances failed', { error: e, propertyInstance: propertyInstance.realmToObject(), projectId });
          console.warn(e);
          success = false;
        }
      }));

      if (_.isFunction(callback) && !success)
        callback(success);

      try {
        if (saveLocally)
          setPropertiesInstancesValues([...uploadFailedPropertyInstances, ...uploadedPropertyInstances], subjectName, undefined, projectId, realmInstance, lokiInstance, platformActions);
        const isConnected = getAppState && getAppState() && getAppState().getNested(['app', 'isConnected'], false);
        if (!isConnected) {
          throw new Error('No reception');
        }
      } catch (e) {
        platformActions.sentry.notify('uploadPropertyInstances => setPropertiesInstancesValues failed', { error: e });
        console.warn(e);
        success = false;
        return { success };
      }

      //throw new Error('Test error');
      if (updateFirebase) {
        if (Object.keys(updates).length > 50) {
          const updateBatchesArr = splitInBatches(updates, 50);
          await Promise.all(updateBatchesArr.map(async updateBatch => {
            return await firebase.update(updateBatch).then(() => {
              _.forIn(updateBatch, inst => {
                localPropInstances.unset(inst.id); // for more explanation check out the comments inside localPropInstances object at the top of the file
              });
            });
          }));
          if (_.isFunction(callback)) callback(success);
        }
        else
          firebase.update(updates).then(() => {

            _.forIn(updates, inst => {
              localPropInstances.unset(inst.id); // for more explanation check out the comments inside localPropInstances object at the top of the file
            });

            if (_.isFunction(callback)) callback(success);
          });
      }

      return { updates, success };
    };

    return {
      type: UPDATE_PROPERTIES_INSTANCE,
      payload: getPromise()
    };
  };
}

let mapAllObjectsToUpload = (obj, currFullPath) => {
  let isObject = obj != null && typeof obj == 'object';
  let ret = {};

  if (isObject && (obj.isLocal || obj.file || (obj.uri && !obj.uri.startsWith('http'))))
    ret[currFullPath] = obj;

  if (isObject)
    obj.loopEach(k => {
      let map = mapAllObjectsToUpload(obj[k], currFullPath + ',' + k);
      Object.keys(map || {}).forEach(k => ret[k] = map[k]);
    });

  return ret;
};

let uploadDeep = (async (obj, instanceId, propId, currTime, subjectName, projectId, dispatch) => {
  let mapAfterUp = {};
  let map = mapAllObjectsToUpload(obj.data, 'data');

  let pathArray = Object.keys(map);
  if (pathArray.length == 0)
    return {};
  await Promise.all(pathArray.map(async (stringPath, index) => {
    let currToUpload = obj.getNested(stringPath.split(','));
    let objRef = currToUpload.file || currToUpload;
    let type = objRef.extension ? objRef.extension : ((objRef.type && objRef.type.indexOf('pdf') != -1) ? 'pdf' : objRef.type);
    type = (type && type.indexOf('jpeg') != -1) ? 'jpg' : type;
    let uri = await uploadImage(objRef, projectId + "/" + propId + "/" + instanceId + '_' + currTime + '_' + index, subjectName);
    if (!uri) return new Promise((res, rej) => rej("uploadImage failed!"));
    mapAfterUp[stringPath] = ({ uri, description: null, type: type || null, updatedTS: currTime, title: currToUpload.title, fileName: objRef.name || null, fileRefId: currToUpload.fileRefId || null, version: currToUpload.version || null, isDeleted: currToUpload.isDeleted || null, isArchive: currToUpload.isArchive || null, owner: currToUpload.owner || null, uploadTS: currToUpload.uploadTS || null });
  }));

  pathArray.forEach(stringPath => obj = obj.setNested(stringPath.split(','), mapAfterUp[stringPath], true));
  
  return obj;
});

export function updateInternalInstances(scope, scopeId, subjectName, instances) {
  return ({ firebase }) => {
    const getPromise = async () => {
      if (!scope || !scopeId || !subjectName || !instances)
        return;

      let dbUpdates = {};
      instances.loopEach((i, instance) => {
        dbUpdates[`internal/properties/instances/${scope}/${scopeId}/${subjectName}/${instance.id}`] = instance;
      });

      let success = true;
      try {
        await firebase.update(dbUpdates);
      } catch (error) {
        console.error("getPromise -> error", error);
        success = false;
      }

      return { success, dbUpdates };
    };

    return {
      type: UPDATE_INTERNAL_INSTANCES,
      payload: getPromise()
    };
  };
}


export function setRecentlySigningUsers(userId, projectId, signaturesContext){
  return {
    type: SET_RECENTLY_SIGNING_USERS,
    payload: { userId, projectId, signaturesContext }
  }
}