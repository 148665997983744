import { Section, Field } from '../ProjectManager/config/projectManagerConfig';
import userMessages from '../../../common/users/usersMessages';
import systemMessages from '../../../common/app/systemMessages';


//////////////////////////////////////////////////////
//////////////////// Employee Fields /////////////////
//////////////////////////////////////////////////////


const employeeName = new Field(userMessages.displayNamePlaceholder, 'String', ['displayName'], null, { fullWidth: true, mandatory: true });

const employeePhoneNumber = new Field(userMessages.mobileSelector, 'String', ['phoneNumber'], null, { fullWidth: true , mandatory: true, innerStyle: {direction: 'ltr'}});

const employeeEmail = new Field(userMessages.emailSelector , 'String', ['email'], null, { fullWidth: true });

const employeeTitle = new Field(userMessages.title, 'SelectionList', ['title'], null, { fullWidth: true });

const employeeCompany = new Field(userMessages.companySelector, 'SelectionList', ['companyId'], null, { fullWidth: true, mandatory: true });


//////////////////////////////////////////////////////
//////////////////// Subcontractor Fields ///////////
//////////////////////////////////////////////////////

const SubcontractorName = new Field(systemMessages.manage.companyName, 'String', ['name'], null, { fullWidth: true, mandatory: true });

const SubcontractorLogo = new Field(systemMessages.manage.companyLogo, 'Picture', ['logo'], null, { fullWidth: true });

///////////////////////////////////////////////////////
///////////////// Details Sections ////////////////////
///////////////////////////////////////////////////////

const DetailsBasicSection = new Section(userMessages.details, [
    employeeName,
    employeePhoneNumber,
    employeeEmail,
    employeeTitle,
    employeeCompany,
  ], {}, 'basic');

const SubcontractorDetailsSection = new Section(userMessages.details, [
    SubcontractorName,
    SubcontractorLogo,
], {}, 'basic')

export const DetailsSection = [
    DetailsBasicSection,
];

export const SubcontractorSection = [
    SubcontractorDetailsSection,
];

