import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import { PROJECT_TYPE_COMPLEX_BUILDINGS } from "../../../common/projects/actions";
import { getChecklistItemInstances } from "../../../common/checklists/funcs";
import withStyles from "@material-ui/core/styles/withStyles";
import { LabelImportantRounded } from "@material-ui/icons";
import MinPost from "../Posts/MinPost";
import { startToast } from "../../../common/app/actions";
import { lokiInstance } from "../../../common/configureMiddleware";
import { GridContainer, GridItem } from "../../components";
import DisplayName from "../../components/CementoComponents/DisplayName";
import {
  endCommentsListener,
  getComments,
  deleteComment,
  createLocalComment,
  uploadNewPostComment,
} from "../../../common/comments/actions";

import {
  updateIssueState,
  updateIssueAssignTo,
} from "../../../common/posts/actions";
import * as issueStates from "../../../common/issues/issueStates";
import issuesMessages from "../../../common/issues/issuesMessages";
import checklistItemMessages from "../../../common/checklistItems/checklistItemMessages";
import checklistMessages from "../../../common/checklists/checklistsMessages";
import Text from "../../components/CementoComponents/Text";
import theme from "../../assets/css/theme";
import NavArrow from "../../assets/img/icons/navArrow.png";
import StatusUpdatedIcon from "../../assets/img/tasks/statusUpdated.png";
import PhotoIcon from "../../assets/img/tasks/photo.png";
import Date from "../../components/CementoComponents/Date";
import { getLocationTitle } from "../Locations/funcs";
import ChecklistRow from "./ChecklistRow";
import trash from "../../assets/img/icons/trash.png";
import MenuScrollbar from "../../components/CementoComponents/MenuScrollbar";

const storyTypes = {
  statusUpdated: 1,
  issusAdded: 2,
  recordsAdded: 3,
};
const storyTypesImages = {
  1: StatusUpdatedIcon,
  2: PhotoIcon,
  3: PhotoIcon,
};

class ChecklistItemCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onInnerObjectSelect = this.onInnerObjectSelect.bind(this);
    this.createStoryTimeline = this.createStoryTimeline.bind(this);
    this.lokiPostsListener = this.lokiPostsListener.bind(this);
    this.getLocationTitles = this.getLocationTitles.bind(this);
    this.onChecklistItemSelectOrUnselect =
      this.onChecklistItemSelectOrUnselect.bind(this);
    this.onCardTabSelect = this.onCardTabSelect.bind(this);
    this.isExtraDataFulfilledCheck = this.isExtraDataFulfilledCheck.bind(this);
    this.defaultFooterFlags = {
      commentMode: false,
      reassignMode: false,
      allButtonsMode: true,
      selectedReassign: null,
    };
    this.state = {
      allButtonsMode: true,
      story: [],
      relevantIssues: [],
      relevantDocumentations: [],
      comments: [],
      ...this.defaultFooterFlags,
    };
  }

  lokiPostsListener(collectionName) {
    if (collectionName == "posts") {
      let newStateChanges = {};
      let res = this.createStoryTimeline(this.props, this.state.instance);
      newStateChanges.relevantIssues = res.relevantIssues;
      newStateChanges.relevantDocumentations = res.relevantDocumentations;
      newStateChanges.story = res.story;
      this.setState(newStateChanges);
    }
  }

  getRelevantPosts(nextProps, instance) {
    if (!nextProps.selectedProjectId || !instance) return [];
    let posts = this.lokiPosts.cementoFind({
      projectId: nextProps.selectedProjectId,
      "checklistItemInstance.id": { $in: [instance.id] },
    });
    return posts;
  }

  componentWillUnmount() {
    const { endCommentsListener, selectedProjectId } = this.props;
    const { instance } = this.state;
    this.lokiPosts.cementoOff("checklistItemCardListener");
    if (endCommentsListener && instance)
      endCommentsListener(selectedProjectId, instance.id);
  }

  UNSAFE_componentWillMount() {
    this.lokiPosts = lokiInstance.getCollection("posts");
    this.lokiPosts.cementoOn(
      "checklistItemCardListener",
      this.lokiPostsListener
    );
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};
    if (
      props.checklistItem != nextProps.checklistItem ||
      props.checklist != nextProps.checklist ||
      props.locationId != nextProps.locationId ||
      (nextProps.checklistItem &&
        props.isValDiff(nextProps, [
          "checklistInstances",
          nextProps.selectedProjectId,
          nextProps.checklistItem.id,
          nextProps.locationId,
        ]))
    ) {
      let instances = nextProps.getNested(
        [
          "checklistInstances",
          nextProps.selectedProjectId,
          nextProps.checklistItem.id,
          nextProps.locationId,
        ],
        []
      );
      newStateChanges.instance = getChecklistItemInstances(
        nextProps.checklist.id,
        instances
      );
    }

    let prevInstanceId = (this.state.instance || {}).id;
    let instanceId = (newStateChanges.instance || this.state.instance || {}).id;
    if (prevInstanceId != instanceId)
      this.onChecklistItemSelectOrUnselect(
        props,
        nextProps,
        this.state.instance,
        newStateChanges.instance
      );

    if (
      props.isValDiff(nextProps, ["checklistItem", "id"]) ||
      props.locationId != nextProps.locationId ||
      props.trades != nextProps.trades ||
      props.buildings != nextProps.buildings ||
      props.floors != nextProps.floors ||
      props.units != nextProps.units ||
      (newStateChanges.instance &&
        newStateChanges.isValDiff(this.state, ["instance", "locationId"]))
    ) {
      let locationTitles = this.getLocationTitles(
        nextProps,
        newStateChanges.instance
      );
      const { onCardLoad, checklistItem, changeEditMode } = nextProps;
      const headerOptions = {
        title: Object.values(locationTitles).join(" / "),
        editable: true,
        onSave: this.isExtraDataFulfilledCheck,
        onCancel: () => {
          if (changeEditMode)
            changeEditMode(false);
          return true;
        },
      };

      let tabs = [
        { href: "info", title: checklistMessages.checklistCard.info },
        {
          href: "relevantIssues",
          title: checklistMessages.checklistCard.connectedTask,
        },
        {
          href: "relevantDocumentations",
          title: checklistMessages.checklistCard.records,
        },
      ];
      if (checklistItem.extraDescription)
        tabs.splice(1, 0, {
          href: "extraDescription",
          title: checklistItemMessages.extraDescription,
        });
      let tabsParams = { tabs, onTabSelect: this.onCardTabSelect };

      onCardLoad(headerOptions, tabsParams, false);
    }

    if (
      instanceId &&
      props.isValDiff(nextProps, [
        "comments",
        nextProps.selectedProjectId,
        nextProps.instanceId,
      ])
    ) {
      newStateChanges.comments = [];
      nextProps
        .getNested(["comments", nextProps.selectedProjectId, instanceId], {})
        .loopEach((id, value) => newStateChanges.comments.push(value));
    }

    if (
      props.checklistItem &&
      nextProps.checklistItem &&
      props.checklistItem.id != nextProps.checklistItem.id
    )
      newStateChanges = Object.assign(newStateChanges, this.defaultFooterFlags);

    if (
      props.style != nextProps.style ||
      props.checklistItem != nextProps.checklistItem ||
      newStateChanges.hasOwnProperty("instance") ||
      (instanceId &&
        props.isValDiff(nextProps, [
          "comments",
          nextProps.selectedProjectId,
          instanceId,
        ]))
    ) {
      let res = this.createStoryTimeline(
        nextProps,
        newStateChanges.hasOwnProperty("instance")
          ? newStateChanges.instance
          : this.state.instance
      );
      newStateChanges.relevantIssues = res.relevantIssues;
      newStateChanges.relevantDocumentations = res.relevantDocumentations;
      newStateChanges.story = res.story;
    }

    if (Object.values(newStateChanges).length > 0)
      this.setState(newStateChanges);
  }

  createStoryTimeline(nextProps, instance) {
    if (!instance)
      return { story: [], relevantIssues: [], relevantDocumentations: [] };

    let story = [];
    let relevantIssues = [];
    let relevantDocumentations = [];
    let relevantPosts = this.getRelevantPosts(nextProps, instance);
    relevantPosts = relevantPosts.sort(
      (a, b) =>
        (b.editedAt || b.createdAt || 0) - (a.editedAt || a.createdAt || 0)
    );
    let commentsArray = [];
    nextProps
      .getNested(["comments", nextProps.selectedProjectId, instance.id], [])
      .loopEach((id, c) => commentsArray.push({ comment: c, s: [] }));
    commentsArray = commentsArray.sort((a, b) => a.createdAt - b.createdAt);

    commentsArray.loopEach((index, curr) => {
      let currStory = {
        id: curr.getNested(["comment", "id"]),
        type: storyTypes.statusUpdated,
        status: curr.getNested(["comment", "data", "status"]),
        title: (
          <Text>
            {
              checklistItemMessages.status[
                curr.getNested(["comment", "data", "status"])
              ]
            }
          </Text>
        ),
        subTitle: (
          <div style={{ display: "flex" }}>
            <DisplayName
              style={{
                ...theme.defaultFont,
                fontSize: 12,
                fontWeight: theme.bold,
              }}
              message={issuesMessages.story.by}
              messageValueProperty={"name"}
              userId={curr.getNested(["comment", "owner", "id"])}
            />
            <Date
              style={{ paddingLeft: 5, paddingRight: 5 }}
              format={{ detailed: true }}
            >
              {curr.comment.createdAt}
            </Date>
          </div>
        ),
      };

      story.push(currStory);
    });

    relevantPosts.forEach((p) =>
      !p.isIssue || p.issueState == issueStates.ISSUE_STATE_CLOSED
        ? relevantDocumentations.push(p)
        : relevantIssues.push(p)
    );
    return { story, relevantIssues, relevantDocumentations };
  }

  onInnerObjectSelect(post) {
    const { onInnerObjectSelect } = this.props;
    if (onInnerObjectSelect) onInnerObjectSelect("post", { post });
  }

  getLocationTitles(nextProps, instance) {
    const { selectedProjectId, trades, checklistItem } = nextProps;

    let locationsTitle = getLocationTitle(
      nextProps.getNested(["buildings"]),
      nextProps.getNested(["floors"]),
      nextProps.getNested(["units"]),
      null,
      null,
      null,
      (instance || {}).locationId || nextProps.locationId,
      nextProps.intl,
      nextProps.projects.getNested([selectedProjectId, "type"]) !=
        PROJECT_TYPE_COMPLEX_BUILDINGS
    );

    return { ...locationsTitle };
  }

  onChecklistItemSelectOrUnselect(
    props,
    nextProps,
    prevInstance,
    nextInstance
  ) {
    const {
      endCommentsListener,
      getComments,
      selectedProjectId,
      post,
      minMode,
    } = nextProps;
    if (prevInstance && prevInstance.id)
      endCommentsListener(selectedProjectId, prevInstance.id);
    if (nextInstance && nextInstance.id)
      getComments(selectedProjectId, nextInstance.id);
  }

  getRef(node) {
    this.postStoryRef = node;
  }

  onCardTabSelect(id) {
    this.setState({ selectedTabId: id });
    setTimeout(
      (() => {
        this.setState({ selectedTabId: null });
      }).bind(this),
      500
    );
  }

  isExtraDataFulfilledCheck() {
    const { startToast } = this.props;
    let returnValue = true;
    if (!this.checklistRowRef?.component?.state?.isExtraDataFulfilled) {
      startToast({
        title: checklistItemMessages.toasts.mandatoryData,
        type: "error",
      });
      returnValue = false;
    }

    return returnValue;
  }

  handleChecklistRowRef = (component) => {
    this.checklistRowRef = { component };
  }

  render() {
    const {
      trades,
      rtl,
      checklist,
      checklistItem,
      locationId,
      classes,
      deleteComment,
      viewer,
      selectedProjectId,
      editMode,
      changeEditMode,
      locationsData,
    } = this.props;
    const {
      instance,
      story,
      relevantIssues,
      relevantDocumentations,
      selectedTabId,
    } = this.state;

    let stageTitle = checklist.getNested(["stage"], null);
    let checklistType = checklist.getNested(["type"], null);
    let checklistTitle = checklist.getNested(["description"], null);
    let duplicationNo = checklist.getNested(["duplicationNo"], null);
    if (duplicationNo) checklistTitle = `${checklistTitle} - ${duplicationNo}`;
    let headerSectionStyle = {
      alignItems: "center",
      textAlign: "center",
      marginBottom: theme.paddingSize,
      color: theme.headerColorDark,
      fontFamily: "Assistant - Semi Bold",
      fontSize: 16,
      fontWeight: theme.bold,
      [rtl ? "marginLeft" : "marginRight"]: 3,
    };

    return (
      <MenuScrollbar isSmooth={true}>
        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <div
            id={"info"}
            className={classes.cardSectionsStyles}
            style={{
              transition: "all 150ms ease 0s",
              backgroundColor:
                selectedTabId == "info"
                  ? theme.backgroundColorHover
                  : theme.backgroundColorBright,
            }}
          >
            {Boolean(stageTitle) && (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  marginBottom: 2 * theme.paddingSize,
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    color: theme.brandNeutral,
                    fontFamily: "Assistant - Semi Bold",
                    fontSize: 16,
                    fontWeight: theme.bold,
                    [rtl ? "marginLeft" : "marginRight"]: 3,
                  }}
                >
                  {stageTitle}
                </div>
                <img
                  style={{
                    margin: 7,
                    transform: "scaleX(" + (rtl ? 1 : -1) + ")",
                  }}
                  src={NavArrow}
                />
                <div
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    color: theme.headerColorDark,
                    fontFamily: "Assistant - Semi Bold",
                    fontSize: 16,
                    fontWeight: theme.strongBold,
                    [rtl ? "marginLeft" : "marginRight"]: 3,
                  }}
                >
                  {checklistTitle}
                </div>
              </div>
            )}
            <ChecklistRow
              onRef={this.handleChecklistRowRef}
              checklistId={checklist.getNested(["id"])}
              linksMode="convert"
              issuesCounter={relevantIssues && relevantIssues.length}
              documentationsCounter={
                relevantDocumentations && relevantDocumentations.length
              }
              minMode={true}
              minTrades={true}
              onClick={null}
              currInstance={instance}
              value={checklistItem}
              checklistType={checklistType}
              locationId={locationId}
              locationsData={locationsData}
              extraDataEditMode={editMode}
              toggleExtraDataEditMode={changeEditMode}
              mainContainerStyle={{
                border: "1px solid" + theme.headerInfoColor + 70,
              }}
            />
          </div>

          {Boolean(checklistItem.extraDescription) && (
            <div
              id={"extraDescription"}
              className={classes.cardSectionsStyles}
              style={{
                transition: "all 150ms ease 0s",
                backgroundColor:
                  selectedTabId == "extraDescription"
                    ? theme.backgroundColorHover
                    : theme.backgroundColorBright,
              }}
            >
              <Text style={headerSectionStyle}>
                {checklistItemMessages.extraDescription}
              </Text>
              <GridContainer spacing={8}>
                <GridItem xs={12}>
                  <Text linksMode={"convert"}>
                    {checklistItem.extraDescription}
                  </Text>
                </GridItem>
              </GridContainer>
            </div>
          )}

          {["relevantIssues", "relevantDocumentations"].map((listName) => (
            <div
              id={listName}
              className={classes.cardSectionsStyles}
              style={{
                transition: "all 150ms ease 0s",
                backgroundColor:
                  selectedTabId == listName
                    ? theme.backgroundColorHover
                    : theme.backgroundColorBright,
              }}
            >
              <Text style={headerSectionStyle}>
                {checklistItemMessages[listName]}
              </Text>
              <GridContainer spacing={8}>
                {this.state[listName].length ? (
                  this.state[listName].map((p) => (
                    <GridItem
                      xs={12}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <MinPost
                        postHeight={null}
                        //itemsMode={!p.isIssue}
                        onSelect={this.onInnerObjectSelect}
                        post={p}
                        viewMode={"blocks"}
                        trades={trades}
                        minMode={true}
                      />
                    </GridItem>
                  ))
                ) : (
                  <GridItem xs={12}>
                    <Text>{checklistItemMessages[listName + "Empty"]}</Text>
                  </GridItem>
                )}
              </GridContainer>
            </div>
          ))}

          <div
            id={"timeline"}
            className={classes.cardSectionsStyles}
            style={{
              transition: "all 150ms ease 0s",
              backgroundColor:
                selectedTabId == "timeline"
                  ? theme.backgroundColorHover
                  : theme.backgroundColorBright,
            }}
          >
            <Text style={headerSectionStyle}>{issuesMessages.story.title}</Text>
            {story.length ? (
              story.map((s, storyIndex) => {
                let Title = s.title;
                let SubTitle = s.subTitle;
                let Content = s.content;
                let lastStory = storyIndex == story.length - 1;
                let StoryIcon = storyTypesImages[s.type] ? (
                  <img src={storyTypesImages[s.type]} />
                ) : (
                  <LabelImportantRounded />
                );

                return (
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        width: 25,
                        [rtl ? "marginRight" : "marginLeft"]: 8,
                        [rtl ? "borderRight" : "borderLeft"]: lastStory
                          ? ""
                          : theme.borderLineNeutral + "80",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          color: theme.brandPrimary,
                          position: "relative",
                          [rtl ? "right" : "left"]: -8,
                        }}
                      >
                        {StoryIcon}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ flexDirection: "column" }}>
                          <div
                            style={{
                              ...theme.defaultFont,
                              fontSize: 13,
                              lineHeight: "13px",
                              fontWeight: theme.strongBold,
                            }}
                          >
                            {Boolean(typeof Title === "object") ? Title : Title}
                          </div>
                          <div style={{ ...theme.subFont, fontSize: 12 }}>
                            {SubTitle}
                          </div>
                        </div>

                        {Boolean(viewer && viewer.adminMode == 1) && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              [rtl ? "paddingLeft" : "paddingRight"]: 5,
                            }}
                            onClick={() =>
                              deleteComment(
                                selectedProjectId,
                                "checklist",
                                instance.id,
                                s.id
                              )
                            }
                          >
                            <div
                              style={{
                                cursor: "pointer",
                                marginLeft: 5,
                                marginRight: 5,
                              }}
                            >
                              <img
                                style={{ width: 13, height: 14 }}
                                src={trash}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          ...theme.defaultFont,
                          fontWeight: theme.bold,
                          color: theme.brandNeutralDark,
                          marginTop: theme.paddingSize,
                          marginBottom: theme.paddingSize * 2,
                          flexDirection: "column",
                        }}
                      >
                        {Boolean(typeof Content === "object")
                          ? Content
                          : Content}
                        {Boolean(false && s.relevantPosts) && (
                          <GridContainer
                            spacing={16}
                            style={{
                              marginTop: Content ? theme.paddingSize / 3 : 0,
                            }}
                          >
                            {s.relevantPosts.map((post, i) =>
                              !Boolean(post) ? null : (
                                <GridItem key={post.id} xs={12}>
                                  <MinPost
                                    postHeight={null}
                                    //itemsMode={!post.isIssue}
                                    onSelect={this.onInnerObjectSelect}
                                    post={post}
                                    viewMode={"blocks"}
                                    trades={trades}
                                    minMode={true}
                                  />
                                </GridItem>
                              )
                            )}
                          </GridContainer>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <Text>{checklistItemMessages.timelineEmpty}</Text>
            )}
          </div>
        </div>
      </MenuScrollbar>
    );
  }
}

let styles = {
  postImageSize: 160,
  cardSectionsStyles: {
    padding: theme.paddingSize + "px " + 2 * theme.paddingSize + "px",
    boxShadow: "rgba(0, 0, 0, 0.08) 0px 1px 20px 0px",
    marginBottom: theme.paddingSize,
    display: "flex",
    flexDirection: "column",
  },
  p: {
    overflow: "hidden",
    fontSize: "0.8vw",
    textOverflow: "ellipsis",
    margin: "0px",
    whiteSpace: "wrap",
  },
  outlinedButtons: {
    fontSize: "10px",
    borderRadius: 0,
    color: theme.brandNeutral,
    backgroundColor: "transparent",
  },
};

ChecklistItemCard = injectIntl(ChecklistItemCard);
ChecklistItemCard = withStyles(styles)(ChecklistItemCard);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      rtl: state.app.rtl,
      trades: state.trades.map,
      comments: state.comments.map,
      checklistInstances: state.checklistItemsInstances.map,
    }),
    {
      endCommentsListener,
      getComments,
      startToast,
      createLocalComment,
      uploadNewPostComment,
      updateIssueState,
      updateIssueAssignTo,
      deleteComment,
    }
  )
);

export default enhance(ChecklistItemCard);
