import projectManagerMessages from '../../../../common/app/projectManagerMessages';
import webActions from '../../../webActions';
import { Section, Field } from './projectManagerConfig';

const { projectDetails, units } = projectManagerMessages.sections;
const { projectStructure } = projectManagerMessages.sideNav

const {
	projectImage,
	projectAddress,
	projectName,
  numberOfBuildings,
} = projectManagerMessages.inputLabels;

// Intermediate object for values coming from async functions
const specialValues = {

};

///////////////////////////////////////////////////////
/////////////////////// Fields ////////////////////////
///////////////////////////////////////////////////////

export const projectNameField    = new Field(projectName, 'String', ['newProjectDetails', 'title'], null, { fullWidth: true, prop: { mandatory: true } });

export const projectImageField   = new Field(projectImage, 'FilesDropzone', ['newProjectDetails', 'images', 'main'], null, { picsOnly: true });

export const projectAddressField = new Field(projectAddress, 'String', ['newProjectDetails', 'address']);

export const numberOfBuildingsField = new Field(numberOfBuildings, 'SelectionList', ['projectStructure', 'numOfBuildings'], Array.from(new Array(100)).map((_, index) => ({ id: ++index, title: index.toString() })), { autoSort: false, fullWidth: true, style: { flexBasis: '100%' }, placeholder: '-' });

export const importExportField = new Field(null, 'Excel', ['importedFile'], null, { settings: { importMethods: [{ id: 'fullMode' }] }});

///////////////////////////////////////////////////////
///////////////////// Sections ////////////////////////
///////////////////////////////////////////////////////

const projectDetailsSection = new Section(projectDetails, [
  projectNameField,
  projectAddressField,
  projectImageField,
]);

const projectStructureSection = new Section(projectStructure, [
  numberOfBuildingsField,
], { mainContentStyle: { display: 'flex', flexWrap: 'wrap' }});

const unitsSection = new Section(units, [
  importExportField,
]);

export default [
  projectDetailsSection,
  projectStructureSection,
  unitsSection,
];
