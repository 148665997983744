import ExtraError from '../lib/errors/extraError'
import { hideLoading } from '../app/actions';
import reportsMessages from '../reports/reportsMessages';
import _ from 'lodash';
import { onError } from '../app/funcs';
import { syncWithDB } from '../lastUpdates/actions';
import { platformActions } from '../platformActions';
import { endProjectListener, getRoundedDate, startProjectFirebaseListener } from '../lib/utils/utils';

export const GET_NEW_FORM_ID 		 				  = 'GET_NEW_FORM_ID';
export const GET_FORMS_BY_TYPE   		  = 'GET_FORMS_BY_TYPE';
export const END_FORMS_LISTENER_BY_TYPE   = 'END_FORMS_LISTENER_BY_TYPE';
export const START_FORMS_LISTENER_BY_TYPE   = 'START_FORMS_LISTENER_BY_TYPE';
export const ADD_NEW_FORM 		 		  			  = 'ADD_NEW_FORM'; 
export const DELETE_FORM 		 		  			    = 'DELETE_FORM'; 
export const CREATE_NEW_FORM_ID 		 		  = 'CREATE_NEW_FORM_ID'; 
export const SEND_FORM_VIA_MAIL 		 	 	    = 'SEND_FORM_VIA_MAIL';
export const GET_FORMS_AGGREGATED_VALUES = 'GET_FORMS_AGGREGATED_VALUES';

export function getNewFormId(projectId, formType = 'safety') {
	return ({ firebaseDatabase }) => {
		let id = firebaseDatabase().ref(`forms/${projectId}/full/${formType}`).push().key;
		return {
			type: CREATE_NEW_FORM_ID,
			payload: { id }
		};
	}
}

export function startFormsListenerByType(uid, projectId, formType, cleanAll) {	
	return ({ dispatch, firebaseDatabase, getState, lokiInstance }) => {
		try {
			formType = formType || 'general';
			let lastUpdateTS = 0;
		  	if (getState() && getState().getNested(['forms', 'lastUpdated', projectId, formType], 0))
					lastUpdateTS = getState().getNested(['forms', 'lastUpdated', projectId, formType]) + 1;
					
			firebaseDatabase().ref('forms/' + projectId + '/full/' + formType).orderByChild('updatedTS').startAt(lastUpdateTS).on('value', function(snapshot) {
				const forms = snapshot.val();
				if (platformActions.app.getPlatform() === 'web')
					saveToLoki(forms, projectId, lokiInstance, cleanAll);
				dispatch({ type: GET_FORMS_BY_TYPE, payload: { forms, viewerId: uid, projectId, formType, projectId } });
			});

			startProjectFirebaseListener(projectId, `forms/${projectId}/aggregated`, 'value', (aggregatedValues) => {
				dispatch({ type: GET_FORMS_AGGREGATED_VALUES, payload: { aggregatedValues, projectId } });
			});
		} catch (error) {
			throw new ExtraError('getFormsByType error', {uid, projectId}, error)     
		}

		return {
			type: START_FORMS_LISTENER_BY_TYPE,
			payload: { uid, projectId, formType, cleanAll }
		};
	};
}

export function endFormsListenerByType(projectId, formType) {	
  return ({ firebaseDatabase }) => {
	  firebaseDatabase().ref('forms/' + projectId + '/full/' + formType).off('value');
		endProjectListener(projectId, `forms/${projectId}/aggregated`);
    return {
      type: END_FORMS_LISTENER_BY_TYPE,
      payload: { projectId, formType }
    };
  };
}

export function sendFormViaMailServer(projectId, formId, formType, targetEmails, subject, text) {
	return ({ apiServer, platformActions }) => {
	  const getPromise = async () => {
			 	await platformActions.net.fetch(apiServer + "/v1/services/email/send/forms", { 
					method: 'POST',
					body: JSON.stringify({
						projectId, 
						formId, 
						formType, 
						targetEmails, 
						subject, 
						text, 
					})});

			  return { projectId, formId, formType, targetEmails, subject, text };
	  };
  
	  return {
		type: SEND_FORM_VIA_MAIL,
		payload: getPromise()
	  };
	};
}

export function upsertForm(projectId, viewer, form, type = "general", shouldAlertUser = true) {
	return ({ firebase, firebaseDatabase, removeEmpty, dispatch, getState }) => {
		const getPromise = async () => {
			const originalGenerator = getState().getNested(['forms', 'map', projectId, form.id, 'generator']);
			const generator = originalGenerator || { id: viewer.id, displayName: viewer.displayName };

			if (!type)
				throw new ExtraError('upsertForm error - form type missing', { form, type });

      let newForm = _.pick(form, [
				'certifications',
				'formTemplateId',
				'id',
				'title',
				'uri',
				'owner',
				'status',
				'checklists',
				'location',
				'universalIds',
				'posts',
				'signatures',
				'reportDate',
				'targetEmails',
				'type',
				'usersToNotify',
				'isDocx',
				'createdTS',
      ]);
      
			if (!newForm.type)
				newForm.type = type;
			
			if (!newForm.createdTS)
				newForm.createdTS = Date.now();

			if (!newForm.reportDate)
				newForm.reportDate = getRoundedDate().timestamp;


			
			if (newForm.location) { 
				const { unitId, buildingId, floorId } = newForm.location;
				const locationId = unitId || floorId || buildingId;

				const shouldSyncPosts = 							getState().getNested(['configurations', 'map', projectId, 'sync', 'beforePdfExport', 'posts'],  true);
				const shouldSyncChecklistInstances =  getState().getNested(['configurations', 'map', projectId, 'sync', 'beforePdfExport', 'checklistInstances'],  true);
				const shouldSyncPropertiesInstances = getState().getNested(['configurations', 'map', projectId, 'sync', 'beforePdfExport', 'propertiesInstances'], true)

				if (locationId) {
					let syncParam = {
						locationId,
						lastUpdateTS: Date.now(),
						onlyDiff: true,
						shouldUpload: true,
						log: false
					};

					shouldSyncPosts 							&& dispatch(syncWithDB('posts', viewer, projectId, syncParam));
					shouldSyncChecklistInstances  && dispatch(syncWithDB('checklistInstances', viewer, projectId, syncParam));
					shouldSyncPropertiesInstances && dispatch(syncWithDB('propertiesInstances', viewer, projectId, syncParam));
				}
			}


			newForm.id = newForm.id ? newForm.id : firebaseDatabase().ref('forms/' + projectId + '/full/' + type).push().key;
			newForm.updatedTS = Date.now();
			newForm.generator = generator;
			newForm = removeEmpty(newForm, 'upsertForm');

			const timeout = 45 * 1000;
			let promise = function (projectId, type, newForm) {
				return new Promise(async function (resolve, reject) {
					if (!projectId || !newForm)
						reject('Missing projectId or form');

					setTimeout(async () => {
						if (!didResolved) {
							didRejected = true;
							reject('Action canceled by timeout: Could not contact server in a reasonable amount of time');
						}
					}, timeout);

					let updates = {};
					updates['forms/' + projectId + '/full/' + type + '/' + newForm.id] = newForm;

					let didResolved = false;
					let didRejected = false;

					firebase.update(updates, () => {
						if (didRejected)
							return;

						didResolved = true;
						resolve(newForm);
					});
				});
			};
			try {
				await (promise(projectId, type, newForm));
			} catch (error) {
				onError({
					errorMessage: 'Failed to upsert form',
					error,
					alertParams: !shouldAlertUser ? null : {
						title: reportsMessages.exportErrors.title,
						message: reportsMessages.exportErrors.content,
					},
					methodMetaData: {
						name: 'upsertForm',
						args: { projectId, viewer, form, type },
					},
				});
				hideLoading();

				return { projectId, success: false };
			}
			return { projectId, reportId: newForm.id, form: newForm, success: true };
		};

		return {
			type: ADD_NEW_FORM,
			payload: getPromise()
		};
	};
}

export function deleteForm(projectId, form, type = "general") {
	return ({ firebase }) => {
	  const getPromise = async () => {
  
				let updates = {};

				updates['forms/' + projectId + '/full/' + type + '/' + form.id + '/updatedTS'] = Date.now();
			  updates['forms/' + projectId + '/full/' + type + '/' + form.id + '/isDeleted'] = true;
			  await firebase.update(updates);
  
			  return { projectId, reportId: form.id, form };
	  };
  
	  return {
		type: DELETE_FORM,
		payload: getPromise()
	  };
	};
}

function saveToLoki(forms = {}, projectId, lokiInstance, cleanAll) {
	if (!cleanAll && Object.keys(forms || {}).length == 0) return;
	
	forms = Object.values(forms).sort((formA, formB) => formA.updatedTS > formB.updatedTS ? -1 : 1);
		
	let allForms = [];
	let deletedFormsIds = [];
	forms.forEach(curr => {
		curr.isDeleted ? 
			deletedFormsIds.push(curr.id) : 
			allForms.push({...curr.realmToObject(), projectId })
	});
	if (cleanAll) { 
		lokiInstance.getCollection('forms').cementoDelete({ projectId });
	}
	if (deletedFormsIds.length > 0)
		lokiInstance.getCollection('forms').cementoDelete({ projectId, id: { '$in' : deletedFormsIds }});

	lokiInstance.getCollection('forms').cementoUpsert(allForms);
}