import { Record } from '../transit';

const Notification = Record({
  createdAt: null,
  id: '',
  postId: null,
  projectId: null,
  title: null,
  body: null,
  data: null,
  type: null,
  owner: null,
}, 'notification');

export default Notification;