import theme from '../app/theme';

export const ISSUE_STATE_CLOSED = 100;
export const ISSUE_STATE_RESOLVED = 200;
export const ISSUE_STATE_OPENED = 300;
export const POST_STATUS = 1;

export const POST_STATUS_COLORS = {
    [ISSUE_STATE_CLOSED]: theme.brandSuccess,
    [ISSUE_STATE_RESOLVED]: theme.brandWarning,
    [ISSUE_STATE_OPENED]: theme.brandDanger,
    [POST_STATUS]: theme.brandInfo
}

export function getPostColorByStatus(status) {
    return POST_STATUS_COLORS[status] || theme.brandInfo;
}
