
import { OrderedMap, Map } from 'immutable';
import { CementoRecordObject, Record } from '../transit';
import * as lastUpdatesActions from '../lastUpdates/actions';

import * as appActions from '../app/actions';
import * as actions from './actions';
import * as projectsAction from '../projects/actions';
import * as checklistActions from '../checklists/actions';
import { REPLACE_USER } from '../auth/actions';
import _ from 'lodash';

import ChecklistItemInstance from './checklistItemInstance';

const InitialState = Record({
  map: new CementoRecordObject,
  lastUpdateTS: new CementoRecordObject,
  didLoad: new CementoRecordObject,
  lastRevoked: new CementoRecordObject,
  lastRevokeAvailable: new CementoRecordObject,
  sync: new CementoRecordObject,
  lastSynced: new CementoRecordObject,
  isAdmin: false,
}, 'checklistItemsInstances', false);

const initialState = new InitialState;

export default function checklistItemsInstancesReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    
    case lastUpdatesActions.GET_LAST_UPDATES: {
      if (!action.payload || !action.payload.projects)
        return state;
    
      const { projects } = action.payload;
      Object.keys(projects).forEach(projectId => {
        let TS = projects.getNested([projectId,'checklists-itemsInstances','lastUpdateTS'], 1);
        let curr = state.getNested(['lastRevokeAvailable', projectId], null);
        if (curr < TS)
          state = state.setIn(['lastRevokeAvailable', projectId], TS);
      });
    
      return state;
    }

    case lastUpdatesActions.GET_SYNCED: {
      if (!action.payload || !action.payload.projects)
        return state;

      const { projects } = action.payload;
      _.forIn(projects, (objectTypes, projectId) => {
        let syncOptions = _.get(objectTypes, ['checklistInstances'], {})
        let TS = syncOptions.lastUpdateTS || 1;
        let curr = state.getNested(['sync', projectId, 'lastUpdateTS'], null)
        if (curr < TS) {
          state = state.setIn(['sync', projectId], syncOptions);
        }
      })

      return state;
    }
    
    case lastUpdatesActions.SYNCED + "_CHECKLIST_INSTANCES_SUCCESS": {
      if (!action.payload || !action.payload.projectId || !action.payload.lastSynced)
        return state;

      const { projectId, lastSynced } = action.payload;
      state = state.setIn(['lastSynced', projectId], lastSynced);
      return state;
    }


    case projectsAction.LOKI_DID_LOAD: {
      if (action.getNested(['payload','loadedCollections','checklistItemsInstances']))
        state = state.setIn(['didLoad', action.getNested(['payload','projectId'])], action.getNested(['payload','status']));
      return state;
    }

    case actions.FETCH_CHECKLIST_ITEM_INSTANCES:
      return state.setIn(['didLoad', action.getNested(['payload', 'projectId'])], false);

    case actions.CHECKLIST_ITEM_INSTANCES_DONE_LOADING: {
      if (!action.payload || !action.payload.scopeId)
        return state;
      
      const { scopeId } = action.payload;
      state = state.setIn(['didLoad', scopeId], true);
      state = state.setIn(['lastRevoked', scopeId], state.getNested(['lastRevokeAvailable', scopeId]) || state.getNested(['lastRevoked', scopeId]) || 1); 
      return state;
    }

    case actions.GET_CHECKLIST_ITEM_INSTANCES: {
      if (!action.payload || !action.payload.checklistItemsInstances)
        return state; 
      
      const { checklistItemsInstances, projectId, cleanAll } = action.payload;
      if (cleanAll)
        state = state.setIn(['map', projectId], new CementoRecordObject);
      
      //let maxUpdateTS = 0;
      checklistItemsInstances && checklistItemsInstances.loopEach((index, currCliInstance) => {
        var cliInstance = new ChecklistItemInstance(currCliInstance.realmToObject());
        var instancesOrderedMap = state.getIn(['map', projectId, cliInstance.checklistItemId, cliInstance.locationId]);
        if (!instancesOrderedMap) 
          instancesOrderedMap = new CementoRecordObject;
        
        if (cliInstance.isDeleted)
          instancesOrderedMap = instancesOrderedMap.delete(cliInstance.id);
        else 
          instancesOrderedMap = instancesOrderedMap.set(cliInstance.id, cliInstance);
        
        // if (maxUpdateTS < cliInstance.updatedTS)
        //   maxUpdateTS = cliInstance.updatedTS;

        state = state.setIn(['map', projectId, cliInstance.checklistItemId, cliInstance.locationId], instancesOrderedMap);
      });

   //   state = state.setIn(['lastUpdateTS', projectId], maxUpdateTS);
      return state;
    }

    case actions.END_CHECKLIST_ITEM_INSTANCES_LISTENER: {
      if (action.payload && action.payload.projectId)
        state = state.setIn(['didLoad', action.payload.projectId], false);
      return state;
    }

    case appActions.CLEAN_DYNAMIC_CACHE_DATA + "_SUCCESS":
    case REPLACE_USER + "_SUCCESS":
    case actions.CLEAN_CACHED_ITEMS_INSTANCES:
    case checklistActions.CLEAN_ALL_CACHED_CHECKLIST_ITEMS_AND_INSTANCES+ "_SUCCESS":
    case actions.CLEAN_CHECKLIST_ITEMS_INSTANCES_CACHE_DATA + "_SUCCESS":
    case appActions.CLEAN_CACHE + "_SUCCESS": { 
      //let lastRevokeAvailable = state.getNested(['lastRevokeAvailable'], {});  
      //let lastRevoked = state.getNested(['lastRevoked'], {});
      state = initialState;
      //lastRevoked.loopEach((projectId, last) => last ? state = state.setIn(['lastRevokeAvailable', projectId], last + 1) : 0)
      //lastRevokeAvailable.loopEach((projectId, last) => last ? state = state.setIn(['lastRevokeAvailable', projectId], last + 1) : 0)
      return state;
    }
  }

  return state;
}


