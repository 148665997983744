import _ from 'lodash';
import { PROJECT_TYPE_COMPLEX_BUILDINGS } from '../projects/actions';
import issuesMessages from '../issues/issuesMessages';
import locationsMessages from './locationsMessages';
import { getAppState } from '../configureMiddleware';
import postsMessages from '../posts/postsMessages';
import floorsMessages from '../floors/floorsMessages';
import { ALL_BUILDINGS_ID } from '../app/constants';
import systemMessages from '../app/systemMessages';
import unitsMessages from '../units/unitsMessages';
import buildingsMessages from '../buildings/buildingsMessages';

export function getLocationTitle(props, emptyValue, separator = ", ") {
  const { location, buildings, floors, units, intl, project, showOnlyLastInfoPart } = props;
  const projectId = project ? project.id : null;
  let ret = "";
  if (location) {
    const buildingId = location.getNested(["building", "id"]);
    const floorId = location.getNested(["floor", "id"]);
    const unitId = location.getNested(["unit", "id"]);
    const buildingNumberByBuildingId = _.keys(buildings.getNested(projectId)).reduce((acc, bId, i) => (Object.assign(acc, { [bId]: i + 1 })), {});
    const building = buildings.getNested([projectId, buildingId]);
    const floor = floors.getNested([projectId, buildingId, floorId]);
    const unit = units.getNested([projectId, buildingId, unitId]);
    
    if (buildingId === ALL_BUILDINGS_ID)
      ret = intl.formatMessage(postsMessages.allProject);
    else {
      let text = "";
      if (building && ((project && project.type == PROJECT_TYPE_COMPLEX_BUILDINGS) || (!floor && !unit)))
        text += building.title || intl.formatMessage(locationsMessages.buildingNumber, { number: buildingNumberByBuildingId[buildingId] });
      if (floor) {
        if (text != "")
          text += separator;
        let addition = floor.description || intl.formatMessage(issuesMessages.floorNumber, {floorNumber: String(floor.num)});
        text = showOnlyLastInfoPart ? addition : (text + addition);
      }
  
      if (unit) {
        if (text != "")
          text +=separator;
        let addition = isNaN(unit.title) ? unit.title : intl.formatMessage(issuesMessages.unitNumber, {number: unit.title});
        text = showOnlyLastInfoPart ? addition : (text + addition);
      }

      ret = text;
    }
  } else if (emptyValue) {
      ret = emptyValue;
  }

  return ret;
}

/**
 * 
 * @param {{
 *  intl: any,
 *  locationObj: { buildingId: string, floorId?: string, unitId?: string },
 *  emptyValue?: string,
 *  separator?: string
 * }} param0 
 * @returns 
 */
export const getLocationTitleNoProps = ({ intl, locationObj = {}, emptyValue = null, separator = ', ' } = {}) => {
  if (!intl)
    return null;

  const appState = getAppState();
  const buildings = appState.getNested(['buildings', 'map']);
  const floors = appState.getNested(['floors', 'map']);
  const units = appState.getNested(['units', 'map']);
  const selectedProjectId = appState.getNested(['ui', 'currProject']);
  const project = appState.getNested(['projects', 'map', selectedProjectId], {});

  return getLocationTitle({
    intl, buildings, floors, units,
    project, 
    location: {
      building: { id: locationObj.buildingId },
      floor: { id: locationObj.floorId },
      unit: { id: locationObj.unitId },
    },
  }, emptyValue, separator);
}

export const getFullLocationDetailsByIdNoProps = (targetLocationId) => {
  const appState = getAppState();
  const selectedProjectId = appState.getNested(['ui', 'currProject']);
  const projectBuildings = appState.getNested(['buildings', 'map', selectedProjectId]);
  const projectFloors = appState.getNested(['floors', 'map', selectedProjectId]);
  const projectUnits = appState.getNested(['units', 'map', selectedProjectId]);

  return getFullLocationDetailsById(projectBuildings, projectFloors, projectUnits, targetLocationId);
}

export function getFullLocationDetailsById(projectBuildings, projectFloors, projectUnits, targetLocationId) {
  const locationObjects = {
    building: {},
    floor: {},
    unit: {},
  };

  const locationIds = {
    buildingId: null,
    floorId: null,
    unitId: null,
  }
  
  let locationType = null;
  let locationFound = false;

  const onLocationFound = ({ building, buildingId, floor, floorId, unit, unitId, type }) => {
    if (building) locationObjects.building = building.toJS ? building.toJS() : building;
    if (floor) locationObjects.floor = floor.toJS ? floor.toJS() : floor;
    if (unit) locationObjects.unit = unit.toJS ? unit.toJS() : unit;
    if (buildingId) locationIds.buildingId = buildingId;
    if (floorId) locationIds.floorId = floorId;
    if (unitId) locationIds.unitId = unitId;
    if (type) locationType = type;
    locationFound = true;
  }

  if (targetLocationId === ALL_BUILDINGS_ID)
    onLocationFound({ buildingId: ALL_BUILDINGS_ID, type: 'project' });
  else {
    // Start searching
    projectBuildings?.loopEach?.((bId, currBuilding) => {
      if (locationFound)
        return;
  
      const currBuildingId = currBuilding.getNested(['id']);
      if (targetLocationId === currBuildingId) {
        onLocationFound({ 
          building: currBuilding,
          buildingId: currBuildingId,
          type: 'building',
        });
        return;
      }
  
      let foundInFloors = projectFloors.getNested([currBuildingId, targetLocationId], null);
      let foundInUnits = projectUnits.getNested([currBuildingId, targetLocationId], null);
  
      if (foundInFloors) {
        onLocationFound({
          floorId: targetLocationId,
          floor: foundInFloors,
          building: currBuilding,
          buildingId: currBuildingId,
          type: 'floor',
        });
        return;
      }
  
      if (foundInUnits) {
        onLocationFound({ 
          building: currBuilding,
          buildingId: currBuildingId,
          unit: foundInUnits,
          unitId: targetLocationId,
          type: 'unit',
        });
        
        let floorFound = false;
        projectFloors.getNested([currBuildingId], {}).loopEach((fId, currFloor) => {
          if (floorFound) 
            return;
  
          if (currFloor.getNested(['num']) === locationObjects.getNested(['unit', 'floor', 'num'])) {
            onLocationFound({
              floor: currFloor,
              floorId: currFloor.getNested(['id']),
            });
            floorFound = true;
          }
        });
        return;
      }
    });
  }

  return { locationObjects, locationIds, locationFound, locationType };
}

export function getFullLocationByPropValue(projectBuildings, projectFloors, projectUnits, targetProperties, optimizers = { inBuildingId: '', skipBuildings: false, skipFloorsInUnits: false, skipFloors: false, skipUnits: false }) {

  let locationObjects = {
    building: null,
    floor: null,
    unit: null,
  };
  let locationIds = {
    buildingId: null,
    floorId: null,
    unitId: null,
  };
  let locationType = null;
  let locationFound = false;

  const setLocationData = ({ building, floor, unit, type }) => {
    if (unit) {
      locationObjects.unit = unit;
      locationIds.unitId = unit.getNested(['id']);
    }

    if (floor) {
      locationObjects.floor = floor;
      locationIds.floorId = floor.getNested(['id']);
    }

    if (building) {
      locationObjects.building = building;
      locationIds.buildingId = building.getNested(['id']);
    }

    if (type) locationType = type;

    locationFound = true;
  }

  if (targetProperties) {
    const { inBuildingId, skipBuildings, skipFloors, skipFloorsInUnits, skipUnits } = optimizers;
    if (inBuildingId)
      projectBuildings = { [inBuildingId]: projectBuildings.getNested([inBuildingId], {}) };

    // Start searching
    projectBuildings.loopEach((bId, building) => {
      if (locationFound)
        return;

      if (!skipBuildings)
        if (_.isMatch(building.toJS ? building.toJS() : building, targetProperties)) {
          setLocationData({
            building,
            type: 'building',
          });
          return;
        }
  
      
      const currBuildingId = building.id;
      if (!skipUnits)
        projectUnits.getNested([currBuildingId], {}).loopEach((uId, unit) => {
          if (locationFound || !_.isMatch(unit.toJS ? unit.toJS() : unit, targetProperties))
            return;
    
          setLocationData({
            building,
            unit,
            type: 'unit',
          });
          
          if (!skipFloorsInUnits) {
            let floorFound = false;
            projectFloors.getNested([currBuildingId], {}).loopEach((fId, floor) => {
              if (floorFound || floor.num !== (unit.floor || {}).num)
                return;
      
              setLocationData({ floor });
              floorFound = true;
            });
          }
        });
  
      if (locationFound || skipFloors)
        return;
  
      projectFloors.getNested([currBuildingId], {}).loopEach((fId, floor) => {
        if (locationFound || !_.isMatch(floor.toJS ? floor.toJS() : floor, targetProperties))
          return;
  
        setLocationData({
          building,
          floor,
          type: 'floor',
        });
      });

    });
  }

  return { locationObjects, locationIds, locationFound, locationType };
}

/**
 * 
 * @param {any} projectBuildings 
 * @param {any} projectFloors 
 * @param {any} projectUnits 
 * @returns {{ numOfBuildings: number, [buildingId: string]: { numOfFloors: number, numOfUnitsPerFloor: { [key: number]: number } }}}
 */
 export const getLocationsStats = (projectBuildings, projectFloors, projectUnits) => {
  let locationsStats = {};

  locationsStats.numOfBuildings = (projectBuildings.toJS ? projectBuildings.getNested(['size']) : Object.keys(projectBuildings).length);
  
  projectBuildings.loopEach((buildingId, building) => {
    let currBuildingFloors = projectFloors.getNested([buildingId], {});
    let currBuildingNumOfFloors = (currBuildingFloors.toJS ? currBuildingFloors.getNested(['size']) : Object.keys(currBuildingFloors).length);

    locationsStats = _.set(locationsStats, [buildingId, 'numOfFloors'], currBuildingNumOfFloors);
  });

  projectUnits.loopEach((buildingId, units) => {
    units.loopEach((unitId, unit) => {
      let unitFloor = unit.getNested(['floor', 'num']);

      locationsStats = _.set(locationsStats, [buildingId, 'numOfUnitsPerFloor', unitFloor], _.get(locationsStats, [buildingId, 'numOfUnitsPerFloor', unitFloor], 0) + 1);
    });
  });

  return locationsStats;
}

/**
 * 
 * @param {any} projectBuildings 
 * @param {any} projectFloors 
 * @param {any} projectUnits 
 * @param {string[]} locationIds 
 * @returns {{
 *  [buildingId: string]: {
 *    title: string,
 *    floors?: {
 *      [floorNum: number]: {
 *        title: string,
 *        units?: string[]
 *      }
 *    }
 *  }
 * }}
 */
export const getOrganizedLocationTitles = (intl, projectBuildings, projectFloors, projectUnits, locationIds) => {
  let locationsFullDetails = {};
  let buildingCount = 0;
  locationIds.forEach(locationId => {
    const { locationObjects, locationFound } = getFullLocationByPropValue(projectBuildings, projectFloors, projectUnits, { id: locationId });
    
    if (!locationFound) return;

    const { building, floor, unit } = locationObjects;
  
    const currBuildingName = building.getCementoTitle();
    if (_.get(locationsFullDetails, [building.id, 'title'], null) === null)
      _.set(locationsFullDetails, [building.id, 'title'], currBuildingName ? currBuildingName : intl.formatMessage(buildingsMessages.building, { counter: ++buildingCount }));
    
    if (floor) {
      _.set(locationsFullDetails, [building.id, 'floors', floor.num, 'title'], floor.description ? floor.description : floor.num);
  
      if (unit) {
        let currUnitName = unit.getCementoTitle();
        _.set(locationsFullDetails, [building.id, 'floors', floor.num, 'units'], _.get(locationsFullDetails, [building.id, 'floors', floor.num, 'units'], []).concat(currUnitName));
      }
    }
  });

  return locationsFullDetails;
}

/**
 * 
 * @param {string[]} locationIds 
 * @param {any} intl 
 * @returns {({ titleRoot: string, titles: string[] })[]}
 */
export const getLocationTitlesParams = (intl, projectBuildings, projectFloors, projectUnits, locationIds, rootLocationSeparator = ', ') => {
  const organizedLocationTitles = getOrganizedLocationTitles(intl, projectBuildings, projectFloors, projectUnits, locationIds);
  const locationsStats = getLocationsStats(projectBuildings, projectFloors, projectUnits);
  const moreThanOneBuilding = locationsStats.numOfBuildings > 1;
  
  let locationsTitlesParams = [];

  Object.entries(organizedLocationTitles).forEach(([buildingId, buildingTitles]) => {
    const { numOfFloors, numOfUnitsPerFloor } = locationsStats[buildingId];
    const { title: buildingName, floors } = buildingTitles;

    if (!floors) { // The selected location is a building
      locationsTitlesParams.push({ titleRoot: buildingName, titles: [] });
      return;
    }

    let floorTitlesWithNoUnits = [];
    Object.entries(floors).forEach(([floorNum, floor]) => {
      if (!floor)
        debugger;
      const { title: floorTitle, units } = floor;

      if (units && units.length) {
        let titleRoot = '';

        if (moreThanOneBuilding)
          titleRoot = `${buildingName}${rootLocationSeparator}`;
        
        if (typeof(floorTitle) === 'string')
          titleRoot += floorTitle;
        else {
          titleRoot += `${intl.formatMessage(floorsMessages.floor)} `;
          titleRoot += intl.formatMessage(systemMessages.ordinalNumber, { number: String(floorTitle) });
        }
        
        const unitTitles = units.length === numOfUnitsPerFloor[floorNum]
          ? [ intl.formatMessage(unitsMessages.allUnits) ]
          : units;
        
        locationsTitlesParams.push({ titleRoot, titles: unitTitles });
      } else
        floorTitlesWithNoUnits.push(floorTitle);
    });
    
    if (floorTitlesWithNoUnits.length) { // The selected locations were the floors
      floorTitlesWithNoUnits.sort((a, b) => typeof(a) === 'string' ? true : a > b);
      
      let titleRoot = '';
      if (moreThanOneBuilding)
        titleRoot +=  `${buildingName}${rootLocationSeparator}`;

      titleRoot += intl.formatMessage(floorsMessages.floor);

      let floorTitles = [];
      if (floorTitlesWithNoUnits.length === numOfFloors)
        floorTitles.push(intl.formatMessage(floorsMessages.allFloors));
      else
        floorTitles = floorTitlesWithNoUnits.map(floorTitle => {
          if (typeof(floorTitle) === 'string')
            return floorTitle;
          else
            return intl.formatMessage(systemMessages.ordinalNumber, { number: String(floorTitle) });
        });
    
      locationsTitlesParams.push({ titleRoot, titles: floorTitles });
    }
  });

  return locationsTitlesParams;
}