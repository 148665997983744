import unitsMessages from '../../../common/units/unitsMessages';
import issuesMessages from '../../../common/issues/issuesMessages';
import systemMessages from '../../../common/app/systemMessages';
import locationsMessages from '../../../common/locations/locationsMessages'
import { getAppState } from '../../../common/configureMiddleware';
import webActions from '../../webActions';

import _ from 'lodash';
import { startToast } from '../../../common/app/actions';


// //export function getLocationTitle(projectBuildings, projectFloors, projectUnits, buildingId, floorId, unitId, specificLocationId, intl, hideBuildingTitleOnOneBuilding) {
// export function getLocationTitle(null, null, null, buildingId, floorId, unitId, specificLocationId, intl, hideBuildingTitleOnOneBuilding) {
//   if (!getAppState())
//     return "";

//   return legacyGetLocationTitle(getAppState().getNested(['buildings', 'map']), projectFloors, projectUnits, buildingId, floorId, unitId, specificLocationId, intl, hideBuildingTitleOnOneBuilding);
// }

export function getLocationTitle(projectBuildings, projectFloors, projectUnits, buildingId, floorId, unitId, specificLocationId, intl, hideBuildingTitleOnOneBuilding) {
  let titles = {};

  if (specificLocationId) {
    let location = getFullLocationByLocationId(projectBuildings, projectFloors, projectUnits, specificLocationId);
    buildingId   = location.getNested(['building','id']);
    floorId      = location.getNested(['floor','id']);
    unitId       = location.getNested(['unit','id']);
  }

  let wantedLocationIsBuilding = buildingId != '_' && ((!floorId || floorId == '_') && (!unitId || unitId == '_'))
  if (projectBuildings && buildingId && buildingId != '_' && (wantedLocationIsBuilding || !hideBuildingTitleOnOneBuilding)) {
    titles.buildingTitle = buildingId == 'all' ? intl.formatMessage(systemMessages.allProject) : projectBuildings.getNested([buildingId, 'title'], '');
    if (!titles.buildingTitle) {
      let buildingsArr = projectBuildings
      buildingsArr = buildingsArr.valueSeq ? buildingsArr.valueSeq().toArray() : Object.values(buildingsArr || {})
      buildingsArr.map((curr, index) => { if (curr.id == buildingId) titles.buildingTitle = curr.title || intl.formatMessage(locationsMessages.buildingNumber, { number: index+1 }); });
    }
  }
  if (projectFloors && floorId && floorId != '_')   { 
    titles.floorTitle = projectFloors.getNested([buildingId, floorId, 'description']) 
    if (projectFloors.getNested([buildingId, floorId, 'type']) == "roof" && !titles.floorTitle) titles.floorTitle = intl.formatMessage(unitsMessages.roof);
    if (!titles.floorTitle) titles.floorTitle = intl.formatMessage(issuesMessages.floorNumber, {floorNumber: projectFloors.getNested([buildingId, floorId, 'num']) }) 
  }
  if (projectUnits && unitId && unitId != '_')      
    titles.unitTitle = projectUnits.getNested([buildingId, unitId, 'title'], '');

  return titles;
}

export function getFullLocationByLocationId(projectBuildings, projectFloors, projectUnits, specificLocationId) {
  let buildingId = null;
  let floorId = null;
  let unitId = null;

  if (specificLocationId) {
    let wasFoundInBuildings = projectBuildings.getNested([specificLocationId]);    
    if (wasFoundInBuildings)
      buildingId = specificLocationId;
    else {
      let wasFoundInFloors;
      let floorsNumMap = {};
      projectFloors.loopEach((bid, curr) => {
        floorsNumMap[bid] = {}
        if (!wasFoundInFloors)
          curr.loopEach((fid, floor) => {
            floorsNumMap[bid][floor.num] = fid;
            if (fid == specificLocationId) {
              wasFoundInFloors = true;
              floorId = specificLocationId;
              buildingId = bid;
            }
          })
      });

      if (!wasFoundInFloors) {
        projectUnits.loopEach((bid, curr) => {
          if (!unitId)
            curr.loopEach((uid, unit) => {
              if (uid == specificLocationId) {
                unitId = uid;
                buildingId = bid;
                floorId = floorsNumMap[bid][unit.floor.num];
              }
            })
        });
      }
    }
  }

    let newLocation = {};
    if (buildingId) newLocation = newLocation.setNested(['building', 'id'], buildingId);
    if (floorId) newLocation = newLocation.setNested(['floor', 'id'], floorId);
    if (unitId) newLocation = newLocation.setNested(['unit', 'id'], unitId);
    return newLocation;
}


let formatMessage = function(intl, message, values) {
  //return new IntlMessageFormat(message, lang).format(values);
  return intl.formatMessage(message, values);
}

export function getAllLocationTitlesMap(buildings, floors, units, intl) {
  let titlesMap = {};
  let bIndex = 0;
  (buildings || {}).loopEach((bid, building) => {
    let buildingOrdinalNo = (bIndex+1)*1000000;
    let buildingTitle = buildings.getNested([bid,'title']) || formatMessage(intl, locationsMessages.buildingNumber, {number:(bIndex+1)} ); 
    titlesMap[bid] = { type: 'buildings', ordinalNo: buildingOrdinalNo, buildingId: bid, buildingTitle: buildingTitle, data: buildings.getNested([bid]) };
    let numMapToFloor = {};
    floors.getNested([bid], {}).loopEach(fid => {
      let currFloor = floors.getNested([bid, fid]);
      let floorTitle = currFloor.description;
      let floorNum = currFloor.num;
      if (!floorTitle && currFloor.type == "roof") floorTitle = formatMessage(intl, locationsMessages.roof);
      else if (!floorTitle) floorTitle = formatMessage(intl, locationsMessages.floorNumber, {number:(floorNum)}); 
      let fObj = { type: 'floors', ordinalNo:0, buildingId: bid, buildingTitle: buildingTitle, floorId: fid, floorTitle: floorTitle, data: currFloor };
      numMapToFloor[floorNum] = fObj;
      titlesMap[fid] = fObj;
    })
    Object.keys(numMapToFloor).sort((a,b) => Number(a) - Number(b)).forEach((k, fIndex) => numMapToFloor[k].ordinalNo = buildingOrdinalNo+((numMapToFloor[k].ordinalNo || fIndex+1)*1000));
    let uIndex = 0;
    units.getNested([bid], {}).loopEach((uid, unit) => {
      let currUnit = units.getNested([bid, uid])
      let unitTitle = currUnit.title ? currUnit.title : uIndex + 1;
          unitTitle = /* isNaN(Number(unitTitle)) ? */ unitTitle //: formatMessage(intl, locationsMessages.unitNumber, { number: unitTitle })
      let floorObj = numMapToFloor[currUnit.floor.num];
      if (floorObj)
        titlesMap[uid] = { type: 'units', ordinalNo:floorObj.ordinalNo+(unit.ordinalNo || uIndex+1), buildingId: bid, buildingTitle: buildingTitle, floorId: floorObj.floorId, floorTitle: floorObj.floorTitle, unitId: uid, unitTitle: unitTitle, data: currUnit };
      else // Just to inform us
        webActions.sentry.notify('ERROR: Unit on floor that does not exist', { floorNum: currUnit.floor.num, unitId: currUnit.id, unitTitle, buildingTitle, buildingId: bid });
      uIndex++;
    });
    bIndex++;
  })

  return titlesMap;
}

export function setFlatLocationsTitlesMap(buildings, floors, units, intl) {
  let locations = { buildings: {}, floors: {}, units: {}};
    let countOfBuildings = 0;
    let buildingIndex  = 1;
    (buildings || {}).loopEach((bid, b,) => { 
      let currTitle = (b.title || `building ${buildingIndex}`);
      countOfBuildings++;
      locations.buildings[bid] = {
        id: bid, 
        exportTitle: currTitle,
        title: currTitle
      }
      buildingIndex++;
    });
    (floors || {}).loopEach((bid, b) => { b.loopEach((fid, f) => { 
      let currTitle = (f.description || intl.formatMessage(issuesMessages.floorNumber, {floorNumber: String(f.num)}));
      locations.floors[fid] = {  
        id: fid, 
        exportTitle: countOfBuildings > 1 ? `${locations.buildings[bid].title}@${currTitle}` : currTitle, 
        title: locations.buildings[bid].title + ' \\ ' + currTitle 
      }
    })});
    (units || {}).loopEach((bid, b) => { b.loopEach((uid, u) => { 
      let currTitle = u.title;
      locations.units[uid] = {
        id: uid,
        exportTitle: countOfBuildings > 1 ?  `${locations.buildings[bid].title}@${currTitle}` : currTitle,
        title: locations.buildings[bid].title + ' \\ ' + intl.formatMessage(issuesMessages.floorNumber, {floorNumber: String(u.floor.num)}) + ' \\ ' + currTitle
      }
    })});
  
    return locations;
}

let didAlerted = {};
export const getLocationsTitlesMap2 = _.memoize((projectBuildings, projectFloors, projectUnits, intl) => {
  let titlesMap = {};
  let flatMapById = {};
  let flatMapByExportTitle = {};
  const buildingsArr = _.values(projectBuildings);
  const isSingleBuilding = buildingsArr.length === 1;
  (buildingsArr).forEach((building, buildingIndex) => {
    const buildingTitle = (building.title || `building ${buildingIndex + 1}`);
    let currBuilding = {
      id: building.id,
      original: building,
      title: buildingTitle,
      fullExportTitle: buildingTitle,
      floors: {},
      get numOfFloors() { return Object.keys(this.floors || {}).length },
      units: {},
      get numOfUnits() { return Object.keys(this.units || {}).length },
      type: 'building',
    }

    titlesMap[currBuilding.id] = currBuilding;
    flatMapByExportTitle[currBuilding.fullExportTitle] = currBuilding;
    flatMapById[currBuilding.id] = currBuilding;

    let floorsByFloorNum = {}
    _.values(_.get(projectFloors, [currBuilding.id])).forEach(floor => {
      const floorTitle = (floor.description || intl.formatMessage(issuesMessages.floorNumber, { floorNumber: String(floor.num) }));
      let currFloor = {
        id: floor.id,
        original: floor,
        building: currBuilding,
        title: floorTitle,
        fullExportTitle: isSingleBuilding ? floorTitle : `${currBuilding.title}@${floorTitle}`,
        units: {},
        get numOfUnits() { return Object.keys(this.units || {}).length },
        type: 'floor',
      }

      floorsByFloorNum[floor.num] = currFloor;
      currBuilding.floors[currFloor.id] = currFloor;
      flatMapByExportTitle[currFloor.fullExportTitle] = currFloor;
      flatMapById[currFloor.id] = currFloor;
    });

    _.values(_.get(projectUnits, [currBuilding.id])).forEach(unit => {
      const floorNumber = _.get(unit, ['floor', 'num']);
      const unitFloor = floorsByFloorNum[floorNumber];
      if (!unitFloor && (Date.now() - (didAlerted[unitFloor] ||0)) > 3000) {
        alert(`Warning - Unit was not loaded! The floor of the unit is not found: unit name: ${unit.title} floor: ${unit.floor.num} building: ${building.title}. `)
        return;
      }

      const unitTitle = unit.title;
      let currUnit = {
        id: unit.id,
        original: unit,
        building: currBuilding,
        floor: unitFloor,
        fullExportTitle: `${unitFloor.fullExportTitle}@${unitTitle}`,
        title: unitTitle,
        type: 'unit',
      }

      unitFloor.units[currUnit.id] = currUnit;
      currBuilding.units[currUnit.id] = currUnit;
      flatMapByExportTitle[currUnit.fullExportTitle] = currUnit;
      flatMapById[currUnit.id] = currUnit;
    });
  });

  return { titlesMap, flatMapById, flatMapByExportTitle };
});

const ALL_BUILDINGS_NAME_IDENTIFIER = 'ALL';
const escapeRegExSpecialCharacters = (/** @type {string} */ text) => (typeof text === 'string' ? text : '').replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
export const getWantedLocations = ({ locationsString, type, projectBuildings, projectFloors, projectUnits, flatMapByExportTitle, intl }) => {
  if (!flatMapByExportTitle)
    flatMapByExportTitle = getLocationsTitlesMap2(projectBuildings, projectFloors, projectUnits, intl).flatMapByExportTitle;

  let wantedLocations = {};
  let missingLocations = {};

  if (locationsString && type) {
    const locationStringsArr = locationsString.split(';');// 'building 1,building 2@Floor 3@unit 1;'building 1,building 2@Floor 4@unit 2''
    
    (locationStringsArr).forEach(locationString => {
      let splitLocString = locationString.split('@'); // building 1, building 2@Floor 3@unit1

      let exportTitlesToGet = [];
      splitLocString.forEach((locsIdentifierStr, index) => { 
        const splitLocIdentifier = locsIdentifierStr.split(','); // 'building 1,building 2'
        if (index === 0) // Root location
          exportTitlesToGet = splitLocIdentifier.map(locIdentifierStr => locIdentifierStr === ALL_BUILDINGS_NAME_IDENTIFIER ? '.*' : locIdentifierStr);
        else
          exportTitlesToGet = exportTitlesToGet.reduce((acc, locStr) => {
            splitLocIdentifier.forEach(locIdentifierStr => acc.push(`${locStr}@${locIdentifierStr === ALL_BUILDINGS_NAME_IDENTIFIER ? '.*' : locIdentifierStr}`));
            return acc;
          }, []);
          // splitLocIdentifier.forEach(locIdentifierStr => exportTitlesToGet = exportTitlesToGet.map(locStr => `${locStr}@${locIdentifierStr === ALL_BUILDINGS_NAME_IDENTIFIER ? '.*' : locIdentifierStr}`)) // [buildin 1, building 2] => 'building 1@floor'
      });

      // Search and destroy
      let flatMapByExportTitleKeys = Object.keys(flatMapByExportTitle);
      (exportTitlesToGet).forEach(exportTitleToGet => {
        const regexStr = `(@|^)${escapeRegExSpecialCharacters(exportTitleToGet)}(@|$)`;
        const regex = new RegExp(regexStr);
        let wasMatched = false;

        (flatMapByExportTitleKeys).forEach(exportTitle => {
          if (!regex.test(exportTitle)) 
            return;

          if (!wasMatched)
            wasMatched = true;

          const locationInfo = flatMapByExportTitle[exportTitle];
          
          if (!locationInfo)
            missingLocations[exportTitle] = true;
          else if (locationInfo.type === type)
            wantedLocations[locationInfo.id] = locationInfo.original;
          else if ((locationInfo.type === 'building' || locationInfo.type === 'floor') && locationInfo[`${type}s`])
            Object.values(locationInfo[`${type}s`]).forEach(unitInfo => wantedLocations[unitInfo.id] = unitInfo.original);
        });

        if (!wasMatched)
          missingLocations[exportTitlesToGet] = true;
      });
    });
  }

  return { wantedLocations, missingLocations };
}
