import { defineMessages } from 'react-intl';

export default defineMessages({
  pleaseCreateUser: {
    defaultMessage: 'You don\'t have a user yet. Please register through Cemento App',
    id: 'auth.signin.pleaseCreateUser',
  },
  enterPhoneNumber: {
    defaultMessage: 'Please enter your phone number to sign in',
    id: 'auth.signin.enterPhoneNumber',
  },
  phoneNumberPlaceholder: {
    defaultMessage: 'Phone number',
    id: 'auth.signin.phoneNumberPlaceholder',
  },
  enterVerification: {
    defaultMessage: 'Enter the verification code you received in text message',
    id: 'auth.signin.enterVerification',
  },
  verificationPlaceholder: {
    defaultMessage: 'Verification code',
    id: 'auth.signin.verificationPlaceholder',
  },
  goodbye: {
    defaultMessage: 'Goodbye',
    id: 'auth.signin.goodbye',
  },
  verificationCodeNotValid: {
    defaultMessage: 'Verification code not valid',
    id: 'auth.signin.verificationCodeNotValid',
  },
  phoneNumberNotValid: {
    defaultMessage: 'Phone Number is not valid',
    id: 'auth.signin.phoneNumberNotValid',
  },
  loading_0: {
    defaultMessage: 'Just a minute... Set up building lines...',
    id: 'auth.loading.step_0',
  },
  loading_1: {
    defaultMessage: "Soon we'll be ready... Building the Walls and Roof...",
    id: 'auth.loading.step_1',
  },
  loading_2: {
    defaultMessage: 'A couple of more seconds... Windows and doors',
    id: 'auth.loading.step_2',
  },
  loading_3: {
    defaultMessage: 'We are ready!',
    id: 'auth.loading.step_3',
  },
  resend:
  {
    defaultMessage: 'Did not receive text message? - Press here to resend',
    id: 'auth.signin.resend',
  },
  sendSMS:
  {
    defaultMessage: 'Send text message',
    id: 'auth.signin.sendSMS',
  },
  sendVerificationSMS:
  {
    defaultMessage: 'Get verification text message',
    id: 'auth.signin.sendVerificationSMS',
  },
  resendVerificationSMS:
  {
    defaultMessage: 'Resend verification', 
    id: 'auth.signin.resendSMS',
  },
  login:
  {
    defaultMessage: 'Login',
    id: 'auth.signin.login',
  },
  verificationCodeWrong: {
    defaultMessage: 'The verification code is wrong',
    id: 'auth.signin.verificationCodeWrong',
  },
  enterAccessCode: {
    defaultMessage: 'Welcome to Cemento.ai. In order to be part of our network, you must be invited by an other member of Cemento.ai or have an access code. If you don\'t know any - Don\'t worry, we added you to our waiting list and one of our representative will contact you when we have more seats open. Stay tuned!',
    id: 'auth.signin.enterAccessCode',
  },
  rightAccessCode: {
    defaultMessage: 'The access code correct, Welcome to Cemento!',
    id: 'auth.signin.rightAccessCode',
  },
  enterSmsManually: {
    defaultMessage: 'Enter text message verification code manually',
    id: 'access.sms.enterSmsManually'
  },
  waitForVerificationSms: {
    defaultMessage: 'Waiting for verification text message',
    id: 'access.sms.waitForVerificationSms'
  },
});
