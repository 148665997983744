import React, { useMemo, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { getDefaultProjectMenus, getProjectCombinedMenus } from '../../../common/menus/funcs';
import { injectIntl } from '../../../common/app/myInjectIntl';
import BurgerMenuIcon from '../../assets/img/icons/BurgerMenu';
import { headerMainRoutes } from '../../routes/main';
import cssTheme from '../../assets/css/theme';
import systemMessages from '../../../common/app/systemMessages';

const useStyles = makeStyles(theme => ({
	container: {
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		flexDirection: 'column',
		width: '280px',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		borderBottom: `1px solid ${theme.palette.divider}`,
		padding: '4px',
		'& span': {
			margin: '0 8px',
		},
	},
	root: {
		flexGrow: 1,
		display: 'flex',
	},
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
		borderLeft: `1px solid ${theme.palette.divider}`,
		width: '50%',
	},
	tabPanels: {
		width: '50%',
	},
	tab: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		fontWeight: cssTheme.bold,
		color: cssTheme.textColorDark,
		'& > span': {
			alignItems: 'flex-start',
			textAlign: 'start',
		},
	},
	selectedTab: {
		color: cssTheme.brandPrimary,
	},
	tabsIndicator: {
		backgroundColor: cssTheme.brandPrimary,
	},
	tabPanel: {
		display: 'flex',
		flexDirection: 'column',
		'& div + *': {
			marginTop: '1rem',
		},
		'& p': {
			fontSize: '0.7rem',
			color: cssTheme.textColorGray,
			fontWeight: cssTheme.bold,
		},
		'& a': {
			color: cssTheme.textColorDark,
		},
	},
	cursorPointer: {
		cursor: 'pointer',
	},
}));

const configToMenuMap = {
	// Paired
	'safety': 'safety',
	'quality': 'qa',
	'spec': 'info',

	// Default menu
	'members': 'members',
	'issues': 'issues',
	'drawings': 'drawings',
	'records': 'records',
	'details': 'details',

	// Default config
	'dailyReport': 'dailyReport',
};

const VerticalMenu = ({ projectId, menus, configurations, closeMenu, intl }) => {
	const classes = useStyles();
	const [value, setValue] = useState(0);

	const projectMenus = useMemo(() => {
		const projectMenus = getProjectCombinedMenus({
			menus,
			defaultProjectMenu: getDefaultProjectMenus({ formsInfoPropTypes: null }),
		});

		// Menus that we don't want to show
		let hiddenMenus = Object.entries(configurations).reduce(
			(acc, [key, value]) => {
				if (!value.isActive) {
					acc.push(configToMenuMap[key]);
				}
				return acc;
			},
			['projectManager', 'forms', 'siteControl'], // Menus that we won't show by default
		);

		const filteredMenus = Object.entries(projectMenus).reduce((acc, [key, menu]) => {
			if (!hiddenMenus.includes(key) && Object.keys(menu).length) {
				const submenus = Object.entries(menu).reduce((acc, [submenuKey, submenuValue]) => {
					const filteredOptions = submenuValue.options.filter(option => option.contentType || option.subjectType);
					if (filteredOptions.length) {
						acc[submenuKey] = {
							...submenuValue,
							options: filteredOptions,
						};
					}
					return acc;
				}, {});

				acc[key] = submenus;
			}
			return acc;
		}, {});

		return filteredMenus;
	}, [menus]);

	const handleChange = (e, value) => {
		setValue(value);
	};

	const buildRedirectHref = option => {
		const { contentType, pageType, type, query, id } = option;

		const buildingView = type === 'buildingView' ? 'locationContainerPage/ALL/_/_' : null;

		const path = [projectId, contentType, pageType, buildingView, id].filter(Boolean);

		return `/main/projectContainerPage/${path.join('/')}${query ? `?${query}` : ''}`;
	};

	const getLinkLabel = label => (typeof label === 'object' ? intl.formatMessage(label) : label);

	const headerMainRoutesNamesMapByContentType = useMemo(
		() =>
			headerMainRoutes.reduce((acc, route) => {
				acc[route.contentType] = intl.formatMessage(route.name);
				return acc;
			}, {}),
		[headerMainRoutes],
	);

	return (
		<div className={classes.container}>
			<header className={classes.header}>
				<BurgerMenuIcon className={classes.cursorPointer} onClick={closeMenu} />
				<span>{intl.formatMessage(systemMessages.quickMenu)}</span>
			</header>
			<div className={classes.root}>
				<Tabs
					classes={{ indicator: classes.tabsIndicator }}
					orientation='vertical'
					variant='fullWidth'
					value={value}
					onChange={handleChange}
					className={classes.tabs}
				>
					{Object.keys(projectMenus).map(key => (
						<Tab
							classes={{
								root: classes.tab,
								selected: classes.selectedTab,
							}}
							key={`Tab-${key}`}
							label={headerMainRoutesNamesMapByContentType[key]}
						/>
					))}
				</Tabs>
				<div className={classes.tabPanels}>
					{Object.entries(projectMenus).map(([menuKey, menu], index) => (
						<TabPanel className={classes.tabPanel} key={`TabPanel-${menuKey}-${index}`} value={value} index={index}>
							{Object.values(menu).map(section => (
								<>
									<Typography key={`TabPanel-Section-${section.caption.defaultMessage}`}>
										{intl.formatMessage(section.caption)}
									</Typography>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										{Object.values(section.options).map((option, index) => (
											<Link
												key={`TabPanel-Section-${section.caption.defaultMessage}-Link-${index}`}
												href={buildRedirectHref(option)}
												target='_blank'
											>
												{getLinkLabel(option.label)}
											</Link>
										))}
									</div>
								</>
							))}
						</TabPanel>
					))}
				</div>
			</div>
		</div>
	);
};

const TabPanel = props => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index ? <Box p={3}>{children}</Box> : null}
		</div>
	);
};

export default injectIntl(VerticalMenu);
