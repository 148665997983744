import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose, hoistStatics } from 'recompose';
import { connectContext } from 'react-connect-context';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';
import buildingsMessages from '../../../common/buildings/buildingsMessages';
import systemMessages from '../../../common/app/systemMessages';
import { BuildingContext } from '../../../common/buildings/contexts';
import { ProjectContext, FiltersSortsContext } from '../../../common/projects/contexts';

import { getPostsByFilter } from '../../../common/posts/actions';
import { prepareLocationsChecklistsStatusMap } from '../../../common/checklists/funcs';

import MenuScrollbar from '../../components/CementoComponents/MenuScrollbar';
import Text from '../../components/CementoComponents/Text';
import Building from './Building.js';
import InputField from '../../components/CementoComponents/InputField';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import buttonStyle from '../../assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx';
import theme from '../../assets/css/theme';
import issuesMessages from '../../../common/issues/issuesMessages';
import postsMessages from '../../../common/posts/postsMessages';
import newProjectMessages from '../../../common/projects/newProjectMessages';
import TextFilter from '../Posts/TextFilter';
import postsMenuMessages from '../../../common/posts/postsMenuMessages';
import HoverWrapper from '../../components/CementoComponents/HoverWrapper';
import { ALL_BUILDINGS_ID } from '../../../common/app/constants';

const defaultFilterType = 'units';

class Buildings extends React.Component {
	constructor(props) {
		super(props);
		this.setComponentData = this.setComponentData.bind(this);
		this.onBuildingSelect = this.onBuildingSelect.bind(this);
		this.onMouseEnter = this.onMouseEnter.bind(this);
		this.onMouseLeave = this.onMouseLeave.bind(this);
		this.getFilteredPosts = this.getFilteredPosts.bind(this);
		this.filterLocations = this.filterLocations.bind(this);
		this.getLocationsFormattedNames = this.getLocationsFormattedNames.bind(this);
		this.onLocationSelect = this.onLocationSelect.bind(this);
		this.onSelectAll = this.onSelectAll.bind(this);
		this.handleChangeType = this.handleChangeType.bind(this);
		this.state = {
			sortedBuildings: [],
			locationsFormattedNames: {},
			filter: {
				type: null,
				string: '',
			},
		};
	}

	UNSAFE_componentWillMount() {
		this.setComponentData({}, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setComponentData(this.props, nextProps);
	}

	setComponentData(props, nextProps) {
		const { buildings } = props;
		let newStateChanges = {};

		if (buildings != nextProps.buildings) {
			let selectedProjectBuildings = nextProps.buildings;
			let buildingsObject =
				(selectedProjectBuildings && selectedProjectBuildings.toJS
					? selectedProjectBuildings.toJS()
					: selectedProjectBuildings) || {};
			let sortedBuildings =
				Object.values(buildingsObject)
					.sort((a, b) => (b.id < a.id ? 1 : -1))
					.sort((a, b) => a.ordinalNo - b.ordinalNo || 0) || [];
			let buildingIdSelected = nextProps.getNested(['selectedLocation', 'buildingId']);
			const moreThanOneBuildingInProj = sortedBuildings.length > 1;
			const singleBuildingInProj = sortedBuildings.length == 1;
			const firstBuildingId = _.get(sortedBuildings, [0, 'id']);
			const firstBuildingTitle = _.get(sortedBuildings, [0, 'title']);
			if (moreThanOneBuildingInProj)
				sortedBuildings.unshift({
					id: ALL_BUILDINGS_ID,
					title: systemMessages.allProject,
				});
			else if (firstBuildingTitle) sortedBuildings.setNested([0, 'title'], systemMessages.allProject);

			newStateChanges.moreThanOneBuildingInProj = moreThanOneBuildingInProj;
			newStateChanges.selectedBuildingId =
				buildingIdSelected && buildingIdSelected != '_'
					? buildingIdSelected == ALL_BUILDINGS_ID && singleBuildingInProj
						? firstBuildingId
						: buildingIdSelected
					: moreThanOneBuildingInProj
					? ALL_BUILDINGS_ID
					: sortedBuildings.getNested([0, 'id']);
			newStateChanges.sortedBuildings = sortedBuildings;
		}

		if (
			props.getNested(['filters']) != nextProps.getNested(['filters']) ||
			props.getNested(['buildings']) != nextProps.getNested(['buildings']) ||
			props.getNested(['selectedProjectId']) != nextProps.getNested(['selectedProjectId']) ||
			props.getNested(['detailedProjects', nextProps.selectedProjectId]) !=
				nextProps.getNested(['detailedProjects', nextProps.selectedProjectId]) ||
			props.getNested(['posts', nextProps.selectedProjectId]) !=
				nextProps.getNested(['posts', nextProps.selectedProjectId]) ||
			props.getNested(['viewer']) != nextProps.getNested(['viewer']) ||
			props.filteredPosts != nextProps.filteredPosts
		) {
			newStateChanges.buildingBadges = this.getFilteredPosts(nextProps);
		}
		if (nextProps.enableSearch || nextProps.isSelectionMode)
			if (
				!Object.values(this.state.locationsFormattedNames).length ||
				props.isValDiff(nextProps, ['buildings']) ||
				props.isValDiff(nextProps, ['floors']) ||
				props.isValDiff(nextProps, ['units']) ||
				props.isValDiff(nextProps, ['selectedLocations']) ||
				props.isValDiff(nextProps, ['onlyLocations'])
			) {
				const { buildings, floors, units, selectedLocations, onlyLocations } = nextProps;
				newStateChanges.locationsFormattedNames = this.getLocationsFormattedNames(
					buildings,
					floors,
					units,
					selectedLocations,
					onlyLocations,
				);
			}

		if (props.isValDiff(nextProps, ['enableSearch'])) newStateChanges.filter = { type: defaultFilterType, string: '' };

		if (nextProps.filterType !== undefined && props.isValDiff(nextProps, ['filterType'])) {
			const { filterType } = nextProps;

			newStateChanges.filter = { type: filterType, string: '' };
		}

		if (props.getNested(['locationsChecklistsStatus']) != nextProps.getNested(['locationsChecklistsStatus'])) {
			newStateChanges.checklistsStatusMap = prepareLocationsChecklistsStatusMap(
				nextProps.locationsChecklistsStatus,
				nextProps.selectedProjectId,
				nextProps.buildings,
				nextProps.floors,
				nextProps.units,
			);
		}

		if (
			(props.contentType != nextProps.contentType && nextProps.contentType == 'issues') ||
			nextProps.contentType == 'records' ||
			nextProps.contentType == 'safety'
		)
			newStateChanges.buildingBadges = this.getFilteredPosts(nextProps);

		if (nextProps.contentType == 'drawings') newStateChanges.buildingBadges = this.getDrawingCounts();

		if (Object.keys(newStateChanges || {}).length > 0) this.setState(newStateChanges);
	}

	getFilteredPosts(nextProps) {
		if (!nextProps.selectedProjectId) return [];

		let issueMode = 3;
		if (nextProps.getNested(['urlParams', 'contentType']) == 'issues') issueMode = 1;
		else if (nextProps.getNested(['urlParams', 'contentType']) == 'records') issueMode = 2;

		let posts = nextProps.filteredPosts;

		return getPostsByFilter(
			posts,
			nextProps.getNested(['buildings']),
			nextProps.getNested(['viewer']),
			nextProps.getNested(['filtersView'], 'tasks') == 'tasks' ? nextProps.getNested(['filters']) : null,
			true,
			issueMode,
			nextProps.detailedProjects.getNested([nextProps.selectedProjectId, 'members']),
		);
	}

	onMouseEnter(currBuilding) {
		this.setState({ hoverBuildingId: currBuilding.id });
	}

	onMouseLeave() {
		this.setState({ hoverBuildingId: null });
	}

	onBuildingSelect(currBuilding) {
		this.setState({ selectedBuildingId: currBuilding.id });

		const infoObj = {
			buildingId: currBuilding.id,
			type: 'building',
		};

		this.onLocationSelect(infoObj);
	}

	getDrawingCounts() {
		const { drawings, selectedProjectId, buildings, floors, units, selectedLocation } = this.props;

		return {}; // TODO: fix getDrawingCounts func

		const filteredDrawing = {};
		const all = {};
		if (buildings && units && floors && selectedProjectId) {
			buildings.keySeq().forEach(building => {
				let buildingDrawings = drawings.getNested([selectedProjectId, building]);
				if (!buildingDrawings) return;
				buildingDrawings.keySeq().forEach(location => {
					let count = buildingDrawings.get(location).size;
					if (!filteredDrawing[location]) {
						filteredDrawing[location] = count || 0;
					} else {
						filteredDrawing[location] += count;
					}
					buildingDrawings
						.get(location)
						.valueSeq()
						.forEach(drawing => {
							let key = drawing.uri || drawing.uriPdf;
							all[key] = key;
						});
				});
			});
			//add floor drawings amount to unit
			const floorCountByUnit = (unit, building) => {
				let floorId = floors
					.get(building)
					.find(floor => floor.get('num') === unit.floor.num)
					.get('id');
				return Number.isInteger(filteredDrawing[floorId]) ? filteredDrawing[floorId] : 0;
			};

			buildings.keySeq().forEach(building => {
				units
					.get(building)
					.valueSeq()
					.forEach(unit => {
						filteredDrawing[unit.id] = Boolean(filteredDrawing[unit.id])
							? filteredDrawing[unit.id] + floorCountByUnit(unit, building)
							: floorCountByUnit(unit, building);
					});
			});
			filteredDrawing[buildings.size > 1 ? 'all' : buildings.keySeq().toJS()[0]] = Object.keys(all).length;
			return filteredDrawing;
		}
	}

	getLocationsFormattedNames(buildings, floors, units, selectedLocations, onlyLocations) {
		const { intl } = this.props;
		const getIsSelected = id => (selectedLocations ? selectedLocations.getNested([id], false) : false);
		const isInLocations = id => !onlyLocations || (onlyLocations && Boolean(onlyLocations.getNested([id], false)));

		let locations = { buildings: {}, floors: {}, units: {} };
		let countOfBuildings = 0;
		let buildingIndex = 1;

		(buildings || {}).loopEach((bid, b) => {
			let currTitle = b.title || `building ${buildingIndex}`;
			countOfBuildings++;
			if (isInLocations(bid))
				locations.buildings[bid] = {
					id: bid,
					exportTitle: currTitle,
					title: currTitle,
					selected: getIsSelected(bid),
				};
			buildingIndex++;
		});

		(floors || {}).loopEach((bid, b) => {
			b.loopEach((fid, f) => {
				let currTitle =
					f.description ||
					intl.formatMessage(issuesMessages.floorNumber, {
						floorNumber: String(f.num),
					});
				if (isInLocations(fid))
					locations.floors[fid] = {
						id: fid,
						exportTitle: currTitle,
						title: currTitle,
						selected: getIsSelected(fid),
					};
			});
		});

		(units || {}).loopEach((bid, b) => {
			b.loopEach((uid, u) => {
				let currTitle = u.title;
				if (isInLocations(uid))
					locations.units[uid] = {
						id: uid,
						exportTitle: currTitle,
						title:
							intl.formatMessage(issuesMessages.floorNumber, {
								floorNumber: String(u.floor.num),
							}) +
							' \\ ' +
							currTitle,
						selected: getIsSelected(uid),
					};
			});
		});
		return locations;
	}

	filterLocations(listToFilter, filter) {
		// working
		const { locationsFormattedNames } = this.state;
		const { string, type } = filter;

		if (!type || typeof string !== 'string') return listToFilter;

		return listToFilter.filter(loc => {
			let title = locationsFormattedNames.getNested([type, loc.id, 'title'], false);
			return title && title.toLowerCase().indexOf(string.toLowerCase()) !== -1;
		});
	}

	onLocationSelect(selectedLocationInfo) {
		const { filter, locationsFormattedNames } = this.state;
		const { onSelectionChange, isSelectionMode, isMultiSelect, onClick } = this.props;
		const { type, buildingId, floorId, unitId } = selectedLocationInfo;

		if (!isSelectionMode && onClick) {
			onClick(selectedLocationInfo);
			return;
		}

		if (filter.type.slice(0, -1) !== type)
			// actually show an error you can't select that
			return;

		const updateSelection = id => {
			const location = _.get(locationsFormattedNames, [filter.type, id]) || {};

			if (isMultiSelect)
				return locationsFormattedNames.setNested([filter.type, id, 'selected'], !location.selected)[filter.type];
			else return { [id]: Object.assign({}, location, { selected: true }) };
		};

		let changeValue = {};
		switch (filter.type) {
			case 'buildings':
				changeValue = updateSelection(buildingId);
				break;

			case 'floors':
				changeValue = updateSelection(floorId);
				break;

			case 'units':
				changeValue = updateSelection(unitId);
				break;
		}

		if (onSelectionChange) onSelectionChange(changeValue);
	}

	onSelectAll(trueOrFalse) {
		const { locationsFormattedNames, filter } = this.state;
		const { onSelectionChange } = this.props;
		const { type, string } = filter;

		let updatedLocationsFormattedNames = locationsFormattedNames;
		locationsFormattedNames[type].loopEach((i, loc) => {
			if (loc.title.toLowerCase().indexOf(string.toLowerCase()) !== -1)
				updatedLocationsFormattedNames = updatedLocationsFormattedNames.setNested(
					[type, loc.id, 'selected'],
					trueOrFalse,
				);
		});

		// this.setState({ locationsFormattedNames: updatedLocationsFormattedNames });

		if (onSelectionChange) onSelectionChange(updatedLocationsFormattedNames[type]);
	}

	handleChangeType(newType) {
		const { onChangeType } = this.props;

		if (newType) {
			newType = Object.values(newType)[0];
		}

		// this.setState(({ filter }) => ({filter: filter.setNested(['type'], newType)}));
		if (onChangeType) onChangeType(newType);
	}

	handleFilterStringChange = newString => {
		this.setState(({ filter }) => ({
			filter: filter.setNested(['string'], newString),
		}));
	};

	render() {
		const {
			classes,
			style,
			maxUnitsInFloor,
			showBadges,
			selectedLocation,
			isSelectionMode = false,
			isMultiSelect = false,
			enableSearch = false,
			showSelectType = true,
		} = this.props;
		const {
			sortedBuildings,
			selectedBuildingId,
			hoverBuildingId,
			checklistsStatusMap,
			buildingBadges,
			filter,
			locationsFormattedNames,
		} = this.state;
		let isSingleBuilding = sortedBuildings.length == 1;

		let isBuildingUrlSelected =
			selectedLocation && selectedLocation.buildingId && !selectedLocation.floorId && !selectedLocation.unitId;
		let badgeSize = 20;

		let filteredSortedBuildings = sortedBuildings;
		if (filter.type === 'buildings') filteredSortedBuildings = this.filterLocations(sortedBuildings, filter);

		const isShowBuildingsView = Boolean(!isSelectionMode || filter.type);

		return (
			<BuildingContext.Provider
				value={{
					checklistsStatusMap,
					buildingBadges,
					selectedLocation,
					isSelectionMode,
					onLocationSelect: this.onLocationSelect,
					filter,
					filterLocations: this.filterLocations,
					locationsMap: locationsFormattedNames,
				}}
			>
				<div className={classes.buildingsContainer} style={style}>
					<div
						style={{
							padding: 20,
							borderBottom: isSingleBuilding ? 'none' : theme.borderLineNeutralLight + '75',
							marginBottom: isSingleBuilding ? 'none' : theme.paddingSize,
						}}
					>
						<div>
							{Boolean(isSelectionMode && (showSelectType || !filter.type)) && (
								<InputField
									name={systemMessages.selectType}
									type={'SelectionList'}
									autoSort={false}
									values={[
										{
											id: 'buildings',
											title: newProjectMessages.locationTypes.buildings,
										},
										{
											id: 'floors',
											title: newProjectMessages.locationTypes.floors,
										},
										{
											id: 'units',
											title: newProjectMessages.locationTypes.units,
										},
									]}
									value={{ [filter.type]: filter.type }}
									onChange={this.handleChangeType}
								/>
							)}
							{Boolean(isShowBuildingsView) && (
								<>
									<div style={{
											display: 'flex',
											flexDirection: 'column',
											marginTop: showSelectType ? 0 : 20
										}}
									>
										{Boolean(enableSearch || isSelectionMode) && (
											<TextFilter
												key={'filter'}
												containerStyle={{
													margin: theme.verticalMargin,
													borderRadius: '5px',
													backgroundColor: 'transparent',
												}}
												value={filter.string}
												onChange={this.handleFilterStringChange}
												clearFilterVal={() => this.handleFilterStringChange('')}
											/>
										)}
										{Boolean(isSelectionMode && isMultiSelect) && (
											<div style={{ display: 'flex', alignItems: 'center' }}>
												{[true, false].map(selectAllBool => (
													<HoverWrapper
														key={'select-all-' + String(selectAllBool)}
														props={{ color: 'unset' }}
														hoverProps={{ color: theme.brandPrimary }}
													>
														{({ color }) => (
															<Text
																style={{ ...styles.selectAllButtons, color }}
																onClick={() => this.onSelectAll(selectAllBool)}
															>
																{postsMenuMessages[selectAllBool ? ALL_BUILDINGS_ID : 'Clear']}
															</Text>
														)}
													</HoverWrapper>
												))}
											</div>
										)}
									</div>

									<div
										style={{
											display: 'flex',
											flex: 1,
											flexDirection: 'column',
										}}
									>
										{filteredSortedBuildings.map((currBuilding, index) => {
											let isActive;
											if (isSelectionMode && filter.type === 'buildings')
												isActive = locationsFormattedNames.getNested([filter.type, currBuilding.id, 'selected'], false);
											else
												isActive =
													(selectedBuildingId == currBuilding.id && (isBuildingUrlSelected || !isSingleBuilding)) ||
													hoverBuildingId == currBuilding.id;

											let badgeBuildingId = filteredSortedBuildings.length == 1 ? ALL_BUILDINGS_ID : currBuilding.id;
											let buildingData = (checklistsStatusMap || {}).getNested([badgeBuildingId]);
											let isLastBuilding = index == filteredSortedBuildings.length - 1;

											let badge = null;

											if (
												showBadges &&
												((!checklistsStatusMap && buildingBadges && buildingBadges[badgeBuildingId]) ||
													(checklistsStatusMap && buildingData && buildingData.issuesCounter != 0))
											)
												badge = checklistsStatusMap ? buildingData.issuesCounter : buildingBadges[badgeBuildingId];
											return (
												<div
													key={currBuilding.id}
													style={{
														display: 'flex',
														flex: 1,
														justifyContent: 'space-between',
														marginBottom: isSingleBuilding || isLastBuilding ? 0 : 7,
														flexDirection: 'row',
														cursor: 'pointer',
														fontSize: theme.mediumFontSize,
														backgroundColor: theme.backgroundColor,
														color: isActive ? theme.brandPrimary : theme.brandNeutral,
													}}
													onMouseEnter={() => {
														this.onMouseEnter(currBuilding);
													}}
													onMouseLeave={() => {
														this.onMouseLeave(currBuilding);
													}}
													onClick={() => {
														this.onBuildingSelect(currBuilding);
													}}
												>
													<Text style={{ fontSize: 13, fontWeight: theme.strongBold }} values={{ counter: index + 1 }}>
														{currBuilding.title || buildingsMessages.buildingNumberShort}
													</Text>
													{Boolean(badge && showBadges) && (
														<div
															style={{
																height: badgeSize,
																width: badgeSize,
																maxWidth: '30px',
																maxHeight: '30px',
																textAlign: 'center',
																backgroundColor: isActive ? theme.brandPrimary : theme.brandNeutral,
																borderRadius: '50%',
																zIndex: theme.zIndexes.numberBadge,
															}}
														>
															<div
																style={{
																	fontFamily: theme.defaultFont,
																	color: theme.backgroundColor,
																	fontWeight: theme.strongBold,
																	fontSize: badge < 100 ? theme.smallFontSize + 1 : theme.smallFontSize,
																}}
															>
																{badge}
															</div>
														</div>
													)}
												</div>
											);
										})}
									</div>
								</>
							)}
						</div>
					</div>
					{Boolean(isShowBuildingsView) && (
						<div ref='buildingWrapper' style={{ flex: 1, display: 'flex' }}>
							<MenuScrollbar isSmooth={false} style={{ width: '100%', height: 'inherit', zIndex: theme.zIndexes.building }}>
								<Building
									showBadges={showBadges}
									stickyMode={true}
									onClick={this.onLocationSelect}
									buildingId={selectedBuildingId}
									maxUnitsInFloor={maxUnitsInFloor}
									filter={filter}
									filterLocations={this.filterLocations}
								/>
							</MenuScrollbar>
						</div>
					)}
				</div>
			</BuildingContext.Provider>
		);
	}
}

const styles = {
	buildingsContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	selectAllButtons: {
		display: 'flex',
		minWidth: theme.margin * 2,
		height: theme.margin * 2 + 5,
		alignSelf: 'center',
		justifyContent: 'center',
		alignItems: 'center',
		margin: theme.verticalMargin,
		cursor: 'pointer',
	},
};

Buildings = withStyles(theme.combineStyles(buttonStyle, styles))(Buildings);
Buildings = injectIntl(Buildings);
const enhance = compose(
	connectContext(FiltersSortsContext.Consumer),
	connectContext(ProjectContext.Consumer),
	connect(
		state => ({
			drawings: state.drawings.map,
			locationsChecklistsStatus: state.checklists.locationsStatus,
			posts: state.posts.map,
			//urlParams: state.ui.urlParams,
		}),
		{},
	),
);
export default enhance(Buildings);
