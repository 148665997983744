import * as appActions from '../app/actions';
import * as actions from './actions';
import { firebaseActions } from '../lib/redux-firebase';
import * as lastUpdatesActions from '../lastUpdates/actions';
import * as projectsAction from '../projects/actions'
import * as usersActions from '../users/actions';
import * as eventsCountersActions from '../events/actions';
import { REPLACE_USER } from '../auth/actions';
import _ from 'lodash';


import ExtraError from '../lib/errors/extraError'
import { OrderedMap, Map } from 'immutable';
import { Record } from '../transit';

const InitialState = Record({
  map: OrderedMap(),
  lastUpdateTS: OrderedMap(),
  localPostsMap: OrderedMap(),
  didLoad: OrderedMap(),
  lastRevoked: Map(),
  lastRevokeAvailable: Map(),
  sync: Map(),
  lastSynced: Map(),
  eventsDidLoad: Map(),
  isAdmin: false,
  expandList: Map(),
  counters: Map()
}, 'posts');

const initialState = new InitialState;

export default function postReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) { 
    case lastUpdatesActions.GET_LAST_UPDATES: {
      if (!action.payload || !action.payload.projects)
        return state;

      const { projects } = action.payload;
      Object.keys(projects).forEach(projectId => {
        let TS = projects.getNested([projectId,'posts','lastUpdateTS'], 1);
        let curr = state.getNested(['lastRevokeAvailable', projectId], null)
        if (curr < TS)
          state = state.setIn(['lastRevokeAvailable', projectId], TS);
      });

      return state;
    }

    case lastUpdatesActions.GET_SYNCED: {
      if (!action.payload || !action.payload.projects)
        return state;

      const { projects } = action.payload;
      _.forIn(projects, (objectTypes, projectId) => {
        let syncOptions = _.get(objectTypes, ['posts'], {})
        let TS = syncOptions.lastUpdateTS || 1;
        let curr = state.getNested(['sync', projectId, 'lastUpdateTS'], null)
        if (curr < TS) {
          state = state.setIn(['sync', projectId], syncOptions);
        }
      })

      return state;
    }

    case lastUpdatesActions.SYNCED + "_POSTS_SUCCESS": {
      if (!action.payload || !action.payload.projectId || !action.payload.lastSynced)
        return state;

      const { projectId, lastSynced } = action.payload;
      state = state.setIn(['lastSynced', projectId], lastSynced);
      return state;
    }

    case actions.GET_POSTS: 
      return state.setIn(['didLoad', action.getNested(['payload','projectId'])], false);
    
    case projectsAction.LOKI_DID_LOAD: {
      if (action.getNested(['payload','loadedCollections','posts']))
        state = state.setIn(['didLoad', action.getNested(['payload','projectId'])], action.getNested(['payload','status']));
      return state;
    }

    case actions.POSTS_DONE_LOADING: {
      if (!action.payload)
        return state;
      
      const { scopeId } = action.payload;
      state = state.setIn(['didLoad', scopeId], true);
      state = state.setIn(['lastRevoked', scopeId], state.getNested(['lastRevokeAvailable', scopeId]) || state.getNested(['lastRevoked', scopeId]) || 1); 

      return state;
    }

    case appActions.CLEAN_DYNAMIC_CACHE_DATA + "_SUCCESS":
    case REPLACE_USER + "_SUCCESS":
    case actions.CLEAN_CACHED_POSTS:
    case appActions.CLEAN_CACHE: {      
      //let lastRevokeAvailable = state.getNested(['lastRevokeAvailable'], {});  
      //let lastRevoked = state.getNested(['lastRevoked'], {});
      state = initialState;
      //lastRevoked.loopEach((projectId, last) => last ? state = state.setIn(['lastRevokeAvailable', projectId], last + 1) : 0)
      //lastRevokeAvailable.loopEach((projectId, last) => last ? state = state.setIn(['lastRevokeAvailable', projectId], last + 1) : 0)
      return state;
    }

    case actions.END_POSTS_LISTENER: {
      if (action.payload && action.payload.projectId)
        state = state.setIn(['didLoad', action.payload.projectId], false);
      return state;
    }

    // case actions.LOAD_LOCAL_POSTS:
    // case actions.CREATE_NEW_POST + "_SUCCESS": {
    //   if (!action.payload)
    //     return state;

    //   const { projectId, post, posts = [] } = action.payload;
    //   if (post)
    //     posts.push(post);
    //   posts.forEach(currPost => {
    //     state = state.setIn(['localPostsMap', projectId, currPost.id], true);
    //   });
    //   return state;
    // }

    // case actions.POST_UPLOADED: {
    //   if (!action.payload)
    //     return state;

    //   const { projectId, post, posts = [] } = action.payload;
    //   if (post)
    //     posts.push(post);
    //   posts.forEach(currPost => {
    //     state = state.setIn(['localPostsMap', projectId, currPost.id], false);
    //   });
    //   return state;
    // }


    case usersActions.SET_ADMIN_MODE:  {
      const { newMode } = action.payload;
      return state.set('isAdmin', newMode);
    }

    case eventsCountersActions.GET_PROJECT_POSTS_EVENTS_COUNTERS: {
     
      if (!action.payload || !action.payload.eventsCounters || !action.payload.projectId)
        return state;
      
      const { eventsCounters, projectId } = action.payload;      

      state = state.setIn(['eventsDidLoad', projectId], true);     
      if (!eventsCounters.posts)
        return state;

      let posts = eventsCounters.posts;
      Object.keys(eventsCounters.posts).forEach(postId => {
        var post = posts[postId];
        state = state.setIn(['counters', postId, 'counter'], post.counter);
        state = state.setIn(['counters', postId, 'fue'], post.fue);
        if (state.hasIn(['map', projectId, postId])) {
          state = state.setIn([' ', projectId, postId, 'counter'], posts[postId].counter);
          state = state.setIn(['map', projectId, postId, 'fue'], posts[postId].fue);
        }
      });

      return state;
    }

    case eventsCountersActions.INIT_POST_EVENTS_COUNTERS + "_START": {
      if (!action.payload)
        return state;

      const { projectId, postId } = action.payload;
      state = state.setIn(['eventsDidLoad', projectId], false);
      state = state.deleteIn(['counters', postId, 'counter']);
      state = state.deleteIn(['counters', postId, 'fue']);

      state = state.deleteIn(['map', projectId, postId, 'counter']);
      state = state.deleteIn(['counters', postId, 'fue']);

      return state;
    }
  }

  return state;
}