import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Carousel from '../nuka-carousel';
import Right from '../../assets/img/icons/right.png'
import Left from '../../assets/img/icons/left.png'
import Cancel from '../../assets/img/icons/cancel.png'
import Download from '../../assets/img/icons/download.png';
import theme from "../../assets/css/theme";
import Dialog from "@material-ui/core/Dialog";
import Grow from "@material-ui/core/Grow";
import ZoomImage from "./ZoomImage";
import { saveAs } from 'file-saver';
import _ from 'lodash';
import mime from 'mime';
//import "react-pdf/dist/Page/AnnotationLayer.css";
//import PdfViewer from "./PdfViewer";

const IMAGE_DEFAULT_DESCRIPTION = 'cemento_image'

function Transition(props) { 
  return <Grow in={props.open} {...props} />; 
}

class ImageCarousel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.getLeftArrow = this.getLeftArrow.bind(this);
    this.getRightArrow = this.getRightArrow.bind(this);
    this.onClose = this.onClose.bind(this)
    this.onSave = this.onSave.bind(this);
    this.state = {};
  }

  onClose() {
    if (this.props.onClose)
      this.props.onClose();
  }

  async onSave() {
    const { items, index = 0 } = this.props;
    const currentItem = _.get(items, index);
    const currentItemSrc = _.get(currentItem, 'src');
    if (currentItemSrc) {
      const currentItemDescription = _.get(currentItem, 'description') || `${IMAGE_DEFAULT_DESCRIPTION}_${Date.now()}`;
      const fetchedImage = await fetch(currentItemSrc);
      const blob = await fetchedImage.blob();
      const extension = mime.getExtension(blob.type);
      saveAs(blob, `${currentItemDescription}.${extension}`);
    }
  }

  getRightArrow({ nextSlide }) {
    return (<img style={{ cursor: 'pointer', width: 30, margin: theme.paddingSize }} src={Right} onClick={nextSlide ? nextSlide : undefined} />)
  }

  getLeftArrow({ previousSlide }) {
    return (<img style={{ cursor: 'pointer', width: 30, margin: theme.paddingSize }} src={Left} onClick={previousSlide ? previousSlide : undefined} />)
  }

  render() {
    const { items, initialSlide, classes, pdfMode, toolbar } = this.props;
    let slides = items || [];
    const slidesLength = slides.length;
    return (
      <Dialog classes={{ paper: classes.paper, root: classes.root }}
        open={true}
        keepMounted
        fullScreen
        style={{ zIndex: theme.zIndexes.imageCarouselModal }}
        TransitionComponent={Transition}
        onClose={this.onClose}
        aria-labelledby="small-modal-slide-title"
        aria-describedby="small-modal-slide-description"
      >
        <div style={{ overflow:'hidden', width:'100vw', zIndex:theme.zIndexes.imageCarouselModal }}>
          <div style={{ right:'0px', position:'fixed', zIndex:theme.zIndexes.imageCarouselModal, cursor:'pointer' }} onClick={this.onClose}>
            <img style={{ width:30, margin:theme.paddingSize }} src={Cancel}/>
          </div>
          <div style={{ right:'40px', position:'fixed', zIndex:theme.zIndexes.imageCarouselModal, cursor:'pointer' }} onClick={this.onSave}>
            <img style={{ width:20, margin:theme.paddingSize + 5 }} src={Download}/>
          </div>
          
            <Carousel
              ref="carousel"
              heightMode={'max'}
              cellAlign={'center'}
              wrapAround={slidesLength > 0}
              dragging={slidesLength > 1}
              transitionMode={'scroll'}
              withoutControls={slidesLength <= 1}
              slidesToShow={1 /*1.25 */}
              slideIndex={initialSlide /*this.state.indexToStart*/}
              renderCenterLeftControls={slidesLength <= 1 ? null : this.getLeftArrow}
              renderCenterRightControls={slidesLength <= 1 ? null : this.getRightArrow}
            >
            {slides.map(({ src, description }) => <Item src={src} pdfMode={pdfMode} description={description} onClose={ this.onClose} toolbar={toolbar} />)}
            </Carousel>
          
        </div>
      </Dialog>
    )
  }
}


class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { src, description, onClose, pdfMode, toolbar } = this.props;
          
    return (
      <div style={{ cursor: "default", justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw' }} onClick={onClose ? onClose : undefined}>
        <ZoomImage maxHeight={'85vh'} maxWidth={'90vw'} src={src} pdfMode={pdfMode} toolbar={toolbar} />
        {Boolean(description) && <div style={{ position: 'inherit', textAlign: 'center', color: 'white', marginTop: '15px', height: '5vh', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', }}>
          {description}
        </div>}
      </div>
    );
  }
}

let styles = {
  paper: {
    flex: 20000,
    zIndex: theme.zIndexes.imageCarouselModal,
    backgroundColor: 'rgba(0, 0, 0, 0.33)',
    minWidth: '1980px'
  },
  root: {
    flex: 30000,
    zIndex: theme.zIndexes.imageCarouselModal,
    backgroundColor: 'rgba(0, 0, 0, 0.33)',
    minWidth: '1980px'
  }
};


ImageCarousel = withStyles(styles)(ImageCarousel);
export default ImageCarousel;
