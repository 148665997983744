// 'react-date-range': 'github:idanCemento/react-date-range#he-support',

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import _ from 'lodash';
import Calendar from 'react-calendar';
import DateRange from '@wojtekmaj/react-daterange-picker';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Text from './Text';
import theme from '../../assets/css/theme';
import '../../assets/css/dateRangePicker.css';
import systemMessages from '../../../common/app/systemMessages';
import { isEmptyValue } from '../../../common/app/funcs';
import useHover from '../../hooks/useHover';
import usePrevious from '../../../common/hooks/usePrevious';

const CALENDAR_BORDER_COLOR = '#00000020';

/**
 * @typedef {{ start: number, end: number }} DateRangePickerValue
 *
 * @typedef DateRangePickerProps
 * @property {Date} [maxDate]
 * @property {string | { id: string, defaultMessage: string }} [placeholder]
 * @property {(DateRangePickerValue) => void} [onChange]
 * @property {boolean} [isPickerStandAlone]
 * @property {DateRangePickerValue} [value]
 * @property {React.CSSProperties} [inputStyle]
 * @property {React.CSSProperties} [calendarContainerStyle]
 * @property {boolean} isDoneOnEndDate
 *
 * @param {DateRangePickerProps} props
 * @returns
 */

let DateRangePicker = props => {
	const {
		maxDate = new Date(),
		inputStyle,
		calendarContainerStyle,
		intl,
		placeholder,
		onChange,
		isPickerStandAlone,
		value,
		isDoneOnEndDate,
	} = props;
	const { rtl } = useSelector(state => ({ rtl: state.app.rtl }));

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [lastSavedRange, setLastSavedRange] = useState({});
	const [isDisplayPicker, setIsDisplayPicker] = useState(false);

	const prevValue = usePrevious(value);
	const [inputRef, isInputHover] = useHover();
	const pickerRef = useRef();

	const lang = useMemo(() => intl.locale, [intl.locale]);

	useEffect(() => {
		if (!_.isEqual(prevValue, value)) {
			const currStartTS = startDate ? startDate.getTime() : null;
			const currEndTS = endDate ? endDate.getTime() : null;
			const currRangeValue = { start: currStartTS, end: currEndTS };

			if (!_.isEqual(currRangeValue, value)) {
				const newStartDate = _.get(value, ['start']) ? new Date(value.start) : null;
				const newEndDate = _.get(value, ['end']) ? new Date(value.end) : null;
				setStartDate(newStartDate);
				setEndDate(newEndDate);
				setLastSavedRange({ start: newStartDate, end: newEndDate });
			}
		}
	}, [value]);

	const handlePickerDateSelect = useCallback(
		date => {
			date = date || null;

			if (endDate || !startDate) {
				setStartDate(date);
				if (endDate) setEndDate(null);
			} else if (startDate) {
				if (startDate > date) {
					setStartDate(date);
					setEndDate(startDate);
				} else setEndDate(date);
			}
		},
		[startDate, endDate, setStartDate, setEndDate],
	);

	const handleDateRangeChange = useCallback(
		([_startDate, _endDate]) => {
			if (_startDate && _endDate) {
				if (_startDate < _endDate) {
					setStartDate(_startDate);
					setEndDate(_endDate);
				} else {
					setStartDate(_endDate);
					setEndDate(_startDate);
				}
			} else {
				setStartDate(_startDate || null);
				setEndDate(_endDate || null);
			}
		},
		[setStartDate, setEndDate],
	);

	const handleSave = useCallback(() => {
		let rangeValue = {};

		if (startDate) rangeValue.start = startDate.getTime();

		if (endDate) rangeValue.end = endDate.getTime();
		else if (startDate) {
			rangeValue.end = startDate.getTime();
			setEndDate(startDate);
		}

		rangeValue = !isEmptyValue(rangeValue) ? rangeValue : null;

		setLastSavedRange(rangeValue || {});
		setIsDisplayPicker(false);

		if (onChange) onChange(rangeValue);
	}, [startDate, endDate, setLastSavedRange, setIsDisplayPicker, onChange]);

	useEffect(() => {
		if (isDoneOnEndDate && startDate && endDate) handleSave();
	}, [startDate, endDate]);

	const handleClear = useCallback(() => {
		setStartDate(null);
		setEndDate(null);
	}, [setStartDate, setEndDate]);

	const handleCancel = useCallback(() => {
		setStartDate(lastSavedRange.start || null);
		setEndDate(lastSavedRange.end || null);
		setIsDisplayPicker(false);
	}, [setStartDate, setEndDate, lastSavedRange]);

	const handleClick = useCallback(
		e => {
			if (!isDisplayPicker || (_.get(pickerRef, ['current', 'contains']) && pickerRef.current.contains(e.target)))
				return;

			handleCancel();
		},
		[handleCancel, isDisplayPicker, pickerRef.current],
	);

	useEffect(() => {
		document.addEventListener('mousedown', handleClick);

		// cleanup
		return () => document.removeEventListener('mousedown', handleClick);
	}, [handleClick]);

	const dateFormatStr = intl.formatMessage(systemMessages.fullDateFormat);

	const picker = useMemo(() => {
		const rangeValue = [startDate, endDate];

		return (
			<div
				ref={pickerRef}
				style={Object.assign(
					{
						backgroundColor: theme.backgroundColorBright,
						paddingBlock: theme.padding,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					},
					Boolean(!isPickerStandAlone) && {
						position: 'absolute',
						top: 40,
						boxShadow: theme.webShadow.boxShadow,
						border: `0.1px solid ${CALENDAR_BORDER_COLOR}`,
					},
					calendarContainerStyle,
				)}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						direction: 'ltr',
						padding: theme.padding,
						paddingBottom: 2 * theme.padding,
					}}
				>
					<DateRange
						value={rangeValue}
						locale={lang}
						selectRange={true}
						maxDate={maxDate}
						onChange={val => {
							if (!Array.isArray(val))
								// the onchange is called on every keystroke, only when date complete it sends an array
								return;

							handleDateRangeChange(val);
						}}
						clearIcon={null}
						calendarIcon={null}
						disableCalendar={true}
						dayPlaceholder={'dd'}
						monthPlaceholder={'mm'}
						yearPlaceholder={'yyyy'}
					/>
				</div>

				<Calendar
					value={rangeValue}
					locale={lang}
					selectRange={true}
					maxDate={maxDate}
					onClickDay={handlePickerDateSelect}
				/>

				<div style={{ ...styles.buttonsContainer, direction: rtl ? 'rtl' : 'ltr' }}>
					{Boolean(!isDoneOnEndDate) && (
						<Text style={styles.button} onClick={handleSave}>
							{systemMessages.save}
						</Text>
					)}
					<Text style={styles.button} onClick={handleClear}>
						{systemMessages.clear}
					</Text>
					{Boolean(!isDoneOnEndDate) && (
						<Text style={styles.button} onClick={handleCancel}>
							{systemMessages.cancel}
						</Text>
					)}
				</div>
			</div>
		);
	}, [
		handleCancel,
		handleClear,
		handleDateRangeChange,
		handlePickerDateSelect,
		handleSave,
		lang,
		maxDate,
		rtl,
		startDate,
		endDate,
		isPickerStandAlone,
	]);

	/*
	|| ============================ ||
	||            RENDER            ||
	|| ============================ ||
	*/

	if (isPickerStandAlone) return picker;

	return (
		<div style={{ position: 'relative' }}>
			<div
				ref={inputRef}
				onClick={() => setIsDisplayPicker(true)}
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					cursor: 'pointer',
					borderColor: isInputHover ? theme.placeholderTextColor : '#dee0e5',
					color: '#838383',
					borderRadius: 4,
					borderStyle: 'solid',
					borderWidth: 1,
					minWidth: 190,
					height: 35,
					[`padding${rtl ? 'Left' : 'Right'}`]: theme.verticalMargin,
					...inputStyle,
				}}
			>
				<span style={{ paddingRight: theme.padding, paddingLeft: theme.padding, direction: 'ltr' }}>
					{Boolean(lastSavedRange.start && lastSavedRange.end)
						? `${moment(lastSavedRange.start).format(dateFormatStr)} - ${moment(lastSavedRange.end).format(
								dateFormatStr,
						  )}`
						: placeholder}
				</span>
				<ExpandMoreIcon />
			</div>

			{Boolean(isDisplayPicker) && picker}
		</div>
	);
};

const styles = {
	button: {
		height: 'fit-content',
		cursor: 'pointer',
		backgroundColor: theme.backgroundColor,
		margin: theme.verticalMargin,
		paddingBlock: (theme.padding * 2) / 10,
		paddingInline: theme.padding,
		borderRadius: 4,
		fontSize: theme.fontSize,
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		padding: theme.padding,
	},
};

DateRangePicker = injectIntl(DateRangePicker);
export default DateRangePicker;
