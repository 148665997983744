import React from "react";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import cx from "classnames";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import SideBarNav from "../../views/Menus/SideBarNav";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import analyticsMessages from "../../../common/analytics/analyticsMessages";

// core components
import headerStyle from "../../assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";
import theme from "../../assets/css/theme";
import { newId } from "../CementoComponents/funcs";
import { saveUIParams, draftValidator } from "../../../common/ui/actions";
import {connectContext} from 'react-connect-context';
import {ProjectContext} from '../../../common/projects/contexts';
import * as permissionsFunc from '../../../common/permissions/funcs';

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.id = newId("HeaderLinks");
    this.state = {
      openAvatar: false,
      miniActive: true,
      routesArray: [],
      sideBarOpen: false,
    };

    this.activeRoute = this.activeRoute.bind(this);
    this.convertRouteUrl = this.convertRouteUrl.bind(this);
    this.getRoutesArray = this.getRoutesArray.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};
    let urlQueryParams = null;

    if (
      props.permissions !== nextProps.permissions ||
      props.location !== nextProps.location ||
      props.isCementoTeamViewer !== nextProps.isCementoTeamViewer ||
      props.getNested(["urlParams", "containerUrl"]) !==
        nextProps.getNested(["urlParams", "containerUrl"])
    )
      newStateChanges.routesArray = this.getRoutesArray(nextProps);

    let contentType =
      nextProps.getNested(["urlParams", "contentType"]) ||
      nextProps.contentType;
    if (nextProps.location && nextProps.location.search) {
      urlQueryParams = nextProps.location.search.replace("?", "");
    }

    const sectionParam = nextProps.getNested(["match", "params", "section"]);
    if (
      (!newStateChanges.selectedFilterSet ||
        props.location != nextProps.location) &&
      nextProps.menus &&
      nextProps.menus[contentType] &&
      nextProps.menus[contentType]["buildingViewSection"] &&
      nextProps.menus[contentType]["buildingViewSection"].options
    ) {
      let sectionMapping = {
        locationContainerPage: "buildingViewSection",
        dashboard: "dashboardSection",
        analytics: "analytics",
      };
      let section = sectionMapping[sectionParam] || "buildingViewSection";
      if (urlQueryParams)
        newStateChanges.selectedFilterSet = nextProps
          .getNested(["menus", contentType, section, "options"], [])
          .filter((x) => x.query == urlQueryParams)[0];
      else
        newStateChanges.selectedFilterSet = nextProps
          .getNested(["menus", contentType, section, "options"], [])
          .filter((x) => nextProps.location.pathname.indexOf(x.id) != -1)[0];

      if (!newStateChanges.selectedFilterSet)
        newStateChanges.selectedFilterSet = nextProps.getNested([
          "menus",
          contentType,
          section,
          "options",
          0,
        ]);
    } else if (
      (!newStateChanges.selectedFilterSet ||
        props.location != nextProps.location) &&
      nextProps.getNested(["menus", contentType, "settings", "options"])
    ) {
      newStateChanges.selectedFilterSet = nextProps
        .getNested(["menus", contentType, "settings", "options"], [])
        .filter((x) => x.subjectType === sectionParam)[0];
    } else if (
      !newStateChanges.selectedFilterSet &&
      nextProps.getNested(["menus", contentType, sectionParam, "options"])
    ) {
      newStateChanges.selectedFilterSet = nextProps.menus[contentType][
        sectionParam
      ].options.filter(
        (option) => nextProps.location.pathname.indexOf(option.id) != -1
      )[0];
      if (!newStateChanges.selectedFilterSet)
        newStateChanges.selectedFilterSet = nextProps.getNested([
          "menus",
          contentType,
          sectionParam,
          "options",
        ])[0];
    } else if (!newStateChanges.selectedFilterSet) {
      Object.values(nextProps.getNested(["menus", contentType], {})).forEach(
        (content) =>
          content.options.forEach((option) => {
            if (
              !newStateChanges.selectedFilterSet &&
              option.type == "contentRoot"
            )
              newStateChanges.selectedFilterSet = option;
          })
      );
    }

    let curr = Boolean(
      nextProps.getNested(["headerParams", "sideBarParams", "open"]) ||
        nextProps.getNested(["headerParams", "sideBarParams", "alwaysOpen"])
    );
    if (this.state.sideBarOpen != curr && curr != null)
      newStateChanges.sideBarOpen = curr;

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  activeRoute(routeName, routeIndex, nextProps) {
    const { location, routes } = nextProps;
    let routeUrl = location.search
      ? routeName.replace(location.search, "")
      : routeName;
    let otherRouteContainSameUrl = routeUrl;
    (routes || []).map((r, index) => {
      if (index == routeIndex) return null;
      let url = this.convertRouteUrl(r, nextProps);
      otherRouteContainSameUrl =
        url.length > otherRouteContainSameUrl.length &&
        location.pathname.indexOf(url) > -1
          ? url
          : otherRouteContainSameUrl;
    });
    let isActive =
      location.pathname.indexOf(routeUrl) > -1 &&
      otherRouteContainSameUrl == routeUrl;
    return isActive;
  }

  getRoutesArray(nextProps) {
    const {
      routes, onRouteSelected, intl,
      urlParams, isCementoTeamViewer, viewer,
      selectedProjectId, configurations
    } = nextProps;
    let activeRoute = null;
    let routesArray = [];
    (routes || []).forEach((prop, index) => {
      if (
        prop.redirect ||
        (prop.hide && !configurations.getNested(['features', prop.contentType, "isActive"], false) && !configurations.getNested(['features', prop.contentType, "web", "isVisible"], false) && !isCementoTeamViewer) ||
        (prop.cementoTeamOnly && !isCementoTeamViewer)
      )
        return null;
      if (
        prop.hideCondition &&
        urlParams.get(prop.hideCondition.key) === prop.hideCondition.value
      )
        return null;

      if (prop.checkPermissions?.length) {
        const isAllowed = permissionsFunc.getActionPermissions(viewer, selectedProjectId, ...prop.checkPermissions);

        if (!isAllowed) {
          return null;
        }
      }

      let route = { ...prop };
      route.routeText =
        typeof prop.name == "object"
          ? intl.formatMessage(prop.name)
          : prop.name;
      route.routeTo = this.convertRouteUrl(prop, nextProps);
      route.isActive = this.activeRoute(route.routeTo, index, nextProps);
      activeRoute = route.isActive ? route : activeRoute;
      routesArray.push(route);
    });

    if (activeRoute && onRouteSelected) onRouteSelected(activeRoute);
    return routesArray;
  }

  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  convertRouteUrl(routeObject, nextProps) {
    const { urlParams, isSecondary, location } = nextProps;
    let url = routeObject.path;
    let routeTo = url
      .replace(":selectedProjectId", urlParams.get("selectedProjectId"))
      .replace(":selectedCompanyId", urlParams.get("selectedCompanyId"));
    if (routeObject.contentType)
      routeTo = routeTo.replace(":contentType", routeObject.contentType);
    routeTo = routeTo.replace("/:section", "");
    let containerUrl = urlParams.get("containerUrl") || "";
    routeTo = isSecondary
      ? (containerUrl.endsWith("/")
          ? containerUrl.slice(0, containerUrl.length - 1)
          : containerUrl) + routeTo
      : routeTo;
    //routeTo += location.getNested(['search'],'');
    return routeTo;
  }

  render() {
    const {
      classes,
      isSecondary,
      rtl,
      routingMode,
      urlParams,
      headerParams,
      menus,
      uiParams,
      draftValidator,
    } = this.props;
    const { routesArray, sideBarOpen, selectedFilterSet } = this.state;

    let contentType =
      urlParams?.getNested(["contentType"]) || this.props.contentType;
    let sideBarWidth = theme.sidebarWidth;
    let params = headerParams || {};

    if (!isSecondary)
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "inherit",
            height: "inherit",
            backgroundColor: "transparent",
            boxShadow: "none",
            zIndex: theme.zIndexes.headerLinksSecondary,
          }}
        >
          <div
            style={{
              display: "flex",
              height: "inherit",
              flex: 1,
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            {Boolean(
              (!isSecondary && routesArray && routesArray.length > 0) ||
                isSecondary
            ) &&
              routesArray.map((curr, index) => {
                if (!curr) return null;
                let isActiveRoute = curr.isActive;
                const navLinkClasses = cx({
                  [classes.itemLink]: true,
                  [classes.itemLinkActive]: isActiveRoute,
                });
                const itemText =
                  classes.itemText +
                  " " +
                  cx({
                    [classes.itemTextActive]: isActiveRoute,
                    [classes.itemTextSecondary]: isSecondary,
                    [classes.itemTextSecondaryActive]:
                      isSecondary && isActiveRoute,
                  });

                let Link;
                Link = routingMode ? (
                  <div
                    key={this.id + index}
                    onClick={() =>
                      draftValidator(
                        this.props.history.push(
                          "/main/projectContainerPage/" +
                            urlParams.get("selectedProjectId") +
                            "/" +
                            curr.path
                        )
                      )
                    }
                    key={index}
                    to={curr.routeTo}
                    style={{ cursor: "pointer", height: "100%" }}
                    className={navLinkClasses}
                  >
                    <div className={itemText}>{curr.routeText}</div>
                  </div>
                ) : (
                  <NavLink
                    key={this.id + index}
                    to={curr.routeTo}
                    style={{ height: "100%" }}
                    className={navLinkClasses}
                  >
                    <div className={itemText}>{curr.routeText}</div>
                  </NavLink>
                );

                return Link;
              })}
          </div>
        </div>
      );

    return (
      <div style={{ zIndex: theme.zIndexes.headerLinks }}>
        {Boolean(params.headerComponent) && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "inherit",
              height: theme.headerHeightSecondary,
              width: "calc(100vw - " + sideBarWidth + "px)",
              [rtl ? "marginRight" : "marginLeft"]: sideBarWidth,
              backgroundColor: theme.backgroundColorBright,
              boxShadow: (rtl ? -3 : 3) + "px 0 8px rgba(0, 0, 0, 0.3)",
              zIndex: theme.zIndexes.headerLinksSecondary,
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                fontWeight: 500,
                color: theme.headerColorDark,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                position: "relative",
              }}
            >
              {params.headerComponent}
            </div>
          </div>
        )}
        {Boolean(
          menus && menus[contentType] && !(params.sideBarParams || {}).hidden
        ) && (
          <SideBarNav
            options={menus[contentType]}
            disabled={
              !menus[contentType]
              //   || Object.keys(menus[contentType]).length < 2
            }
            caption={analyticsMessages.reports}
            selectedValue={selectedFilterSet}
            onOpenOrClose={(currNavState) => {
              this.setState({ sideBarOpen: currNavState });
            }}
            independentMode={!params.headerComponent}
            style={{ top: theme.headerHeight }}
            onChange={(selectedFilterSet) => {
              let scope = urlParams.getNested(["scope"], "project");
              let scopeId;
              if (scope == "company")
                scopeId = urlParams.getNested(["selectedCompanyId"]);
              if (scope == "project")
                scopeId = urlParams.getNested(["selectedProjectId"]);

              draftValidator(() => {
                let newUrl = `/main/${scope}ContainerPage/${scopeId}/`;
                if (selectedFilterSet.contentType && selectedFilterSet.contentType != contentType)
                  newUrl += `${selectedFilterSet.contentType}/${selectedFilterSet.type}/${selectedFilterSet.subjectType}`;
                else if (selectedFilterSet.type == "contentRoot")
                  newUrl += `${contentType}/`;
                else if (selectedFilterSet.type == "dashboardView")
                  newUrl += `${contentType}/dashboard`;
                else if (selectedFilterSet.type === "projectManagerView")
                  newUrl += `${contentType}/${selectedFilterSet.subjectType}`;
                else if (
                  selectedFilterSet.type === "none" &&
                  selectedFilterSet.subjectType
                )
                  newUrl += `${selectedFilterSet.subjectType}`;
                else if (selectedFilterSet.type != "buildingView")
                  newUrl += `${contentType}/analytics/${selectedFilterSet.id}`;
                else newUrl += `${contentType}/locationContainerPage`;

                if (selectedFilterSet.query)
                  newUrl += `?${selectedFilterSet.query}`;

                this.props.history.push(newUrl);
              });
            }}
            beforeChange={(selectedFilterSet) => {
              if (
                selectedFilterSet.type != "dashboardView" &&
                selectedFilterSet.type != "buildingView"
              )
                this.props.saveUIParams({
                  unselectCellAndBackOrCloseSideCard: new Date(),
                });
            }}
            {...(params.sideBarParams || {})}
            open={sideBarOpen}
          />
        )}
      </div>
    );
  }
}

const styles = {
  itemText: {
    color: theme.brandNeutral,
    margin: 0,
    padding: 0,
    fontSize: "14px",
    transform: "translate3d(0px, 0, 0)",
    opacity: "1",
    position: "relative",
    display: "block",
    whiteSpace: "nowrap",
    transition: "all .33s cubic-bezier(0.4, 0, 0.2, 1) 0s",
  },
  itemTextSecondary: {
    color: "#2e231d",
  },
  itemLink: {
    padding: 5,
    transition: "all .33s cubic-bezier(0.4, 0, 0.2, 1) 0s",
    position: "relative",
    color: theme.backgroundColorBright,
    width: "auto",
    "&:hover": {
      outline: "none",
      backgroundColor: "rgba(200, 200, 200, 0.2)",
      boxShadow: "none",
    },
    "&,&:hover,&:focus": {
      color: "inherit",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: theme.paddingSize * 1.3,
    paddingLeft: theme.paddingSize * 1.3,
    minWidth: 80,
  },
  itemTextActive: {
    color: theme.backgroundColorBright,
    fontWeight: theme.bold,
  },
  itemTextSecondaryActive: {
    color: theme.brandPrimary,
  },
  itemLinkActive: {
    borderBottom: "3px solid " + theme.brandPrimary,
  },
};

HeaderLinks = withStyles(theme.combineStyles(headerStyle, styles))(HeaderLinks);
HeaderLinks = withRouter(HeaderLinks);
const enhance = compose(
  connect(
    (state) => ({
      rtl: state.app.rtl,
      headerTitle: state.ui.headerTitle,
      urlParams: state.ui.urlParams,
      uiParams: state.ui.uiParams,
      permissions: state.permissions.map,
    }),
    { saveUIParams, draftValidator }
  ),
  connectContext(ProjectContext.Consumer)
);
export default enhance(HeaderLinks);
