import { defineMessages } from 'react-intl';

export default defineMessages({
  empty: {
    defaultMessage: 'You have not joined any project yet.',
    id: 'projects.projects.empty'
  },
  noProjectsFound: {
    defaultMessage: 'No projects found match the search criteria',
    id: 'projects.projects.noProjectsFound'
  },
  searchProject: {
    defaultMessage: 'Project search',
    id: 'projects.projects.searchProject'
  },
  whenGCInvite: {
    defaultMessage: 'When GC invite you to join a project, it will be shown here.',
    id: 'projects.projects.whenGCInvite'
  },
  createNewProject: {
    defaultMessage: 'Start by creating a new project',
    id: 'projects.projects.createNewProject'
  },
  project: {
    defaultMessage: 'Project view',
    id: 'projects.projects.project'
  },
  dashboard: {
    defaultMessage: 'Quality',
    id: 'projects.projects.dashboard'
  },  
  projectManager: {
    defaultMessage: 'Project settings',
    id: 'projects.manage.settings'
  },
  title: {
    defaultMessage: 'Project Members',
    id: 'projects.members.title'
  },
  loading: {
    defaultMessage: 'Loading Projects',
    id: 'projects.projects.loading'
  },
  loadingProjectData: {
    defaultMessage: 'Loading Project',
    id: 'projects.projects.loadingProjectData'
  },
  loadingMembers: {
    defaultMessage: 'Loading Project Members',
    id: 'projects.members.loading'
  },
  searchMembers: {
    defaultMessage: 'Search by name',
    id: 'projects.members.search'
  },
  addProjectMembers: {
    defaultMessage: 'Add a project member',
    id: 'projects.members.addProjectMembers'
  },
  addNewProject: {
    defaultMessage: 'Add new project',
    id: 'projects.projects.addNewProject'
  },
  selectProject: {
    defaultMessage: 'Select Project',
    id: 'projects.projects.selectProject'
  },
  addingUserToProject: {
    defaultMessage: 'Adding {user} to project...',
    id: 'projects.projects.addingUserToProject'
  },
  userAddedToProject: {
    defaultMessage: '{user} added to project successfully',
    id: 'projects.projects.userAddedToProject'
  },
  userRemovedFromProject: {
    defaultMessage: '{user} removed from project successfully',
    id: 'projects.projects.userRemovedFromProject'
  },
  about: {
    defaultMessage: 'About',
    id: 'projects.facade.about'
  },   
  notPermittedToWebProject: {
    "defaultMessage": "You have no access permissions for the web application\nPlease use out mobile application",
    "id": "projects.permissions.notPermittedToWebProject"
  },   
  noProjects: {
    "defaultMessage": "Haven’t found any project you are member of",
    "id": "projects.permissions.noProjects"
  },
});
