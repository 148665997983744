import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import moment from "moment";
import { CSVLink } from "react-csv";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import PropertyCard from "./PropertyCard.js";
import PropertyRow from "./PropertyRow.js";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import systemMessages from '../../../common/app/systemMessages'; 
import { startToast } from '../../../common/app/actions';
import { saveProperties, getNewId } from '../../../common/propertiesTypes/actions'
import { convertCSVtoSectionsArray } from './funcs'
import theme from "../../assets/css/theme";
import ParentChildView from "../Checklists/ParentChildView";
import * as propertyConstantTypes from '../../../common/propertiesTypes/propertiesTypes';
import _ from 'lodash';
import { getProjectsSelectionListValues, updateTagsOptions } from "../../../common/propertiesTypes/funcs";
import * as utils from '../../../common/lib/utils/utils';


const PapaParse = require('papaparse/papaparse.min.js');
const EXTRA_PROPERTIES_DEFAULT_SECTION = 'EXTRA_PROPERTIES_DEFAULT_SECTION';
const GROUP_PROPERTY_ID = 'groups';
class PropertiesManager extends React.Component {
  constructor(props) {
    super(props);
    //this.onToggleModeChange = this.onToggleModeChange.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.setSectionsArray = this.setSectionsArray.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.onDataChange = this.onDataChange.bind(this);
    this.onValuesListsChanges = this.onValuesListsChanges.bind(this);
    this.onValuesListsCreation = this.onValuesListsCreation.bind(this);
    this.onItemDelete = this.onItemDelete.bind(this);
    this.onItemDuplicate = this.onItemDuplicate.bind(this);
    this.saveAllChanged = this.saveAllChanged.bind(this);
    this.onChangeMode = this.onChangeMode.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onImport = this.onImport.bind(this);
    this.onExport = this.onExport.bind(this);
    this.formatAndDisplayImportErrors =
      this.formatAndDisplayImportErrors.bind(this);
    this.defaultState = {
      activeChanges: false,
      fromExcelMode: false,
      editChecklistsMode: true,
      sections: [],
      dynamicValuesLists: {},
    };
    this.state = Object.assign({}, this.defaultState);
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  onChangeMode() {
    this.setState({
      selectedChildIndex: null,
      selectedParentIndex: null,
    });
  }

  onRowSelect(parentIndex, childIndex) {
    this.setState({
      selectedChildIndex: childIndex,
      selectedParentIndex: parentIndex,
    });
  }

  onSort(newSections) {
    this.setState({
      activeChanges: true,
      selectedChildIndex: null,
      selectedParentIndex: null,
      sections: newSections,
    });
  }

  onItemDelete(newSections, selectedSectionIndex, deletedItem) {
    let sections = [...newSections];

    if (deletedItem) {
      let deletedItemId = deletedItem.id;
      sections[selectedSectionIndex].childs = sections[selectedSectionIndex].childs.map(child => {
        let childExtraTypes = child.getNested(['extraTypes']) || [];
        if (childExtraTypes.length) {
          let newExtraTypes = childExtraTypes.filter(id => id != deletedItemId);
          return child.setNested(['extraTypes'], newExtraTypes);
        }
        else
          return child;
      });
    }
    this.setState({
      activeChanges: true,
      selectedChildIndex: null,
      selectedParentIndex: null,
      sections,
    });
  }

  setComponentData(props, nextProps) {
    const { propertiesSections, propertiesTypes } = props;

    let newStateChanges = {};

    if (
      this.state.lang !=
      nextProps.getNested(
        ["projects", nextProps.selectedProjectId, "lang"],
        "en"
      )
    )
      newStateChanges.lang = nextProps.getNested(
        ["projects", nextProps.selectedProjectId, "lang"],
        "en"
      );

    if (props.subjectName != nextProps.subjectName)
      newStateChanges = Object.assign(newStateChanges, this.defaultState);

    if (nextProps.propertiesTypes && nextProps.propertiesSections && (propertiesTypes != nextProps.propertiesTypes || propertiesSections != nextProps.propertiesSections || props.subjectName != nextProps.subjectName)) 
    newStateChanges.sections = this.setSectionsArray(nextProps.propertiesSections, nextProps.propertiesTypes, nextProps.subjectName)

    if (Object.keys(newStateChanges).length > 0)
      this.setState(newStateChanges);
  }

  setSectionsArray(propertiesSections, propertiesTypes, subjectName) {
    const { lang } = this.props;
    if (!propertiesTypes || !propertiesSections)
      return [];

    let sectionsMap = {};

    let sectionOrdinalNo=0
    _.values(propertiesTypes[subjectName])
      .sort((a, b) => (a.ordinalNo - b.ordinalNo)|| a.id.localeCompare(b.id))
      .forEach(prop => {
        const sectionId = prop.sectionId || EXTRA_PROPERTIES_DEFAULT_SECTION;
        const currentPropertySection = _.get(propertiesSections, [subjectName, sectionId]);
        if (currentPropertySection || sectionId == EXTRA_PROPERTIES_DEFAULT_SECTION) {
          if (!sectionsMap[sectionId])
            sectionsMap[sectionId] = {
              parent: sectionId == EXTRA_PROPERTIES_DEFAULT_SECTION ?
                { id: EXTRA_PROPERTIES_DEFAULT_SECTION, title: { [lang]: EXTRA_PROPERTIES_DEFAULT_SECTION } } :
                currentPropertySection || {},
              childs: [],
              ordinalNo: sectionOrdinalNo,
            };
          sectionsMap[sectionId].childs.push(prop);
        }
        sectionOrdinalNo++;
      });

    const sectionsArray = Object.values(sectionsMap).sort((a, b) => (a.ordinalNo - b.ordinalNo) || a.parent.id.localeCompare(b.parent.id));
    return sectionsArray;
  }

  onDataChange(field, value) {
    const { selectedParentIndex, selectedChildIndex, sections, activeChanges } = this.state;

    let newActiveChanges = activeChanges || false;
    let newSections = (sections || []).slice();
    let selectedPropertySection = sections.getNested([selectedParentIndex, 'parent']);
    let selectedPropertyType = sections.getNested([selectedParentIndex, 'childs', selectedChildIndex]);

    if ((!selectedPropertySection && !selectedPropertyType) || !field || _.isUndefined(value))
      return;
    if (selectedPropertyType) {
      newActiveChanges = true;
      let updatedItem = selectedPropertyType.setNested(field, value);
      if (field[0] === "type" && value !== propertyConstantTypes.ARRAY)
        updatedItem = updatedItem.setNested(["innerTypes"], null);
      newSections[selectedParentIndex].childs[selectedChildIndex] = updatedItem;
      if (field[0] == "sectionId") {
        newSections[selectedParentIndex].childs.splice(selectedChildIndex, 1);
        sections.forEach((currSection) => {
          if (currSection.parent.id == value)
            currSection.childs.push(updatedItem);
        });
      }
    }
    else if (selectedPropertySection) {
      newActiveChanges = true;
      if (field[0] === "permissions")
        value = value && value.length ? value : null;
      let updatedChecklist = selectedPropertySection.setNested(field, value);
      newSections[selectedParentIndex].parent = updatedChecklist;
    }

    this.setState({activeChanges: newActiveChanges, sections: newSections})
  }

  onValuesListsCreation(listId, title, originalPropId) {
    const { dynamicValuesLists, lang } = this.state;
    let stateChanges = {
      dynamicValuesLists: { ...dynamicValuesLists },
      activeChanges: true
    };
    const list = {
      title: { [lang]: title },
      id: listId,
      originalPropId
    };

    _.set(stateChanges, ['dynamicValuesLists', listId], list);
    this.setState(stateChanges);
  }

  onValuesListsChanges(listId, valueId, value) {
    const { dynamicValuesLists, lang } = this.state;
    let stateChanges = {
      dynamicValuesLists: { ...dynamicValuesLists },
      activeChanges: true
    };
    _.set(stateChanges, ['dynamicValuesLists', listId, "values", valueId], value);
    this.setState(stateChanges);
  }

  async getLists() {
    const { selectedProjectId } = this.props;
    const lists = getProjectsSelectionListValues(selectedProjectId);
    return lists || {};
  }

  async componentDidMount() {
    const { lang } = this.props;
    let dynamicValuesLists = await this.getLists();
    this.setState({ dynamicValuesLists });
  }

  onItemDuplicate() {
    const { selectedProjectId, getNewId, subjectName } = this.props;
    const {
      selectedParentIndex,
      selectedChildIndex,
      sections,
      activeChanges,
      lang,
    } = this.state;

    let newActiveChanges = activeChanges || false;
    let selectedPropertySection = sections.getNested([
      selectedParentIndex,
      "parent",
    ]);
    let selectedPropertyType = sections.getNested([
      selectedParentIndex,
      "childs",
      selectedChildIndex,
    ]);

    try {
      if (selectedPropertyType) {
        let newId = getNewId(selectedProjectId, subjectName).payload.id;
        let propClone = selectedPropertyType.setNested("id", newId);
        propClone = propClone.setNested("values", null);
        propClone = propClone.setNested(
          ["title", lang],
          propClone.getNested(["title", lang]) +
            " (copy " +
            moment(new Date()).format("HH:mm:ss") +
            ")"
        );
        sections[selectedParentIndex].childs.splice(
          selectedChildIndex + 1,
          0,
          propClone
        );
        newActiveChanges = true;
      } else if (selectedPropertySection) {
        let newSectionId = getNewId(selectedProjectId, subjectName, true)
          .payload.id;
        let newSection = { parent: null, childs: [] };
        let sectionClone = selectedPropertySection.setNested(
          "id",
          newSectionId
        );
        sectionClone = sectionClone.setNested("locations", {});
        sectionClone = sectionClone.setNested(
          ["title", lang],
          sectionClone.getNested(["title", lang]) +
            " (copy " +
            moment(new Date()).format("HH:mm:ss") +
            ")"
        );
        newSection.parent = sectionClone;

        sections
          .getNested([selectedParentIndex, "childs"], [])
          .forEach((currProp) => {
            let newId = getNewId(selectedProjectId, subjectName).payload.id;
            let propClone = currProp.setNested("id", newId);
            propClone = propClone.setNested("values", null);
            propClone = propClone.setNested("sectionId", newSectionId);
            newSection.childs.push(propClone);
          });

        sections.splice(selectedParentIndex + 1, 0, newSection);
        newActiveChanges = true;
      }

      this.setState({
        selectedChildIndex: null,
        selectedParentIndex: null,
        activeChanges: newActiveChanges,
        sections: this.state.sections,
      });
    } catch (err) {
      console.error(err);
    }
  }

  async saveAllChanged() {
    const { saveProperties, startToast, subjectName, intl, viewer, scopeId, selectedProjectId } = this.props;
    const { sections, dynamicValuesLists, mergerUpdates } = this.state;
    const { haveUniqueTitles, nonUniqueTitle: { section, property } } = this.propertiesHaveUniqueTitles(sections);
    if (!haveUniqueTitles) {
      startToast({
        overlay: true,
        mandatory: true,
        message: `You cannot save two properties under the same section with the same name (section "${section}", property "${property}").`,
        actions: [{ message: systemMessages.ok, color: "success" }],
      });
      return;
    }

    let properties = {};
    let isGroupPropExists = false;
    let propertiesSections = [];
    let tagsOptions = {};
    let ordinalNo = 1;
    sections.forEach(currSection => {
      if (currSection.parent.id)
        propertiesSections.push(currSection.parent.toJS ? currSection.parent.toJS() : Object.assign(currSection.parent));
      currSection.childs.forEach(property => {
        property = property.toJS ? property.toJS() : Object.assign({}, property);
        if (property.id == 'groups') {
          property = property.setNested(['mandatory'], true);
          isGroupPropExists = true;
        }
        _.keys(property.tags).forEach(tag => tagsOptions[tag] = true);
        properties[property.id] = { ...property, ordinalNo };
        ordinalNo++;
      });
    });

    let allExtraTypes = {};
    properties.loopEach((k, prop) => {
      (prop.extraTypes || []).forEach(id => {
        if (properties[id]) properties[id].isExtra = true;
        allExtraTypes[id] = true;
      });
    });
    properties.loopEach((k, prop) => { if (prop.isExtra && !allExtraTypes[prop.id] && properties[prop.id]) properties[prop.id].isExtra = false; });
    
    if (_.keys(tagsOptions).length) {
      updateTagsOptions(tagsOptions);
    }

    let saveResult = { success: false };

    if (isGroupPropExists)
      saveResult = await saveProperties(selectedProjectId, subjectName, Object.values(properties), propertiesSections, dynamicValuesLists);


    const didSaveSucceeded = saveResult.success;
    const toastMessage = isGroupPropExists
      ? (didSaveSucceeded ? systemMessages.objectSavedSuccessfully : systemMessages.errorOnSave)
      : "You cannot save properties without define a 'Groups' property";
    const toastActionColor = didSaveSucceeded ? 'success' : 'danger';

    startToast({
      overlay: true,
      mandatory: true,
      message: toastMessage,
      values: didSaveSucceeded ? { objectName: intl.formatMessage(systemMessages.properties) } : {},
      actions: [
        { message: systemMessages.ok, color: toastActionColor },
      ]
    });

    this.setState({ activeChanges: false });
  }

  propertiesHaveUniqueTitles(sections) {
    let takenTitles = {};
    let nonUniqueTitle = {};
    let haveUniqueTitles = true;
    Object.values(sections).forEach((section) => {
      if (!haveUniqueTitles) return;

      const { childs, parent } = section;
      // TODO: if no childs or parent;

      const parentTitle = parent.getCementoTitle().trim().toLowerCase();
      takenTitles[parentTitle] = {};
      childs.forEach((childProp) => {
        if (!haveUniqueTitles) return;

        const childTitle = childProp.getCementoTitle().trim().toLowerCase();
        if (takenTitles[parentTitle][childTitle]) {
          haveUniqueTitles = false;
          nonUniqueTitle = {
            section: parent.getCementoTitle(),
            property: childProp.getCementoTitle(),
          };
          return;
        }

        takenTitles[parentTitle][childTitle] = true;
      });
    });

    return { haveUniqueTitles, nonUniqueTitle };
  }

  onExport() {
    const { sections, lang } = this.state;
    let toExport = [
      [
        "externalId",
        "type",
        "title",
        "selectionListValues",
        "isGroupsField",
        "showOnNull",
        "hideOnMobile",
        "universalId",
        "isExtra",
        "editable",
        "uploadFormat",
        "businessType",
        "isMulti",
      ],
    ];
    sections.forEach((section) => {
      let propertySection = section.parent;
      toExport.push([
        null,
        -1,
        propertySection.getNested(["title", lang]),
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ]);
      section.childs
        .sort((a, b) =>
          a.type == propertyConstantTypes.CERTIFICATION ? -1 : 1
        )
        .forEach((property) => {
          let selectionListValues = property.values
            ? property.values
                .map((v) =>
                  v.getNested(["title", lang], "").replace(/\"/g, "''")
                )
                .join(",")
            : null;
          toExport.push([
            property.externalId,
            property.type,
            property.getNested(["title", lang]),
            selectionListValues,
            property.id == "groups",
            property.showOnNullValue,
            property.hideOnMobile,
            property.universalId,
            property.isExtra,
            String(property.editable),
            property.defaultContentType,
            property.businessType,
            property.isMulti,
          ]);
        });
    });

    let uri = this.refs.toCSVcomponent.buildURI(toExport, true, null, ",");
    this.setState({ csvURI: uri }, () => {
      this.refs.CSVDownload.click();
    });
  }

  onImport(csv) {
    const { getNewId, selectedProjectId } = this.props;
    const { sections, lang } = this.state;
    let { sections: sectionsAddition, errors } = convertCSVtoSectionsArray(
      csv,
      selectedProjectId,
      lang,
      getNewId
    );
    if (errors.length) {
      this.formatAndDisplayImportErrors(errors);
      return;
    }
    let newSections = (sections || []).slice();
    newSections = newSections.concat(sectionsAddition);
    this.setState({ activeChanges: true, sections: newSections });
  }

  formatAndDisplayImportErrors(errors) {
    const { startToast } = this.props;
    const error = errors.join("\n");

    startToast({
      mandatory: true,
      overlay: true,
      message: systemMessages.errorOnImport,
      values: { error },
      actions: [{ message: systemMessages.ok, type: "success" }],
    });
  }

  render() {
    const { selectedProjectId, subjectName, projects, intl } = this.props;
    const { csvURI, sections, activeChanges, selectedParentIndex, selectedChildIndex, lang, dynamicValuesLists } = this.state;
    let btnStyle = { borderRadius:'5px', display:'flex', padding:'0px 5px', minWidth: 75, height:30, alignSelf:'center', justifyContent:'center', alignItems:'center', margin: 5, border: '1px solid ' + theme.brandPrimary + '85', color: theme.brandPrimary, cursor: 'pointer' };
    let selectedProject = projects.getNested([selectedProjectId], {});
    let propertySection = sections.getNested([selectedParentIndex, "parent"]);
    let propertyType = sections.getNested([
      selectedParentIndex,
      "childs",
      selectedChildIndex,
    ]);
    let showCard = propertySection || propertyType;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          flex: 1,
          justifyContent: "space-between",
        }}
      >
        <GridContainer spacing={8}>
          <GridItem xs={showCard ? 8 : 12}>
            <ParentChildView
              style={styles.cardStyle}
              editable={false}
              sections={sections}
              rowComponent={PropertyRow}
              selectedChildIndex={selectedChildIndex}
              selectedParentIndex={selectedParentIndex}
              onSort={this.onSort}
              onRowSelect={this.onRowSelect}
              onChangeMode={this.onChangeMode}
              onItemDuplicate={this.onItemDuplicate}
              onItemDelete={this.onItemDelete}
              parentSectionTitlePath={['title', lang]}
            />
          </GridItem>
          {Boolean(showCard) && 
          <GridItem xs={4}>
            <PropertyCard 
              subjectName={subjectName}
              style={styles.cardStyle}
              editMode={true}
              onChange={this.onDataChange}
              onValuesListsCreation={this.onValuesListsCreation}
              onValuesListsChanges={this.onValuesListsChanges}
              dynamicValuesLists={dynamicValuesLists}
              lang={lang}
              sections={sections}
              itemMode={Boolean(selectedChildIndex != null)}
              propertySection={propertySection} 
              propertyType={propertyType}/>
          </GridItem>}
        </GridContainer>
        <div style={{ width: "100%" }}>
          <GridContainer style={{ flexDirection: "row-reverse" }}>
            <GridItem xs={1}>
              <div
                style={{
                  ...btnStyle,
                  ...(!activeChanges
                    ? {
                        pointerEvents: "none",
                        color: theme.brandNeutral,
                        border: "1px solid " + theme.brandNeutral,
                        backgroundColor: theme.brandNeutral + "10",
                      }
                    : { backgroundColor: theme.brandPrimary + "10" }),
                }}
                onClick={this.saveAllChanged}
              >
                {intl.formatMessage(systemMessages.saveChanges)}
              </div>
            </GridItem>
            <GridItem xs={1}>
              <ImageUpload
                style={btnStyle}
                disabled={!selectedProjectId}
                readAsText={true}
                onFileLoaded={this.onImport}
                acceptFiles={".csv"}
              >
                {intl.formatMessage(systemMessages.manage.excelImport)}
              </ImageUpload>
            </GridItem>
            <GridItem xs={1}>
              <CSVLink ref="toCSVcomponent" data={[[]]} />
              <a
                ref="CSVDownload"
                download={
                  (selectedProject.title || selectedProject.address || "") +
                  "_checklists.csv"
                }
                href={csvURI}
                style={{ display: "none" }}
              ></a>
              <div
                style={{
                  ...btnStyle,
                  ...(!selectedProjectId || !sections || sections.length == 0
                    ? {
                        pointerEvents: "none",
                        color: theme.brandNeutral,
                        border: "1px solid " + theme.brandNeutral,
                      }
                    : {}),
                }}
                onClick={this.onExport}
              >
                {intl.formatMessage(systemMessages.manage.excelExport)}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

let styles = {
  cardStyle: {
    width: "100%",
    padding: theme.paddingSize,
    boxShadow: "none",
    backgroundColor: theme.backgroundColorBright,
  },
};

PropertiesManager = withStyles(buttonStyle)(PropertiesManager);
PropertiesManager = injectIntl(PropertiesManager);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      rtl: state.app.rtl,
    }),
    { startToast, saveProperties, getNewId }
  )
);
export default enhance(PropertiesManager);

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  handleImageChange(e) {
    const { readAsText } = this.props;
    try {
      e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];
      reader.onloadend = (() => {
        const { onFileLoaded } = this.props;
        if (onFileLoaded) {
          const csvData = PapaParse.parse(reader.result, {
            error: () => console.log("error reading csv"),
          });
          onFileLoaded(csvData.data);
        }
      }).bind(this);

      if (readAsText) reader.readAsText(file);
      else reader.readAsDataURL(file);
    } catch (e) {
      console.error(e);
    }
  }
  handleSubmit(e) {
    e.preventDefault();
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  render() {
    var { acceptFiles, children, disabled, style } = this.props;
    return (
      <div
        style={{
          ...(style || {}),
          ...(disabled
            ? {
                pointerEvents: "none",
                color: theme.brandNeutral,
                border: "1px solid " + theme.brandNeutral,
              }
            : {}),
        }}
        onClick={this.handleClick}
      >
        <input
          type="file"
          className="fileinput text-center"
          style={{ display: "none" }}
          onChange={this.handleImageChange}
          ref="fileInput"
          accept={acceptFiles}
        />
        {children}
      </div>
    );
  }
}
