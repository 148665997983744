import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import Text from "./Text";
import theme from "../../assets/css/theme";

class Paging extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.state = {
      //currPage: 0,
      dataCount: 1,
      countPerPage: 1,
    };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};

    // if (props.defaultPage != nextProps.defaultPage)
    //   newStateChanges.currPage = nextProps.defaultPage;

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  handlePageClick(page) {
    const { currPage, style, dataCount, countPerPage, onClick } = this.props;
    const { pageHover } = this.state;

    //this.setState({currPage:page});
    if (onClick) onClick(page);
  }

  render() {
    const { currPage, style, dataCount, countPerPage, onClick } = this.props;
    const { pageHover } = this.state;

    let pagesButtonsCount = Math.ceil((dataCount || 1) / countPerPage);
    let pagesButtonsArray = [];
    let maxButtonsToShow = 5;
    let firstPageToShow = Math.max(
      currPage - Math.floor(maxButtonsToShow / 2),
      0
    );
    for (
      let i = firstPageToShow;
      i < pagesButtonsCount && i < firstPageToShow + maxButtonsToShow;
      i++
    )
      pagesButtonsArray.push(i);

    let pagePluse = Math.max(currPage - 1, 0);
    let pageMinus = Math.min(currPage + 1, pagesButtonsCount - 1);
    let showPages = pagesButtonsArray.length > 1;

    if (showPages)
      return (
        <div style={style}>
          <PageButton
            page={pagePluse}
            pageText={"<"}
            style={{ opacity: currPage == 0 ? 0 : 1 }}
            onClick={this.handlePageClick}
          />
          {pagesButtonsArray.map((page, i) => (
            <PageButton
              key={i}
              isActive={page == currPage}
              page={page}
              pageText={page + 1}
              onClick={this.handlePageClick}
            />
          ))}
          <PageButton
            page={pageMinus}
            pageText={">"}
            style={{
              opacity: currPage == pagesButtonsArray.length - 1 ? 0 : 1,
            }}
            onClick={this.handlePageClick}
          />
        </div>
      );

    return null;
  }
}

Paging.defaultProps = {
  currPage: 0,
};

let styles = {};

Paging = withStyles(styles)(Paging);
const enhance = compose(connectContext(ProjectContext.Consumer));
export default enhance(Paging);

class PageButton extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.setHover = this.setHover.bind(this);
    this.state = {};
  }

  onClick() {
    const { onClick, page } = this.props;
    onClick(page);
  }

  setHover() {
    const { hover } = this.state;
    this.setState({ hover: !hover });
  }

  render() {
    const { page, pageText, isActive, style } = this.props;
    const { hover } = this.state;

    return (
      <div
        style={{ cursor: "pointer", ...style }}
        onMouseOver={this.setHover}
        onMouseOut={this.setHover}
        onClick={this.onClick}
      >
        <Text
          noSelect={true}
          style={{
            cursor: "pointer",
            color: theme.brandPrimary,
            fontSize: isActive || hover ? 24 : 18,
            fontWeight: theme.strongBold,
            margin: 5,
            transition: "all 200ms ease 0s",
          }}
        >
          {pageText}
        </Text>
      </div>
    );
  }
}

PageButton.defaultProps = {
  style: {},
};
