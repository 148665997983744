import { platformActions } from "../platformActions";
import { envParams } from "../configureMiddleware";
import { validateUpdateCompletion } from "../firebase/funcs";

export async function updateForms (projectId, formId, formValues) {
    const firebase = platformActions.firebase.getFirebase();
    const { apiServer } = envParams;
    let scope = "projects";
    let scopeId = projectId;
    let updates = {};

    updates['templates/configurations/projects/' + projectId + '/forms/' + formId] = Object.assign({}, formValues, {id: formId});

    try {
        await validateUpdateCompletion(firebase.database().ref(), updates);
        await new Promise((resolve,reject) => {
            setTimeout(async () => {
                await platformActions.net.fetch(apiServer + `/v1/services/templates/merge?scope=${scope}&scopeId=${scopeId}`);
                resolve();
            }, 5000);
        })

        return true;
    }
    catch {
        return false;
    }
}