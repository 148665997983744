import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import _ from "lodash";
import AwesomeDebouncePromise from "awesome-debounce-promise";

// Components
import Buildings from "../Locations/Buildings.js";
import SplitViewPage from "../../layouts/SplitViewPage";

// Other
import { updateProjectFields } from "../../../common/projects/actions";
import {
  ProjectManagerContext,
  ProjectStructureContext,
} from "../../../common/projects/contexts";
import theme from "../../assets/css/theme";
import { OrderedMap } from "immutable";
import { Field } from "./config/projectManagerConfig";
import {
  updateLocalBuilding,
  getNewBuildingId,
  updateBuilding,
} from "../../../common/buildings/actions";
import {
  updateFloors,
  updateLocalFloor,
  getNewFloorId,
} from "../../../common/floors/actions";
import {
  updateUnits,
  getNewUnitId,
  updateLocalUnit,
} from "../../../common/units/actions";
import { baseRemoveNested } from "./ProjectManager2_0";

class ProjectStructure extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeLocation = this.handleChangeLocation.bind(this);
    this.recalcHeader = this.recalcHeader.bind(this);
    this.prepareDataForExcelExport = this.prepareDataForExcelExport.bind(this);
    this.updateFloorSortedUnits = this.updateFloorSortedUnits.bind(this);
    this.handleConfirmSave = this.handleConfirmSave.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.state = {
      newlyAddedLocationObjects: {},
      sortedUnitsPerBuilding: {},
      isMoving: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({ firstMount: true }, this.props);
  }
  componentDidMount() {
    const { setIsProjectStructurePage } = this.props;

    setIsProjectStructurePage(true);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.setComponentData(this.props, nextProps);
  }

  componentDidUpdate(prevProps) {
    const { isEditMode } = this.props;
    if (isEditMode != prevProps.isEditMode) this.recalcHeader();
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};

    if (props.firstMount) {
      const { selectedProjectId, getBuildings, getFloors, getUnits } =
        nextProps;

      getBuildings(selectedProjectId);
      getFloors(selectedProjectId);
      getUnits(selectedProjectId);

      this.recalcHeader();
    }

    if (
      props.isValDiff(nextProps, ["buildings"]) ||
      props.isValDiff(nextProps, ["floors"]) ||
      props.isValDiff(nextProps, ["units"])
    ) {
      const { buildings, floors, units } = nextProps;
      newStateChanges.sortedUnitsPerBuilding = this.getSortedUnitsPerBuilding(
        buildings,
        floors,
        units
      );
    }

    if (Object.keys(newStateChanges).length) this.setState(newStateChanges);
  }

  componentWillUnmount() {
    const { handleConfirmCancel } = this.props;

    if (handleConfirmCancel) handleConfirmCancel();
  }

  getSortedUnitsPerBuilding(buildings, floors, units) {
    let sortedUnitsPerBuilding = {};
    if (!(buildings && floors && units)) return;

    buildings.loopEach((buildingId, building) => {
      let buildingUnits = units.getNested([buildingId], {});
      buildingUnits = buildingUnits.toJS ? buildingUnits.toJS() : buildingUnits;

      sortedUnitsPerBuilding[buildingId] = {};
      floors.getNested([buildingId], {}).loopEach((i, floor) => {
        const floorNum = floor.getNested(["num"]);
        sortedUnitsPerBuilding[buildingId][floorNum] = Object.values(
          buildingUnits
        ).filter((unit) => unit.getNested(["floor", "num"]) === floorNum);
        sortedUnitsPerBuilding[buildingId][floorNum] = sortedUnitsPerBuilding[
          buildingId
        ][floorNum].sort((a, b) =>
          a.ordinalNo !== undefined && b.ordinalNo !== undefined
            ? -1 * (b.ordinalNo - a.ordinalNo)
            : 0
        );
      });
    });

    return sortedUnitsPerBuilding;
  }

  updateFloorSortedUnits(newSortedChunk, buildingId, floorNumber) {
    const {
      setHasModifications,
      hasModifications,
      updateLocalUnits,
      selectedProjectId,
    } = this.props;
    const { isMoving } = this.state;

    this.setState(({ sortedUnitsPerBuilding }) => ({
      sortedUnitsPerBuilding: sortedUnitsPerBuilding.setNested(
        [buildingId, floorNumber],
        newSortedChunk
      ),
    }));

    let debouncedUpdateLocation = AwesomeDebouncePromise(
      () => updateLocalUnits(selectedProjectId, buildingId, newSortedChunk),
      0
    ); // Hack to give react-sortable enough  time to update (actually makes a difference, not sure why but don't remove)
    if (!isMoving) debouncedUpdateLocation();

    if (!hasModifications && setHasModifications) setHasModifications(true);
  }

  // FOR ENABLING THESE ACTIONS THROUGH UI===
  /* handleAddNewBuilding() {
    const { newlyAddedLocationObjects } = this.state;
    const { selectedProjectId, handleInputChange, updateLocalBuilding, getNewBuildingId } = this.props;
    
    const { buildingId } = getNewBuildingId(selectedProjectId).payload;
    const minFloor = 0;
    const maxFloor = 0;
    const newBuilding = {
      id: buildingId,
      minFloor,
      maxFloor,
      title: 'New building',
      createdAt: Date.now(),
    }

    if (updateLocalBuilding) updateLocalBuilding(selectedProjectId, newBuilding);

    this.setState({ newlyAddedLocationObjects: newlyAddedLocationObjects.setNested([buildingId, 'buildings', buildingId], newBuilding) });
  }
  
  handleAddNewFloor(buildingId, floorNum, type = null) {
    const { newlyAddedLocationObjects } = this.state;
    const { selectedProjectId, buildings, handleInputChange, updateLocalFloor, getNewFloorId } = this.props;
    
    const { floorId } = getNewFloorId(selectedProjectId, buildingId).payload;
    const newFloor = {
      id: floorId,
      num: floorNum,
      type,
    }

    let newMinFloor;
    if (buildings.getNested([buildingId, 'minFloor']) > floorNum)
      newMinFloor = floorNum;
    
    let newMaxFloor;
    if (buildings.getNested([buildingId, 'maxFloor']) < floorNum)
      newMaxFloor = floorNum;
    

    if (updateLocalFloor) updateLocalFloor(selectedProjectId, buildingId, newFloor);

    if (handleInputChange) {
      if (newMinFloor) handleInputChange(['locationsData', buildingId, 'buildings', buildingId, 'minFloor'], newMinFloor);
      if (newMaxFloor) handleInputChange(['locationsData', buildingId, 'buildings', buildingId, 'maxFloor'], newMaxFloor);
    }

    this.setState({ newlyAddedLocationObjects: newlyAddedLocationObjects.setNested([buildingId, 'floors', floorId], newFloor) });
  }

  handleAddNewUnit(buildingId, floorNum) {
    const { sortedUnitsPerBuilding, newlyAddedLocationObjects } = this.state;
    const { selectedProjectId, updateLocalUnit, getNewUnitId } = this.props;

    const ordinalNo = sortedUnitsPerBuilding.getNested([buildingId, floorNum], []).length || 1;
    
    const { unitId } = getNewUnitId(selectedProjectId, buildingId).payload;
    const newUnit = {
      id: unitId,
      floor: { num: floorNum },
      title: 'New unit',
      ordinalNo,
    }

    if (updateLocalUnit) updateLocalUnit(selectedProjectId, buildingId, newUnit);

    this.setState({ newlyAddedLocationObjects: newlyAddedLocationObjects.setNested([buildingId, 'units', unitId], newUnit) });
  } */

  // END=====

  handleConfirmSave() {
    const { structureWasImportedAtLeastOnce, saveCurrProjectSructure } =
      this.props;

    if (structureWasImportedAtLeastOnce) {
      if (saveCurrProjectSructure) saveCurrProjectSructure();
    } else {
      const { sortedUnitsPerBuilding } = this.state;
      const {
        modifiedValues,
        selectedProjectId,
        updateBuilding,
        updateFloors,
        updateUnits,
        startToast,
        getNewUnitId,
      } = this.props;

      let updates = Object.assign(
        {},
        modifiedValues.getNested(["locationData"], {})
      );

      sortedUnitsPerBuilding.loopEach((buildingId, building) => {
        building.loopEach((floorNumber, floorUnits) => {
          floorUnits.forEach((unit, index) => {
            let newUnit =
              updates.getNested([buildingId, "units", unit.id], false) || unit; // change for create unit
            newUnit = baseRemoveNested(newUnit, [
              /* newUnit.removeNested([ */ ["chosen"],
              ["selected"],
            ]);
            if (!newUnit.id) {
              const {
                payload: { unitId },
              } = getNewUnitId(selectedProjectId, buildingId);
              newUnit.id = unitId;
            }
            updates = updates.setNested(
              [buildingId, "units", newUnit.id],
              newUnit
            );
          });
        });
      });

      updates.loopEach((buildingId, update) => {
        const { buildings, floors, units } = update;
        // says buildingS but he lying, its actually only 1
        if (buildings) updateBuilding(selectedProjectId, buildings);

        if (floors) updateFloors(selectedProjectId, buildingId, floors);

        if (units) updateUnits(selectedProjectId, buildingId, units, true);
      });
    }
  }

  prepareDataForExcelExport() {
    const { sortedUnitsPerBuilding } = this.state;
    const { buildings, selectedProjectId } = this.props;
    const columnsHeaders = [
      { header: "ID", key: "id", width: 50 },
      { header: "Floor", key: "floor", width: 10 },
      { header: "Unit name", key: "unitName", width: 30 },
    ];

    let data = {};
    buildings.loopEach(
      (buildingId, buildingInfo) =>
        (data[buildingInfo.getNested(["title"], buildingId)] = {
          columnsHeaders,
          rowsData: [],
        })
    );

    sortedUnitsPerBuilding.loopEach((buildingId, unitsPerFloor) => {
      let sheetName = buildings.getNested([buildingId, "title"], buildingId);

      unitsPerFloor.loopEach((floorNumber, units) => {
        if (units.length)
          units.loopEach((i, unit) => {
            data[sheetName].rowsData.push({
              id: unit.id,
              floor: floorNumber,
              unitName: unit.title,
            });
          });
        else
          data[sheetName].rowsData.push({
            floor: floorNumber,
          });
      });
    });

    return { fileName: selectedProjectId, data };
  }

  recalcHeader() {
    const { recalcHeaderWithOptions, rtl, calcInputField } = this.props;

    if (recalcHeaderWithOptions) {
      let options = {
        extraOnComp: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
              justifyContent: "flex-start",
              [rtl ? "marginLeft" : "marginRight"]: theme.verticalMargin,
            }}
          >
            {calcInputField(
              new Field(null, "Excel", ["importedFile"], null, {
                settings: {
                  exportMethods: [
                    { getDataToExportFunc: this.prepareDataForExcelExport },
                  ],
                  importMethods: [{ id: "default" }],
                },
                overWriteStyles: true,
                style: { padding: 0, marginBottom: 0 },
              })
            )}
          </div>
        ),
        confirmSaveFunc: this.handleConfirmSave,
      };

      recalcHeaderWithOptions(options);
    }
  }

  handleChangeLocation(locationsIds) {
    const { setBuildingOverview, buildings, floors, units, setLocationData } =
      this.props;
    const { buildingId, floorId, unitId, type } = locationsIds;

    if (setBuildingOverview) setBuildingOverview(locationsIds);

    let newLocationData = {};

    switch (type) {
      case "building":
        newLocationData = buildings
          .getNested([buildingId], new OrderedMap([]))
          .toJS();
        break;
      case "floor":
        newLocationData = floors
          .getNested([buildingId, floorId], new OrderedMap([]))
          .toJS();
        break;
      case "unit":
        newLocationData = units
          .getNested([buildingId, unitId], new OrderedMap([]))
          .toJS();
        break;
      default:
        newLocationData = {};
    }

    if (setLocationData) setLocationData(newLocationData);

    const selectedLocationId = locationsIds[`${locationsIds.type}Id`];
    this.setState({ selectedLocationId });
  }

  /* async handleConfirmSave() {
		const { floors, units, project, selectedProjectId, updateProjectFields, updateBuildings, updateFloors, updateUnits, startToast, hasModifications } = this.props;
		const { objectSavedSuccessfully, changes } = systemMessages;
    let { buildings } = this.props;
    buildings = buildings.toJS ? buildings.toJS() : buildings;
    
    if (Object.keys(buildings).length > 1 && project.getNested(['type']) !== 4) // TODO: replace with constant type
      updateProjectFields({ type: 4 }, selectedProjectId);
    else if (Object.keys(buildings).length === 1 && project.getNested(['type']) !== 3)
      updateProjectFields({ type: 3 }, selectedProjectId);
      
    if (hasModifications) {
      if (updateBuildings) updateBuildings(selectedProjectId, buildings, true);
      Object.keys(buildings).forEach(buildingId => {
        if (updateFloors) updateFloors(selectedProjectId, buildingId, floors.getNested([buildingId]), true);
        if (updateUnits) updateUnits(selectedProjectId, buildingId, units.getNested([buildingId]), true);
      });
    }

		startToast({ title: objectSavedSuccessfully, values: { objectName: changes } });
  } */

  render() {
    const { isEditMode, buildingsOverview, rtl, importedFile } = this.props;
    const { sortedUnitsPerBuilding, selectedLocationId } = this.state;

    return (
      <ProjectStructureContext.Provider
        value={{
          setContextProjectStructure: this.updateFloorSortedUnits,
          handleAddNewBuilding: this.handleAddNewBuilding,
          handleAddNewFloor: this.handleAddNewFloor,
          handleAddNewUnit: this.handleAddNewUnit,
          sortedUnitsPerBuilding,
          isEditMode,
          forceUpdate: Boolean(importedFile),
        }}
      >
        <SplitViewPage
          rtl={rtl}
          SideStack={[
            { type: "projectStructure", props: { selectedLocationId } },
          ]}
          Main={
            <div
              style={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                display: "flex",
                flexGrow: 1,
              }}
            >
              <Buildings
                showBadges={false}
                style={{
                  marginTop: 0,
                  visibility: "visible",
                  width: "40%",
                  height: "inherit",
                  backgroundColor: theme.backgroundColor,
                }}
                maxUnitsInFloor={4}
                selectedLocation={buildingsOverview}
                onClick={this.handleChangeLocation}
              />
            </div>
          }
        />
      </ProjectStructureContext.Provider>
    );
  }
}

ProjectStructure = injectIntl(ProjectStructure);

const enhance = compose(
  connectContext(ProjectManagerContext.Consumer),
  connect((state) => ({}), {
    updateProjectFields,
    updateBuilding,
    updateLocalBuilding,
    updateFloors,
    updateLocalFloor,
    updateUnits,
    updateLocalUnit,
    getNewUnitId,
    getNewBuildingId,
    getNewFloorId,
  })
);

export default enhance(ProjectStructure);
