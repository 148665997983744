import React, { useState } from "react";
import Excel from "exceljs";
import { saveAs } from "file-saver";
// TODO: when sending values and provided sheet structure, convert values to the right types on entry.
// Components
import Text from "../../components/CementoComponents/Text";
import DropdownMenu from "./DropdownMenu";

// Icons
import importIconBrandPrimary from "../../assets/img/icons/import-brand-primary.png";
import importIconBlack from "../../assets/img/icons/import-brand-neutral-dark.png";
import exportIconBrandPrimary from "../../assets/img/icons/export-brand-primary.png";
import exportIconBlack from "../../assets/img/icons/export-brand-neutral-dark.png";

// Other
import systemMessages from "../../../common/app/systemMessages";
import theme from "../../assets/css/theme";
import { injectIntl } from "react-intl";
import { hideLoading, startLoading } from "../../../common/app/actions";
import { compose, hoistStatics } from "recompose";
import { connect } from "react-redux";
import { viewerMathConditions } from "../../../common/permissions/funcs";
import { v4 as uuidV4 } from 'uuid';

/* const exampleMethod = {
  id: 'someId', --> if more than 1 import method, it is required
  getDataToExportFunc: () => {},
  label: intl message || 'String',
  viewerPermissions: {} -> object where key is '-' separated path in viewer object and value is the value that it must equate to
} */

// !!IMPORTANT!!:  Only hex colors are accepted when styling

const defaultProps = {
  style: {},
  accept: ".xlsx",
  settings: {
    importMethods: [],
    exportMethods: [],
  },
};

const defaultColWidth = 12;
const defaultFontSize = 11;
const defaultFontFamily = "Calibry (body)";
class ImportExportExcel extends React.Component {
  constructor(props) {
    super(props);
    this.onImport = this.onImport.bind(this);
    this.onExport = this.onExport.bind(this);
    this.componentUniqueId = uuidV4();
    this.state = {};
  }

  onImport(e, methodId) {
    const { onChange, intl } = this.props;
    let workbook = new Excel.Workbook();
    let reader = new FileReader();

    reader.onloadend = async () => {
      const buffer = reader.result;
      workbook = await workbook.xlsx.load(buffer);
      let workbookObj = new CustomWorkbook(workbook, { intl });
      if (onChange) onChange(workbookObj, methodId);
    };

    let file = e.target.files[0];
    if (file) reader.readAsArrayBuffer(file);
    e.target.value = null; // important for importing the same file
  }

  async onExport(getDataToExportFunc) {
    const { hideLoading, startLoading } = this.props;

    if (getDataToExportFunc) {
      startLoading({ title: systemMessages.loadingMessage, overlay: true });
      let workbook = new Excel.Workbook();

      let { fileName, data } = await getDataToExportFunc();
      if (!data) {
        hideLoading();
        return;
      }

      data.loopEach(
        (
          sheetName = "",
          {
            columnsHeaders,
            rowsData,
            validationRulesMap = null,
            sheetStructure = null,
            sheetView = [],
          }
        ) => {
          let columnsKeyLetterMap = {};
          if (sheetStructure) {
            let i = 0;
            columnsHeaders = [];
            Object.entries(sheetStructure)
              .sort(
                ([aKey, a], [bKey, b]) =>
                  (a.ordinalNo || 0) - (b.ordinalNo || 0)
              )
              .forEach(([colKey, colStructure]) => {
                const {
                  name,
                  types = [],
                  style: { width, backgroundColor, ...styleRest } = {},
                } = colStructure;
                let { richColName, fullText = "" } = this.getRichCellText(name);
                let colWidth = width || this.getDynamicColWidth(fullText);

                columnsHeaders.push({
                  header: richColName,
                  key: colKey,
                  width: colWidth,
                  style: {
                    ...styleRest,
                    ...(backgroundColor
                      ? {
                          fill: {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: backgroundColor.split("#")[1] },
                          },
                        }
                      : {}), // ONLY HEX
                  },
                });
                if (validationRulesMap) {
                  columnsKeyLetterMap = columnsKeyLetterMap.setNested(
                    [colKey],
                    this.getColumnNameFromColNumber(i + 1)
                  );
                  i++;
                }
              });
          } else if (validationRulesMap)
            columnsHeaders.loopEach((i, { key }) => {
              columnsKeyLetterMap = columnsKeyLetterMap.setNested(
                [key],
                this.getColumnNameFromColNumber(i + 1)
              );
            });

          let newSheet = workbook.addWorksheet(sheetName.trim());
          newSheet.columns = columnsHeaders;
          // newSheet = this.setStyledRows(newSheet, rowsData);

          // The reason that we do all this shit is because somewhere we setting the hour to be 12:00 and then because of GMT difference it might change the date
          let fixedDatesRowsData = rowsData.map((row) => {
            Object.values(row).forEach((propData) => {
              if (propData instanceof Date) {
                const propDataHour = propData.getHours();
                const propDataMinute = propData.getMinutes();
                if (propDataHour === 0 && propDataMinute === 0) {
                  const timeZoneOffset = propData.getTimezoneOffset() / 60;
                  const newHour =
                    timeZoneOffset < 0
                      ? propDataHour - timeZoneOffset
                      : propDataHour + timeZoneOffset;
                  const fixedDate = propData.setHours(newHour);
                  propData = new Date(fixedDate);
                }
              }
            });
            return row;
          });

          newSheet.addRows(fixedDatesRowsData);
          newSheet.views = sheetView;

          if (!sheetStructure || sheetStructure.boldHeaders !== false)
            newSheet.getRow(1).font = {
              bold: true,
              name: defaultFontFamily,
              size: defaultFontSize,
            };

          if (validationRulesMap)
            validationRulesMap.loopEach((key, validationRule) => {
              for (let i = 2; i < 2000; i++)
                newSheet.dataValidations.add(
                  columnsKeyLetterMap[key] + i,
                  validationRule
                );
            });
        }
      );
      data = await workbook.xlsx.writeBuffer();
      data = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(data, `${fileName || "excel"}.xlsx`);
      hideLoading();
    }
  }

  // Styling text
  getRichCellText(cellText) {
    if (!(cellText instanceof Array))
      return { richColName: cellText, fullText: cellText };
    let richColName = {
      richText: [],
    };

    let fullText = "";

    cellText.forEach((styledTextChunk) => {
      const { text, style } = styledTextChunk;
      const {
        color,
        bold = false,
        italic = false,
        underline = null,
        fontSize = defaultFontSize,
        fontFamily = defaultFontFamily,
      } = style; // TODO: add other styling properties;
      richColName.richText.push({
        font: {
          ...(color ? { color: { argb: color.split("#")[1] } } : {}), // ONLY HEX
          name: fontFamily,
          size: fontSize,
          bold,
          italic,
          underline,
        },
        text,
      });
      fullText += text;
    });

    return { richColName, fullText };
  }

  getDynamicColWidth(name) {
    // TODO: support different font-sizes (take font size from style)
    let nameLines = name.split("\n");
    let colWidth = defaultColWidth;
    nameLines.forEach((line) => {
      if (line.length > colWidth) colWidth = line.length;
    });

    return colWidth + 5;
  }

  getColumnNameFromColNumber(num) {
    let ret = "";
    for (let a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26)
      ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;

    return ret;
  }

  getMethodHtmlFor(method, i) {
    return `importExportExcel-${
      method.id
        ? method.id
        : (method.label || {}).defaultMessage
        ? (method.label || {}).defaultMessage
        : method.label
    }-import-${i}`;
  }

  render() {
    const { accept, settings = {}, rtl, style = {}, viewer } = this.props;

    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          alignItems: "center",
          ...style,
        }}
      >
        {["exportMethods", "importMethods"].map((methodsType) => {
          let methods = settings[methodsType];
          if (!methods) return null;
          methods = methods.filter(
            (method) =>
              !method.viewerPermissions ||
              viewerMathConditions(viewer, method.viewerPermissions)
          );
          if (methods.length === 0) return null;
          const isImport = methodsType === "importMethods";
          const onlyOneMethod = methods.length === 1;

          let buttonLabel =
            onlyOneMethod && methods[0].label && methods[0].label;
          let icons = {};
          if (isImport) {
            buttonLabel = buttonLabel
              ? buttonLabel
              : systemMessages.importExcel;
            icons.hover = importIconBrandPrimary;
            icons.neutral = importIconBlack;
          } else {
            methods.forEach((method) => {
              method.onClick = () => this.onExport(method.getDataToExportFunc);
            });
            buttonLabel = buttonLabel
              ? buttonLabel
              : systemMessages.exportExcel;
            icons.hover = exportIconBrandPrimary;
            icons.neutral = exportIconBlack;
          }
          const methodsKey = this.componentUniqueId + methodsType;
          return (
            <DropdownMenu
              key={methodsKey}
              closeOnMenuItemClick
              menuItems={!onlyOneMethod ? methods : null}
              OriginComponent={
                <>
                  <ExportImportButton
                    rtl={rtl}
                    icon={icons.neutral}
                    iconHover={icons.hover}
                    label={buttonLabel}
                    htmlFor={
                      onlyOneMethod ? this.getMethodHtmlFor(methods[0], 0) : undefined
                    }
                    onClick={(onlyOneMethod && methods[0] && methods[0].onClick) ? methods[0].onClick : undefined}
                  />
                  {Boolean(isImport) &&
                    methods.map((method, i) => {
                      method.htmlFor = this.getMethodHtmlFor(method, i);
                      return (
                        <input
                          key={methodsKey + method.htmlFor}
                          id={method.htmlFor}
                          style={{ display: "none" }}
                          type="file"
                          accept={accept}
                          multiple={false}
                          onChange={(e) => this.onImport(e, method.id)}
                        />
                      );
                    })}
                </>
              }
              originComponentContainerStyle={{
                width: "max-content",
                [rtl ? "marginLeft" : "marginRight"]: theme.margin,
                alignItems: "center",
              }}
            />
          );
        })}
      </div>
    );
  }
}

ImportExportExcel.defaultProps = defaultProps;

ImportExportExcel = injectIntl(ImportExportExcel);

const enhance = compose(
  connect(
    (state) => ({
      viewer: state.users.viewer,
    }),
    {
      startLoading,
      hideLoading,
    }
  )
);

export default enhance(ImportExportExcel);

const ExportImportButton = ({
  onClick,
  label,
  icon,
  iconHover,
  rtl,
  htmlFor,
}) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <label
      style={{ color: theme.brandNeutralDark, cursor: "pointer" }}
      htmlFor={htmlFor}
    >
      <div
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          flex: 1,
        }}
        onClick={() => onClick && onClick()}
      >
        {Boolean(icon) && (
          <img
            style={{
              height: "20px",
              [rtl ? "marginLeft" : "marginRight"]: theme.margin - 5,
            }}
            src={isHover && iconHover ? iconHover : icon}
          />
        )}
        <Text
          style={{
            fontSize: theme.fontSize,
            fontWeight: theme.strongBold,
            ...(isHover ? { color: theme.brandPrimary } : { color: "#3C4858" }),
          }}
        >
          {label}
        </Text>
      </div>
    </label>
  );
};

ExportImportButton.defaultProps = {
  onClick: () => {},
  label: "Default label",
  logo: null,
};

class BaseCustomExcel {
  constructor(properties) {
    this._properties = properties;
  }
  // TODO: move methods like checkValType and such to this base class
  _onUserError(...error) {
    const { intl } = this._properties;

    if (!error.length) return;

    if (!intl || !(error[0] || {}).defaultMessage) throw error[0];

    throw intl.formatMessage(...error);
  }

  extractRichText(richTextCellValue) {
    return richTextCellValue.richText.map(({ text }) => text).join("");
  }
}

export class CustomWorkbook extends BaseCustomExcel {
  constructor(excelWorkbook, properties) {
    super(properties);
    this.workbook = excelWorkbook;
    this.sheets = this._convertExcelWorkbook(this.workbook);
  }

  _convertExcelWorkbook(workbook) {
    if (!workbook || !workbook.worksheets) return null;

    let sheetsObj = {};
    let gotTwiceSameColName = null;
    workbook.worksheets.forEach((worksheet) => {
      if (worksheet.state !== "visible") return;

      let sheetName = worksheet.name;

      let headersMap = {};
      worksheet.getRow(1).eachCell((cell, colNumber) => {
        if (!cell.value) return;

        let cellValue = cell.value;
        if (cellValue.richText) cellValue = this.extractRichText(cellValue);

        if (headersMap[cellValue]) {
          gotTwiceSameColName = `Found two columns with the same name "${cellValue}". Please remove one and try again`; // TODO: translate;
        }
        headersMap = headersMap.setNested([cellValue], colNumber);
      });

      let colValuesMap = {};
      let errorInCellValue = null;
      Object.entries(headersMap).forEach(([colName, colNumber]) => {
        let colValues = [];
        let prevRowNumber = 1;
        worksheet.getColumn(colNumber).eachCell((cell, rowNumber) => {
          if (rowNumber === 1) return;

          let cellValue = cell.value;
          if (cellValue instanceof Object && !(cellValue instanceof Date)) {
            // That means that sometimes the date will be an actual date, and sometimes it will be a string;
            switch (true) {
              case Boolean(cellValue.result): // When cell has a formula. Get computed value;
                cellValue = cellValue.result;
                break;

              case Boolean(cellValue.richText): // When the cell contains text that is rich like bold and colors and stuff. Concat the rich values;
                cellValue = this.extractRichText(cellValue);
                break;

              case Boolean(cellValue.hyperlink): // When the cell contains hyperlink text. Grab the text;
                // TODO: grab hyperlink link with cellValue.hyperlink
                cellValue = cellValue.text;
                break;

              case Boolean(cellValue.error):
                errorInCellValue = `An error was found in the cell located in column "${colName
                  .split("\n")
                  .join(" ")}" row ${rowNumber}. Please fix it and try again.`;
                break;
              default:
                break;
            }
          }

          if (cellValue === undefined) {
          }

          // Fill in missing row values to keep data integrity (must have same number of rows in all culumns and exceljs 'includeEmpty' sucks...)
          if (rowNumber - prevRowNumber !== 1) {
            for (let i = prevRowNumber + 1; i < rowNumber; i++)
              colValues.push(null);
          }

          colValues.push(cellValue);
          prevRowNumber = rowNumber;
        });

        colValuesMap = colValuesMap.setNested([colName], colValues);
      });

      sheetsObj = sheetsObj.setNested(
        [sheetName],
        new CustomExcelSheet(
          sheetName,
          colValuesMap,
          Object.keys(headersMap),
          [gotTwiceSameColName, errorInCellValue].filter(Boolean),
          this._properties
        )
      );
    });

    return sheetsObj;
  }

  setGeneralSheetsStructure(generalSheetsStructure) {
    if (!this.sheets || !(this.sheets instanceof Object)) {
      console.error("This workbook has no sheets");
      return;
    }

    this.sheets.loopEach((sheetName, sheet) => {
      sheet.setSheetStructure(generalSheetsStructure);
    });
  }

  /**
   * @callback EachSheetCallback
   * @param {string} sheetName
   * @param {CustomExcelSheet} sheet
   * @param {string[]} columnNames
   */
  /**
   * @param {EachSheetCallback} callback
   * @returns
   */
  eachSheet(callback) {
    if (typeof callback !== "function") {
      console.error('"callback" must be a function');
      return;
    }

    if (!this.sheets || !(this.sheets instanceof Object)) {
      console.error("This workbook has not sheets");
      return;
    }

    let callbackArray = [];
    Object.entries(this.sheets).forEach(([sheetName, sheet]) => {
      if (sheet.structureErrors.length) throw sheet.structureErrors.join("\n");

      callbackArray.push(callback(sheetName, sheet, sheet.columnNames));
    });

    return callbackArray;
  }
}

class CustomExcelSheet extends BaseCustomExcel {
  constructor(sheetName, sheet, columnNames, structureErrors, properties) {
    super(properties);
    this.name = sheetName;
    this.sheet = sheet;
    this.columnNames = columnNames;
    this.sheetStructure = null;
    this.structureErrors = structureErrors || [];
    this.numOfColumns = Object.values(this.sheet || {}).length;
    this.numOfRows = (Object.values(this.sheet || {})[0] || []).length;
  }

  setSheetStructure(newSheetStructure) {
    if (!newSheetStructure || !(newSheetStructure instanceof Object)) {
      console.error('"sheetStructure" should be an object');
      return;
    }

    this.sheetStructure = newSheetStructure;
    this._onSheetStructureChange();
  }

  _onSheetStructureChange() {
    let headersMap = this._sheetStructureToHeadersMap(this.sheetStructure);

    let newSheet = {};
    Object.entries(this.sheet).forEach(([colName, colValues]) => {
      let currColStructureName = headersMap[colName];
      let currColumnStructure = this.sheetStructure[currColStructureName] || {};
      /* if (currColumnStructure.allowEmptyCells === false) 
        this._checkEmpties(colValues, colName); */
      newSheet = newSheet.setNested([currColStructureName], colValues);
    });

    this.sheet = newSheet;
    this.columnNames = Object.values(headersMap);
    this._checkMissingColumn(this.sheet);
  }

  _checkEmpties(valuesArr, colName) {
    let faultyRowNumber;
    if (
      valuesArr.length !==
      valuesArr.filter((val, index) => {
        faultyRowNumber = Boolean(val) ? faultyRowNumber : index + 2;
        return Boolean(val);
      }).length
    )
      this._onUserError(
        `All the cells of column ${colName} must contain a value. Please check row ${faultyRowNumber} and try again.`
      );
  }

  _checkMissingColumn(sheet) {
    if (!this.sheetStructure || !(this.sheetStructure instanceof Object))
      return;

    Object.keys(this.sheetStructure).forEach((colName) => {
      const { name, isOptional } = this.sheetStructure[colName];
      if (Boolean(isOptional)) return;
      if (!sheet[colName])
        this._onUserError(systemMessages.excel.missingColumn, { name });
    });
  }

  _sheetStructureToHeadersMap(sheetStructure) {
    if (!(sheetStructure instanceof Object)) {
      console.error('"sheetStructure" is not an object.');
      return;
    }

    let headersMap = {};
    sheetStructure.loopEach((colKey, colInfo) => {
      if (!colInfo.name) {
        console.error(
          'Each property in "sheetStructure" should contain a "name" property'
        );
        return;
      }

      const { name } = colInfo;
      headersMap = headersMap.setNested([name], colKey);
    });

    return headersMap;
  }

  /**
   * @callback EachRowCallback
   * @param {CustomExcelRow} row
   * @param {number} rowNumber - starts from 1
   * @param {string[]} columnNames
   */
  /**
   * @param {EachRowCallback} callback
   * @returns
   */
  eachRow(callback) {
    if (typeof callback !== "function") {
      console.error('"callback" must be a function');
      return;
    }
    let callbackArray = [];
    for (let rowIndex = 0; rowIndex < this.numOfRows; rowIndex++) {
      let rowCells = {};
      let rowNumber = rowIndex + 2; // to match rowNumber
      Object.keys(this.sheet).forEach((colName) => {
        let currCell = this.sheet[colName][rowIndex];
        currCell = new CustomExcelCell(
          currCell,
          colName,
          rowNumber,
          this._properties
        );

        this._checkValType(currCell);
        rowCells = rowCells.setNested2([colName], currCell);
      });

      let row = new CustomExcelRow(rowCells, rowNumber, this._properties);

      if (row.isEmpty) this._onRowEmpty(rowNumber);

      callbackArray.push(callback(row, rowNumber, this.columnNames));
    }

    return callbackArray;
  }

  _checkValType(cell) {
    if (!this.sheetStructure || !cell) return;

    if (!this.sheetStructure[cell.columnName]) {
    }

    let { name, types = [] } = this.sheetStructure[cell.columnName] || {};
    cell.checkValType(types);
  }

  _onRowEmpty(rowNumber) {
    this._onUserError(systemMessages.excel.emptyRow, { rowNumber });
  }
}

class CustomExcelRow extends BaseCustomExcel {
  constructor(rowCells, number, properties) {
    super(properties);
    this.cells = rowCells;
    this.number = number;
    this.values = this._getRowValuesFromCells(rowCells);
    this.isEmpty = this._checkIsEmpty();
  }

  _getRowValuesFromCells(rowCells) {
    let rowValues = {};
    rowCells.loopEach((i, cell) => {
      rowValues[cell.columnName] = cell.value;
    });

    return rowValues;
  }

  /**
   *
   * @param {string} columnName
   * @returns
   */
  getValueInColumn(columnName) {
    return this.cells[columnName] ? this.cells[columnName].value : null;
  }

  /**
   *
   * @param {string} columnName
   * @returns
   */
  getCellInColumn(columnName) {
    return this.cells[columnName] || null;
  }

  /**
   * @callback EachCellCallback
   * @param {CustomExcelCell} cell
   * @param {string} columnName
   */
  /**
   * @param {EachCellCallback} callback
   * @returns
   */
  eachCell(callback) {
    if (typeof callback !== "function") {
      console.error('"callback" must be a function');
      return;
    }

    let callbackArray = [];
    Object.values(this.cells).forEach((cell) => {
      callbackArray.push(callback(cell, cell.columnName));
    });

    return callbackArray;
  }

  /**
   * @callback EachValueCallback
   * @param {any} cellValue
   * @param {string} columnName
   */
  /**
   * @param {EachValueCallback} callback
   * @returns
   */
  eachValue(callback) {
    if (typeof callback !== "function") {
      console.error('"callback" must be a function');
      return;
    }

    let callbackArray = [];
    Object.entries(this.values).forEach(([colName, value]) => {
      callbackArray.push(callback(value, colName));
    });

    return callbackArray;
  }

  _checkIsEmpty() {
    return (
      Object.values(this.values).filter(
        (val) => Boolean(val) || val === 0 || val === false
      ).length === 0
    );
  }
}

class CustomExcelCell extends BaseCustomExcel {
  constructor(cellValue, columnName, rowNumber, properties) {
    super(properties);
    this.value = cellValue;
    this.columnName = columnName;
    this.rowNumber = rowNumber;
  }

  /**
   * @param {string[]} types
   * @returns
   */
  checkValType(types) {
    if (!types) {
      console.error("No types provided", types);
      return;
    }

    if (!Array.isArray(types)) {
      console.error("Types must be an array", types);
      return;
    }

    if (!this.value) return;

    let failedTests = [];
    types.forEach((type, i) => {
      let lowType = type.toLowerCase();
      if (lowType === "string")
        // They all can be strings, who cares
        return;

      if (
        (lowType === "number" &&
          (typeof this.value === "boolean" ||
            (!Number(this.value) && Number(this.value) !== 0))) || // check if boolean becasue Number(true) is 1
        (lowType !== "number" && (typeof this.value).toLowerCase() !== lowType)
      )
        failedTests.push(type);
    });

    if (types.length && types.length === failedTests.length) {
      const { intl } = this._properties;

      let formatedTypes = types
        .map((type) => systemMessages.excel.types[type.toLowerCase()])
        .filter(Boolean);
      formatedTypes = formatedTypes.map((typeMessage) =>
        intl.formatMessage(typeMessage)
      );

      this._onUserError(systemMessages.excel.wrongValueType, {
        name: this.columnName,
        rowNumber: this.rowNumber,
        types: formatedTypes.join(" / ").toLowerCase(),
      });
    }
  }
}

/*
sheetStructure EXAMPLE  ------------------------------------------------------------------
const sheetStructure = {
  id                    : { name: 'ID',                        types: ['String'] },
  isStage               : { name: 'Is stage?',                 types: ['Boolean'] },
  isChecklist           : { name: 'Is checklist?',             types: ['Boolean'] },
  isChecklistItem       : { name: 'Is checklistItem?',         types: ['Boolean'] },
  isDeleted             : { name: 'Is deleted?',               types: ['Boolean'] },
  description           : { name: 'Description',               types: ['String'] },
  extraDescription      : { name: 'Extra Description',         types: ['String'] },
  tradeId               : { name: 'Trade ID',                  types: ['Number'] },
  buildingTitles        : { name: 'Building titles',           types: ['String'] },
  floorTitles           : { name: 'Floor titles',              types: ['String'] },
  unitTitles            : { name: 'Unit titles',               types: ['String'] },
  imageRequired         : { name: 'Image required?',           types: ['Boolean'] },
  descriptionRequired   : { name: 'Description required?',     types: ['Boolean'] },
  attachmentRequired    : { name: 'Attachment required?',      types: ['Boolean'] },
  drawingRequired       : { name: 'Drawing required?',         types: ['Boolean'] },
  enablePartialButton   : { name: 'Enable partial button?',    types: ['Boolean'] },
  enableIrrelevantButton: { name: 'Enable irrelevant button?', types: ['Boolean'] },
  weight                : { name: 'Weight',                    types: ['Number'] },
  isRoutine             : { name: 'Is routine?',               types: ['Boolean', 'Number'] },
  isDuplicatable        : { name: 'Is duplicatable?',          types: ['Boolean'] },
  includeInGrade        : { name: 'Include in grade?',         types: ['Boolean'] },
}
*/

// columnsHeaders EXAMPLE  ------------------------------------------------------------------
/* const columnsHeaders = [
      { header: id,                     key: id,                     width: 30 },
      { header: isStage,                key: isStage,                width: smallWidth },
      { header: isChecklist,            key: isChecklist,            width: smallWidth },
      { header: isChecklistItem,        key: isChecklistItem,        width: largeWidth },
      { header: isDeleted,              key: isDeleted,              width: smallWidth },
      { header: description,            key: description,            width: largeWidth },
      { header: extraDescription,       key: extraDescription,       width: largeWidth },
      { header: tradeId,                key: tradeId,                width: smallWidth },
      { header: buildingTitles,         key: buildingTitles,         width: largeWidth },
      { header: floorTitles,            key: floorTitles,            width: largeWidth },
      { header: unitTitles,             key: unitTitles,             width: largeWidth },
      { header: imageRequired,          key: imageRequired,          width: largeWidth },
      { header: descriptionRequired,    key: descriptionRequired,    width: largeWidth },
      { header: attachmentRequired,     key: attachmentRequired,     width: largeWidth },
      { header: drawingRequired,        key: drawingRequired,        width: largeWidth },
      { header: enablePartialButton,    key: enablePartialButton,    width: largeWidth },
      { header: enableIrrelevantButton, key: enableIrrelevantButton, width: largeWidth },
      { header: weight,                 key: weight,                 width: smallWidth },
      { header: isRoutine,              key: isRoutine,              width: smallWidth },
      { header: isDuplicatable,         key: isDuplicatable,         width: smallWidth },
      { header: includeInGrade,         key: includeInGrade,         width: smallWidth },
    ]; */
