import { useEffect, useState } from 'react';
import _ from 'lodash';

/**
 * @template T
 * @param {T} value 
 * @returns 
 */
const useMemoizedValue = (value) => {
  const [memoizedValue, setMemoizedValue] = useState(value);

  useEffect(() => {
    if (!_.isEqual(memoizedValue, value)) {
      setMemoizedValue(value);
    }  
  }, [value]);

  return memoizedValue;
}

export default useMemoizedValue;
