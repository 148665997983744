import { getAppState, getDispatch } from "../configureMiddleware";
import { platformActions } from "../platformActions";
import { envParams } from '../configureMiddleware';
import _ from 'lodash';
import moment from 'moment';
import getPlatformTheme from '../platformTheme';
import { startProjectFirebaseListener } from '../lib/utils/utils';
import { hideLoading, startLoading } from '../app/actions';
import siteControlMessages from "../siteControl/siteControlMessages";
import { onError } from "../app/funcs";
import systemMessages from "../app/systemMessages";

export const EXIT = 'exit';
export const ENTRANCE = 'entrance';
const SYNC_TIMEOUT = 5 * 60 * 1000;

export const isSiteControlProject = function (projectId) {
  const cameras = getAppState().getNested(['configurations', 'map', projectId, 'cameras'], {});
  return _.some(cameras, cam => !cam.disabled);
};

export const getTSArrayByTimeUnit = function ({ startTS, endTS, unit = 'day' }) {
  if (!(startTS && endTS)) return;
  let startDate = moment(startTS).startOf(unit);
  let dates = [];
  let nextDate = startDate.valueOf();

  while (nextDate < endTS) {
    dates.push(nextDate);
    nextDate = startDate.add(1, unit).valueOf();
  }

  return dates;
};

export const getEmployeesPresence = async function ({ projectId, ids, startTS, endTS, includeMissingDays, excludeEmptyLogs, populate, includeMonitor, skipLoading }) {

  if (!projectId)
    return;

  const dispatch = getDispatch();
  if (!skipLoading)
    dispatch(startLoading({ title: siteControlMessages.general.loadingEmployees, overlay: true }));

  let res;
  let url = `${envParams.apiServer}/v1`;
  if (populate)
    url += '/gateway';
  url += `/siteControl/employees?projectId=${projectId}&startTS=${startTS}&endTS=${endTS}&includeMonitor=${includeMonitor}&v=2`;
  if (ids)
    url += `&ids=${JSON.stringify(_.values(ids))}`;

  try {
    res = await platformActions.net.fetch(url);
    res = await res.getJson();

    if (_.isEmpty(res.employees))
      throw "no logs in requested time range";

    if (excludeEmptyLogs)
      res.employees = _.pickBy(res.employees, emp => !_.isEmpty(emp.log));

    if (includeMissingDays) {
      const days = getTSArrayByTimeUnit({ startTS, endTS });
      let emptyDays = {};
      _.forEach(days, dayTS => _.set(emptyDays, [dayTS], {}));
      res.employees = _.mapValues(res.employees, emp => {
        const log = _.assign({}, emptyDays, emp.log);
        const newEmp = _.assign({}, emp, { log });
        return newEmp;
      });
    }
  }
  catch (error) {

    const alertParams = {
      title: siteControlMessages.presenceLogs.loadingFailed,
      type: 'error',
      message: res
        ? siteControlMessages.table.noPresence
        : systemMessages.errors.fetchingErrorContent
    };

    onError({
      errorMessage: "Failed to fetch employees presence",
      alertParams,
      error,
      errorMetaData: { projectId, ids, startTS, endTS, populate, includeMonitor, url }
    });
  }
  finally {
    dispatch(hideLoading());
  }

  return res;
};


export const splitDailyLogsToVisits = function (logs, shouldSquash) {
  let presence = [];
  let curr = {};

  _.forIn(logs, (eventType, eventTS) => {

    if (eventType == ENTRANCE && curr[EXIT] || curr[eventType]) {
      presence.push(curr);
      curr = {};
    }

    curr[eventType] = Number(eventTS);
  });

  if (!_.isEmpty(curr)) {
    presence.push(curr);
  }


  if (shouldSquash && presence.length) {
    const lastIndex = presence.length - 1;
    presence = [{
      [ENTRANCE]: _.get(presence, [0, ENTRANCE]),
      [EXIT]: _.get(presence, [lastIndex, EXIT])
    }];
  }


  return presence;
};



export const splitLogsToVisits = function (logs, shouldSquashDaily) {
  const visits = {};
  _.forIn(logs, (dailyLogs, dayTS) => {
    visits[dayTS] = splitDailyLogsToVisits(dailyLogs, shouldSquashDaily);
  });

  if (!_.isEmpty(visits))
    return visits;
};

export const employeesPresenceStatuses = {
  PRESENT: "PRESENT",
  NOT_PRESENT: "NOT_PRESENT",
  ERROR: "ERROR"
};

export const getEmployeesPresenceStatusColor = status => {
  const theme = getPlatformTheme();
  const colors = {
    [employeesPresenceStatuses.PRESENT]: theme.brandSuccess,
    [employeesPresenceStatuses.NOT_PRESENT]: theme.darkSeparatorColor,
    [employeesPresenceStatuses.ERROR]: theme.darkSeparatorColor, //theme.brandRealDanger,
    default: theme.darkSeparatorColor
  };

  return colors[status || 'default'];
};


export const getEmployeesPresenceStatus = ({ logs, ts }) => {
  const dayTS = moment(Number(ts) || Date.now()).startOf('day').valueOf();
  const isToday = moment(dayTS).isSame(Date.now(), 'day');
  const dayLogs = _.get(logs, [dayTS]);
  const latestLogTS = _.max(_.keys(dayLogs));
  const firstLogTS = _.min(_.keys(dayLogs));
  let status = employeesPresenceStatuses.NOT_PRESENT;

  if (isToday && _.get(dayLogs, [latestLogTS]) == ENTRANCE)
    status = employeesPresenceStatuses.PRESENT;
  if (_.get(dayLogs, [firstLogTS]) == EXIT)
    status = employeesPresenceStatuses.ERROR;

  return status;
};


export const msToHoursDurationString = ms => (_.isNil(ms) || _.isNaN(ms)) ? undefined : moment.utc(ms).format('H:mm');

const tsToTimeString = ts => (_.isNil(ts) || _.isNaN(ts)) ? undefined : moment(ts).format('H:mm');

export const getVisitsInfo = (dailyLogs) => {
  const visits = splitDailyLogsToVisits(dailyLogs);
  const firstEntrance = _.get(_.head(visits), ['entrance']);
  const lastExit = _.get(_.last(visits), ['exit']);
  const total = lastExit - firstEntrance;
  const totalNet = _.reduce(visits, (acc, currVisit) => (acc + (currVisit.exit - currVisit.entrance)), 0);
  return {
    visits,
    firstEntrance: tsToTimeString(firstEntrance),
    lastExit: tsToTimeString(lastExit),
    total: msToHoursDurationString(total),
    totalNet: msToHoursDurationString(totalNet),
  };
};


export const prepareEmployeePresenceDataForTable = employeesPresence => {
  let data = {};
  _.forIn(employeesPresence, (employee, employeeKey) => {
    const employeeData = _.pick(employee, ['id', 'fullName', 'companyName', 'companyId']);

    _.forIn(employee.log, (dayLogs, dayTS) => {
      const date = moment(Number(dayTS)).format('L');
      const dayVisitsInfo = getVisitsInfo(dayLogs);
      _.set(employeeData, ['log', date], dayVisitsInfo);
    });

    _.set(data, [employeeKey], employeeData);
  });

  return data;
};


export const getLastSyncTSFromCamerasMonitor = (monitor) => {
  let ts;
  _.forIn(monitor, camera => {
    const { lastSync, lastCameraKeepAlive } = camera;
    const newMaxTs = Math.max(lastSync, lastCameraKeepAlive);
    if ((!ts || newMaxTs > ts))
      ts = newMaxTs;
  });
  return ts;
};

export const syncCameras = ({ projectId, from, to = Date.now() }) => {
  return new Promise(async (resolve, reject) => {

    if (!(projectId && from && to))
      return reject('missing params');



    let closeListenerFuncsArray = [];
    let timeoutId;
    const listenersRemovalCallBack = (value, key) => {
      if (key !== 'lastSync' && key != 'lastCameraKeepAlive')
        return;

      closeListenerFuncsArray.forEach(closeListenerFunc => {
        if (_.isFunction(closeListenerFunc))
          closeListenerFunc();
      });

      if (_.isUndefined(value))
        reject("timeout while waiting to syncCameras results");
      else
        resolve(value);

      clearTimeout(timeoutId);
    };

    timeoutId = setTimeout(() => {
      listenersRemovalCallBack();
    }, SYNC_TIMEOUT);

    [ENTRANCE, EXIT].map(cameraName => {
      const pathInDB = `monitor/cameras/projects/${projectId}/${cameraName}`;
      const closeListenerFunc = startProjectFirebaseListener(projectId, pathInDB, 'child_changed', (val, key) => {
        listenersRemovalCallBack(val, key);
      });
      closeListenerFuncsArray.push(closeListenerFunc);
    });

    await (platformActions.net.fetch(`${envParams.apiServer}/v1/siteControl/eventLog/syncLogs`, {
      method: 'POST',
      body: JSON.stringify({ projectId, from, to })
    }));

  });
};

export const startMonitorListener = ({ projectId, callback, event = 'child_changed' }) => {
  const pathInDB = `monitor/cameras/projects/${projectId}/entrance`;
  return startProjectFirebaseListener(projectId, pathInDB, event, callback);
};


export const getEmployeesTablesMetadata = (employeesPresence) => {
  let ret = {};

  _.forIn(employeesPresence, (emp, empKey) => {
    const { companyName, log } = emp;
    _.forIn(log, (currDayLogs, dayTS) => {
      const wasPresent = !_.isEmpty(currDayLogs);
      if (!wasPresent)
        return;
      const isToday = moment().isSame(Number(dayTS), 'day');
      const isPresentNow = isToday && _.last(_.values(currDayLogs)) !== EXIT;

      const dayPresentCount = _.get(ret, [dayTS, 'present'], 0);
      const dayNonPresentCount = _.get(ret, [dayTS, 'nonPresent'], 0);
      const companyPresentCount = _.get(ret, [dayTS, 'companies', companyName, 'present'], 0);
      const companyNonPresentCount = _.get(ret, [dayTS, 'companies', companyName, 'nonPresent'], 0);

      _.set(ret, [dayTS, isPresentNow ? 'present' : 'nonPresent'], 1 + (isPresentNow ? dayPresentCount : dayNonPresentCount));
      _.set(ret, [dayTS, 'companies', companyName, isPresentNow ? 'present' : 'nonPresent'], 1 + (isPresentNow ? companyPresentCount : companyNonPresentCount));
    });
  });

  return ret;
};
