import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import theme from '../../assets/css/theme';
import CompanyTable from '../../components/CementoComponents/CompanyTable/CompanyTable';
import MenuScrollbar from '../../components/CementoComponents/MenuScrollbar';
import _ from 'lodash';
import moment from 'moment';
import StandardInput from '../../components/CementoComponents/StandardInput';
import systemMessages from '../../../common/app/systemMessages';
import safetyMessages from '../../../common/safety/safetyMessages';
import analyticsMessages from '../../../common/analytics/analyticsMessages';
import { startLoading, hideLoading } from '../../../common/app/actions';
import { connect } from 'react-redux';
import { compose, hoistStatics } from 'recompose';
import Text from '../../components/CementoComponents/Text';
import RefreshIcon from '@material-ui/icons/Refresh';
import TimeFilter, {
	defaultOption as defaultTSFilter,
	timeFilteringOptions,
} from '../../components/CementoComponents/TimeFilter';
import HoverWrapper from '../../components/CementoComponents/HoverWrapper';

class CompanyQATable extends Component {
	constructor(props) {
		super(props);
		this.handleTimeStampFilter = this.handleTimeStampFilter.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.handleProjectClick = this.handleProjectClick.bind(this);

		let startTS = _.get(timeFilteringOptions, [defaultTSFilter, 'getStartTS'], _.noop)();
		let endTS = _.get(timeFilteringOptions, [defaultTSFilter, 'getEndTS'], _.noop)();

		this.state = {
			columns: [],
			selectedCompanyId: null,
			tablesData: [],
			selectedTimeStampFilter: timeFilteringOptions[defaultTSFilter],
			startTS,
			endTS,
		};
	}

	UNSAFE_componentWillMount() {
		// this.recalcHeader();
		this.setComponentData({}, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setComponentData(this.props, nextProps);
	}

	async setComponentData(props, nextProps) {
		let stateChanges = {};

		if (
			nextProps.isValDiff(props, ['urlParams', 'selectedCompanyId']) ||
			nextProps.isValDiff(props, ['selectedCompanyId'])
		) {
			let newSelectedCompanyId =
				nextProps.getNested(['urlParams', 'selectedCompanyId']) || nextProps.getNested(['selectedCompanyId']);
			this.fetchData(newSelectedCompanyId);
			stateChanges.selectedCompanyId = newSelectedCompanyId;
			this.recalcHeader(newSelectedCompanyId);
		}

		if (Object.values(stateChanges).length > 0) this.setState(stateChanges);
	}

	async fetchData(newSelectedCompanyId = null) {
		const { startTS = null, endTS = null } = this.state;
		const { fetchCompanyTablesData } = this.props;

		if (fetchCompanyTablesData) {
			let tablesData = await fetchCompanyTablesData('quality', newSelectedCompanyId, startTS, endTS);
			let columns = _.concat(qualityTableConfig.columns, this.getTradesColumns(tablesData));
			this.setState({ tablesData, columns });
		}
	}

	getTradesColumns(data) {
		const { tradesMap, intl } = this.props;
		let trades = {};
		data.forEach(project => {
			_.keys(_.get(project, 'openIssues')).forEach(tradeId => {
				let trade = tradesMap.getNested([tradeId]);
				if (!trade) return;

				let pathToValue = ['openIssues', tradeId];
				let title =
					trade.getCementoTitle() ||
					(_.has(trade.title, 'en') && _.get(trade, ['title', 'en'])) ||
					_.values(trade.title)[0] ||
					'-';

				if (!trades[tradeId] && _.get(project, pathToValue))
					trades[tradeId] = {
						section: 'openTasksByTrade',
						name: tradeId,
						title,
						type: 'string',
						path: pathToValue,
						defaultValue: 0,
						getColor: val => (val == 0 ? `${theme.brandNeutralLight}80` : 'inherit'),
						customStyle: { fontSize: theme.fontSizeH5 },
					};
			});
		});
		return _.values(trades).sort((a, b) => _.get(a, 'title', '').localeCompare(_.get(b, 'title', '')));
	}

	handleTimeStampFilter(startTS, endTS, selectedTimeStampFilter) {
		let stateChanges = {
			selectedTimeStampFilter,
			startTS,
			endTS,
		};

		this.setState(stateChanges, this.fetchData);
	}

	handleProjectClick(projectId) {
		const { onProjectClick } = this.props;
		const innerPath = 'qa/analytics';
		if (onProjectClick) onProjectClick(projectId, innerPath);
	}

	recalcHeader(companyId) {
		const { setHeaderParams, isPdfMode, rtl, intl } = this.props;

		if (!setHeaderParams || isPdfMode) return;

		let sideBarParams = { open: false, outerClickClose: true };
		let headerComponent = (
			<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
				<TimeFilter key={`company_${companyId}_timeFilter`} onChange={this.handleTimeStampFilter} isClearable={false} />
				<HoverWrapper
					props={{ color: 'inherit' }}
					hoverProps={{ color: theme.brandPrimary }}
					renderItem={({ color }) => (
						<div
							onClick={() => this.fetchData()}
							style={{
								display: 'flex',
								alignItems: 'center',
								padding: 2 * theme.padding,
								cursor: 'pointer',
							}}
						>
							<RefreshIcon style={{ fontSize: theme.fontSizeH3, color }} />
							<Text
								style={{
									fontWeight: 700,
									[`margin${rtl ? 'Right' : 'Left'}`]: theme.margin / 3,
									color,
								}}
							>
								{systemMessages.refresh}
							</Text>
						</div>
					)}
				/>
			</div>
		);
		setHeaderParams({ headerComponent, sideBarParams });
	}

	render() {
		const { tablesData, selectedTimeStampFilter, columns = [] } = this.state;
		const { rtl, isPdfMode } = this.props;

		if (!tablesData) return;

		let Table = (
			<div style={{ display: 'flex' }}>
				<div style={styles.tableContainer}>
					<div id={'qualityTable'}>
						<CompanyTable
							sections={qualityTableConfig.sections}
							columns={columns}
							data={tablesData}
							rtl={rtl}
							onProjectClick={this.handleProjectClick}
							isPdfMode={isPdfMode}
							timeFilterTitle={selectedTimeStampFilter.tableTitle}
						/>
					</div>
				</div>
			</div>
		);

		return isPdfMode ? (
			Table
		) : (
			<MenuScrollbar isSmooth={false} withHorizontalScroll={true}>
				{Table}
			</MenuScrollbar>
		);
	}
}

const enhance = compose(
	connect(
		state => ({
			rtl: state.app.rtl,
		}),
		{
			startLoading,
			hideLoading,
		},
	),
	injectIntl,
);
export default enhance(CompanyQATable);

const styles = {
	tableContainer: {
		display: 'flex',
		backgroundColor: theme.foregroundColor,
	},
	pdfModeTableContainer: {
		display: 'flex',
		backgroundColor: theme.foregroundColor,
	},
};

const qualityTableConfig = {
	sections: [
		{
			name: 'project',
			title: safetyMessages.safetyTable.project,
		},
		{
			name: 'totalStats',
			title: _.get(analyticsMessages, 'companyQualityTable.sections.totalStats'),
			withBorders: true,
		},
		{
			name: 'recentUse',
			title: _.get(analyticsMessages, 'companyQualityTable.sections.recentUse'),
			injectTimeFilterTitle: true,
			withBorders: true,
		},
		{
			name: 'openTasksByTrade',
			title: _.get(analyticsMessages, 'companyQualityTable.sections.openTasksByTrade'),
			withBorders: true,
		},
	],
	columns: [
		//project section
		{
			section: 'project',
			name: 'projectImage',
			type: 'image',
			path: ['project', 'image'],
			width: 100,
		},
		{
			section: 'project',
			name: 'projectName',
			type: 'col',
			customStyle: {
				padding: theme.padding / 2,
				alignItems: 'flex-start',
			},
			subRows: [
				{
					type: 'string',
					path: ['project', 'title'],
					customStyle: {
						alignItems: 'flex-start',
						fontSize: theme.fontSizeH6,
						height: 'auto',
						overflow: 'hidden',
					},
					width: 'auto',
				},
				{
					type: 'string',
					path: ['project', 'address'],
					customStyle: { flex: 1 },
					width: 'auto',
				},
			],
			width: 210,
		},
		//totalStats sections
		{
			section: 'totalStats',
			name: 'openIssues',
			title: _.get(analyticsMessages, 'companyQualityTable.columns.openIssues'),
			type: 'counter',
			path: ['allTimeStats', 'issues', 'open'],
			counterColorType: 'danger',
			width: 100,
		},
		{
			section: 'totalStats',
			name: 'resolvedIssues',
			title: _.get(analyticsMessages, 'companyQualityTable.columns.resolvedIssues'),
			type: 'counter',
			path: ['allTimeStats', 'issues', 'resolved'],
			counterColorType: 'warning',
			width: 100,
		},
		{
			section: 'totalStats',
			name: 'closedIssues',
			title: _.get(analyticsMessages, 'companyQualityTable.columns.closedIssues'),
			type: 'counter',
			path: ['allTimeStats', 'issues', 'closed'],
			counterColorType: 'success',
			width: 100,
		},
		{
			section: 'totalStats',
			name: 'records',
			title: _.get(analyticsMessages, 'companyQualityTable.columns.records'),
			type: 'counter',
			path: ['allTimeStats', 'records'],
			counterColorType: 'low',
			defaultValue: 0,
			width: 100,
		},
		{
			section: 'totalStats',
			name: 'completedChecklists',
			title: _.get(analyticsMessages, 'companyQualityTable.columns.completedChecklists'),
			type: 'string',
			customStyle: { fontSize: theme.fontSizeH5 },
			path: ['allTimeStats', 'checklists', 'complete'],
			counterColorType: '',
			width: 100,
			getColor: val => (val == 0 ? `${theme.brandNeutralLight}80` : 'inherit'),
		},
		{
			section: 'totalStats',
			name: 'partialChecklists',
			title: _.get(analyticsMessages, 'companyQualityTable.columns.partialChecklists'),
			type: 'string',
			customStyle: { fontSize: theme.fontSizeH5 },
			path: ['allTimeStats', 'checklists', 'partial'],
			counterColorType: '',
			width: 100,
			getColor: val => (val == 0 ? `${theme.brandNeutralLight}80` : 'inherit'),
		},
		//recentUse section
		{
			section: 'recentUse',
			name: 'timedNewIssues',
			title: _.get(analyticsMessages, 'companyQualityTable.columns.newIssues'),
			type: 'counter',
			counterColorType: 'danger',
			customStyle: { fontSize: theme.fontSizeH5 },
			path: ['timedStats', 'issues', 'new'],
			defaultValue: 0,
			width: 100,
		},
		{
			section: 'recentUse',
			name: 'timedResolvedIssues',
			title: _.get(analyticsMessages, 'companyQualityTable.columns.resolvedIssues'),
			type: 'counter',
			counterColorType: 'warning',
			customStyle: { fontSize: theme.fontSizeH5 },
			path: ['timedStats', 'issues', 'resolved'],
			defaultValue: 0,
			width: 100,
		},
		{
			section: 'recentUse',
			name: 'timedClosedIssues',
			title: _.get(analyticsMessages, 'companyQualityTable.columns.recentClosedIssues'),
			type: 'counter',
			counterColorType: 'success',
			customStyle: { fontSize: theme.fontSizeH5 },
			path: ['timedStats', 'issues', 'closed'],
			defaultValue: 0,
			width: 100,
		},
		// {
		//   section: 'recentUse',
		//   title: 'timedReopenedIssues',
		//   name: 'timedReopenedIssues',
		//   type: 'string',
		//   customStyle: { fontSize: theme.fontSizeH5 },
		//   path: ['timedStats', 'issues', 'reopened'],
		//   defaultValue: 0,
		//   width: 100
		// },
		{
			section: 'recentUse',
			name: 'timedNewRecords',
			title: _.get(analyticsMessages, 'companyQualityTable.columns.newRecords'),
			type: 'counter',
			counterColorType: 'low',
			customStyle: { fontSize: theme.fontSizeH5 },
			path: ['timedStats', 'records'],
			defaultValue: 0,
			width: 100,
		},
		{
			section: 'recentUse',
			name: 'recentCompleteChecklists',
			title: _.get(analyticsMessages, 'companyQualityTable.columns.recentCompletedChecklists'),
			type: 'string',
			customStyle: { fontSize: theme.fontSizeH5 },
			path: ['timedStats', 'checklists', 'complete'],
			defaultValue: 0,
			width: 100,
			getColor: val => (val == 0 ? `${theme.brandNeutralLight}80` : 'inherit'),
		},
		{
			section: 'recentUse',
			name: 'recentRejectedChecklists',
			title: _.get(analyticsMessages, 'companyQualityTable.columns.recentPartialChecklists'),
			type: 'string',
			customStyle: { fontSize: theme.fontSizeH5 },
			path: ['timedStats', 'checklists', 'partial'],
			defaultValue: 0,
			width: 100,
			getColor: val => (val == 0 ? `${theme.brandNeutralLight}80` : 'inherit'),
		},
		// {
		//   section: 'recentUse',
		//   name: 'recentChecklistItems',
		//   title: _.get(analyticsMessages, 'companyQualityTable.columns.checklistItems'),
		//   type: 'string',
		//   customStyle: { fontSize: theme.fontSizeH5 },
		//   path: ['timedStats', 'checklistsItems', 'complete'],
		//   defaultValue: 0,
		//   width: 100
		// },
		// {
		//   section: 'recentUse',
		//   name: 'recentRejectedChecklistItems',
		//   title: _.get(analyticsMessages, 'companyQualityTable.columns.rejectedChecklistItems'),
		//   type: 'string',
		//   customStyle: { fontSize: theme.fontSizeH5 },
		//   path: ['timedStats', 'checklistsItems', 'rejected'],
		//   defaultValue: 0,
		//   width: 100
		// }
	],
};
