import React from "react";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import { injectIntl } from "react-intl";
import { compose, hoistStatics } from "recompose";
import { connect } from "react-redux";
import _ from "lodash";

import MultiCheckSelect from "../../components/CementoComponents/MultiCheckSelect";
import InputField from "../../components/CementoComponents/InputField";
import Modal from "../../components/CementoComponents/Modal";

// Assets
import theme from "../../assets/css/theme";
import { Checkbox } from "@material-ui/core";

class TableFilters extends React.Component {
  constructor(props) {
    super(props);
    this.openAdminModal = this.openAdminModal.bind(this);
    this.handleCloseAdminModal = this.handleCloseAdminModal.bind(this);
    this.state = {
      adminModal: false,
    };
  }

  openAdminModal() {
    this.setState({ adminModal: true });
  }

  handleCloseAdminModal() {
    this.setState({ adminModal: false });
  }

  render() {
    const { adminModal } = this.state;
    let {
      intl,
      rtl,
      filters,
      updateFilters,
      selectedFilterSet,
      updateSelectedFilterSet,
      originalSections,
      originalColumns,
      handleSaveFilter,
      showColumnSelector,
      checklists,
      propertiesSections,
      propertiesTypes,
      subjectName,
      lang,
    } = this.props;

    originalColumns = Object.values(originalColumns || {}).filter((col) =>
      Boolean(!checklists.getNested([(col || {}).id, "originChecklist"]))
    ); // filter duplications

    // TODO: Do this only when on admin mode
    let columnTableVisibilityArray = [];
    let columnTableVisibilityMap = {};
    Object.values(originalColumns || {})
      .filter((x) => !x.isPrimary && (!originalSections || x.parentId))
      .forEach((column) => {
        const sectionTitle = originalSections
          ? _.get(originalSections, [column.parentId, "title"], "")
          : column.section;
        if ((filters || {}).filterBySubColumn) {
          Object.values(column.subColumns || {}).forEach((subColumn) => {
            const itemChecked = Boolean(
              filters &&
                _.get(filters, [
                  "columnVisibility",
                  column.id,
                  "subColumnVisibility",
                  subColumn.checklistItemId,
                  "table",
                ])
            );
            const columnObject = {
              id: column.id,
              subId: subColumn.checklistItemId,
              label:
                sectionTitle + " / " + column.title + " / " + subColumn.title,
              checked: itemChecked,
              ordinalNo: subColumn.num,
            };
            columnTableVisibilityArray.push(columnObject);
            columnTableVisibilityMap[column.id] = columnObject;
          });
        } else {
          let checked = Boolean(
            filters &&
              filters.getNested(["columnVisibility", column.id, "table"])
          );
          const columnObject = {
            id: column.id,
            label: sectionTitle + " / " + column.title,
            checked,
            ordinalNo: column.num,
          };
          columnTableVisibilityArray.push(columnObject);
          columnTableVisibilityMap[column.id] = columnObject;
        }
      });

    let columnCardVisibilityArray = Object.values(originalColumns || {})
      .filter((x) => !originalSections || x.parentId)
      .map((column) => {
        const isChecked = Boolean(
          _.get(filters, ["columnVisibility", column.id, "card"])
        );
        const sectionTitle =
          _.get(originalSections, [column.parentId, "title"], column.section) ||
          "";
        return {
          id: column.id,
          label: sectionTitle + " / " + column.title,
          checked: isChecked,
          ordinalNo: column.num,
        };
      });

    const subjectProperties = _.get(propertiesTypes, [subjectName], {});
    _.values(subjectProperties).forEach((property) => {
      const isExistsInTheArrayAlready = columnTableVisibilityMap[property.id];
      if (!isExistsInTheArrayAlready) {
        const sectionTitle = _.get(
          propertiesSections,
          [subjectName, property.sectionId, "title", lang],
          ""
        );
        const propertyTitle = property.getCementoTitle();
        const isChecked = _.get(
          filters,
          ["columnVisibility", property.id, "table"],
          false
        );
        columnTableVisibilityArray.push({
          id: property.id,
          label: sectionTitle + " / " + propertyTitle,
          checked: isChecked,
          ordinalNo: property.num,
        });
        columnCardVisibilityArray.push({
          id: property.id,
          label: sectionTitle + " / " + propertyTitle,
          checked: isChecked,
          ordinalNo: property.num,
        });
      }
    });

    columnTableVisibilityArray = columnTableVisibilityArray.sort((a, b) =>
      (a.label || "").localeCompare(b.label || "")
    );
    columnCardVisibilityArray = columnCardVisibilityArray.sort((a, b) =>
      (a.label || "").localeCompare(b.label || "")
    );

    let reportLabel = (selectedFilterSet || {}).label;
    if (reportLabel && typeof reportLabel == "object")
      reportLabel = intl.formatMessage(reportLabel);
    return (
      <div
        style={{
          height: 30,
          alignSelf: "center",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          background: "lightgray",
          [rtl ? "marginLeft" : "marginRight"]: 10,
          cursor: "pointer",
          padding: 5,
          borderRadius: 5,
        }}
      >
        <div onClick={this.openAdminModal}>
          <span>בחירת עמודות ושמירה</span>
        </div>
        <Modal style={{ zIndex: theme.zIndexes.highest }} onClose={this.handleCloseAdminModal} open={adminModal}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "flex-end",
              flex: 1,
              padding: 2 * theme.paddingSize,
              maxHeight: 800,
            }}
          >
            <span>Filter by sub columns</span>
            <Checkbox
              checked={(filters || {}).filterBySubColumn}
              onChange={(e, isChecked) => {
                let columnVisibility = Object.keys(
                  (filters || {}).columnVisibility || {}
                ).reduce((acc, filterId) => {
                  acc[filterId] = { card: true };
                  return acc;
                }, {});
                updateFilters({
                  ...(filters || {}),
                  columnVisibility,
                  filterBySubColumn: isChecked,
                });
              }}
            />
            <div style={{ margin: theme.paddingSize, maxHeight: "inherit" }}>
              <span>עמודות טבלה</span>
              <MultiCheckSelect
                style={{ margin: 5 }}
                items={columnTableVisibilityArray}
                titlePropPath={["label"]}
                onChange={(allItemsNew, allCheckedMap, allUnheckedMap) => {
                  let newColumnVisibility = Object.assign(
                    {},
                    (filters || {}).columnVisibility
                  );

                  Object.values(allCheckedMap || {}).forEach((newItem) => {
                    newColumnVisibility = newColumnVisibility.setNested(
                      [newItem.id, "table"],
                      true
                    );
                    newColumnVisibility = newColumnVisibility.setNested(
                      [newItem.id, "card"],
                      true
                    );
                    if (newItem.subId) {
                      newColumnVisibility = newColumnVisibility.setNested(
                        [
                          newItem.id,
                          "subColumnVisibility",
                          newItem.subId,
                          "table",
                        ],
                        true
                      );
                      newColumnVisibility = newColumnVisibility.setNested(
                        [
                          newItem.id,
                          "subColumnVisibility",
                          newItem.subId,
                          "card",
                        ],
                        true
                      );
                    }
                  });

                  Object.values(allUnheckedMap || {}).forEach((newItem) => {
                    if (
                      newColumnVisibility[newItem.id] &&
                      newColumnVisibility[newItem.id].table
                    ) {
                      if (newItem.subId) {
                        if (
                          _.get(newColumnVisibility, [
                            newItem.id,
                            "subColumnVisibility",
                            newItem.subId,
                            "table",
                          ])
                        ) {
                          delete newColumnVisibility[newItem.id]
                            .subColumnVisibility[newItem.subId].table;
                          if (
                            !Object.values(
                              newColumnVisibility[newItem.id]
                                .subColumnVisibility
                            ).filter((a) => a.table).length
                          ) {
                            delete newColumnVisibility[newItem.id].table;
                            delete newColumnVisibility[newItem.id]
                              .subColumnVisibility;
                          }
                        }
                      } else delete newColumnVisibility[newItem.id].table;
                    }
                  });

                  let newFilters = (filters || {}).setNested(
                    ["columnVisibility"],
                    newColumnVisibility
                  );
                  updateFilters(newFilters);
                }}
              />
            </div>

            {Boolean(showColumnSelector) && (
              <div style={{ margin: theme.paddingSize, maxHeight: "inherit" }}>
                <span>עמודות כרטיס</span>
                <MultiCheckSelect
                  style={{ margin: 5 }}
                  items={columnCardVisibilityArray}
                  titlePropPath={["label"]}
                  onChange={(allItemsNew, allCheckedMap, allUnheckedMap) => {
                    let newColumnVisibility = Object.assign(
                      {},
                      (filters || {}).columnVisibility
                    );

                    Object.values(allCheckedMap || {}).forEach((newItem) => {
                      newColumnVisibility = newColumnVisibility.setNested(
                        [newItem.id, "card"],
                        true
                      );
                    });

                    Object.values(allUnheckedMap || {}).forEach((newItem) => {
                      if (
                        newColumnVisibility[newItem.id] &&
                        newColumnVisibility[newItem.id].card
                      ) {
                        delete newColumnVisibility[newItem.id].card;
                      }
                    });

                    let newFilters = (filters || {}).setNested(
                      ["columnVisibility"],
                      newColumnVisibility
                    );
                    updateFilters(newFilters);
                  }}
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                alignSelf: "stretch",
                justifyContent: "space-between",
                margin: theme.paddingSize,
              }}
            >
              <span>הגדרות שמירה</span>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span style={{ marginLeft: theme.padding }}>שם דוח: </span>
                  <InputField
                    type={"String"}
                    key={"reportName"}
                    value={reportLabel}
                    onChange={(val) => {
                      let newSelectedfilterSet = Object.assign(
                        {},
                        selectedFilterSet,
                        { label: val }
                      );
                      updateSelectedFilterSet(newSelectedfilterSet);
                    }}
                  />
                </div>

                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span style={{ marginLeft: theme.padding }}>מספר :</span>
                  <InputField
                    style={{ width: "100%" }}
                    type={"Number"}
                    key={"reportNum"}
                    value={(selectedFilterSet || {}).num}
                    onChange={(val) => {
                      let newSelectedfilterSet = Object.assign(
                        {},
                        selectedFilterSet,
                        { num: val }
                      );
                      updateSelectedFilterSet(newSelectedfilterSet);
                    }}
                  />
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  disabled={!Boolean((selectedFilterSet || {}).label)}
                  onClick={() => handleSaveFilter()}
                  style={{
                    height: 30,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    background: "lightgray",
                    marginRight: 10,
                    marginLeft: 10,
                    cursor: "pointer",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <span>שמירה כדו"ח חדש</span>
                </div>

                {Boolean(selectedFilterSet) && (
                  <div
                    disabled={!Boolean((selectedFilterSet || {}).label)}
                    onClick={() => handleSaveFilter(selectedFilterSet)}
                    style={{
                      height: 30,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      marginLeft: 10,
                      background: "lightgray",
                      cursor: "pointer",
                      padding: 5,
                      borderRadius: 5,
                    }}
                  >
                    <span>עדכן דו"ח קיים</span>
                  </div>
                )}

                {Boolean(selectedFilterSet) && (
                  <div
                    disabled={!Boolean((selectedFilterSet || {}).label)}
                    onClick={() => handleSaveFilter(selectedFilterSet, true)}
                    style={{
                      height: 30,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      marginLeft: 10,
                      background: "lightgray",
                      cursor: "pointer",
                      padding: 5,
                      borderRadius: 5,
                    }}
                  >
                    <span>מחק דו"ח</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

TableFilters = injectIntl(TableFilters);

const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect((state) => ({
    uiParams: state.ui.uiParams,
  }))
);
export default enhance(TableFilters);
