import React, { Component } from "react";
import SearchIcon from "../../assets/img/icons/search_icon.png";
import CancelIcon from "../../assets/img/icons/exit.png";
import theme from "../../assets/css/theme";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { track } from "../../../common/lib/reporting/actions";
import { injectIntl } from "react-intl";
import postsMessages from "../../../common/posts/postsMessages";

class TextFilter extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      active: false,
    };
  }

  activate = () => {
    this.inputRef.current.focus();
    this.setState({ active: true });
  };

  deactivate = () => {
    const { track, value } = this.props;
    // track("filterByText", { content: value });
    this.setState({ active: false });
  };

  changeHandler = (e) => this.props.onChange(e.target.value);
  render() {
    const { active } = this.state;
    const { value, clearFilterVal, intl, containerStyle = {} } = this.props;
    const isValueEmpty = Boolean(!value || value.length < 1);

    return (
      <div
        onClick={this.activate}
        style={{
          ...styles.container,
          ...(active && { border: `solid 2px ${theme.brandPrimary}` }),
          ...containerStyle,
        }}
      >
        <input
          onBlur={this.deactivate}
          ref={this.inputRef}
          style={styles.input}
          type="text"
          placeholder={intl.formatMessage(postsMessages.searchPlaceholder)}
          value={value}
          onChange={this.changeHandler}
        />

        <div style={{ maxWidth: "20%" }}>
          <img
            style={{
              ...styles.icons,
              ...(!isValueEmpty && { cursor: "pointer" }),
            }}
            src={isValueEmpty ? SearchIcon : CancelIcon}
            onClick={(!isValueEmpty && clearFilterVal) ? clearFilterVal : undefined}
          />
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    minWidth: 140,
    borderRadius: 50,
    margin: "0 5px",
    padding: "3px 6px",
    backgroundColor: "#f6f6f6",
    border: `solid 1px #dee0e5 `,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    height: 35,
  },
  input: {
    borderRadius: 50,
    height: 28,
    minWidth: "85%",
  },
  icons: {
    flex: 1,
    maxWidth: 20,
  },
};

const enhance = compose(connect(null, { track }));

TextFilter = injectIntl(TextFilter);

export default enhance(TextFilter);
