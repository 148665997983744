import analyticsMessages from '../../../../common/analytics/analyticsMessages';
import systemMessages from '../../../../common/app/systemMessages';
import { Section, Field } from './projectManagerConfig';
import newProjectMessages from '../../../../common/projects/newProjectMessages';

const {
    description,
    type,
    basic,
} = systemMessages;

const {
    locations,
} = analyticsMessages;

//////////////////////////////////////////
//////////////// Group Fields ////////////intl.formatMessage
//////////////////////////////////////////

const groupDescription = new Field(description, 'String', ['title'], null, { fullWidth: true, mandatory: true });

const groupType = new Field(type, 'SelectionList', ['types'], [{id: '-buildings', title: newProjectMessages.locationTypes.buildings}, {id: '-floors', title: newProjectMessages.locationTypes.floors}, {id: '-units', title: newProjectMessages.locationTypes.units}], { fullWidth: true, mandatory: true });

//////////////////////////////////////////
//////////////// Group Section ////////////
//////////////////////////////////////////

const groupBasicSection = new Section(basic, [
    groupDescription,
    groupType,
], {}, 'basic');




export const groupSections =[
    groupBasicSection,
];