import _ from 'lodash';
import { getServerSocket } from './io/serverSocket';
import * as ioEvents from './io/eventTypes';
import * as ioDisconnectReasons from './io/disconnectReasons';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

/**
 * @typedef {'projects' | 'companies'} Scope
 * @typedef {{ data?: any[], attributes: SubscriptionParams }} ChangePayload
 * @typedef {{ id: string, phoneNumber: string }} Viewer
 * @typedef {{ scope: Scope, scopeId: string, subject: string, shouldSendData: boolean, lastUpdateTS: number, params?: Object<string, any> }} SubscriptionParams
 * @typedef {(data: any[]) => void} OnData
 * @typedef {() => void} OnDataChanged
 * @typedef {{ onData?: OnData, onDataChanged?: OnDataChanged }} ServiceHandlers
 * @typedef {{ id: string, subscriptionParams: SubscriptionParams, handlers?: ServiceHandlers }} Service
 */

const DEBOUNCED_ON_DATA_CHANGED_TIMEOUT = 1000;
class ClientServerConnectivityManager {
  /** @type {Boolean} */
  #didInitSocketListeners;
  /** @type {Object<string, Service>} */
  #registeredServices;
  /** @type {Viewer} */
  #registeredViewer;
  /** @type {ReturnType<getServerSocket>} */
  #serverSocket;
  /** @type {{[serviceId: string]: (callback: OnDataChanged) => void}} */
  #debouncedOnDataChanged;

  constructor() {
    this.#didInitSocketListeners = false;
    this.#registeredServices = {};
    this.#registeredViewer = null;
    this.#serverSocket = null;
    this.#debouncedOnDataChanged = {};
  }

  /**
   * Handles change events from the server, Given to the change listener on the socket
   * 
   * @param {ChangePayload} payload 
   * @returns 
   */
  #handleChangeListener = (payload) => {
    if (!payload.attributes) return;

    const service = this.#getService(payload.attributes);
    if (!service)
      return;

    const { id: serviceId, handlers, subscriptionParams } = service;
    
    const { onDataChanged, onData } = handlers;
    if (subscriptionParams.shouldSendData && payload.data) {
      console.info(`Socket event:`, `"${ioEvents.CHANGE}"`, `Saving data ${subscriptionParams.scopeId} ${subscriptionParams.subject}`);
      onData?.(payload.data);
    } else {
      if (!this.#debouncedOnDataChanged[serviceId])
        this.#debouncedOnDataChanged[serviceId] = AwesomeDebouncePromise(callback => {
          console.info(`Socket event:`, `"${ioEvents.CHANGE}"`, `Fetching ${subscriptionParams.scopeId} ${subscriptionParams.subject}`);
          callback?.();
        }, DEBOUNCED_ON_DATA_CHANGED_TIMEOUT);

      this.#debouncedOnDataChanged[serviceId](onDataChanged); // Sending it as a callback because it could get updated between different calls
    }
  }

  /**
   * Inits server socket listeners
   * 
   * @returns 
   */
  #initSocketListeners = () => {
    const serverSocket = this.#serverSocket;
    if (!serverSocket)
      return; // TODO: throw error?

    if (!this.#didInitSocketListeners) {
      /**
       * 
       * @param {typeof ioDisconnectReasons[keyof typeof ioDisconnectReasons]} reason 
       * @returns
       */
      const disconnectListener = reason => {
        if (reason === ioDisconnectReasons.CLIENT_DISCONNECT) {
          console.info('Socket event:', `"${ioEvents.DISCONNECT}"`, 'Unregistering services...');
          serverSocket.removeListener(ioEvents.CHANGE, this.#handleChangeListener);
          serverSocket.removeListener(ioEvents.DISCONNECT, disconnectListener);
          serverSocket.removeListener(ioEvents.RECONNECT, this.#renewServicesSubscriptions);
          this.#clearAllRegisteredServices();
          this.#didInitSocketListeners = false;
        }
      }

      serverSocket.on(ioEvents.CHANGE, this.#handleChangeListener);
      serverSocket.on(ioEvents.DISCONNECT, disconnectListener);
      serverSocket.on(ioEvents.RECONNECT, this.#renewServicesSubscriptions);
      this.#didInitSocketListeners = true;
    }
  }

  /**
   * Sets up listeners on initialization of the socket and emits subscribe event with the given params
   * 
   * @param {SubscriptionParams} subscriptionParams 
   * @returns 
   */
  #subscribeService = (subscriptionParams) => {
    const serverSocket = this.#serverSocket;
    if (!serverSocket)
      return; // TODO: throw error?

    this.#initSocketListeners();

    let subParams = subscriptionParams;
    if (_.isEmpty(subParams.params))
      delete subParams.params;

    serverSocket.emit(ioEvents.SUBSCRIBE, subscriptionParams);
  }

  /**
   * Goes over the registeredServices map and subcribes to events again
   * 
   * @returns
   */
  #renewServicesSubscriptions = () => {
    console.info('Socket event:', `"${ioEvents.RECONNECT}"`, 'Renewing subscriptions...')
    _.values(this.#registeredServices).forEach(service => {
      const { subscriptionParams, handlers } = service;
      this.#subscribeService(subscriptionParams);
      handlers?.onDataChanged?.(); // When resubscribing, should fetch the data again from last update in case we missed updates;
    });
  }

  /**
   * Clears registeredServices map
   * 
   * @returns
   */
  #clearAllRegisteredServices = () => {
    this.#registeredServices = {};
  }

  /**
   * Concats subscriptions params to generate a unique id for the subscription. 
   * This id is then used as key in the registeredServices map
   * 
   * @param {Partial<SubscriptionParams>} subscriptionParams 
   * @returns 
   */
  #getUniqueServiceId = (subscriptionParams) => {
    const { scope, scopeId, subject, params } = subscriptionParams;
    return [
      scope,
      scopeId,
      subject,
      _.values(params).join('+'),
    ].filter(Boolean).join('_');
  }

  /**
   * Registers the service to the registeredServices map
   * 
   * @param {SubscriptionParams} subscriptionParams 
   * @param {ServiceHandlers} serviceHandlers 
   * @returns 
   */
  #registerService = (subscriptionParams, serviceHandlers) => {
    const uniqueId = this.#getUniqueServiceId(subscriptionParams);
    this.#registeredServices[uniqueId] = {
      id: uniqueId,
      subscriptionParams,
      handlers: serviceHandlers,
    };
  }
  
  /**
   * Update a service on registeredServices map and resubscribes if need be
   * 
   * @param {SubscriptionParams} _subscriptionParams 
   * @param {ServiceHandlers} serviceHandlers 
   * @returns 
   */
  #updateServiceSubscription = (_subscriptionParams, serviceHandlers) => {
    const existingService = this.#getService(_subscriptionParams);
    if (!existingService)
      return;

    const { subscriptionParams } = existingService;
    const shouldRenewSubscription = _subscriptionParams.shouldSendData !== subscriptionParams.shouldSendData;

    this.#registerService(_subscriptionParams, serviceHandlers);
    if (shouldRenewSubscription) {
      this.#unsubscribeService(subscriptionParams);
      this.#subscribeService(subscriptionParams);
    }
  }

  /**
   * Removes a service from the registeredServices map
   * 
   * @param {SubscriptionParams} subscriptionParams 
   * @returns 
   */
  #unregisterService = (subscriptionParams) => {
    const serviceId = this.#getUniqueServiceId(subscriptionParams);
    delete this.#registeredServices[serviceId];
    delete this.#debouncedOnDataChanged[serviceId];
  }

  /**
   * Emits an unsubscribe event to the server
   * 
   * @param {SubscriptionParams} subscriptionParams 
   * @returns 
   */
  #unsubscribeService = (subscriptionParams) => {
    const serverSocket = this.#serverSocket;
    if (!serverSocket)
      return; // TODO: throw

    let subParams = subscriptionParams;
    if (_.isEmpty(subscriptionParams.params))
      delete subParams.params;
    serverSocket.emit(ioEvents.UNSUBSCRIBE, subParams);
  }

  /**
   * Get a service from registeredService map
   * 
   * @param {SubscriptionParams} subscriptionParams 
   * @returns 
   */
   #getService = (subscriptionParams) => {
    const uniqueId = this.#getUniqueServiceId(subscriptionParams);
    return this.#registeredServices[uniqueId];
  }

  /**
   * Get services matching the subscriptions params (which can be partial but must include at least scope and scopeId) from 
   * the registeredServices map
   * 
   * @param {Partial<SubscriptionParams>} subscriptionParams 
   * @returns 
   */
  #getServices = (subscriptionParams) => {
    const uniqueId = this.#getUniqueServiceId(subscriptionParams);
    /** @type {Service[]} */
    let relevantServices = [];
    _.entries(this.#registeredServices).forEach(([uniqueServiceId, service]) => {
      if (uniqueServiceId.indexOf(uniqueId) !== -1) {
        relevantServices.push(service);
      }
    });

    return relevantServices;
  }

  /**
   * Set the registeredViewer to the new viewer and renew the subscriptions based on the new viewer
   * 
   * @param {Viewer} viewer 
   * @return
   */
  #setViewer = (viewer) => {
    if (this.#registeredViewer?.id !== viewer?.id) {
      const servicesToResubscribe = this.#registeredServices;
      
      this.#registeredViewer = viewer;
      this.#serverSocket = getServerSocket(viewer); // Will automatically trigger a manual disconnect event which will clear the listeners and clear the services map
      
      this.#serverSocket.once(ioEvents.CONNECT, () => {
        _.values(servicesToResubscribe).forEach(service => {
          const { subscriptionParams, handlers } = service;
          this.subscribeService(viewer, subscriptionParams, handlers);
        });
      });
    }
  }

  /**
   * Unsubscribed all registered services of a scope
   * 
   * @public
   * @param {Scope} scope 
   * @param {string} scopeId 
   * @returns 
   */
  unsubscribeAllScopeServices = (scope, scopeId) => {
    if (!(scope && scopeId))
      return; // TODO: throw

    const relevantServices = this.#getServices({ scope, scopeId })
    relevantServices.forEach(service => {
      this.unsubscribeService(service.subscriptionParams);
    });
  }

  /**
   * - Sets the viewer to trigger side effects if the viewer changes.
   * - Checks if the service is already subscribed, if so, no need to emit the event again, just update the handlers,
   * otherwise, subscribe the service
   * 
   * @public
   * @param {Viewer} viewer 
   * @param {SubscriptionParams} subscriptionParams 
   * @param {ServiceHandlers} serviceHandlers 
   * @returns 
   */
  subscribeService = (viewer, subscriptionParams, serviceHandlers) => {
    if (this.#registeredViewer?.id !== viewer?.id) {
      this.#setViewer(viewer);
    }

    if (this.isServiceSubscribed(subscriptionParams)) {
      this.#updateServiceSubscription(subscriptionParams, serviceHandlers);
    }
    else {
      this.#registerService(subscriptionParams, serviceHandlers);
      this.#subscribeService(subscriptionParams);
    }
  }

  /**
   * Unsubscribes and unregisters service
   * 
   * @public
   * @param {SubscriptionParams} subscriptionParams 
   * @returns 
   */
  unsubscribeService = (subscriptionParams) => {
    if (!this.isServiceSubscribed(subscriptionParams))
      return;

    this.#unsubscribeService(subscriptionParams);
    this.#unregisterService(subscriptionParams);
  }

  /**
   * Check if the service is registered
   * 
   * @public
   * @param {SubscriptionParams} subscriptionParams 
   * @returns 
   */
  isServiceSubscribed = (subscriptionParams) => {
    return Boolean(this.#getService(subscriptionParams));
  }
}

const ClientServerConnectivityManagerInstance = new ClientServerConnectivityManager();
globalThis.__ClientServerConnectivityManagerInstance = ClientServerConnectivityManagerInstance;
export default ClientServerConnectivityManagerInstance;
