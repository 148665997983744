import {getSnapshotData} from '../lib/utils/utils';

export const GET_UNITS = 'GET_UNITS';
export const GET_LOCAL_UNITS = 'GET_LOCAL_UNITS';
export const END_UNITS_LISTENER = 'END_UNITS_LISTENER';
export const CLEAR_LOCAL_UNITS = 'CLEAR_LOCAL_UNITS';
export const UPDATE_UNIT = 'UPDATE_UNIT';
export const UPDATE_LOCAL_UNIT = 'UPDATE_LOCAL_UNIT';
export const UPDATE_UNITS = 'UPDATE_UNITS';
export const UPDATE_LOCAL_UNITS = 'UPDATE_LOCAL_UNITS';
export const GET_NEW_UNIT_ID = 'GET_NEW_UNIT_ID';


export function getUnits(projectId) {	
  return {
    type: GET_UNITS,
    payload: getSnapshotData({api:'units'}, projectId)
};
}

export function getLocalUnits(projectId, units) {
  return {
    type: GET_LOCAL_UNITS + "_SUCCESS",
    payload: { projectId, units }
  }
}

export function endUnitsListener(projectId) {	
  return ({ dispatch, firebaseDatabase }) => {
	  firebaseDatabase().ref('units/' + projectId).off('value');
    return {
      type: END_UNITS_LISTENER,
      payload: { projectId }
    };
  };
}

export function removeLocalUnits(projectId) {
  return {
    type: CLEAR_LOCAL_UNITS,
    payload: { projectId }
  }
}

export function updateUnit(projectId, buildingId, unit) {
  return ({ firebase, firebaseDatabase }) => {
    const getPromise = async () => {
      if (!projectId || !buildingId || !unit)
        return false;
      
      let updates = {};
      if (!unit.id)
        unit.id = firebaseDatabase().ref('units/' + projectId + '/' + buildingId).push().key;
      
      updates['units/' + projectId + '/' + buildingId + '/' + unit.id] = unit;

      await firebase.update(updates);
      return { projectId, buildingId, unit };
    }

    return {
      type: UPDATE_UNIT,
      payload: getPromise()
    }
  }
}

export function updateLocalUnit(projectId, buildingId, unit) {
  return {
    type: UPDATE_LOCAL_UNIT + "_SUCCESS",
    payload: { projectId, buildingId, unit }
  }
}

export function updateUnits(projectId, buildingId, units, replaceAll) {
  return ({ firebase, firebaseDatabase }) => {
    const getPromise = async () => {
      
      if (!projectId || !buildingId || !units)
        return false;

      let updates = {};
      
      if (replaceAll) {
        
        let path = 'units/' + projectId + '/' + buildingId;
        units.loopEach((i, unit) => {
          unit = unit.toJS ? unit.toJS() : unit;
          if (!unit.id)
            unit.id = firebaseDatabase().ref('units/' + projectId + '/' + buildingId).push().key;
            
          updates = updates.setNested([path, unit.id], unit);
        });
      } else {
        units.loopEach((i, unit) => {
          unit = unit.toJS ? unit.toJS() : unit;
          if (!unit.id) 
            unit.id = firebaseDatabase().ref('units/' + projectId + '/' + buildingId).push().key;
          if(!unit.title) unit.title = '';
          updates['units/' + projectId + '/' + buildingId + '/' + unit.id] = unit;
        });
      }
      
      await firebase.update(updates);
      return { projectId, buildingId, units };
    };

    return {
      type: UPDATE_UNITS,
      payload: getPromise()
    };
  };
}

export function updateLocalUnits(projectId, buildingId, units) {
  return {
    type: UPDATE_LOCAL_UNITS + "_SUCCESS",
    payload: { projectId, buildingId, units }
  }
}

export function getNewUnitId(projectId, buildingId) {
  return ({ firebaseDatabase }) => {
    if (!projectId || !buildingId)
      return false;

    let unitId = firebaseDatabase().ref('units/' + projectId + '/' + buildingId).push().key;

    return {
      type: GET_NEW_UNIT_ID,
      payload: { unitId }
    }
  }
}