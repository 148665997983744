import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import {
  GridContainer,
  GridItem,
  Card,
  CustomInput,
  PictureUpload,
} from "../../components";
import Button from "@material-ui/core/Button";
import { AttachmentRounded, SendRounded } from "@material-ui/icons";
import User from "../../components/CementoComponents/User";
import Image from "../../components/CementoComponents/Image";
import Text from "../../components/CementoComponents/Text";
import DisplayName from "../../components/CementoComponents/DisplayName";
import commentsMessages from "../../../common/comments/commentsMessages";
import * as IssueStates from "../../../common/issues/issueStates.js";
import notificationsMessages from "../../../common/notifications/notificationsMessages";
import theme from "../../assets/css/theme";
import buttonsStyle from "../../assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx";
import { v4 as uuidv4 } from "uuid";

class Comment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onNewCommentChange = this.onNewCommentChange.bind(this);
    this.onCreateNewComment = this.onCreateNewComment.bind(this);
    this.onImageUploadSubmit = this.onImageUploadSubmit.bind(this);
    this.state = {
      newImageData: null,
      newCommentText: "",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};

    if (props.parentId != nextProps.parentId)
      newStateChanges.newCommentText = "";

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  onCreateNewComment() {
    const { onCreate, parentId } = this.props;
    const { newCommentText, newImageData } = this.state;
    if (onCreate) {
      let newComment = { content: newCommentText };

      if (newImageData) {
        var newId = uuidv4();
        newComment.images = {};
        newComment.images[newId] = {
          id: newId,
          uri: "",
          data: newImageData,
          uploading: true,
        };
      }

      onCreate(newComment, parentId);
      this.setState({ newCommentText: "", newImageData: null });
    }
  }

  onNewCommentChange(event) {
    this.setState({ newCommentText: event.target.value });
  }

  onImageUploadSubmit(uploadObject) {
    this.setState({ newImageData: uploadObject });
  }

  render() {
    const { comment, style, newCommentMode, classes } = this.props;
    const { newCommentText, newImageData } = this.state;

    if (newCommentMode)
      return (
        <GridContainer spacing={12} alignItems="center" style={style}>
          <div style={{ display: "flex", flex: 1 }}>
            <CustomInput
              noClasses
              formControlProps={{ fullWidth: true }}
              inputProps={{
                autoFocus: true,
                disableUnderline: !Boolean(newImageData),
                value: newCommentText,
                onChange: this.onNewCommentChange,
                multiline: true,
              }}
            />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 5px" }}
          >
            {Boolean(newImageData) && (
              <div style={{ width: 20 }}>
                <Image
                  src={
                    typeof newImageData == "object"
                      ? newImageData.data
                      : newImageData
                  }
                />
              </div>
            )}
            <PictureUpload
              iconStyle={{ margin: 0, maxHeight: 20 }}
              onSubmit={this.onImageUploadSubmit}
            />
            <SendRounded
              style={{
                display: "flex",
                alignSelf: "center",
                justifyContent: "center",
                color: theme.brandPrimary,
                margin: theme.margin * 3,
              }}
              onClick={this.onCreateNewComment}
            />
          </div>
        </GridContainer>
      );
    else {
      var content = null;
      var sentence = null;

      if (comment.parentType == "checklistItemInstance") {
        sentence =
          notificationsMessages.checklistItemInstance.status[
            comment.data.status
          ];
        if (sentence)
          // TODO: get the content out of the displayName
          content = (
            <DisplayName
              messageValueProperty={"name"}
              userId={comment.owner.id}
              message={sentence}
            />
          );
      } else {
        if (comment.type == "status" && comment.owner) {
          if (comment.data.status == IssueStates.ISSUE_STATE_RESOLVED)
            sentence = <Text>{notificationsMessages.changedStatusDone}</Text>;
          else if (comment.data.status == IssueStates.ISSUE_STATE_OPENED)
            sentence = <Text>{notificationsMessages.changedStatusOpen}</Text>;
          else if (comment.data.status == IssueStates.ISSUE_STATE_CLOSED)
            sentence = <Text>{notificationsMessages.changedStatusClose}</Text>;
        } else if (
          (comment.type == "forward" || comment.type == "forwardIssue") &&
          comment.getNested(["data", "newAssignTo", "id"])
        ) {
          content = (
            <DisplayName
              messageValueProperty={"name"}
              userId={comment.data.newAssignTo.id}
              message={notificationsMessages.forwardMessage}
            />
          );
        }
      }

      if (!content) content = <Text>{comment.content}</Text>;

      return (
        <GridContainer spacing={8} style={style}>
          <GridItem xs={4}>
            <User
              userId={comment.getNested(["owner", "id"])}
              hideTitleAndTrades={true}
            />
          </GridItem>
          <GridItem xs={8}>
            <GridContainer>
              <GridItem xs={12}>{content}</GridItem>
              <GridItem xs={12}>
                {Object.values(comment.images || {}).map(
                  (curr) => Boolean(curr) && <Image src={curr.uri} />
                )}
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      );
    }
  }
}

let styles = {
  outlinedButtons: {
    fontSize: "8.5px",
    borderRadius: 0,
    color: theme.brandNeutral,
  },
};

export default withStyles(styles)(Comment);
