import { getProjectGCs } from '../companies/funcs';
import * as companiesConsts from '../companies/companiesTypes';
import { realmInstance } from '../configureMiddleware';
import _ from 'lodash';
import { platformActions } from '../platformActions';
import { instanceDataToString } from '../propertiesInstances/funcs';
import { postsSchema } from '../lib/realm/schema';
import { convertDateToTS } from '../lib/utils/utils';

let instancesMap = {};
const POSTS_SUBJECT_NAME = 'postsInfo';

export function getPostInstances(projectId) {
	let postsInstances = _.get(instancesMap, [projectId, POSTS_SUBJECT_NAME]);

	if (!postsInstances) {
		const realmQuery = `projectId = "${projectId}" AND subjectName = "${POSTS_SUBJECT_NAME}"`
		postsInstances = realmInstance.propertyInstances.objects('propertyInstance1').filtered(realmQuery);
		setPostsInstances(projectId, postsInstances);
	}

	return postsInstances;
}

function setPostsInstances(projectId, instancesCursor) {
	_.set(instancesMap, [projectId, POSTS_SUBJECT_NAME], instancesCursor);
}

export function getPostsPrefixProp(postsPropertiesTypes, viewsConfigurations) {
	const universalIdForTitlePrefix = _.get(viewsConfigurations, ['pages', 'posts', 'List', 'titles', 'primary', 'prefix', 'universalId']);
	let postsPrefixProp = null;

	if (universalIdForTitlePrefix)
		postsPrefixProp =_.head(_.filter(postsPropertiesTypes, propertyType => propertyType.universalId === universalIdForTitlePrefix));

	return postsPrefixProp;
}

export function getFullPostTitle(postTitle, postPrefixTitle, delimiter) {
	const fullPostTitle = [postPrefixTitle, postTitle].filter(Boolean).join(delimiter);

	return fullPostTitle;
}

export function isPostInValid(post, mandatoryData) {
	
	if (!post)
		return true;

	let drawingExists = false;
	let imagesArray = post.images || [];
	let attachmentsArray = post.attachments || [];
	imagesArray = Array.isArray(imagesArray) ? imagesArray : Object.values(imagesArray);
	attachmentsArray = Array.isArray(attachmentsArray) ? attachmentsArray : Object.values(attachmentsArray);
	
    imagesArray.forEach(img => drawingExists = drawingExists || img.type == 'drawing');
    let inValid = {
      img: mandatoryData && mandatoryData.img && imagesArray.length == 0,
      desc: mandatoryData && mandatoryData.desc && (!post.title || post.title.trim() == ''),
      file: mandatoryData && mandatoryData.file && attachmentsArray == 0, 
      drawing: mandatoryData && mandatoryData.drawing && !drawingExists,
      assignee: post.isIssue && !post.assignTo,
      general: (!mandatoryData || (!mandatoryData.drawing && !mandatoryData.file)) && (!post.title || post.title.trim() == '') && imagesArray.length == 0 && attachmentsArray.length == 0,
    }

    let inValidKey = null;
    Object.keys(inValid).forEach(k => inValidKey = inValid[k] ? k : inValidKey);

	return inValidKey;
}

export function setDefaultTagSelected(inNewPost, members, viewer, projectId) {
	let newPost = Object.assign({}, inNewPost);

	if (!newPost.taggedCompanies)
			newPost.taggedCompanies = {};

	// Add the company of the assignee
	if (newPost.assignTo) {
			let assignToCompanyId = members.getNested([newPost.assignTo.id, 'companyId']);
			if (assignToCompanyId) 
				newPost.taggedCompanies[assignToCompanyId] = { id: assignToCompanyId }
			else { 
				if (!newPost.taggedCompanies[companiesConsts.unknownCompanyId]) 
					newPost.taggedCompanies[companiesConsts.unknownCompanyId] = { id: companiesConsts.unknownCompanyId, explicitMembers: {}} 
				newPost.taggedCompanies = newPost.taggedCompanies.setNested([companiesConsts.unknownCompanyId, "explicitMembers", newPost.assignTo.id], newPost.assignTo);
			}
	}

	// Add the company of the viewer
	let viewerCompanyId = members.getNested([viewer.id, 'companyId']);
	if (viewerCompanyId) newPost.taggedCompanies[viewerCompanyId] = { id: viewerCompanyId }
	else { 
		if (!newPost.taggedCompanies[companiesConsts.unknownCompanyId]) 
			newPost.taggedCompanies[companiesConsts.unknownCompanyId] = { id: companiesConsts.unknownCompanyId, explicitMembers: {}};
		newPost.taggedCompanies = newPost.taggedCompanies.setNested([companiesConsts.unknownCompanyId, "explicitMembers", viewer.id], viewer);
	}

	// Add menagment companies
	let GCsIds = getProjectGCs(projectId);
	(GCsIds || []).loopEach((i, currGC) => {
		newPost.taggedCompanies[currGC.id] = { id: currGC.id };
	});

	//this.setState({ post: newPost, didChange: true });

	return newPost;
}

export function prepareRealmForFirebase(inPost, projectId) {
	let post = JSON.parse(JSON.stringify(inPost));
	arrayToKeyValue(post, 'comments');
	arrayToKeyValue(post, 'images');
	arrayToKeyValue(post, 'attachments');
	arrayToKeyValue(post, 'taggedCompanies');
	arrayToKeyValue(post, 'refs');
	preparePostDateFieldsBeforeUpload(post);

	if (post && post.trade && post.trade.id && post.trade.id.toString)
		post.trade = { id: post.trade.id.toString() };

	post.projectId = projectId;

	return post;
}

function arrayToKeyValue(obj, path) {
	let array = obj[path];
	
	if (!Object.keys(array || {}).length) {
		delete obj[path];
		return;
	}

	obj[path] = {};
	Object.values(array).forEach(x => obj[path][x.id] = x);
}

const postDateFields = _.reduce(postsSchema.properties, (acc = {}, curr, key) => {
	if (curr === 'date' || curr === 'date?' || curr.type === 'date' || curr.type === 'date?')
		acc[key] = key;
	return acc;
}, {});

const preparePostDateFieldsBeforeUpload = post => {
	_.forIn(postDateFields, fieldKey => {
		const prevVal = post[fieldKey];
		const nextVal = convertDateToTS(prevVal);
		_.set(post, [fieldKey], nextVal);
	});
	return post;
}

export function getPostPrefixData(intl, prefixProp, instance) {
	const isNative = platformActions.app.isNative();
	
	let prefixString = '';
	
	if (instance) {
		let prefixData = _.get(instance, 'data');
		if (isNative && typeof prefixData === 'string') {
			try {
				prefixData = JSON.parse(prefixData); //because of realm we doing parse
			}
			catch (error) {}
		}
	
		if (prefixData)
			prefixString = instanceDataToString(prefixProp, prefixData, intl);
	}
	

	return prefixString;
}