import React from "react";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import { envParams } from "../../../common/configureMiddleware";
import {
  revokeUsersCache,
  updateProjectLastUpdateTS,
} from "../../../common/lastUpdates/actions";
import Select from "react-select";
import withStyles from "@material-ui/core/styles/withStyles";
import MemberCard_deprecated from "./MemberCard_deprecated";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import theme from "../../assets/css/theme";
import Button from "../../components/CustomButtons/Button.jsx";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import MembersManagerTable from "./MembersManagerTable";
import MultiCheckSelect from "../../components/CementoComponents/MultiCheckSelect";
import Text from "../../components/CementoComponents/Text";
import webActions from '../../webActions';
import _ from "lodash"

class MembersManager extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.revokeCache = this.revokeCache.bind(this);
    this.onRevokeFeatureSelect = this.onRevokeFeatureSelect.bind(this);
    this.onRevokeCacheClick = this.onRevokeCacheClick.bind(this);
    this.onLastUpdateTSClick = this.onLastUpdateTSClick.bind(this);
    this.updateMultiCheckSelect = this.updateMultiCheckSelect.bind(this);
    this.getZendeskOrganizations = this.getZendeskOrganizations.bind(this);
    this.getCementoCompanies = this.getCementoCompanies.bind(this);
    this.saveCompanyOrganizationMapping = this.saveCompanyOrganizationMapping.bind(this);
    this.clearMultiSelect = this.clearMultiSelect.bind(this);
    this.getNumberOfActiveProjects = this.getNumberOfActiveProjects.bind(this);
    this.saveParentCompaniesMapping = this.saveParentCompaniesMapping.bind(this);
    this.toggleCementoZendeskMapping = this.toggleCementoZendeskMapping.bind(this);
    this.toggleParentCompanyConfiguration = this.toggleParentCompanyConfiguration.bind(this);
    this.state = {
      selectedMember: null,
    };
  }

  async UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
    this.setState({
      showCementoZendeskMapping: false,
      showParentCompanyConfiguration: false
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};
    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  onSelect(selected) {
    this.setState({ selectedMember: selected });
  }

  onRevokeFeatureSelect(e) {
    let selectedRevokeFeature = e;
    this.setState({ selectedRevokeFeature });
  }

  onRevokeCacheClick() {
    const { members, selectedProjectId } = this.props;
    const { selectedRevokeFeature } = this.state;
    this.revokeCache(
      selectedRevokeFeature,
      members ? Object.keys(members) : null,
      selectedProjectId ? selectedProjectId : null
    );
  }

  onLastUpdateTSClick() {
    const { members, selectedProjectId, updateProjectLastUpdateTS } =
      this.props;
    const { selectedRevokeFeature } = this.state;
    if (!selectedProjectId) return;
    updateProjectLastUpdateTS(selectedRevokeFeature.value, selectedProjectId);
  }

  revokeCache(feature, usersIds, projectsIds) {
    const { revokeUsersCache } = this.props;
    const { selectedRevokeFeature } = this.state;
    if (usersIds && projectsIds && feature)
      revokeUsersCache(feature, false, usersIds, projectsIds);
    else if (selectedRevokeFeature.value)
      revokeUsersCache(selectedRevokeFeature.value, true);
  }

  updateMultiCheckSelect(allItems, statePath, allowMultiSelect=false) {
    let updatedItems = _.get(this.state, [statePath]);
    let checkedItems = {};
    (allItems || []).forEach(currItem => {
      if (currItem.checked)
      checkedItems[currItem.id] = true;
    });

    if (_.keys(checkedItems).length <= 1 || allowMultiSelect)
      updatedItems = allItems;

    this.setState({[statePath]: updatedItems});
  }

  async saveParentCompaniesMapping() {
    const { parentCompanies, subCompanies } = this.state;
    let selectedParentCompanyID = {};
    let selectedSubCompaniesIDs = {};

    (parentCompanies || []).forEach(currCompany => {
      if (currCompany.checked)
        selectedParentCompanyID[currCompany.id] = true;
    });
    (subCompanies || []).forEach(currCompany => {
      if (currCompany.checked)
        selectedSubCompaniesIDs[currCompany.id] = true;
    });

    selectedParentCompanyID = _.head(_.keys(selectedParentCompanyID));
    selectedSubCompaniesIDs = _.keys(selectedSubCompaniesIDs);

    if (!selectedParentCompanyID) {
      alert('PLEASE SELECT A PARENT COMPANY'); // PLACEHOLDER
      return;
    }

    try {
      const reqParams = { parentCompany: selectedParentCompanyID };
      let promisesArray = [];
      selectedSubCompaniesIDs.forEach(currCompanyID => {
        promisesArray.push(webActions.net.fetch(`${envParams.apiServer}/v1/companies?id=${currCompanyID}`, {
          method: 'PUT',
          body: JSON.stringify(reqParams)
        }))
      });
      await Promise.all(promisesArray);
      this.clearMultiSelect();
      alert('SUCCESS'); // PLACEHOLDER
    }
    catch (err) {
      alert(`ERROR: ${err}`); // PLACEHOLDER
    }
  }

  async saveCompanyOrganizationMapping() {
    const { cementoCompanies, zendeskOrganizations } = this.state;
    let selectedCompanyID = {};
    let selectedOrganizationID = {};
    (cementoCompanies || []).forEach(currCompany => {
      if (currCompany.checked)
        selectedCompanyID[currCompany.id] = true;
    });

    (zendeskOrganizations || []).forEach(currOrganization => {
      if (currOrganization.checked)
        selectedOrganizationID[currOrganization.id] = true;
    });

    if (_.keys(selectedCompanyID).length != 1 || _.keys(selectedOrganizationID).length != 1) {
      alert('INVALID AMOUNT OF COMPANIES/ORGANIZATIONS SELECTED'); // PLACEHOLDER
      return;
    }

    const reqParams = {
      scope: 'organization',
      companyID: _.head(_.keys(selectedCompanyID)),
      organizationID: _.head(_.keys(selectedOrganizationID))
    };
    try {
      await (webActions.net.fetch(envParams.apiServer + '/v1/services/zendesk/setExternalID', {
        method: 'POST',
        body: JSON.stringify(reqParams)
      }));
      this.clearMultiSelect();
      alert('SUCCESS'); // PLACEHOLDER
    }
    catch (err) {
      alert(`ERROR: ${err}`); // PLACEHOLDER
    }
  }

  clearMultiSelect() {
    const { cementoCompanies, zendeskOrganizations, parentCompanies, subCompanies } = this.state;
    if (cementoCompanies && zendeskOrganizations && parentCompanies && subCompanies) {
      cementoCompanies.forEach(currCompany => (currCompany.checked = false));
      zendeskOrganizations.forEach(currOrganization => (currOrganization.checked = false));
      parentCompanies.forEach(currParentCompany => (currParentCompany.checked = false));
      subCompanies.forEach(currSubCompany => (currSubCompany.checked = false));
      this.setState({ cementoCompanies, zendeskOrganizations, parentCompanies, subCompanies });
    }
  }

  async getZendeskOrganizations() {
    const url = `${envParams.apiServer}/v1/services/zendesk/getOrganizations`;
    let resp = await webActions.net.fetch(url);
    let data = await resp.json();
    let mappedOrganizations = [];
    data.forEach(currOrganization => {
      const currMappedOrganizations = {
        id: (_.get(currOrganization, ['id'])),
        name: _.get(currOrganization, ['name']),
        checked: false
      };
      mappedOrganizations.push(currMappedOrganizations);
    });
    return mappedOrganizations;
  }

  getNumberOfActiveProjects(companyObject) {
    let projectsCounter = 0;
    for (const currProject in _.get(companyObject, ['projects'])) {
      if (!_.get(companyObject, [currProject, 'isDeleted']))
        projectsCounter++;
    }
    return projectsCounter;
  }

  async getCementoCompanies(shouldGetOnlyParentCompanies=false) {
    const url = `${envParams.apiServer}/v1/companies?force=true&fields=["id", "name", "projects", "parentCompany"]`;
    let resp = await webActions.net.fetch(url);
    let data = await resp.json();
    let mappedCompanies = [];

    for (const currCompany in data) {
      if (shouldGetOnlyParentCompanies && _.get(data, [currCompany, 'parentCompany']))
        continue;

      if (_.get(data, [currCompany, 'name']) && !_.get(data, [currCompany, 'isDeleted'])) {
        const numberOfActiveProjects = this.getNumberOfActiveProjects(_.get(data, [currCompany]));
        const currMappedCompany = {
          id: (_.get(data, [currCompany, 'id'])),
          name: `${_.get(data, [currCompany, 'name'])} (${numberOfActiveProjects})`,
          checked: false
        };
        mappedCompanies.push(currMappedCompany);
      }
    }
    return mappedCompanies;
  }

  async toggleCementoZendeskMapping () {
    const { showCementoZendeskMapping } = this.state;
    let stateChanges = { showCementoZendeskMapping: !showCementoZendeskMapping };
    if (showCementoZendeskMapping) {
      stateChanges = {...stateChanges, zendeskOrganizations: {}, cementoCompanies: {}};
    }
    else {
      const [ zendeskOrganizations, cementoCompanies ] = await Promise.all([this.getZendeskOrganizations(), this.getCementoCompanies(true)]);
      stateChanges = {...stateChanges, zendeskOrganizations, cementoCompanies};
    }
    this.setState(stateChanges);
  }

  async toggleParentCompanyConfiguration () {
    const { showParentCompanyConfiguration } = this.state;
    let stateChanges = { showParentCompanyConfiguration: !showParentCompanyConfiguration };
    if (showParentCompanyConfiguration) {
      stateChanges = {...stateChanges, parentCompanies: {}, subCompanies: {}};
    }
    else {
      const cementoCompanies = await this.getCementoCompanies();
      stateChanges = {...stateChanges, parentCompanies: cementoCompanies, subCompanies: cementoCompanies};
    }
    this.setState(stateChanges);
  }

  render() {
    const { members, projectMembers, viewer, projectScope } = this.props;
    const { showCementoZendeskMapping, showParentCompanyConfiguration} = this.state;
    const { selectedMember, selectedRevokeFeature, zendeskOrganizations, cementoCompanies, parentCompanies, subCompanies } = this.state;
    const zendeskOrganizationsData = zendeskOrganizations || [];
    const cementoCompaniesData = cementoCompanies || [];
    const parentCompaniesData = parentCompanies || [];
    const subCompaniesData = subCompanies || [];
    const adminMode = (viewer || {}).adminMode;
    const options = [
      "buildings",
      "checklists-checklists",
      "checklists-items",
      "properties-types",
      "properties-mapping",
      "properties-instances-locationInfo",
      "properties-instances-employeesInfo",
      "properties-instances-equipmentInfo",
      "properties-instances-checklistItemsInfo",
      "drawings/units",
      "drawings/floors",
      "drawings/buildings",
      "floors",
      "posts",
      "projects",
      "units",
      "employees",
      "equipment",
    ].map((feature) => {
      return { value: feature, label: feature };
    });

    return (
      <div style={{ width: "100%", padding: theme.paddingSize }}>
        <GridContainer spacing={16}>
          <GridItem xs={4}>
            <Select
              value={selectedRevokeFeature}
              onChange={this.onRevokeFeatureSelect}
              styles={theme.basicSelectStyles}
              options={options}
            />
          </GridItem>
          <GridItem xs={4}>
            {/* <Button fullWidth disabled={Boolean(adminMode != 1)} onClick={this.onRevokeCacheClick}>{projectScope ? 'Revoke all project members cache' : 'Revoke all cemento members cache'}</Button> */}
            <Button
              fullWidth
              disabled={Boolean(adminMode != 1)}
              onClick={this.onLastUpdateTSClick}
            >
              {projectScope
                ? "Revoke all project members cache"
                : "Revoke all cemento members cache"}
            </Button>
          </GridItem>
          <GridItem xs={8}>
            <MembersManagerTable
              style={{ marginTop: theme.paddingSize }}
              onSelect={this.onSelect}
              members={projectScope ? projectMembers : members}
            />
          </GridItem>
          <GridItem xs={4}>
            <MemberCard_deprecated
              style={{
                backgroundColor: theme.backgroundColor,
                color: theme.brandNeutral,
              }}
              revokeCache={this.revokeCache}
              member={selectedMember}
            />
          </GridItem>
        </GridContainer>
        <GridContainer  style={{marginTop: "20px"}}>
          <GridItem xs={6}>
            <div style={{ borderRadius: '5px', display: 'flex', padding: '0px 5px', minWidth: 75, height: 30, alignSelf: 'center', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: 5, border: '1px solid ' + theme.brandPrimary + '85', color: theme.brandPrimary, cursor: 'pointer' }} onClick={this.toggleCementoZendeskMapping}><Text>Cemento-Zendesk mapping</Text></div>
          </GridItem>
          <GridItem xs={6}>
            <div style={{ borderRadius: '5px', display: 'flex', padding: '0px 5px', minWidth: 75, height: 30, alignSelf: 'center', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: 5, border: '1px solid ' + theme.brandPrimary + '85', color: theme.brandPrimary, cursor: 'pointer' }} onClick={this.toggleParentCompanyConfiguration}><Text>Parent company configuration</Text></div>
          </GridItem>
        </GridContainer>
        {
          Boolean(showCementoZendeskMapping) && 
          <GridContainer style={{marginTop: "20px"}}>
            <GridItem xs={12}>
            <Text style={{ color: 'rgb(242, 125, 34)', fontSize: theme.fontSizeH2, fontWeight: 'bold', paddingBottom: 2 * theme.paddingSize, textAlign: 'center' }}>Cemento-Zendesk mapping</Text>
            </GridItem>
            <GridItem xs={6}>
              <Text style={{ color: 'rgb(242, 125, 34)', fontSize: theme.fontSizeH4, fontWeight: 'bold' }}>Cemento company</Text>
              <MultiCheckSelect items={cementoCompaniesData} titlePropPath={['name']} onChange={(allItems) => this.updateMultiCheckSelect(allItems, 'cementoCompanies')}/>
            </GridItem>
            <GridItem xs={6}>
            <Text style={{ color: 'rgb(242, 125, 34)', fontSize: theme.fontSizeH4, fontWeight: 'bold' }}>Zendesk organization</Text>
              <MultiCheckSelect items={zendeskOrganizationsData} titlePropPath={['name']} onChange={(allItems) => this.updateMultiCheckSelect(allItems, 'zendeskOrganizations')}/>
            </GridItem>
            <GridItem xs={2}>
              <div style={{ borderRadius: '5px', display: 'flex', padding: '0px 5px', minWidth: 75, height: 30, alignSelf: 'center', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: 5, border: '1px solid ' + theme.brandPrimary + '85', color: theme.brandPrimary, cursor: 'pointer' }}  onClick={this.saveCompanyOrganizationMapping}><Text>שמור</Text></div>
            </GridItem>
          </GridContainer>
        }
        {
          Boolean(showParentCompanyConfiguration) &&
          <GridContainer style={{marginTop: "20px"}}>
            <GridItem xs={12}>
            <Text style={{ color: 'rgb(242, 125, 34)', fontSize: theme.fontSizeH2, fontWeight: 'bold', paddingBottom: 2 * theme.paddingSize, textAlign: 'center' }}>Parent company configuration</Text>
            </GridItem>
            <GridItem xs={6}>
              <Text style={{ color: 'rgb(242, 125, 34)', fontSize: theme.fontSizeH4, fontWeight: 'bold' }}>Sub company</Text>
              <MultiCheckSelect items={subCompaniesData} titlePropPath={['name']} onChange={(allItems) => this.updateMultiCheckSelect(allItems, 'subCompanies', true)}/>
            </GridItem>
            <GridItem xs={6}>
            <Text style={{ color: 'rgb(242, 125, 34)', fontSize: theme.fontSizeH4, fontWeight: 'bold' }}>Parent company</Text>
              <MultiCheckSelect items={parentCompaniesData} titlePropPath={['name']} onChange={(allItems) => this.updateMultiCheckSelect(allItems, 'parentCompanies')}/>
            </GridItem>
            <GridItem xs={2}>
              <div style={{ borderRadius: '5px', display: 'flex', padding: '0px 5px', minWidth: 75, height: 30, alignSelf: 'center', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: 5, border: '1px solid ' + theme.brandPrimary + '85', color: theme.brandPrimary, cursor: 'pointer' }}  onClick={this.saveParentCompaniesMapping}><Text>שמור</Text></div>
            </GridItem>
          </GridContainer>
        }
        
      </div>
    );
  }
} 

MembersManager = withStyles(buttonStyle)(MembersManager);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      members: state.members.map,
    }),
    { revokeUsersCache, updateProjectLastUpdateTS }
  )
);
export default enhance(MembersManager);
