import { removeProjectsStorage } from '../projects/actions';
import { getSnapshotData } from '../lib/utils/utils';
import { DRAWINGS_KEY } from './reducer';

export const GET_DRAWING_SETTINGS = 'GET_DRAWING_SETTINGS';
export const GET_DRAWINGS = 'GET_DRAWINGS';
export const UPDATE_DRAWINGS = 'UPDATE_DRAWINGS';
export const CLEAN_DRAWINGS_CACHE_DATA = 'CLEAN_DRAWINGS_CACHE_DATA';
export const BUILDINGS = "buildings";
export const BUILDING = "building";
export const FLOORS = "floors";
export const FLOOR = "floor";
export const UNITS = "units";
export const UNIT = "unit";

export const getDrawings = (projectId, drawingType) => {
  const getPromise = async () => {
    const snapshot = await getSnapshotData({
        api: DRAWINGS_KEY,
        firebaseSuffix: drawingType,
        queryParams: {
          locationType: drawingType,
          legacyMode: true
        }
      }, projectId
    ) || {};

    return  {
      ...snapshot,
      drawingType,
    };
  };

  return {
    type: GET_DRAWINGS,
    payload: getPromise()
  };
}

export const updateDrawings = (drawingsUpdates, projectId) => {
  const getPromise = async () => {
    return {
      drawingsUpdates,
      projectId,
    };
  };

  return {
    type: UPDATE_DRAWINGS,
    payload: getPromise()
  };
};

export function cleanDrawingsCachedData() {  
  return ({dispatch, getState, realmInstance, platformActions}) => {
    const getPromise = async () => {
      let projectIdsArray = [];
      let projectStateToRemove = [
        ['drawings', 'map'],
        ['drawings', 'lastUpdated'],
      ];

      getState().getNested(['projects', 'map'],{}).loopEach((k,p) => projectIdsArray.push(p.id))
      dispatch(removeProjectsStorage(projectIdsArray, projectStateToRemove));

    }
    return {
      type: CLEAN_DRAWINGS_CACHE_DATA,
      payload: getPromise()
    };
  };
}
